import {Box, Tab, Tabs} from '@mui/material'

type BrainBoxConfigTabsHeaderProps = {
  tab: number
  setTab: (tab: number) => void
}

const BrainBoxConfigTabsHeader = ({tab, setTab}: BrainBoxConfigTabsHeaderProps) => {
  return (
    <Box sx={styles.tabHeader}>
      <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} variant='scrollable' scrollButtons='auto' aria-label='scrollable auto tabs example'>
        <Tab label='Dados Gerais' style={styles.tab} disabled={false} />
        <Tab label='Dispositivos' style={styles.tab} disabled={false} />
        <Tab label='Controles' style={styles.tab} disabled={false} />
      </Tabs>
    </Box>
  )
}

const styles = {
  tabHeader: {
    justifyContent: 'center',
    display: 'flex',
  },
  tab: {
    fontFamily: 'Inter',
    fontSize: '13px',
  },
}

export default BrainBoxConfigTabsHeader
