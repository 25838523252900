import React, {forwardRef, useState} from 'react'
import {StyledTheme} from '../../StyledTheme'
import ErrorIcon from '../../images/selectables/ErrorIconInput'
import HidePasswordIcon from '../../images/selectables/HidePassword'
import ShowPasswordIcon from '../../images/selectables/ShowPassword'
import {
  InputBase,
  InputBaseContainer,
  InputContainer,
  InputIcon,
  InputLabel,
  InputLabelContainer,
  InputMuiLabel,
  InputMuiNoBorder,
  InputRequired,
  InputRightIconContainer,
  PasswordIconContainer,
  RightIconContainer,
} from './InputBase'
import {InputContainerProps, InputProps} from './InputProps'
import {applyMask} from './utils/applyMask'

const InputRoot = forwardRef<HTMLInputElement, InputProps>(({disabled, children, onChange, maxLength, ...props}: InputProps, ref) => {
  const [isShowingPassword, setIsShowingPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [hasValue, setHasValue] = useState(props.controlled ? !!props.value : !!props.defaultValue)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value
    if (props.mask) {
      if (typeof props.mask === 'function') {
        value = props.mask(value)
        e.currentTarget.value = value
      } else {
        let cursorPosition = e.currentTarget.selectionStart || 0
        const {outputValue, newCursorPosition} = applyMask(value, props.mask, cursorPosition)
        e.currentTarget.value = outputValue
        e.currentTarget.selectionEnd = newCursorPosition
      }
    }
    onChange && onChange(e)
  }

  return (
    <InputContainer width={props.width} disabled={disabled} {...(props as InputContainerProps)}>
      {props.label && (
        <InputLabelContainer>
          {props.labelTheme === 'mui' ? (
            <>
              <InputMuiLabel isFocused={isFocused} hasValue={hasValue}>
                {props.label}
                <InputMuiNoBorder isFocused={isFocused} hasValue={hasValue} bgColor={props.bgColor} />
              </InputMuiLabel>
            </>
          ) : (
            <InputLabel
              disabled={disabled}
              labelColor={props.labelColor}
              labelFontSize={props.labelFontSize}
              labelFontFamily={props.labelFontFamily}
              labelFontWeight={props.labelFontWeight}>
              {props.label}
            </InputLabel>
          )}
          {props.required && <InputRequired disabled={disabled}>*</InputRequired>}
        </InputLabelContainer>
      )}
      <InputBaseContainer
        disabled={disabled}
        disabledBorderOnFocus={props.disabledBorderOnFocus}
        error={!!props.errorMessage || props.hasError}
        bgColor={props.bgColor}
        borderColor={props.borderColor}
        borderRadius={props.borderRadius}>
        {props.leftIcon && <InputIcon>{props.leftIcon}</InputIcon>}

        <InputBase
          placeholderColor={props.placeholderColor}
          onFocus={() => setIsFocused(true)}
          ref={ref}
          id={props.id}
          height={props.height}
          name={props.name}
          disabled={disabled}
          onBlur={(e) => {
            props.onBlur?.(e)
            setIsFocused(false)
            if (e.currentTarget.value) {
              setHasValue(true)
            } else {
              setHasValue(false)
            }
          }}
          min={props.min}
          max={props.max}
          maxLength={maxLength}
          autoComplete={props.autoComplete}
          required={props.required}
          onChange={handleInputChange}
          fontSize={props.fontSize}
          hasIcon={!!props.leftIcon}
          fontFamily={props.fontFamily}
          fontWeight={props.fontWeight}
          hasError={!!props.errorMessage}
          placeholder={props.placeholder}
          borderRadius={props.borderRadius}
          value={props.controlled ? props.value : undefined}
          defaultValue={!props.controlled ? props.defaultValue : undefined}
          type={(props.type === 'password' && !isShowingPassword) || props.type !== 'password' ? props.type : 'text'}
        />

        {!props.rightIcon && props.type !== 'password' && props.errorMessage && (
          <RightIconContainer>
            <ErrorIcon color={disabled ? StyledTheme.colors.disabled : undefined} />
          </RightIconContainer>
        )}

        {props.rightIcon ? (
          <RightIconContainer>
            <InputRightIconContainer bgColor={props.bgColor}>{props.rightIcon}</InputRightIconContainer>
          </RightIconContainer>
        ) : (
          props.type === 'password' && (
            <RightIconContainer>
              <PasswordIconContainer disabled={disabled} onClick={() => setIsShowingPassword(!isShowingPassword)} type='button'>
                {isShowingPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
              </PasswordIconContainer>
            </RightIconContainer>
          )
        )}
      </InputBaseContainer>
      {children}
    </InputContainer>
  )
})

export default InputRoot
