import {Chip} from '@mui/material'
import {Box, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {Company, User} from 'src/services/api'
import {Quota} from 'src/services/api/endpoints/CompanyQuotaEndpoint'
import {Page} from 'src/types'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Pagination from '../../../components/pagination/Pagination'
import {QuotasTableOption} from './QuotasTableOption'

type QuotasGridProps = {
  setSelectedQuotasIds: (ids: number[]) => void
  selectedQuotasIds: number[]
  quotasUser: Quota[]
  openEditModal: (quota: Quota) => void
  quotasPage: Page
  setQuotasPage: Function
  totalElements: number
  companies: Company[]
  user: User
  loadingQuotas: boolean
}

function QuotasGridTable({quotasUser, openEditModal, quotasPage, setQuotasPage, totalElements, companies, user, loadingQuotas}: QuotasGridProps) {
  const quotasPageChange = (page: number) => setQuotasPage((lastPage: Page) => ({...lastPage, page}))
  const quotasPageSizeChange = (pageSize: number) => setQuotasPage((lastPage: Page) => ({...lastPage, pageSize}))
  const hasUpdateQuotasPermission = ValidatePermissions('quotas:update')
  const [quotas, setQuotas] = useState<QuotasGridTableProps[]>([])

  type QuotasGridTableProps = {
    id?: number
    company?: string
    contract?: string
    resource?: string
    totalQuotas: number
    available: number
    inUse: number
    self: Quota
  }

  const control = {
    currentPage: quotasPage.page,
    setCurrentPage: quotasPageChange,
    nextPage: () => quotasPageChange(quotasPage.page + 1),
    prevPage: () => quotasPageChange(quotasPage.page - 1),
    changePageSize: (pageSize: number) => quotasPageSizeChange(pageSize),
  }

  function renderChip(row: QuotasGridTableProps) {
    if (!hasUpdateQuotasPermission) return row.company
    return user.role === 'admin' ? (
      <Box>
        {row.company} &nbsp;
        {row.contract === 'Expirado' ? (
          <Chip label={row.contract} color='error' size='small' variant='outlined' />
        ) : row.contract === 'Teste' ? (
          <Chip label={row.contract} color='warning' size='small' variant='outlined' />
        ) : (
          <></>
        )}
      </Box>
    ) : (
      <>{row.company!}</>
    )
  }

  const columns = [
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'resource',
      name: 'Recurso',
    },
    {
      id: 'totalQuotas',
      name: 'Total de quotas',
    },
    {
      id: 'available',
      name: 'Disponível',
    },
    {
      id: 'inUse',
      name: 'Em uso',
    },
    {
      id: 'self',
      name: '',
    },
  ]

  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) => (
          <TableHeaderColumn key={col.id}>{col.name}</TableHeaderColumn>
        ))}
      </TableHeaderRow>
    </thead>
  )

  useEffect(() => {
    const quotasMapped = quotasUser.map((it) => {
      const days = moment().format('YYYY-MM-DD')
      const inExpiration = it.company?.expirationDate
      const timeDiff = moment(inExpiration).diff(days, 'day')
      return {
        id: it.id,
        company: companies.find((it2) => it2.id === it.companyId)?.name,
        contract: it.company?.expirationDate ? (timeDiff <= 15 && timeDiff >= 0 ? 'Teste' : timeDiff < 0 ? 'Expirado' : '-') : '-',
        resource: it.resource?.name,
        totalQuotas: Number(it.quota),
        available: Number(it.quota) - Number(it.used),
        inUse: Number(it.used),
        self: it,
      }
    })
    setQuotas(quotasMapped)
  }, [quotasUser])

  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table columns={columns} isLoading={loadingQuotas} skeletonRowsAmount={10} tableHeaderChildren={customHeader}>
          {quotas.map((row, index) => (
            <RowHover key={index}>
              <TableData>{renderChip(row)}</TableData>
              <TableData>
                <LinkWithAction onClick={() => openEditModal(row.self)}>{row.resource}</LinkWithAction>
              </TableData>
              <TableData>{row.totalQuotas}</TableData>
              <TableData>{row.available}</TableData>
              <TableData>{row.inUse}</TableData>
              <TableData>
                <QuotasTableOption
                  oneObject={String(row.id)}
                  onClick={(event) => {
                    if (event === 'edit-quota') openEditModal(row.self)
                  }}
                  hasUpdatePermission={hasUpdateQuotasPermission}
                />
              </TableData>
            </RowHover>
          ))}
        </Table>
      </Box>

      {!loadingQuotas && quotas.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={totalElements}
            itemsPerPage={quotasPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default QuotasGridTable
