import {Box, Card, CardContent, CardHeader, Checkbox, InputLabel, MenuItem, Select, SelectChangeEvent, TextField} from '@mui/material'

import {IMaskInput} from 'react-imask'
import {useAnalyticCamerasContext} from 'src/pages/analytic-cameras/context/AnalyticCamerasContext'
import createStyleSheet from '../../../../../utilities/createStyleSheet'

function CameraRegisterHikvisionModal() {
  const {analyticCamera, setAnalyticCamera, selectedAnalytics, analytics, setAnalytics, setSelectedAnalytics} = useAnalyticCamerasContext()

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const eventValue = event.target.value as string[]
    const mappedAnalytics = analytics.map((value) => {
      if (eventValue.includes(value.analytic)) return {...value, selected: !value.selected}
      return value
    })
    setAnalytics(mappedAnalytics)
    if (!eventValue.length) return
    const selected = mappedAnalytics.filter((analytic) => analytic.selected).map((analytic) => ' ' + analytic.label)
    setSelectedAnalytics(selected)
  }

  return (
    <Box sx={styles.container}>
      <Card sx={{boxShadow: 0}}>
        <Box sx={styles.boxHeader}>
          <Box sx={{display: 'flex'}}>
            <CardHeader container title='Cadastro de câmera analítica Hikvision' />
          </Box>
        </Box>
        <CardContent sx={styles.cardContent}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'left',
              marginTop: '20px',
              gap: '10px',
            }}>
            <Box>
              <InputLabel htmlFor='name'>Nome da câmera</InputLabel>
              <TextField
                name='name'
                sx={{width: '100%'}}
                placeholder='Nome'
                required
                type='text'
                value={analyticCamera.name}
                onChange={(e) => {
                  setAnalyticCamera({...analyticCamera, name: e.target.value})
                }}
              />
            </Box>
            <Box style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
              <Box sx={{flex: 2, width: '100%'}}>
                <InputLabel htmlFor='host'>Host</InputLabel>
                <TextField
                  sx={{width: '95%'}}
                  name='host'
                  placeholder='Host'
                  variant='outlined'
                  value={analyticCamera?.host}
                  onChange={(e) => {
                    setAnalyticCamera({...analyticCamera, host: e.target.value})
                  }}
                />
              </Box>
              <Box sx={{flex: 1}}>
                <InputLabel htmlFor='analytic-camera-port'>Porta</InputLabel>
                <IMaskInput
                  name='analytic-camera-port'
                  placeholder='Porta'
                  style={styles.macInput}
                  value={String(analyticCamera?.port)}
                  mask={Number}
                  min={0}
                  max={65535}
                  onAccept={(value) => {
                    setAnalyticCamera({...analyticCamera, port: Number(value)})
                  }}
                />
              </Box>
            </Box>
            <Box>
              <InputLabel htmlFor='macAddress'>Endereço MAC</InputLabel>
              <IMaskInput
                name='macAddress'
                mask='##:##:##:##:##:##'
                definitions={{'#': /[a-fA-F0-9]/}}
                style={styles.macInput}
                placeholder='A0:B0:C0:D0:E0:F0'
                label='Endereço MAC'
                required
                overwrite
                value={analyticCamera.macAddress.toUpperCase()}
                unmask={false}
                onAccept={(value) => {
                  setAnalyticCamera({...analyticCamera, macAddress: value})
                }}
              />
            </Box>
            <Box>
              <InputLabel htmlFor='analytics'>Analíticos</InputLabel>
              <Select
                name='analytics'
                multiple
                required
                sx={{height: '3.46875em', width: '100%'}}
                value={selectedAnalytics}
                renderValue={(value) => [value].join(', ')}
                onChange={handleChange}
                MenuProps={{
                  PaperProps: {
                    sx: {maxHeight: '220px'},
                  },
                  style: {
                    maxHeight: 500,
                  },
                }}>
                {analytics.map((analytic, index) => (
                  <MenuItem key={'role-' + index} value={analytic.analytic}>
                    <Checkbox checked={analytic.selected} />
                    {`${analytic.label} `}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}

const styles = createStyleSheet({
  input: {
    '&:invalid': {
      border: 'red solid 2px',
    },
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
  },
  backButton: {
    width: '150px',
    height: '40px',
    marginRight: '17px',
    marginBottom: '10px',
    fontFamily: 'Inter',
    textTransform: 'none',
  },
  container: {
    paddingTop: '2rem',
    justifyContent: 'center',
  },
  searchContainer: {
    paddingBottom: '1rem',
  },
  listContainer: {
    maxHeight: '42vh',
    overflow: 'hidden',
    height: '400px',
  },
  buttonAdd: {
    justifyContent: 'space-evenly',
    height: '2.7em',
    width: '13em',
    fontFamily: 'Inter',
    margin: '0px 16px 0px 16px',
    fontSize: '0.9rem',
    display: 'flex',
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  boxHeader: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    alignItems: 'center',
    gap: 1.5,
    paddingBottom: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  exHost: {
    fontSize: '13px',
    color: '#0000cd',
    marginBottom: '20px',
  },
  hasLink: {
    fontSize: '15px',
    paddingTop: 1,
    paddingRight: 4,
  },
  fieldsUser: {
    justifyContent: 'space-between',
    display: 'flex',
    marginBottom: 2,
  },
  cardContent: {
    width: '100%',
    padding: '0px 60px 0px 60px',
    overflow: 'hidden',
    height: '50.192vh',
  },
  sizeCodeStream: {
    width: '240px',
    height: '56px',
  },
  sizeRightCodeStream: {
    width: '240px',
    height: '56px',
    marginRight: '14em',
  },
  sizeCodeStreamIntel: {
    width: '240px',
    height: '56px',
    marginRight: '10.3em',
  },
  sizeRightCodeStreamIntel: {
    width: '185px',
    height: '56px',
    marginRight: '29.3em',
  },
  macInput: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '16px',
    justifyContent: 'center',
    borderRadius: '6px',
    height: '56px',
    padding: '10px',
    border: '#C6C1C1 solid 1px',
    '&:hover': {
      border: '#00639f solid 1px',
    },
  },
})

export default CameraRegisterHikvisionModal
