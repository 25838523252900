import {Box, Pagination, TextField} from '@mui/material'
import {useState} from 'react'
import {useDebounce} from 'usehooks-ts'

interface PaginationProps {
  page: number
  count: number
  onChange: (event: any) => void
}

function CustomPagination({page, count, onChange}: PaginationProps) {
  const [pageText, setPageText] = useState((page && page.toString()) || '1')
  const pageTextDebounced = useDebounce(pageText, 1000)
  const pageCount = Math.ceil(count / 100)

  return (
    <Box sx={styles.container}>
      <TextField
        variant='standard'
        inputProps={{min: 0, style: {textAlign: 'center'}}}
        sx={{minWidth: '40px', maxWidth: '60px'}}
        onFocus={(event) => {
          event.target.select()
        }}
        onChange={(e) => {
          const textValue = e.target.value.replace(/[^\d]/g, '')
          const value = textValue === '' ? 1 : Number(textValue)
          if (value < pageCount) setPageText(value.toString())
          else setPageText(pageCount.toString())
        }}
        value={pageText}
      />

      <Pagination
        color='primary'
        count={pageCount}
        showFirstButton
        showLastButton
        page={+pageTextDebounced}
        onChange={(event, value) => {
          onChange(value)
          setPageText(value.toString())
        }}
        sx={styles.pagination}
      />
      <Box sx={styles.rightText}>
        <p style={styles.quantityText}>
          Total de Itens
          <span style={styles.quantityNumber}>{count}</span>
        </p>
      </Box>
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  pagination: {
    display: 'flex',
  },
  rightText: {
    padding: '0 2rem',
  },
  quantityText: {
    fontSize: '15px',
  },
  quantityNumber: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: 'black',
    marginLeft: '8px',
  },
}

export default CustomPagination
