import {useEffect} from 'react'
import {TermLI, TermOL, TermParagraph, TermSectionTitle} from './TermContentBase'

export function TermContent() {
  const navigateSmooth = (anchor: string) => {
    const targetElement = document.getElementById(anchor)
    if (targetElement) {
      targetElement.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  const copyUrl = (anchor: string) => {
    const baseUrl = window.location.href.split('#')[0]
    const url = `${baseUrl}#${anchor}`
    navigator.clipboard.writeText(url).then(() => {
      window.history.pushState(null, '', url)
      setTimeout(() => navigateSmooth(anchor), 100)
    })
  }

  useEffect(() => {
    const anchor = window.location.hash.slice(1)
    if (anchor) navigateSmooth(anchor)
  }, [])

  return (
    <div>
      <TermParagraph>
        A Viptech Desenvolvimento de Programas LTDA, doravante denominada simplesmente como Viptech , inscrita no CNPJ/MF sob o nº 22.823.882/0001-28, detentora
        e titular da marca Brain Artificial Intelligence , registrada junto ao INPI, com nome comercial do aplicativo BRAIN e do site{' '}
        <a href='https://brain.viptech.com.br'>https://brain.viptech.com.br</a>, preocupada e engajada com a política de privacidade, termos de uso e proteção
        de dados de seus usuários e visitantes, informa que neste documento você encontrará:
      </TermParagraph>
      <TermOL type='a'>
        <TermLI>
          <TermParagraph>Termos e definições importantes para compreensão total do documento;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Informações sobre o funcionamento do serviço solicitado, ou, produto adquirido;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Regras de uso e privacidade;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Dados e informações que serão armazenadas ao visitar o site e adquirir qualquer dos produtos/serviços;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Responsabilidades do visitante e adquirente;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Responsabilidades da VIPTECH;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Formas de contato e canais de comunicação ao usuário;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Foro responsável para discussões judiciais;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Formas de garantia, cancelamento e devolução de valores;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Informações sobre tratamento de dados pessoais e sua finalidade;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Compartilhamento de dados com terceiros;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Medidas de Segurança</TermParagraph>
        </TermLI>
      </TermOL>

      <TermSectionTitle id='ACEITACAO_DO_TERMO_DE_USO_E_POLITICA_DE_PRIVACIDADE' onClick={() => copyUrl('ACEITACAO_DO_TERMO_DE_USO_E_POLITICA_DE_PRIVACIDADE')}>
        ACEITAÇÃO DO TERMO DE USO E POLÍTICA DE PRIVACIDADE
      </TermSectionTitle>
      <TermParagraph>
        Ao utilizar os serviços e visitar o site, você confirma que leu, compreendeu o Termo de Uso e Política de Privacidade aplicáveis ao serviço solicitado e
        concorda em ficar a eles vinculado.
      </TermParagraph>

      <TermSectionTitle id='DOS_TERMOS_E_DEFINICOES' onClick={() => copyUrl('DOS_TERMOS_E_DEFINICOES')}>
        DOS TERMOS E DEFINIÇÕES
      </TermSectionTitle>
      <TermParagraph>Para os efeitos deste documento aplicam-se os seguintes termos e definições:</TermParagraph>
      <TermOL type='I'>
        <TermLI>
          <TermParagraph>Visitante: pessoa que navega pelo site.</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Cadastro: dados fornecidos voluntariamente quando o visitante e cria uma conta com login e senha no site/aplicativo;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Adquirente: pessoa que compra o produto/serviço oferecido;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Dado Pessoal - Qualquer dado relacionado à pessoa natural identificada ou identificável, tais como: IP, geolocalização, nome, RG, CPF, endereço,
            telefone, conta bancária, dados de veículo, imagens, dentre outros.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Tratamento de Dados: refere-se às operações realizadas com Dados Pessoais, sejam essas operações referentes a coleta, produção, recepção,
            classificação, utilização, ao acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou
            controle de informações, modificação, comunicação, transferência, difusão ou extração;
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Agentes de tratamento - são aqueles que realizam o Tratamento de Dados Pessoais, ou seja, participam de operações que envolvem Dados Pessoais,
            também são conhecidos como Controlador e Operador.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Controlador: é a pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao Tratamento de Dados Pessoais.
            Neste documento, quaisquer menções e comentários à Controladora deverão ser entendidos como direcionados à VIPTECH;
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Operador: é a pessoa natural ou jurídica, de direito público ou privado, que realiza o Tratamento de Dados Pessoais, em consonância com as
            instruções do Controlador;
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            ANPD - Autoridade Nacional de Proteção de Dados (ANPD). Órgão vinculado à Presidência da República, dotada de autonomia técnica e decisória, que tem
            o objetivo de proteger os direitos fundamentais de liberdade e privacidade e o livre desenvolvimento da personalidade da pessoa natural, orientada
            pelo disposto na Lei nº 13.709, de 14 de agosto de 2018, a LGPD.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Biometria - Característica física mensurável ou um traço pessoal comportamental usado para reconhecer ou verificar a identidade de uma pessoa.
            (Imagens faciais, digitais, amostras de íris, etc).
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Cookies - Arquivos baixados no computador do usuário, tablet ou telefone quando na visitação de um site, para aprimoramento da experiência. Alguns
            cookies são classificados como necessários e permitem as funcionalidades centrais, como segurança, gerenciamento de rede e acessibilidade. Esses
            cookies podem ser coletados e armazenados assim que você inicia sua navegação ou quando usa algum recurso que os requer.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Consentimento - Manifestação livre, informada e inequívoca pela qual o Titular concorda com o tratamento de seus Dados Pessoais para uma finalidade
            determinada. Ao ingressar no site e fazer seu cadastro o usuário estará outorgando seu consentimento. O consentimento pode ser revogado pelo titular
            a qualquer momento.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Dado Pessoal Sensível - Dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de
            caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa
            natural. Também são considerados Dados, Pessoais Sensíveis qualquer dado biométrico do Titular (digital, reconhecimento facial, íris, voz, retina,
            imagens, etc).
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Direito do Titular/Usuário sobre os dados - Direito garantido ao Titular para o acesso, correção e exclusão dos seus Dados Pessoais.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Finalidade da coleta de dados - É a realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao Titular, sem
            possibilidade de tratamento posterior de forma incompatível com essas finalidades.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Pixels - Pequenas imagens que fazem parte de códigos em páginas da internet que realizam tarefas como permitir que outro servidor meça a
            visualização de uma página da internet e muitas vezes são usados em conjunto com os cookies.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Prazo de utilização dos dados - Prazo indeterminado concedido para que a Viptech faça o armazenamento dos Dados Pessoais e posterior exclusão;
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Tags - Ferramentas utilizadas para aferição de cliques em determinadas seções e links específicos dos sites.</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Endereço IP: Endereço de Protocolo de Internet, o código atribuído a um terminal de uma rede para permitir sua identificação, definido segundo
            parâmetros internacionais.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Sites e aplicativos: Os sites são um conjunto de páginas disponíveis na internet, e os aplicativos são softwares que executam um grupo de funções
            com o objetivo de disponibilizar um serviço aos usuários.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Arquivos de Log: registro de eventos, como acessos de usuários, tentativas de login, modificações de arquivos, erros do sistema, qual usuário ou
            processo gerou o evento, códigos de erro, mensagens de status, ou outras informações contextuais, e muito mais
          </TermParagraph>
        </TermLI>
      </TermOL>

      <TermSectionTitle
        id='OS_DADOS_PESSOAIS_QUE_SAO_COLETADOS_E_TRATADOS_PELA_VIPTECH_E_OS_DIREITOS_DO_USUARIO_VISITANTE'
        onClick={() => copyUrl('OS_DADOS_PESSOAIS_QUE_SAO_COLETADOS_E_TRATADOS_PELA_VIPTECH_E_OS_DIREITOS_DO_USUARIO_VISITANTE')}>
        OS DADOS PESSOAIS QUE SÃO COLETADOS E TRATADOS PELA VIPTECH e OS DIREITOS DO USUÁRIO/VISITANTE
      </TermSectionTitle>
      <TermOL type='I'>
        <TermLI>
          <TermParagraph>
            Todas as informações ativamente fornecidas pelo Usuário, como, por exemplo, nome, e e-mail, endereço, dados bancários, RG, CPF, bem como aquelas que
            são geradas automaticamente, como as características do dispositivo de acesso, protocolo de internet (“IP”), informações de acessos, dados de
            geolocalização, histórico de aplicações e dados informados para o login, são recebidas e armazenadas em ambiente seguro, no Banco de Dados da
            Viptech.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Informações financeiras e de pagamento;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Dados Pessoais Sensíveis: Se for necessário o tratamento de Dados Pessoais sensíveis para outras finalidades, estas têm base legal e a Viptech
            proverá prévia ciência ao Titular.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            O Site conta com um sistema de formulários para preenchimento, de acordo com o tipo de comunicação pretendida pelo Usuário;
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>A VIPTECH coleta os Dados Pessoais do Usuário, através do Site para as seguintes finalidades:</TermParagraph>
          <TermOL type='a'>
            <TermLI>
              <TermParagraph>Atendimento ao Contato do Usuário;</TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>Oferecimento de produtos destinados ao perfil do usuário;</TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Evitar fraude e outras atividades ilegais e proibidas, assegurando a integridade do Site e cumprindo as exigências legais (Marco Civil da
                Internet).
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>São direitos do usuário titular dos dados:</TermParagraph>
          <TermOL type='a'>
            <TermLI>
              <TermParagraph>confirmação da existência de tratamento;</TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>acesso aos Dados Pessoais;</TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>correção de Dados Pessoais incompletos, inexatos ou desatualizados;</TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários, excessivos ou tratados em desconformidade com o disposto na Lei nº
                13.709;
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                portabilidade dos Dados Pessoais a outro fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos comercial e
                industrial, de acordo com a regulamentação do órgão controlador;
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                eliminação dos Dados Pessoais tratados com o consentimento do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709;
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                informação das entidades públicas e privadas com as quais a Controladora realizou uso compartilhado de Dados Pessoais;
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; e IX. revogação do consentimento, nos
                termos do § 5º do art. 8º da Lei nº 13.709.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
      </TermOL>

      <TermSectionTitle id='FINALIDADE_E_USO_DOS_DADOS_COLETADOS' onClick={() => copyUrl('FINALIDADE_E_USO_DOS_DADOS_COLETADOS')}>
        FINALIDADE E USO DOS DADOS COLETADOS
      </TermSectionTitle>
      <TermParagraph>
        Os dados coletados são destinados a diversas finalidades, onde o usuário/visitante concorda que não será necessário autorização específica para cada
        uso, porém, de maneira genérica se destinam a:
      </TermParagraph>
      <TermOL type='a'>
        <TermLI>
          <TermParagraph>Comunicação;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Identificação;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Segurança;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Personalização de termos, produtos e serviços;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Manter o usuário logado;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Permitir o envio de notificações;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Identificar perfil do consumidor;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Definir a geolocalização do usuário;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Autenticar o ingresso;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Garantir meios seguros de pagamento e cobrança;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Permitir acesso a recursos exclusivos para usuários autenticados e reduzir o risco de fraudes;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Permitir Recrutamento, seleção e admissão;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Cumprimento de determinações legais e finalização de contratos;</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Customizar experiências</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Atender às determinações de autoridades competentes</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Gerenciar riscos e detectar, prevenir e/ou remediar fraudes</TermParagraph>
        </TermLI>
      </TermOL>

      <TermSectionTitle
        id='USO_DE_COOKIES_TECNOLOGIAS_SIMILARES_E_ARQUIVOS_DE_LOG'
        onClick={() => copyUrl('USO_DE_COOKIES_TECNOLOGIAS_SIMILARES_E_ARQUIVOS_DE_LOG')}>
        USO DE COOKIES/TECNOLOGIAS SIMILARES E ARQUIVOS DE LOG
      </TermSectionTitle>
      <TermOL type='I'>
        <TermLI>
          <TermParagraph>
            A Viptech poderá utilizar Cookies (rastreadores de navegação) para melhorar o uso e a funcionalidade das páginas e serviços, e para entender melhor
            como os visitantes os usam, bem como as ferramentas e serviços ali oferecidos. Os Cookies ajudam a adaptar as páginas e serviços da Viptech às
            necessidades pessoais, facilitando cada vez mais o seu uso, avaliando a satisfação e viabilizando a comunicação com o usuário em outros locais na
            internet.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Alguns tipos de Cookies podem ser usados nas páginas e serviços da Viptech e seus parceiros, os sites da Viptech podem conter cookies, e neste caso,
            será solicitado ao usuário o consentimento antes de baixá-los:
          </TermParagraph>
          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                Cookies Primários: Serão colocados no dispositivo do usuário diretamente pelo site da Viptech. Eles são essenciais para que o cliente navegue no
                site e utilize todos os seus recursos.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Cookies de Terceiros: São colocados no dispositivo do usuário não pelo site que o mesmo está visitando, mas por terceiros, como, por exemplo, os
                sistemas analíticos.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Cookies Temporários: São cookies de sessão. São temporários e expiram quando o usuário fecha o navegador ou quando a sessão termina. A
                finalidade é estabelecer controle de idioma e segurança ao tempo da sessão.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Cookies Persistentes: Cookies que permanecem no disco rígido do usuário até que o mesmo o apague ou seu navegador o faça, dependendo da data de
                expiração do cookie. Todos os cookies persistentes têm uma data de expiração gravada em seu código, mas sua duração pode variar. A finalidade é
                coletar e armazenar a ciência sobre o uso de cookies no site.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Cookies de marketing: Esses cookies rastreiam a atividade online do usuário para ajudar os anunciantes a fornecer publicidade mais relevante
                para quem usa o site, podendo estas informações serem compartilhadas com outras organizações. São cookies persistentes e quase sempre de
                proveniência de terceiros.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Cookies de preferências: Esses cookies permitem que um site se lembre de escolhas que você fez no passado, como o idioma de sua preferência ou o
                nome de usuário e a senha.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Cookies de estatísticas: Esses cookies coletam informações sobre como o usuário utiliza um site, quais páginas ele visitou e quais links ele
                clicou. Nenhuma dessas informações poderá ser usada para identificar o titular de dados pessoais. Seu único objetivo é melhorar as funções do
                site baseado nas estatísticas coletadas.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>
            As páginas ou serviços da Viptech ou de seus parceiros podem também usar outras tecnologias de rastreamento, inclusive endereços IP, tags, arquivos
            de registro e sinalizadores da web, que também ajudam a adaptar os sites às necessidades pessoais do usuário.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            O endereço IP do usuário e outros dados relativos à conexão do mesmo poderão ser utilizados para personalizar o serviço e compreender como os
            usuários utilizam os serviços.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            É reservado à Viptech bloquear o acesso ao site de usuários alocados em alguns países, identificados de acordo com o IP utilizado.
          </TermParagraph>
        </TermLI>
      </TermOL>

      <TermSectionTitle id='COMPARTILHAMENTO_DOS_DADOS' onClick={() => copyUrl('COMPARTILHAMENTO_DOS_DADOS')}>
        COMPARTILHAMENTO DOS DADOS
      </TermSectionTitle>
      <TermOL type='I'>
        <TermLI>
          <TermParagraph>O compartilhamento de dados e informações poderão ocorrer para:</TermParagraph>
          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                Os prestadores de serviço e fornecedores, para atuarem na operação e execução dos serviços contratados, os quais estarão cientes e irão possuir
                as responsabilidades e comprometimento sobre a privacidade de Dados Pessoais acordados em cláusulas contratuais específicas;
              </TermParagraph>
              <TermLI>
                <TermParagraph>As unidades bancárias, exclusivamente para realização de transações contratuais ou trabalhistas;</TermParagraph>
              </TermLI>
              <TermLI>
                <TermParagraph>
                  Os órgãos reguladores, autoridades judiciais ou administrativas, onde podemos compartilhar as informações pessoais para prestar às autoridades
                  competentes todas as informações que forem solicitadas com relação ao Titular para investigação de suspeitas de violação da lei, ou para
                  combater qualquer outra suspeita de descumprimento das nossas políticas e contratos;
                </TermParagraph>
              </TermLI>
              <TermLI>
                <TermParagraph>
                  Os clientes, que devem atuar em conformidade com a legislação vigente sobre proteção de dados pessoais e as determinações de órgãos
                  reguladores sobre a matéria, além das demais normas e políticas de proteção de dados, assegurando a proteção dos dados compartilhados,
                  preservando sua confidencialidade, integridade, disponibilidade e autenticidade.
                </TermParagraph>
              </TermLI>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Para os casos não previstos acima em que seja necessário o compartilhamento dos Dados Pessoais, será solicitada ao Titular dos Dados Pessoais a
            autorização expressa (consentimento), através do envio de uma notificação com informações sobre o compartilhamento.
          </TermParagraph>
        </TermLI>
      </TermOL>

      <TermSectionTitle id='OBRIGACOES_DO_USUARIO' onClick={() => copyUrl('OBRIGACOES_DO_USUARIO')}>
        OBRIGAÇÕES DO USUÁRIO
      </TermSectionTitle>

      <TermParagraph>O usuário/visitante tem papel fundamental na proteção dos seus Dados Pessoais, ficando obrigado a:</TermParagraph>
      <TermParagraph>
        a. Ao criar uma conta online ou uma senha o usuário deve assegurar-se de definir uma senha que seja de difícil descoberta e nunca a revelar a outras
        pessoas. Cada um é responsável por manter a senha confidencial e intransferível.
      </TermParagraph>
      <TermOL type='a'>
        <TermLI>
          <TermParagraph>
            Ao usar computador compartilhado ou público, nunca escolher a opção de lembrar ID de login, endereço de e-mail, login ou senha e sempre
            certificar-se que saiu da sua conta (“log out”).
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>Utilizar todas as configurações de privacidade ou controles que são fornecidos em nosso site, serviços ou aplicativos.</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            O usuário deverá ser cuidadoso ao usar computadores de terceiros ou potencialmente infectados, para evitar que suas senhas sejam obtidas e seus
            emails e logins sejam indevidamente acessados.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            O usuário deverá ainda acessar seu Webmail, digitando a URL diretamente no navegador e ter cuidado ao clicar em links recebidos por meio de
            mensagens eletrônicas.
          </TermParagraph>
        </TermLI>
      </TermOL>

      <TermSectionTitle
        id='ALTERACOES_NA_POLITICA_DE_PRIVACIDADE_E_PROTECAO_DE_DADOS'
        onClick={() => copyUrl('ALTERACOES_NA_POLITICA_DE_PRIVACIDADE_E_PROTECAO_DE_DADOS')}>
        ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS
      </TermSectionTitle>

      <TermParagraph>
        Sempre que a Viptech alterar formas e objetivos na coleta e tratamento de Dados Pessoais, esta Política será atualizada. A Viptech se reserva o direito
        de fazer alterações às práticas e a esta Política de Privacidade a qualquer tempo. Solicita-se que esta Política seja periodicamente acessada.
      </TermParagraph>
      <TermSectionTitle id='CONTATO' onClick={() => copyUrl('CONTATO')}>
        CONTATO
      </TermSectionTitle>

      <TermParagraph>
        Para fazer qualquer solicitação, informação, ou, notificação sobre as práticas de privacidade o usuário deverá entrar em contato pelo e-mail{' '}
        <a href='mailto:privacidade@viptech.com.br'>privacidade@viptech.com.br</a>.
      </TermParagraph>

      <TermSectionTitle id='FORMAS_DE_CANCELAMENTO_E_DEVOLUCAO_DE_VALORES' onClick={() => copyUrl('FORMAS_DE_CANCELAMENTO_E_DEVOLUCAO_DE_VALORES')}>
        FORMAS DE CANCELAMENTO E DEVOLUÇÃO DE VALORES
      </TermSectionTitle>
      <TermParagraph>Regras de Cancelamento de Produtos</TermParagraph>

      <TermOL type='I'>
        <TermLI>
          <TermParagraph>Prazo de Cancelamento</TermParagraph>

          <TermOL type='a'>
            <TermLI>
              <TermParagraph>Produtos Digitais: O usuário pode cancelar a compra de produtos digitais no prazo de 7 dias corridos após a compra.</TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>Condições para Cancelamento</TermParagraph>

          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                Produtos Digitais: O produto digital não deve ter sido consumido (baixado ou utilizado) além de uma visualização prévia permitida. No caso de
                produtos pagos por período (mensal, semanal, anual), será cobrado a totalidade do período escolhido na ocasião da aquisição, sem direito a
                reembolso.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>Processo de Cancelamento</TermParagraph>

          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                O usuário deve solicitar o cancelamento através do formulário online disponível no site ou através do atendimento ao cliente.
              </TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>O usuário deve fornecer detalhes do pedido, motivo do cancelamento e quaisquer outras informações solicitadas.</TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>Devolução de Valores</TermParagraph>
          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                Produtos Digitais: Após a verificação do cancelamento dentro do prazo e condições estabelecidas, o valor pago será reembolsado dentro de 10 dias
                úteis.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>Custos de Devolução</TermParagraph>

          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                Em caso de devolução de produtos físicos, a empresa cobrirá os custos de envio, desde que o cancelamento tenha ocorrido dentro do prazo de 7
                dias corridos.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>Atendimento ao Cliente</TermParagraph>

          <TermOL type='a'>
            <TermLI>
              <TermParagraph>
                O atendimento ao cliente está disponível para ajudar com qualquer dúvida ou problema relacionado ao cancelamento e devolução de produtos e
                serviços.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
        <TermLI>
          <TermParagraph>Comunicação</TermParagraph>
          <TermOL type='a'>
            <TermLI>
              <TermParagraph>Todas as regras de cancelamento devem ser claramente comunicadas ao usuário no momento da compra.</TermParagraph>
            </TermLI>
            <TermLI>
              <TermParagraph>
                Um e-mail de confirmação será enviado ao usuário após a solicitação de cancelamento, detalhando os próximos passos e prazos para a devolução de
                valores.
              </TermParagraph>
            </TermLI>
          </TermOL>
        </TermLI>
      </TermOL>

      <TermSectionTitle id='DISPOSICOES_GERAIS' onClick={() => copyUrl('DISPOSICOES_GERAIS')}>
        DISPOSIÇÕES GERAIS
      </TermSectionTitle>
      <TermOL type='I'>
        <TermLI>
          <TermParagraph>As políticas e normas da Viptech devem ser integradas e harmonizadas com as disposições desta Política.</TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            O presente documento deve ser considerado em conjunto com outros padrões, normas, processos e procedimentos aplicáveis adotados pela Viptech.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            Os casos não previstos ou que estejam omissos a esta política deverão ser encaminhados para a Diretoria Administrativa Financeira, por meio do
            e-mail <a href='mailto:financeiro@viptech.com.br'>financeiro@viptech.com.br</a>.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            As diretrizes e boas práticas desta Política de Privacidade podem sofrer alterações a qualquer momento as quais serão informadas e estarão
            disponíveis nos canais cabíveis.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>
            A Viptech se resguarda de executar verificações e avaliações do cumprimento das diretrizes estabelecidas, podendo o interessado encaminhar
            ocorrências nos casos do não atendimento a essa Política para a Diretoria Administrativa Financeira, por meio do email{' '}
            <a href='mailto:financeiro@viptech.com.br'>financeiro@viptech.com.br</a>, responsável por decidir quais sanções serão aplicadas.
          </TermParagraph>
        </TermLI>
        <TermLI>
          <TermParagraph>A Política entrará em vigor a partir da data de sua publicação.</TermParagraph>
        </TermLI>
      </TermOL>
    </div>
  )
}
