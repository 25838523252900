import {BoxProps} from '@mui/material'

type NonReadonlyArray<T> = T extends ReadonlyArray<any> ? never : T

export type SxProps = NonNullable<NonReadonlyArray<BoxProps['sx']>>

export type SxMap<K extends string = string> = Record<K, SxProps>

function createStyleSheet<K extends string, T extends SxMap<K>>(data: T): T {
  return data
}

export default createStyleSheet
