import {Box, Button, Dropdown, DropdownItem, Input, Modal} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useCompany} from 'src/contexts/CompanyContext'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import useClients from '../../hooks/useClients'

export type CreateListModalProps = {
  isOpen: boolean
  closeModal: () => void
}

function CreateListModal(props: CreateListModalProps) {
  const {isOpen, closeModal} = props
  const [name, setName] = useState('')
  const [listSelected, setListSelected] = useState<DropdownItem>({} as DropdownItem)
  const [companySelected, setCompanySelected] = useState<DropdownItem>({} as DropdownItem)
  const [clientSelected, setClientSelected] = useState<DropdownItem>({} as DropdownItem)
  const {enqueueSnackbar} = useSnackbar()
  const {companies} = useCompany()
  const {clients: clientItems, refresh} = useClients()
  const hasFacialCreatePermission = ValidatePermissions('face:create')
  const hasLicensePlateCreatePermission = ValidatePermissions('lpr:create')
  const listTypeItems = [
    {id: 'face', label: 'Facial'},
    {id: 'lpr', label: 'Placas'},
  ]
  const companyItems = companies.map((company) => ({id: `${company.id}`, label: company.name}))

  if (!hasFacialCreatePermission) {
    listTypeItems.shift()
  }

  if (!hasLicensePlateCreatePermission) {
    listTypeItems.pop()
  }

  useEffect(() => {
    if (companySelected.id) {
      refresh(+companySelected.id)
    }
  }, [companySelected])

  function clearFields() {
    setName('')
    setListSelected({} as DropdownItem)
    setCompanySelected({} as DropdownItem)
    setClientSelected({} as DropdownItem)
  }

  function handleClose() {
    clearFields()
    closeModal()
  }

  async function handleSaveList() {
    try {
      const body = {
        name,
        actionId: 2,
        companyId: +companySelected.id,
        clientId: +clientSelected.id || undefined,
      }
      if (listSelected.label === 'Facial') {
        await api.facialRecognition.createFacialList(body)
      } else {
        await api.lpr.createLprList(body)
      }
      enqueueSnackbar('Lista criada com sucesso', {variant: 'success'})
      handleClose()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao criar lista')
    }
  }

  const onSelectChangeCompany = (e: DropdownItem) => {
    if (e.id === companySelected.id) return
    setClientSelected({} as DropdownItem)
    setCompanySelected(e)
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => handleClose()}
      backgroundColor='white'
      width='600px'
      height='500px'
      padding='16px'
      paddingBottom='16px'
      paddingLeft='16px'
      paddingRight='16px'
      paddingTop='16px'>
      <Box position='absolute' right='14px' top='14px'>
        <Icon src={CloseIcon} cursor='pointer' onClick={() => handleClose()} />
      </Box>
      <Box paddingBottom='20px' fontWeight='600' fontSize='22px' color='black'>
        Nova Lista
      </Box>
      <Box width='100%' display='flex' direction='column' justify='center' align='center' rowGap='20px'>
        <Input.Root width='97%' label='Nome da lista' onChange={(e) => setName(e.target.value)} maxLength={99} />
        <Dropdown width='97%' items={listTypeItems} label='Tipo de lista' onChangeSelected={(e) => setListSelected(e)} />
        <Dropdown width='97%' selected={companySelected} items={companyItems || []} label='Empresa' onChangeSelected={(e) => onSelectChangeCompany(e)} />
        <Dropdown
          width='97%'
          selected={clientSelected}
          items={companySelected.id ? clientItems : []}
          label='Cliente (Opcional)'
          onChangeSelected={(e) => setClientSelected(e)}
        />
      </Box>
      <Box display='flex' direction='row' columnGap='10px' align='center' justify='flex-end' paddingTop='20px' paddingRight='8px'>
        <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => handleClose()}>
          Cancelar
        </Button>
        <Button
          fontSize='12px'
          height='40px'
          width='150px'
          disabledTextColor='#8E8E8E'
          disabled={!name || !listSelected || !companySelected}
          onClick={() => handleSaveList()}>
          Salvar
        </Button>
      </Box>
    </Modal>
  )
}

export default CreateListModal
