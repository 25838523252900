import {useSnackbar} from 'notistack'
import React, {createContext, Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {AreaItem, ObjectItem} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import {Pos} from 'src/common/types/generics/ImageDetections'
import {useCompany} from 'src/contexts/CompanyContext'
import mkKey from 'src/utilities/mkKey'
import {isNumber} from 'src/utilities/regexValidation'
import {useDebounce} from 'usehooks-ts'
import useLoading from '../../../hooks/useLoading'
import api, {Camera, CameraObject, ConnectionTimeMultiple, InterestArea, Server} from '../../../services/api'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'

type Page = {
  page: number
  pageSize: number
}
type AddAreaItem = {
  name: string
  points: Pos[]
}
type AreaData = AreaItem<{
  area?: InterestArea
  updated?: boolean
  adding?: boolean
}>
type ObjectData = ObjectItem<{
  selected?: boolean
  object?: CameraObject
}>
export type LatLong = {
  longitude?: number
  latitude?: number
}

export const initalCameraFilterStatus = ['Armado', 'Desarmado', 'Fora do horário']
export const initalCameraFilterSituation = ['Habilitada', 'Desabilitada']

type CamerasContextData = {
  loadingCameraStatus: boolean
  toggleStatusFilter: (status: string) => void
  toggleSituationFilter: (status: string) => void
  cameraStatusFilter?: string[]
  cameraSituationFilter?: string
  showOptionsButton: boolean
  deleteCameras: () => Promise<void>
  setLoadingCameraStatus: Dispatch<SetStateAction<boolean>>
  cameraStatusFilterDebounced: any
  setCamerasSearch: (value: string) => void
  camerasSearch: string
  loadingCameras: boolean
  setLoadingCameras: (isloading: boolean) => void
  camerasTotalCount: number
  camerasPageChange: (page: Partial<Page>) => void
  selectedCamerasIds: number[]
  setSelectedCamerasIds: (ids: number[]) => void
  camerasPage: Page
  setCamerasPage: (cameraPage: number) => void
  setCamerasPageSize: (pageSize: number) => void
  cameras: Camera[]
  reloadCameras: () => void
  tab: number
  setTab: (newTab: number) => void
  openCameraModal: (camera: Camera) => Promise<void> // contact modal
  objectItems: ObjectItem[]
  toggleObject: (key: string) => void
  toggleAllObjects: () => void
  areaItems: AreaItem[]
  addInterestArea: (area: AddAreaItem) => void
  deleteInterestArea: (area: AreaItem) => void
  editInterestArea: (area: AreaItem) => void
  unboundLprBlacklist: boolean
  setUnboundLprBlacklist: (unboundLprBlacklist: boolean) => void
  listBoolean: boolean[]
  listIds: number[]
  selectedPlatesIds: number[]
  setSelectedPlatesIds: (ids: number[]) => void
  showOptionsButtonLPR: boolean
  namePlate: string
  setNamePlate: (name: string) => void
  latLong: LatLong
  setLatLong: (LatLong: LatLong) => void
  searchFilterSelected: string
  setSearchFilterSelected: (searchFilter: string) => void
  serverRTSP: Server
  setServerRTSP: (serverHost: Server) => void
  isEditing: boolean
  setIsEditing: (isEdit: boolean) => void
  partition: string
  setPartition: (partition: string) => void
  timeData: {[x: string]: number}
  setCamerasStatus: (option: boolean) => Promise<void>
}

function tryParseString(value: any) {
  try {
    return JSON.parse(value)
  } catch (error) {
    return null
  }
}

function loadCamerasSearchValue() {
  const savedState = localStorage.getItem('navigationState')
  if (!savedState) return ''

  const state = tryParseString(savedState)
  return state.prevPage === 'register/devices' ? state.query ?? '' : ''
}

function loadItemSearchValue() {
  const savedState = localStorage.getItem('navigationState')
  if (!savedState) return 'Nome do Dispositivo'

  const state = tryParseString(savedState)
  return state.prevPage === 'register/devices' ? state.itemSearch ?? 'Nome do Dispositivo' : 'Nome do Dispositivo'
}

export const CamerasContext = createContext({} as CamerasContextData)
const CamerasProvider = ({children}: React.PropsWithChildren<any>) => {
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanies} = useCompany()
  const [loadingCameras, setLoadingCameras] = useState(true)
  const [camerasSearch, setCamerasSearch] = useState(loadCamerasSearchValue())
  const [camerasPage, _setCamerasPage] = useState({page: 1, pageSize: 20})
  const [searchFilterSelected, setSearchFilterSelected] = useState<string>(loadItemSearchValue())

  const [camerasTotalCount, setCamerasTotalCount] = useState(0)
  const [selectedCamerasIds, setSelectedCamerasIds] = useState<number[]>([])
  const [cameras, setCameras] = useState<Camera[]>([])
  const [reloadCamerasCount, setReloadCamerasCount] = useState(0)
  const [chosenCameraStatusFilter, setChosenCameraStatusFilter] = useState<string[]>([])
  const cameraStatusFilterDebounced = useDebounce(chosenCameraStatusFilter, 1000)

  const [chosenCameraFilterSituation, setChosenCameraFilterSituation] = useState<boolean>()
  const cameraSituationFilterDebounced = useDebounce(chosenCameraFilterSituation, 1000)

  const [loadingCameraStatus, setLoadingCameraStatus] = useState(false)
  const [objectItems, setObjectItems] = useState<ObjectData[]>([])
  const [areaItems, setAreaItems] = useState<AreaData[]>([])
  const [areaItemsDeleting, setAreaItemsDeleting] = useState<AreaData[]>([])
  const [tab, setTab] = useState(0)
  const {loading, applyLoading} = useLoading(false)
  const [unboundLprBlacklist, setUnboundLprBlacklist] = useState<boolean>(false)
  const [latLong, setLatLong] = useState<LatLong>({
    latitude: undefined,
    longitude: undefined,
  })
  const [serverRTSP, setServerRTSP] = useState<Server>({
    id: -1,
    ip: '',
    name: '',
  })
  const listBoolean: boolean[] = []
  const listIds: number[] = []
  const [selectedPlatesIds, setSelectedPlatesIds] = useState<number[]>([])
  const showOptionsButtonLPR = selectedPlatesIds.length > 0
  const camerasSearchDebounce = useDebounce(camerasSearch)
  const [namePlate, setNamePlate] = useState<string>('')
  const [isEditing, setIsEditing] = useState(false)
  const [partition, setPartition] = useState<string>('')
  const [timeData, setTimeData] = useState<{[x: string]: number}>({})
  const location = useLocation()
  const navigate = useNavigate()
  const blockLoadingCameras = useRef(false)
  const blockLoadingConnectionTime = useRef(false)

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('client')) {
      const clientId = parseInt(params.get('client')!)
      setLoadingCameras(true)
      setSearchFilterSelected('ID do Cliente')
      setCamerasSearch(clientId.toString())
    }
  }, [location, enqueueSnackbar])

  function setCamerasPage(page: number) {
    _setCamerasPage((lastPage) => ({...lastPage, page}))
  }

  function setCamerasPageSize(pageSize: number) {
    _setCamerasPage((lastPage) => ({...lastPage, pageSize}))
  }

  function toggleObject(key: string) {
    setObjectItems(
      objectItems.map((it) => {
        if (it.key === key) {
          return {...it, selected: !it.selected}
        } else {
          return it
        }
      }),
    )
  }

  function toggleAllObjects() {
    const selected = !objectItems.every((it) => it.selected)
    setObjectItems(objectItems.map((it) => ({...it, selected})))
  }

  function addInterestArea(areaItem: AddAreaItem) {
    setAreaItems(
      areaItems.concat([
        {
          key: mkKey(),
          label: areaItem.name,
          points: areaItem.points,
          data: {adding: true},
        },
      ]),
    )
  }

  function deleteInterestArea(areaItem: AreaData) {
    setAreaItems(areaItems.filter((it) => it.key !== areaItem.key))
    if (areaItem.data?.area) {
      setAreaItemsDeleting(areaItemsDeleting.concat(areaItem))
    }
  }

  function editInterestArea(areaItem: AreaData) {
    const editAreaItem = {
      ...areaItem,
      data: {...areaItem.data, updated: true},
    }
    setAreaItems(areaItems.map((it) => (it.key === areaItem.key ? editAreaItem : it)))
  }

  async function deleteCameras() {
    if (loading) return
    if (selectedCamerasIds.length === 0) throw new Error('unreachable')
    try {
      await applyLoading(async () => {
        await api.camera.deleteMany(selectedCamerasIds)
        setSelectedCamerasIds([])
      })
      enqueueSnackbar(`Dispositivos removidos com sucesso`, {
        variant: 'success',
      })
      reloadCameras()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover os dispositivos integrados')
    }
  }

  async function setCamerasStatus(option: boolean) {
    if (loading) return
    if (selectedCamerasIds.length === 0) throw new Error('unreachable')
    try {
      await applyLoading(async () => {
        return await api.camera.setCamerasStatus(selectedCamerasIds, option)
      })
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao alterar o status dos dispositivos integrados')
    } finally {
      reloadCameras()
    }
  }

  async function openCameraModal(camera: Camera) {
    if (loadingCameras) return
    if (!camera) {
      throw new Error('alarm.camera.clientId must exist')
    }
    navigate(`/registers/devices/${camera.id}`)
  }

  const loadSnapshot = useCallback(async (ids: Array<number>) => {
    if (ids.length === 0) return
    if (blockLoadingConnectionTime.current) return
    blockLoadingConnectionTime.current = true
    api.camera.getConnetionTimes(ids).then((response) => {
      if (!response.body) return
      const reader = response.body.getReader()
      return new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({done, value}) => {
              if (done) return controller.close()
              const splitedChunk = new TextDecoder().decode(value).split('\n')
              const connections = splitedChunk.filter((chunk) => chunk !== '').map((chunk) => JSON.parse(chunk))
              connections.forEach((connection: ConnectionTimeMultiple) => {
                if (Object.keys(connection).includes('id')) timeData[String(connection.id)] = Number(connection.connectionTime) / 1000
              })
              setTimeData(Object.assign({}, timeData))
              push()
            })
          }
          push()
        },
      })
    })
    blockLoadingConnectionTime.current = false
  }, [])

  const loadCameras = useCallback(
    async (
      camerasPage: Page,
      searchFilterSelected: string,
      selectedCompanies: Array<number>,
      camerasSearchDebounce: any,
      cameraStatusFilterDebounced: string[] | undefined,
      cameraSituationFilterDebounced: boolean | undefined,
    ) => {
      if (blockLoadingCameras.current) return
      blockLoadingCameras.current = true
      setLoadingCameras(true)
      try {
        const response = (
          await api.camera.getMany({
            page: camerasPage.page === 0 ? 1 : camerasPage.page,
            pageSize: camerasPage.pageSize,
            filter: {
              ids: searchFilterSelected === 'ID' && isNumber(camerasSearchDebounce) ? [camerasSearchDebounce] : undefined,
              companyIds: selectedCompanies,
              cameraName: searchFilterSelected === 'Nome do Dispositivo' ? [camerasSearchDebounce] : undefined,
              clientIds: searchFilterSelected === 'ID do Cliente' && isNumber(camerasSearchDebounce) ? [Number(camerasSearchDebounce)] : undefined,
              statuses: cameraStatusFilterDebounced ? cameraStatusFilterDebounced : undefined,
              isOnline: cameraSituationFilterDebounced,
            },
            includes: ['server', 'status', 'configuration', 'client', 'addressInfo'],
            search: {
              clientName: searchFilterSelected === 'Nome do Cliente' ? camerasSearchDebounce : undefined,
              serverName: searchFilterSelected === 'Nome do Servidor' ? camerasSearchDebounce : undefined,
            },
          })
        ).data
        setCamerasTotalCount(response.data.totalElements)
        setCameras(response.data.entities)
        const ids = response.data.entities.map((it) => it.id)
        if (ids.length > 0) {
          loadSnapshot(ids)
        }
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar dispositivos integrados')
      } finally {
        setLoadingCameras(false)
        blockLoadingCameras.current = false
      }
    },
    [enqueueSnackbar, loadSnapshot],
  )

  useEffect(() => {
    setCamerasPage(1)
  }, [camerasSearchDebounce, cameraSituationFilterDebounced, cameraStatusFilterDebounced])

  useEffect(() => {
    loadCameras(camerasPage, searchFilterSelected, selectedCompanies, camerasSearchDebounce, cameraStatusFilterDebounced, cameraSituationFilterDebounced)
  }, [camerasPage, reloadCamerasCount, selectedCompanies, loadCameras])

  const reloadCameras = () => {
    if (loadingCameras) return
    setLoadingCameras(true)
    setReloadCamerasCount((x) => x + 1)
  }

  const camerasPageChange = (page: Partial<Page>) => {
    _setCamerasPage((lastPage) => ({...lastPage, ...page}))
  }
  const showOptionsButton = selectedCamerasIds.length > 1

  function toggleStatusFilter(status: string) {
    const isChecked = chosenCameraStatusFilter?.includes(status.toUpperCase())
    if (isChecked) setChosenCameraStatusFilter(chosenCameraStatusFilter?.filter((it) => it.toLowerCase() !== status.toLowerCase()))
    else setChosenCameraStatusFilter((prev) => [...prev, status.toUpperCase()])
  }

  function toggleSituationFilter(situation: string) {
    const filterOnLineCamera = situation === 'Habilitada'
    if (chosenCameraFilterSituation !== undefined) setChosenCameraFilterSituation(undefined)
    else setChosenCameraFilterSituation(filterOnLineCamera)
  }

  return (
    <CamerasContext.Provider
      value={{
        setLoadingCameraStatus,
        toggleStatusFilter,
        toggleSituationFilter,
        camerasSearch,
        setCamerasSearch,
        selectedCamerasIds,
        setSelectedCamerasIds,
        showOptionsButton,
        deleteCameras,
        loadingCameras,
        camerasTotalCount,
        camerasPageChange,
        setCamerasPage,
        setCamerasPageSize,
        camerasPage,
        reloadCameras,
        cameras,
        openCameraModal,
        tab,
        setTab,
        objectItems,
        toggleObject,
        toggleAllObjects,
        areaItems,
        addInterestArea,
        deleteInterestArea,
        editInterestArea,
        unboundLprBlacklist,
        setUnboundLprBlacklist,
        listBoolean,
        listIds,
        selectedPlatesIds,
        cameraStatusFilterDebounced,
        setSelectedPlatesIds,
        showOptionsButtonLPR,
        namePlate,
        setNamePlate,
        latLong,
        setLatLong,
        searchFilterSelected,
        setSearchFilterSelected,
        serverRTSP,
        setServerRTSP,
        isEditing,
        setIsEditing,
        setLoadingCameras,
        partition,
        setPartition,
        loadingCameraStatus,
        timeData,
        setCamerasStatus,
      }}>
      {children}
    </CamerasContext.Provider>
  )
}

export default CamerasProvider

export function useCameraContext() {
  return useContext(CamerasContext)
}
