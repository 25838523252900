import styled from 'styled-components'

export const TitleError = styled.p`
  color: var(--Gray-25, #fcfcfd);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const ErrorContent = styled.p`
  color: var(--Gray-25, #fcfcfd);
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin-top: 1.5em;
  max-width: 499px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const Logo = styled.img`
  width: 150px;
  position: absolute;
  top: 2em;
  left: 2em;
`

export const ContainerButtons = styled.div`
  margin-top: 4em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.7em;
  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`

export const ContainerRedirect = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const Button = styled.button`
  width: 250px;
  background: transparent;
  color: white;
  height: 47.5px;
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #009eff;
  border-radius: 10px;
`

export const BlueButton = styled.button`
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #009eff;
  color: white;
  font-size: 14px;
  background-color: #009eff;
  width: 250px;
  height: 47.5px;
  transition: 0.4s;
  font-weight: bold;
  :hover {
    background-color: #00639f;
  }
  :disabled {
    opacity: 0.4;
    cursor: default;
    :hover {
      background-color: #009eff;
    }
  }
`

export const ContainerErrorSSO = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: black;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
export const ContainerImageErrorSSO = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    display: none;
  }
`
export const ContainerInfosErrorSSO = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
