import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {IMaskInput} from 'react-imask'
import {BtnClose} from 'src/components/BtnClose'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api, {Company} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type CompanyRegisterModalProps = {
  isCreate: boolean
  openModal: boolean
  closeModal: () => void
  company: Company
  setCompany: (company: Company) => void
  loadCompanies: Function
  setIdCompany: (id: number) => void
  setSaveModalCompany: (idCompany: number) => void
}

function CompanyRegisterModal(props: CompanyRegisterModalProps) {
  const {isCreate, openModal, closeModal, company, setCompany, loadCompanies, setIdCompany, setSaveModalCompany} = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {enqueueSnackbar} = useSnackbar()
  const {setUpdateExpirationDate} = useApp()

  useEffect(() => {
    if (isCreate) setCompany({...company, name: '', document: '', expirationDate: moment(moment().add(15, 'day').format()).format('YYYY-MM-DD')})
  }, [])

  async function createCompany() {
    setIsLoading(true)
    try {
      const response = await api.company.create({
        name: company.name,
        document: company.document,
        expirationDate: moment(company.expirationDate).format('YYYY-MM-DD').concat('T23:59:59Z'),
      })
      enqueueSnackbar('Empresa criada com sucesso', {
        variant: 'success',
      })
      localStorage.setItem('needsRefresh', 'true')
      setIdCompany(response.data.data.id!)
      setSaveModalCompany(response.data.data.id!)
      setUpdateExpirationDate((prev) => !prev)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar empresa')
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  async function updateCompany() {
    setIsLoading(true)
    try {
      await api.company.update({
        id: company.id,
        name: company.name,
        document: company.document,
        expirationDate: company.expirationDate ? moment(company.expirationDate).format('YYYY-MM-DD').concat('T23:59:59Z') : undefined,
      })
      enqueueSnackbar('Empresa atualizada com sucesso', {
        variant: 'success',
      })
      loadCompanies()
      setUpdateExpirationDate((prev) => !prev)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar empresa')
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  return (
    <Dialog open={openModal} onClose={closeModal} scroll='paper' maxWidth='sm' fullWidth>
      <DialogTitle sx={styles.dialogTitle}>{isCreate ? 'Adicionar' : 'Editar'} Empresa</DialogTitle>
      <BtnClose onClose={closeModal} />
      <DialogContent>
        <Grid
          container
          spacing={1}
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <Grid item xs={12}>
            <TextField
              label='Nome'
              variant='outlined'
              type={'text'}
              value={company.name}
              autoFocus={true}
              onChange={(e) => setCompany({...company, name: e.target.value})}
              sx={styles.input}
            />
          </Grid>
          <Grid item xs={12}>
            <IMaskInput
              mask={company.document.length <= 14 ? '###.###.###-##' : '##.###.###/####-##'}
              definitions={{'#': /[0-9]/}}
              id='cpnj-input'
              value={company.document}
              label='Documento'
              onAccept={(e) => {
                setCompany({...company, document: e})
              }}
              style={styles.cnpjInput}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label='Data de Expiração'
              variant='outlined'
              type={'date'}
              value={moment(company.expirationDate).format('YYYY-MM-DD')}
              InputLabelProps={{shrink: true}}
              sx={styles.date}
              onChange={(e) => {
                setCompany({
                  ...company,
                  expirationDate: e.target.value,
                })
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box sx={{marginBottom: 2.2, marginTop: 2}}>
          <Button onClick={closeModal} sx={styles.cancelButton}>
            Cancelar
          </Button>
          <LoadingButton
            variant='contained'
            sx={styles.confirmButton}
            onClick={() => {
              if (isCreate) createCompany()
              else updateCompany()
            }}
            loading={isLoading}
            disabled={company.name === '' || company.document === ''}>
            Salvar
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  container: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  dialogTitle: {paddingTop: 3, color: '#353535'},
  input: {
    marginBottom: '20px',
    width: '100%',
  },
  cnpjInput: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '16px',
    justifyContent: 'center',
    borderRadius: '6px',
    height: '56px',
    padding: '10px',
    border: '#C6C1C1 solid 1px',
    '&:hover': {
      border: '#00639f solid 1px',
    },
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '17px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: '20px',
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  formContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    padding: '0px',
    width: '100%',
  },
  formType: {
    width: '50%',
    flexDirection: 'row',
  },
  date: {
    width: '100%',
  },
  closeButton: {
    p: 1,
    minWidth: 0,
    height: 1,
    fontWeight: 'bold',
  },
})

export default CompanyRegisterModal
