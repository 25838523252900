import createStyleSheet from 'src/utilities/createStyleSheet'

export const cameraConfigReceiveEventStyles = createStyleSheet({
  partitionItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: '0.25em',
  },
  button: {
    height: '3em',
    width: '12em',
    borderRadius: '5px',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '0.9rem',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  editButton: {
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  cancelButton: {
    border: '1px solid',
    marginRight: '1em',
  },
  editIcon: {
    height: '25px',
    width: '25px',
  },
  gridPaper: {
    border: '1px solid #E0E0E0',
    borderRadius: 2,
    padding: 1.5,
  },
})
