import {Box} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import BrainBoxConfigTabsContent from './components/BrainBoxConfigTabsContent'
import BrainBoxConfigTabsHeader from './components/BrainBoxConfigTabsHeader'

type Params = {
  id: string
}

function BrainBoxConfig() {
  const [tab, setTab] = useState<number>(0)
  const {id: maybeId} = useParams<Params>()
  const [brainBoxOne, setBrainBoxOne] = useState<BrainBox>({
    id: Number(maybeId),
    client: {
      id: -1,
      name: '',
      isDeleted: false,
      companyId: -1,
    },
    host: '',
    port: '',
    isArmed: false,
    isOnline: false,
    name: '',
    outputDevices: [
      {
        id: -1,
        brainBoxId: -1,
        channel: -1,
      },
    ],
  })
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()
  const {sidebarTitle, setSidebarTitle} = useApp()
  const blockBrainboxRequests = useRef(false)

  useEffect(() => {
    const id = Number(maybeId)
    if (!id || isNaN(id)) {
      navigate('registers/brainbox')
    } else {
      if (brainBoxOne.name) {
        const title = `${sidebarTitle} > ${brainBoxOne && brainBoxOne.name}`
        setSidebarTitle(title)
      }
    }
  }, [brainBoxOne])

  useEffect(() => {
    if (!maybeId) return
    getOneBrainbox()
  }, [maybeId])

  async function getOneBrainbox() {
    try {
      if (blockBrainboxRequests.current) return
      blockBrainboxRequests.current = true
      const response = await api.brainBox.getAll({
        includes: ['client'],
        filter: {
          ids: [Number(maybeId)],
        },
      })

      setBrainBoxOne(response.data.data.entities[0])
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar Brain Box')
    } finally {
      blockBrainboxRequests.current = false
    }
  }

  async function updateSituation(diffBrainBoxOne?: BrainBox) {
    if (!diffBrainBoxOne) return
    try {
      await api.brainBox.updateBrainBoxStatus(Number(diffBrainBoxOne.id), {
        name: diffBrainBoxOne.name,
        host: diffBrainBoxOne.host,
        port: diffBrainBoxOne.port === '' ? null : Number(diffBrainBoxOne.port),
      })
      enqueueSnackbar('Brain Box atualizada com sucesso', {
        variant: 'success',
        preventDuplicate: true,
      })
      getOneBrainbox()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar Brain Box')
    }
  }

  return (
    <Box sx={styles.paper}>
      <Box>
        <BrainBoxConfigTabsHeader tab={tab} setTab={setTab} />
      </Box>
      <Box>
        <BrainBoxConfigTabsContent
          tab={tab}
          brainBoxOne={brainBoxOne}
          setBrainBoxOne={setBrainBoxOne}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          updateSituation={updateSituation}
        />
      </Box>
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: 1.5,
    height: '83vh',
  },
})

export default BrainBoxConfig
