import styled from 'styled-components'

export const ButtonContainerInner = styled.div`
  display: flex;
  flex-direction: row;
  font-family: 'Inter';
  padding: 10px 20px;
  justify-content: center;
  gap: 8px;
`

export const ButtonContainer = styled.div<{minHeight: string; minWidth: string}>`
  display: flex;
  flex-direction: row;
  font-family: 'Inter';
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;

  min-height: ${(props) => props.minHeight};
  min-width: ${(props) => props.minWidth};

  &:hover {
    transition: 0.3s;
    background-color: rgba(0, 158, 255, 0.1);
    cursor: pointer;
  }
`

export const ButtonText = styled.div<{disabled: boolean}>`
  background-color: transparent;
  color: ${(props) => (props.disabled ? '#B9B9B9' : '#009EFF')};
  text-transform: none;
  font-weight: 500;
  font-size: 16px;
`
