import {Box, Button} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useAuth} from 'src/contexts/AuthContext'
import api, {Company, CompanyCredentials} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import ConfirmCredentialsDialog from './components/ConfirmCredentialsDialog'
import CredentialsDialog from './components/CredentialsDialog'

export function SettingsCredentials() {
  const [company, setCompany] = useState<Company>({id: 0, name: '-', document: '-', apiUser: '-'})
  const [openConfirmCredentialsDialog, setOpenConfirmCredentialsDialog] = useState(false)
  const [credentials, setCredentials] = useState<CompanyCredentials>({apiUser: '', apiPassword: ''})
  const [openCredentialsDialog, setOpenCredentialsDialog] = useState<boolean>(false)

  const {enqueueSnackbar} = useSnackbar()
  const {user} = useAuth()
  const hasPermissionCompaniesUpdate = ValidatePermissions('companies:update')

  async function loadCompany() {
    try {
      const response = await api.company.getMany({
        filter: {
          ids: user.authorizedCompanies,
        },
      })
      setCompany(response.data.data.entities[0])
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar credenciais')
    }
  }

  useEffect(() => {
    loadCompany()
  }, [])

  return (
    <Box sx={styles.paper}>
      <Box sx={styles.sectionUser}>
        <label style={styles.lableTitle}>Usuário</label>
        <p>{company.apiUser}</p>
      </Box>
      <Box sx={styles.sectionPassword}>
        <label style={styles.lableTitle}>Senha</label>
        <p>************</p>
      </Box>
      {hasPermissionCompaniesUpdate && (
        <Box sx={styles.sectionButton}>
          <Box sx={styles.credentialsBox}>
            <Button onClick={() => setOpenConfirmCredentialsDialog(true)} sx={[styles.button, styles.credentialsButton]}>
              Renovar credenciais
            </Button>
          </Box>
        </Box>
      )}
      {openConfirmCredentialsDialog && (
        <>
          {user?.role !== 'admin' ? (
            <Box sx={{color: 'red', fontSize: '13px'}}>*Apenas administradores possuem permissão para renovar credenciais*</Box>
          ) : (
            <ConfirmCredentialsDialog
              open={openConfirmCredentialsDialog}
              setOpen={setOpenConfirmCredentialsDialog}
              setOpenCredentialsDialog={setOpenCredentialsDialog}
              setCredentials={setCredentials}
            />
          )}
        </>
      )}
      {openCredentialsDialog && (
        <CredentialsDialog
          open={openCredentialsDialog}
          setOpen={setOpenCredentialsDialog}
          credentials={credentials}
          onClickConfirm={() => {
            setOpenCredentialsDialog(false)
            loadCompany()
          }}
        />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  sectionUser: {
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  sectionPassword: {
    fontFamily: 'Inter',
  },
  sectionButton: {
    fontFamily: 'Inter',
    marginBottom: '1.2rem',
  },
  lableTitle: {
    fontSize: '1rem',
    color: '#6f6f6f',
    fontWeight: 'bold',
  },
  button: {
    borderRadius: '5px',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '0.9rem',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  credentialsButton: {
    height: '3em',
    width: '15em',
    border: '1px solid',
  },
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: '1.5625em 0.9375em 0.3125em 1.375em',
  },
  credentialsBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
})
