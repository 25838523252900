import {Box, CircularProgress, Grid, Typography} from '@mui/material'

import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {formatNumberSize} from 'src/utilities/safeFormatBigNumber'
import api from '../../../services/api'
import {NumberFormat} from '../../../types'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'

import ReactApexChart from 'react-apexcharts'
import {useCompany} from 'src/contexts/CompanyContext'
import {CameraReports} from 'src/services/api/endpoints/ReportsEndpoint'

type CameraStatusGraphic = {
  armed: number
  desarmed: number
  outOfHour: number
}

function DashboardCameras() {
  const [loading, setLoading] = useState<boolean>(true)
  const [quantityOnOff, setQuantityOnOff] = useState<CameraReports | null>(null)
  const [cameraStatus, setCameraStatus] = useState<CameraStatus>({
    armed: {short: '-', full: '-'},
    desarmed: {short: '-', full: '-'},
    outOfHour: {short: '-', full: '-'},
  })
  const [cameraStatusGraphic, setCameraStatusGraphic] = useState<CameraStatusGraphic>({
    armed: -1,
    desarmed: -1,
    outOfHour: -1,
  })
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanyId} = useCompany()
  const blockCameraRequests = useRef(false)

  const getData = async () => {
    if (!selectedCompanyId) return
    if (blockCameraRequests.current) return
    blockCameraRequests.current = true
    const response = await api.reports.camerasStats({filter: {companyId: selectedCompanyId}})

    const response2 = await api.reports.camerasReports({filter: {companyId: selectedCompanyId}})
    setQuantityOnOff(response2.data.data)

    setCameraStatus({
      armed: formatNumberSize(response.data.data.armed.count),
      desarmed: formatNumberSize(response.data.data.desarmed.count),
      outOfHour: formatNumberSize(response.data.data.outOfHour.count),
    })

    setCameraStatusGraphic({
      armed: response.data.data.armed.count,
      desarmed: response.data.data.desarmed.count,
      outOfHour: response.data.data.outOfHour.count,
    })
    blockCameraRequests.current = false
  }

  useEffect(() => {
    getData()
      .then(() => {
        setLoading(false)
      })
      .catch((error) => {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar câmeras integradas')
        setLoading(false)
      })
  }, [selectedCompanyId])

  const opts: ApexCharts.ApexOptions = {
    series: [cameraStatusGraphic.armed, cameraStatusGraphic.desarmed, cameraStatusGraphic.outOfHour],

    chart: {
      type: 'donut',
      width: '90%',
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 1920,
        options: {
          legend: {
            offsetX: 15,
          },
        },
      },
    ],

    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: '75%',
        },
        offsetY: 5,
      },
    },
    colors: [blue, orange, yellow],
    labels: ['Armadas', 'Desarmadas', 'Fora do Horário'],
    legend: {
      show: false,
    },
  }

  return (
    <Grid container sx={loading ? styles.grid : styles.grid2}>
      <Grid item container padding={1.5} paddingTop={1} sx={{alignItems: 'left', display: 'flex'}}>
        <Grid item padding={1.5} sx={{alignItems: 'left', display: 'flex', height: '30px'}}>
          <Typography style={styles.title}>Dispositivos</Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} paddingTop={4}>
        {quantityOnOff?.online !== 0 ? (
          <>
            <Grid item lg={7} xl={6} height='317px' sx={{display: 'flex', justifyContent: 'right'}}>
              {!loading ? (
                <Box>
                  <ReactApexChart options={opts} series={opts.series} type='donut' height={270} />
                </Box>
              ) : (
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '325px'}}>
                  <CircularProgress size={'3em'} />
                </Box>
              )}
            </Grid>
            <Grid item lg={0.2} xl={1.7}></Grid>
          </>
        ) : (
          <Grid item lg={0.7} xl={0.7} height='317px'></Grid>
        )}
        <Grid container item xl={4} lg={4.8} sx={{display: 'flex', flexDirection: 'column'}}>
          <Grid item>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontWeight: 'bold', color: blue, fontSize: '1.8em', lineHeight: 0.9, marginTop: 2}}>
              {cameraStatus.armed.short}
            </Typography>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontSize: '0.770em'}}>Dispositivos Armados</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontWeight: 'bold', color: orange, fontSize: '1.8em', lineHeight: 0.9, marginTop: 2}}>
              {cameraStatus.desarmed.short}
            </Typography>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontSize: '0.770em'}}>Dispositivos Desarmados</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontWeight: 'bold', color: yellow, fontSize: '1.8em', lineHeight: 0.9, marginTop: 2}}>
              {cameraStatus.outOfHour.short}
            </Typography>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontSize: '0.770em'}}>Dispositivos Fora do Horário</Typography>
          </Grid>
          <Grid item>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontWeight: 'bold', color: red, fontSize: '1.8em', lineHeight: 0.9, marginTop: 2}}>
              {quantityOnOff?.offline}
            </Typography>
            <Typography sx={{fontFamily: "'Inter', sans-serif", fontSize: '0.770em'}}>Dispositivos Offline</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

type CameraStatus = {
  armed: NumberFormat
  desarmed: NumberFormat
  outOfHour: NumberFormat
}

const blue = '#009eff'
const red = '#EA0002'
const yellow = '#FFD700'
const orange = '#FF8C00'

const styles = createStyleSheet({
  grid: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
  grid2: {
    borderRadius: '8px',
    background: 'white',
    justifyContent: 'center',
  },
  title: {
    marginLeft: '0.73em',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '1.05em',
    lineHeight: '2',
    textAlign: 'center',
    '@media (maxWidth:1540px)': {
      marginLeft: '0.23em',
    },
    paddingTop: 12,
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
  cameraArmed: {
    backgroundColor: blue,
  },
  cameraUnarmed: {
    backgroundColor: orange,
  },
  cameraOutOfTime: {
    backgroundColor: yellow,
  },
})

export default DashboardCameras
