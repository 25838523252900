import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import SettingsIcon from '@mui/icons-material/Settings'
import {Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import actionsObject from 'src/images/actionsObject.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'

type LprTooltipEvent = 'add-to-list' | 'see-vehicle-transity'

type LprInfosProps = {
  oneLpr: number
  onClick?: (event: LprTooltipEvent, oneLpr: number) => void
  cameraId: number
}

const MenuMoreInformations = ({oneLpr, onClick, cameraId}: LprInfosProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  return (
    <>
      <Tooltip title='Ações' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('add-to-list', oneLpr)
          }}>
          <PlaylistAddIcon fontSize='small' sx={{width: '1.5em'}} />
          Adicionar a uma lista
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('see-vehicle-transity', oneLpr)
          }}>
          <DirectionsCarFilledIcon fontSize='small' sx={{width: '1.5em'}} />
          Ver passagens do veículo
        </MenuItem>
        <MenuItem sx={styles.menuInfos} onClick={() => navigate(`/registers/devices/${cameraId}`)}>
          <SettingsIcon fontSize='small' sx={{width: '1.5em'}} />
          Ir para configuração da câmera
        </MenuItem>
      </Menu>
    </>
  )
}

export default MenuMoreInformations

const styles = createStyleSheet({
  menuInfos: {
    padding: 1,
    fontSize: '12px',
  },
})
