import {CardCVVContainer, InfoIcon} from './CardCVVBase'
import {useState} from 'react'
import {Typography} from '@viptech/react-components'
import CardCVVIcon from 'src/images/cardCVVIcon.svg'

function staticTooltipContent() {
  return (
    <CardCVVContainer>
      <Typography variant='span'>
        O código de segurança de seu cartão (CVV) é o número de 3 ou 4 dígitos localizado na frente ou no verso da maioria dos cartões.
      </Typography>
      <img src={CardCVVIcon} alt='CVV' />
    </CardCVVContainer>
  )
}

function CardCVV() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <InfoIcon onMouseEnter={() => setShowModal(true)} onMouseLeave={() => setShowModal(false)}>
        ?
      </InfoIcon>
      {showModal && staticTooltipContent()}
    </>
  )
}

export default CardCVV
