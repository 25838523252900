import styled from 'styled-components'

export const PageTitle = styled.p`
  font-style: normal;
  font-size: 1.1rem;
  line-height: 2rem;
  font-weight: bold;
  color: black;
  @media (max-width: 1400px) {
    font-size: 0.9rem;
  }
  @media (max-width: 1100px) {
    font-size: 0.7rem;
  }
  @media (max-width: 767px) {
    font-size: 0.9rem;
    line-height: 1.1rem;
    font-weight: 700;
  }
  @media (max-width: 375px) {
    font-size: 0.8rem;
  }
`

export const PageTitleBreadcrumb = styled.p`
  font-style: normal;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 500;
  margin-right: 7px;
  cursor: pointer;
  :hover {
    color: #000080;
  }
  @media (max-width: 1400px) {
    font-size: 0.8rem;
  }
  @media (max-width: 1100px) {
    font-size: 0.5rem;
  }
`

export const ExpiredChip = styled.div<{color: string}>`
  color: ${({color}) => color};
  font-weight: 600;
  margin-left: 0.7;
  font-size: 15px;
  display: inline-block;
  gap: 10px;
`

export const WrapperSelectCompaniesOnHeader = styled.div`
  width: 100%;
  max-width: 30em;
  min-width: 20em;

  @media (max-width: 767px) {
    min-width: 5em;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 20px;
  align-items: center;

  @media (max-width: 767px) {
    height: 4.5rem;
    padding: 0;
  }
`

export const BoxHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  @media (max-width: 767px) {
    display: none;
  }
`

export const BoxProfileComponent = styled.div`
  @media (max-width: 767px) {
    padding-right: 15px;
  }
`
