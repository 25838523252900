import {Snapshot} from '..'
import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_SNAPSHOT_INGESTION_BASE_URL

export class SnapshotIngesterEndpoint {
  async snapshot(uuid: string): Promise<AxiosResult<Snapshot>> {
    return await axios.get(`${prefix}/snapshot/${uuid}`)
  }
}
