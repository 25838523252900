import EditIcon from '@mui/icons-material/Edit'
import {Tooltip} from '@mui/material'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'src/contexts/AuthContext'
import {Box, Button, Menu, Typography} from '@viptech/react-components'
import {capitalize} from 'lodash'
import {BoxProfileComponent} from '../HeaderBase'

export function ProfileMenuComponent() {
  const {user, logout} = useAuth()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  function stringToColor(string: string) {
    let hash = 0
    for (let i = 0; i < string.length; i++) hash = string.charCodeAt(i) + ((hash << 5) - hash)
    let color = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    return color
  }

  function renderAvatar(name: string) {
    const nameEdit = name.replace(' de ', ' ')
    return (
      <Box
        color='white'
        backgroundColor={stringToColor(nameEdit)}
        width='40px'
        height='40px'
        display='flex'
        align='center'
        fontSize='17px'
        justifyContent='center'
        borderRadius='50%'
        style={{textTransform: 'uppercase'}}>
        {nameEdit.split(' ').length <= 1 ? `${nameEdit.split(' ')[0][0].toLocaleUpperCase()}` : `${nameEdit.split(' ')[0][0]}${nameEdit.split(' ')[1][0]}`}
      </Box>
    )
  }

  return (
    <BoxProfileComponent>
      <Tooltip title='Opções de Usuário'>
        <Button variant='outlined' color='transparent' hoverColor='transparent' padding='0px' onClick={(event) => setAnchorEl(event.currentTarget)}>
          {renderAvatar(user.name)}
        </Button>
      </Tooltip>
      <Menu anchor={anchorEl} onClose={() => setAnchorEl(null)} borderRadius='8px'>
        <Box justifyContent='center' display='flex' direction='column' alignContent='center' align='center' padding='20px' rowGap='15px'>
          <Box display='flex' justifyContent='center'>
            {renderAvatar(user.name)}
          </Box>

          <Box width='100%' display='flex' direction='column' rowGap='10px' alignContent='center' align='center'>
            <Typography variant='h5'>{capitalize(user.name)}</Typography>
            <Typography variant='p' size={'14px'}>
              {user.email}
            </Typography>

            <Box paddingTop={'15px'} width='100%' display='flex' direction='column'>
              <Button variant='outlined' color='transparent' hoverColor='transparent' height='35px' onClick={() => navigate('/change-password')}>
                <EditIcon sx={{width: '13px', height: '13px'}} color='primary' />
                <Typography variant='p' color='rgb(0, 158, 255)' size='12px'>
                  Alterar senha
                </Typography>
              </Button>

              <Button variant='outlined' fontSize='14px' color='rgb(0, 158, 255)' onClick={() => logout()} height='30px'>
                Sair
              </Button>
            </Box>
          </Box>
        </Box>
      </Menu>
    </BoxProfileComponent>
  )
}
