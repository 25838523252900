import {Box} from '@mui/material'
import {useEffect, useRef, useState} from 'react'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from 'src/contexts/AuthContext'

function RouteRedirector() {
  const {authFromUrlToken, isAuth, logout} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const location = useLocation()
  const decodedLocation = decodeURIComponent(location.pathname)
  const params = new URLSearchParams(decodedLocation)
  const next = params.get('next')
  const token = params.get('/?token') || params.get('token')
  const blockAuthRequests = useRef<boolean>(false)

  async function authenticate() {
    if (blockAuthRequests.current) return
    blockAuthRequests.current = true
    if (token) {
      logout()
      const response = await authFromUrlToken(token)
      if (response) {
        navigate(`/${next}`)
      }
    }
    setIsLoading(false)
    blockAuthRequests.current = false
  }

  useEffect(() => {
    authenticate()
  }, [])

  return (
    <>
      {!isLoading && (
        <>
          {isAuth && next ? (
            <Box>
              <Navigate to={`/${next}`} />
            </Box>
          ) : (
            <Navigate to='/login' />
          )}
        </>
      )}
    </>
  )
}

export default RouteRedirector
