import moment from 'moment'
import ms from 'ms'
import {CameraHistoric} from 'src/services/api'

export const lastTime = ['1', '7', '14']

export const cameraHistoricColumns = [
  {
    field: 'createdAt',
    headerName: 'Atualização',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'self',
    headerName: 'Atualizado há',
    minWidth: 400,
    maxWidth: 400,
  },
  {
    field: 'statusId',
    headerName: 'Status',
    minWidth: 70,
    maxWidth: 200,
    flex: 1,
  },
  {
    field: 'isOnline',
    headerName: 'Situação',
    minWidth: 100,
    maxWidth: 150,
    flex: 1,
  },
]

export function cameraHistoricRows(cameraHistorics: CameraHistoric[]) {
  return cameraHistorics.map((cameraHistoric) => {
    const newDate = moment(cameraHistoric.createdAt).format('DD/MM/YYYY HH:mm:ss')
    const lastUpdate = moment(cameraHistoric.createdAt).fromNow()
    const newUpdate = lastUpdate.split(' ').slice(1).join(' ')

    return {
      id: cameraHistoric.id,
      createdAt: newDate.toLocaleString(),
      isOnline: cameraHistoric.isOnline ? '🟢  Habilitada' : '🔴  Desabilitada',
      statusId: cameraHistoric.statusId === 1 ? 'Armada' : cameraHistoric.statusId === 2 ? 'Desarmada' : 'Fora do horário',
      self: newUpdate,
    }
  })
}

export const time: {[key: string]: Date} = {
  '1': new Date(Date.now() - ms('24h')),
  '2': new Date(Date.now() - ms('7d')),
  '3': new Date(Date.now() - ms('14d')),
}
