import styled from 'styled-components'

export const TitleError = styled.p`
  color: var(--Gray-700, #344054);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 25px;
  }
`

export const ErrorContent = styled.p`
  color: var(--Gray-700, #344054);
  text-align: justify;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin-top: 1.5em;
  max-width: 499px;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const Container = styled.div`
  border-radius: 8px;
  align-items: center;
  flex-direction: column;
  background: white;
  padding: 5em 0;
  justify-content: center;
  display: flex;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const ContainerErrorSSO = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  color: black;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
export const ContainerImageErrorSSO = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    display: none;
  }
`
export const ContainerInfosErrorSSO = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2em;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`
