import {useEffect, useRef} from 'react'
import {YearPickerContainer, YearPickerContent, YearPickerRows} from './YearPickerBase'
import {YearPickerProps} from './YearPickerProps'

const YearPicker = ({currentYear, onChangeYear}: YearPickerProps) => {
  const yearPickerRef = useRef<HTMLDivElement | null>(null)

  const generateYearList = () => {
    const startYear = 2023
    const endYear = new Date().getFullYear()
    const years = []

    for (let year = startYear; year <= endYear; year++) {
      years.push(year)
    }

    const rows = []
    const colsPerRow = 5
    const totalYears = years.length

    for (let i = 0; i < totalYears; i += colsPerRow) {
      const row = years.slice(i, i + colsPerRow)
      rows.push(row)
    }

    return rows
  }

  useEffect(() => {
    if (yearPickerRef.current) {
      const selectedYearElement = yearPickerRef.current.querySelector(`#year_${currentYear}`)
      if (selectedYearElement) selectedYearElement.scrollIntoView({behavior: 'auto', block: 'center'})
    }
  }, [])

  return (
    <YearPickerContainer ref={yearPickerRef}>
      {generateYearList().map((row, rowIndex) => (
        <YearPickerRows key={`row_${rowIndex}`}>
          {row.map((year, colIndex) => (
            <YearPickerContent
              key={`year_${colIndex}`}
              isCurrentYear={new Date().getFullYear() === year}
              isSelected={currentYear === year}
              onClick={() => onChangeYear(year)}
              id={`year_${year}`}>
              {year}
            </YearPickerContent>
          ))}
        </YearPickerRows>
      ))}
    </YearPickerContainer>
  )
}

export default YearPicker
