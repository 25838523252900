import {Box} from '@viptech/react-components'
import {useCallback, useEffect, useReducer} from 'react'
import {Circle, Group, Layer, Line, Rect} from 'react-konva'
import {CamerasKonvaImageDetections} from 'src/common/components/cameras-konva-image-detections/CamerasKonvaImageDetections'
import {posTuple} from 'src/common/utils/Internal'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import mkKey from 'src/utilities/mkKey'
import {imageDrawStyles} from '../../generics/components/image-draw/ImageDrawBase'
import {INITIAL_STATE, invScalePoints, pointsToPos, reducer, safePos} from '../../generics/components/image-draw/ImageDrawFunctions'
import {ImageDrawProps} from '../../generics/components/image-draw/ImageDrawProps'
import {KonvaImageDrawToolbar} from './components/konva-image-draw-toolbar/KonvaImageDrawToolbar'

export function CameraImageDraw(cameraImageDrawProps: ImageDrawProps) {
  const {konvaImageRef} = useCameraConfigContext()
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const {points, pos} = state
  const {dim, scale} = konvaImageRef.current || {scale: 0}

  const {
    src,
    onSave,
    onCancel,
    editable,
    points: _pointsInital,
    width,
    height,
    getPoints,
    cancel,
    save,
    isKonvaImageDrawToolbar,
    maxAmountOfPoints,
  } = cameraImageDrawProps

  useEffect(() => {
    if (!getPoints) return
    if (points.length >= 2 || editable === true) getPoints(points)
  }, [points, getPoints])

  const onClickCancel = useCallback(() => {
    if (!onCancel) return
    onCancel()
    dispatch({
      type: 'cancel',
      payload: pointsToPos(_pointsInital || [], scale),
    })
  }, [_pointsInital, onCancel, scale])

  const onClickSave = useCallback(() => {
    if (!onSave) return
    onSave(invScalePoints(points, scale))
    dispatch({type: 'save'})
  }, [onSave, points, scale])

  useEffect(() => {
    if (cancel === 'cancel') onClickCancel()
    else if (save === 'save' && points.length >= 2) onClickSave()
  }, [cancel, save, onClickCancel, onClickSave, points.length])

  useEffect(() => {
    if (_pointsInital && scale !== 0) {
      dispatch({
        type: 'points-init',
        payload: pointsToPos(_pointsInital, scale),
      })
    }
  }, [_pointsInital, scale])

  return (
    <Box display='flex' direction='column' rowGap='10px' align='center'>
      <CamerasKonvaImageDetections StageProps={{style: imageDrawStyles.stage}} src={src} width={width} height={height} konvaImageRef={konvaImageRef}>
        {dim && scale > 0 && (
          <Layer>
            <Rect
              {...dim}
              onClick={(e) => {
                if (!editable) return
                if (maxAmountOfPoints && points.length >= maxAmountOfPoints) return
                const pos = e.target.getRelativePointerPosition()
                if (!pos) return
                dispatch({
                  type: 'point-new',
                  payload: {...pos, key: mkKey()},
                })
              }}
            />
            <Group name='points'>
              <Line points={points.flatMap((it) => [it.x, it.y]).concat(points.length === 0 ? [] : posTuple(points[0]))} stroke='blue' />
              {editable &&
                points.map((it, index) => {
                  const isDragging = pos?.key === it.key
                  const isLast = index === points.length - 1
                  return (
                    <Circle
                      key={`draw-circle-${it.x}-${it.y}-${index}`}
                      x={it.x}
                      y={it.y}
                      radius={9}
                      fill={isDragging ? 'red' : isLast ? 'yellow' : 'blue'}
                      draggable
                      onDragMove={(e) =>
                        dispatch({
                          type: 'pos-move',
                          payload: {
                            ...it,
                            ...safePos({x: e.target.attrs.x, y: e.target.attrs.y}, it, dim),
                          },
                        })
                      }
                      onDragStart={() => dispatch({type: 'pos-move', payload: it})}
                      onDragEnd={() => dispatch({type: 'pos-end'})}
                    />
                  )
                })}
            </Group>
          </Layer>
        )}
      </CamerasKonvaImageDetections>
      {isKonvaImageDrawToolbar && (
        <KonvaImageDrawToolbar
          editable={editable}
          onClickRedo={() => dispatch({type: 'redo'})}
          onClickUndo={() => dispatch({type: 'undo'})}
          onClickClearLast={() => dispatch({type: 'clear-last'})}
          onClickClear={() => dispatch({type: 'clear'})}
        />
      )}
    </Box>
  )
}
