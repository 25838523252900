import MoreVertIcon from '@mui/icons-material/MoreVert'

import {Box} from '@viptech/react-components'
import AppAddButton from './app-add-button/AppAddButton'
import {TitleOnButton} from './app-add-button/AppAddButtonBase'

type AppAddOrOptionsButtonProps = {
  text: string
  showOptions?: boolean
  onClickAdd: React.MouseEventHandler<HTMLButtonElement>
  onClickOptions?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  sx?: any
}

function AppAddOrOptionsButton({text, showOptions, onClickAdd, onClickOptions, disabled, sx}: AppAddOrOptionsButtonProps) {
  if (showOptions) {
    return (
      <AppAddButton onClick={onClickOptions} disabled={disabled} sx={sx}>
        <Box display='flex' columnGap={'5px'} align='center' justifyContent='center'>
          <MoreVertIcon />
          <TitleOnButton>Opções</TitleOnButton>
        </Box>
      </AppAddButton>
    )
  } else {
    return <AppAddButton onClick={onClickAdd} text={text} disabled={disabled} sx={sx} />
  }
}

export default AppAddOrOptionsButton
