import {CountByCategoriesDTO} from '../../services/api/endpoints/ReportsEndpoint'

export const translateCategories = (categories: Array<string | number>) => {
  let dictionary: any = {
    child: 'Criança',
    young: 'Adolescente',
    middle: 'Jovem',
    prime: 'Adulto',
    middleAged: ['Meia', 'Idade'],
    elderly: 'Idoso',
    longHair: ['Cabelo', 'Longo'],
    shortHair: ['Cabelo', 'Curto'],
    blue: 'Azul',
    brown: 'Marron',
    black: 'Preta',
    yellow: 'Amarela',
    white: 'Branca',
    gray: 'Cinza',
    red: 'Vermelha',
    green: 'Verde',
    purple: 'Roxa',
    pink: 'Rosa',
    violet: 'Violeta',
    orange: 'Laranja',
    longSleeve: ['Manga', 'Comprida'],
    shortSleeve: ['Manga', 'Curta'],
    skirt: 'Saia',
    longTrousers: ['Calça', 'Comprida'],
    leftward: 'Esquerda',
    backward: 'Para trás',
    forward: 'Em frente',
    rightward: 'Direta',
    panic: 'Pânico',
    surprised: 'Surpreso',
    happy: 'Feliz',
    disgusted: 'Enojado',
    'poker-faced': 'Inexpressivo',
    sad: 'Triste',
    male: 'Homens',
    female: 'Mulheres',
    glass: 'Com óculos',
    bag: 'Com mochila',
    hat: 'Com chapéu',
    mask: 'Com máscara',
  }

  return categories.map((it) => dictionary[it] ?? 'Outros')
}

export const formatChartSeriesAndTranslateNames = (data: CountByCategoriesDTO, categorizeBy: string) => {
  let translateSeries: any = {series: [], categories: []}
  const letTranslateCategories = translateCategories(data.categories)

  data.series.forEach((it) => {
    if (it.name === 'female') translateSeries.series.push({name: 'Mulheres', data: it.data.map((it) => +it.toFixed(2) * 100)})
    else if (it.name === 'male') {
      translateSeries.series.push({name: 'Homens', data: it.data.map((it) => +it.toFixed(2) * 100)})

      if (categorizeBy === 'age') translateSeries.categories = data.categories
      else translateSeries.categories = letTranslateCategories
    } else if (it.name === 'unidentified') translateSeries.series.push({name: 'Não identificado', data: it.data.map((it) => it.toFixed(2))})
  })

  return translateSeries
}

export const formatChartCountSeriesAndTranslateNames = (data: CountByCategoriesDTO, categorizeBy: string) => {
  let translateSeries: any = {series: [], categories: []}

  data.series.forEach((it) => {
    if (it.name === 'female') translateSeries.series.push({name: 'Mulheres', data: it.data})
    else if (it.name === 'male') {
      translateSeries.series.push({name: 'Homens', data: it.data})

      if (categorizeBy === 'date') {
        let dateToConvert = data.categories.map((item) => new Date(item).getUTCDay())
        translateSeries.categories = convertDateToDayOfWeek(dateToConvert)
      } else if (categorizeBy === 'hour') translateSeries.categories = data.categories.map((it) => it + 'h')
    } else if (it.name === 'unidentified') translateSeries.series.push({name: 'Não identificado', data: it.data})
  })

  return translateSeries
}

export const convertDateToDayOfWeek = (dates: Array<number>) => {
  let dictionary: {[x: number]: string} = {
    0: 'Dom',
    1: 'Seg',
    2: 'Ter',
    3: 'Qua',
    4: 'Qui',
    5: 'Sex',
    6: 'Sab',
  }

  return dates.map((it) => dictionary[it])
}

export const hexColorDictionary = (colors: Array<string>) => {
  let dictionary: any = {
    blue: '#0085FF',
    brown: '#7A2B00',
    black: '#2b2b2b',
    yellow: '#e2f252',
    white: '#F1F1F1',
    gray: '#7c7b7b',
    red: '#BA281E',
    green: '#26BA02',
    purple: '#7A16C7',
    pink: '#ba1e98',
    violet: '#a576c9',
    orange: '#F2B252',
  }

  return colors.map((it) => dictionary[it])
}
