import { StyledTheme } from '../StyledTheme'
import { ArrowDownIconProps } from '../components/dropdown/DropdownProps'

export default function ArrowDownIcon({ disabled, transform, color }: ArrowDownIconProps) {
  return (
    <svg
      style={{
        transition: 'all 0.2s ease-in-out',
        transform: transform,
      }}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="13976e77964109f613e2a13bf6dcc1a9"
        d="M1 1.5L6 6.5L11 1.5"
        stroke={disabled ? StyledTheme.colors.disabled : color ?? StyledTheme.input.borderColor}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}
