import {Box, Button, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {ConfirmationModal} from 'src/components/ConfirmationModal/ConfirmationModal'
import {Input} from 'src/components/input/Input'
import {GoBackButton, TitleAlt} from 'src/pages/self-register/select-plan/components/PlanSelectBase'
import api, {AddCameras} from 'src/services/api'
import {UserToken} from '../../../contexts/AuthContext'
import Stepper from '../components/Stepper/Stepper'
import {ContainerButtonCopie, ContainerContent, ContainerPageSelfRegister, InnerBoxContent, WrapperContent} from './CameraRTMPConfigurationBase'
import {ConfirmationConfigurationModal} from './components/ConfirmationConfigurationModal'
import {useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {useQueryParams} from 'src/hooks/useQuery'
import {ChangePlanModalContact} from './components/change-plan-modal/ChangePlanModalContact'
import {getDecodedToken} from 'src/utilities/getDecodedToken'
import ArrowLeft from 'src/images/goBackArrow.svg'
import {useNavigate} from 'react-router-dom'
import Loading from 'src/components/Loading'
import YoutubeEmbed from '../components/youtube-embed/YoutubeEmbed'

type CameraRTMPConfigurationProps = {
  setCameraConfigId: (cameraConfigId: number) => void
  setCameraId: (cameraId: number) => void
  cameraConfigId: number
  setPage: (page: number) => void
}

function CameraRTMPConfiguration(props: CameraRTMPConfigurationProps) {
  const {setCameraConfigId, setCameraId, cameraConfigId, setPage} = props
  const {setCameraCreatedPrevious} = useB2CContext()
  const [rtmpKey, setRtmpKey] = useState('')
  const query = useQueryParams()
  const params = query.get('type')
  const editId = query.get('id')
  const tryAddNewCamera = params === 'new-camera'

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [confirmedAwareness, setConfirmedAwareness] = useState(false)

  const [openModalConfirmationConfiguration, setOpenModalConfirmationConfiguration] = useState(false)
  const userFromDecodedToken = useRef<UserToken>()

  const {enqueueSnackbar} = useSnackbar()

  const [openChangePlanModal, setOpenChangePlanModal] = useState(false)

  async function getRtmpKey() {
    if (!userFromDecodedToken.current) return
    try {
      const clients = userFromDecodedToken.current.payload.authorizedClients
      const companies = userFromDecodedToken.current.payload.authorizedCompanies
      const dataCameraRtmp: AddCameras = {
        origin: 'RTMP',
        soft: true,
        companyId: companies[0],
        data: [
          {
            clientId: clients[0],
            isOnline: true,
          },
        ],
      }
      const rawRes = await api.camera.addMany(dataCameraRtmp)
      setCameraCreatedPrevious(rawRes.data.data.entities[0])

      const rtmpKey = rawRes.data.data.entities[0].addressInfo.link ?? ''
      setCameraId(rawRes.data.data.entities[0].id)
      setCameraConfigId(rawRes.data.data.entities[0].configurationId)
      setRtmpKey(rtmpKey)
    } catch (err) {
      if (tryAddNewCamera) {
        setOpenChangePlanModal(true)
      }
    }
  }

  async function getCamera() {
    if (!userFromDecodedToken.current) return
    const filter = editId ? {ids: [+editId]} : {companyIds: [userFromDecodedToken.current.payload.authorizedCompanies[0]]}
    const cameras = (
      await api.camera.getMany({
        page: 1,
        pageSize: 100,
        filter,
        includes: ['configuration', 'addressInfo'],
      })
    ).data.data.entities

    setCameraCreatedPrevious(cameras[0])

    if (!cameras[0]) return getRtmpKey()

    setCameraId(cameras[0].id)
    setCameraConfigId(cameras[0].configurationId)
    if (editId) return setPage(1)
    setRtmpKey(cameras[0].addressInfo.link ?? '')
    enqueueSnackbar('Bom ter você de volta. Continue configurando sua câmera', {variant: 'info'})
  }

  useEffect(() => {
    const decodedToken = getDecodedToken()
    userFromDecodedToken.current = decodedToken

    if (tryAddNewCamera) getRtmpKey()
    else getCamera()
  }, [])

  async function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text)
    enqueueSnackbar('Copiado com sucesso', {variant: 'success'})
  }

  async function handleClickCopy() {
    if (!confirmedAwareness) {
      return setIsConfirmationOpen(true)
    }
    copyToClipboard(rtmpKey)
  }

  function nothing() {}
  const navigate = useNavigate()

  return (
    <ContainerPageSelfRegister>
      {!rtmpKey ? (
        <Loading height='50px' width='50px' />
      ) : (
        <>
          {tryAddNewCamera && (
            <GoBackButton onClick={() => navigate(-1)}>
              <img src={ArrowLeft} alt='Arrow Left' />
              <Typography variant='span'> Voltar</Typography>
            </GoBackButton>
          )}

          <InnerBoxContent marginTop={tryAddNewCamera ? '20px' : '0'}>
            <Stepper activeStep={0} stepTitles={['Dados da câmera', 'Personalizar área', 'Finalizar']} />
            {tryAddNewCamera ? (
              <TitleAlt>Vamos configurar sua câmera e você poderá personalizar a sua área de deteção</TitleAlt>
            ) : (
              <TitleAlt>Seja bem-vindo, agora nós vamos configurar sua câmera e você poderá personalizar a sua área de deteção</TitleAlt>
            )}
          </InnerBoxContent>

          <WrapperContent>
            <YoutubeEmbed embedId='zK0UPexkJmM' />

            <Box display='flex' direction='column' align='flex-start' justifyContent='flex-start' rowGap='30px'>
              <Box display='flex' direction='column'>
                <Typography variant='span' size='16px' weight={600}>
                  Vamos utilizar o modo de detecção RTMP.
                </Typography>
                <Typography variant='span' size='16px' weight={600}>
                  Copie o link abaixo e siga as instruções no vídeo tutorial ao lado.
                </Typography>
                <Typography variant='span' size='16px' weight={600}>
                  Não se preocupe, estamos aqui para ajudar!
                </Typography>
              </Box>

              <ContainerContent>
                <Input.Root label='Chave de transmissão RTMP' controlled value={rtmpKey} onChange={nothing} type='password' />

                <ContainerButtonCopie>
                  <Button variant='contained' disabled={!rtmpKey} disabledBgColor='#9E9E9E' onClick={() => handleClickCopy()} height='42px' width='100%'>
                    Copiar
                  </Button>
                </ContainerButtonCopie>
              </ContainerContent>

              <Box alignSelf='center'>
                <Button
                  variant='contained'
                  disabled={!confirmedAwareness}
                  disabledBgColor='#9E9E9E'
                  onClick={() => setOpenModalConfirmationConfiguration(true)}>
                  Pronto, vamos avançar
                </Button>
              </Box>
            </Box>
          </WrapperContent>

          <ConfirmationModal
            isOpen={isConfirmationOpen}
            onClose={() => setIsConfirmationOpen(false)}
            title='Chave de transmissão'
            content={
              <Box>
                <Typography variant='p' size='16px'>
                  Nunca compartilhe a sua chave de transmissão com alguém!
                </Typography>
                <Typography variant='p' size='16px'>
                  A equipe Brain nunca pedirá esse tipo de informação para você.
                </Typography>
                <Typography variant='p' size='16px'>
                  Está ciente das informações acima?
                </Typography>
              </Box>
            }
            buttonsContent={[
              {
                label: 'Cancelar',
                onClick: () => setIsConfirmationOpen(false),
                variant: 'outlined',
                color: 'black',
                hoverColor: 'darkgray',
              },
              {
                label: 'Sim, estou ciente',
                onClick: () => {
                  setConfirmedAwareness(true)
                  setIsConfirmationOpen(false)
                  copyToClipboard(rtmpKey)
                },
                variant: 'contained',
              },
            ]}
          />

          {openModalConfirmationConfiguration && (
            <ConfirmationConfigurationModal
              cameraId={+cameraConfigId}
              isOpen={openModalConfirmationConfiguration}
              onClose={() => setOpenModalConfirmationConfiguration(false)}
              confirmationFunction={() => setPage(1)}
            />
          )}

        </>
      )}
      {openChangePlanModal && <ChangePlanModalContact isOpen={openChangePlanModal} />}
    </ContainerPageSelfRegister>
  )
}

export default CameraRTMPConfiguration
