import styled from 'styled-components'
import {StyledTheme} from '../../StyledTheme'
import {AccordionButtonStyleProps} from './AccordionProps'

export const AccordionContainer = styled.div<AccordionButtonStyleProps>`
  width: 100%;
  height: fit-content;
  margin: ${({margin}) => margin ?? `0px`};
`

export const AccordionButtonContainer = styled.div<AccordionButtonStyleProps>`
  display: flex;
  width: 100%;
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};

  height: ${({height}) => height ?? '2rem'};
  align-items: ${({align}) => align ?? 'center'};
  justify-content: ${({justify}) => justify ?? 'flex-start'};
  background-color: ${({backgroundColor}) => backgroundColor ?? StyledTheme.colors.disabledBackground};
  padding: ${({padding}) => padding ?? `0.25rem 0`};
  margin: ${({margin}) => margin ?? `0px`};
  border: ${({border}) => border ?? `0px`};
  border-radius: ${({borderRadius}) => borderRadius ?? '0px'};

  border-bottom: ${({borderBottom, isOpen}) => (borderBottom ?? !isOpen ? `1px solid ${StyledTheme.colors.primary}` : '0px')};

  border-left: ${({borderLeft}) => borderLeft ?? '0px'};
  border-top: ${({borderTop}) => borderTop ?? '0px'};
  border-right: ${({borderRight}) => borderRight ?? '0px'};
  transition: all 0.1s ease-in-out;

  :hover {
    background-color: ${({hoverBackgroundColor}) => hoverBackgroundColor ?? StyledTheme.colors.disabled};
  }
`

export const ChildrenContainer = styled.div<AccordionButtonStyleProps>`
  overflow: hidden;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'collapse')};
  position: relative;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;
  width: 100%;

  background-color: ${({childrenBackgroundColor}) => childrenBackgroundColor ?? StyledTheme.colors.white};
  border: ${({childrenBorder}) => childrenBorder ?? `0px`};
  border-radius: ${({childrenBorderRadius}) => childrenBorderRadius ?? '0px'};

  border-bottom: ${({childrenBorderBottom, isOpen}) => (childrenBorderBottom ?? isOpen ? `1px solid ${StyledTheme.colors.primary}` : '0px')};
  border-left: ${({childrenBorderLeft}) => childrenBorderLeft ?? '0px'};
  border-top: ${({childrenBorderTop}) => childrenBorderTop ?? '0px'};
  border-right: ${({childrenBorderRight}) => childrenBorderRight ?? '0px'};
`

export const AccordionIconContainer = styled.div<AccordionButtonStyleProps>`
  margin: 0 0.5rem;
`
