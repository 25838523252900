import api, {Camera} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export const daysMap: Record<number, string> = {
  1: 'Domingo',
  2: 'Segunda',
  3: 'Terça',
  4: 'Quarta',
  5: 'Quinta',
  6: 'Sexta',
  7: 'Sábado',
}

export async function sendPostSchedule(dayWeekSelected: number[], startTime: string, endTime: string, camera: Camera | null, enqueueSnackbar: any) {
  try {
    if (dayWeekSelected.length !== 0) {
      await api.schedule.addSchedule({
        start: `2000-01-01T${startTime}Z`,
        end: `2000-01-01T${endTime}Z`,
        cameraId: camera!.id,
        daysSchedule: dayWeekSelected.map((dayOfWeekId) => ({dayOfWeekId})),
      })
      enqueueSnackbar('Schedule adicionado com sucesso', {
        variant: 'success',
      })
      return true
    } else {
      enqueueSnackbar(`Erro ao adicionar Schedule: Dias da semana não selecionados`, {variant: 'error'})
    }
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar Schedule')
  }
  return false
}
