import styled from 'styled-components'

type RegisterGroupButtonProps = {
  height?: string
}

export const RegisterButtonIcon = styled.div`
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 0;
  padding: 14px;
  align-items: center;
  display: flex;
  :hover {
    background-color: #e1e1e18d;
    transition: 0.3s;
  }
`

export const RegisterGroupButton = styled.div<RegisterGroupButtonProps>`
  display: flex;
  align-self: flex-end;
  align-items: flex-end;
  column-gap: 16px;
  ${(props) => !!props.height && `height: ${props.height};`}
`
