import Joi from 'joi'

function validateEnv() {
  const envVarsSchema = Joi.object({
    NODE_ENV: Joi.string().valid('development', 'production', 'test').required(),
    PORT: Joi.number().default(8000),
    REACT_APP_BASE_URL: Joi.string().required(),
    REACT_APP_EVENT_IMAGES_BASE_URL: Joi.string().required(),
    REACT_APP_LPR_CROPPED_IMAGES_BASE_URL: Joi.string().required(),
    REACT_APP_CAMERAS_BASE_URL: Joi.string().required(),
    REACT_APP_OBJECTS_BASE_URL: Joi.string().required(),
    REACT_APP_DIGIFORT_BASE_URL: Joi.string().required(),
    REACT_APP_COMPANIES_BASE_URL: Joi.string().required(),
    REACT_APP_AUTH_BASE_URL: Joi.string().required(),
    REACT_APP_NOTIFICATIONS_BASE_URL: Joi.string().required(),
    REACT_APP_BRAINBOX_BASE_URL: Joi.string().required(),
    REACT_APP_LICENSE_PLATES_BASE_URL: Joi.string().required(),
    REACT_APP_FACIAL_BASE_URL: Joi.string().required(),
    REACT_APP_EVENTS_BASE_URL: Joi.string().required(),
    REACT_APP_DGUARD_BASE_URL: Joi.string().required(),
    REACT_APP_REPORTS_BASE_URL: Joi.string().required(),
    REACT_APP_LIVESTREAMS_BASE_URL: Joi.string().required(),
    REACT_APP_SNAPSHOT_INGESTION_BASE_URL: Joi.string().required(),
    REACT_APP_PAYMENTS_BASE_URL: Joi.string().required(),
  })
    .unknown()
    .required()
  const {error} = envVarsSchema.validate(process.env)
  if (error) {
    throw new Error(`Config validation error: ${error.message}`)
  }
  return true
}

export default validateEnv
