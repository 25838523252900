import {Box, Table, TableData} from '@viptech/react-components'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {ConfirmationModal} from 'src/components/ConfirmationModal/ConfirmationModal'
import RowHover from 'src/components/RowHover/RowHover'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api from 'src/services/api'
import {Page} from 'src/types'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import TopBar from '../../../../components/top-bar/TopBar'
import AppReloadButton from '../../../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../../../components/dropdown/DropdownProps'
import Pagination from '../../../../components/pagination/Pagination'
import {SelectWithSearchComponent} from '../../../../components/selectWithSearchComponent/SelectWithSearchComponent'
import useFacialListItems from '../../hooks/useFacialListItems'
import {ListsContextMenu} from '../listsComponents/ListsContextMenu'
import CreateFaceModal from './CreateFaceModal'

const columns = [
  {
    id: 'name',
    name: 'Nome Cadastrado',
  },
  {
    id: 'date',
    name: 'Data',
  },
  {
    id: 'actions',
    name: '',
  },
]

const searchFilterData = [{id: '1', label: 'Nome Cadastrado'}]

function FacialListTable() {
  const {enqueueSnackbar} = useSnackbar()
  const [listSearch, setListSearch] = useState('')
  const listSearchDebounced = useDebounce(listSearch, 500)
  const hasFaceDeletePermission = ValidatePermissions('face:delete')
  const [isOpen, setIsOpen] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [callback, setCallback] = useState<() => void>(() => {})

  const [page, setPage] = useState<Page>({
    page: 1,
    pageSize: 20,
  })

  const {sidebarTitle, setSidebarTitle} = useApp()

  const setCurrentPage = (page: number): void => {
    setPage((prev: Page) => ({...prev, page}))
  }

  const setPageSize = (pageSize: number): void => {
    setPage((prev: Page) => ({...prev, pageSize}))
  }

  const control = {
    currentPage: page.page,
    setCurrentPage: setCurrentPage,
    changePageSize: setPageSize,
    nextPage: () => {
      setCurrentPage(page.page + 1)
    },
    prevPage: () => {
      setCurrentPage(page.page - 1)
    },
  }

  const {search} = useLocation()
  const listId = +search.split('=')[1]

  const {listItems, isLoading, totalItems, name, error, refresh} = useFacialListItems({listId, filter: listSearchDebounced, page})

  useEffect(() => {
    setSidebarTitle(`${sidebarTitle} > ${name}`)
  }, [name])

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Erro ao carregar faces', {variant: 'error'})
    }
  }, [error])

  useEffect(() => {
    refresh()
  }, [listSearchDebounced, page])

  async function handleDeleteList(id: string) {
    try {
      await api.facialRecognition.deleteFacial(+id)
      enqueueSnackbar('Face deletada com sucesso', {variant: 'success'})
      refresh()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao deletar face')
    }
  }

  async function handleConfirmDelete(id: string) {
    await handleDeleteList(id)
    setIsConfirmationOpen(false)
  }

  async function handleClose() {
    setIsOpen(false)
    refresh()
  }

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })
  return (
    <Box borderRadius='8px' align='center' direction='column' backgroundColor='white'>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title='Deletar face'
        content='Deseja realmente deletar esta face? Esta ação não pode ser revertida.'
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setIsConfirmationOpen(false),
            variant: 'outlined',
            color: 'black',
            hoverColor: 'darkgray',
          },
          {
            label: 'Excluir',
            onClick: () => callback(),
            variant: 'contained',
            dangerAction: true,
          },
        ]}
      />
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={isLoading} onClick={() => refresh()} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchFilterData}
              setSearchSelected={setShowSearchSelected}
              searchSelected={showSearchSelected}
              inputValue={listSearch}
              inputSearch={setListSearch}
              widthOnSelect={'280px'}
            />
          </Box>
          <AppAddOrOptionsButton text='Vincular uma face' onClickAdd={() => setIsOpen(true)} sx={{width: '200px'}} />
        </Box>
      </TopBar>

      <CreateFaceModal isOpen={isOpen} closeModal={() => handleClose()} listId={listId!} />

      <Box height='79vh' overflow='auto' padding='0 20px' display='flex' direction='column'>
        <Box flex={1} display={'flex'}>
          <Table columns={columns} isLoading={isLoading}>
            {listItems.map((row) => {
              const key = `${row.id}`
              return (
                <RowHover key={key}>
                  <TableData>{row.name}</TableData>
                  <TableData>{moment(row.createdAt).format('DD/MM/YYYY - HH:mm')}</TableData>
                  <TableData>
                    <ListsContextMenu
                      hideLists
                      hasDeletePermission={hasFaceDeletePermission}
                      rowId={`${row.id}`}
                      onClick={(event, rowListId) => {
                        if (event === 'delete') {
                          setIsConfirmationOpen(true)
                          setCallback(() => () => handleConfirmDelete(rowListId))
                        }
                      }}
                    />
                  </TableData>
                </RowHover>
              )
            })}
          </Table>
        </Box>

        {!isLoading && listItems.length === 0 ? (
          <Box display='flex' align='center' justify='center' height='72vh'>
            Nenhum registro encontrado
          </Box>
        ) : (
          <Box display='flex' align='flex-start' justify='flex-end' margin='15px 0'>
            <Pagination.Root
              backgroundColor='#009EFF'
              color='white'
              totalItems={totalItems}
              itemsPerPage={page.pageSize}
              possibleItemsPerPage={[20, 50, 100]}
              control={control}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default FacialListTable
