import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_DGUARD_BASE_URL

export type DguardCamera = {
  id: number
  name: string
}

export type DguardServerParams = {
  serverId: number
}

export type DguardServer = {
  name: string
  guid: string
  parentGuid: string
  hasChildren: boolean
  active: boolean
  connected: boolean
}

export type GetDguardCamerasParams = {
  signal?: AbortSignal
  serverId: number
  serverGuid: string
}

export type Snapshot = {
  base64: string
  type: string
  size: number
}

export class DguardEndpoint {
  async getCameras(params: GetDguardCamerasParams): Promise<AxiosResult<DguardCamera[]>> {
    return axios.get(`${prefix}/camera`, {
      params: {serverId: params.serverId, serverGuid: params.serverGuid},
      signal: params.signal,
    })
  }

  async getDguardServers(params: DguardServerParams): Promise<AxiosResult<DguardServer[]>> {
    return axios.get(`${prefix}/servers`, {params})
  }
}
