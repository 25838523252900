import {Box, Typography} from '@mui/material'
import ReactPlayer from 'react-player'
import createStyleSheet from 'src/utilities/createStyleSheet'

const MoniGuide = () => {
  return (
    <Box sx={styles.paper}>
      <Typography sx={{marginBottom: 5, fontSize: '1.6rem'}}>Informações adicionais para integração Moni</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>
        Ao abrir a plataforma do Moni, vá em "Utilitários" {'->'} "Configurar" {'->'} "Softwares Integração"
      </Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>Assim que a nova janela abrir, inclua um novo item</Typography>

      <Typography sx={{marginBottom: 1, fontSize: '1.3rem'}}>
        Na página para incluir um novo item
        <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>
          Insira a descrição desejada e preencha os campos "Usuário" e "Senha" com as credenciais de acesso pragmático geradas na plataforma do Brain
        </Typography>
      </Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>
        Após a inclusão do novo item, vá em "Utilitários" {'->'} "Configurar" {'->'} "Softwares de Integração"
      </Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>Clique no item criado, vá em "Serviço" {'->'} "Moni Integração" e clique em "Ativar"</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>Ative a caixa para Fixar IP e preencha a porta com o valor que desejar</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>Vá no item desejado, e clique no ícone para adicionar algum código neste item</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>
        Adicione o código para o tipo de detecção desejado, informe "E" na identificação e coloque a descrição de acordo com o código inserido.
      </Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>Por fim, escolha a cor desejada para fazer a detecção.</Typography>

      <Typography sx={{marginBottom: 1, fontSize: '1.3rem'}}>
        Códigos de detecção:
        <Typography sx={{marginBottom: 4, fontSize: '1.2rem'}}>
          B001 - PESSOA DETECTADA
          <br />
          B002 - VEÍCULO DETECTADO
          <br />
          B003 - ALIMENTO DETECTADO
          <br />
          B004 - UTENSÍLIOS DOMÉSTICOS DETECTADO
          <br />
          B005 - ELETRÔNICOS DETECTADOS
          <br />
          B006 - ANIMAL DETECTADO
          <br />
          B007 - OBJETOS PÚBLICO DETECTADO
          <br />
          B008 - OBJETOS PESSOAIS DETECTADO
          <br />
          B009 - OBJETOS ESPORTIVOS DETECTADO
          <br />
          B010 - VARIAÇÃO
          <br />
          B011 - ARMAS
          <br />
          B012 - AGLOMERAÇÃO
          <br />
          B013 - EQUIPAMENTO DE PROTEÇÃO INDIVIDUAL DETECTADO
          <br /> B014 - AUSÊNCIA DE EQUIPAMENTO DE PROTEÇÃO INDIVIDUAL DETECTADO
        </Typography>
      </Typography>

      <Typography sx={{marginBottom: 3, fontSize: '1.3rem'}}>O vídeo a seguir explica de forma detalhada os passos para completar a integração:</Typography>

      <Box>
        <ReactPlayer width={'1000px'} height={'450px'} url='https://youtu.be/XFC-d7GTCP8' controls={true} />
      </Box>
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: 1.5,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: 0.5,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '77vh',
    overflow: 'scroll',
  },
  gridItem2: {
    display: 'flex',
    flexDirection: 'column',
    height: '82vh',
    overflow: 'scroll',
  },
  notReleases: {
    fontSize: '1rem',
    justifyContent: 'center',
    height: '77vh',
    alignItems: 'center',
    display: 'flex',
  },
})

export default MoniGuide
