import PaginationRoot from './PaginationRoot'
import CurrentItemsRange from './components/PageRange/PageRange'

const Pagination = {
  Root: PaginationRoot,
  PageRange: CurrentItemsRange,
}

export {CurrentItemsRange as PageRange}

export default Pagination
