import {Box, Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import credentialsIcon from 'src/images/credentialsBlackIcon.svg'
import Delete from 'src/images/deleteIconBlack.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'
import actionsObject from '../../../images/actionsObject.svg'
import IconEditCompany from '../../../images/iconEditCompany.svg'
import permissionCompany from '../../../images/permissionCompany.svg'

type CompanyTooltipEvent = 'set-permissions' | 'edit-company' | 'update-credentials' | 'delete'
type CompanyInfos = {
  oneObject: number
  onClick?: (event: CompanyTooltipEvent, oneObject: number) => void
  hasDeleteCompanyPermission: boolean
}

const CompanyMenuOptions = ({oneObject, onClick, hasDeleteCompanyPermission}: CompanyInfos) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Tooltip title='Opções' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('edit-company', oneObject)
          }}>
          <img src={IconEditCompany} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.7}}>Editar Empresa</Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('set-permissions', oneObject)
          }}>
          <img src={permissionCompany} alt='permissionCompany' style={{width: 15, height: 15}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.8}}>Definir Permissões</Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('update-credentials', oneObject)
          }}>
          <img src={credentialsIcon} alt='credentialsIcon' style={{width: 15, height: 15}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.8}}>Renovar Credenciais</Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('delete', oneObject)
          }}
          disabled={!hasDeleteCompanyPermission}>
          <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  )
}

export default CompanyMenuOptions

const styles = createStyleSheet({
  menuInfos: {
    padding: '5px 12px 4px 12px',
    fontSize: '12px',
  },
})
