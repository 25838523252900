import {Box, Button, Dialog, DialogContent, DialogTitle, Tooltip} from '@mui/material'
import {useEffect, useState} from 'react'
import ReactPlayer from 'react-player'
import {useLocation, useNavigate} from 'react-router-dom'
import {BtnClose} from 'src/components/BtnClose'
import api, {Camera} from 'src/services/api'
import storage from 'src/services/storage'
import createStyleSheet from 'src/utilities/createStyleSheet'
import EditCam from '../../../images/editCam.svg'
import LayoutDefault from './LayoutDefault'
import LayoutLPR from './LayoutLPR'

type streamOpen = {tag: 'Success'; data: Camera; hash: string}

function LayoutShow() {
  const [cameras, setCameras] = useState<number[]>([])
  const [mosaico, setMosaico] = useState<number>(0)
  const [, setLayoutId] = useState<number[]>([])
  const [typeLayout, setTypeLayout] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const location = useLocation()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [stream, setStream] = useState<streamOpen>()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const id = +params.get('id')!
    loadLayout(id)
  }, [location])

  async function loadLayout(id: number) {
    if (id === 0) return
    const response = await api.layout.getMany({
      filter: {
        ids: [id],
      },
      includes: ['cameras'],
    })

    const quantityMosaico = response.data.data.entities
    quantityMosaico.forEach((quantity) => {
      if (quantity.cameras) setCameras(quantity.cameras?.map((it) => it.cameraId))
      setMosaico(quantity.maxCameras)
      setTypeLayout(quantity.layoutTypeId === 1 ? 'Default' : 'LPR')
      setIsLoading(false)
    })
    setLayoutId(response.data.data.entities.map((it) => it.id))
  }

  const openCameraModal = (stream: any) => {
    setOpenModal(true)
    setStream(stream)
  }
  const closeCameraModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Box sx={styles.container}>
        {!isLoading &&
          (typeLayout === 'Default' ? (
            <LayoutDefault openCameraModal={openCameraModal} cameras={cameras} mosaico={mosaico} />
          ) : (
            <LayoutLPR openCameraModal={openCameraModal} cameras={cameras} mosaico={mosaico} />
          ))}
      </Box>
      {stream && (
        <Dialog open={openModal} onClose={closeCameraModal} maxWidth='xl'>
          <DialogTitle sx={styles.header}>
            <Box sx={styles.titleModal}>{`${stream!.data.name} (#${stream!.data.id})`}</Box>

            <Tooltip title='Editar configuração da câmera' placement='top'>
              <Button sx={styles.buttonModalConfigCam} onClick={() => navigate(`/registers/devices/${stream?.data.id}`)}>
                <img src={EditCam} alt='editar camera' />
              </Button>
            </Tooltip>

            <BtnClose onClose={closeCameraModal} />
          </DialogTitle>
          <DialogContent sx={{padding: 0, height: '100vh', width: '70vw', display: 'flex', backgroundColor: '#1D1E28'}}>
            <ReactPlayer
              url={`${api.streaming.startStream(stream!.hash)}`}
              config={{
                file: {
                  hlsOptions: {
                    forceHLS: true,
                    debug: false,
                    xhrSetup: function (xhr: any) {
                      xhr.setRequestHeader('Authorization', `Bearer ${storage.get('token')}`)
                    },
                  },
                },
              }}
              autoPlay
              preload={'auto'}
              playing={true}
              width={'100%'}
              height={'100%'}
              controls={true}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  )
}

export default LayoutShow

const styles = createStyleSheet({
  gridItem: {
    backgroundColor: 'black',
    width: '100%',
    overflow: 'scroll',
  },
  gridItem2: {
    backgroundColor: '#3E3E3E',
    height: '100vh',
    width: '344px',
  },
  container: {
    width: '100vw',
    height: '100vh',
  },
  buttonModal: {
    width: '45px',
    minWidth: 0,
    marginRight: 2,
    minHeight: 1,
    height: '38px',
    border: '1px solid #C0C0C0',
    '&:hover': {
      border: '1px solid #000',
    },
  },
  buttonModalConfigCam: {
    width: '45px',
    minWidth: 0,
    minHeight: 1,
    height: '38px',
    border: '1px solid #C0C0C0',
    '&:hover': {
      border: '1px solid #000',
    },
    marginRight: 4,
  },
  closeButton: {
    position: 'absolute',
    right: '0.1%',
    top: 0,
    minWidth: 0,
    fontWeight: 'bold',
  },
  titleModal: {
    fontWeight: 'bold',
    color: '#353535',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})
