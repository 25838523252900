import {Typography} from '@viptech/react-components'
import styled, {keyframes} from 'styled-components'
import {CalendarStyleProps} from './CalendarProps'

export const CalendarContainer = styled.div`
  display: inline-block;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 1rem;
`

export const CalendarCalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 0rem 0.5rem;
`

export const CalendarDay = styled.div<CalendarStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: ${({isSelected, theme}) => (isSelected ? theme.calendar.selectedDayBackgroundColor : theme.calendar.backgroundColor)};
  color: ${({isSelected, theme}) => (isSelected ? theme.calendar.selectedDayColor : theme.calendar.color)};

  ${({isToday, isSelected, theme}) => isToday && !isSelected && `border: 1px solid ${theme.calendar.actualDateBorder};`};

  ${({isToday, isSelected, theme}) => isToday && !isSelected && `color: ${theme.calendar.actualDateColor};`};

  ${({isToday, isSelected, theme}) => isToday && !isSelected && `background-color: ${theme.calendar.actualDateBackgroundColor};`};
  ${({isCurrentMonth}) => !isCurrentMonth && 'opacity: 0.5'};
  font-family: ${({theme}) => theme.calendar.fontFamily};
  font-size: ${({theme}) => theme.calendar.fontSize};
  font-weight: ${({theme}) => theme.calendar.fontWeight};

  :hover {
    background-color: ${({theme, isSelected}) => !isSelected && theme.calendar.hoverBackgroundColor};
    color: ${({theme, isSelected}) => !isSelected && theme.calendar.hoverColor};
  }
`
export const CalendarEmptyDay = styled.div`
  height: 2rem;
  width: 2rem;
`

export const CalendarHeaderText = styled(Typography)`
  font-family: ${({theme}) => theme.calendar.headerFontFamily};
  font-size: ${({theme}) => theme.calendar.headerFontSize};
  font-weight: ${({theme}) => theme.calendar.headerFontWeight};
`

export const CalendarMonthNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 1rem;
  height: 2.5rem;
`

export const CalendarDropdownButton = styled.span`
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`

export const CalendarMonthButton = styled.button<CalendarStyleProps>`
  border: ${({disabled}) => (disabled ? '1px solid gray' : '1px solid #009eff')};
  border-radius: 8px;
  height: 1.8rem;
  width: 2rem;
  background-color: ${({disabled, theme}) => (disabled ? theme.colors.disabledBackground : theme.calendar.navigationArrowsBackgroundColor)};
  color: ${({disabled, theme}) => (disabled ? theme.colors.disabled : theme.calendar.navigationArrowsColor)};
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`

const zoomFadeInAnimation = keyframes`
  from {
    transform: scale(1.2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

export const CalendarZoomFadeInWrapper = styled.div`
  opacity: 0;
  animation: ${zoomFadeInAnimation} 0.3s forwards;

  &.visible {
    opacity: 1;
  }
`
