import {Box, CircularProgress, Grid, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import createStyleSheet from 'src/utilities/createStyleSheet'

import api from '../../../services/api'

import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'

import moment from 'moment'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useCompany} from 'src/contexts/CompanyContext'
import {EventCamMax} from 'src/services/api/endpoints/ReportsEndpoint'
import {ChartEventFilterDate} from './DashboardEventsStatus'

type CamMAxEvent = {
  cameraId?: string
  clientId?: string
  data: number
}

type DashboardAggregationEventProps = {
  filterDate: ChartEventFilterDate

  filter: string
}

function DashboardAggregationCamChart({filterDate, filter}: DashboardAggregationEventProps) {
  const [chartEventMaxCam, setChartEventMaxCam] = useState<CamMAxEvent[]>([])
  const hasPermissionReadCameras = ValidatePermissions('cameras:read')
  const blockEventMaxCamRequests = useRef(false)

  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanyId} = useCompany()

  const [isLoadindChartCloseEventUser, setIsLoadindChartCloseEventUser] = useState<boolean>(false)

  const dayDefault = moment().format('YYYY-MM-DD')

  async function getAggregationCam() {
    if (!selectedCompanyId) return
    if (blockEventMaxCamRequests.current) return
    blockEventMaxCamRequests.current = true
    setIsLoadindChartCloseEventUser(true)
    try {
      let x
      if (moment(filterDate.end).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        x = moment(Date.now()).format('HH:mm')
      } else {
        x = '23.99'
      }
      const response = await api.reports.eventCamMax({
        start:
          filterDate.start !== moment().format('YYYY-MM-DD')
            ? moment(filterDate.start).format('YYYY-MM-DDTHH:mm')
            : moment(dayDefault).format('YYYY-MM-DDTHH:mm'),
        end:
          filterDate.start !== moment().format('YYYY-MM-DD')
            ? moment(filterDate.start!).add(23.99, 'hours').format('YYYY-MM-DDTHH:mm')
            : moment(filterDate.start!).add(x, 'hours').format('YYYY-MM-DDTHH:mm'),

        orderBy: 'DESC',
        filter: {companyId: selectedCompanyId},
      })
      if (response.data.data.length > 0) {
        let ids: EventCamMax[] = []

        for (let i = 0; i < 5; i++) {
          if (response.data.data[i] !== undefined) ids.push(response.data.data[i])
        }

        if (!hasPermissionReadCameras) return
        try {
          const response2 = await api.camera.getMany({
            filter: {
              ids: ids.map((it) => it.cameraId!),
            },
          })

          const camMaxEvent = ids.map((it) => {
            return {
              cameraId: response2.data.data.entities.find((it2) => it2.id === it.cameraId!)
                ? response2.data.data.entities.filter((it2) => it2.id === it.cameraId)[0].name
                : 'Câmera deletada',
              data: it.data,
            }
          })
          setChartEventMaxCam(camMaxEvent)
        } catch (error) {
          handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar nome das cameras')
        }
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar dados das ocorrências fechadas pelo operador')
    } finally {
      setIsLoadindChartCloseEventUser(false)
      blockEventMaxCamRequests.current = false
    }
  }

  useEffect(() => {
    setChartEventMaxCam([])
    getAggregationCam()
  }, [filterDate.start, selectedCompanyId])

  return (
    <Grid container sx={styles.gridPaper}>
      <Grid container item padding={1.5} paddingTop={4} sx={{alignItems: 'left', display: 'flex'}}>
        <Grid item xs={12} textAlign='left' paddingLeft={1}>
          <Typography sx={styles.title}>{filter} com Mais Eventos</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={styles.boxTable}>
            <TableContainer
              sx={[
                styles.table,
                {
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: 0,
                  },
                },
              ]}>
              <Table stickyHeader aria-label='table close user event'>
                <TableHead>
                  <TableRow>
                    <TableCell align='left' width={'100%'} />
                    <TableCell align='right' sx={[styles.textInfo, {width: '200px'}]} />
                    <TableCell align='right' sx={[styles.textInfo, {width: '200px'}]} />
                  </TableRow>
                </TableHead>
                {isLoadindChartCloseEventUser ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={12} height='270px' sx={[styles.textInfo, {textAlign: 'center', fontWeight: '400'}]}>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : chartEventMaxCam.length > 0 ? (
                  <TableBody>
                    {chartEventMaxCam.map((row) => (
                      <TableRow key={row.cameraId} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                        <TableCell component='th' scope='row' sx={styles.textInfoRows}>
                          {row.cameraId}
                        </TableCell>
                        <TableCell component='th' scope='row' sx={styles.textInfoRows} />
                        <TableCell sx={[styles.textInfoRows, styles.moreInfo]}>{row.data}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow>
                      <TableCell height='270px' colSpan={12} sx={[styles.textInfo, {textAlign: 'center', fontWeight: '400'}]}>
                        Nenhum evento foi encontrado
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

const styles = createStyleSheet({
  textInfo: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 450,
    '@media (max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  gridPaper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selectPeriod: {
    height: '70%',
    fontFamily: 'Inter, sans-serif',
  },
  fontStyle: {fontFamily: 'Inter, sans-serif'},
  boxTable: {
    width: '100%',
    overflow: 'hidden',
    paddingLeft: 1.4,
  },
  table: {
    minWidth: '50%',
    minHeight: '30vh',
    maxHeight: '90vh',
    padding: 0,
    margin: 0,
  },
  moreInfo: {
    display: 'flex',
    flexDirection: 'row',
    color: '#009EFF',
    alignItems: 'center',
    paddingRight: '1.600000000000003em',
    justifyContent: 'flex-end',
    '@media (max-width:1540px)': {
      paddingRight: '1.700000000000003em',
    },
  },
  title: {
    margin: '0px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 550,
    fontSize: '1.125em',
    lineHeight: '1',
    textAlign: 'left',
    marginLeft: '0.73em',
    '@media (max-width:1540px)': {
      fontSize: '1.05em',
      marginLeft: '0.43em',
    },
  },
  textInfoRows: {
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.875em',
    fontWeight: 470,
    padding: 1.5,
    '@media (max-width:1540px)': {
      fontSize: '0.8em',
      padding: 0.95,
    },
    border: 'transparent',
  },
  selectPeriodWidth: {
    width: '35%',
    '@media (max-width:1540px)': {
      width: '45%',
    },
  },
  FilterRangeTimeButton: {
    width: '100px',
    height: '38.5px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginTop: '9.5px',
  },
})

export default DashboardAggregationCamChart
