import styled from 'styled-components'
import { InputBaseProps, InputContainerProps, InputDisabledProps, InputMuiLabelProps } from './InputProps'

export const InputContainer = styled.div<InputContainerProps>`
  display: inline-flex;
  flex-direction: column;
  position: relative;

  height: ${({ height }) => height ?? 'auto'};
  font-family: ${({ theme }) => theme.fonts.primary};
  border-color: ${({ theme }) => theme.input.borderColor};
  color: ${({ theme }) => theme.colors.text};

  ${(props) => props.margin && `margin: ${props.margin};`};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`};
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft};`};
  ${(props) => props.marginRight && `margin-right: ${props.marginRight};`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`};

  ${(props) => props.padding && `padding: ${props.padding};`};
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft};`};
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight};`};
  ${(props) => props.paddingBottom && `padding-bottom: ${props.paddingBottom};`};

  width: ${({ width }) => width ?? '100%'};
`

export const InputLabelContainer = styled.div`
  margin-bottom: 4px;
`

export const RightIconContainer = styled.div`
  position: absolute;
  right: 1rem;
  width: 1rem;
`

export const InputMuiLabel = styled.span<InputMuiLabelProps>`
  position: absolute;
  left: 10px;
  top: ${(props) => (props.isFocused || props.hasValue ? '4.4px' : '55%')};
  transform: translateY(-50%);
  font-size: ${(props) => (props.isFocused || props.hasValue ? '12px' : '15px')};
  color: ${(props) => (props.isFocused ? props.theme.colors.focus : 'gray')};
  letter-spacing: 1px;
  transition: 0.3s;
  padding: ${(props) => (props.isFocused || props.hasValue ? '0px 4px' : '0')};
  background: transparent;
  z-index: 1;
`

export const InputMuiNoBorder = styled.div<InputMuiLabelProps>`
  position: absolute;
  background: ${(props) => props.bgColor ?? 'white'};
  z-index: -1;
  top: ${(props) => (props.isFocused || props.hasValue ? '46%' : '55%')};
  height: 5px;
  right: 0.5px;
  width: 100%;
`

export const InputLabel = styled.label<InputDisabledProps>`
  width: fit-content;
  font-size: ${({ labelFontSize, theme }) => (labelFontSize ? `${labelFontSize}` : theme.input.labelFontSize)};
  font-weight: ${({ labelFontWeight, theme }) => (labelFontWeight ? `${labelFontWeight}` : theme.input.labelFontWeight)};
  font-size: ${({ labelFontSize, theme }) => (labelFontSize ? `${labelFontSize}` : theme.input.labelFontSize)};

  color: ${({ labelColor, disabled, theme }) =>
    disabled ? `${theme.colors.disabled}` : `${labelColor ? labelColor : theme.input.labelColor ?? theme.colors.text};`};
`

export const InputRequired = styled.span<InputDisabledProps>`
  width: fit-content;
  margin-left: 4px;
  color: ${({ disabled, theme }) => (disabled ? `${theme.colors.disabled}` : `${theme.colors.required};`)};
`

export const InputBaseContainer = styled.div<InputContainerProps>`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.input.borderColor};
  border-radius: ${({ theme, borderRadius }) => (borderRadius ? `${borderRadius}` : theme.input.borderRadius)};
  border: 1px solid ${({ theme, borderColor }) => borderColor ?? theme.input.borderColor};
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.input.backgroundColor};
  height: ${({ height, theme }) => height ?? theme.input.height};
  ${({ disabled, error, theme }) =>
    disabled
      ? `border-color: ${theme.colors.disabled};
         background-color: ${theme.colors.disabledBackground};
         color: ${theme.colors.disabled};`
      : error && `border-color: ${theme.colors.error};`}

  :focus-within {
    border-color: ${({ theme, disabledBorderOnFocus }) => (disabledBorderOnFocus ? 'transparent' : theme.colors.focus)};
  }
`

export const InputIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
`

export const InputBase = styled.input<InputBaseProps>`
  border: 0;
  outline: 0;
  width: 100%;
  height: ${({ height }) => height};
  padding-top: 0;
  padding-bottom: 0;
  background-color: transparent;
  color: ${({ theme, placeholderColor }) => placeholderColor ?? theme.colors.text};
  font-size: ${({ theme, fontSize }) => fontSize ?? theme.input.fontSize};
  font-family: ${({ theme, fontFamily }) => fontFamily ?? theme.input.fontFamily};
  font-weight: ${({ theme, fontWeight }) => fontWeight ?? theme.input.fontWeight};
  padding-left: 10px;
  border-radius: ${({ theme, borderRadius }) => borderRadius ?? theme.input.borderRadius};

  ::placeholder {
    color: ${({ theme, placeholderColor }) => placeholderColor ?? theme.colors.placeholder};
    font-weight: ${({ theme }) => theme.input.fontWeight};
  }

  ::-webkit-calendar-picker-indicator {
    display: none;
  }
`

export const PasswordIconContainer = styled.button`
  border: none;
  background-color: white;
  cursor: pointer;
  color: currentColor;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  width: 30px;
  height: 30px;
`

export const InputRightIconContainer = styled.div<InputContainerProps>`
  background-color: ${({ bgColor, theme }) => bgColor ?? theme.input.backgroundColor};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
`
