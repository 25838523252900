import {CircularProgress, Grid, Tooltip, Typography} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import infoIcon from 'src/images/infoIcon.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'
import api from '../../../services/api'
import {CountByCategoriesDTO} from '../../../services/api/endpoints/ReportsEndpoint'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'
import {formatChartCountSeriesAndTranslateNames} from '../DashboardRetailFunctions'

type LineChartTimeSeriesProps = {
  startTime: string
  endTime: string
  selectedNewDay: string | number | undefined
  click: boolean
}

function LineChartTimeSeries({startTime, endTime, selectedNewDay, click}: LineChartTimeSeriesProps) {
  const {enqueueSnackbar} = useSnackbar()

  const [isLoading, setIsloading] = useState(false)
  const [countAccessPerTime, setCountAccessPerTime] = useState<CountByCategoriesDTO>()
  const [showStartDate, setShowStartDate] = useState<string>()

  const loadCountPerDay = async () => {
    try {
      setIsloading(true)
      const selectedNewDayFormattedStart = selectedNewDay && moment(selectedNewDay).startOf('day').format('YYYY-MM-DDTHH:mm')
      const selectedNewDayFormattedEnd = selectedNewDay && moment(selectedNewDay).endOf('day').format('YYYY-MM-DDTHH:mm')
      const start = selectedNewDayFormattedStart || startTime
      const end = selectedNewDayFormattedEnd || endTime
      setShowStartDate(moment(start).format('DD-MM-YYYY'))
      const response = await api.reports.genderCount({start, end, countByDay: false})

      const translateTimeSeries = formatChartCountSeriesAndTranslateNames(response.data.data, 'hour')
      setCountAccessPerTime(translateTimeSeries)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar os dados')
    } finally {
      setIsloading(false)
    }
  }

  useEffect(() => {
    loadCountPerDay()
  }, [click, startTime, selectedNewDay])

  const opts: ApexCharts.ApexOptions = {
    series: countAccessPerTime?.series ? countAccessPerTime.series : [],
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      fontFamily: 'Inter, sans-serif',
      toolbar: {},
    },
    dataLabels: {
      enabled: true,
    },
    colors: ['#0B62B9', '#9c27b0', '#26BA02'],
    xaxis: {categories: countAccessPerTime?.categories},
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    title: {
      text: `Referência: ${showStartDate}`,
      margin: 41,
      align: 'center',
      offsetX: 0,
      offsetY: -15,
      style: {
        fontSize: '14px',
        fontWeight: 0,
        fontFamily: "'Inter', 'sans-serif",
        color: '#364152',
      },
    },
    subtitle: {
      text: 'Quantidade de Acessos',
      offsetX: 0,
      offsetY: 60,
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'none',
        fontFamily: "'Inter', 'sans-serif",
        color: '#8E8E8E',
      },
    },
    grid: {
      show: true,
    },
    markers: {
      size: 1,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        fillColors: ['#0B62B9', '#9c27b0', '#26BA02'],
      },
    },
    noData: {
      text: 'Nenhum dado foi encontrado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 20,
      offsetY: -15,
      style: {
        color: '#000000',
        fontSize: '10px',
        fontFamily: "'Inter', 'sans-serif",
      },
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: true,
      },
    },
  }
  return (
    <Grid container sx={styles.grid} height={427}>
      <Grid item xs={12}>
        <Typography style={styles.title}>Acessos por Hora</Typography>

        <Tooltip title='Clique em um dia da semana no indicador “Acessos por Dias da Semana” para visualizar o acesso por hora da data requerida'>
          <Icon src={infoIcon} cursor='pointer' style={{position: 'absolute', top: '18px', right: '23px'}} />
        </Tooltip>

        {isLoading ? <CircularProgress /> : <ReactApexChart options={opts} series={opts.series} type='line' height={350} />}
      </Grid>
    </Grid>
  )
}

const styles = createStyleSheet({
  grid: {
    padding: 2,
    paddingLeft: 3.5,
    borderRadius: '8px',
    background: 'white',
    position: 'relative',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    width: '100%',
    color: '#364152',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    textAlign: 'center',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'left',
  },
  FilterRangeTimeButton: {
    width: '100px',
    height: '38.5px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginTop: '9.5px',
  },
})

export default LineChartTimeSeries
