import styled from 'styled-components'

export const FloatingImage = styled.img`
  width: 70px;

  @media screen and (max-width: 1200px) {
    width: 65px;
  }

  @media screen and (max-width: 1024px) {
    width: 60px;
  }

  @media screen and (max-width: 767px) {
    width: 50px;
  }
`
