import {FaceDTO} from 'src/common/models/facial-recognition/FaceDTO'
import {FacialListDTO} from 'src/common/models/facial-recognition/FacialListDTO'
import api, {Alarm} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export const selectedFilesMock = [{lastModified: 326522156, name: 'image_mock', webkitRelativePath: 'path_mock', size: 18, type: 'Blob'} as any]
export const imageMock = 'iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5Erk'

function handleExpectedError(newFaceLists: number, enqueueSnackbar: any, base64?: string, name?: string) {
  if (base64 === undefined && name === undefined && newFaceLists === 0) {
    enqueueSnackbar('Todos os campos estão vazios', {variant: 'warning'})
    return
  } else if (name === undefined) {
    enqueueSnackbar('Nome completo pendente', {variant: 'warning'})
    return
  } else if (newFaceLists === 0) {
    enqueueSnackbar('Necessário selecionar uma lista', {variant: 'warning'})
    return
  } else if (base64 === undefined) {
    enqueueSnackbar('Necessário fazer upload de uma imagem', {variant: 'warning'})
    return
  } else return true
}

const handleCreateFacial = (newFaceLists: number, base64: string, name: string, id: string) => {
  return {
    name: name,
    listId: newFaceLists,
    clientId: Number(id),
    image: base64,
  }
}

const handleUpdateFacial = (newFaceLists: number, base64: string, name: string, id: number) => {
  return {
    id: id,
    name: name,
    listId: newFaceLists,
    image: base64 === imageMock ? undefined : base64,
  }
}

async function createRegisterFacial(
  newFaceLists: number,
  enqueueSnackbar: any,
  onSave: () => void,
  loading: (value: boolean) => void,
  getFacialData: (value: boolean, face: FaceDTO) => void,
  base64?: string,
  name?: string,
  id?: string,
) {
  if (handleExpectedError(newFaceLists, enqueueSnackbar, base64, name)) {
    loading(true)
    try {
      const newCreateFace = handleCreateFacial(newFaceLists, base64!, name!, id!)
      const response = (await api.facialRecognition.createFacial(newCreateFace)).data
      if (response.data.isNew === false) {
        getFacialData(true, response.data.face)
      } else {
        onSave()
        enqueueSnackbar('Sucesso ao cadastrar um rosto', {
          variant: 'success',
        })
      }
    } catch (error: any) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao cadastrar rosto')
    } finally {
      loading(false)
    }
  }
}

async function updateRegisterFacial(
  facialId: number,
  newFaceLists: number,
  enqueueSnackbar: any,
  onSave: () => void,
  loading: (value: boolean) => void,
  base64?: string,
  name?: string,
) {
  if (handleExpectedError(newFaceLists, enqueueSnackbar, base64, name)) {
    loading(true)
    try {
      const newUpdateFacial = handleUpdateFacial(newFaceLists, base64!, name!, facialId)
      if (facialId !== null) {
        await api.facialRecognition.updateFacial(newUpdateFacial)
        onSave()
        enqueueSnackbar('Sucesso ao atualizar um rosto', {
          variant: 'success',
        })
      }
    } catch (error: any) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar rosto')
    } finally {
      loading(false)
    }
  }
}

export async function typeMethodFacial(
  newFaceLists: number,
  enqueueSnackbar: any,
  isEdit: boolean,
  onSave: () => void,
  loading: (value: boolean) => void,
  getFacialData: (value: boolean, face: FaceDTO) => void,
  alarm?: Alarm,
  facial?: FacialListDTO,
  base64?: string,
  name?: string,
  id?: string,
) {
  if (isEdit) {
    if (alarm && alarm.faceRecognitions) {
      await updateRegisterFacial(alarm.faceRecognitions[0].faceId!, newFaceLists, enqueueSnackbar, onSave, loading, base64, name)
    } else await updateRegisterFacial(facial?.id!, newFaceLists, enqueueSnackbar, onSave, loading, base64, name)
  } else await createRegisterFacial(newFaceLists, enqueueSnackbar, onSave, loading, getFacialData, base64, name, id)
}

export const handleUseImage = (facial?: FacialListDTO, alarm?: Alarm, isImage?: boolean) => {
  const imageUrl = process.env.REACT_APP_FACE_IMAGES_BASE_URL || ''
  if (facial) return `${imageUrl}/${facial.cropImageKey}`
  else {
    if (alarm && alarm.faceRecognitions) {
      if (alarm.faceRecognitions[0].face && alarm.faceRecognitions[0].face.name !== null) return `${imageUrl}/${alarm.faceRecognitions[0].face.cropImageKey}`
      else return `${imageUrl}/${alarm.faceRecognitions[0].cropImageKey}`
    }
    return isImage ? imageUrl : null
  }
}

export const handleIsEdit = (isEdit: boolean, alarm?: Alarm, facial?: FacialListDTO) => {
  return isEdit || Boolean(alarm?.faceRecognitions![0].face) || Boolean(facial) || Boolean(alarm?.faceRecognitions![0].cropImageKey)
}
