import formatBigNumber from 'src/utilities/formatBigNumber'

export default function safeFormatBigNumber(x: number | null | undefined) {
  if (typeof x === 'number') {
    return formatBigNumber(x)
  } else {
    return '-'
  }
}

export function formatNumberSize(x: number | null | undefined) {
  const fullFormat = safeFormatBigNumber(x)
  let shortFormat = '-'
  if (typeof x === 'number') {
    const numberClasses = fullFormat.split('.')
    shortFormat = numberClasses[0]
    switch (numberClasses.length) {
      case 2:
        shortFormat += ' K'
        break
      case 3:
        shortFormat += ' M'
        break
      case 4:
        shortFormat += ' B'
        break
      case 5:
        shortFormat += ' T'
    }
  }

  return {
    short: shortFormat,
    full: fullFormat,
  }
}
