import {Box} from '@viptech/react-components'
import lodash from 'lodash'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Client, Layout} from 'src/services/api'
import {Page} from 'src/types'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import LayoutGridTable from './components/LayoutGridTable'
import LayoutOptionsMenu from './components/LayoutOptionsMenu'
import LayoutRegisterModal from './components/LayoutRegisterModal'

const searchFilterData = [{id: '1', label: 'Nome do Layout'}]

function Layouts() {
  const hasCreateLayoutsPermission = ValidatePermissions('layouts:create')
  const hasReadClientsPermission = ValidatePermissions('clients:read')
  const initialLayout = {id: 0, name: '', maxCameras: 0, companyId: 0, clientId: 0, layoutTypeId: 0}
  const [layoutSearch, setLayoutSearch] = useState<string>('')
  const layoutSearchDebounced = useDebounce(layoutSearch, 400)
  const [layouts, setLayouts] = useState<Layout[]>([])
  const [loadingLayouts, setLoadingLayouts] = useState<boolean>(false)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [layoutsPage, _setLayoutsPage] = useState<Page>({page: 1, pageSize: 20})
  const [open, setOpen] = useState<boolean>(false)
  const [layout, setLayout] = useState<Layout>(initialLayout)
  const [isCreate, setIsCreate] = useState<boolean>(true)
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanies} = useCompany()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [optButton, setOptButton] = useState<boolean>(false)
  const [layoutIds, setLayoutIds] = useState<number[]>([])
  const [clients, setClients] = useState<Client[]>([])
  const menuOpen = Boolean(menuEl)
  const quantity = 1
  const isPlural: string = quantity === 1 ? '' : 's'
  const isLoadingLayouts = useRef(false)
  const isLoadingCameras = useRef(false)

  function withConfirmation(message: string, title: string) {
    return () => {
      setMenuEl(null)
      setModalContent(title)
      setOpenModal(true)
      setModalTitle(message)
    }
  }

  function setLayoutsPage(page: number) {
    _setLayoutsPage((prev) => ({...prev, page}) as Page)
  }

  function setLayoutsPageSize(pageSize: number) {
    _setLayoutsPage((prev) => ({...prev, pageSize}) as Page)
  }

  async function loadLayouts() {
    try {
      if (isLoadingLayouts.current) return
      isLoadingLayouts.current = true
      setLoadingLayouts(true)
      const response = await api.layout.getMany({
        page: layoutsPage.page === 0 ? 1 : layoutsPage.page,
        pageSize: layoutsPage.pageSize,
        includes: ['cameras'],
        filter: {companyIds: selectedCompanies},
        search: {
          layoutName: layoutSearchDebounced || undefined,
        },
        orderBy: {layoutType: 'DESC'},
      })
      setLayouts(response.data.data.entities)
      setTotalElements(response.data.data.totalElements)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar layouts')
    } finally {
      setLoadingLayouts(false)
      isLoadingLayouts.current = false
    }
  }

  useEffect(() => {
    async function loadClients() {
      if (!hasReadClientsPermission) return

      try {
        if (isLoadingCameras.current) return
        isLoadingCameras.current = true
        const response = await api.camera.getMany({
          page: 1,
          paginate: false,
          filter: {
            companyIds: selectedCompanies,
            origin: 'RTSP',
          },
          includes: ['client'],
        })
        const clients = response.data.data.entities.map((camera) => camera.client)
        const filteredClients = lodash.uniqBy(clients, 'id')
        setClients(filteredClients)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao listar clientes')
      } finally {
        isLoadingCameras.current = false
      }
    }
    loadClients()
    loadLayouts()
  }, [selectedCompanies])

  useEffect(() => {
    setLayoutsPage(1)
  }, [layoutSearchDebounced, selectedCompanies])

  useEffect(() => {
    loadLayouts()
  }, [layoutsPage])

  async function deleteLayout() {
    if (layoutIds.length === 0) throw new Error('Erro ao deletar layout')
    try {
      await api.layout.delete(layoutIds)
      enqueueSnackbar('layout(s) removido(s) com sucesso', {
        variant: 'success',
      })
      loadLayouts()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover o(s) layout(s)')
    }
  }

  function openEditModal(layout: Layout) {
    setIsCreate(false)
    setOpen(true)
    setLayout(layout)
  }

  function showOptionButton(ids: number[]) {
    if (ids.length > 0) {
      setOptButton(true)
      setLayoutIds(ids)
    } else {
      setOptButton(false)
    }
  }

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  const onSelectChange = (e: DropdownItem) => {
    setShowSearchSelected(e)
  }

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton onClick={() => loadLayouts()} loading={loadingLayouts} />

            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchFilterData}
              setSearchSelected={onSelectChange}
              searchSelected={showSearchSelected}
              inputValue={layoutSearch}
              inputSearch={setLayoutSearch}
              widthOnSelect={'270px'}
            />
          </Box>

          <RenderIfConditionIsMet condition={hasCreateLayoutsPermission}>
            <AppAddOrOptionsButton
              text='Adicionar novo layout'
              showOptions={optButton}
              onClickAdd={() => {
                setIsCreate(true)
                setOpen(true)
              }}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
            />
          </RenderIfConditionIsMet>

          <LayoutOptionsMenu
            open={menuOpen}
            anchorEl={menuEl}
            onClose={() => setMenuEl(null)}
            onDelete={withConfirmation(
              `Deletar layout${isPlural}`,
              `Esta ação resultará na exclusão do${isPlural} layout${isPlural} e não poderá ser desfeita.`,
            )}
          />
        </Box>
      </TopBar>
      <LayoutGridTable
        layouts={layouts}
        loadingLayouts={loadingLayouts}
        totalElements={totalElements}
        setLayoutsPage={setLayoutsPage}
        setLayoutsPageSize={setLayoutsPageSize}
        layoutsPage={layoutsPage}
        openEditModal={openEditModal}
        layoutsIds={showOptionButton}
        clients={clients}
      />
      <ConfirmationModal
        title={modalTitle}
        content={modalContent}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenModal(false)
              setMenuEl(null)
              deleteLayout()
            },
            variant: 'contained',
            color: '#009EFF',
          },
        ]}
      />
      {open && (
        <LayoutRegisterModal
          isCreate={isCreate}
          openModal={open}
          closeModal={() => {
            if (!isCreate) setLayout(initialLayout)
            setOpen(false)
          }}
          layout={layout}
          setLayout={setLayout}
          loadLayouts={loadLayouts}
        />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default Layouts
