import {GridAlignment, GridRenderCellParams} from '@mui/x-data-grid'
import {CameraConfiguration} from 'src/services/api'

export type Page = {
  page: number
  pageSize: number
}

export type LoadState = 'IDLE' | 'PENDING' | 'LOADING' | 'COMPLETE' | 'ERROR'

export type NumberFormat = {
  short: string
  full: string
}

export type ColumnsMuiType = {
  field: string
  headerName: string
  minWidth?: number
  maxWidth?: number
  align?: GridAlignment
  headerAlign?: GridAlignment
  flex?: number
  renderCell?: (it: GridRenderCellParams) => JSX.Element
}

export class CameraConfigurationValidator {
  receiveModeId?: number
  brainBoxId?: number
  snapshotHeight?: number
  snapshotWidth?: number
  outputIntegrationId?: number | null
  lprIntegrationId?: number | null
  detectionSensitivity: number
  partition: string

  constructor(obj: Partial<CameraConfiguration>) {
    this.receiveModeId = obj.receiveModeId
    this.brainBoxId = obj.brainBoxId
    this.snapshotHeight = obj.snapshotHeight
    this.snapshotWidth = obj.snapshotWidth
    this.outputIntegrationId = obj.outputIntegrationId
    this.lprIntegrationId = obj.lprIntegrationId
    this.detectionSensitivity = obj.detectionSensitivity!
    this.partition = obj.partition!
  }
}
