import styled from 'styled-components'
import TermHeaderBackground from 'src/images/TermHeaderBackground.png'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #344054;
  position: relative;
  background-image: url(${TermHeaderBackground});
  background-size: cover;
  background-position: center;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000096;
    z-index: 1;
  }
  z-index: 0;
`

export const ImageContainer = styled.img`
  width: 177px;
  height: 39px;
  cursor: pointer;
  z-index: 2;
`

export const HeaderExitButtonContainer = styled.div`
  position: absolute;
  right: 15px;

  @media (max-width: 767px) {
    right: 5px;
  }
`

export const ImageLogoutContainer = styled.img`
  @media (max-width: 767px) {
    display: none;
  }
`
