export type Permission = {
  name: string
}

export const DashboardPermissions: Permission[] = [{name: 'dashboard:read'}]
export const EventsPermissions: Permission[] = [{name: 'events:read'}]
export const LprPermissions: Permission[] = [{name: 'lpr:read'}]
export const NotificationsPermissions: Permission[] = [{name: 'notifications:read'}]
export const ImagensPermissions: Permission[] = [{name: 'transactions:read'}]
export const DevicesPermissions: Permission[] = [{name: 'cameras:read'}]
export const BrainboxPermissions: Permission[] = [{name: 'brainbox:read'}]
export const ClientsPermissions: Permission[] = [{name: 'clients:read'}]
export const LayoutsPermissions: Permission[] = [{name: 'layouts:read'}]
export const CameraMosaicPermissions: Permission[] = [{name: 'livestream:read'}]
export const UserPermissions: Permission[] = [{name: 'users:read'}]
export const CredentialsPermissions: Permission[] = [{name: 'credentials:read'}]
export const ServerEntradaPermissions: Permission[] = [{name: 'servers:read'}]
export const ServerSaidaPermissions: Permission[] = [{name: 'servers:read'}]
export const QuotasPermissions: Permission[] = [{name: 'quotas:read'}]
export const CompaniesPermissions: Permission[] = [{name: 'companies:read'}]
export const AnalyticCamerasPermissions: Permission[] = [{name: 'analytic-cameras:read'}]
export const FaceListPermissions: Permission[] = [{name: 'face:read'}]
export const LprListPermissions: Permission[] = [{name: 'lpr:read'}]
export const B2cPermission: Permission[] = [{name: 'self-registration:create'}]
