import styled from 'styled-components'

export const PaymentFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  margin-top: 20px;
`

export const PaymentFormInputSameLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100%;
`

export const ContainerWrapperForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  @media (max-width: 767px) {
    width: 350px;
  }
`

export const Link = styled.a`
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
`

export const PaymentCardSelectButton = styled.div`
  all: unset;
  display: flex;
  width: 100px;
  height: 100%;
  gap: 11px;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: pointer;
  }
`
