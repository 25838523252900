import {Box, Button, Typography} from '@viptech/react-components'
import {jwtDecode} from 'jwt-decode'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router-dom'
import {Input} from 'src/components/input/Input'
import {CardFlagsEnum, useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {Title} from 'src/pages/self-register/select-plan/components/PlanSelectBase'
import api from 'src/services/api'
import {UserToken} from '../../../../../contexts/AuthContext'
import storage from '../../../../../services/storage'
import CardCVV from '../CardCVV/CardCVV'
import {ContainerWrapperForm, PaymentFormContainer, PaymentFormInputSameLine, Link, PaymentCardSelectButton} from './PaymentFormBase'
import {ContainerBaseInput} from 'src/components/selectWithSearchComponent/SelectWithSearchComponentBase'
import Separation from 'src/components/separation/Separation'
import Dropdown from 'src/components/dropdown/Dropdown'

import mastercard from './card-icons/mastercard.svg'
import amex from './card-icons/amex.svg'
import DinersClub from './card-icons/DinersClub.svg'
import Elo from './card-icons/Elo.svg'
import JCB from './card-icons/JCB.svg'
import visa from './card-icons/visa-logo.svg'

import {DropdownItem} from 'src/components/dropdown/DropdownProps'
import {useState} from 'react'
import {BrainError} from 'src/services/api/endpoints/_types'
import ArrowDownIcon from 'src/images/ArrowDownIcon'
import {CardFlagMap, CardType} from './CardFlagMap'

const MAXTIME = 35000

const listSvgComponentsCard = [
  {
    id: '1',
    label: 'mastercard',
    svgComponent: <img src={mastercard} alt='mastercard' />,
  },
  {
    id: '2',
    label: 'visa',
    svgComponent: <img src={visa} alt='visa' />,
  },
  {
    id: '3',
    label: 'amex',
    svgComponent: <img src={amex} alt='american express' />,
  },
  {
    id: '4',
    label: 'diners',
    svgComponent: <img src={DinersClub} alt='diners' />,
  },
  {
    id: '5',
    label: 'elo',
    svgComponent: <img src={Elo} alt='elo' />,
  },
  {
    id: '6',
    label: 'jcb',
    svgComponent: <img src={JCB} alt='jcb' />,
  },
]

const svgComponentsMap = {
  mastercard: 0,
  visa: 1,
  amex: 2,
  diners: 3,
  elo: 4,
  jcb: 5,
}

function PaymentForm() {
  const {paymentMethod, setPaymentMethod, selectedPlan} = useB2CContext()
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [isLoadingPayment, setIsLoadingPayment] = useState(false)
  const [showSelect, setShowSelect] = useState(false)

  async function retrySuccessStatusRace(promise: Promise<unknown>, power: number, billingId: number) {
    const timeoutInSeconds = Math.pow(2, power)
    const promiseAttempt = new Promise((resolve) =>
      setTimeout(async () => resolve((await api.payments.getBillingStatus(billingId)).data.data), timeoutInSeconds * 1000),
    )
    const raceResult = await Promise.race([promise, promiseAttempt])
    if (raceResult === 'terminate') return false
    if (raceResult === 'failed') return false
    if (raceResult === 'paid') return true
    return retrySuccessStatusRace(promise, power + 1, billingId)
  }

  async function verifyPayment(billingId: number) {
    const terminatePromise = new Promise((resolve) => setTimeout(() => resolve('terminate'), MAXTIME))
    const mayProceed = await retrySuccessStatusRace(terminatePromise, 1, billingId)
    if (!mayProceed) {
      enqueueSnackbar('Erro ao processar pagamento', {variant: 'error'})
      return
    }
    navigate('/camera-config')
  }

  async function asyncRefresh() {
    try {
      const keepLoggedIn = storage.get('keepLoggedIn') === 'true'
      const refreshResponse = await api.auth.refresh(keepLoggedIn)
      storage.clearKeys(['token', 'refreshToken'])
      storage.set('token', refreshResponse.data.data.accessToken, 'local')
      storage.set('refreshToken', refreshResponse.data.data.refreshToken, 'local')
      api.setBearerToken(refreshResponse.data.data.accessToken)
      return refreshResponse.data.data.accessToken
    } catch (err) {
      return false
    }
  }

  async function handleSave() {
    if (!selectedPlan.id) {
      enqueueSnackbar('Selecione um plano novamente', {variant: 'error'})
      navigate('/select-plan')
      return
    }
    if (!paymentMethod.cardNumber || !paymentMethod.cardExpiration || !paymentMethod.cardCVV || !paymentMethod.cardName || !paymentMethod.flag) {
      return enqueueSnackbar('Preencha todos os campos', {variant: 'error'})
    }
    try {
      setIsLoadingPayment(true)
      const token = await asyncRefresh()
      if (!token) return

      const decodedToken = jwtDecode(token) as UserToken
      const companies = decodedToken.payload.authorizedCompanies

      const cardInfoBody = {
        companyId: companies[0],
        cardDigits: paymentMethod.cardNumber,
        flag: paymentMethod.flag || 'mastercard',
        cardName: paymentMethod.cardName,
        validity: paymentMethod.cardExpiration,
        cardCvv: paymentMethod.cardCVV,
      }

      const billingData = {
        planId: selectedPlan.id,
        fullPaymentInfo: cardInfoBody,
        installments: 1,
      }
      const billing = await api.payments.createBilling(billingData)

      if (billing.data.data.bill.status === 'paid') await asyncRefresh()

      await verifyPayment(billing.data.data.bill.id)
    } catch (err) {
      const error = err as BrainError
      enqueueSnackbar(`Erro ao processar pagamento: ${error.response.data.devMessage}`, {variant: 'error'})
    } finally {
      setIsLoadingPayment(false)
    }
  }

  function maskCardNumber(value: string) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{4})(?=\d)/g, '$1 ')
      .trim()
  }

  function handleValidity(value: string) {
    const onlyNumbers = value.replace(/\D/g, '')
    if (onlyNumbers.length < 4) return setPaymentMethod({cardExpiration: onlyNumbers.replace(/\D/g, '')})
    const month = onlyNumbers.slice(0, 2)
    const year = onlyNumbers.slice(2, 4)
    const monthNumber = parseInt(month, 10)
    if (monthNumber < 1 || monthNumber > 12) return enqueueSnackbar('Mês inválido', {variant: 'error'})
    return setPaymentMethod({cardExpiration: `${month}/${year}`})
  }

  const [cardFlagSelected, setCardFlagSelected] = useState<DropdownItem>()

  const handleSvgSelect = (e: DropdownItem) => {
    setCardFlagSelected(e)
    setShowSelect(false)
    const flagEnum = e.label as CardFlagsEnum
    setPaymentMethod({flag: flagEnum})
  }

  function tryDetectFlag(card: string): CardType | 'unknown' {
    const cardNumber = card.replaceAll(' ', '')
    for (const cardType in CardFlagMap) {
      if (CardFlagMap[cardType as CardType].test(cardNumber)) {
        return cardType as CardType
      }
    }
    return 'unknown'
  }

  function handleCardNumberChange(value: string) {
    const detectedFlag = tryDetectFlag(value)
    if (detectedFlag !== 'unknown') {
      setCardFlagSelected(listSvgComponentsCard[svgComponentsMap[detectedFlag]])
      setPaymentMethod({flag: detectedFlag})
    }
    const maskedValue = maskCardNumber(value)
    setPaymentMethod({cardNumber: maskedValue})
  }

  return (
    <ContainerWrapperForm>
      <Title>
        Informe os dados do seu <br /> cartão de crédito
      </Title>
      <PaymentFormContainer>
        <Box display='flex' direction='column' width='100%' rowGap='5px'>
          <Typography variant='span' size='12px' weight={500} color='#344054'>
            Número do cartão
          </Typography>

          <ContainerBaseInput width='100%'>
            <Input.Root
              controlled
              value={paymentMethod.cardNumber}
              labelFontSize='12px'
              borderColor='transparent'
              bgColor='transparent'
              placeholder='0000 0000 0000 0000'
              disabledBorderOnFocus
              maxLength={23}
              onChange={(e) => handleCardNumberChange(e.target.value)}
            />
            <Separation height='80%' width='1px' color='#9aa4b2' />
            {showSelect ? (
              <Dropdown
                id='cardFlagSelect'
                width={'20px'}
                height='100%'
                fontSize='14px'
                labelFontSize='14px'
                borderColor='transparent'
                placeholder=''
                bgColor='white'
                alwaysOpen
                items={listSvgComponentsCard}
                onChangeSelected={(e) => handleSvgSelect(e)}
                disabledBorderColor
                dontReflectValueSelectedOnLabel
              />
            ) : (
              <PaymentCardSelectButton onClick={() => setShowSelect(true)}>
                <>
                  {cardFlagSelected?.svgComponent || <Box width='34px' />}
                  <ArrowDownIcon />
                </>
              </PaymentCardSelectButton>
            )}
          </ContainerBaseInput>
        </Box>

        <PaymentFormInputSameLine>
          <Input.Root
            label='Data de validade'
            controlled
            value={paymentMethod.cardExpiration}
            labelFontSize='12px'
            labelColor='#344054'
            borderColor='#98A2B3'
            placeholder='MM/AA'
            maxLength={4}
            onChange={(e) => handleValidity(e.target.value)}
          />
          <Input.Root
            label='CVV'
            controlled
            value={paymentMethod.cardCVV}
            labelFontSize='12px'
            labelColor='#344054'
            placeholder='000'
            maxLength={4}
            borderColor='#98A2B3'
            onChange={(e) => setPaymentMethod({cardCVV: e.target.value})}
            rightIcon={<CardCVV />}
          />
        </PaymentFormInputSameLine>
        <Input.Root
          label='Nome no cartão'
          controlled
          value={paymentMethod.cardName}
          labelFontSize='12px'
          labelColor='#344054'
          borderColor='#98A2B3'
          onChange={(e) => setPaymentMethod({cardName: e.target.value})}
        />
        <Typography variant='span' size='14px' color='#667085' textAlign='justify'>
          Ao clicar no botão “Iniciar assinatura” abaixo, você concorda com nossos{' '}
          <Link href={`${window.location.origin}/terms`} target='brainTerms'>
            Termos de Uso
          </Link>{' '}
          e com nossa{' '}
          <Link href={`${window.location.origin}/terms`} target='brainTerms'>
            Declaração de Privacidade
          </Link>
          , confirma ter mais de 18 anos e aceita que o Brain renove automaticamente sua assinatura e cobre o preço da assinatura (atualmente R${' '}
          {selectedPlan.price}
          ,00/câmera) da sua forma de pagamento até você cancelar. Você pode cancelar quando quiser para evitar cobranças futuras.
        </Typography>
        <Button
          isLoading={isLoadingPayment}
          variant='contained'
          padding='12px 64px'
          fontSize='14px'
          width='300px'
          themes='primary'
          onClick={() => handleSave()}>
          Iniciar assinatura
        </Button>
      </PaymentFormContainer>
    </ContainerWrapperForm>
  )
}

export default PaymentForm
