import {Box, Button, DropdownItem, Typography} from '@viptech/react-components'
import {useEffect, useRef, useState} from 'react'
import Accordion from '../../../components/accordion/Accordion'
import Dropdown from '../../../components/dropdown/Dropdown'
import {Input} from '../../../components/input/Input'
import {StepperModalActionsButtonContainer} from '../../camera-config/components/object-detection/newComponents/styles'
import CameraRegisterModal from '../components/CameraAddModal/CameraRegisterModal'
import {Item} from '../components/CameraAddModal/CameraRegisterModal/TransferList/types'
import {Camera, ChosenObject} from './CreateCameraStepper'
import {AccessContainer, AccessContainerBox, ContainerSteps, ContainerStepsRow} from './styles'

type AccessCredentialsStepProps = {
  nextFunction: (index: number, resume: string) => void
  returnFunction: (index: number, goBackTo?: number) => void
  handleCreateCameraVMS: () => void
  setCamera: (choices: Partial<Camera>) => void
  chosen: ChosenObject
  setChosen: (choices: Partial<ChosenObject>) => void
  rightItems: Item[]
  setRightItems: (itens: Item[]) => void
  clearContextComponent: () => void
}

const EnumCameraManufacturer: DropdownItem[] = [
  {label: 'Intelbras', id: '1'},
  {label: 'Hikvision', id: '2'},
]

const streamOptions: DropdownItem[] = [
  {id: '0', label: 'Main-stream'},
  {id: '1', label: 'Sub-stream'},
]

const AccessCredentialsStep = (props: AccessCredentialsStepProps) => {
  const {nextFunction, returnFunction, chosen, setChosen, setCamera, handleCreateCameraVMS, rightItems, setRightItems, clearContextComponent} = props

  const creatingRtsp = chosen?.serverName === 'DVR / NVR' || chosen?.serverName === 'Câmera RTSP'
  const [accordionOne, setAccordionOne] = useState(false)
  const [accordionTwo, setAccordionTwo] = useState(false)

  const manufacturerId = EnumCameraManufacturer.find((it) => it.label === chosen.cameraRTSP.manufacturer)?.id || ''
  const [selectedManufacturer, setSelectedManufacturer] = useState({
    id: manufacturerId,
    label: chosen.cameraRTSP.manufacturer,
  })

  const streamLabel = streamOptions.find((it) => +it.id === chosen.cameraRTSP.stream)?.label || ''

  const [selectedStream, setSelectedStream] = useState({
    id: String(chosen.cameraRTSP.stream),
    label: streamLabel,
  })

  const checkIsValidLink = useRef(false)
  const errorMessage = useRef<string | undefined>(undefined)

  const validateLink = (link: string) => {
    if (link) {
      const hasProtocol = link.includes('://')
      const hasUsernamePassword = link.includes('@')

      const isValidLink = hasProtocol && hasUsernamePassword
      checkIsValidLink.current = isValidLink
      if (!isValidLink) errorMessage.current = 'Link inválido'
      else errorMessage.current = undefined
    } else {
      checkIsValidLink.current = false
      errorMessage.current = 'Link inválido'
    }
  }

  function maskValue(value: string, max: number): string {
    const numericValue = +value.replace(/\D/g, '')
    const isValid = numericValue <= max && numericValue >= 0
    if (isValid) {
      return numericValue ? String(numericValue) : ''
    } else {
      return value.slice(0, -1)
    }
  }

  const handleRTSPComponent = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', gap: '20px', margin: '30px 0'}}>
        <Accordion
          titleComponent={<p style={{fontSize: '14px', color: '#344054'}}>Desejo construir o link de conexão</p>}
          controlled={true}
          isOpen={accordionOne}
          onClick={() => {
            setAccordionOne(!accordionOne)
            setAccordionTwo(false)
          }}>
          <AccessContainer>
            <AccessContainerBox>
              <div style={{width: '50%'}}>
                <Dropdown
                  height='40px'
                  fontSize='12px'
                  label='Fabricante da câmera'
                  labelFontSize='12px'
                  placeholder='Fabricante da câmera'
                  items={EnumCameraManufacturer}
                  selected={selectedManufacturer}
                  onChangeSelected={(e) => {
                    setCamera({manufacturer: e.label})
                    setSelectedManufacturer(e)
                  }}></Dropdown>
              </div>
              <div style={{width: '50%', display: 'flex', gap: '20px'}}>
                <Dropdown
                  height='40px'
                  fontSize='12px'
                  placeholder='Stream'
                  labelFontSize='12px'
                  label='Stream'
                  selected={selectedStream}
                  items={streamOptions}
                  onChangeSelected={(e) => {
                    setCamera({stream: +e.id})
                    setSelectedStream(e)
                  }}></Dropdown>

                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  placeholder={chosen?.serverName === 'DVR / NVR' ? 'Insira a quantidade de canais' : 'Informe o canal'}
                  labelFontSize='12px'
                  type='number'
                  defaultValue={String(chosen.cameraRTSP.channel)}
                  label={chosen?.serverName === 'DVR / NVR' ? 'Quantidade de canais' : 'Canal'}
                  min={1}
                  mask={(value) => maskValue(value, 100)}
                  value={`${chosen.cameraRTSP.channel}` || ''}
                  onChange={(e) => setCamera({channel: +e.target.value})}
                />
              </div>
            </AccessContainerBox>

            <AccessContainerBox>
              <div style={{width: '50%'}}>
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  placeholder='Insira o host'
                  label='Host'
                  labelFontSize='12px'
                  defaultValue={chosen.cameraRTSP.host}
                  value={chosen.cameraRTSP.host || ''}
                  onChange={(e) => setCamera({host: e.target.value})}
                />
              </div>

              <div style={{width: '50%', display: 'flex', gap: '20px'}}>
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  type='number'
                  defaultValue={String(chosen.cameraRTSP.httpPort)}
                  label='Porta HTTP'
                  labelFontSize='12px'
                  placeholder='Insira a porta HTTP'
                  value={`${chosen.cameraRTSP.httpPort}` || ''}
                  mask={(value) => maskValue(value, 65535)}
                  onChange={(e) => setCamera({httpPort: +e.target.value})}
                />
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  labelFontSize='12px'
                  type='number'
                  defaultValue={String(chosen.cameraRTSP.rtspPort)}
                  placeholder='Insira a porta RTSP'
                  label='Porta RTSP'
                  mask={(value) => maskValue(value, 65535)}
                  value={`${chosen.cameraRTSP.rtspPort}` || ''}
                  onChange={(e) => setCamera({rtspPort: +e.target.value})}
                />
              </div>
            </AccessContainerBox>

            <AccessContainerBox>
              <Input.Root
                maxLength={50}
                labelFontWeight='400'
                placeholder='Insira o usuário'
                labelFontSize='12px'
                label='Usuário'
                defaultValue={chosen.cameraRTSP.user}
                value={chosen.cameraRTSP.user || ''}
                onChange={(e) => setCamera({user: e.target.value})}
              />
              <Input.Root
                maxLength={50}
                labelFontWeight='400'
                placeholder='Insira a senha'
                labelFontSize='12px'
                label='Senha'
                type='password'
                hasIcon
                defaultValue={chosen.cameraRTSP.password}
                value={chosen.cameraRTSP.password || ''}
                onChange={(e) => setCamera({password: e.target.value})}
              />
            </AccessContainerBox>
          </AccessContainer>
        </Accordion>
        <Accordion
          titleComponent={<p style={{fontSize: '14px', color: '#344054'}}>Desejo informar o link de conexão completo</p>}
          controlled={true}
          isOpen={accordionTwo}
          onClick={() => {
            setAccordionTwo(!accordionTwo)
            setAccordionOne(false)
          }}>
          <div style={{padding: '10px'}}>
            <Input.Root
              maxLength={150}
              labelFontWeight='400'
              label='Link RTSP'
              labelFontSize='12px'
              value={chosen.cameraRTSP.link || ''}
              defaultValue={chosen.cameraRTSP.link}
              onChange={(e) => {
                setCamera({link: e.target.value})
                validateLink(e.target.value)
              }}
              error={!checkIsValidLink.current}
              errorMessage={errorMessage.current}
            />
          </div>
        </Accordion>
      </div>
    )
  }

  const labelOnButton = () => {
    if (creatingRtsp) return 'Próximo'
    return 'Finalizar'
  }

  const handleNextFunction = () => {
    if (creatingRtsp) return handleCreateRTSP()
    return handleCreateCameraVMS()
  }

  const handleCreateRTSP = () => {
    setChosen({
      createRTSPWithLink: accordionTwo,
    })
    nextFunction(2, 'Concluído')
  }

  const disabledButton = () => {
    if (creatingRtsp) {
      if (accordionOne) {
        const checkCamera = chosen.cameraRTSP
        if (
          checkCamera.channel &&
          checkCamera.host &&
          checkCamera.httpPort >= 0 &&
          checkCamera.manufacturer &&
          checkCamera.password &&
          checkCamera.rtspPort >= 0 &&
          checkCamera.stream >= 0 &&
          checkCamera.user
        )
          return false
        return true
      } else {
        return !checkIsValidLink.current
      }
    } else if (!rightItems.length) return true
  }

  useEffect(() => {
    disabledButton()
  }, [chosen])

  return (
    <ContainerSteps>
      <ContainerStepsRow>
        <Box display='flex' direction='column' width='100%'>
          <Box align='center' justifyContent='center'>
            <Typography variant='span' size='16px' style={{fontWeight: 500}}>
              Cadastro de câmera
            </Typography>
            <Typography variant='p' size='12px' color='#8E8E8E'>
              Preencha abaixo as informações sobre a sua câmera.
            </Typography>
            {creatingRtsp && (
              <Typography variant='span' size='12px' color='#8E8E8E'>
                Você pode fornecer o link de conexão de duas maneiras: preenchendo-o passo a passo ou inserindo o link completo.
              </Typography>
            )}
          </Box>

          {creatingRtsp ? handleRTSPComponent() : <CameraRegisterModal rightItems={rightItems} setRightItems={setRightItems} />}
        </Box>
      </ContainerStepsRow>

      <StepperModalActionsButtonContainer>
        <Button
          fontSize='12px'
          height='40px'
          width='150px'
          variant='outlined'
          color='#8E8E8E'
          onClick={() => {
            clearContextComponent()
            returnFunction(0)
          }}>
          Voltar
        </Button>
        <Button fontSize='12px' disabledTextColor='#8E8E8E' height='40px' width='150px' disabled={disabledButton()} onClick={() => handleNextFunction()}>
          {labelOnButton()}
        </Button>
      </StepperModalActionsButtonContainer>
    </ContainerSteps>
  )
}

export default AccessCredentialsStep
