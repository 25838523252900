import styled from 'styled-components'
import {PaginationBaseProps} from './PaginationProps'

export const PaginationContainer = styled.div<{color: string; width: string}>`
  width: ${({width}) => width};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.5rem;
`

export const PaginationInnerContainer = styled.div<{width?: string; justifyContent: string}>`
  display: flex;
  justify-content: ${({justifyContent}) => justifyContent};
  align-items: center;
`

export const PaginationNumberButton = styled.button<PaginationBaseProps>`
  border: none;
  width: 2rem;
  height: 2rem;
  color: ${({color}) => color || 'black'};
  background-color: ${({backgroundColor}) => backgroundColor || 'transparent'};
  font-size: ${({fontSize}) => fontSize};
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: ${({hoverColor}) => hoverColor || '#e0e0e0'};
  }
`
