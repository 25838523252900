import {Box} from '@viptech/react-components'
import {useState} from 'react'
import {ConfirmationModal} from 'src/components/ConfirmationModal/ConfirmationModal'
import CreateListModal from './components/listsComponents/CreateListModal'
import ListTable from './components/listsComponents/ListsTable'

function Lists() {
  const [isOpen, setIsOpen] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [callback, setCallback] = useState<() => void>(() => {})
  const [refreshCount, setRefreshCount] = useState(0)

  function handleCloseModal() {
    setIsOpen(false)
    setRefreshCount((prev) => prev + 1)
  }

  return (
    <Box backgroundColor='white' borderRadius='8px'>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title='Deletar lista'
        content='Os registros desta lista serão removidos. Esta ação não pode ser revertida.'
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setIsConfirmationOpen(false),
            variant: 'outlined',
            color: 'black',
            hoverColor: 'darkgray',
          },
          {
            label: 'Excluir',
            onClick: () => callback(),
            variant: 'contained',
            dangerAction: true,
          },
        ]}
      />
      <CreateListModal isOpen={isOpen} closeModal={() => handleCloseModal()} />
      <ListTable
        refresh={refreshCount}
        isCreateListOpen={isOpen}
        setIsCreateListOpen={setIsOpen}
        setIsConfirmationOpen={setIsConfirmationOpen}
        setCallback={setCallback}
      />
    </Box>
  )
}

export default Lists
