import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'
import {DropdownItem} from '@viptech/react-components'
import {useEffect} from 'react'
import SearchIconInputSmallChangeColor from '../../images/SearchIconInputSmall'
import Dropdown from '../dropdown/Dropdown'
import {Input} from '../input/Input'
import Separation from '../separation/Separation'
import {AdvancedFiltersWrapper, ContainerBaseInput, ContainerBaseInputResponsive, SelectWithSearchWrapper} from './SelectWithSearchComponentBase'

export type MultipleSearchDropdownItem = {
  id: string
  label: string
  searchBy: string
}

export type SelectWithSearchComponentProps = {
  searchBy: DropdownItem[]
  searchSelected: DropdownItem | MultipleSearchDropdownItem
  setSearchSelected: (search: any) => void
  inputSearch: (input: string) => void
  inputValue?: string
  filterBy?: any[]
  setSelectedFilter?: (selected: any) => void
  disabledSelectFilter?: boolean
  multipleSearch?: boolean
  handleMultipleSearch?: () => void
  widthOnSelect?: string
  dontSetSearchBy?: boolean
  hideAdvancedFilters?: boolean
}

export function SelectWithSearchComponent(props: SelectWithSearchComponentProps) {
  const {
    searchBy,
    multipleSearch,
    handleMultipleSearch,
    setSearchSelected,
    searchSelected,
    filterBy,
    setSelectedFilter,
    inputSearch,
    inputValue,
    disabledSelectFilter,
    widthOnSelect,
    dontSetSearchBy,
    hideAdvancedFilters,
  } = props

  useEffect(() => {
    if (!dontSetSearchBy) setSearchSelected(searchBy[0])
  }, [searchBy])

  return (
    <SelectWithSearchWrapper>
      <ContainerBaseInput>
        <Dropdown
          width={widthOnSelect ?? '160px'}
          fontSize='14px'
          labelFontSize='14px'
          borderColor='transparent'
          bgColor='white'
          items={searchBy}
          disabledBorderColor
          selected={searchSelected}
          onChangeSelected={(e) => {
            setSearchSelected(e)
          }}
        />

        <Separation height='80%' width='1px' color='#9aa4b2' />

        <Input.Root
          fontSize='14px'
          controlled
          value={inputValue || ''}
          placeholder='Pesquisar'
          placeholderColor={inputValue ? '#1976D2' : 'gray'}
          borderColor='transparent'
          bgColor='transparent'
          disabledBorderOnFocus
          onChange={(e) => inputSearch(e.target.value)}
          leftIcon={<SearchIconInputSmallChangeColor color='#1976D2' />}
        />

        {multipleSearch && inputValue && (
          <button disabled={!inputValue} onClick={() => handleMultipleSearch?.()} style={{background: 'none', marginRight: '10px', marginTop: '5px'}}>
            <PostAddOutlinedIcon color={!inputValue ? 'disabled' : 'primary'} />
          </button>
        )}
      </ContainerBaseInput>

      <ContainerBaseInputResponsive>
        <Input.Root
          fontSize='14px'
          controlled
          value={inputValue || ''}
          placeholder='Pesquisar'
          placeholderColor={inputValue ? '#1976D2' : 'gray'}
          borderColor='transparent'
          bgColor='transparent'
          disabledBorderOnFocus
          width='100%'
          onChange={(e) => inputSearch(e.target.value)}
          leftIcon={<SearchIconInputSmallChangeColor color='#1976D2' />}
        />
      </ContainerBaseInputResponsive>

      {!hideAdvancedFilters && filterBy && (
        <AdvancedFiltersWrapper>
          <Dropdown
            className='pai'
            height='40px'
            width='160px'
            fontSize='14px'
            labelFontSize='14px'
            borderColor='#009EFF'
            bgColor='white'
            iconColor='#009EFF'
            placeholder='Adicionar Filtro'
            items={filterBy}
            placeholderColor='#009EFF'
            disabled={disabledSelectFilter}
            onChangeSelected={(e) => {
              setSelectedFilter?.(e)
            }}
            dontReflectValueSelectedOnLabel
          />
        </AdvancedFiltersWrapper>
      )}
    </SelectWithSearchWrapper>
  )
}
