import {TopBarContainer} from './TopBarBase'

type AppTopBarProps = {
  children: JSX.Element | JSX.Element[]
}

const TopBar = ({children}: AppTopBarProps) => {
  return <TopBarContainer>{children}</TopBarContainer>
}

export default TopBar
