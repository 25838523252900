import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'
import {Company} from './CompanyEndpoint'

const prefix = process.env.REACT_APP_COMPANIES_BASE_URL

export type ClientInclude = 'company' | 'cameraStatus' | 'cameras' | 'moniClientInfo' | 'analyticCameras' | 'onePortariaClientInfo'

export type Client = {
  id: number
  name: string
  email: string
  sigmaAccount?: string
  sigmaClientId: null | number
  sigmaLastCheckedDate: string
  isDeleted: boolean
  companyId: number
  cameraStatusId: number
  cameras: number[]
  moniClientInfo?: MoniClientInfo | null
  origin?: string
  defaultLayoutId?: number
  isClientDefault: boolean
  totalOfCameras: number
  onePortariaClientInfo?: OnePortariaInfo | null
  company?: Company
}

export type CreateClient = {
  clientName: string
  email?: string | null
  sigmaAccount?: string | null
  companyId?: number
  cameraStatusId?: number
  moniClientInfo?: MoniClientInfo | null
  onePortariaClientInfo?: OnePortariaInfo | null
}

export type MoniClientInfo = {
  clientCode?: string
  moniCompanyId?: string
  sector?: string
}

export type OnePortariaInfo = {
  clientCode?: string
  sector?: number
  clientId?: number
  companyCode?: string
}

export type AbstractEditClient = {
  clientName: string
  email?: string | null
  cameraStatusId?: number
  defaultLayoutId?: number
}

export type SigmaClient = {
  sigmaAccount: string
} & AbstractEditClient

export type MoniClient = {
  moniClientInfo: MoniClientInfo
} & AbstractEditClient

export type OnePortariaClient = {
  onePortariaClientInfo: OnePortariaInfo
} & AbstractEditClient

export type RemovingIntegration = {
  sigmaAccount?: null
  moniClientInfo?: null
  onePortariaClientInfo?: null
} & AbstractEditClient

export type ClientEdit = SigmaClient | MoniClient | OnePortariaClient | RemovingIntegration

export type GetClientsParams = {
  page?: number
  pageSize?: number
  includes?: ClientInclude[]
  paginate?: boolean
  filter?: {
    ids?: number[]
    companyIds?: number[]
    sigmaAccountIds?: number[]
    serverTypeId?: number
  }
  search?: {
    name?: string
  }
}

export type DispatchTestEventResponse = {
  status: number
  devMessage: string
  message: string
  response?: any
}

export type DispatchTestEvent = {
  code: string
  companyId: number
}

export class ClientEndpoint {
  async getMany(params: GetClientsParams): Promise<AxiosResult<Paginate<Client>>> {
    return await axios.get(`${prefix}/clients`, {
      params: prepareParams(params),
    })
  }

  async getById(clientId: number): Promise<AxiosResult<Client>> {
    return await axios.get(`${prefix}/client`, {params: {id: clientId}})
  }

  async createOne(body: CreateClient): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/client`, body)
  }

  async updateOne(clientId: number, body: ClientEdit): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/client`, {id: clientId, ...body})
  }

  async deleteOne(clientId: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/client`, {params: {id: clientId}})
  }

  async deleteMany(clientIds: number[]): Promise<Promise<AxiosResult<any>>[]> {
    return clientIds.map(async (clientId) => {
      return await this.deleteOne(clientId)
    })
  }

  async dispatchTestEvent(body: DispatchTestEvent): Promise<AxiosResult<DispatchTestEventResponse>> {
    return await axios.post(`${prefix}/client/test-event`, body)
  }
}
