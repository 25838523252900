import styled from 'styled-components'
import {ButtonProps} from './stylesProps'

export const ContainerStepsRow = styled.div`
  display: flex;
  gap: 80px;
  flex: 1;
  min-height: 460px;
  @media (max-width: 1400px) {
    min-height: 400px;
  }
`

export const ContainerSteps = styled.div`
  padding: 20px 30px;
`

export const TransparentButton = styled.button<ButtonProps>`
  background: transparent;
  width: 100%;
  color: ${(props) => props.color};
  height: 30px;
  font-size: 14px;
  margin: 0;
  border: ${(props) => (props.border ? '1px solid #009EFF' : 'none')};
`

export const PlayButton = styled.button<ButtonProps>`
  background: transparent;
  width: 100%;
  color: ${(props) => props.color};
  height: 40px;
  width: 40px;
  font-size: 14px;
  margin: 0;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: ${(props) => (props.border ? '1px solid #009EFF' : 'none')};
  :disabled {
    border: 1px solid grey;
  }
`

export const ContainerClientsCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: 'center';
  align-items: 'center';
  gap: 10px;
  padding-top: 20px;
  height: 150px;
  overflow: auto;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

export const AccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 245px;
  overflow: auto;
  @media (max-width: 1400px) {
    height: 180px;
  }
`
export const AccessContainerBox = styled.div`
  display: flex;
  gap: 20px;
  min-height: 75px;
`
export const ContainerServersCards = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: 'center';
  align-items: 'center';
  gap: 10px;
  margin-top: 10px;
`

export const ContainerServers = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`

export const ContainerChannels = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 5fr 4fr 0.3fr;
  width: 100%;
  gap: 15px;
`
export const ContainerChannelsTitles = styled.div`
  display: grid;
  grid-template-columns: 0.1fr 5fr 4fr 0.3fr;
  gap: 15px;
  padding: 20px 0;
`

export const ChannelsTitles = styled.span<{fontWeight: number}>`
  color: #58595b;
  font-size: 12px;
  font-style: normal;
  font-weight: ${(props) => props.fontWeight};
  line-height: 16px;
  padding-left: 5px;
`

export const ContainerCameras = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 290px;
  min-height: 250px;
  overflow: auto;
  gap: 10px;
  padding-right: 8px;
  margin-bottom: 20px;
  @media (max-width: 1400px) {
    min-height: 200px;
  }
`

export const ContainerCamerasVisualization = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;
  max-height: 290px;
  min-height: 250px;
  overflow: auto;
  gap: 10px;
  padding-right: 8px;
  margin-bottom: 20px;
  @media (max-width: 1400px) {
    min-height: 200px;
  }
`

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 290px;
  overflow: auto;
  gap: 10px;
  padding-right: 8px;
  margin-bottom: 20px;
  @media (max-width: 1400px) {
    height: 200px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 0px;
  background: none;
`
