import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-direction: column;
`

export const IntegrationsTitle = styled.h2`
  line-height: 1px;
`

export const IntegrationName = styled.h3`
  color: #46464f;
  line-height: 1px;
`

export const TableHeader = {
  backgroundColor: '#46464f',
  color: 'white',
}

export const TableCell = styled.td`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: inherit;
  text-align: center;
  padding: 16px;
`
