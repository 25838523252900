import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {BtnClose} from 'src/components/BtnClose'
import {AddButtonModal, CancelButtonModal} from 'src/pages/users/styles'
import api, {Company, User} from 'src/services/api'
import {Quota, Resource} from 'src/services/api/endpoints/CompanyQuotaEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type QuotasModal = {
  open: boolean
  handleClose: () => void
  loadQuotas: () => void
  closeModal: () => void
  quotaUser: Quota
  setQuota: (quota: Quota) => void
  isCreate: boolean
  companies: Company[]
  user: User

  setCompaniesScrollQuotas: (companies: Company[]) => void
}

function QuotasRegisterModal({
  open,
  handleClose,
  loadQuotas,
  quotaUser,
  setQuota,
  isCreate,
  companies,
  user,

  setCompaniesScrollQuotas,
}: QuotasModal) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {enqueueSnackbar} = useSnackbar()
  const [resources, setResources] = useState<Resource[]>([])
  const [totalPage, setTotalPage] = useState<number>(0)
  const [pageCount, setPageCount] = useState<number>(0)

  async function loadResource() {
    try {
      const response = await api.quota.getAllResource()
      setResources(response.data.data.entities)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar cotas')
    }
  }

  useEffect(() => {
    loadResource()
  }, [])

  useEffect(() => {
    async function loadFilteredCompanies() {
      try {
        const response2 = await api.company.getMany({
          filter: {
            from: moment().toLocaleString(),
          },
        })
        setPageCount(pageCount + 1)

        setCompaniesScrollQuotas(response2.data.data.entities)
        setTotalPage(response2.data.data.totalPages!)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar empresas')
      }
    }
    loadFilteredCompanies()
  }, [])

  async function updateQuota(id: number) {
    setIsLoading(true)
    try {
      await api.quota.update(id, {quota: Number(quotaUser.quota), step: quotaUser.step ? quotaUser.step : undefined})
      enqueueSnackbar('Cota atualizada com sucesso', {
        variant: 'success',
      })
      loadQuotas()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar cota')
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  async function loadMoreItems(e: any) {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight
    if (bottom) {
      if (pageCount < totalPage) {
        const response = await api.company.getMany({
          page: pageCount + 1,
          pageSize: 100,
          filter: {
            from: moment().toLocaleString(),
          },
        })
        setTotalPage(response.data.data.totalPages!)
        response.data.data.entities.forEach((it) => {
          if (it.id !== quotaUser.companyId) {
            companies.push(it)
          }
        })
      }

      setPageCount(pageCount + 1)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper' maxWidth='md'>
      <DialogTitle sx={styles.dialogTitle}>{!isCreate ? 'Editar' : 'Adicionar'} Cota</DialogTitle>
      <BtnClose onClose={handleClose} />
      <form
        onSubmit={(event) => {
          event.preventDefault()
          updateQuota(quotaUser && quotaUser.id!)
        }}>
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '100%',
            }}>
            <Grid item xs={6.5} sx={styles.gridItem}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label' sx={{width: '420px'}}>
                  Empresa
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Empresa'
                  sx={{width: '420px'}}
                  required
                  autoFocus={true}
                  value={quotaUser.companyId}
                  onChange={(e) => {
                    setQuota({...quotaUser, companyId: Number(e.target.value)})
                  }}
                  disabled={!isCreate}
                  MenuProps={{
                    PaperProps: {
                      onScroll: loadMoreItems,
                      onLoad: loadMoreItems,
                    },
                    style: {
                      maxHeight: 500,
                    },
                  }}>
                  {companies.map((company, index) => (
                    <MenuItem key={`role-${company.id}-${index}`} value={company.id}>
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6.5} sx={styles.gridItem}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Tipo de Recurso</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Tipo de Recurso'
                  sx={{width: '420px'}}
                  required
                  value={quotaUser.resource?.id}
                  onChange={(e) => {
                    setQuota({...quotaUser, resource: {id: Number(e.target.value)}})
                  }}
                  disabled={!isCreate}>
                  {resources.map((resource, index) => (
                    <MenuItem key={`role-${resource}-${index}`} value={resource.id}>
                      {resource.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3.5} sx={styles.gridItem}>
              <FormControl fullWidth>
                <TextField
                  label='Quantidade cotas'
                  variant='outlined'
                  type={'number'}
                  value={quotaUser.quota}
                  autoComplete='false'
                  sx={{width: '420px'}}
                  onChange={(e) =>
                    setQuota({
                      ...quotaUser,
                      quota: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            {quotaUser.resource?.id === 3 && (
              <Grid item xs={3.5} sx={styles.gridItem}>
                <FormControl fullWidth>
                  <TextField
                    label='Período (em ms)'
                    variant='outlined'
                    type={'number'}
                    value={quotaUser.step}
                    required
                    autoComplete='false'
                    sx={{width: '420px'}}
                    onChange={(e) =>
                      setQuota({
                        ...quotaUser,
                        step: Number(e.target.value),
                      })
                    }
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{gap: '0px'}}>
          <Box sx={{marginTop: 3}}>
            <Button onClick={handleClose} sx={CancelButtonModal}>
              Cancelar
            </Button>
            <LoadingButton
              type={'submit'}
              loading={isLoading}
              variant='contained'
              sx={AddButtonModal}
              disabled={quotaUser.resource === undefined || quotaUser.quota === undefined || quotaUser.companyId === undefined}>
              Salvar
            </LoadingButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const styles = createStyleSheet({
  gridContainer: {
    m: 0,
    p: 0,
    height: '55vh',
  },
  gridItem: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '0px',
    marginTop: '2px',
  },
  gridItemLogin: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    marginTop: '10px',
    marginBottom: '10px',
  },
  dialogTitle: {paddingTop: 3, color: '#353535'},
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    overflowY: 'auto',
    height: '100%',
  },
  actions1: {
    padding: 0,
  },
  content2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  fullWidthItem: {
    width: '300px',
  },
})

export default QuotasRegisterModal
