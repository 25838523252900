import {Grid} from '@mui/material'
import sub from 'date-fns/sub'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {CameraAnalyticDTO} from 'src/services/api/endpoints/AnalyticCameraEndpoint'
import api from '../../services/api'
import {CountByCategoriesDTO, PeopleCountDTO, RateByGroupsDTO} from '../../services/api/endpoints/ReportsEndpoint'
import handleErrorWithSnackbar from '../../utilities/handleErrorWithSnackbar'
import AnalyticCamerasProvider, {useAnalyticCamerasContext} from '../analytic-cameras/context/AnalyticCamerasContext'
import {ChartEventFilterDate} from '../dashboard/components/DashboardEventsStatus'
import {formatChartSeriesAndTranslateNames, hexColorDictionary, translateCategories} from './DashboardRetailFunctions'
import ColumnChartsCard from './components/ColumnChartsCard'
import DonutChartsCard from './components/DonutChartsCard'
import HeaderDashRetail from './components/HeaderDashRetail'
import HitCounter from './components/HitCounter'
import LineChartAccessPerWeek from './components/LineChartAccessPerWeek'
import LineChartTimeSeries from './components/LineChartTimeSeries'
import LinearProgressCard from './components/LinearProgressCard'
import PeopleCountCard from './components/PeopleCountCard'

function DashboardRetail() {
  const {enqueueSnackbar} = useSnackbar()

  const [filterDate, setFilterDate] = useState<ChartEventFilterDate>({
    start: moment(sub(new Date(), {minutes: 30})).format('YYYY-MM-DDTHH:mm'),
    end: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
  })

  const handleChangeTimeStart = (dates: [Date, Date]) => {
    let start = moment(dates[0]).format('YYYY-MM-DDTHH:mm')
    let end = moment(dates[1]).format('YYYY-MM-DDTHH:mm')
    if (dates[0] === dates[1]) {
      start = moment(dates[0].setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm')
      end = moment(dates[1].setHours(23, 59, 59, 59)).format('YYYY-MM-DDTHH:mm')
    }
    setFilterDate({
      start,
      end,
    })
  }

  const [isLoadingCountPeople, setLoadingCountPeople] = useState(false)
  const [peopleCount, setPeopleCount] = useState<PeopleCountDTO>()
  const [percentageOfAccess, setPercentageOfAccess] = useState({passedAtMorning: 0, passedAtAfternoon: 0})
  const [rateGenderByDayPeriod, setRateGenderByDayPeriod] = useState({
    femalePassedAtAfternoon: 0,
    malePassedAtAfternoon: 0,
    femalePassedAtMorning: 0,
    malePassedAtMorning: 0,
  })

  const [isloadingRate, setIsloadingRate] = useState(false)
  const categoriesToRate = ['trousersType', 'hairStyle', 'age', 'ageGroup']
  const [ageGroupCount, setAgeGroupCount] = useState<CountByCategoriesDTO>()
  const [ageCount, setAgeCount] = useState<CountByCategoriesDTO>()
  const [hairStyle, setHairStyle] = useState<CountByCategoriesDTO>()
  const [trousersType, setTrousersType] = useState<CountByCategoriesDTO>()

  const [isloadingGroups, setIsloadingGroups] = useState(false)
  const groupsToRate = ['faceExpression', 'direction', 'jacketColor']
  const [jacketColor, setJacketColor] = useState<RateByGroupsDTO>()
  const [direction, setDirection] = useState<RateByGroupsDTO>()
  const [faceExpression, setFaceExpression] = useState<RateByGroupsDTO>()
  const [colors, setColors] = useState<Array<string>>()

  const [isloadingSensitiveData, setIsloadingSensitiveData] = useState(false)
  const [sensitiveDataRate, setSensitiveDataRate] = useState<Array<{label: string; value: number}>>()

  const start = filterDate.start
  const end = filterDate.end

  const [refreshAt, setRefreshAt] = useState<string>('')
  const [analyticCameras, setAnalyticCameras] = useState<CameraAnalyticDTO[]>([])

  const {filteredClients, clients, filteredAnalyticCameras} = useAnalyticCamerasContext()
  const [filteredClientsIds, setFilteredClientsIds] = useState<Array<number | undefined>>()
  const [filteredCamerasIds, setFilteredCamerasIds] = useState<Array<number | undefined>>()

  // eslint-disable-next-line
  const clientIds = clients.map((it) => {
    if (filteredClients.length && filteredClients.includes(it.name)) return it.id
  })

  // eslint-disable-next-line
  const camerasIds = analyticCameras.map((it) => {
    if (filteredAnalyticCameras.includes(it.name)) return it.id
  })

  const [click, setClick] = useState(false)
  const [selectedNewDay, setSelectedNewDay] = useState<string | number | undefined>()

  const loadPeopleCount = async () => {
    try {
      setLoadingCountPeople(true)
      setRefreshAt(moment(Date.now()).format('DD-MM-YYYY HH:mm:ss'))
      const res = await api.reports.peopleCount({start, end, filter: {clientIds: filteredClientsIds, cameraIds: filteredCamerasIds}})
      setPeopleCount(res.data.data)

      const {data: response} = await api.reports.rateGenderByDayPeriod({
        start,
        end,
        filter: {clientIds: filteredClientsIds, cameraIds: filteredCamerasIds},
      })

      let genderPassedAt = {
        femalePassedAtAfternoon: 0,
        malePassedAtAfternoon: 0,
        femalePassedAtMorning: 0,
        malePassedAtMorning: 0,
      }
      // eslint-disable-next-line
      response.data.map((it) => {
        if (it.period === 'afternoon' && it.gender === 'female') genderPassedAt.femalePassedAtAfternoon = it.quantity * 100
        else if (it.period === 'afternoon' && it.gender === 'male') genderPassedAt.malePassedAtAfternoon = it.quantity * 100
        else if (it.period === 'morning' && it.gender === 'female') genderPassedAt.femalePassedAtMorning = it.quantity * 100
        else if (it.period === 'morning' && it.gender === 'male') genderPassedAt.malePassedAtMorning = it.quantity * 100
      })
      setRateGenderByDayPeriod(genderPassedAt)

      let calcOfPercentage = {
        passedAtAfternoon: genderPassedAt.femalePassedAtAfternoon + genderPassedAt.malePassedAtAfternoon,
        passedAtMorning: genderPassedAt.femalePassedAtMorning + genderPassedAt.malePassedAtMorning,
      }
      setPercentageOfAccess(calcOfPercentage)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar dados')
    } finally {
      setLoadingCountPeople(false)
    }
  }

  const loadRateByCategories = async () => {
    try {
      setIsloadingRate(true)

      let allRates = categoriesToRate.map(async (it) => {
        const res = await api.reports.categoriesCount({
          start,
          end,
          groupBy: 'gender',
          categorizeBy: it,
          filter: {clientIds: filteredClientsIds, cameraIds: filteredCamerasIds},
        })
        const translateSeries = formatChartSeriesAndTranslateNames(res.data.data, it)

        if (it === 'trousersType') setTrousersType(translateSeries)
        else if (it === 'hairStyle') setHairStyle(translateSeries)
        else if (it === 'age') setAgeCount(translateSeries)
        else if (it === 'ageGroup') setAgeGroupCount(translateSeries)
      })

      await Promise.all(allRates)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar dados')
    } finally {
      setIsloadingRate(false)
    }
  }

  const loadRateByGroups = async () => {
    try {
      setIsloadingGroups(true)

      const allRates = groupsToRate.map(async (it) => {
        const res = await api.reports.groupsCount({
          start,
          end,
          groupBy: it,
          filter: {clientIds: filteredClientsIds, cameraIds: filteredCamerasIds},
        })
        const translateLabels = translateCategories(res.data.data.labels)
        const translateSeries: RateByGroupsDTO = {
          series: res.data.data.series.map((it) => Number(it.toFixed(2)) * 100),
          labels: translateLabels,
        }

        if (it === 'jacketColor') {
          setJacketColor(translateSeries)
          setColors(hexColorDictionary(res.data.data.labels))
        } else if (it === 'direction') setDirection(translateSeries)
        else if (it === 'faceExpression') setFaceExpression(translateSeries)
      })
      await Promise.all(allRates)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar dados')
    } finally {
      setIsloadingGroups(false)
    }
  }

  const loadRateBySensitiveData = async () => {
    try {
      setIsloadingSensitiveData(true)

      const res = await api.reports.sensitiveData({
        start,
        end,
        groups: ['glass', 'bag', 'hat', 'mask'],
        filter: {clientIds: filteredClientsIds, cameraIds: filteredCamerasIds},
      })
      const translateLabels: Array<string> = translateCategories(res.data.data.labels)
      const sensitiveData = res.data.data.series.map((it, index) => {
        return {
          value: it ? +it.toFixed(2) * 100 : 0,
          label: translateLabels[index],
        }
      })

      setSensitiveDataRate(sensitiveData)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar dados')
    } finally {
      setIsloadingSensitiveData(false)
    }
  }

  const loadDataOnSelects = async () => {
    try {
      const response = await api.analyticCamera.getAnalyticCameras({page: 1, pageSize: 100, filter: {clientIds}})
      setAnalyticCameras(response.data.data.entities)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar dados')
    }
  }

  useEffect(() => {
    setFilteredClientsIds(clientIds)
    setFilteredCamerasIds(camerasIds)
  }, [filteredClients, filteredAnalyticCameras])

  useEffect(() => {
    if (filteredClients === undefined || filteredCamerasIds === undefined) return
    loadPeopleCount()
    loadRateByCategories()
    loadRateByGroups()
    loadRateBySensitiveData()
    loadDataOnSelects()
  }, [filterDate, filteredClientsIds, filteredCamerasIds])

  const refreshDash = () => {
    loadPeopleCount()
    loadRateByCategories()
    loadRateByGroups()
    loadRateBySensitiveData()
  }

  return (
    <Grid container spacing={'1.35em'}>
      <Grid item container xs={12} spacing={'1.35em'}>
        <Grid item xs={12}>
          <HeaderDashRetail refreshAt={refreshAt} cameraOptions={analyticCameras} onClick={() => refreshDash()} handleChangeTimeStart={handleChangeTimeStart} />
        </Grid>
      </Grid>

      <Grid item container xs={12} spacing={'1.35em'}>
        <Grid item container xs={6} spacing={'1em'}>
          <Grid item xs={6}></Grid>
        </Grid>
        <Grid item container xs={6} spacing={'1em'}>
          <Grid item container xs={12} spacing={'1em'}>
            <Grid item xs={4}>
              <PeopleCountCard title='Pessoas' loading={isLoadingCountPeople} value={peopleCount?.count} />
            </Grid>
            <Grid item md={4}>
              <PeopleCountCard title='Acessaram' loading={isLoadingCountPeople} value={peopleCount?.passed} />
            </Grid>
            <Grid item md={4}>
              <PeopleCountCard title='Não Acessaram' loading={isLoadingCountPeople} value={peopleCount?.notPassed} />
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={'1em'}>
            <Grid item xs={6}>
              <HitCounter
                title='Acessos Manhã'
                loading={isLoadingCountPeople}
                value={percentageOfAccess.passedAtMorning}
                menCount={rateGenderByDayPeriod.malePassedAtMorning}
                womanCount={rateGenderByDayPeriod.femalePassedAtMorning}
              />
            </Grid>
            <Grid item md={6}>
              <HitCounter
                title='Acessos Tarde'
                loading={isLoadingCountPeople}
                value={percentageOfAccess.passedAtAfternoon}
                menCount={rateGenderByDayPeriod.malePassedAtAfternoon}
                womanCount={rateGenderByDayPeriod.femalePassedAtAfternoon}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={'1.35em'}>
        <Grid item xs={3}>
          <ColumnChartsCard title='Faixa Etária' loading={isloadingRate} data={ageGroupCount?.series} labelsXaxis={ageGroupCount?.categories} height='200px' />
        </Grid>
        <Grid item xs={3}>
          <ColumnChartsCard title='Idade' loading={isloadingRate} data={ageCount?.series} labelsXaxis={ageCount?.categories} height='200px' />
        </Grid>
        <Grid item xs={3}>
          <DonutChartsCard
            title='Humor'
            loading={isloadingGroups}
            data={faceExpression?.series}
            labelsXaxis={faceExpression?.labels}
            height='300px'
            infos={'pânico, surpreso, feliz, enojado, inexpressivo ou triste'}
          />
        </Grid>
        <Grid item xs={3}>
          <DonutChartsCard
            title='Direção'
            infos={'esquerda, para trás, em frente ou direta'}
            loading={isloadingGroups}
            data={direction?.series}
            labelsXaxis={direction?.labels}
            height='300px'
          />
        </Grid>
      </Grid>

      <Grid item container spacing={'1.35em'}>
        <Grid item xs={3}>
          <ColumnChartsCard title='Penteado' loading={isloadingRate} data={hairStyle?.series} labelsXaxis={hairStyle?.categories} height='200px' />
        </Grid>
        <Grid item xs={3}>
          <ColumnChartsCard title='Tipo da Roupa ' loading={isloadingRate} data={trousersType?.series} labelsXaxis={trousersType?.categories} height='200px' />
        </Grid>
        <Grid item xs={3}>
          <DonutChartsCard
            title='Cor da Roupa'
            loading={isloadingGroups}
            data={jacketColor?.series}
            labelsXaxis={jacketColor?.labels}
            height='300px'
            colors={colors}
            infos={'azul, marron, preta, amarela, branca, cinza, vermelha, verde, roxa, rosa, violeta ou laranja'}
          />
        </Grid>
        <Grid item xs={3}>
          <LinearProgressCard title='Dados Sensíveis' loading={isloadingSensitiveData} data={sensitiveDataRate} height='300px' />
        </Grid>
      </Grid>

      <Grid item container spacing={'1.35em'}>
        <Grid item xs={6}>
          <LineChartAccessPerWeek startTime={start} endTime={end} setSelectedNewDay={setSelectedNewDay} setClick={setClick} />
        </Grid>
        <Grid item xs={6}>
          <LineChartTimeSeries startTime={start} endTime={end} selectedNewDay={selectedNewDay} click={click} />
        </Grid>
      </Grid>
    </Grid>
  )
}

function DashRetail() {
  return (
    <AnalyticCamerasProvider>
      <DashboardRetail />
    </AnalyticCamerasProvider>
  )
}
export default DashRetail
