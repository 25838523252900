import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {Tooltip} from '@mui/material'
import {Box, Button} from '@viptech/react-components'
import {HeaderPageTitle} from './components/HeaderPageTitle'
import {HandleCompanieSelect} from './components/HandleCompanieSelect'
import {CompaniesExpirationDateMenu} from './components/CompaniesExpirationDateMenu'
import {NotificationsComponent} from './components/NotificationsComponent'
import {ProfileMenuComponent} from './components/ProfileMenuComponent'
import {HeaderContainer} from './HeaderBase'
import {useAuth} from 'src/contexts/AuthContext'
import openSidebar from 'src/images/iconSideBar.svg'
import {useWindowSize} from 'usehooks-ts'

type HeaderProps = {
  handleChangeTimeStart: (dates: [Date, Date]) => void
  setOpenDrawer: (open: boolean) => void
}

export function Header({handleChangeTimeStart, setOpenDrawer}: HeaderProps) {
  const {userIsB2c} = useAuth()
  const {width} = useWindowSize()

  return (
    <HeaderContainer>
      {width <= 767 && (
        <Button variant='outlined' color='transparent' hoverColor='transparent' onClick={() => setOpenDrawer(true)}>
          <img src={openSidebar} alt='abrir side bar' />
        </Button>
      )}

      <Box marginLeft='20px'>
        <HeaderPageTitle />
      </Box>

      <Box display='flex' align='center' columnGap='13px'>
        <HandleCompanieSelect handleChangeTimeStart={handleChangeTimeStart} />
        {!userIsB2c && <CompaniesExpirationDateMenu />}
        <NotificationsComponent />

        <Tooltip title='Precisa de ajuda?'>
          <Button
            width='50%'
            color='transparent'
            hoverColor='transparent'
            padding='0'
            onClick={() => window.open('https://wa.me/message/ID6OZ7HFR5R2H1', 'blank')}>
            <WhatsAppIcon sx={{color: '#25D366', height: '28px', width: '28px'}} />
          </Button>
        </Tooltip>

        <ProfileMenuComponent />
      </Box>
    </HeaderContainer>
  )
}
