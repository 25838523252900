import React, {useEffect} from 'react'
import TableHeader from './components/tableHeader/TableHeader'
import {TableComponent, TableContainer} from './TableBase'
import {TableProps} from './TableProps'
import LoadingTableRow from './components/loadingTableRow'

function Table({
  width,
  height,
  margin,
  padding,
  columns,
  isLoading,
  loadingDataHeight,
  skeletonRowsAmount,
  isOpenAdvancedSearch,
  onAdvancedSearch,
  onCloseAdvancedSearch,
  children,
  tableHeaderChildren,
  tableBodyProps,
  ...headerProps
}: TableProps) {
  const loadingArray = new Array(skeletonRowsAmount ?? 5).fill(1)

  function handleColumns() {
    return columns[columns.length - 1].id.trim().length !== 0 && onCloseAdvancedSearch ? [...columns, {id: '', name: ''}] : columns
  }

  useEffect(() => {
    if (!isOpenAdvancedSearch && onAdvancedSearch) onAdvancedSearch(null)
  }, [isOpenAdvancedSearch])

  return (
    <TableContainer width={width} height={height} margin={margin} padding={padding}>
      <TableComponent>
        {tableHeaderChildren ?? <TableHeader columns={handleColumns()} {...headerProps} />}
        {tableBodyProps ?? (
          <tbody>
            {isLoading
              ? loadingArray.map((_, index) => <LoadingTableRow key={index} columns={handleColumns()} loadingDataHeight={loadingDataHeight} />)
              : children}
          </tbody>
        )}
      </TableComponent>
    </TableContainer>
  )
}

export default Table
