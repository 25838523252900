import styled from 'styled-components'
import {TableHeaderSortButtonProps, TableHeaderSortProps, TableHeaderStyleProps} from './TableHeaderProps'

export const TableHeaderContainer = styled.thead`
  @media only screen and (max-width: 760px), (min-device-width: 761px) and (max-device-width: 1024px) {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: auto;
    padding: 0;
    position: absolute;
    width: 1px;
  }
`

export const TableHeaderSortButton = styled.button<TableHeaderSortButtonProps>`
  ${({isOpen}) => !isOpen && 'visibility: hidden;'};
  ${({isOpen}) => !isOpen && 'opacity: 0.4;'};
  transition: all 0.1s ease-in-out;
  border: none;
  width: fit-content;
  background-color: transparent;
`

export const TableHeaderColumn = styled.th<TableHeaderStyleProps & TableHeaderSortProps>`
  top: 0;
  height: ${({headerHeight, theme}) => headerHeight ?? theme.table.tableHeader.height};

  font-size: ${({headerFontSize, theme}) => headerFontSize ?? theme.table.tableHeader.fontSize};
  text-align: ${({headerTextAlign, theme}) => headerTextAlign ?? theme.table.tableHeader.textAlign};
  font-family: ${({headerFontFamily, theme}) => headerFontFamily ?? theme.table.tableHeader.fontFamily};
  font-weight: ${({headerFontWeight, theme}) => headerFontWeight ?? theme.table.tableHeader.fontWeight};

  border-top: ${(props) => props.headerBorderTop && props.headerBorderTop};
  border-left: ${(props) => props.headerBorderLeft && props.headerBorderLeft};
  border-right: ${(props) => props.headerBorderRight && props.headerBorderRight};
  border-bottom: ${({headerBorderBottom, theme}) => headerBorderBottom ?? theme.table.tableHeader.borderBottom};
  border-radius: ${(props) => props.headerBorderRadius && props.headerBorderRadius};
  border: ${(props) => props.headerBorder && props.headerBorder};
  border-color: ${({headerBorderColor, theme}) => headerBorderColor ?? theme.table.tableHeader.borderColor};

  padding: ${({headerPadding, theme}) => headerPadding ?? theme.table.tableHeader.padding};
  padding-top: ${(props) => props.headerPaddingTop && props.headerPaddingTop};
  padding-left: ${(props) => props.headerPaddingLeft && props.headerPaddingLeft};
  padding-right: ${(props) => props.headerPaddingRight && props.headerPaddingRight};
  padding-bottom: ${(props) => props.headerPaddingBottom && props.headerPaddingBottom};

  color: ${({headerColor, theme}) => headerColor ?? theme.table.tableHeader.color};
  background-color: ${({headerBackgroundColor, theme}) => headerBackgroundColor ?? theme.table.tableHeader.backgroundColor};

  cursor: ${({cursor}) => cursor ?? 'auto'};

  ${({charAmount}) => `min-width: ${(charAmount ?? 0) + 4}ch`};

  :hover ${TableHeaderSortButton} {
    visibility: visible;
  }

  @media (max-width: 767px) {
    font-size: 10px;
    padding: 0 10px;
  }
`

export const TableHeaderRow = styled.tr<TableHeaderStyleProps>`
  border-left: ${(props) => props.headerExternalLeftBorder && props.headerExternalLeftBorder};
  border-right: ${(props) => props.headerExternalRightBorder && props.headerExternalRightBorder};
  gap: 10px;

  @media only screen and (max-width: 760px), (min-device-width: 761px) and (max-device-width: 1024px) {
    display: block;
    margin-bottom: 0.625em;
  }
`
