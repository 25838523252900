import {Box, Button, Card, Grid, Tooltip, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {AspectRatio} from 'react-aspect-ratio'
import ReactPlayer from 'react-player'
import {useNavigate} from 'react-router-dom'
import api, {Camera, Snapshot} from 'src/services/api'
import storage from 'src/services/storage'
import Loading from '../../../components/Loading'
import MosaicIconSelected from '../../../images/mosaicIconSelected.svg'
import {AxiosErrorGeneric} from 'src/utilities/RequestError'

export type defaultProps = {
  cameras: number[]
  mosaico: number
  openCameraModal: (stream: StreamsAsync) => void
}

type StreamsAsync = {tag: 'Empty'} | {tag: 'Loading'} | {tag: 'Success'; data: Camera; hash: string} | {tag: 'Error'; message?: any; data?: Snapshot | null}

function LayoutDefault(props: defaultProps) {
  const {cameras, mosaico, openCameraModal} = props
  const [streams, setStreams] = useState<StreamsAsync[]>([])
  const tooltipCam = `Exibir player <br/> da câmera`
  const navigate = useNavigate()

  function getMessageHtml() {
    return <div dangerouslySetInnerHTML={{__html: tooltipCam}} />
  }

  const getColumnQuantity = () => {
    switch (mosaico) {
      case 1:
        return {sm: 12, xl: 12}
      case 4:
        return {xs: 12, md: 6, sm: 6, lg: 6, xl: 6}
      case 9:
        return {xs: 12, sm: 6, md: 4, lg: 4, xl: 4}
      case 16:
        return {xs: 12, sm: 6, md: 3, xl: 3}
      case 32:
        return {xs: 12, sm: 6, md: 3, xl: 3}
      default:
        break
    }
  }

  const getItemsQuantityByMosaico = () => {
    switch (mosaico) {
      case 1:
        return 1
      case 4:
        return 2
      case 9:
        return 3
      case 16:
        return 4
      case 32:
        return 8
      default:
        return 1
    }
  }

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      } else {
        navigate(-1)
      }
    }
  }

  function handleFullscreenChange() {
    if (!document.fullscreenElement) {
      navigate(-1)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    async function loadConnection() {
      if (!cameras) return
      setStreams(Array.from({length: mosaico}, (_, index) => (index < cameras.length ? {tag: 'Loading'} : {tag: 'Empty'})))

      await Promise.allSettled(
        cameras.map(async (camera, index) => {
          const response2 = await api.camera.getById(camera, {includes: ['server']})
          const cam = response2.data.data

          try {
            const response = await api.streaming.getStream(camera)
            const liveStream = response.data.data

            setStreams((lastStream) => {
              const newStream = [...lastStream]
              if (liveStream !== null) {
                newStream[index] = {tag: 'Success', data: cam, hash: liveStream}
              } else {
                newStream[index] = {tag: 'Error'}
              }
              return newStream
            })
          } catch (error: any) {
            const axiosError = error as AxiosErrorGeneric
            let snapshot: Snapshot | null = null
            setStreams((lastStream) => {
              const newStream = [...lastStream]
              newStream[index] = {tag: 'Error', message: axiosError.response?.data.message, data: snapshot}
              return newStream
            })
          }
        }),
      )
    }
    loadConnection()
  }, [cameras])

  return (
    <Grid container sx={{backgroundColor: 'black'}}>
      {streams.map((streamAsync, index) => (
        <Grid item {...getColumnQuantity()} sx={{...styles.gridItem, height: `calc(100vh / (${mosaico / getItemsQuantityByMosaico()}))`}} key={index}>
          <Card sx={mosaico === 1 ? styles.card : styles.cardSmall}>
            {streamAsync.tag === 'Loading' ? (
              <Box>
                <Loading />
              </Box>
            ) : streamAsync.tag === 'Empty' ? (
              <Box sx={{color: 'white', justifyContent: 'center', fontSize: '20px', display: 'flex'}}>Sem Câmera</Box>
            ) : streamAsync.tag === 'Success' ? (
              <Box sx={{width: '100%', height: '100%', position: 'relative'}}>
                <Box sx={styles.boxInfos}>
                  <Typography sx={mosaico === 1 ? styles.cameraInfo1 : mosaico === 4 ? styles.cameraInfo4 : styles.cameraInfo}>
                    #({streamAsync.data.id}) {streamAsync.data.name}
                  </Typography>
                  <Tooltip title={getMessageHtml()}>
                    <Button
                      sx={styles.cameraPlayer}
                      onClick={() => {
                        openCameraModal(streamAsync)
                      }}>
                      <img src={MosaicIconSelected} alt='Ver camera' style={mosaico === 1 ? {width: 30, height: 30} : {}} />
                    </Button>
                  </Tooltip>
                </Box>
                <AspectRatio ratio={16 / 9}>
                  <Box>
                    <ReactPlayer
                      url={`${api.streaming.startStream(streamAsync.hash)}`}
                      config={{
                        file: {
                          hlsOptions: {
                            forceHLS: true,
                            debug: false,
                            xhrSetup: function (xhr: any) {
                              xhr.setRequestHeader('Authorization', `Bearer ${storage.get('token')}`)
                            },
                          },
                        },
                      }}
                      autoPlay
                      playing={true}
                      width={'100%'}
                      height={'100%'}
                    />
                  </Box>
                </AspectRatio>
              </Box>
            ) : (
              <Box sx={styles.streamError}>
                <img src={`data:image/jpeg;base64,${streamAsync.data?.base64}`} alt='Imagem' style={styles.image} />
                <Box sx={mosaico === 32 ? styles.messageError32 : mosaico === 16 ? styles.messageError16 : styles.messageError}>{streamAsync.message}</Box>
              </Box>
            )}
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

const styles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#000000',
    border: '1px solid #3E3E3E',
  },
  cardSmall: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#000000',
    border: '1px solid #3E3E3E',
  },
  image: {
    m: 1,
    backgroundColor: '#000000',
    width: '100%',
    height: '100%',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  video: {
    position: 'relative',
    paddingTop: '56.25%',
  },
  cameraInfo: {
    fontSize: '16px',
    fontWeight: 'bold',
    fontFamily: 'Inter',
    '-webkit-text-stroke-width': '0.5px',
    '-webkit-text-stroke-color': '#000',
  },
  cameraInfo1: {
    paddingBottom: 3,
    fontSize: '35px',
    '-webkit-text-stroke-width': '1px',
    '-webkit-text-stroke-color': '#000',
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  cameraInfo4: {
    paddingBottom: 1,
    fontSize: '22px',
    '-webkit-text-stroke-width': '0.5px',
    '-webkit-text-stroke-color': '#000',
    fontFamily: 'Inter',
    fontWeight: 'bold',
  },
  cameraPlayer: {
    zIndex: 9999,
    width: '35px',
    minWidth: 0,
    height: '30px',
  },
  messageError: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    justifyContent: 'center',
    fontSize: '20px',
    display: 'flex',
    backgroundColor: '#444444',
    zIndex: '1',
  },
  messageError32: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    justifyContent: 'center',
    fontSize: '14px',
    width: '200px',
    display: 'flex',
    backgroundColor: '#444444',
    zIndex: '1',
  },
  messageError16: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    justifyContent: 'center',
    fontSize: '16px',
    width: '220px',
    display: 'flex',
    backgroundColor: '#444444',
    zIndex: '1',
  },
  streamError: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  boxInfos: {
    position: 'absolute',
    display: 'flex',
    color: '#fff',
    flexDirection: 'column',
    margin: '10px 20px',
  },
}

export default LayoutDefault
