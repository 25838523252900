import React, {ReactNode} from 'react'
import styled from 'styled-components'

const LinkBase = styled.div`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: #00639f;
  }
`
export type LinkWithActionProps = {
  children: ReactNode
  onClick: (event: React.MouseEvent) => void
  onAuxClick?: (event: React.MouseEvent) => void
}

function LinkWithAction(props: LinkWithActionProps) {
  const {children, onClick, onAuxClick} = props
  return (
    <LinkBase onAuxClick={(event) => onAuxClick && onAuxClick(event)} onClick={(event) => onClick(event)}>
      {children}
    </LinkBase>
  )
}

export default LinkWithAction
