import {Box, Button, Input, Modal} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useCallback, useState} from 'react'
import {InfoFacialRecognition} from 'src/common/components/register-facial-recognition/components/info-facial-recognition/InfoFacialRecognition'
import {UploadPhotos} from 'src/common/components/register-facial-recognition/components/upload-photos/UploadPhotos'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export type CreateFaceModalProps = {
  isOpen: boolean
  closeModal: () => void
  listId: number
}

function CreateFaceModal(props: CreateFaceModalProps) {
  const {isOpen, closeModal, listId} = props
  const [name, setName] = useState('')
  const [base64, setBase64] = useState<string>()
  const updateSetImage = useCallback((base64?: string) => setBase64(base64), [])
  const {enqueueSnackbar} = useSnackbar()

  function handleClose() {
    setName('')
    setBase64(undefined)
    closeModal()
  }

  async function handleSaveList() {
    try {
      const faceDTO = {
        name,
        listId,
        image: base64!,
        ignoreExisting: true,
      }
      await api.facialRecognition.createFacial(faceDTO)
      enqueueSnackbar('Face vinculada com sucesso', {variant: 'success'})
      handleClose()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao vincular face')
    }
  }

  return (
    <Modal isOpen={isOpen} closeModal={() => handleClose()} backgroundColor='white' width='620px' height='680px' padding='0px'>
      <Box padding='24px'>
        <Box position='absolute' right='24px' top='24px'>
          <Icon src={CloseIcon} cursor='pointer' onClick={() => handleClose()} />
        </Box>
        <Box fontWeight='600' fontSize='22px' color='black'>
          Cadastro de Face
        </Box>
        <Box width='100%' display='flex' direction='column' justify='center' align='center' rowGap='20px' padding='10px 0px 20px 0px'>
          <Input.Root width='97%' label='Nome completo' onChange={(e) => setName(e.target.value)} />
          <Box height='200px' paddingTop='5px'>
            <UploadPhotos getImageBase64={updateSetImage} editImage={false} imageKey={null} status={undefined} isEdit={false} />
          </Box>
        </Box>
        <Box display='flex' align='center' justify='center' direction='column'>
          <Box width='95%'>
            <InfoFacialRecognition />
          </Box>
        </Box>
        <Box display='flex' direction='row' columnGap='10px' align='center' justify='flex-end' paddingTop='20px'>
          <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => handleClose()}>
            Cancelar
          </Button>
          <Button fontSize='12px' height='40px' width='150px' disabled={!name || !base64} disabledTextColor='#8E8E8E' onClick={() => handleSaveList()}>
            Adicionar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CreateFaceModal
