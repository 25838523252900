import {EnumRecurrence} from 'src/layouts/b2c-layout/contexts/B2CContext'
import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_PAYMENTS_BASE_URL

export type Plan = {
  id: number
  name: string
  price: number
  recurrence: EnumRecurrence.ANNUALY | EnumRecurrence.MONTHLY
  description: string
}

export class PlanEndpoint {
  async getPlans(): Promise<AxiosResult<Plan[]>> {
    return await axios.get(`${prefix}/plans`)
  }
}
