import {Box, Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import actionsObject from 'src/images/actionsObject.svg'
import Delete from 'src/images/deleteIconBlack.svg'
import IconEditUser from 'src/images/iconEditUser.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'

type BrainBoxTableOptionProps = {
  oneObject: string
  onClick: (event: string, oneObject: string) => void
  hasDeletePermission: boolean
  hasUpdatePermission: boolean
}

export const BrainBoxTableOption = (clientsTableOptionProps: BrainBoxTableOptionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {oneObject, onClick, hasDeletePermission, hasUpdatePermission} = clientsTableOptionProps
  return (
    <>
      <Tooltip title='Opções' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('edit-box', oneObject)
          }}
          disabled={!hasUpdatePermission}>
          <img src={IconEditUser} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Dados do Dispositivo</Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick('delete', oneObject)
          }}
          disabled={!hasDeletePermission}>
          <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = createStyleSheet({
  menuInfos: {
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
  },
})
