import {Box, Table, TableData} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {ConfirmationModal} from 'src/components/ConfirmationModal/ConfirmationModal'
import RowHover from 'src/components/RowHover/RowHover'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api from 'src/services/api'
import {Page} from 'src/types'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import TopBar from '../../../../components/top-bar/TopBar'
import AppReloadButton from '../../../../components/appReloadButton/AppReloadButton'
import Pagination from '../../../../components/pagination/Pagination'
import usePlateListItems from '../../hooks/usePlateListItems'
import {ListsContextMenu} from '../listsComponents/ListsContextMenu'
import CreatePlateModal from './CreatePlateModal'

const columns = [
  {
    id: 'plate',
    name: 'Placas Vinculadas',
  },
  {
    id: 'description',
    name: 'Descrição',
  },
  {
    id: 'actions',
    name: '',
  },
]

function PlatesListTable() {
  const {enqueueSnackbar} = useSnackbar()
  const [, setMenuEl] = useState<null | HTMLElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const hasDeletePlatePermission = ValidatePermissions('lpr:delete')
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [callback, setCallback] = useState<() => void>(() => {})
  const [page, setPage] = useState<Page>({
    page: 1,
    pageSize: 20,
  })

  const {sidebarTitle, setSidebarTitle} = useApp()

  const setCurrentPage = (page: number): void => {
    setPage((prev: Page) => ({...prev, page}))
  }

  const setPageSize = (pageSize: number): void => {
    setPage((prev: Page) => ({...prev, pageSize}))
  }

  const control = {
    currentPage: page.page,
    setCurrentPage: setCurrentPage,
    changePageSize: setPageSize,
    nextPage: () => {
      setCurrentPage(page.page + 1)
    },
    prevPage: () => {
      setCurrentPage(page.page - 1)
    },
  }

  const {search} = useLocation()
  const listId = +search.split('=')[1]

  const {listItems, isLoading, totalItems, name, error, refresh} = usePlateListItems({listId, page})

  useEffect(() => {
    setSidebarTitle(`${sidebarTitle} > ${name}`)
  }, [name])

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Erro ao carregar listas', {variant: 'error'})
    }
  }, [error])

  useEffect(() => {
    refresh()
  }, [page])

  const [selectedIds] = useState<string[]>([])

  async function handleDeleteList(id: string) {
    try {
      await api.lpr.deletePlate(listId, id)
      enqueueSnackbar('Placa deletada com sucesso', {variant: 'success'})
      return refresh()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao deletar placa')
    }
  }

  async function handleConfirmDelete(id: string) {
    await handleDeleteList(id)
    setIsConfirmationOpen(false)
  }

  return (
    <Box borderRadius='8px' align='center' direction='column' backgroundColor='white'>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        title='Deletar placa'
        content='Deseja realmente deletar esta placa? Esta ação não pode ser revertida.'
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setIsConfirmationOpen(false),
            variant: 'outlined',
            color: 'black',
            hoverColor: 'darkgray',
          },
          {
            label: 'Excluir',
            onClick: () => callback(),
            variant: 'contained',
            dangerAction: true,
          },
        ]}
      />

      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={isLoading} onClick={() => refresh()} />
          </Box>
          <AppAddOrOptionsButton
            text='Vincular uma placa'
            onClickAdd={() => setIsOpen(true)}
            showOptions={selectedIds.length > 1}
            onClickOptions={(e) => setMenuEl(e.currentTarget)}
            sx={{width: '200px'}}
          />
        </Box>
      </TopBar>

      <CreatePlateModal isOpen={isOpen} closeModal={() => setIsOpen(false)} listId={listId} refreshList={refresh} />

      <Box height='79vh' overflow='auto' padding='0 20px' display='flex' direction='column'>
        <Box flex={1} display={'flex'}>
          <Table columns={columns} isLoading={isLoading}>
            {listItems.map((row) => {
              const key = `${row.licensePlate}`
              return (
                <RowHover key={key}>
                  <TableData>{row.licensePlate}</TableData>
                  <TableData>{row.description}</TableData>
                  <TableData>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                      <ListsContextMenu
                        hideLists
                        hasDeletePermission={hasDeletePlatePermission}
                        onClick={(event, rowListId) => {
                          if (event === 'delete') {
                            setIsConfirmationOpen(true)
                            setCallback(() => () => handleConfirmDelete(rowListId))
                          }
                        }}
                        rowId={row.licensePlate}
                      />
                    </div>
                  </TableData>
                </RowHover>
              )
            })}
          </Table>
        </Box>

        {!isLoading && listItems.length === 0 ? (
          <Box display='flex' align='center' justify='center' height='72vh'>
            Nenhum registro encontrado
          </Box>
        ) : (
          <Box display='flex' align='flex-start' justify='flex-end' margin='15px 0'>
            <Pagination.Root
              backgroundColor='#009EFF'
              color='white'
              totalItems={totalItems}
              itemsPerPage={page.pageSize}
              possibleItemsPerPage={[20, 50, 100]}
              control={control}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default PlatesListTable
