import CloseIcon from 'src/images/closeIconModal.svg'

interface BtnCloseProps {
  onClose: () => void
}

export const BtnClose = ({onClose}: BtnCloseProps) => {
  return (
    <button style={styles} onClick={onClose}>
      <img src={CloseIcon} alt='close' style={{height: '25px', width: '25px'}} />
    </button>
  )
}
const styles: Record<string, string> = {
  position: 'absolute',
  right: '10px',
  top: '13px',
  minWidth: '0',
  background: 'none',
}
