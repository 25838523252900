import {useEffect} from 'react'
import {createPortal} from 'react-dom'
import {ModalBackground, ModalContainer} from './ModalBase'
import {ModalProps} from './ModalProps'

export default function ModalCustom({isOpen, bgOpacity, bgBackgroundColor, children, closeModal, ...props}: ModalProps) {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Escape') closeModal?.()
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [isOpen])

  useEffect(() => {
    if (isOpen) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return isOpen ? (
    <>
      {createPortal(
        <>
          <ModalBackground onClick={closeModal} bgBackgroundColor={bgBackgroundColor} bgOpacity={bgOpacity} />
          <ModalContainer {...props}>{children}</ModalContainer>
        </>,
        document.body,
      )}
    </>
  ) : null
}
