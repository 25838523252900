import React, {useEffect, useMemo, useState} from 'react'
import {Group, Image, Layer, Line, Stage, Text} from 'react-konva'
import {DetectionTooltip} from 'src/common/components/detection-tooltip/DetectionTooltip'
import {DetectionTooltipProps} from 'src/common/generics/props/DetectionTooltipProps'
import {ImageDetectionsProps} from 'src/common/generics/props/ImageDetectionsProps'
import {Dim, Pos} from 'src/common/types/generics/ImageDetections'
import {boxScale, boxToPoints, computeScalar, detectionToBox, dim2pos, pos2dim, posDefault, posScale} from 'src/common/utils/Internal'
import useImage from 'src/hooks/useImage'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import api from 'src/services/api'

export function CamerasKonvaImageDetections({
  src,
  detections = [],
  hideDetections = false,
  scale = 1,
  width,
  height,
  children,
  StageProps,
  konvaImageRef,
}: React.PropsWithChildren<ImageDetectionsProps>) {
  const {_dimImage, setDimImage, setAreaItems, camera} = useCameraConfigContext()
  const image = useImage(src)
  const [tooltipProps, setTooltipProps] = useState<DetectionTooltipProps>({
    x: 0,
    y: 0,
    text: '',
    visible: false,
  })
  const internalScale = computeScalar(scale, _dimImage, {width, height})
  const posImage: Pos = dim2pos(_dimImage)
  const dimScaled: Dim = pos2dim(posDefault(posScale(posImage, internalScale), posImage))

  function updateTooltipProps(props: Partial<DetectionTooltipProps>) {
    setTooltipProps((lastProps) => ({...lastProps, ...props}))
  }

  useEffect(() => {
    image.decode().then(() => {
      setDimImage({width: image.width, height: image.height})
    })
    async function loadInterestAreas() {
      if (!camera) return
      const response = await api.interestArea.getInterestAreas({
        cameraId: camera.id,
      })
      setAreaItems(
        response.data.data.map((it) => {
          const points = it.coordinates.map((it2) => {
            return {
              id: it2.id,
              x: it2.x * image.width,
              y: it2.y * image.height,
              interestAreaId: it2.interestAreaId,
            }
          })

          return {
            key: `${it.name} ${it.id}`,
            label: it.name,
            points,
            data: {area: it, updated: false},
          }
        }),
      )
    }
    loadInterestAreas()
  }, [image])

  const lines = useMemo(() => {
    return detections.map((it, index) => {
      const box = boxScale(detectionToBox(it), internalScale)
      const text = it.data.additionalData?.plate?.code ? it.data.additionalData?.plate?.code : '-'
      const startX = box.p2.x > box.p1.x + text.length * 15 ? box.p1.x : box.p2.x - text.length * 15
      return (
        <React.Fragment key={`konva-line-${index}`}>
          {it.data.additionalData?.plate && <Text text={it.data.additionalData?.plate?.code} x={startX} y={box.p1.y - 15} fontSize={20} fill='red' />}
          <Line
            key={`detection-line-${index}`}
            points={boxToPoints(box)}
            strokeWidth={2 * internalScale}
            stroke='red'
            shadowColor='red'
            closed
            data={it.data}
          />
        </React.Fragment>
      )
    })
  }, [internalScale, detections])

  if (konvaImageRef) {
    if (konvaImageRef.current === null) {
      konvaImageRef.current = {dim: dimScaled, scale: internalScale}
    } else if (konvaImageRef.current.scale !== internalScale) {
      konvaImageRef.current = {dim: dimScaled, scale: internalScale}
    }
  }

  return (
    <Stage {...StageProps} {...dimScaled}>
      <Layer
        onMouseOver={(event) => {
          const pointer = event?.target?.getStage()?.getPointerPosition()
          const attrs = event?.target?.getAttrs()
          if (pointer && attrs.data) {
            setTooltipProps({
              text: `${(100 * attrs.data.accuracy).toFixed(1)}% ${attrs.data.class}`,
              x: pointer.x,
              y: pointer.y,
              visible: true,
            })
          } else {
            updateTooltipProps({visible: false})
          }
        }}
        onMouseOut={() => {
          updateTooltipProps({visible: false})
        }}>
        <Image image={image} {...dimScaled} />
        <Group name='detection-areas'>{!hideDetections && lines}</Group>
      </Layer>
      <DetectionTooltip {...tooltipProps} />
      {dimScaled.height !== 0 && children}
    </Stage>
  )
}
