import {BtnClose} from 'src/components/BtnClose'
import {useBrainBoxContext} from 'src/pages/brain-box/context/BrainBoxContext'
import BrainBoxClient from './BrainBoxClient'
import BrainBoxData from './BrainBoxData'
import {Modal, Box, Button} from '@viptech/react-components'
import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useWindowSize} from 'usehooks-ts'
import BrainBoxInstructions from './BrainBoxInstructions'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import {useSnackbar} from 'notistack'
import {useAuth} from 'src/contexts/AuthContext'

function BrainBoxAddModal() {
  const {isOpen, dialogClose, createBrainBox, brainBox, verifyBrainBoxCode, step, setStep} = useBrainBoxContext()
  const {height} = useWindowSize()
  const {user} = useAuth()
  const [isFullHd, setIsFullHd] = useState(height > 768)
  const disabled = useMemo(() => Object.values(brainBox).some((value) => !value), [brainBox])
  const currentStep = useMemo(() => step, [step])
  const [companySelectedName, setCompanySelectedName] = useState<string>('')
  const {enqueueSnackbar} = useSnackbar()

  const handlePrevious = useCallback(() => {
    if (step === 2) setStep(step - 1)
  }, [step, setStep])

  const handleNext = useCallback(async () => {
    if (step === 1) {
      const isValid = await verifyBrainBoxCode(enqueueSnackbar)
      if (isValid) setStep(step + 1)
    } else {
      createBrainBox()
      dialogClose()
    }
  }, [step, setStep, createBrainBox, dialogClose])

  useEffect(() => {
    setIsFullHd(height > 768)
  }, [height])

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => dialogClose()}
      backgroundColor='white'
      padding='16px'
      paddingBottom='16px'
      paddingLeft='16px'
      paddingRight='16px'
      paddingTop='16px'>
      <Box width={isFullHd ? '1400px' : '1000px'} height={isFullHd ? '900px' : '600px'}>
        <Box width='100%' display='flex' justifyContent='flex-end'>
          <Box height={'30px'}>
            <BtnClose onClose={dialogClose} />
          </Box>
        </Box>
        <Box display='flex' direction='row' justifyContent='space-between' align='flex-start' height='88%' padding='16px' overflow='hidden'>
          {currentStep === 1 ? (
            <>
              <BrainBoxClient isFullHd={isFullHd} setCompanySelectedName={setCompanySelectedName} />
              <BrainBoxData />
            </>
          ) : (
            <>
              <BrainBoxInstructions isFullHd={isFullHd} userName={user.name} companyName={companySelectedName} brainBoxCode={brainBox.code} />
            </>
          )}
        </Box>
        <Box display='flex' align='flex-end' justifyContent='flex-end' marginTop={isFullHd ? '10px' : '0px'} marginRight='15px'>
          <RenderIfConditionIsMet condition={step > 1}>
            <Button variant='outlined' padding='10px 30px' marginRight='20px' width='120px' disabledBgColor='#9E9E9E' onClick={() => handlePrevious()}>
              Voltar
            </Button>
          </RenderIfConditionIsMet>
          <Button variant='contained' padding='10px 30px' width='120px' disabledBgColor='#9E9E9E' onClick={() => handleNext()} disabled={disabled}>
            {step === 1 ? 'Próximo' : 'Finalizar'}
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default BrainBoxAddModal
