export const CardFlagMap = {
  amex: /^3[47]\d{13}$/,
  diners: /^3(?:0[0-5]|[68]\d)\d{11}$/,
  elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  mastercard: /^(5[1-5]\d{14}|2[2-7][01]\d{13}|22[2-9]\d{13}|2720\d{12})$/,
  visa: /^4\d{12}(?:\d{3})?$/,
}

export type CardType = keyof typeof CardFlagMap
