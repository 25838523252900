import config from '../config'
import storage from '../storage'

export function enableProductionDebugMode() {
  const lastKeysPressed: string[] = []
  const lastKeysMaxCount = config.debugKeys.length

  const initialDebug = storage.get('debugMode')
  if (initialDebug) config.debug = true

  const addKey = (key: string) => {
    if (key === undefined) return
    if (key.match(/^[a-zA-Z-]$/)) {
      lastKeysPressed.push(key)
      if (lastKeysPressed.length > lastKeysMaxCount) {
        lastKeysPressed.shift()
      }
    }
  }

  const isDebugKeysPressedValid = () => {
    return lastKeysPressed.length === lastKeysMaxCount && lastKeysPressed.join('') === config.debugKeys
  }

  const toggleDebugMode = () => {
    if (config.debug) {
      console.warn('Debug mode disabled')
      config.debug = false
      storage.remove('debugMode')
    } else {
      console.warn('Debug mode enabled')
      storage.set('debugMode', '1')
      config.debug = true
    }
  }

  document.addEventListener('keydown', (event) => {
    if (event.key === ']') {
      if (isDebugKeysPressedValid()) {
        toggleDebugMode()
      }
    } else {
      addKey(event.key)
    }
  })
}

enableProductionDebugMode()
