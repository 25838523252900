import FilterListIcon from '@mui/icons-material/FilterList'
import {Box, Grid, Tooltip} from '@mui/material'
import {Column, Table, TableData} from '@viptech/react-components'
import sub from 'date-fns/sub'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import AppAddButton from 'src/components/app-add-button/AppAddButton'
import RowHover from 'src/components/RowHover/RowHover'
import CustomCalendar from 'src/components/customCalendar/CustomCalendar'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import api, {CameraHistoric} from 'src/services/api'
import {Page} from 'src/types'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import Pagination from '../../../../components/pagination/Pagination'
import {cameraHistoricRows} from './CameraConfigHistoricFunctions'
import {FilterDate} from './components/camera-historic-filter/CameraFilterProps'
import {CameraFilterStatus} from './components/camera-historic-filter/CameraFilterStatus'

const CameraConfigHistoric = () => {
  const [openFilter, setOpenFilter] = useState(false)
  const [loadingCameraStatus, setLoadingCameraStatus] = useState(true)
  const [cameraStatusFilter, setCameraStatusFilter] = useState<string[]>([])
  const [cameraStatusPage, _setCameraStatusPage] = useState<Page>({page: 1, pageSize: 20})
  const filterDate = useRef<FilterDate>({
    from: moment(sub(new Date(), {minutes: 30})).format('YYYY-MM-DDTHH:mm'),
    to: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
  })
  const [cameraHistoric, setCameraHistoric] = useState<CameraHistoric[]>([])
  const cameraStatusFilterDebounced = useDebounce(cameraStatusFilter, 1000)
  const {enqueueSnackbar} = useSnackbar()
  const {camera} = useCameraConfigContext()
  const blockCameraHistoricRequests = useRef(false)
  const [totalItems, setTotalItems] = useState(0)

  function setCameraStatusPage(page: number) {
    _setCameraStatusPage((lastPage) => ({...lastPage, page}))
  }

  function setCameraStatusPageSize(pageSize: number) {
    _setCameraStatusPage((lastPage) => ({...lastPage, pageSize}))
  }

  const control = {
    currentPage: cameraStatusPage.page,
    setCurrentPage: setCameraStatusPage,
    changePageSize: (pageSize: number) => {
      setCameraStatusPageSize(pageSize)
    },
    nextPage: () => {
      setCameraStatusPage(cameraStatusPage.page + 1)
    },
    prevPage: () => {
      setCameraStatusPage(cameraStatusPage.page - 1)
    },
  }

  const getHistoricCamera = useCallback(async () => {
    if (!camera) return
    const {id} = camera
    setLoadingCameraStatus(true)
    const statuses = cameraStatusFilterDebounced.map((s: any) => s.toUpperCase())
    try {
      if (blockCameraHistoricRequests.current) return
      blockCameraHistoricRequests.current = true
      const response = await api.camera.getCameraHistoric({
        page: cameraStatusPage.page || 1,
        pageSize: cameraStatusPage.pageSize,
        from: filterDate.current.from !== '' ? moment(filterDate.current.from).format() : undefined,
        to: filterDate.current.to !== '' ? moment(filterDate.current.to).format() : undefined,
        filter: {
          statuses: statuses.length > 0 ? statuses : undefined,
          cameraId: id,
        },
        orderBy: {
          timestamp: 'DESC',
        },
      })
      setCameraHistoric(response.data.data.entities)
      setTotalItems(response.data.data.totalElements)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar histórico')
    } finally {
      setLoadingCameraStatus(false)
      blockCameraHistoricRequests.current = false
    }
  }, [enqueueSnackbar, filterDate, camera, cameraStatusPage, cameraStatusFilterDebounced])

  const handleChangeTimeStart = (dates: [Date, Date]) => {
    let from = moment(dates[0]).format('YYYY-MM-DDTHH:mm')
    let to = moment(dates[1]).format('YYYY-MM-DDTHH:mm')
    if (dates[0] === dates[1]) {
      from = moment(dates[0].setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm')
      to = moment(dates[1].setHours(23, 59, 59, 59)).format('YYYY-MM-DDTHH:mm')
    }
    filterDate.current = {
      from,
      to,
    }
    getHistoricCamera()
  }

  const columns = [
    {
      id: 'updatedAt',
      name: 'Atualização',
    },
    {
      id: 'lastUpdate',
      name: 'Atualizado há',
    },
    {
      id: 'status',
      name: 'Status',
    },
    {
      id: 'situation',
      name: 'Situação',
    },
  ] as Column[]

  useEffect(() => {
    getHistoricCamera()
  }, [])

  return (
    <Box sx={{height: '55vh', overflow: 'auto'}}>
      <Box sx={styles.paper}>
        <Grid item xs={10} sx={{display: 'flex', paddingBottom: 1.5, justifyContent: 'end'}}>
          <Tooltip title='Filtrar por' placement='top'>
            <Box>
              <AppAddButton onClick={() => setOpenFilter((prev) => !prev)} sx={{width: 'auto', minWidth: '54px'}}>
                <FilterListIcon sx={{fontSize: 'large'}} />
              </AppAddButton>
            </Box>
          </Tooltip>
          <Box sx={{marginLeft: 1.5}}>
            <CustomCalendar setDate={handleChangeTimeStart} />
          </Box>
        </Grid>
        <Box sx={{justifyContent: 'end', display: 'flex'}}>
          {openFilter && <CameraFilterStatus cameraStatusFilter={cameraStatusFilter} setCameraStatusFilter={setCameraStatusFilter} />}
        </Box>
        <Table isLoading={loadingCameraStatus} columns={columns} skeletonRowsAmount={20}>
          {cameraHistoricRows(cameraHistoric).map((row) => (
            <RowHover id={`row-${row.id}`}>
              <TableData>{row.createdAt}</TableData>
              <TableData>{row.self}</TableData>
              <TableData>{row.statusId}</TableData>
              <TableData>{row.isOnline}</TableData>
            </RowHover>
          ))}
        </Table>
        <Box sx={styles.pagination}>
          <Pagination.Root backgroundColor='#009EFF' color='white' totalItems={totalItems} itemsPerPage={cameraStatusPage.pageSize} control={control} />
        </Box>
      </Box>
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    width: '100%',
    height: 'fit-content',
    overflow: 'hidden',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    marginTop: '15px',
    width: 'fit-content',
  },
  menuContent: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    textAlign: 'center',
    fontFamily: 'Inter',
    padding: 2,
    gap: 1,
  },
})

export default CameraConfigHistoric
