import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Dialog, DialogActions, DialogTitle} from '@mui/material'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {BtnClose} from 'src/components/BtnClose'
import Loading from 'src/components/Loading'
import {EnumServer} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {useClientsContext} from '../contexts/ClientsContext'
import ClientsRegisterContent from './ClientsRegisterContent'

function ClientsModal() {
  const [isLoading] = useState<boolean>(true)
  const {setTab, isClientModalOpen, closeClientModal, clientSelected, clientAdd, addClient, updateClient, serverClient} = useClientsContext()
  const navigate = useNavigate()
  const isCreate = clientSelected?.id === undefined

  function shouldDisableButton() {
    const isNameEmpty = clientAdd.name === ''
    const hasCompanySelected = clientAdd.companyId
    let hasClientInfo = false
    switch (serverClient.id) {
      case EnumServer.SIGMA_CLOUD:
        hasClientInfo = !!clientAdd.sigmaAccount
        break
      case EnumServer.MONI:
        hasClientInfo = !!clientAdd.moniClientInfo
        break
      case EnumServer.ONE_PORTARIA:
        hasClientInfo = !!clientAdd.onePortariaInfo
        break
      case -1:
        hasClientInfo = true
        clientAdd.sigmaAccount = undefined
        clientAdd.moniClientInfo = undefined
        clientAdd.onePortariaInfo = undefined
        break
      default:
        break
    }
    return isNameEmpty || !hasCompanySelected || !hasClientInfo
  }

  return (
    <Dialog open={isClientModalOpen} onClose={closeClientModal} maxWidth='sm' fullWidth PaperProps={{sx: styles.container}}>
      <DialogTitle sx={styles.dialogTitle}>{isCreate ? 'Adicionar Cliente' : 'Editar Cliente'}</DialogTitle>
      <BtnClose onClose={closeClientModal} />
      <ClientsRegisterContent isCreate={isCreate} isClientModalOpen={isClientModalOpen} />
      <DialogActions>
        <Box>
          <Button
            onClick={() => {
              closeClientModal()
              navigate(`/registers/clients`)
              setTab(0)
            }}
            sx={styles.cancelButton}>
            Cancelar
          </Button>
          <LoadingButton
            variant='contained'
            sx={styles.confirmButton}
            onClick={() => {
              if (isCreate) addClient()
              else updateClient()
              closeClientModal()
              navigate(`/registers/clients`)
              setTab(0)
            }}
            loading={!isLoading}
            loadingIndicator={<Loading />}
            disabled={shouldDisableButton()}>
            Salvar
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  container: {
    paddingBottom: 2,
  },
  form: {
    paddingTop: 2,
  },
  input: {
    marginBottom: '20px',
  },
  dialogTitle: {paddingTop: 3, color: '#353535'},
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '17px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: '20px',
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonCameraClient: {
    justifyContent: 'center',
    height: '2.7em',
    width: '13em',
    fontFamily: 'Inter',
    fontSize: '0.9rem',
    display: 'flex',
    marginBottom: '20px',
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
})

export default ClientsModal
