import {Company} from './CompanyEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_AUTH_BASE_URL

type UsersIncludes = 'permissions' | 'roleEntity' | 'authorizedCompanies'

export type User = {
  name: string
  email: string
  id: number
  role: string
  roleEntity?: RoleType
  company: Company
  forcePasswordUpdate: boolean
  permissions: string[]
  authorizedCompanies: number[]
  authorizedClients: number[]
  sessionToken?: string
  password: string
}

export type UserPermissions = {
  id: number
  permissions: string[]
}

export type PermissionsBody = {
  permissions: string[]
}

export type AuthorizedCompaniesBody = {
  companyIds: number[]
}

export type GetUsersParams = {
  page?: number
  pageSize?: number
  search?: {
    name: string
  }
  filter?: {
    ids?: number[]
    companyIds?: number[]
    statuses?: string[]
  }

  includes?: UsersIncludes[]
}

export type AuthorizedClientsBody = {
  clientIds: number[]
}

export type GetSingleUserParams = {
  id?: number
}

export type AddUser = {
  id?: number
  name: string
  email: string
  authorizedCompanies: number[]
  authorizedClients?: number[]
  role: string
  companyId?: number
}

export type EditUser = {
  id: number
  name?: string
  email?: string
  role?: string
  authorizedCompanies?: number[]
  authorizedClients?: number[]
  companyId?: number
}

export type RoleType = {
  name: string
  label: string
  priority: number
}

export class UsersEndpoint {
  async getAll(usersParams: GetUsersParams): Promise<AxiosResult<Paginate<User>>> {
    return await axios.get(`${prefix}/users`, {
      params: prepareParams(usersParams),
    })
  }
  async getById(params: GetSingleUserParams): Promise<AxiosResult<User>> {
    return await axios.get(`${prefix}/user`, {
      params: prepareParams(params),
    })
  }

  async addUser(user: AddUser) {
    return await axios.post(`${prefix}/user`, user)
  }

  async updateUser(user: EditUser) {
    return await axios.patch(`${prefix}/user`, user)
  }

  async addPermissions(userId: number, body: PermissionsBody): Promise<AxiosResult<UserPermissions>> {
    return await axios.put(`${prefix}/user/${userId}/permissions`, body)
  }

  async deletePermissions(userId: number, body: PermissionsBody): Promise<AxiosResult<UserPermissions>> {
    return await axios.delete(`${prefix}/user/${userId}/permissions`, {data: body})
  }

  async deleteMany(ids: number[]): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/users`, {
      data: {ids},
    })
  }

  async getRoles(): Promise<AxiosResult<RoleType[]>> {
    return await axios.get(`${prefix}/roles/less-than-or-equal`)
  }

  async getAllRoles(): Promise<AxiosResult<RoleType[]>> {
    return await axios.get(`${prefix}/roles/`)
  }

  async addAuthorizedClients(userId: number, body: AuthorizedClientsBody): Promise<AxiosResult<User>> {
    return await axios.put(`${prefix}/user/${userId}/authorized-clients`, body)
  }

  async deleteAuthorizedClients(userId: number, body: AuthorizedClientsBody): Promise<AxiosResult<User>> {
    return await axios.delete(`${prefix}/user/${userId}/authorized-clients`, {data: body})
  }

  async getAllAuthorizedCompaniesPermissions(userId: number): Promise<AxiosResult<string[]>> {
    return await axios.get(`${prefix}/user/possible-permissions?userId=${userId}`)
  }

  async addAuthorizedCompanies(userId: number, body: AuthorizedCompaniesBody): Promise<AxiosResult<User>> {
    return await axios.put(`${prefix}/user/${userId}/authorized-companies`, body)
  }

  async deleteAuthorizedCompanies(userId: number, body: AuthorizedCompaniesBody): Promise<AxiosResult<User>> {
    return await axios.delete(`${prefix}/user/${userId}/authorized-companies`, {data: body})
  }
}
