import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {Box, Button, Collapse, Grid, Link, List, Typography} from '@mui/material'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useAuth} from 'src/contexts/AuthContext'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import {ReleasesNotes} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal'

type NotificationProps = {
  defaultOpen?: boolean
  notification: ReleasesNotes
  onEdit: (notification: ReleasesNotes) => void
  onDelete: (id: number) => void
  createReleasesViews: (notification: ReleasesNotes) => void
}

function NotificationList(notificationProps: NotificationProps) {
  const {defaultOpen, notification, onEdit, onDelete, createReleasesViews} = notificationProps
  const [isOpen, setIsOpen] = useState(notificationProps.defaultOpen || false)
  const {user} = useAuth()
  const hasUpdateNotificationPermission = ValidatePermissions('notifications:update')
  const hasDeleteNotificationPermission = ValidatePermissions('notifications:delete')
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState<boolean>(false)

  const navigate = useNavigate()
  const {setUpdateNotifications} = useApp()

  useEffect(() => {
    if (defaultOpen) setIsOpen(defaultOpen)
  }, [defaultOpen])

  function handleIsOpen() {
    createReleasesViews(notification)
    setUpdateNotifications((prev) => !prev)
    setIsOpen((prev) => !prev)
    navigate('/notifications')
  }

  return (
    <List id={String(notification.id)} component='nav' aria-labelledby='nested-list-subheader' sx={isOpen ? styles.items : styles.itemsEffect}>
      <Grid sx={user?.role === 'admin' ? {width: '80%'} : {width: '100%'}}>
        <Box sx={styles.title}>{notification.title}</Box>
        <Box sx={{color: '#999999'}}>{`${moment(notification.createdAt).format('LL')}`}</Box>
        <Box sx={{fontWeight: '500', marginTop: 0.5}}>{notification.subtitle}</Box>
        <Collapse in={isOpen} timeout='auto' unmountOnExit sx={{height: 'auto'}}>
          <Box dangerouslySetInnerHTML={{__html: notification.description}}></Box>
        </Collapse>
        <Link onClick={handleIsOpen} underline='none'>
          {isOpen ? (
            <Box sx={styles.buttonsExpandInfos}>
              <Typography sx={styles.buttonDescription}>Ver menos</Typography>
              <ExpandLess color='primary' />
            </Box>
          ) : (
            <Box sx={styles.buttonsExpandInfos}>
              <Typography sx={styles.buttonDescription}>Ver mais</Typography>
              <ExpandMore color='primary' />
            </Box>
          )}
        </Link>
      </Grid>
      {!isOpen && (
        <Grid sx={styles.gridButtons}>
          {hasUpdateNotificationPermission && (
            <Button onClick={() => onEdit(notification)} sx={styles.buttonEdit}>
              <EditIcon />
            </Button>
          )}
          {hasDeleteNotificationPermission && (
            <Button onClick={() => setOpenModalConfirmDelete(true)} sx={styles.buttonDelete}>
              <DeleteIcon color='error' />
            </Button>
          )}
        </Grid>
      )}

      <ConfirmationModal
        isOpen={openModalConfirmDelete}
        title={'Tem certeza que deseja excluir esta notificação?'}
        content={'Esta ação é crítica e irá deletar a notificação de todos os usuários!'}
        onClose={() => setOpenModalConfirmDelete(false)}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModalConfirmDelete(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              onDelete(notification.id)
            },
            variant: 'contained',
            dangerAction: true,
          },
        ]}
      />
    </List>
  )
}

const styles = createStyleSheet({
  items: {
    height: 'auto',
    display: 'flex',
    margin: '10px 3px 10px 3px',
    transition: '0.3s',
    padding: '15px 15px 10px 15px',
    boxShadow: 'rgba(0, 0, 0, 0.20) 0px 3px 6px, rgba(0, 0, 0, 0.30) 0px 3px 6px',
  },
  itemsEffect: {
    height: 'auto',
    display: 'flex',
    margin: '10px 3px 10px 3px',
    transition: '0.3s',
    padding: '15px 15px 10px 15px',
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;',
  },
  gridButtons: {
    marginLeft: '7%',
    width: '13%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    display: 'flex',
  },
  buttonEdit: {
    width: '2.8em',
    height: '2.8em',
    borderRadius: '50%',
    minWidth: '10px',
    border: '1px solid blue',
  },
  buttonDelete: {
    width: '2.8em',
    height: '2.8em',
    borderRadius: '50%',
    minWidth: '0.625em',
    border: '1px solid red',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1.375rem',
    lineHeight: '40px',
  },
  buttonsExpandInfos: {
    display: 'flex',
    height: '3.125em',
    alignItems: 'center',
  },
  buttonDescription: {
    fontFamily: 'Inter',
    fontSize: '14px',
    marginRight: 0.5,
    cursor: 'pointer',
  },
})

export default NotificationList
