export function isValidLicensePlate(licensePlate: string): boolean {
  return !!licensePlate.match(/[A-Z]{3}[0-9][0-9A-Z][0-9]{2}/)
}

export function isPasswordLoginValid(password: string): boolean {
  return !!password.match(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z\d`~!@#$%^&*_|+\-=?;:'",.\\]{8,40}$/)
}

export function isValidCnpj(document: string): boolean {
  return !!document.match(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/)
}

export function cnpjMask(document: string): string {
  return document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
}

export function isNumber(text: string): boolean {
  return !isNaN(parseFloat(text)) && isFinite(Number(text))
}

export function isLetter(string: string): boolean {
  return !!string.match(/[a-zA-Z]/)
}

export function isEmailLoginValid(username: string) {
  return !!username.match(/^[A-Za-z0-9\\._-]+@[a-z0-9]+(\.[a-z]+)+?$/i)
}

export function isResolutionValid(string: string) {
  try {
    const num = Number(string)
    if (isNaN(num)) return false
    if (num < 240) return false
    if (num > 1280) return false
    return true
  } catch (error) {
    return false
  }
}

export function isValidUuid(uuid: string) {
  return Boolean(uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i))
}

export function isValidHostAddress(address: string): boolean {
  const validIpAddressRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])+$/
  // eslint-disable-next-line no-useless-escape
  const validHostnameRegex = /^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/
  const addressIpMatches = address.match(validIpAddressRegex)
  const addressHostnameMatches = address.match(validHostnameRegex)
  return Boolean((addressIpMatches && addressIpMatches.includes(address)) || (addressHostnameMatches && addressHostnameMatches.includes(address)))
}
