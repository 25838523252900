import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import {useState} from 'react'
import {IMaskInput} from 'react-imask'
import {BtnClose} from 'src/components/BtnClose'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {AnalyticCamera, useAnalyticCamerasContext} from '../../context/AnalyticCamerasContext'

function CameraUpdateModal() {
  type FormValidationType = {[x in keyof AnalyticCamera]: boolean}
  const {editModalIsOpen, closeModalEdit, selectedCamera, setSelectedCamera, analytics, setAnalytics, selectedAnalytics, setSelectedAnalytics, saveEdit} =
    useAnalyticCamerasContext()
  const [validForm, setValidForm] = useState<FormValidationType>(
    Object.fromEntries(Object.keys(selectedCamera).map((key) => [key, true])) as FormValidationType,
  )
  const macRegex = /([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})/

  const formIsValid = (): boolean => {
    const selected = analytics.filter((analytic) => analytic.selected).map((analytic) => analytic.analytic)
    return !Object.keys(validForm).some((key) => validForm[key as keyof FormValidationType] === false) && selected.length > 0
  }

  const styles = createStyleSheet({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '100%',
    },
    dialogTitle: {paddingTop: 3, color: '#353535'},
    macInput: {
      fontSize: '16px',
      width: '100%',
      marginBottom: '16px',
      justifyContent: 'center',
      borderRadius: '6px',
      height: '56px',
      padding: '10px',
      border: '#C6C1C1 solid 1px',
      '&:hover': {
        border: '#00639f solid 1px',
      },
    },
    cancelButton: {
      width: '150px',
      height: '40px',
      marginRight: 2.7,
      marginBottom: 2,
      border: '1px solid',
      fontFamily: 'Inter',
      boxShadow: 'none',
      textTransform: 'none',
    },
    saveButton: {
      width: '150px',
      height: '40px',
      fontFamily: 'Inter',
      boxShadow: 'none',
      textTransform: 'none',
      marginRight: 2,
      marginBottom: 2,
    },
  })

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const eventValue = event.target.value as string[]
    const mappedAnalytics = analytics.map((value) => {
      if (eventValue.includes(value.analytic)) return {...value, selected: !value.selected}
      return value
    })
    setAnalytics(mappedAnalytics)
    if (!eventValue.length) return
    const selected = mappedAnalytics.filter((analytic) => analytic.selected).map((analytic) => ' ' + analytic.label)
    setSelectedAnalytics(selected)
  }

  return (
    <Dialog open={editModalIsOpen} onClose={() => closeModalEdit()} maxWidth='xl'>
      <DialogTitle sx={styles.dialogTitle}>Editar Câmera</DialogTitle>
      <BtnClose onClose={closeModalEdit} />
      <DialogContent>
        <Box style={styles.container}>
          <Box sx={{marginBottom: 2}}>
            <InputLabel htmlFor='analytic-camera-name'>Nome do Dispositivo</InputLabel>
            <TextField
              name='analytic-camera-name'
              variant='outlined'
              value={selectedCamera?.name}
              onChange={(e) => {
                setSelectedCamera({...selectedCamera, name: e.target.value})
                setValidForm({...validForm, name: e.target.value.length > 0})
              }}
              sx={{height: '3.46875em', width: '610px'}}
            />
          </Box>
          <Box style={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{flex: 2, width: '100%'}}>
              <InputLabel htmlFor='host'>Host</InputLabel>
              <TextField
                sx={{width: '95%'}}
                name='host'
                placeholder='Host'
                variant='outlined'
                value={selectedCamera?.host}
                onChange={(e) => {
                  setSelectedCamera({...selectedCamera, host: e.target.value})
                  setValidForm({...validForm, host: e.target.value.length > 0})
                }}
              />
            </Box>
            <Box sx={{flex: 1}}>
              <InputLabel htmlFor='analytic-camera-port'>Porta</InputLabel>
              <IMaskInput
                name='analytic-camera-port'
                placeholder='Porta'
                style={styles.macInput}
                value={String(selectedCamera?.port)}
                mask={Number}
                min={0}
                max={65535}
                onAccept={(value) => {
                  setSelectedCamera({...selectedCamera, port: Number(value)})
                  setValidForm({...validForm, port: true})
                }}
              />
            </Box>
          </Box>
          <Box>
            <InputLabel htmlFor='analytic-camera-mac-address'>Endereço MAC</InputLabel>
            <IMaskInput
              name='analytic-camera-mac-address'
              mask='##:##:##:##:##:##'
              definitions={{'#': /[a-fA-F0-9]/}}
              style={styles.macInput}
              placeholder='A0:B0:C0:D0:E0:F0'
              label='Endereço MAC'
              required
              overwrite
              value={selectedCamera.macAddress?.toUpperCase()}
              unmask={false}
              onAccept={(value) => {
                setSelectedCamera({...selectedCamera, macAddress: value})
                setValidForm({...validForm, macAddress: macRegex.test(value)})
              }}
              onBlur={(e) => {
                if (formIsValid()) setSelectedCamera({...selectedCamera, macAddress: selectedCamera.macAddress})
                else setSelectedCamera({...selectedCamera, macAddress: ''})
              }}
            />
          </Box>
          <Box>
            <InputLabel htmlFor='analytics'>Analíticos</InputLabel>
            <Select
              name='analytics'
              multiple
              required
              sx={{height: '3.46875em', width: '610px'}}
              value={selectedAnalytics}
              renderValue={(value) => [value].join(', ')}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  sx: {maxHeight: '220px'},
                },
                style: {
                  maxHeight: 500,
                },
              }}>
              {analytics.map((analytic, index) => (
                <MenuItem key={'role-' + index} value={analytic.analytic}>
                  <Checkbox checked={analytic.selected} />
                  {`${analytic.label} `}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{marginTop: 3}}>
          <Button
            onClick={() => {
              closeModalEdit()
            }}
            sx={styles.cancelButton}>
            Cancelar
          </Button>
          <LoadingButton type={'submit'} loading={false} variant='contained' sx={styles.saveButton} disabled={!formIsValid()} onClick={() => saveEdit()}>
            Salvar
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default CameraUpdateModal
