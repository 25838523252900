import {Company} from './CompanyEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_COMPANIES_BASE_URL

type QuotaInclude = 'resource' | 'company'

export type Quota = {
  id: number | undefined
  quota: string | undefined
  used?: string | undefined
  resource?: Resource | undefined
  companyId?: number | undefined
  step?: number | undefined
  company?: Company
}

export type Resource = {
  id: number
  name?: string
}

export type GetQuotasParams = {
  page: number
  pageSize: number
  search?: {
    companyName?: string
    resourceName?: string
  }
  includes?: QuotaInclude[]
  filter?: {
    companyIds?: number[]
  }
}

export type UpdateQuota = {
  quota: number
  step?: number
}

export class CompanyQuotaEndpoint {
  async getAll(params: GetQuotasParams): Promise<AxiosResult<Paginate<Quota>>> {
    return await axios.get(`${prefix}/quotas`, {params: prepareParams(params)})
  }
  async getAllResource(): Promise<AxiosResult<Paginate<Resource>>> {
    return await axios.get(`${prefix}/resources`)
  }

  async update(id: number, body: UpdateQuota): Promise<AxiosResult<UpdateQuota>> {
    return await axios.patch(`${prefix}/quotas/${id}`, body)
  }
}
