import {SelectChangeEvent} from '@mui/material'
import {useEffect, useState} from 'react'
import CustomCalendar from 'src/components/customCalendar/CustomCalendar'
import {useCompany} from 'src/contexts/CompanyContext'
import {Company} from 'src/services/api'

import HeaderCompanyManagementSelect from './HeaderCompanyManagementSelect'
import CompaniesSelect from './CompaniesSelect'
import {BoxHeaderWrapper, WrapperSelectCompaniesOnHeader} from '../HeaderBase'
import {useLocation} from 'react-router-dom'

const hideCompaniesSelectUrls = ['/administrative/companies', '/registers/devices/', 'registers/brainbox/', 'registers/lists/']

type CompaniesProps = {
  handleChangeTimeStart: (dates: [Date, Date]) => void
}

export function HandleCompanieSelect({handleChangeTimeStart}: CompaniesProps) {
  const {companies, checkRetailDashboardAccess, setSelectedCompanies, selectedCompanies} = useCompany()
  const isRetail = checkRetailDashboardAccess()

  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])
  const {pathname} = useLocation()

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: {value},
    } = event
    setCompanyNames(typeof value === 'string' ? value.split(',') : value)
    const names = value as string[]
    const viewCompanies = companies.filter((it) => names.includes(it.name)).map((it) => it.id)
    setSelectedCompanies(viewCompanies)
    localStorage.setItem('selectedCompaniesState', viewCompanies.toString())
  }

  function shouldRenderSelect() {
    const last = pathname.split('/').pop()
    return hideCompaniesSelectUrls
      .map((forbiddenUrl) => {
        return pathname.includes(forbiddenUrl) && !!last
      })
      .every((value) => value === false)
  }

  useEffect(() => {
    shouldRenderSelect()
  }, [pathname])

  return (
    <BoxHeaderWrapper>
      {companies.length > 1 && shouldRenderSelect() && (
        <>
          {pathname.includes('/dashboard') ? (
            <HeaderCompanyManagementSelect isDashboard={true} />
          ) : (
            <WrapperSelectCompaniesOnHeader>
              <CompaniesSelect
                selectLabel='Você está visualizando:'
                companiesScroll={companiesScroll}
                setCompaniesScroll={setCompaniesScroll}
                pageCount={pageCount}
                setPageCount={setPageCount}
                companyNames={companyNames}
                defaultValueIds={selectedCompanies}
                handleChange={handleChange}
                setCompanyNames={setCompanyNames}
                blockUnselect={true}
              />
            </WrapperSelectCompaniesOnHeader>
          )}
          {pathname.includes('/dashboard') && !isRetail && <CustomCalendar setDate={handleChangeTimeStart} hideRuler hideTime />}
        </>
      )}
    </BoxHeaderWrapper>
  )
}
