import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import {Box, Tooltip} from '@mui/material'
import {memo, useEffect, useRef, useState} from 'react'
import {RegisterButtonIcon} from 'src/common/components/register-facial-recognition/RegisterFacialRecognitionBase'
import {Button} from 'src/common/generics/components/button/Button'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import AttachFileIcon from 'src/images/AttachFileIcon'
import CloseIcon from 'src/images/closeIcon.svg'
import {imageMock, selectedFilesMock} from '../../RegisterFacialRecognitionFunctions'

type UploadPhotosProps = {
  isEdit: boolean
  editImage?: boolean
  status?: string
  imageKey?: string | null
  getImageBase64: (base64?: string) => void
}

export const UploadPhotos = memo((uploadPhotosProps: UploadPhotosProps) => {
  const [selectedFiles, setSelectedFiles] = useState<Array<File> | null>([])
  const [image, setImage] = useState<Array<string | undefined>>([])
  const {getImageBase64, imageKey, editImage} = uploadPhotosProps
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleRemoveFile = (index: number) => {
    if (hiddenFileInput.current) hiddenFileInput.current.value = ''
    setSelectedFiles((prev) => (prev ? prev?.filter((_, oldIndex) => oldIndex !== index) : null))
    setImage((prev) => prev?.filter((_, oldIndex) => oldIndex !== index))
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const selectedFileNames = selectedFiles?.map((file) => file.name)
      const newFiles = files.filter((file) => !selectedFileNames?.includes(file.name))
      const images: string[] = []

      for (let i = 0; i < newFiles.length; i++) {
        const file = newFiles[i]
        const reader = new FileReader()

        reader.readAsDataURL(file)

        reader.addEventListener('load', (event) => {
          const target = event.target as FileReader
          images.push(target.result as string)
          if (images.length === newFiles.length) {
            setImage((prev) => prev.concat(images))
            getImageBase64(images[0]?.split(',')[1])
          }
        })
        setSelectedFiles((prev) => prev!.concat(file))
      }
    }
  }

  const NotImage = () => {
    return (
      <Box display={'flex'} alignSelf={'center'} justifyContent={'center'} alignItems={'center'} width={'170px'} height={'189.7px'}>
        <ImageNotSupportedIcon fontSize='large' />
      </Box>
    )
  }

  useEffect(() => {
    if (imageKey) {
      setImage([imageKey])
      setSelectedFiles(selectedFilesMock)
      getImageBase64(imageMock)
    }
  }, [imageKey, getImageBase64])

  return (
    <>
      {uploadPhotosProps.status && uploadPhotosProps.status !== 'loaded' ? (
        <NotImage />
      ) : selectedFiles && selectedFiles.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'center',
            boxSizing: 'border-box',
            width: '170px',
            height: '200px',
            border: '1px dashed #D7D7D7',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <input type='file' multiple accept='image/*' ref={hiddenFileInput} onChange={handleFileChange} style={{display: 'none'}} />
          <Button
            leftIcon={<AttachFileIcon />}
            padding='8px 16px 8px 16px'
            height='31px'
            width='137px'
            color='#009EFF'
            fontSize='12px'
            shape='5px'
            hoverColor='#1565c0'
            onClick={() => hiddenFileInput.current?.click()}>
            Fazer upload
          </Button>
        </Box>
      ) : (
        image &&
        image?.map((item, index) => (
          <div
            key={index.toString()}
            style={{
              position: 'relative',
              display: 'flex',
              alignSelf: 'center',
              boxSizing: 'border-box',
              justifyContent: 'center',
            }}>
            <img key={index.toString()} src={item} alt={`${index}`} style={{width: '170px', height: '201px'}} />
            {editImage ? (
              <></>
            ) : (
              <RegisterButtonIcon
                onClick={() => {
                  handleRemoveFile(index)
                  getImageBase64(image?.filter((_, oldIndex) => oldIndex !== index)[0])
                }}>
                <Tooltip title='Remover imagem' placement='top'>
                  <Icon width='1.5em' src={CloseIcon} position='absolute' cursor='pointer' margin='0 2PX 0 0' />
                </Tooltip>
              </RegisterButtonIcon>
            )}
          </div>
        ))
      )}
    </>
  )
})
