
export default function HidePasswordIcon() {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.727273 0.917222L1.65818 0L13.8182 12.0756L12.8945 13L10.6545 10.7756C9.81818 11.05 8.93091 11.1944 8 11.1944C4.36364 11.1944 1.25818 8.94833 0 5.77778C0.501818 4.50667 1.30182 3.38722 2.32 2.49889L0.727273 0.917222ZM8 3.61111C8.57865 3.61111 9.13361 3.83938 9.54278 4.24571C9.95195 4.65204 10.1818 5.20314 10.1818 5.77778C10.1822 6.02374 10.1404 6.26797 10.0582 6.5L7.27273 3.73389C7.50638 3.65227 7.75232 3.61075 8 3.61111ZM8 0.361111C11.6364 0.361111 14.7418 2.60722 16 5.77778C15.4064 7.27509 14.3978 8.57474 13.0909 9.52611L12.0582 8.49333C13.0639 7.80244 13.8751 6.86765 14.4145 5.77778C13.8266 4.58607 12.9138 3.58206 11.7798 2.87986C10.6459 2.17767 9.33634 1.80547 8 1.80556C7.20727 1.80556 6.42909 1.93556 5.70182 2.16667L4.58182 1.06167C5.62909 0.613889 6.78545 0.361111 8 0.361111ZM1.58545 5.77778C2.17341 6.96949 3.08623 7.9735 4.22017 8.67569C5.3541 9.37788 6.66366 9.75009 8 9.75C8.50182 9.75 8.99636 9.69944 9.45455 9.59833L7.79636 7.94444C7.2903 7.89058 6.81805 7.6663 6.45816 7.30891C6.09827 6.95152 5.87243 6.48255 5.81818 5.98L3.34545 3.51722C2.62545 4.13111 2.02182 4.89667 1.58545 5.77778Z"
        fill="currentColor"
      />
    </svg>
  )
}
