import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'

export enum ListObjectIdEnum {
  OBJECT_DETECTION = '1',
  LPR = '2',
  LINE_CROSSING = '3',
  FACIAL_RECOGNITION = '4',
  SCENE_CHANGE = '5',
}

export enum ListObjectEnum {
  OBJECT_DETECTION = 'Detecção de Objetos',
  LPR = 'LPR',
  LINE_CROSSING = 'Cruzamento de Linha',
  FACIAL_RECOGNITION = 'Reconhecimento Facial',
  SCENE_CHANGE = 'Mudança de Cenário',
}

export const direction: {[key: number]: string} = {
  1: 'Meio',
  2: 'Entrada',
  3: 'Saída',
}

export const listObject: Array<{id: string; name: string}> = [
  {
    id: ListObjectIdEnum.OBJECT_DETECTION,
    name: ListObjectEnum.OBJECT_DETECTION,
  },
  {
    id: ListObjectIdEnum.LPR,
    name: ListObjectEnum.LPR,
  },
  {
    id: ListObjectIdEnum.LINE_CROSSING,
    name: ListObjectEnum.LINE_CROSSING,
  },
  {
    id: ListObjectIdEnum.FACIAL_RECOGNITION,
    name: ListObjectEnum.FACIAL_RECOGNITION,
  },
  {
    id: ListObjectIdEnum.SCENE_CHANGE,
    name: ListObjectEnum.SCENE_CHANGE,
  },
]
export const updateList: {[key: string]: string} = {
  'Detecção de Objetos': ListObjectIdEnum.OBJECT_DETECTION,
  LPR: ListObjectIdEnum.LPR,
  'Cruzamento de linha': ListObjectIdEnum.LINE_CROSSING,
  'Reconhecimento Facial': ListObjectIdEnum.FACIAL_RECOGNITION,
  'Mudança de Cenário': ListObjectIdEnum.SCENE_CHANGE,
}

export function listTypesEvent() {
  let list: Array<string> = []
  const hasPermissionReadEvent = ValidatePermissions('events:read')
  const hasPermissionReadLineCrossing = ValidatePermissions('line-crossing:read')
  const hasPermissionReadFacial = ValidatePermissions('face:read')
  const hasPermissionReadLpr = ValidatePermissions('lpr:read')
  const hasPermissionReadSceneChange = ValidatePermissions('scene-change:read')

  if (hasPermissionReadEvent) list.push(ListObjectEnum.OBJECT_DETECTION)
  if (hasPermissionReadLineCrossing) list.push(ListObjectEnum.LINE_CROSSING)
  if (hasPermissionReadFacial) list.push(ListObjectEnum.FACIAL_RECOGNITION)
  if (hasPermissionReadLpr) list.push(ListObjectEnum.LPR)
  if (hasPermissionReadSceneChange) list.push(ListObjectEnum.SCENE_CHANGE)
  return list
}
