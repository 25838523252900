import {DropdownItem} from '@viptech/react-components'
import {AxiosError} from 'axios'
import {useEffect, useRef, useState} from 'react'
import api from 'src/services/api'

function useClients() {
  const [clients, setClients] = useState<DropdownItem[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError | null>(null)
  const blockRequests = useRef(false)

  async function getClients(companyId?: number) {
    if (blockRequests.current) return
    blockRequests.current = true
    setIsLoading(true)
    try {
      const res = await api.client.getMany({filter: {companyIds: companyId ? [companyId] : undefined}})
      setClients(
        res.data.data.entities.map((it) => {
          return {id: `${it.id}`, label: it.name}
        }),
      )
      setError(null)
    } catch (err) {
      const error = err as AxiosError
      setError(error)
    } finally {
      blockRequests.current = false
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getClients()
  }, [])

  return {clients, isLoading, error, refresh: getClients}
}

export default useClients
