import styled from 'styled-components'

export const ContainerColumn = styled.div`
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  gap: 15px;
  margin-top: 18px;
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: 'center';
  align-items: 'center';
  gap: 50px;
`
export const LabelsSlider = styled.p`
  color: #8e8e8e;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  margin-top: 8px;
`
