import {Box, Button, Typography} from '@viptech/react-components'
import {useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import SuccessImg from 'src/images/congratsImage.svg'
import ArrowLeft from 'src/images/goBackArrow.svg'
import CustomCongrats from 'src/images/statusCustom.svg'
import {GoBackButton, TitleAlt, TitleOnGoBackButton} from 'src/pages/self-register/select-plan/components/PlanSelectBase'
import {useQueryParams} from '../../../hooks/useQuery'
import {useB2CContext} from '../../../layouts/b2c-layout/contexts/B2CContext'
import api from '../../../services/api'
import {Image} from '../self-register/components'
import {ContainerMessage} from './StatusScreenBase'

function StatusScreen() {
  const type = useRef('')
  const query = useQueryParams()
  const navigate = useNavigate()
  const {registerData} = useB2CContext()

  const queryType = query.get('type')
  if (queryType) type.current = queryType

  useEffect(() => {
    if (type.current === 'custom') {
      const contactToSendRdStation = {
        name: 'Pessoa Física solicitou plano personalizado - B2C',
        contact: {
          email: registerData.email,
          name: registerData.name,
          phone: registerData.phone,
          title: `CPF: ${registerData.document}`,
        },
      }

      api.crmIntegration.createCrmDealWithContact(contactToSendRdStation)
    }
  }, [])

  return (
    <Box display='flex' direction='column' align='center' justifyContent='center' rowGap='60px' padding='50px 0' position='relative'>
      {type.current === 'custom' && (
        <GoBackButton onClick={() => navigate(-1)}>
          <img src={ArrowLeft} alt='Arrow Left' />
          <TitleOnGoBackButton> Voltar</TitleOnGoBackButton>
        </GoBackButton>
      )}
      {type.current !== 'success' ? (
        <Box marginTop='50px'>
          <Image src={CustomCongrats} alt='Muito obrigado pelo seu interesse' width={'350px'} />
        </Box>
      ) : (
        <Image src={SuccessImg} alt='Muito obrigado pelo seu interesse' />
      )}

      <Box>
        {type.current !== 'success' ? (
          <TitleAlt>Muito obrigado pelo seu interesse</TitleAlt>
        ) : (
          <TitleAlt>
            Parabéns, sua câmera esta ativa e todas as <br /> notificações chegarão no seu telegram
          </TitleAlt>
        )}
        <ContainerMessage>
          {type.current === 'success' && (
            <Box display='flex' direction='column' align='center' rowGap='40px'>
              <Typography variant='p' style={{textAlign: 'center'}} size='18px'>
                E não acaba aqui, com seu login você pode acessar nosso portal e visualizar seu histórico de eventos e se necessário editar algumas informações,
                estaremos sempre por perto.
              </Typography>

              <Button onClick={() => navigate('/events')}>Quero acessar o portal Brain</Button>
            </Box>
          )}

          {type.current === 'custom' && (
            <Box display='flex' direction='column' align='center' maxWidth='760px'>
              <Typography variant='p' style={{textAlign: 'center'}} size='18px'>
                Nosso consultor especializado entrará em contato com você em breve para entender melhor suas necessidades e criar um plano personalizado que se
                encaixe perfeitamente para você. Estamos ansiosos para ajudá-lo a alcançar seus objetivos!
              </Typography>
            </Box>
          )}

          {type.current === 'company' && (
            <Box display='flex' direction='column' align='center' maxWidth='760px'>
              <Typography variant='p' style={{textAlign: 'center'}} size='18px'>
                Para oferecer a melhor solução para sua empresa, nosso consultor especializado entrará em contato com você em breve. Assim, podemos entender
                melhor as suas necessidades e fornecer uma proposta personalizada e adequada ao seu negócio.
              </Typography>

              <Typography variant='p' style={{textAlign: 'center'}} size='18px'>
                Estamos ansiosos para ajudar sua empresa a alcançar resultados incríveis!
              </Typography>
            </Box>
          )}
        </ContainerMessage>
      </Box>
    </Box>
  )
}

export default StatusScreen
