import {Box, Button, FormControl, Modal, TextField} from '@mui/material'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import {daysMap, sendPostSchedule} from './CameraReceiveEventModalFunctions'
import {
  AddButtonModal,
  BoxCameraModal,
  CancelButtonModal,
  CheckboxDay,
  DayCircle,
  DayOptions,
  IntervalForm,
  ModalTitle,
  SpanContainerDay,
  StyleBoxModalConfig,
} from './styles'

export function CameraReceiveEventModal() {
  const [checked, setChecked] = useState<number[]>([])
  const [startTime, setStartTime] = useState('00:00:00')
  const [endTime, setEndTime] = useState('23:59:59')
  const {enqueueSnackbar} = useSnackbar()
  const {camera, isOpenReceiveModal, setIsOpenReceiveModal} = useCameraConfigContext()

  const onCancelButton = () => {
    setIsOpenReceiveModal(false)
    setChecked([])
    setStartTime('00:00:00')
    setEndTime('23:59:59')
  }

  return (
    <div>
      <Modal open={isOpenReceiveModal} onClose={() => setIsOpenReceiveModal(false)}>
        <StyleBoxModalConfig>
          <ModalTitle>Escolha um intervalo</ModalTitle>
          <IntervalForm>
            <DayOptions>
              {Object.keys(daysMap).map((key, index) => {
                const day = Number(key)
                const ch = daysMap[+day][0]
                const isChecked = checked.includes(day)

                return (
                  <React.Fragment key={`day-checkbox-${index}`}>
                    <SpanContainerDay isSelected={isChecked} index={index}>
                      <CheckboxDay
                        type='checkbox'
                        value={day}
                        onChange={() => {
                          if (isChecked) {
                            setChecked(checked.filter((x) => x !== day))
                          } else {
                            setChecked([...checked, day])
                          }
                        }}
                      />
                      <DayCircle>{ch}</DayCircle>
                    </SpanContainerDay>
                  </React.Fragment>
                )
              })}
            </DayOptions>
          </IntervalForm>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <FormControl fullWidth>
              <TextField
                label='Início'
                required
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                type='time'
                inputProps={{step: 2, type: 'time'}}
                InputLabelProps={{shrink: true}}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label='Final'
                required
                value={endTime}
                onChange={(e) => setEndTime(e.target.value)}
                variant='outlined'
                inputProps={{step: 2, type: 'time'}}
                InputLabelProps={{shrink: true}}
              />
            </FormControl>
          </Box>
          <Box sx={BoxCameraModal}>
            <Button onClick={onCancelButton} sx={CancelButtonModal}>
              Cancelar
            </Button>
            <Button
              variant='contained'
              sx={AddButtonModal}
              onClick={async () => {
                if (await sendPostSchedule(checked, startTime, endTime, camera, enqueueSnackbar)) setIsOpenReceiveModal(false)
              }}>
              Adicionar
            </Button>
          </Box>
        </StyleBoxModalConfig>
      </Modal>
    </div>
  )
}
