import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_BRAINBOX_BASE_URL

export type CreateBrainBoxOutput = {
  brainBoxId: number
  description: string
  channel: number | null
}

export type UpdateBrainBoxOutput = {
  description?: string
  isActive?: boolean
}

export type BrainBoxOutput = {
  id: number
  brainBoxId: number
  description?: string
  channel: number
  isActive?: boolean
  lastTrigger?: string
  lastTriggerEject?: string
  triggerTime?: number
  actionId?: number
}

export type BrainBoxInput = {
  id: number
  brainBoxId: number
  description?: string
  channel: number
  isActive?: boolean
}

export type BrainBoxAction = {
  id: number
  triggerTime: number
  outputDeviceChannel: number
  inputDeviceId: number
}

type GetBrainBoxOutputDevicesParams = {
  includes?: string[]
  filter?: {
    status: string
  }
  brainBoxId: number
}

type ActionUpsert = {
  triggerTime?: number
  outputDeviceChannel: number
}

export type BrainboxUpsertActions = {
  inputDeviceId: number
  inputDeviceActions: ActionUpsert[]
}
export class BrainBoxOutputDevices {
  async getAll(params: GetBrainBoxOutputDevicesParams): Promise<AxiosResult<Paginate<BrainBoxOutput>>> {
    return await axios.get(`${prefix}/brain-box/output-devices/all`, {
      params: prepareParams(params),
    })
  }

  async createBrainBoxOutputDevices(body: CreateBrainBoxOutput): Promise<AxiosResult<CreateBrainBoxOutput>> {
    return await axios.post(`${prefix}/brain-box/output-devices`, body)
  }

  async updateBrainBoxOutput(id: number, body: UpdateBrainBoxOutput) {
    return await axios.patch(`${prefix}/brain-box/output-devices/${id}`, body)
  }

  async delete(id: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/brain-box/output-devices/${id}`)
  }

  async forceShootOutputDevices(id: number): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/brain-box/output-devices/trigger/${id}`)
  }

  async stopShootOutputDevices(id: number): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/brain-box/output-devices/eject/${id}`)
  }

  async getBrainBoxInputDevices(params: GetBrainBoxOutputDevicesParams): Promise<AxiosResult<Paginate<BrainBoxInput>>> {
    return await axios.get(`${prefix}/brain-box/input-devices/all`, {
      params: prepareParams(params),
    })
  }

  async getBrainBoxInputDeviceActions(inputDeviceId: number): Promise<AxiosResult<Paginate<BrainBoxAction>>> {
    return await axios.get(`${prefix}/brain-box/input-devices/actions?inputDeviceId=${inputDeviceId}`)
  }

  async upsertActions(body: BrainboxUpsertActions): Promise<AxiosResult<BrainBoxAction>> {
    return await axios.put(`${prefix}/brain-box/input-devices/actions`, body)
  }

  async updateBrainBoxInput(id: number, body: UpdateBrainBoxOutput) {
    return await axios.patch(`${prefix}/brain-box/input-devices/${id}`, body)
  }
}
