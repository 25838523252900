import {Chip} from '@mui/material'
import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import moment from 'moment'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {Company} from 'src/services/api'
import {Page} from 'src/types'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Pagination from '../../../components/pagination/Pagination'
import CompanyMenuOptions from './CompanyButtonOptions'

type CompanyGridTableProps = {
  companies: Company[]
  loadingCompanies: boolean
  totalElements: number
  companiesPage: Page
  setCompaniesPage: Function
  openEditModal: (company: Company) => void
  selectedCompaniesIds: number[]
  setSelectedCompaniesIds: (ids: number[]) => void
  openConfirmCredentialsDialog: (company: Company) => void
  deleteCompany: (companyId?: number) => void
}

function CompanyGridTable(props: CompanyGridTableProps) {
  const {
    companies: companiesRaw,
    loadingCompanies,
    totalElements,
    companiesPage,
    setCompaniesPage,
    openEditModal,
    selectedCompaniesIds,
    setSelectedCompaniesIds,
    openConfirmCredentialsDialog,
    deleteCompany,
  } = props
  const hasUpdateCompanyPermission = ValidatePermissions('companies:update')
  const hasDeleteCompanyPermission = ValidatePermissions('companies:delete')
  const companiesPageChange = (page: number) => setCompaniesPage((lastPage: Page) => ({...lastPage, page}))
  const companiesPageSizeChange = (pageSize: number) => setCompaniesPage((lastPage: Page) => ({...lastPage, pageSize}))
  const [companies, setCompanies] = useState<CompanyTableProps[]>([])
  const navigate = useNavigate()

  const control = {
    currentPage: companiesPage.page,
    setCurrentPage: companiesPageChange,
    nextPage: () => companiesPageChange(companiesPage.page + 1),
    prevPage: () => companiesPageChange(companiesPage.page - 1),
    changePageSize: (pageSize: number) => companiesPageSizeChange(pageSize),
  }

  type CompanyTableProps = {
    id: number
    name: string
    document: string
    apiUser?: string
    retail: boolean
    dateFormated: string
    expirationDate?: string
    contract: string
    self: Company
  }

  useEffect(() => {
    const companiesMapped = companiesRaw.map((it) => {
      const days = moment().format('YYYY-MM-DD')
      const inExpiration = it.expirationDate
      const timeDiff = moment(inExpiration).diff(days, 'day')
      const isRetail = it.permissions?.map((it) => it.codename).includes('analytic-cameras:read')

      return {
        id: it.id,
        name: it.name,
        document: it.document,
        apiUser: it.apiUser,
        retail: !!isRetail,
        dateFormated: it.expirationDate ? moment(it.expirationDate).calendar() : '-',
        expirationDate: it.expirationDate,
        contract: it.expirationDate ? (timeDiff <= 15 && timeDiff >= 0 ? 'Teste' : timeDiff < 0 ? 'Expirado' : '-') : '-',
        self: it,
      }
    })
    setCompanies(companiesMapped)
  }, [companiesRaw])

  const columns = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'name',
      name: 'Nome',
    },
    {
      id: 'document',
      name: 'CNPJ',
    },
    {
      id: 'retail',
      name: 'Tipo',
    },
    {
      id: 'apiUser',
      name: 'Usuário',
    },
    {
      id: 'expirationDate',
      name: 'Data de Expiração',
    },
    {
      id: 'actions',
      name: '',
    },
  ]

  const columnsNoPermission = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'name',
      name: 'Nome da Empresa',
    },
    {
      id: 'document',
      name: 'CNPJ',
    },
    {
      id: 'expirationDate',
      name: 'Data de Expiração',
    },
  ]
  const onSelectAll = () => {
    if (selectedCompaniesIds.length) return setSelectedCompaniesIds([])
    const allIds = companies.map((row) => row.id)
    setSelectedCompaniesIds(allIds)
  }
  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={selectedCompaniesIds.length ? companies.length === selectedCompaniesIds.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )
  if (!hasDeleteCompanyPermission) {
    columns.shift()
    columnsNoPermission.shift()
  }

  function renderNameWithChip(row: CompanyTableProps) {
    const isB2cCompanie = row.document.length <= 14

    return (
      <LinkWithAction
        onClick={() => {
          const {id, name, document, expirationDate} = row.self
          if (hasUpdateCompanyPermission) openEditModal({id, name, document: document, expirationDate})
        }}>
        <Box display='flex' columnGap='15px'>
          {row.name}

          {row.contract === 'Expirado' ? (
            <Chip label={row.contract} color='error' size='small' variant='outlined' />
          ) : row.contract === 'Teste' && !isB2cCompanie ? (
            <Chip label={row.contract} color='warning' size='small' variant='outlined' />
          ) : (
            ''
          )}

          {isB2cCompanie && <Chip label={'Cliente B2C'} color='success' size='small' variant='outlined' />}
        </Box>
      </LinkWithAction>
    )
  }

  const conditionalRenderTableData = (row: CompanyTableProps) => {
    if (hasUpdateCompanyPermission)
      return (
        <>
          <TableData>{row.document}</TableData>
          <TableData>{row.retail ? 'Varejo' : 'Padrão'}</TableData>
          <TableData>{row.apiUser}</TableData>
          <TableData>{row.dateFormated}</TableData>
          <TableData>
            <CompanyMenuOptions
              oneObject={row.id}
              onClick={(event, oneObject) => {
                if (event === 'set-permissions') navigate(row.id.toString())
                if (event === 'edit-company') {
                  const company = row
                  const {id, name, document, expirationDate} = company
                  openEditModal({id, name, document: document, expirationDate})
                }
                if (event === 'update-credentials') {
                  const company = row
                  const {id, name, document, expirationDate} = company
                  openConfirmCredentialsDialog({id, name, document: document, expirationDate})
                }
                if (event === 'delete') {
                  setSelectedCompaniesIds([+row.id])
                  deleteCompany(row.id)
                }
              }}
              hasDeleteCompanyPermission={hasDeleteCompanyPermission}
            />
          </TableData>
        </>
      )
    return (
      <>
        <TableData>{row.document}</TableData>
        <TableData>{row.dateFormated}</TableData>
      </>
    )
  }

  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table
          columns={hasUpdateCompanyPermission ? columns : columnsNoPermission}
          skeletonRowsAmount={10}
          isLoading={loadingCompanies}
          tableHeaderChildren={customHeader}>
          {companies.map((row) => (
            <RowHover>
              <RenderIfConditionIsMet condition={hasDeleteCompanyPermission}>
                <TableData>
                  <CheckboxTable id={row.id} selectedIds={selectedCompaniesIds} setSelectedIds={setSelectedCompaniesIds} />
                </TableData>
              </RenderIfConditionIsMet>
              <TableData>{renderNameWithChip(row)}</TableData>
              {conditionalRenderTableData(row)}
            </RowHover>
          ))}
        </Table>
      </Box>
      {!loadingCompanies && companies.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={totalElements}
            itemsPerPage={companiesPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default CompanyGridTable
