import ReplayIcon from '@mui/icons-material/Replay'
import {FormControl, TextField} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import ReactPlayer from 'react-player'
import Loading from 'src/components/Loading'
import NoImage from 'src/images/noImage.jpg'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import api from 'src/services/api'
import storage from 'src/services/storage'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isResolutionValid} from 'src/utilities/regexValidation'
import {GreenSwitch, ContainerBoxes} from './CameraGeneralDataBase'
import {CameraGeneralDataProps, LatLong} from './CameraGeneralDataProps'
import {
  CameraStatusLabel,
  ConectedCamStatus,
  ContainerGeneralData,
  ContainerStatusCamera,
  DivInfo,
  ImageToSnapshot,
  InfoConfigCamera,
  ReactPlayerContainer,
  TitleConfigCamera,
  TitleModalConfig,
} from './styles'
import {Box, Typography} from '@viptech/react-components'
import {capitalize} from 'lodash'

const playerConfig = {
  file: {
    attributes: {
      style: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        maxHeight: '345px',
        maxWidth: '700px',
      },
    },
    hlsOptions: {
      forceHLS: true,
      debug: false,
      xhrSetup: function (xhr: any) {
        xhr.setRequestHeader('Authorization', `Bearer ${storage.get('token')}`)
      },
    },
  },
}

export function CameraGeneralData(cameraGeneralDataProps: CameraGeneralDataProps) {
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [message, setMessage] = useState('')
  const [, setHashCode] = useState('')
  const [latLong, setLatLong] = useState<LatLong>({
    latitude: undefined,
    longitude: undefined,
  })
  const [isPlaying, setIsPlaying] = useState(false)
  const [playerURL, setPlayerURL] = useState('')
  const {camera, diffConfig, setDiffConfig, snapshot, reloadSnapshot, loadingSnapshot, setLoadingSnapshot} = useCameraConfigContext()
  const {isEditing, isCancel, getGeneralData} = cameraGeneralDataProps
  const {enqueueSnackbar} = useSnackbar()
  const blockStreamRequests = useRef(false)

  const isLive = useCallback(
    async (id: number) => {
      try {
        setLoadingSnapshot(true)
        if (!camera?.configuration.isOnline) return
        if (blockStreamRequests.current) return
        blockStreamRequests.current = true
        const response = await api.streaming.getStream(id)
        const url = api.streaming.startStream(response.data.data)
        setPlayerURL(url)
        setHashCode(response?.data?.data)
      } catch (error: any) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao abrir stream da câmera')
      } finally {
        setLoadingSnapshot(false)
        blockStreamRequests.current = false
      }
    },
    [enqueueSnackbar],
  )

  const handleChangeStatus = async () => {
    if (!camera) return

    try {
      await api.camera.setCamerasStatus([camera.id], !diffConfig!.isOnline)
      setDiffConfig({isOnline: !diffConfig!.isOnline})
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar status da câmera')
    }
  }

  const handleChangeLatLong = (key: keyof LatLong) => (e: any) => {
    if (e.target.value[0] === '.') return
    const v = e.target.value
    if (v.match(/^-?\d*\.?\d*$/)) {
      if (v.length === 1 && v[0] === '-') {
        setLatLong({...latLong, [key]: v})
      } else if (v.length > 0) {
        if (v[v.length - 1] === '.' && v.length > 2) {
          setLatLong({...latLong, [key]: v})
        } else {
          const value = Math.min(Math.max(Number(v), -180), 180)
          setLatLong({...latLong, [key]: value})
        }
      } else {
        setLatLong({...latLong, [key]: v})
      }
    }
  }

  useEffect(() => {
    if (getGeneralData) getGeneralData(latLong, isConfirmed)
  }, [getGeneralData, latLong, isConfirmed])

  useEffect(() => {
    if (camera && isCancel) {
      setLatLong({longitude: camera.longitude ? camera.longitude : '-', latitude: camera.latitude ? camera.latitude : '-'})
    }
  }, [camera, isCancel])

  useEffect(() => {
    if (camera && camera.server)
      setLatLong((prev) => ({
        ...prev,
        latitude: camera.latitude === null ? '-' : camera.latitude,
        longitude: camera.longitude === null ? '-' : camera.longitude,
      }))
  }, [camera])

  useEffect(() => {
    if (!isResolutionValid(String(diffConfig?.snapshotWidth))) {
      setIsConfirmed(false)
      setMessage('Largura mínima 240. Máxima 1280.')
      return
    } else {
      setIsConfirmed(true)
    }

    if (!isResolutionValid(String(diffConfig?.snapshotHeight))) {
      setIsConfirmed(false)
      setMessage('Altura mínima 240. Máxima 1280.')
      return
    } else {
      setIsConfirmed(true)
    }
  }, [diffConfig?.snapshotWidth, diffConfig?.snapshotHeight])

  useEffect(() => {
    if (camera === null) return
    if (!camera.origin.includes('RT')) return
    isLive(camera.id)
  }, [camera, isLive])

  const handleReloadButtonClick = () => {
    if (camera === null) return
    if (camera.origin.includes('RT')) return isLive(camera.id)
    return reloadSnapshot
  }

  if (!camera) return
  return (
    <ContainerGeneralData>
      <ReactPlayerContainer>
        <ContainerBoxes sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Box display='flex' justifyContent='center' align='center' width='100%'>
            {loadingSnapshot && <Loading width='50px' height='50px' />}

            <Box style={{display: !isPlaying ? 'none' : 'block', maxWidth: '700px', maxHeight: '345px', width: '100%', height: '100%'}}>
              <ReactPlayer url={playerURL} config={playerConfig} onReady={() => setIsPlaying(true)} playing={isPlaying} width='100%' height='100%' />
            </Box>

            {snapshot && !isPlaying && !loadingSnapshot && !camera.origin.includes('RT') && (
              <ImageToSnapshot src={snapshot ? `data:image/jpeg;base64,${snapshot}` : NoImage} alt='snapshot' />
            )}
          </Box>

          <button onClick={handleReloadButtonClick} color='transparent' style={{background: 'none', alignItems: 'flex-start', display: 'flex'}}>
            <ReplayIcon color='primary' style={{transform: 'scaleX(-1) rotate(0.85turn)'}} />
          </button>
        </ContainerBoxes>
      </ReactPlayerContainer>

      <ContainerGeneralData>
        <ContainerBoxes>
          <TitleModalConfig>Dados do Dispositivo</TitleModalConfig>

          <Box direction='column' display='flex' rowGap='30px'>
            <Box direction='column' display='flex'>
              <TitleConfigCamera>Status</TitleConfigCamera>

              <ContainerStatusCamera>
                <CameraStatusLabel status={camera.status.description}>{capitalize(camera.status.description)}</CameraStatusLabel>

                <ConectedCamStatus isOnline={diffConfig?.isOnline!}>
                  {diffConfig && diffConfig.isOnline ? 'Habilitada' : 'Desabilitada'}
                  <GreenSwitch size='small' onChange={() => handleChangeStatus()} checked={diffConfig?.isOnline} />
                </ConectedCamStatus>
              </ContainerStatusCamera>
            </Box>

            <Box direction='column' display='flex' rowGap='10px'>
              <DivInfo>
                <TitleConfigCamera>ID</TitleConfigCamera>
                <InfoConfigCamera>{camera.id}</InfoConfigCamera>
              </DivInfo>

              <DivInfo>
                <TitleConfigCamera>Origem</TitleConfigCamera>
                <InfoConfigCamera>{camera.origin}</InfoConfigCamera>
              </DivInfo>

              <DivInfo>
                <TitleConfigCamera>Atualizado em </TitleConfigCamera>
                <InfoConfigCamera>{moment(camera.lastUpdate).format('DD/MM/YYYY HH:mm:ss')}</InfoConfigCamera>
              </DivInfo>

              <DivInfo>
                <TitleConfigCamera>Cliente</TitleConfigCamera>
                {camera.client && (
                  <InfoConfigCamera onClick={() => window.open(`/registers/clients?id=${camera?.clientId}`)} cursor='pointer' textDecoration='underline'>
                    {camera.client?.name}
                  </InfoConfigCamera>
                )}
              </DivInfo>
            </Box>
          </Box>
        </ContainerBoxes>

        <Box display='flex' direction='column' rowGap='20px'>
          <ContainerBoxes>
            <Box display='flex' direction='column' paddingBottom='9px'>
              <TitleModalConfig>Localização</TitleModalConfig>

              <DivInfo>
                <FormControl>
                  <TextField
                    id='outlined-number'
                    label='Latitude'
                    type='text'
                    size='small'
                    value={latLong.latitude}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!isEditing}
                    onChange={handleChangeLatLong('latitude')}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id='outlined-number'
                    label='Longitude'
                    type='text'
                    size='small'
                    value={latLong.longitude}
                    onChange={handleChangeLatLong('longitude')}
                    sx={{marginTop: 2.5}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!isEditing}
                  />
                </FormControl>
              </DivInfo>
            </Box>
          </ContainerBoxes>

          <ContainerBoxes>
            <Box display='flex' direction='column' paddingBottom='9px'>
              <TitleModalConfig>Tamanho da Imagem</TitleModalConfig>
              <DivInfo>
                <TextField
                  id='outlined-number'
                  label='Largura'
                  type='text'
                  size='small'
                  value={diffConfig?.snapshotWidth && diffConfig?.snapshotWidth > 0 ? diffConfig?.snapshotWidth : ''}
                  onChange={(e) => setDiffConfig({snapshotWidth: Number(e.target.value.replace(/[^0-9]+/, ''))})}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: 1280,
                    min: 640,
                  }}
                  disabled={!isEditing}
                />
                <TextField
                  id='outlined-number'
                  label='Altura'
                  type='text'
                  size='small'
                  sx={{marginTop: 2}}
                  value={diffConfig?.snapshotHeight && diffConfig?.snapshotHeight >= 0 ? diffConfig?.snapshotHeight : ''}
                  onChange={(e) => setDiffConfig({snapshotHeight: Number(e.target.value.replace(/[^0-9]+/, ''))})}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    max: 720,
                    min: 480,
                  }}
                  disabled={!isEditing}
                />
              </DivInfo>
              <DivInfo>
                <Typography variant='p' hidden={isConfirmed} style={{color: '#FF9900', fontSize: '1em', paddingTop: '2px'}}>
                  {message}
                </Typography>
              </DivInfo>
            </Box>
          </ContainerBoxes>
        </Box>
      </ContainerGeneralData>
    </ContainerGeneralData>
  )
}
