import styled from 'styled-components'

interface SpanContainer {
  index: number
  isSelected: boolean
}

export const StyleBoxModalConfig = styled.div`
  background-color: #fafafa;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 2px;
  box-shadow:
    0px 2px 4px -1px #00000033,
    0px 4px 5px 0px #00000024,
    0px 1px 10px 0px #0000001f;
  top: 50%;
  left: 50%;
  position: absolute;
  overflow-y: auto;
  width: max-content;
  height: auto;
  max-height: 800px;
  transform: translate(-50%, -50%);
  padding: 16px 24px;
`

export const ModalTitle = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.6;
  margin-bottom: 20px;
`

export const IntervalForm = styled.form``

export const DayOptions = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  margin-bottom: 15px;
`

export const SpanContainerDay = styled.span<SpanContainer>`
  display: inline-flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  border-radius: 50%;
  background-color: ${(props) => (props.isSelected ? '#46464f' : '#46464f99')};
  color: #ffffff;
  margin-left: ${(props) => (props.index === 0 ? '' : '15px')};
`

export const CheckboxDay = styled.input`
  position: absolute;
  opacity: 0;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  margin: 0px;
  padding: 0px;
  z-index: 1;
  cursor: inherit;
`

export const DayCircle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
`

export const BoxCameraModal = {
  display: 'flex',
  justifyContent: 'right',
  marginTop: '30px',
  fontFamily: 'Inter',
}

export const CancelButtonModal = {
  width: '150px',
  height: '40px',
  marginRight: 2,
  marginBottom: 0,
  border: '1px solid',
  fontFamily: 'Inter',
  textTransform: 'none',
}

export const AddButtonModal = {
  width: '150px',
  height: '40px',
  fontFamily: 'Inter',
  textTransform: 'none',
}
