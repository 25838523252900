import Drawer from '@mui/material/Drawer'
import {styled} from '@mui/material/styles'
import {Theme} from '@mui/material/styles'
import {CSSObject} from 'styled-components'

export const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  overflowX: 'hidden',
})

export const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shorter,
  }),
  overflowX: 'hidden',
  width: `calc(64px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(64px)`,
  },
})

const DrawerCustom = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'ola',
})(({theme, open}) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default DrawerCustom
