
export default function ArrowRightIcon({ color = 'black', width = '24' }: { color?: string; width?: string }) {
  return (
    <svg
      width={width ?? '24'}
      height={width ?? '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6_13422)">
        <path d="M10 17L15 12L10 7V17Z" fill={color ?? '#323232'} />
      </g>
      <defs>
        <clipPath id="clip0_6_13422">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
