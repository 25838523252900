import {Box} from '@mui/material'
import {useCallback, useEffect, useState} from 'react'
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom'
import {
  AnalyticCamerasPermissions,
  B2cPermission,
  BrainboxPermissions,
  CameraMosaicPermissions,
  ClientsPermissions,
  CompaniesPermissions,
  CredentialsPermissions,
  DashboardPermissions,
  DevicesPermissions,
  EventsPermissions,
  FaceListPermissions,
  ImagensPermissions,
  LayoutsPermissions,
  LprListPermissions,
  LprPermissions,
  NotificationsPermissions,
  QuotasPermissions,
  ServerEntradaPermissions,
  ServerSaidaPermissions,
  UserPermissions,
} from 'src/common/utils/PermissionsList'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import Loading from 'src/components/Loading'
import {useAuth} from 'src/contexts/AuthContext'
import MainLayout from 'src/layouts/main-layout'
import Alarms from 'src/pages/alarms/Alarms'
import AnalyticCameras from 'src/pages/analytic-cameras/AnalyticCameras'
import BrainBoxPage from 'src/pages/brain-box'
import BrainBoxConfig from 'src/pages/brain-box-config/BrainBoxConfig'
import CameraMosaic from 'src/pages/camera-mosaic'
import Devices from 'src/pages/cameras/Cameras'
import Clients from 'src/pages/clients/Clients'
import Companies from 'src/pages/companies'
import CompanyRegisterPermissions from 'src/pages/companies/components/CompanyRegisterPermissions'
import Layouts from 'src/pages/layouts'
import LayoutShow from 'src/pages/layouts/components/LayoutShow'
import Lists from 'src/pages/lists'

import B2CLayout from 'src/layouts/b2c-layout/index'
import Login from 'src/pages/login/Login'
import {LicensePlate} from 'src/pages/lpr/LicensePlate'
import ReleaseNotes from 'src/pages/notifications'
import ChangePassword from 'src/pages/password-change/ChangePassword'
import DefinePassword from 'src/pages/password-change/DefinePassword'
import ForgotPassword from 'src/pages/password-change/ForgotPassword'
import Quotas from 'src/pages/quotas'
import Payment from 'src/pages/self-register/payment/Payment'
import RtmpCamera from 'src/pages/self-register/rtmp-camera/index'
import PlanSelect from 'src/pages/self-register/select-plan/PlanSelect'
import SelfRegister from 'src/pages/self-register/self-register/SelfRegister'
import {SettingsCompanyData} from 'src/pages/settings/pages/settings-company-data/SettingsCompanyData'
import {SettingsCredentials} from 'src/pages/settings/pages/settings-credentials/SettingCredentials'
import Servers from 'src/pages/settings/pages/settings-servers/index'
import Transactions from 'src/pages/transactions'
import DguardGuide from 'src/pages/tutorials/pages/D_Guard'
import DigifortGuide from 'src/pages/tutorials/pages/Digifort'
import MoniGuide from 'src/pages/tutorials/pages/Moni'
import Users from 'src/pages/users'
import UserRegisterPermissions from 'src/pages/users/components/UserRegisterPermissions'
import {CameraConfig} from '../pages/camera-config/CameraConfig'
import RedirectToDashboard from '../pages/dashboard-retail/RedirectToDashboard'
import ForbiddenPage from '../pages/forbidden/Forbidden'
import FacialListTable from '../pages/lists/components/facialComponents/FacialListTable'
import PlatesListTable from '../pages/lists/components/platesComponents/PlatesListTable'
import RedirectTo from '../pages/redirect/RedirectTo'
import StatusScreen from '../pages/self-register/status-screen/StatusScreen'
import RouteRedirector from './RouteRedirector'
import {PrivateRoute} from './RoutesPermission'
import SubMenus from './SubMenus'
import ConfirmationPage from 'src/pages/self-register/self-register/confirmation-email-page/ConfirmationPage'
import Terms from 'src/pages/terms/Terms'

function Starting() {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Loading />
    </Box>
  )
}

function RoutesPage() {
  const {isAuth, user, userToken, noPasswordAllowed, checksToken} = useAuth()
  const [tokenChecked, setTokenChecked] = useState(false)

  function getListPermissions() {
    if (!isAuth) return [{name: 'face:read'}, {name: 'lpr:read'}]
    const hasFaceList = ValidatePermissions('face:read')
    const hasLprList = ValidatePermissions('lpr:read')
    if (hasFaceList) return [{name: 'face:read'}]
    if (hasLprList) return [{name: 'lpr:read'}]
    return [{name: 'face:read'}, {name: 'lpr:read'}]
  }

  const passwordRedirect = useCallback(() => {
    if (noPasswordAllowed)
      return (
        <Routes>
          <Route path='define-password' element={<DefinePassword />} />
          <Route path='*' element={<Navigate to={'define-password'} replace />} />
        </Routes>
      )
    return (
      <Routes>
        <Route path='change-password' element={<ChangePassword />} />
        <Route path='*' element={<Navigate to={'change-password'} replace />} />
      </Routes>
    )
  }, [noPasswordAllowed])

  function loggedRoutes() {
    const multipleAuthorizedCompanies = userToken && userToken.authorizedCompanies ? userToken.authorizedCompanies.length > 1 : false
    return (
      <Routes>
        <Route path='change-password' element={<ChangePassword />} />
        <Route path='define-password' element={<DefinePassword />} />
        <Route path='layout' element={<LayoutShow />} />
        <Route path='tutorials/digifort' element={<DigifortGuide />} />
        <Route path='tutorials/dguard' element={<DguardGuide />} />
        <Route path='tutorials/moni' element={<MoniGuide />} />
        <Route element={<MainLayout />}>
          <Route path='forbidden' element={<ForbiddenPage />} />
          <Route path='dashboard' element={<PrivateRoute permissions={DashboardPermissions} component={RedirectToDashboard} />} />
          <Route path='events' element={<PrivateRoute permissions={EventsPermissions} component={Alarms} />} />
          <Route path='/license-plate' element={<PrivateRoute permissions={LprPermissions} component={LicensePlate} />} />
          <Route path='notifications' element={<PrivateRoute permissions={NotificationsPermissions} component={ReleaseNotes} />} />
          <Route path='camera-mosaic/:id?' element={<PrivateRoute permissions={CameraMosaicPermissions} component={CameraMosaic} />} />
          <Route path='transactions' element={<PrivateRoute permissions={ImagensPermissions} component={Transactions} />} />
          <Route element={<SubMenus />}>
            <Route path='registers/devices' element={<PrivateRoute permissions={DevicesPermissions} component={Outlet} />}>
              <Route index element={<Devices />} />
              <Route path=':id' element={<CameraConfig />} />
            </Route>
            <Route path='registers/analytic-cameras' element={<PrivateRoute permissions={AnalyticCamerasPermissions} component={AnalyticCameras} />}></Route>
            <Route path='registers/brainbox' element={<PrivateRoute permissions={BrainboxPermissions} component={Outlet} />}>
              <Route index element={<BrainBoxPage />} />
              <Route path=':id' element={<BrainBoxConfig />} />
            </Route>
            <Route element={<SubMenus />}>
              <Route path='registers/clients' element={<PrivateRoute permissions={ClientsPermissions} component={Outlet} />}>
                <Route index element={<Clients />} />
              </Route>
            </Route>
            <Route path='registers/layouts' element={<PrivateRoute permissions={LayoutsPermissions} component={Layouts} />} />
            <Route path='registers/lists' element={<PrivateRoute permissions={getListPermissions()} component={Outlet} />}>
              <Route index element={<Lists />} />
              <Route path='facial/:id' element={<PrivateRoute permissions={FaceListPermissions} component={FacialListTable} />} />
              <Route path='plates/:id' element={<PrivateRoute permissions={LprListPermissions} component={PlatesListTable} />} />
            </Route>
            <Route path='registers/users' element={<PrivateRoute permissions={UserPermissions} component={Outlet} />}>
              <Route index element={<Users />} />
              <Route path=':id' element={<UserRegisterPermissions />} />
            </Route>
          </Route>
          <Route element={<SubMenus />}>
            <Route
              path='settings/inputs'
              element={<PrivateRoute permissions={ServerEntradaPermissions} component={() => <Servers key={1} integrationType={'ENTRADA'} />} />}
            />
            <Route
              path='settings/outputs'
              element={<PrivateRoute permissions={ServerSaidaPermissions} component={() => <Servers key={2} integrationType={'SAÍDA'} />} />}
            />
            <Route
              path='settings/credentials'
              element={<PrivateRoute permissions={CredentialsPermissions} component={SettingsCredentials} disabled={multipleAuthorizedCompanies} />}
            />
            <Route
              path='settings/company-data'
              element={<PrivateRoute permissions={CompaniesPermissions} component={SettingsCompanyData} disabled={multipleAuthorizedCompanies} />}
            />
          </Route>
          <Route element={<SubMenus />}>
            <Route path='administrative/quotas' element={<PrivateRoute permissions={QuotasPermissions} component={Quotas} />} />
            <Route
              path='administrative/companies'
              element={<PrivateRoute permissions={CompaniesPermissions} component={Outlet} disabled={!multipleAuthorizedCompanies} />}>
              <Route index element={<Companies />} />
              <Route path=':id' element={<CompanyRegisterPermissions />} />
            </Route>
          </Route>
          <Route path='terms' element={<Terms />} />
        </Route>
        <Route element={<B2CLayout />}>
          <Route path='select-plan' element={<PrivateRoute permissions={B2cPermission} component={PlanSelect} />} />
          <Route path='payment-method' element={<PrivateRoute permissions={B2cPermission} component={Payment} />} />
          <Route path='camera-config' element={<PrivateRoute permissions={B2cPermission} component={RtmpCamera} />} />
          <Route path='status' element={<PrivateRoute permissions={B2cPermission} component={StatusScreen} />} />
        </Route>
        <Route path='/' element={<RedirectTo user={user} />} />
        <Route path='*' element={<RedirectTo user={user} />} />
      </Routes>
    )
  }

  function notLoggedRoutes() {
    return (
      <Routes>
        <Route path='login' element={<Login />} />
        <Route path='terms' element={<Terms />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route element={<B2CLayout />}>
          <Route path='confirmation' element={<ConfirmationPage />} />
          <Route path='sign-up' element={<SelfRegister />} />
          <Route path='status' element={<StatusScreen />} />
        </Route>
        <Route path='/' element={<RouteRedirector />} />
        <Route path='*' element={<RouteRedirector />} />
      </Routes>
    )
  }

  function getRoutes() {
    if (isAuth) {
      if (user?.forcePasswordUpdate) {
        return passwordRedirect()
      }
      return loggedRoutes()
    }
    return notLoggedRoutes()
  }

  useEffect(() => {
    async function checkUserToken() {
      await checksToken()
      setTokenChecked(true)
    }
    checkUserToken()
  }, [])

  if (!tokenChecked) return <Starting />

  return <BrowserRouter>{getRoutes()}</BrowserRouter>
}

export default RoutesPage
