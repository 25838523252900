import DeleteIcon from '@mui/icons-material/Delete'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import RedoIcon from '@mui/icons-material/Redo'
import UndoIcon from '@mui/icons-material/Undo'
import {Box, IconButton, Tooltip} from '@mui/material'
import {konvaImageDrawToolbarStyles} from './KonvaImageDrawToolbarBase'

type KonvaImageDrawToolbarProps = {
  editable: boolean
  onClickClearLast: () => void
  onClickClear: () => void
  onClickUndo: () => void
  onClickRedo: () => void
}

export function KonvaImageDrawToolbar(konvaImageDrawToolbarProps: KonvaImageDrawToolbarProps) {
  const {editable, onClickClearLast, onClickClear, onClickRedo, onClickUndo} = konvaImageDrawToolbarProps
  return (
    <Box sx={konvaImageDrawToolbarStyles.toolbar}>
      <Tooltip title='Deletar área'>
        <IconButton disabled={!editable} onClick={onClickClear}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title='Deletar o último ponto'>
        <IconButton disabled={!editable} onClick={onClickClearLast}>
          <DeleteOutlineIcon />
        </IconButton>
      </Tooltip>
      <IconButton disabled={!editable} onClick={onClickUndo}>
        <UndoIcon />
      </IconButton>
      <IconButton disabled={!editable} onClick={onClickRedo}>
        <RedoIcon />
      </IconButton>
    </Box>
  )
}
