import {Typography} from '@viptech/react-components'
import styled from 'styled-components'
import {SeparationStyleProps} from './SeparationProps'

export const SeparationLine = styled.div<SeparationStyleProps>`
  width: ${(props) => props.width ?? '100%'};
  height: ${(props) => props.height ?? '1px'};
  background-color: ${({color, theme}) => color ?? theme.colors.primary};
  ${({title}) => title && 'margin-left: 1rem'};
`

export const SeparationTitle = styled(Typography)`
  white-space: nowrap;
  font-family: ${({theme}) => theme.fonts.primary};
`
