import {AxiosError} from 'axios'
import {useEffect, useRef, useState} from 'react'
import {FacialListDTO} from 'src/common/models/facial-recognition/FacialListDTO'
import api from 'src/services/api'
import {Page} from 'src/types'

type FacialListItemsProps = {
  listId: number
  filter: string
  page: Page
}

function useFacialListItems(props: FacialListItemsProps) {
  const {filter: faceName, listId, page} = props
  const [listItems, setListItems] = useState<FacialListDTO[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [error, setError] = useState<AxiosError | null>(null)
  const blockRequests = useRef(false)

  async function getListItems() {
    if (blockRequests.current) return
    blockRequests.current = true
    setIsLoading(true)
    try {
      const res = await api.facialRecognition.getFacialList({page: page.page, pageSize: page.pageSize, search: {faceName}, filter: {listIds: [listId]}})
      setListItems(res.data.data.entities)
      setTotalItems(res.data.data.totalElements)
      setError(null)
    } catch (err) {
      const error = err as AxiosError
      setError(error)
    } finally {
      blockRequests.current = false
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getListItems()
  }, [])

  return {listItems, isLoading, totalItems, name: 'Faces', error, refresh: getListItems}
}

export default useFacialListItems
