import {CircularProgress, Grid, Typography} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import createStyleSheet from 'src/utilities/createStyleSheet'
import api from '../../../services/api'
import {CountByCategoriesDTO} from '../../../services/api/endpoints/ReportsEndpoint'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'
import {formatChartCountSeriesAndTranslateNames} from '../DashboardRetailFunctions'

type LineChartAccessPerWeekProps = {
  startTime: string
  endTime: string
  setSelectedNewDay: (date: string | number | undefined) => void
  setClick: (click: boolean) => void
}

function LineChartAccessPerWeek({startTime, endTime, setSelectedNewDay, setClick}: LineChartAccessPerWeekProps) {
  const {enqueueSnackbar} = useSnackbar()

  const [isloadingCountAccessPerWeek, setIsloadingCountAccessPerWeek] = useState(false)
  const [countAccessPerWeek, setCountAccessPerWeek] = useState<CountByCategoriesDTO>()
  const [auxCategories, setAuxCategories] = useState<CountByCategoriesDTO>()
  const showStartDate = moment(startTime).format('DD-MM-YYYY')
  const showEndDate = moment(endTime).format('DD-MM-YYYY')

  const loadCountAccessPerWeek = async () => {
    try {
      setIsloadingCountAccessPerWeek(true)
      const res = await api.reports.genderCount({start: startTime, end: endTime, countByDay: true})
      setAuxCategories(res.data.data)
      const translateSeries = formatChartCountSeriesAndTranslateNames(res.data.data, 'date')
      setCountAccessPerWeek(translateSeries)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar dados')
    } finally {
      setIsloadingCountAccessPerWeek(false)
    }
  }

  useEffect(() => {
    loadCountAccessPerWeek()
  }, [startTime])

  const opts: ApexCharts.ApexOptions = {
    series: countAccessPerWeek?.series ? countAccessPerWeek.series : [],
    chart: {
      type: 'line',
      zoom: {
        enabled: false,
      },
      fontFamily: 'Inter, sans-serif',
      events: {
        dataPointSelection(e, chart, options) {
          const selectedDataPoints: Array<any> = options.selectedDataPoints
          const arrayOfClicks = selectedDataPoints.flatMap((it) => it)
          const lastIndexOf = arrayOfClicks.pop()
          const newDateChosed = auxCategories?.categories[lastIndexOf]
          setClick(true)
          setSelectedNewDay(newDateChosed)
        },
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ['#0B62B9', '#9c27b0', '#26BA02'],
    xaxis: {categories: countAccessPerWeek?.categories},
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    title: {
      text: `Referência: ${showStartDate} a ${showEndDate}`,
      margin: 41,
      align: 'center',
      offsetX: -5,
      offsetY: -15,
      style: {
        fontSize: '14px',
        fontWeight: 0,
        fontFamily: "'Inter', 'sans-serif",
        color: '#364152',
      },
    },
    subtitle: {
      text: 'Quantidade de Acessos',
      offsetX: 0,
      offsetY: 60,
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: 'none',
        fontFamily: "'Inter', 'sans-serif",
        color: '#8E8E8E',
      },
    },
    grid: {
      show: true,
    },
    markers: {
      size: 1,
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        fillColors: ['#0B62B9', '#9c27b0', '#26BA02'],
      },
    },
    noData: {
      text: 'Nenhum dado foi encontrado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 20,
      offsetY: -15,
      style: {
        color: '#000000',
        fontSize: '10px',
        fontFamily: "'Inter', 'sans-serif",
      },
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    states: {
      active: {
        allowMultipleDataPointsSelection: true,
      },
    },
  }
  return (
    <Grid container sx={styles.grid} height={427}>
      <Grid item xs={12}>
        <Typography style={styles.title}>Acessos por Dias da Semana</Typography>
        {isloadingCountAccessPerWeek ? <CircularProgress /> : <ReactApexChart options={opts} series={opts.series} type='line' height={350} />}
      </Grid>
    </Grid>
  )
}

const styles = createStyleSheet({
  grid: {
    padding: 2,
    paddingLeft: 3.5,
    borderRadius: '8px',
    background: 'white',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#364152',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontSize: '16px',
    textAlign: 'center',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'left',
  },
  FilterRangeTimeButton: {
    width: '100px',
    height: '38.5px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginTop: '9.5px',
  },
})

export default LineChartAccessPerWeek
