import {Box} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {useAuth} from 'src/contexts/AuthContext'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {AddUser, AuthorizedClientsBody, AuthorizedCompaniesBody, Company, EditUser, RoleType, User} from 'src/services/api'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import {Page} from '../../types'
import UserGridTable from './components/UserGridTable'
import UserOptionsMenu from './components/UserOptionsMenu'
import UserRegisterModal from './components/UserRegisterModal'

export type UpdateUser = Omit<Partial<AddUser>, 'authorizedCompanies' | 'authorizedClients'>

export type RawUser = {
  id?: number
  name: string
  email: string
  authorizedCompanies: number[]
  authorizedClients: number[]
  role: string
  companyId?: number
}

const initialUser: RawUser = {
  name: '',
  email: '',
  role: 'operator',
  authorizedCompanies: [],
  authorizedClients: [],
}
const searchFilterData = [{id: '1', label: 'Nome do Usuário'}]

function Users() {
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const menuOpen = Boolean(menuEl)
  const {companies, selectedCompanies} = useCompany()
  const [userSearch, setUserSearch] = useState('')
  const userSearchDebounced = useDebounce(userSearch, 500)
  const [usersPage, setUsersPage] = useState<Page>({page: 1, pageSize: 20})
  const [openUserRegisterModal, setOpenUserRegisterModal] = useState<boolean>(false)
  const {enqueueSnackbar} = useSnackbar()
  const [users, setUsers] = useState<User[]>([])
  const [totalUsersElements, setTotalUsersElements] = useState<number>(0)
  const [reloadUserCount, setReloadUserCount] = useState(0)
  const [reloadingUser, setReloadingUser] = useState(false)
  const [loadingUser, setLoadingUser] = useState(true)
  const [roles, setRoles] = useState<RoleType[]>([{label: 'Usuário', name: 'operator', priority: 10}])

  const {user: currentUser} = useAuth()
  const [isSaving, setIsSaving] = useState(false)
  const [selectedUsersIds, setSelectedUsersIds] = useState<number[]>([])
  const [user, setUser] = useState<RawUser>(initialUser)
  const isEdit = user.id !== undefined
  const showOptionsButton = selectedUsersIds.length > 1
  const quantity = selectedUsersIds.length
  const isPlural: string = quantity <= 1 ? '' : 's'
  const [totalPage, setTotalPage] = useState<number>(0)
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [saveModalUsers, setSaveModalUsers] = useState<boolean>(false)
  const [idUser, setIdUser] = useState<number>()
  const hasCreateUserPermission = currentUser?.permissions.includes('users:create')
  const hasReadCompanyPermission = currentUser?.permissions.includes('companies:read')
  const [usersToDelete, setUsersToDelete] = useState<Array<number>>([])

  function handleOpen() {
    setOpenUserRegisterModal(true)
  }
  function handleClose() {
    setOpenUserRegisterModal(false)
    setIsSaving(false)
    reloadUsers()
  }

  useEffect(() => {
    if (openUserRegisterModal) return
    setUser(initialUser)
  }, [openUserRegisterModal])

  useEffect(() => {
    async function getRoles() {
      try {
        const response = await api.users.getRoles()
        setRoles(response.data.data)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar roles')
      }
    }
    getRoles()
  }, [enqueueSnackbar])

  async function openEditModal(user: EditUser) {
    try {
      if (hasReadCompanyPermission) setCompaniesScroll(companies)
      const response = await api.users.getById({id: user.id})
      setUser({
        id: response.data.data.id,
        name: response.data.data.name,
        email: response.data.data.email,
        authorizedCompanies: response.data.data.authorizedCompanies,
        authorizedClients: response.data.data.authorizedClients,
        role: response.data.data.role,
      })
      setOpenUserRegisterModal(true)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar usuário')
      return
    }
  }

  async function addUser(user: AddUser) {
    if (isSaving) return
    try {
      setIsSaving(true)
      const response = await api.users.addUser(user)
      enqueueSnackbar('Usuário adicionado com sucesso', {
        variant: 'success',
      })
      setIdUser(response.data.data.id)
      setSaveModalUsers(true)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar o usuário')
    }
    setIsSaving(false)
  }

  const editUser = async (
    userEdit: UpdateUser,
    companiesToAdd: AuthorizedCompaniesBody,
    companiesToRemove: AuthorizedCompaniesBody,
    clientsToAdd: AuthorizedClientsBody,
    clientsToRemove: AuthorizedClientsBody,
  ) => {
    async function saveCompaniesFirst(userId: number, data: EditUser) {
      if (companiesToAdd.companyIds.length > 0) await api.users.addAuthorizedCompanies(userId, companiesToAdd)
      if (companiesToRemove.companyIds.length > 0) await api.users.deleteAuthorizedCompanies(userId, companiesToRemove)
      if (data.email || data.name || data.role) await api.users.updateUser(data)
    }

    async function saveUserFirst(userId: number, data: EditUser) {
      if (data.email || data.name || data.role) await api.users.updateUser(data)
      if (companiesToAdd.companyIds.length > 0) await api.users.addAuthorizedCompanies(userId, companiesToAdd)
      if (companiesToRemove.companyIds.length > 0) await api.users.deleteAuthorizedCompanies(userId, companiesToRemove)
      if (clientsToAdd.clientIds.length > 0) await api.users.addAuthorizedClients(userId, clientsToAdd)
      if (clientsToRemove.clientIds.length > 0) await api.users.deleteAuthorizedClients(userId, clientsToRemove)
    }

    if (isSaving) return
    const userId = user.id
    if (!userId) throw new Error('Missing id when editing user')

    try {
      setIsSaving(true)
      const data = {...userEdit, id: userId} as EditUser
      if (userEdit.role === 'manager') {
        saveCompaniesFirst(userId, data)
      } else {
        saveUserFirst(userId, data)
      }
      enqueueSnackbar('Usuário alterado com sucesso', {
        variant: 'success',
      })
      reloadUsers()
      handleClose()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao alterar o usuário')
      handleClose()
    } finally {
      setIsSaving(false)
    }
  }

  async function deleteUser() {
    try {
      await api.users.deleteMany(usersToDelete)

      enqueueSnackbar('Usuário(s) removido(s) com sucesso', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover o(s) usuário(s)')
    }
    setSelectedUsersIds([])
    reloadUsers()
  }

  useEffect(() => {
    setUsersPage((lastPage) => ({...lastPage, page: 1}))
  }, [userSearchDebounced, selectedCompanies])

  useEffect(() => {
    async function getAllUsers() {
      try {
        const response = await api.users.getAll({
          page: usersPage.page === 0 ? 1 : usersPage.page,
          pageSize: usersPage.pageSize,
          filter: {companyIds: selectedCompanies},
          search: {name: userSearchDebounced},
          includes: ['permissions', 'roleEntity', 'authorizedCompanies'],
        })
        setUsers(response.data.data.entities)
        setTotalUsersElements(response.data.data.totalElements)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar Usuários')
      } finally {
        setLoadingUser(false)
        setReloadingUser(false)
      }
    }
    getAllUsers()
  }, [usersPage, enqueueSnackbar, reloadUserCount])

  useEffect(() => {
    async function loadCompanies() {
      if (!hasReadCompanyPermission) return
      try {
        const response = await api.company.getMany({
          orderBy: {names: 'ASC'},
        })

        setPageCount(pageCount + 1)

        setCompaniesScroll(response.data.data.entities)
        setTotalPage(response.data.data.totalPages!)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar empresas')
      }
    }
    loadCompanies()
  }, [enqueueSnackbar])

  const reloadUsers = () => {
    if (loadingUser) return
    setReloadingUser(true)
    setReloadUserCount((x) => x + 1)
  }

  const deleteUserWithConfirmation = (id: number) => {
    withConfirmation(`Deletar usuário${isPlural}`, `Você realmente deseja deletar este${isPlural} usuário${isPlural}? A ação não poderá ser desfeita.`, [id])
  }

  const usersPageChange = (page: number) => setUsersPage((lastPage) => ({...lastPage, page}))

  const usersPageSizeChange = (pageSize: number) => setUsersPage((lastPage) => ({...lastPage, pageSize}))

  function withConfirmation(message: string, title: string, userToDelete?: number[]) {
    setMenuEl(null)
    setModalContent(title)
    setOpenConfirmationModal(true)
    setModalTitle(message)
    if (userToDelete) setUsersToDelete(userToDelete)
  }
  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton onClick={() => reloadUsers()} loading={reloadingUser} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchFilterData}
              setSearchSelected={setShowSearchSelected}
              searchSelected={showSearchSelected}
              inputValue={userSearch}
              inputSearch={setUserSearch}
              widthOnSelect={'270px'}
            />
          </Box>
          <RenderIfConditionIsMet condition={hasCreateUserPermission}>
            <AppAddOrOptionsButton
              text='Adicionar um usuário'
              showOptions={showOptionsButton}
              onClickAdd={() => handleOpen()}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
            />
          </RenderIfConditionIsMet>
        </Box>
      </TopBar>
      <UserGridTable
        users={users}
        totalUsersElements={totalUsersElements}
        usersPage={usersPage}
        usersPageChange={usersPageChange}
        usersPageChangeSize={usersPageSizeChange}
        loadingUsers={loadingUser}
        openEditModal={openEditModal}
        selectedUsersIds={selectedUsersIds}
        roles={roles}
        setSelectedUsersIds={setSelectedUsersIds}
        notificationToDeleteUser={deleteUserWithConfirmation}
      />
      <ConfirmationModal
        title={modalTitle}
        content={modalContent}
        isOpen={openConfirmationModal}
        onClose={() => {
          setOpenConfirmationModal(false)
        }}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenConfirmationModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenConfirmationModal(false)
              setMenuEl(null)
              deleteUser()
            },
            variant: 'contained',
            dangerAction: true,
          },
        ]}
      />
      <UserRegisterModal
        idUser={idUser}
        saveModalUsers={saveModalUsers}
        setSaveModalUsers={setSaveModalUsers}
        hasReadCompanyPermission={hasReadCompanyPermission}
        open={openUserRegisterModal}
        handleClose={handleClose}
        user={user}
        addUser={addUser}
        isSaving={isSaving}
        companiesScroll={companiesScroll}
        setCompaniesScroll={setCompaniesScroll}
        editUser={editUser}
        isEdit={isEdit}
        pageCount={pageCount}
        setPageCount={setPageCount}
        totalPage={totalPage}
        roles={roles}
      />
      <UserOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onDelete={() =>
          withConfirmation(
            `Deletar usuário${isPlural}`,
            `Você realmente deseja deletar este${isPlural} usuário${isPlural}? A ação não poderá ser desfeita.`,
            selectedUsersIds,
          )
        }
      />
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default Users
