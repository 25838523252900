import styled from 'styled-components'
import {IconProps} from 'src/common/generics/props/IconProps'

export const SVGIconWrapper = styled.svg<IconProps>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  ${(props) => (props.margin ? `margin: ${props.margin};` : '')}
  ${(props) => (props.rotate ? `transform: rotate(${props.rotate});` : '')}
  ${(props) => (props.position ? `position: ${props.position};` : '')}
  ${(props) => (props.transition ? `transition: ${props.transition};` : '')}
  ${(props) => (props.top ? `top: ${props.top};` : '')}
  ${(props) => (props.left ? `left: ${props.left};` : '')}
  ${(props) => (props.right ? `right: ${props.right};` : '')}
  ${(props) => (props.bottom ? `bottom: ${props.bottom};` : '')}
  ${(props) => (props.cursor ? `cursor: ${props.cursor};` : '')};
`

export const SVGIconPath = styled.path<IconProps>`
  fill: ${(props) => props.fill};
`
