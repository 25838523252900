import {Box, Tooltip} from '@mui/material'
import {Table, TableData} from '@viptech/react-components'
import {useState} from 'react'
import {KonvaImageDetectionsFullscreen} from 'src/common/components/konva-image-detections-fullscreen/KonvaImageDetectionsFullscreen'
import Button from 'src/components/Button/Button'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import {LprLoadingPlateScaled} from 'src/pages/lpr/components/LprLoadingPlateScaled'
import MenuMoreInformations from 'src/pages/lpr/components/LprMoreInformations'
import {Lpr, LprFilterType} from 'src/services/api/endpoints/LprEndpoint'
import {AddPlateToListModal} from '../lpr-black-list/AddPlateToListModal'
import {lprGridTableStyles} from './LprGridTableBase'
import {lprEventRow} from './LprGridTableFunctions'

type LprGridTableProps = {
  loadingInformation: boolean
  loadingMore: boolean
  lprEvents: Lpr[]
  filter: LprFilterType
  setFilter: (filter: LprFilterType) => void
  reloadLprEvents: () => void
  canLoadMore: boolean
}

function LprGridTable(lprGridTableProps: LprGridTableProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenPlateList, setIsOpenPlateList] = useState(false)
  const [plateLpr, setPlateLpr] = useState('')
  const [imageKey, setImageKey] = useState('')
  const [selectedPlateCompanyId, setSelectedPlateCompanyId] = useState<number>(0)
  const {loadingInformation, lprEvents, setFilter, filter, reloadLprEvents, loadingMore, canLoadMore} = lprGridTableProps
  const {companies} = useCompany()

  const openImageModal = (key: string) => {
    setIsOpen(true)
    setImageKey(key)
  }
  const closeImageModal = () => {
    setIsOpen(false)
  }

  const columns = [
    {
      id: 'camera',
      name: 'Nome da câmera',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'dateTime',
      name: 'Data/hora',
    },
    {
      id: 'plate',
      name: 'Placa',
    },
    {
      id: 'plateImage',
      name: 'Imagem da Placa',
    },
    {
      id: 'brand',
      name: 'Marca',
    },
    {
      id: 'model',
      name: 'Modelo',
    },
    {
      id: 'color',
      name: 'Cor',
    },
    {
      id: 'sentTo',
      name: 'Enviado para',
    },
    {
      id: 'contextMenu',
      name: '',
    },
  ]

  return (
    <Box padding={'20px'} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'white'}}>
      <Box sx={[lprGridTableStyles.gridItem]}>
        <AddPlateToListModal
          isOpenModal={isOpenPlateList}
          closeModal={() => setIsOpenPlateList(false)}
          plateLpr={plateLpr}
          companyId={selectedPlateCompanyId}
        />
        <Table columns={columns} isLoading={loadingInformation} skeletonRowsAmount={20}>
          {lprEventRow(lprEvents, companies).map((row) => (
            <RowHover id={`row-${row.id}`}>
              <TableData>{row.camera}</TableData>
              <TableData>{row.company}</TableData>
              <TableData>{row.dateTime}</TableData>
              <TableData>{row.plate}</TableData>
              <TableData>
                <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                  <Tooltip title='Ver veículo' placement='top'>
                    <Box
                      sx={lprGridTableStyles.boxSeeImage}
                      onClick={(event) => {
                        event.stopPropagation()
                        openImageModal(row.fullImageKey)
                      }}>
                      <LprLoadingPlateScaled croppedImageKey={row.croppedImageKey} />
                    </Box>
                  </Tooltip>
                </Box>
              </TableData>
              <TableData>{row.brand}</TableData>
              <TableData>{row.model}</TableData>
              <TableData>{row.color}</TableData>
              <TableData>{row.sentTo}</TableData>
              <TableData>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <MenuMoreInformations
                    oneLpr={row.id}
                    onClick={(event) => {
                      if (event === 'add-to-list') {
                        setSelectedPlateCompanyId(row.self.camera.companyId)
                        setPlateLpr(row.plate.toString())
                        setIsOpenPlateList(true)
                      }
                      if (event === 'see-vehicle-transity') {
                        setFilter({...filter, plates: [row.plate.toString()]})
                      }
                    }}
                    cameraId={row.self.camera.id}
                  />
                </Box>
              </TableData>
            </RowHover>
          ))}
        </Table>
        {isOpen && (
          <KonvaImageDetectionsFullscreen
            open={Boolean(imageKey)}
            src={`${process.env.REACT_APP_EVENT_IMAGES_BASE_URL}/${imageKey}`}
            detections={[]}
            onClose={closeImageModal}
          />
        )}

        {!loadingInformation && lprEvents.length === 0 ? (
          <Box sx={lprGridTableStyles.noRegisters}>Nenhum registro encontrado</Box>
        ) : (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '6px'}}>
            <Button onClick={() => reloadLprEvents()} isLoading={loadingMore} disabled={!canLoadMore} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default LprGridTable
