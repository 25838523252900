import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, TextField} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {BtnClose} from 'src/components/BtnClose'
import api from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type BrainBoxRegisterDeviceOutProps = {
  isRegisterDeviceOutOpen: boolean
  setIsRegisterDeviceOutOpen: (isOpen: boolean) => void
  brainBoxOut: BrainBoxOutput
  setBrainBoxOut: (brainBoxOut: BrainBoxOutput) => void
  brainBoxOne: BrainBox
  deviceType: 'input' | 'output'
  getOutputDevices: Function
  getInputDevices: Function
  handleClose: () => void
  channels: any[]
}

function BrainBoxRegisterDeviceOut({
  brainBoxOne,
  isRegisterDeviceOutOpen,
  setIsRegisterDeviceOutOpen,
  brainBoxOut,
  setBrainBoxOut,
  getOutputDevices,
  getInputDevices,
  handleClose,
  deviceType,
  channels,
}: BrainBoxRegisterDeviceOutProps) {
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const editBrainBoxOutputdevices = async () => {
    setIsLoading(true)
    try {
      if (deviceType === 'output') {
        await api.brainBoxOutput.updateBrainBoxOutput(brainBoxOut.id, {
          description: brainBoxOut.description,
        })
        getOutputDevices()
      } else {
        await api.brainBoxOutput.updateBrainBoxInput(brainBoxOut.id, {
          description: brainBoxOut.description,
        })
        getInputDevices()
      }
      enqueueSnackbar('Dispositivo de saída atualizado com sucesso', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar dispositivo de saída')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={isRegisterDeviceOutOpen} onClose={() => setIsRegisterDeviceOutOpen(false)}>
      <DialogTitle sx={styles.dialogTitle}>Editar Dispositivo de Saída</DialogTitle>
      <BtnClose onClose={handleClose} />
      <DialogContent>
        <Box sx={{display: 'flex'}}>
          <FormControl fullWidth sx={styles.description}>
            <TextField
              size='small'
              label='Descrição'
              value={brainBoxOut.description}
              onChange={(e) => setBrainBoxOut({...brainBoxOut, description: e.target.value})}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{padding: '25px 8px'}}>
        <Box>
          <Button variant='outlined' sx={styles.cancelButton} onClick={() => setIsRegisterDeviceOutOpen(false)}>
            Cancelar
          </Button>
          <LoadingButton
            sx={styles.confirmButton}
            loading={isLoading}
            variant='contained'
            disabled={brainBoxOut.description === '' || brainBoxOut.channel === null}
            onClick={() => {
              editBrainBoxOutputdevices()
              setIsRegisterDeviceOutOpen(false)
            }}>
            Salvar
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
const styles = createStyleSheet({
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '17px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: 2.5,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  dialogTitle: {paddingTop: 3, color: '#353535'},
  title: {
    fontSize: '22px',
    fontWeight: '600',
    fontFamily: 'Inter',
    height: '70px',
    alignItems: 'center',
    display: 'flex',
  },
  description: {
    width: '118.75em',
  },
})

export default BrainBoxRegisterDeviceOut
