import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {Fab} from '@mui/material'
import Box from '@mui/material/Box'

type DrawerToggleProps = {
  open: boolean
  onClick: () => void
}

function DrawerToggle({open, onClick}: DrawerToggleProps) {
  if (open) {
    return (
      <Box sx={BoxSideBarMain}>
        <Fab onClick={onClick} key='5' sx={FloatButton}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </Fab>
      </Box>
    )
  } else {
    return (
      <Box sx={{width: open ? '60px' : '30px'}}>
        <Fab onClick={onClick} key='4' sx={FloatButtonMirror}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </Fab>
      </Box>
    )
  }
}

export const BoxSideBarMain = {
  width: '200px',
}

export const FloatButton = {
  zIndex: '1500',
  marginLeft: '200px',
  marginTop: '1.875em',
  width: '30px',
  height: '30px',
  backgroundColor: '#ffffff',
  color: '#009eff',
  minHeight: '0px',
}

export const FloatButtonMirror = {
  zIndex: '1500',
  marginLeft: '48px',
  marginTop: '1.990em',
  width: '30px',
  height: '30px',
  backgroundColor: '#ffffff',
  color: '#009eff',
  minHeight: '0px',
}

export const BoxButtonFloat = {
  width: '69px',
}

export default DrawerToggle
