import {Box} from '@viptech/react-components'
import {useEffect, useState} from 'react'
import CloseIcon from '../../images/closeBlueIcon.svg'
import Dropdown from '../dropdown/Dropdown'
import {DropdownItem} from '../dropdown/DropdownProps'
import {CloseButtonFilter} from './AdvancedFiltersBase'

export type FilterSelectedFinal = {
  id: string
  label: string
  itemsOnMenu: DropdownItem[]
  selectedItem?: DropdownItem
}

export type FiltersSelected = {
  label: string
  itemsOnMenu: DropdownItem[]
  selectedItem?: DropdownItem
}

export type AdvancedFiltersProps = {
  filtersSelected: FiltersSelected
  setFiltersSelected: (value: DropdownItem, index: number) => void
  index: number
  deleteFilter: (index: number) => void
  clearEmptyFilter?: () => void
}

export function AdvancedFilters(props: AdvancedFiltersProps) {
  const {filtersSelected, index, setFiltersSelected, deleteFilter, clearEmptyFilter} = props
  const [preventEditing, setPreventEditing] = useState(false)

  const [showLabel, setShowLabel] = useState<string>(filtersSelected.selectedItem?.label ?? '')

  useEffect(() => {
    if (!filtersSelected.selectedItem || !filtersSelected.itemsOnMenu.length) setPreventEditing(true)
  }, [filtersSelected])

  const handleLabel = () => {
    if (showLabel) return `${filtersSelected.label}: ${filtersSelected.selectedItem?.label}`
    return `${filtersSelected.label}`
  }

  return (
    <Box display='flex'>
      <Dropdown
        items={filtersSelected.itemsOnMenu}
        onChangeSelected={(e) => {
          if (filtersSelected.selectedItem?.label) return
          setFiltersSelected(e, index)
          setShowLabel(e.label)
        }}
        height='32px'
        hideIcon
        placeholderColor='#1976d2'
        iconColor='#1976d2'
        bgColor='rgba(0, 158, 255, 0.05)'
        borderColor='#009eff'
        paddingLeftInsideInput='1em'
        open={!!filtersSelected.itemsOnMenu.length}
        preventEditing={preventEditing}
        placeholder={handleLabel()}
        key={index}
        dontReflectValueSelectedOnLabel
        clickOutsideFunction={clearEmptyFilter}
        className='pai'
        icon={
          filtersSelected.selectedItem?.label ? (
            <CloseButtonFilter
              onClick={() => {
                deleteFilter(index)
              }}>
              <img src={CloseIcon} alt='close' width={'10px'} />
            </CloseButtonFilter>
          ) : undefined
        }
      />
    </Box>
  )
}
