import styled from 'styled-components'

export const TermTitle = styled.h1`
  color: #01182b;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const TermsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  gap: 48px;
  padding: 0 300px;
  scroll-behavior: smooth;

  @media (max-width: 1200px) {
    padding: 0 200px;
  }

  @media (max-width: 768px) {
    padding: 0 64px;
    gap: 32px;
  }

  @media (max-width: 480px) {
    padding: 0 24px;
    margin-top: 32px;
    gap: 24px;
  }
`
