import {DispatchDate, DispatchActionType, DispatchNewDate} from '../CalendarProps'

const FIRST_MONTH_OF_YEAR = 0
const LAST_MONTH_OF_YEAR = 11

export const reduceDate = (date: DispatchDate, action: {type: DispatchActionType; newDate?: DispatchNewDate}) => {
  let newYear = date.year
  let newMonth = date.month
  switch (action.type) {
    case 'DECREMENT':
      newYear = date.year
      newMonth = date.month

      if (date.month === FIRST_MONTH_OF_YEAR) {
        newMonth = LAST_MONTH_OF_YEAR
        newYear -= 1
      } else newMonth -= 1

      return {year: newYear, month: newMonth}

    case 'INCREMENT':
      newYear = date.year
      newMonth = date.month

      if (date.month === LAST_MONTH_OF_YEAR) {
        newMonth = FIRST_MONTH_OF_YEAR
        newYear += 1
      } else newMonth += 1

      return {year: newYear, month: newMonth}

    case 'SET':
      if (!action.newDate) return {year: date.year, month: date.month}
      else return {year: action.newDate.year ?? date.year, month: action.newDate.month ?? date.month}

    default:
      return {year: date.year, month: date.month}
  }
}
