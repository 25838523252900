import {List} from 'src/pages/lists/hooks/useLists'
import {Camera} from './CameraEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, NoPaginate, Ord, Paginate} from './_types'

const prefix = process.env.REACT_APP_LICENSE_PLATES_BASE_URL
const prefixEvents = process.env.REACT_APP_EVENTS_BASE_URL

export type Lpr = {
  id: number
  plate: LicensePlate
  createdAt: string
  situation: LPRSituation
  camera: Camera
  sentToSpia: boolean
  sentToHarpia: boolean
  score: number
  imageAwsKey: string
  croppedImageAwsKey: string
}

export type LprInfos = {
  id: number
  ano: string
  municipio: string
  uf: string
  situacao: string
  camera: {
    name: string
  }
}

export type LprFilterType = {
  cameraIds?: number[]
  companyIds?: number[]
  color?: string
  model?: string
  plates?: string[]
  city?: string
  brand?: string
}

export type LicensePlate = {
  id: number
  licensePlate: string
  vehicle: Vehicle
}

export type LPRSituation = {
  id: number
  name: string
}

export type Vehicle = {
  id: number
  plateId: number
  marca: string
  modelo: string
  subModelo: string
  versao: string
  ano: string
  chassi: string
  codigoSituacao: string
  cor: string
  data: string
  logo: string
  municipio: string
  origem: string
  placa_alternativa: string
  situacao: string
  uf: string
}

export type GetLPRParams = {
  batchSize: number
  startId: number | null
  includes?: string[]
  filter?: LprFilterType
  start?: string
  end?: string
  sentToHarpia?: boolean
  sentToSpia?: boolean
  orderBy?: {
    createdAt: Ord
  }
}

export type OrderingTypes = 'ASC' | 'DESC'

type LicensePlateListIncludes = 'action'

export type GetLicensePlateListsParams = {
  includes?: LicensePlateListIncludes[]
  filter?: {
    ids?: number[]
    clientIds?: number[]
    companyIds?: Array<number>
  }
  orderBy?: {createdAt?: OrderingTypes}
  search?: {listName?: string}
  page?: number
  pageSize?: number
}

export type LicensePlateList = {
  id: number
  name: string
  clientId: number
  companyId: number
  actionId: number
  createdAt: Date
}

export type CreateLprListParams = {
  name: string
  companyId: number
  clientId?: number
  actionId: number
}

export type LicensePlateHasList = {
  listId: number
  licensePlate: string
  description: string
}

type PlateDTO = {
  licensePlate: string
  description: string
}

type PlateIncludes = 'actions'

export type GetPlatesParams = {
  includes?: PlateIncludes[]
  filter?: {
    ids?: number[]
    clientIds?: number[]
  }
  orderBy?: {createdAt?: OrderingTypes}
  search?: {listName?: string}
  page?: number
  pageSize?: number
}

export class LprEndpoint {
  async getAllLPR(params: GetLPRParams): Promise<AxiosResult<NoPaginate<Lpr>>> {
    const result: AxiosResult<NoPaginate<Lpr>> = await axios.get(`${prefixEvents}/detections`, {
      params: prepareParams(params),
    })
    return result
  }

  async getManyLists(params: GetLicensePlateListsParams): Promise<AxiosResult<Paginate<LicensePlateList>>> {
    return await axios.get(`${prefix}/license-plate-list`, {
      params: prepareParams(params),
    })
  }

  async deleteManyPlateLists(ids: number[]): Promise<AxiosResult<any>> {
    return axios.delete(`${prefix}/license-plate-list`, {data: {ids}})
  }

  async createLprList(params: CreateLprListParams): Promise<AxiosResult<LicensePlateList>> {
    return await axios.post(`${prefix}/license-plate-list`, params)
  }

  async updateLprList(id: number, body: Partial<List>): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/license-plate-list/${id}`, body)
  }

  async getPlates(id: string, params: GetPlatesParams): Promise<AxiosResult<Paginate<LicensePlateHasList>>> {
    return await axios.get(`${prefix}/license-plate-list/${id}/plates`, {
      params: prepareParams(params),
    })
  }

  async createPlate(id: string, plateDTO: PlateDTO): Promise<AxiosResult<LicensePlateHasList>> {
    return await axios.put(`${prefix}/license-plate-list/${id}/plate`, plateDTO)
  }

  async deleteList(id: number): Promise<AxiosResult<LicensePlateList>> {
    return await axios.delete(`${prefix}/license-plate-list/${id}`)
  }

  async deletePlate(listId: number, plate: string): Promise<AxiosResult<LicensePlateHasList>> {
    return await axios.delete(`${prefix}/license-plate-list/${listId}/plate`, {data: {licensePlate: plate}})
  }
}
