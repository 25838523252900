import {Box} from '@viptech/react-components'
import {useEffect, useState} from 'react'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import AppAddOrOptionsButton from '../../components/AppAddOrOptionsButton'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import {useCompany} from '../../contexts/CompanyContext'
import CameraAddModal from './components/CameraAddModal/index'
import CameraUpdateModal from './components/CameraUpdateModal'
import CamerasAnalyticGridTable from './components/CamerasAnalyticGridTable'
import CamerasAnalyticOptionsMenu from './components/CamerasAnalyticOptionsMenu'
import AnalyticCamerasProvider, {AnalyticCamera, useAnalyticCamerasContext} from './context/AnalyticCamerasContext'

const searchFilterData = [
  {id: '1', label: 'Nome do Dispositivo'},
  {id: '2', label: 'Cliente'},
  {id: '3', label: 'Endereço Físico'},
]

const AnalyticCamerasInner = () => {
  const {
    stepsOpen,
    openModalEdit,
    setSelectedCamera,
    setSelectedCameraId,
    getAnalytics,
    loadNavigationState,
    isLoading,
    hasCreateCameraPermission,
    searchValue,
    setSearchValue,
    selectedSearchFilter,
    setselectedSearchFilter,
    page,
    setCurrentPage,
    debouncedSearchValue,
    reloadClients,
    deleteAnalyticCameras,
  } = useAnalyticCamerasContext()
  const {selectedCompanies} = useCompany()
  const hasDeleteAnalyticCameraPermission = ValidatePermissions('analytic-cameras:delete')
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [selectedCamerasIds, setSelectedCamerasIds] = useState<number[]>([])
  const showOptionsButton = hasDeleteAnalyticCameraPermission && selectedCamerasIds.length > 1
  const menuOpen = Boolean(menuEl)

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  async function openCameraModal(camera: AnalyticCamera) {
    setSelectedCamera(camera)
    setSelectedCameraId(Number(camera.id))
    openModalEdit()
  }

  const onChangeSelect = (event: DropdownItem) => {
    const value = event.label
    setselectedSearchFilter(value)
    setShowSearchSelected(event)
  }

  useEffect(() => {
    if (debouncedSearchValue) {
      setCurrentPage(1)
    }
  }, [selectedSearchFilter, debouncedSearchValue, selectedCompanies])

  useEffect(() => {
    loadNavigationState()
  }, [page, selectedCompanies])

  useEffect(() => {
    getAnalytics()
    reloadClients()
  }, [selectedCompanies])

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={isLoading} onClick={() => loadNavigationState()} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchFilterData}
              setSearchSelected={onChangeSelect}
              searchSelected={showSearchSelected}
              inputValue={searchValue}
              inputSearch={setSearchValue}
              widthOnSelect={'310px'}
            />
          </Box>
          <RenderIfConditionIsMet condition={hasCreateCameraPermission}>
            <AppAddOrOptionsButton
              text='Adicionar uma câmera'
              showOptions={showOptionsButton}
              onClickAdd={() => stepsOpen()}
              onClickOptions={(event) => setMenuEl(event.currentTarget)}
            />
          </RenderIfConditionIsMet>
        </Box>
      </TopBar>
      <CamerasAnalyticGridTable
        openCameraModal={openCameraModal}
        selectedCamerasIds={selectedCamerasIds}
        setSelectedCamerasIds={setSelectedCamerasIds}
        amountSelectedCameras={selectedCamerasIds.length}
      />
      <CamerasAnalyticOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onClickDelete={async () => {
          const res = await deleteAnalyticCameras(selectedCamerasIds)
          if (res) setSelectedCamerasIds([])
          loadNavigationState()
        }}
      />
    </Box>
  )
}

function AnalyticCameras() {
  return (
    <AnalyticCamerasProvider>
      <AnalyticCamerasInner />
      <CameraAddModal />
      <CameraUpdateModal />
    </AnalyticCamerasProvider>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    flexDirection: 'column',
    background: 'white',
    display: 'flex',
    overflow: 'hidden',
  },
})

export default AnalyticCameras
