import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_NOTIFICATIONS_BASE_URL

export type CreateDealDTO = {
  contact: CreateContact
  name: string
}

type CreateContact = {
  email: string
  name: string
  phone: string
  title?: string
}

export class CrmIntegrationEndpoint {
  async createCrmDealWithContact(body: CreateDealDTO): Promise<AxiosResult<void>> {
    return await axios.post(`${prefix}/crm-integration`, body)
  }
}
