const KEY_PREFIX_LOCAL = '@Brain-'

class Storage<T extends string> {
  constructor(private keys: Record<T, null>) {}

  get(key: T) {
    let value = localStorage.getItem(KEY_PREFIX_LOCAL.concat(key))
    return value
  }

  get2(key: T, storage = 'local'): string | null {
    return localStorage.getItem(KEY_PREFIX_LOCAL.concat(key))
  }

  set(key: T, value: string, storage = 'local') {
    localStorage.setItem(KEY_PREFIX_LOCAL.concat(key), value)
  }

  remove(key: T) {
    localStorage.removeItem(KEY_PREFIX_LOCAL.concat(key))
  }

  clearAll() {
    for (const key in this.keys) {
      this.remove(key)
    }
  }

  clearKeys(keys: T[]) {
    keys.forEach((key) => this.remove(key))
  }
}

const storage = new Storage({
  token: null,
  refreshToken: null,
  debugMode: null,
  keepLoggedIn: null,
  sessionRefreshToken: null,
  companiesMemo: null,
})

export default storage
