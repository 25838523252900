import {CircularProgress, Tooltip} from '@mui/material'
import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import {Server} from 'src/services/api'
import {Page} from 'src/types'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Pagination from '../../../../../components/pagination/Pagination'

type ServerGridTableProps = {
  servers: Server[]
  loadingServers: boolean
  totalElements: number
  serversPage: Page
  setServersPage: Function
  openEditModal: (server: Server) => void
  selectedServerIds: number[]
  setSelectedServerIds: (ids: number[]) => void
  serverStatus: {[x: number]: 'OK' | 'ERROR'}
  fetchError: boolean
}

function ServerGridTable(props: ServerGridTableProps) {
  const {
    servers,
    loadingServers,
    totalElements,
    serversPage,
    setServersPage,
    openEditModal,
    selectedServerIds,
    setSelectedServerIds,
    serverStatus,
    fetchError,
  } = props
  const hasUpdateServersPermission = ValidatePermissions('servers:update')
  const hasDeleteServersPermission = ValidatePermissions('servers:delete')
  const serversPageChange = (page: number) => setServersPage((lastPage: Page) => ({...lastPage, page}))
  const serversPageSizeChange = (pageSize: number) => setServersPage((lastPage: Page) => ({...lastPage, pageSize}))
  const {getCompanyName} = useCompany()
  const control = {
    currentPage: serversPage.page,
    setCurrentPage: serversPageChange,
    nextPage: () => serversPageChange(serversPage.page + 1),
    prevPage: () => serversPageChange(serversPage.page - 1),
    changePageSize: (pageSize: number) => serversPageSizeChange(pageSize),
  }

  const columns = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'status',
      name: '',
    },
    {
      id: 'name',
      name: 'Nome da Integração',
    },
    {
      id: 'ip',
      name: 'Host',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'serverType',
      name: 'Servidor',
    },
  ]

  if (!hasDeleteServersPermission) {
    columns.shift()
  }

  function handleOpenEditModal(server: Server) {
    if (!hasUpdateServersPermission) return
    openEditModal(server)
  }
  const onSelectAll = () => {
    if (selectedServerIds.length) return setSelectedServerIds([])
    const allIds = servers.map((row) => row.id)
    setSelectedServerIds(allIds)
  }

  function renderServerStatus(server: Server) {
    const loading = (
      <Box style={{paddingTop: '2px'}}>
        <CircularProgress size={18} />
      </Box>
    )
    if (fetchError) return ''
    if (!serverStatus) return loading
    const statusRequest = serverStatus[server.id]
    if (!statusRequest) return loading
    if (statusRequest === 'OK') {
      return (
        <Tooltip title='Servidor Online' placement='top'>
          <Box>🟢</Box>
        </Tooltip>
      )
    }
    return (
      <Tooltip title='Servidor Offline' placement='top'>
        <Box>🔴</Box>
      </Tooltip>
    )
  }

  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={selectedServerIds.length ? servers.length === selectedServerIds.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )
  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table skeletonRowsAmount={20} columns={columns} isLoading={loadingServers} tableHeaderChildren={customHeader}>
          {servers.map((server) => (
            <RowHover>
              <RenderIfConditionIsMet condition={hasDeleteServersPermission}>
                <TableData width='20px'>
                  <CheckboxTable id={server.id} selectedIds={selectedServerIds} setSelectedIds={setSelectedServerIds} />
                </TableData>
              </RenderIfConditionIsMet>
              <TableData width='20px'>{renderServerStatus(server)}</TableData>
              <TableData>
                <LinkWithAction onClick={() => handleOpenEditModal(server)}>{server.name}</LinkWithAction>
              </TableData>
              <TableData>{server.ip}</TableData>
              <TableData>{getCompanyName(server?.companyId)}</TableData>
              <TableData>{server.serverType?.description}</TableData>
            </RowHover>
          ))}
        </Table>
      </Box>

      {!loadingServers && servers.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={totalElements}
            itemsPerPage={serversPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default ServerGridTable
