import {TermFooterBaseContainer, TermFooterImageContainer, TermFooterSuperLink} from './TermFooterBase'
import brainLogo from 'src/images/fullDarkViptechLogo.svg'

export function TermFooter() {
  return (
    <TermFooterBaseContainer>
      <TermFooterImageContainer src={brainLogo} alt='logo' />
      <TermFooterSuperLink href='https://brain.viptech.com.br'>brain.viptech.com.br</TermFooterSuperLink>
    </TermFooterBaseContainer>
  )
}
