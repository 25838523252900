import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Step, StepLabel, Stepper} from '@mui/material'

import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

import {useSnackbar} from 'notistack'
import {BtnClose} from 'src/components/BtnClose'
import createStyleSheet from '../../../../utilities/createStyleSheet'
import {useAnalyticCamerasContext} from '../../context/AnalyticCamerasContext'
import CameraRegisterHikvisionModal from './CameraAnalyticRegister'
import CameraClientModal from './CameraClient'
import CameraOriginModal from './CameraOrigin'
import SavedCameraModal from './SavedCamera'

function StepContent({step}: {step: number}) {
  switch (step) {
    case 0:
      return <CameraClientModal />
    case 1:
      return <CameraOriginModal />
    case 2:
      return <CameraRegisterHikvisionModal />
    case 3:
      return <SavedCameraModal />
  }

  throw new Error('unreachable')
}

function CameraAddModal() {
  const {
    isStepsOpen,
    step,
    stepsPrevious,
    stepsClose,
    stepsCompleted,
    selectedTypeIntegration,
    analyticCamera,
    selectedModelDevice,
    step3,
    selectedClient,
    setAnalyticCamera,
    isSaving,
    setIsSaving,
    loadNavigationState,
    analytics,
  } = useAnalyticCamerasContext()

  const macRegex = /([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})/

  const {enqueueSnackbar} = useSnackbar()

  function LastNameStep() {
    switch (selectedTypeIntegration) {
      case 'Hikvision':
        return <StepLabel>Informe os dados do Dispositivo</StepLabel>
      default:
        return <StepLabel></StepLabel>
    }
  }

  async function saveCamera() {
    setIsSaving(true)
    try {
      const selected = analytics.filter((analytic) => analytic.selected).map((analytic) => analytic.analytic)
      const response = await api.analyticCamera.createAnalyticCamera({...analyticCamera, clientId: selectedClient?.id, analytics: selected})
      setAnalyticCamera(response.data.data)

      step3()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar camera')
    } finally {
      loadNavigationState()
      setIsSaving(false)
    }
  }

  const changeButton = () => {
    if (step < 3) {
      const selected = analytics.filter((analytic) => analytic.selected).map((analytic) => analytic.analytic)
      const valid = macRegex.test(analyticCamera.macAddress) && analyticCamera.name.length > 0 && selected.length > 0
      return (
        <LoadingButton
          onClick={async () => {
            await saveCamera()
          }}
          variant='contained'
          sx={styles.confirmButton}
          loading={isSaving}
          disabled={!valid}>
          Salvar
        </LoadingButton>
      )
    }
  }

  return (
    <Dialog open={isStepsOpen} onClose={() => stepsClose()} maxWidth='md'>
      <DialogTitle component='div'>
        <BtnClose onClose={stepsClose} />
        <Box sx={styles.stepsContainer}>
          <Stepper sx={styles.steps} activeStep={step}>
            <Step>
              <StepLabel>Selecione um Cliente</StepLabel>
            </Step>
            <Step>{step >= 1 ? <StepLabel>Selecione o Tipo da Integração </StepLabel> : <StepLabel></StepLabel>}</Step>
            <Step>{LastNameStep()}</Step>
            {selectedModelDevice === 2 && <Step>{step >= 3 ? <StepLabel>Selecione as cameras do Dispositivo </StepLabel> : <StepLabel></StepLabel>}</Step>}
          </Stepper>
        </Box>
      </DialogTitle>
      <DialogContent sx={styles.dialogContent}>
        <StepContent step={step} />
      </DialogContent>
      <DialogActions>
        <Box sx={[styles.actions, {opacity: step === 0 ? 0 : 1}]}>
          {step !== 3 && (
            <Button variant='outlined' onClick={stepsPrevious} sx={styles.cancelButton} disabled={step === 0}>
              Voltar
            </Button>
          )}
          {step === 3 && (
            <Button
              variant='contained'
              sx={styles.confirmButton}
              onClick={async () => {
                stepsCompleted()
              }}>
              OK
            </Button>
          )}
          {changeButton()}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    margin: '16px',
  },
  stepsContainer: {
    width: '850px',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1.5em',
  },
  steps: {
    width: '750px',
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '2px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: '20px',
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  dialogContent: {
    height: '70vh',
    overflowY: 'hidden',
  },
})

export default CameraAddModal
