import styled from 'styled-components'

export const TopBarContainer = styled.div`
  display: flex;
  height: auto;
  padding: 20px 20px 10px;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 20px 20px 0px;
  }
`
