import {Box, Button, Typography} from '@viptech/react-components'
import OfferAppBrainImg from 'src/images/offerAppBrain.svg'
import GooglePlayImg from 'src/images/googlePlayButton.svg'
import AppStoreImg from 'src/images/appStoreButton.svg'

export const OfferAppBrain = () => {
  return (
    <Box width='100%' height='80vh' backgroundColor='white' display='flex' direction='column' align='center' justifyContent='center' rowGap='20px'>
      <img src={OfferAppBrainImg} width={'208px'} height={'229px'} alt='baixe o aplicativo brain' />

      <Box display='flex' direction='column' align='center' marginTop='20px'>
        <Typography variant='p' weight={600} color='#344054' size='12px' textAlign='center'>
          Para visualizar os eventos em seu celular, <br /> é necessário baixar nosso aplicativo.
        </Typography>
        <Typography variant='p' weight={600} color='#344054' size='12px' textAlign='center'>
          Clique nos links abaixo para fazer o download.
        </Typography>
      </Box>

      <Box display='flex' padding='0 10px' columnGap='10px' marginTop='10px'>
        <Button
          variant='outlined'
          color='transparent'
          hoverColor='transparent'
          padding='0'
          onClick={() => window.open('https://play.google.com/store/apps/details?id=br.com.viptech.brain&pcampaignid=web_share')}>
          <img src={GooglePlayImg} alt='atalho para google play' />
        </Button>
        <Button
          variant='outlined'
          color='transparent'
          hoverColor='transparent'
          padding='0'
          onClick={() => window.open('https://apps.apple.com/br/app/brain/id6526487007')}>
          <img src={AppStoreImg} alt='atalho para app store' />
        </Button>
      </Box>
    </Box>
  )
}
