import styled from 'styled-components'
import {SingleErrorStyleProps} from './SingleErrorProps'

export const SingleErrorText = styled.span<SingleErrorStyleProps>`
  bottom: 0;
  font-size: 12px;
  position: absolute;

  color: ${({$disabled, theme}) => ($disabled ? `${theme.colors.disabled};` : `${theme.colors.error};`)};
`
