import {Box, FormControl, InputLabel, MenuItem, Select, styled, Tooltip, tooltipClasses, TooltipProps} from '@mui/material'
import {useEffect, useState} from 'react'
import Loading from 'src/components/Loading'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Company} from 'src/services/api'

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
  },
})

type HeaderCompanyManagementSelectProps = {
  isDashboard: boolean
}

function HeaderCompanyManagementSelect({isDashboard}: HeaderCompanyManagementSelectProps) {
  const {selectedCompanyId, setSelectedCompanyId, companies, isLoading, _pageCount, totalPage} = useCompany()
  const [pageCount, setPageCount] = useState<number>(0)
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])

  function Starting() {
    if (isLoading)
      return (
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Loading />
        </Box>
      )
  }

  useEffect(() => {
    setCompaniesScroll(companies)
    setPageCount(_pageCount)
  }, [_pageCount, companies])

  async function loadMoreItems(e: any) {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight
    if (bottom) {
      if (pageCount < totalPage) {
        const response = await api.company.getMany({
          page: pageCount + 1,
        })

        response.data.data.entities.forEach((it) => {
          setCompaniesScroll([...companiesScroll, it])
        })
      }
      setPageCount(pageCount + 1)
    }
  }

  return (
    <CustomWidthTooltip disableFocusListener disableTouchListener title={''} placement={'left'}>
      <FormControl sx={{width: '100%', maxWidth: '30em', minWidth: '20em'}}>
        <InputLabel id='demo-simple-select-label'>{'Dados da Empresa: '}</InputLabel>
        <Select
          label={'Dados da Empresa: '}
          required
          size='small'
          value={selectedCompanyId}
          onChange={(e) => {
            Starting()
            setSelectedCompanyId(Number(e.target.value))
            localStorage.setItem('selectedCompanyState', e.target.value.toString())
          }}
          MenuProps={{
            PaperProps: {
              onScroll: loadMoreItems,
            },
            style: {
              maxHeight: 500,
            },
          }}>
          {companiesScroll.map((company, index) => (
            <MenuItem key={'role-' + index} value={company.id}>
              {`${company.name}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </CustomWidthTooltip>
  )
}

export default HeaderCompanyManagementSelect
