import {Box, Button, Typography} from '@viptech/react-components'
import {useEffect, useState} from 'react'

import {useSnackbar} from 'notistack'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {StepperModalActionsButtonContainer} from '../../../styles'
import {ChosenObject} from '../../StepperModal'
import Card from '../../common/components/Card'
import {detections, skipFourthStep, skipSecondStep, skipThirdStep} from '../Constants'
import {ContainerColumn} from './DetectionTypeBase'

type DetectionTypeStepProps = {
  nextFunction: (index: number, resume: string, advanceTo?: number) => void
  returnFunction: (isOpen: boolean) => void
  setChosen: (choices: Partial<ChosenObject>) => void
  selected?: string | null
}

const DetectionTypeStep = (props: DetectionTypeStepProps) => {
  const {nextFunction, returnFunction, setChosen, selected} = props
  const hasFacialListPermission = ValidatePermissions('face:create')
  const hasLprListPermission = ValidatePermissions('lpr:create')
  const hasCountingPermission = ValidatePermissions('tracking:create')
  const {enqueueSnackbar} = useSnackbar()

  const [selectedCard, setSelectedCard] = useState(selected || '')

  const enumCardLabels = ['Reconhecimento Facial', 'Leitura de Placas', 'Relatório de Contagem']

  const enumCardPermissions = [hasFacialListPermission, hasLprListPermission, hasCountingPermission]

  useEffect(() => {
    setChosen({
      detectionTypeChosen: selectedCard,
    })
  }, [selectedCard])

  const handleNextStep = () => {
    const skipSecond = skipSecondStep.includes(selectedCard)
    const skipThird = skipThirdStep.includes(selectedCard)
    const skipFourth = skipFourthStep.includes(selectedCard)
    if (skipSecond && skipThird) return nextFunction(1, selectedCard, 3)
    if (skipSecond && skipFourth) return nextFunction(1, selectedCard, 2)
    nextFunction(1, selectedCard)
  }

  const handleSelectCard = (cardSelected: string) => {
    const indexOfSelectedCard = enumCardLabels.indexOf(cardSelected)
    if (indexOfSelectedCard === -1) {
      return setSelectedCard(cardSelected)
    }
    if (enumCardPermissions[indexOfSelectedCard]) {
      return setSelectedCard(cardSelected)
    }
    enqueueSnackbar(
      <Box display='flex' direction='column'>
        <div>Você não tem as permissões necessárias para criar este tipo de detecção</div>
        <div>Entre em contato com o administrador para obter as permissões apropriadas</div>
      </Box>,
      {variant: 'error'},
    )
  }

  return (
    <Box display='flex' direction='column' flexWrap='wrap' justify='flex-end' minHeight='390px'>
      <Box padding='20px'>
        <Typography variant='span' size='16px' style={{fontWeight: 500}}>
          O que você deseja detectar?
        </Typography>
        <Typography variant='p' size='12px' color='#8E8E8E'>
          Escolha abaixo o tipo de detecção que melhor atenda às suas necessidades de monitoramento e segurança.
        </Typography>

        <ContainerColumn>
          {detections.map((it) => {
            return <Card label={it} selected={selectedCard === it} onClick={handleSelectCard} initialState={!selectedCard} />
          })}
        </ContainerColumn>
      </Box>
      <StepperModalActionsButtonContainer>
        <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => returnFunction(false)}>
          Cancelar
        </Button>
        <Button fontSize='12px' height='40px' width='150px' disabledTextColor='#8E8E8E' disabled={!selectedCard} onClick={() => handleNextStep()}>
          Próximo
        </Button>
      </StepperModalActionsButtonContainer>
    </Box>
  )
}

export default DetectionTypeStep
