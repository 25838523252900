import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import api from '../../../../services/api'
import {DetectorCountObjects} from '../../../../services/api/endpoints/ReportsEndpoint'
import handleErrorWithSnackbar from '../../../../utilities/handleErrorWithSnackbar'
import CountCard from './CountCard'
import {GridDiv} from './CountObjectsBase'
import {CountObjectsProps} from './CountObjectsProps'

const CountObjects = (props: CountObjectsProps) => {
  const {filterDate, setLengthOfCountObjects} = props
  const [detectorCountObjects, setDetectorCountObjects] = useState<DetectorCountObjects[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const getDetectorCounts = async () => {
    try {
      setIsLoading(true)
      const {data: response} = await api.reports.getDetectorCounts({
        start: filterDate.start,
        end: filterDate.end,
      })

      let number = response.objects.length
      setLengthOfCountObjects(number > 4 ? 12 : 3)
      translateObjectsTypes(response.objects)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao carregar contagem de objetos')
    } finally {
      setIsLoading(false)
    }
  }

  const translateObjectsTypes = (objects: DetectorCountObjects[]) => {
    let dictionary: any = {
      car: 'Carros',
      'cell phone': 'Celulares',
      person: 'Pessoas',
      plate: 'Placas',
      'safety-clothes': 'Roupas de Segurança',
      body: 'Corpos',
      face: 'Faces',
      fork: 'Garfos',
      knife: 'Facas',
      celllabel: 'Celulares',
      scissors: 'Tesouras',
      bottle: 'Garrafas',
      cup: 'Copos',
      spoon: 'Colheres',
      book: 'Livros',
      clock: 'Relógios',
      mouse: 'Mouses',
      motorbike: 'Motos',
      bicycle: 'Bicicletas',
      motorcycle: 'Motocicletas',
      airplane: 'Aviões',
      bus: 'Ônibus',
      train: 'Trens',
      truck: 'Caminhões',
      boat: 'Barcos',
      trafficlabel: 'Semáforos',
      fire: 'Hidrantes',
      'stop sign': 'Sinais de Parada',
      bench: 'Bancos de Sentar',
      bird: 'Pássaros',
      cat: 'Gatos',
      dog: 'Cães',
      horse: 'Cavalos',
      sheep: 'Ovelhas',
      cow: 'Vacas',
      backpack: 'Mochilas',
      umbrella: 'Guardas-Chuva',
      handbag: 'Bolsas',
      suitcase: 'Malas de Viagem',
      'baseball bat': 'Tacos de Beisebol',
      'tennis racket': 'Raquetes de Tênis',
      chair: 'Cadeiras',
      'potted plant': 'Vasos de Planta',
      tv: 'Televisões',
      laptop: 'Computadores Portáteis',
      keyboard: 'Teclados',
      vase: 'Vasos',
      crowd: 'Aglomerações',
      'without-safety-clothes': 'Sem Roupa de Segurança',
      'without-helmet': 'Sem Capacete',
      helmet: 'Capacetes',
      'scene change': 'Mudanças de Cenário',
    }

    const translateObjects = objects.map((it) => {
      return {cameraId: it.cameraId, objectType: dictionary[it.objectType] ?? 'Outros', count: it.count}
    })
    setDetectorCountObjects(translateObjects)
  }

  useEffect(() => {
    getDetectorCounts()
  }, [filterDate])

  const getLengthOfGrid = () => {
    if (detectorCountObjects.length <= 3) return 'repeat(1, 1fr)'
    else if (detectorCountObjects.length === 4) return 'repeat(2, 1fr)'
    return 'repeat(4, 1fr)'
  }

  return (
    <GridDiv length={getLengthOfGrid()}>
      {detectorCountObjects.length ? (
        detectorCountObjects.map((it) => {
          return <CountCard title={`Contagem de ${it.objectType}`} value={it.count} loading={isLoading} />
        })
      ) : (
        <CountCard notFound={true} loading={isLoading} />
      )}
    </GridDiv>
  )
}

export default CountObjects
