import {useSnackbar} from 'notistack'
import {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from 'src/contexts/AuthContext'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {
  AbstractEditClient,
  Client,
  ClientEdit,
  EnumServer,
  MoniClient,
  MoniClientInfo,
  OnePortariaClient,
  OnePortariaInfo,
  RemovingIntegration,
  SigmaClient,
} from 'src/services/api'
import {Page} from 'src/types'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isNumber} from 'src/utilities/regexValidation'
import {useDebounce} from 'usehooks-ts'
import {DropdownItem} from '../../../components/dropdown/DropdownProps'
import {ServerClient} from '../components/ClientsRegisterContent'

type ClientAdd = {
  name: string
  email?: string | null
  sigmaAccount?: string | null
  companyId: number
  moniClientInfo?: MoniClientInfo | null
  layoutId?: number
  onePortariaInfo?: OnePortariaInfo | null
}

type ClientsContextData = {
  // clients
  showOptionsButton: boolean
  clients: Client[]
  clientsPage: Page
  setClientsPage: (page: number) => void
  setClientsPageSize: (pageSize: number) => void
  selectedClientsIds: number[]
  setSelectedClientsIds: (ids: number[]) => void
  clientsTotalCount: number
  loadingClients: boolean
  reloadingClients: boolean
  reloadClients: () => void
  // client select
  clientSearch: string
  setClientSearch: (text: string) => void
  clientSelected: null | Client
  selectClient: (client: Client) => void
  clientAdd: ClientAdd
  setClientAdd: (clientAdd: Partial<ClientAdd>) => void
  addClient: () => void
  updateClient: () => void
  deleteClient: (clientId?: number) => void
  closeClientModal: () => void
  openClientModal: (client: Client) => Promise<void>
  isClientModalOpen: boolean
  openClientAddModal: () => void
  openClientUpdateModal: () => void
  // tabs
  tab: number
  setTab: (newTab: number) => void

  serverClient: ServerClient
  setServerClient: (serverClient: ServerClient) => void

  searchFilterData: DropdownItem[]
  searchFilterSelected: string
  setSearchFilterSelected: (searchFilter: string) => void
}

const initialClientAdd: ClientAdd = {
  name: '',
  email: null,
  companyId: 0,
  layoutId: 0,
  sigmaAccount: undefined,
  moniClientInfo: undefined,
  onePortariaInfo: undefined,
}

const searchFilterData = [
  {id: '1', label: 'Nome do Cliente'},
  {id: '2', label: 'ID'},
]

const ClientsContext = createContext({} as ClientsContextData)

type ClientPrototype = {
  sigmaAccount?: string
  moniClientInfo?: MoniClientInfo
  onePortariaClientInfo?: OnePortariaInfo
} & AbstractEditClient

type NullableClientPrototype = {
  sigmaAccount?: string | null
  moniClientInfo?: MoniClientInfo | null
  onePortariaClientInfo?: OnePortariaInfo | null
} & AbstractEditClient

function ClientsContextProvider({children}: any) {
  const [clients, setClients] = useState<Client[]>([])
  const [clientId, setClientId] = useState<number>(-1)
  const [clientsPage, _setClientsPage] = useState<Page>({
    page: 1,
    pageSize: 20,
  })
  const [clientsTotalCount, setClientsTotalCount] = useState<number>(0)
  const [reloadClientsCount, setReloadClientsCount] = useState(0)

  const [clientSearch, setClientSearch] = useState<string>('')
  const debouncedSearch = useDebounce<string>(clientSearch, 500)
  const [clientAdd, _setClientAdd] = useState<ClientAdd>(initialClientAdd)
  const [clientSelected, setClientSelected] = useState<null | Client>(null)
  const [loadingClients, setLoadingClients] = useState(true)
  const [reloadingClients, setReloadingClients] = useState(false)
  const [isClientModalOpen, setIsClientModalOpen] = useState(false)
  const [selectedClientsIds, setSelectedClientsIds] = useState<number[]>([])
  const [tab, setTab] = useState<number>(0)
  const {enqueueSnackbar} = useSnackbar()
  const showOptionsButton = selectedClientsIds.length > 1
  const location = useLocation()
  const navigate = useNavigate()
  const {refreshToken} = useAuth()
  const {selectedCompanies} = useCompany()
  const [serverClient, setServerClient] = useState<ServerClient>({id: -1})
  const [selectedClientCurrentIntegrationId, setSelectedClientCurrentIntegrationId] = useState<number>(-1)
  const [searchFilterSelected, setSearchFilterSelected] = useState<string>('Nome do Cliente')
  const blockClientsRequests = useRef(false)
  const blockClientRequests = useRef(false)

  function setClientsPage(page: number) {
    _setClientsPage((prev) => {
      return {...prev, page}
    })
  }

  function setClientsPageSize(pageSize: number) {
    _setClientsPage((prev) => {
      return {...prev, pageSize}
    })
  }

  useEffect(() => {
    async function openClientModal(client?: number) {
      if (client && client !== -1) {
        selectClient({id: client} as Client)
      } else {
        setIsClientModalOpen(true)
      }
    }
    if (isClientModalOpen) return
    const params = new URLSearchParams(location.search)

    if (params.has('new')) openClientModal()
    else if (params.has('id')) {
      const id = parseInt(params.get('id')!)
      setClientId(id)
      openClientModal(id)
    }
  }, [location, isClientModalOpen])

  async function openClientModal(client: Client) {
    if (client.id) navigate(`/registers/clients?id=${client.id}`)
    else navigate('/registers/clients')
  }

  useEffect(() => {
    if (clientAdd !== null && isClientModalOpen) {
      if (location.search.length === 0) {
        if (clientId !== -1) navigate(`/registers/clients?id=${clientId}`)
        else navigate('/registers/clients')
      }
    }
  }, [location, navigate])

  useEffect(() => {
    setClientsPage(1)
  }, [debouncedSearch, selectedCompanies])

  useEffect(() => {
    async function loadClients() {
      try {
        if (blockClientsRequests.current) return
        blockClientsRequests.current = true
        setLoadingClients(true)
        const response = await api.client.getMany({
          page: clientsPage.page === 0 ? 1 : clientsPage.page,
          pageSize: clientsPage.pageSize,
          includes: ['cameras', 'moniClientInfo', 'analyticCameras', 'onePortariaClientInfo'],
          search: {
            name: searchFilterSelected === 'Nome do Cliente' ? debouncedSearch : undefined,
          },
          filter: {
            ids: searchFilterSelected === 'ID' && isNumber(debouncedSearch) ? [Number(debouncedSearch)] : undefined,
            companyIds: selectedCompanies,
          },
        })
        setClients(response.data.data.entities)
        setClientsTotalCount(response.data.data.totalElements)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar clientes cadastrados')
      } finally {
        setLoadingClients(false)
        setReloadingClients(false)
        blockClientsRequests.current = false
      }
    }

    loadClients()
  }, [clientsPage, enqueueSnackbar, reloadClientsCount])

  const setClientAdd = useCallback((clientAdd: Partial<ClientAdd>) => {
    _setClientAdd((prev) => {
      return {...prev, ...clientAdd}
    })
  }, [])

  async function selectClient(client: Client) {
    try {
      if (blockClientRequests.current) return
      blockClientRequests.current = true
      let clientInfo = client
      if (clients.map((it) => it.id).includes(client.id) === false) {
        clientInfo = await api.client
          .getMany({
            filter: {
              ids: [client.id],
            },
            includes: ['moniClientInfo', 'onePortariaClientInfo', 'company'],
          })
          .then((response) => response.data.data.entities[0])
      } else clientInfo = clients.filter((it) => it.id === client.id)[0]
      setClientAdd({
        name: clientInfo.name,
        email: clientInfo.email,
        sigmaAccount: clientInfo.sigmaAccount || undefined,
        companyId: clientInfo.companyId,
        moniClientInfo: clientInfo.moniClientInfo || undefined,
        layoutId: clientInfo.defaultLayoutId,
        onePortariaInfo: clientInfo.onePortariaClientInfo || undefined,
      })
      setClientSelected(clientInfo)
      const integrationId = clientInfo.sigmaAccount
        ? EnumServer.SIGMA_CLOUD
        : clientInfo.moniClientInfo
          ? EnumServer.MONI
          : clientInfo.onePortariaClientInfo
            ? EnumServer.ONE_PORTARIA
            : -1
      setSelectedClientCurrentIntegrationId(integrationId)
      setServerClient((prev) => ({
        ...prev,
        id: integrationId,
      }))

      openClientUpdateModal()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar cliente')
    } finally {
      blockClientRequests.current = false
    }
  }

  async function addClient() {
    try {
      await api.client.createOne({
        clientName: clientAdd.name,
        email: clientAdd.email,
        sigmaAccount: clientAdd.sigmaAccount,
        moniClientInfo: clientAdd.moniClientInfo,
        companyId: clientAdd.companyId,
        onePortariaClientInfo: clientAdd.onePortariaInfo,
      })
      enqueueSnackbar('Cliente adicionado com sucesso', {
        variant: 'success',
      })
      await refreshToken()
      closeClientModal()
      reloadClients()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar cliente')
    }
  }

  function isSigmaClient(object: any): object is SigmaClient {
    return 'sigmaAccount' in object && object.sigmaAccount !== undefined
  }

  function isMoniClient(object: any): object is MoniClient {
    return 'moniClientInfo' in object && object.moniClientInfo !== undefined
  }

  function isOnePortariaClient(object: any): object is OnePortariaClient {
    return 'onePortariaClientInfo' in object && object.onePortariaClientInfo !== undefined
  }

  function doenstHaveClientInfo(object: any): object is AbstractEditClient {
    return (
      ('sigmaAccount' in object || object.sigmaAccount === undefined) &&
      ('moniClientInfo' in object || object.moniClientInfo === undefined) &&
      ('onePortariaClientInfo' in object || object.onePortariaClientInfo === undefined)
    )
  }

  function ensureIsClientValid(client: ClientPrototype): ClientEdit | null {
    if (isSigmaClient(client)) return client
    if (isMoniClient(client)) return client
    if (isOnePortariaClient(client)) return client
    return null
  }

  function injectIntegrationRemoval(client: NullableClientPrototype): ClientEdit {
    if (selectedClientCurrentIntegrationId === EnumServer.SIGMA_CLOUD) {
      client.sigmaAccount = null
    } else if (selectedClientCurrentIntegrationId === EnumServer.MONI) {
      client.moniClientInfo = null
    } else if (selectedClientCurrentIntegrationId === EnumServer.ONE_PORTARIA) {
      client.onePortariaClientInfo = null
    }
    return client as RemovingIntegration
  }

  async function updateClient() {
    if (clientSelected === null) throw new Error('client id is null')
    try {
      if (clientAdd.onePortariaInfo) delete clientAdd.onePortariaInfo.companyCode
      const clientEdit = {
        clientName: clientAdd.name,
        email: clientAdd.email,
        sigmaAccount: clientAdd.sigmaAccount || undefined,
        moniClientInfo: clientAdd.moniClientInfo || undefined,
        defaultLayoutId: clientAdd.layoutId ? clientAdd.layoutId : undefined,
        onePortariaClientInfo: clientAdd.onePortariaInfo || undefined,
      }
      let validClient = ensureIsClientValid(clientEdit)
      if (!validClient) {
        if (doenstHaveClientInfo(clientEdit) && serverClient.id !== -1) {
          throw new Error('Cliente necessita saída válida')
        }
        validClient = injectIntegrationRemoval(clientEdit)
      }
      await api.client.updateOne(clientSelected.id, validClient)
      enqueueSnackbar('Cliente atualizado com sucesso', {
        variant: 'success',
      })
      closeClientModal()
      reloadClients()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar cliente')
    }
  }

  async function deleteClient(clientId?: number) {
    const deleteId = clientId ? [clientId] : selectedClientsIds || []
    if (!deleteId.length) throw new Error('Erro ao deletar cliente')
    try {
      await api.client.deleteMany(deleteId)
      enqueueSnackbar('Cliente removido com sucesso', {
        variant: 'success',
      })
      closeClientModal()
      setSelectedClientsIds([])
      reloadClients()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover cliente')
    }
  }

  function openClientAddModal() {
    setIsClientModalOpen(true)
  }

  function openClientUpdateModal() {
    setIsClientModalOpen(true)
  }

  function closeClientModal() {
    setIsClientModalOpen(false)
    setClientAdd(initialClientAdd)
    setClientSelected(null)
    setServerClient({id: -1})
    setSelectedClientCurrentIntegrationId(-1)
    navigate(`/registers/clients`)
  }

  const reloadClients = () => {
    setReloadingClients(true)
    setReloadClientsCount((x) => x + 1)
  }

  return (
    <ClientsContext.Provider
      value={{
        showOptionsButton,
        clients,
        clientsPage,
        openClientModal,
        setClientsPage,
        setClientsPageSize,
        clientsTotalCount,
        clientSelected,
        selectClient,
        clientAdd,
        setClientAdd,
        loadingClients,
        reloadingClients,
        isClientModalOpen,
        openClientAddModal,
        openClientUpdateModal,
        closeClientModal,
        setSelectedClientsIds,
        selectedClientsIds,
        addClient,
        updateClient,
        deleteClient,
        reloadClients,
        clientSearch,
        setClientSearch,
        tab,
        setTab,
        serverClient,
        setServerClient,
        searchFilterData,
        searchFilterSelected,
        setSearchFilterSelected,
      }}>
      {children}
    </ClientsContext.Provider>
  )
}

function useClientsContext() {
  return useContext(ClientsContext)
}

export {useClientsContext}
export default ClientsContextProvider
