import React from 'react'

export default function SortIcon({ color = '#212121', transform }: { color?: string; transform?: string }) {
  return (
    <svg
      style={{
        transform: transform,
        transition: 'all 0.1s ease-in-out',
      }}
      width="12"
      height="12"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.21848 0.43868C7.93723 0.157779 7.55598 0 7.15848 0C6.76098 0 6.37973 0.157779 6.09848 0.43868L0.439481 6.09468C0.158086 6.37607 -4.19311e-09 6.75773 0 7.15568C4.19311e-09 7.55363 0.158086 7.93529 0.439481 8.21668C0.720875 8.49808 1.10253 8.65616 1.50048 8.65616C1.89843 8.65616 2.28009 8.49808 2.56148 8.21668L5.65848 5.12068V10.5V12.7237C5.65848 13.1215 5.81652 13.503 6.09782 13.7844C6.37912 14.0657 6.76066 14.2237 7.15848 14.2237C7.55631 14.2237 7.93784 14.0657 8.21914 13.7844C8.50045 13.503 8.65848 13.1215 8.65848 12.7237V5.12068L11.7545 8.21668C11.8938 8.35601 12.0592 8.46654 12.2413 8.54194C12.4233 8.61735 12.6184 8.65616 12.8155 8.65616C13.0125 8.65616 13.2076 8.61735 13.3897 8.54194C13.5717 8.46654 13.7371 8.35601 13.8765 8.21668C14.0158 8.07735 14.1263 7.91194 14.2017 7.72989C14.2771 7.54784 14.316 7.35273 14.316 7.15568C14.316 6.95863 14.2771 6.76352 14.2017 6.58147C14.1263 6.39942 14.0158 6.23401 13.8765 6.09468L8.21848 0.43868Z"
        fill={color}
      />
    </svg>
  )
}
