import {Box, Chip} from '@mui/material'
import {cameraHistoricStatus} from './CameraFilterFunctions'
import {CameraFilterStatusProps} from './CameraFilterProps'

export function CameraFilterStatus(cameraFilterStatusProps: CameraFilterStatusProps) {
  const {cameraStatusFilter, setCameraStatusFilter} = cameraFilterStatusProps

  return (
    <Box sx={{paddingBottom: 1.5, paddingTop: 0.5}}>
      {cameraHistoricStatus.map((status, index) => {
        const isChecked = cameraStatusFilter.includes(status)

        return (
          <Chip
            key={`day-checkbox-${index}`}
            sx={{marginLeft: index === 0 ? 0 : 1, border: '1px solid #1976d2'}}
            label={status}
            color='primary'
            variant={isChecked ? 'filled' : 'outlined'}
            onClick={() => {
              if (isChecked) {
                setCameraStatusFilter(cameraStatusFilter.filter((x) => x !== status))
              } else {
                setCameraStatusFilter([...cameraStatusFilter, status])
              }
            }}
          />
        )
      })}
    </Box>
  )
}
