import {Grid} from '@mui/material'
import {useState} from 'react'
import {useHeader} from 'src/layouts/main-layout'
import {useCompany} from '../../contexts/CompanyContext'
import DashboardAggregationCamChart from './components/DashboardAggregation'
import DashboardAggregationCamChartClient from './components/DashboardAggregationClient'
import DashboardCameras from './components/DashboardCameras'
import DashboardEventsStatus from './components/DashboardEventsStatus'
import DashboardTimeEvents from './components/DashboardTimeEvents'
import CountObjects from './components/counter/CountObjects'

function Dashboard() {
  const {filterDate} = useHeader()
  const {checkCountObjectPermissionOnCompany} = useCompany()
  const hasDetectorCounterRead = checkCountObjectPermissionOnCompany()
  const [lengthOfCountObjects, setLengthOfCountObjects] = useState(3)

  return (
    <Grid container spacing={'1.35em'}>
      <Grid item container spacing={'1.35em'}>
        {hasDetectorCounterRead && (
          <Grid item xs={lengthOfCountObjects}>
            <CountObjects filterDate={filterDate} setLengthOfCountObjects={setLengthOfCountObjects} />
          </Grid>
        )}
        <Grid item xs={lengthOfCountObjects === 3 && hasDetectorCounterRead ? lengthOfCountObjects : 6}>
          <DashboardTimeEvents filterDate={filterDate} />
        </Grid>
        <Grid item md={6}>
          <DashboardCameras />
        </Grid>
      </Grid>

      <Grid item container spacing={'1.35em'}>
        <Grid item xs={6}>
          <DashboardAggregationCamChartClient filter={'Clientes'} filterDate={filterDate} />
        </Grid>
        <Grid item xs={6}>
          <DashboardAggregationCamChart filter={'Dispositivos'} filterDate={filterDate} />
        </Grid>
      </Grid>

      <Grid item container spacing={'1.35em'}>
        {/* <Grid item xs={12}>
          {hasMonitoringRead && <DashboardTimeEvents filterDate={filterDate} />}
        </Grid> */}
        <Grid item xs={12}>
          <DashboardEventsStatus filterDate={filterDate} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard
