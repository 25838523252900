import styled from 'styled-components'

export const ReloadButtonCustom = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 8px;
  cursor: pointer;
  color: white;

  border: 1px solid #009eff;
  background: transparent;

  text-transform: initial;

  font-family: 'Inter; sans-serif';
  font-size: 0.875em;
  font-weight: 500;
  font-style: normal;

  background-color: white;

  transition: 0.3s;
  :hover {
    background-color: rgba(0, 158, 255, 0.05);
  }

  @media (max-width: 767px) {
    display: none;
  }
`
