import moment from 'moment'
import {Lpr} from 'src/services/api/endpoints/LprEndpoint'
import {lprGridTableStyles} from './LprGridTableBase'
import {Company} from 'src/services/api'

export const showColorSituation: {[key: string]: any} = {
  REGISTRADO: lprGridTableStyles.boxSituationRegistered,
  PROCESSANDO: lprGridTableStyles.boxSituationProcessing,
  'FURTO/ROUBO': lprGridTableStyles.boxSituationTheft,
  'NÃO REGISTRADO': lprGridTableStyles.boxSituationNotRegistered,
}

export const renderValueColumn = (optionHarpia: boolean, optionSpia: boolean) => {
  if (optionHarpia && optionSpia) {
    return 'Harpia, Spia'
  } else if (optionHarpia) {
    return 'Harpia'
  } else if (optionSpia) {
    return 'Spia'
  } else {
    return '-'
  }
}

export function lprEventRow(lpr: Lpr[], companies: Company[]) {
  return lpr.map((it) => {
    const createdDate = moment(it.createdAt).format('DD/MM/YYYY HH:mm:ss')
    const companyId = it.camera.companyId
    const companyName = companies.find((company) => company.id === companyId)?.name || ''
    return {
      id: it.id,
      camera: it.camera.name,
      company: companyName,
      dateTime: createdDate.toLocaleString(),
      plate: it.plate.licensePlate,
      fullImageKey: it.imageAwsKey,
      croppedImageKey: it.croppedImageAwsKey,
      situation: it.situation.name,
      brand: it.plate.vehicle?.marca ? it.plate.vehicle?.marca : '-',
      model: it.plate.vehicle?.modelo ? it.plate.vehicle?.modelo : '-',
      color: it.plate.vehicle?.cor ? it.plate.vehicle?.cor : '-',
      sentTo: renderValueColumn(it.sentToHarpia, it.sentToSpia),
      self: it,
      show: it.id,
    }
  })
}
