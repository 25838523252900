import {Box, Tab, Tabs} from '@mui/material'
import {CameraConfigHeaderProps} from './CameraTabsHeaderProps'
import {useAuth} from 'src/contexts/AuthContext'

export function CameraTabsHeader(cameraTabsHeaderProps: CameraConfigHeaderProps) {
  const {tab, setTab, hasLpr, isEditing} = cameraTabsHeaderProps
  const {user} = useAuth()
  const userHasEditConfigPermission = user.permissions.includes('camera-parameter-config:update')

  function shouldDisplayLPRTab() {
    if (!hasLpr) {
      return {sx: {display: 'none'}}
    }
    return {}
  }

  return (
    <Box sx={styles.tabHeader}>
      <Tabs value={tab} onChange={(_, newTab) => setTab(newTab)} scrollButtons='auto' aria-label='scrollable auto tabs example'>
        <Tab label='Dados Gerais' style={styles.tab} disabled={isEditing} />
        <Tab label='Conexão' style={styles.tab} disabled={isEditing} />
        <Tab label='Barreiras' style={styles.tab} disabled={isEditing} />
        <Tab label='Detecção de Objetos' style={styles.tab} disabled={isEditing} />
        <Tab label='Arme/Desarme' style={styles.tab} disabled={isEditing} />
        <Tab label='Histórico' style={styles.tab} disabled={isEditing} />
        <Tab label='Integrações LPR' style={styles.tab} hidden={!hasLpr} disabled={isEditing} {...shouldDisplayLPRTab()} />
        <Tab label='Configurações Avançadas' style={styles.tab} disabled={isEditing} {...(!userHasEditConfigPermission ? {sx: {display: 'none'}} : {})} />
      </Tabs>
    </Box>
  )
}

const styles = {
  tabHeader: {
    justifyContent: 'center',
    display: 'flex',
  },
  tab: {
    fontFamily: 'Inter',
    fontSize: '13px',
  },
}
