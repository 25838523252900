import styled from 'styled-components'

export const RegistrationContainer = styled.div`
  display: flex;
  gap: 250px;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;

  @media (max-width: 1200px) {
    gap: 150px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

export const Image = styled.img`
  max-width: 500px;
  max-height: 500px;

  @media (max-width: 1200px) {
    max-width: 320px;
  }

  @media (max-width: 1024px) {
    max-width: 320px;
  }

  @media (max-width: 948px) {
    display: none;
  }
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 24px;
  max-width: 540px;

  @media (max-width: 1024px) {
    max-width: 300px;
  }

  @media (max-width: 767px) {
    margin-top: 70px;
    max-width: 540px;
  }
`

export const Title = styled.span`
  font-size: 28px;
  color: #344054;
  font-weight: 700;
  width: 100%;
  align-self: flex-start;

  @media (max-width: 1024px) {
    font-size: 24px;
  }

  @media (max-width: 767px) {
    text-align: center;
    font-size: 18px;
  }
`

export const Subtitle = styled.p<{bold?: boolean}>`
  font-size: 16px;
  color: #344054;
  font-weight: ${({bold}) => (bold ? 600 : 400)};
  align-self: flex-start;
  width: 100%;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    text-align: center;
    font-size: 14px;
  }
`

export const MessageLabel = styled.span`
  font-size: 12px;
  color: #344054;
  font-weight: 400;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`

export const MessageLabelLink = styled.a`
  font-size: 12px;
  color: #1570ef;
  font-weight: 500;
  text-align: center;
  text-decoration: underline;

  @media (max-width: 1024px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
`
