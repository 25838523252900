import {Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import createStyleSheet from 'src/utilities/createStyleSheet'
import actionsObject from 'src/images/actionsObject.svg'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import ForceAction from 'src/images/forceAction.svg'
import StopAction from 'src/images/stopAction.svg'
import EditIcon from 'src/images/edit-pen-black.svg'
import TriggerAction from 'src/images/alarms-black.svg'

type TooltipEvent = 'edit' | 'trigger-shoot-output' | 'eject-shoot-output' | 'trigger-output-device'

type LprInfos = {
  oneObject: number
  deviceType: 'input' | 'output'
  onClick?: (event: TooltipEvent, oneObject: number) => void
}

const MenuMoreInformationsBrainBoxInOut = ({oneObject, onClick, deviceType}: LprInfos) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Tooltip title='Mais Informações' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        {deviceType === 'input' && (
          <MenuItem
            sx={styles.menuInfos}
            onClick={() => {
              setAnchorEl(null)
              onClick?.('trigger-output-device', oneObject)
            }}>
            <Icon src={TriggerAction} width='14px' margin='0px 5px 0px 0px' />
            Acionar saídas
          </MenuItem>
        )}
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('edit', oneObject)
          }}>
          <Icon src={EditIcon} width='14px' margin='0px 5px 0px 0px' />
          Editar nome
        </MenuItem>
        {deviceType === 'output' && (
          <>
            <MenuItem
              sx={styles.menuInfos}
              onClick={() => {
                setAnchorEl(null)
                onClick?.('trigger-shoot-output', oneObject)
              }}>
              <Icon src={ForceAction} width='14px' margin='0px 5px 0px 0px' />
              Forçar Disparo
            </MenuItem>
            <MenuItem
              sx={styles.menuInfos}
              onClick={() => {
                setAnchorEl(null)
                onClick?.('eject-shoot-output', oneObject)
              }}>
              <Icon src={StopAction} width='14px' margin='0px 5px 0px 0px' />
              Cessar Disparo
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  )
}

export default MenuMoreInformationsBrainBoxInOut

const styles = createStyleSheet({
  menuInfos: {
    padding: 1,
    fontSize: '12px',
  },
})
