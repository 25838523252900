import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {useAuth} from 'src/contexts/AuthContext'
import {InitialRoutesWithPermissions} from '../../../routes/RoutesPageFunctions'
import {resolveSidebarTitle} from '../sidebar/computeSidebarItems'

type AppData = {
  sidebarTitle: string
  setSidebarTitle: (title: string) => void
  updateNotifications: boolean
  updateExpirationDate: boolean
  setUpdateNotifications: React.Dispatch<React.SetStateAction<boolean>>
  setUpdateExpirationDate: React.Dispatch<React.SetStateAction<boolean>>
}

export const AppContext = createContext({} as AppData)

export function AppProvider({children}: React.PropsWithChildren<any>) {
  const {user} = useAuth()
  const [sidebarTitle, _setSidebarTitle] = useState(InitialRoutesWithPermissions(user).name)
  const location = useLocation()
  const [updateNotifications, setUpdateNotifications] = useState(false)
  const [updateExpirationDate, setUpdateExpirationDate] = useState(false)

  useEffect(() => {
    if (!sidebarTitle.includes(resolveSidebarTitle(location, user))) {
      _setSidebarTitle(resolveSidebarTitle(location, user))
    }
  }, [location, user])

  const setSidebarTitle = useCallback((title: string) => {
    _setSidebarTitle(title)
  }, [])

  return (
    <AppContext.Provider value={{sidebarTitle, setSidebarTitle, setUpdateNotifications, updateNotifications, updateExpirationDate, setUpdateExpirationDate}}>
      {children}
    </AppContext.Provider>
  )
}

export function useApp() {
  return useContext(AppContext)
}
