import {Button} from '@viptech/react-components'
import {ReactElement} from 'react'
import exclamationIcon from 'src/images/exclamationIcon.svg'
import whiteCloseIcon from 'src/images/whiteCloseIcon.svg'
import ModalCustom from '../modal/ModalCustom'
import {HeaderTitle, IconButton, ModalButtonsContainer, ModalContent, ModalHeader} from './ConfirmationModalBase'

export type AppDialogProps = {
  isOpen: boolean
  onClose: () => void
  title: string
  content: string | ReactElement
  buttonsContent: Array<ButtonsContentType>
}

export type ButtonsContentType = {
  label: string
  onClick: () => void
  variant: 'outlined' | 'contained'
  dangerAction?: boolean
  color?: string
  hoverColor?: string
}

export function ConfirmationModal(props: AppDialogProps) {
  const {isOpen, title, content, onClose, buttonsContent} = props
  return (
    <ModalCustom isOpen={isOpen} closeModal={onClose} backgroundColor='white' padding='0px'>
      <ModalHeader>
        <HeaderTitle>
          <img src={exclamationIcon} alt='Icone de exclamação' />
          <span>{title}</span>
        </HeaderTitle>

        <IconButton onClick={onClose}>
          <img src={whiteCloseIcon} alt='close' />
        </IconButton>
      </ModalHeader>

      <ModalContent>{content}</ModalContent>

      <ModalButtonsContainer>
        {buttonsContent.map((button, index) => {
          return (
            <div style={{minWidth: '9em'}}>
              <Button
                key={index}
                onClick={button.onClick}
                variant={button.variant}
                color={button.dangerAction === true ? 'red' : button.color}
                width='100%'
                height='37px'
                borderRadius='10px'
                hoverColor={button.dangerAction === true ? 'darkred' : button.hoverColor}>
                {button.label}
              </Button>
            </div>
          )
        })}
      </ModalButtonsContainer>
    </ModalCustom>
  )
}
