import DownloadIcon from '@mui/icons-material/Download'
import {IconButton, Tooltip} from '@mui/material'
import {useState} from 'react'
import {KonvaImageDetectionsFullscreen} from 'src/common/components/konva-image-detections-fullscreen/KonvaImageDetectionsFullscreen'
import {KonvaImageDetections} from 'src/common/components/konva-image-detections/KonvaImageDetections'
import Loading from 'src/components/Loading'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {useAlarmsContext} from '../contexts/AlarmsContext'
import {Box} from '@viptech/react-components'

function AlarmsImagesModal() {
  const {loadingAlarms, snapshotsEvents} = useAlarmsContext()
  const [imageSelected, setImageSelected] = useState('')
  const [detections, setDetections] = useState<any[]>([])

  if (loadingAlarms) {
    return (
      <Box style={styles.loadingContainer}>
        <Loading />
      </Box>
    )
  }

  if (snapshotsEvents.length === 0) {
    return <Box style={{textAlign: 'center'}}>Nenhum snapshot deste alerta foi encontrado!</Box>
  }

  return (
    <Box style={styles.container}>
      {snapshotsEvents
        .sort((a, b) => (a.additionDate > b.additionDate ? 1 : -1))
        .map((image, index) => {
          const src = `${process.env.REACT_APP_EVENT_IMAGES_BASE_URL}/${image.awsKey}`
          const detections = image.demarcatedAreas.map((it) => ({
            x: it.startX,
            y: it.startY,
            width: it.endX,
            height: it.endY,
            data: it,
          }))

          return (
            <Box display='flex' direction='column' align='center' justifyContent='center' rowGap='8px'>
              <Box
                key={`alarm2-${index}`}
                onClick={() => {
                  setImageSelected(src)
                  setDetections(detections)
                }}>
                <KonvaImageDetections detections={detections} src={src} height={245} />
              </Box>
              {src && (
                <Tooltip title='Fazer download da imagem' placement='top'>
                  <IconButton onClick={() => window.open(`${process.env.REACT_APP_EVENT_IMAGES_BASE_URL}/${image.awsKey}`, '_blank', 'noopener,noreferrer')}>
                    <DownloadIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        })}
      {imageSelected && (
        <KonvaImageDetectionsFullscreen open={Boolean(imageSelected)} src={imageSelected} detections={detections} onClose={() => setImageSelected('')} />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default AlarmsImagesModal
