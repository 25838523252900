import LoadingButton from '@mui/lab/LoadingButton'
import {Avatar, Box, Button, CardActions, CircularProgress, Grid, TextField, Tooltip, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import editPen from 'src/images/edit-pen.svg'
import restart from 'src/images/restartIcon.svg'
import {GreenSwitch} from 'src/pages/camera-config/components/camera-general-data/CameraGeneralDataBase'
import {DivInfo, TitleConfigCamera, TitleModalConfig} from 'src/pages/camera-config/components/camera-general-data/styles'
import api from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type BrainBoxConfigGeneralDataProps = {
  brainBoxOne: BrainBox
  setBrainBoxOne: (brainBox: BrainBox) => void
  setIsEdit: (isEdit: boolean) => void
  isEdit: boolean
  updateSituation: (diffBrainBoxOne: BrainBox) => void
}

function BrainBoxConfigGeneralData({brainBoxOne, setBrainBoxOne, setIsEdit, isEdit, updateSituation}: BrainBoxConfigGeneralDataProps) {
  const {enqueueSnackbar} = useSnackbar()
  const [diffBrainBoxOne, setDiffBrainBoxOne] = useState<BrainBox>(brainBoxOne)
  const [isReboot, setIsReboot] = useState<boolean>(false)
  const [width, setWidth] = useState(window.innerWidth)
  const hasPermissionReadClient = ValidatePermissions('clients:read')

  const getWidth = (e: any) => {
    const newWidth = e.target.innerWidth
    setWidth(newWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', getWidth)
    return () => window.removeEventListener('resize', getWidth)
  }, [])

  useEffect(() => {
    if (brainBoxOne.id === -1) return
    setDiffBrainBoxOne(brainBoxOne)
  }, [brainBoxOne])

  async function updateArmed(id: number, isArmed: boolean) {
    try {
      await api.brainBox.updateBrainBoxStatus(id, {isArmed})
      setBrainBoxOne({...brainBoxOne, isArmed: isArmed})
      enqueueSnackbar('Brain Box atualizada com sucesso', {
        variant: 'success',
        preventDuplicate: true,
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar Brain Box')
    }
  }

  if (brainBoxOne.id === -1) {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress />
      </Box>
    )
  }

  async function rebootBrainBox(id: number) {
    setIsReboot(true)
    try {
      await api.brainBoxControl.reboot(id)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao tentar reiniciar Brain Box')
    } finally {
      setIsReboot(false)
    }
  }

  return (
    <>
      <Grid container xs={12} sx={styles.gridContainer}>
        <Grid item xs={8.9}>
          <Box sx={styles.gridContainerOne}>
            <Grid item xs={12}>
              <TitleModalConfig>Dados do Dispositivo</TitleModalConfig>
            </Grid>
            <Grid item xs={6}>
              <Box sx={styles.itemsBoxOne}>
                <DivInfo>
                  <TitleConfigCamera>ID</TitleConfigCamera>
                  <Typography sx={styles.infosNotEdit}>{brainBoxOne.id}</Typography>
                </DivInfo>
                <TitleConfigCamera style={{paddingTop: '10px'}}>Cliente</TitleConfigCamera>
                {hasPermissionReadClient ? (
                  <Tooltip title='Ver cliente' placement='top-start'>
                    <Link to={`/registers/clients?id=${brainBoxOne.client!.id}`}>{brainBoxOne.client!.name}</Link>
                  </Tooltip>
                ) : (
                  <Box>{brainBoxOne.client!.name}</Box>
                )}
              </Box>
            </Grid>
            <Box sx={styles.gridInfo}>
              <Grid item xs={6}>
                <Typography sx={styles.titleSubmenusBrainBox}>Nome Brain Box</Typography>
                {isEdit ? (
                  <Box>
                    <TextField
                      sx={{width: '83.3%'}}
                      size={'small'}
                      type='text'
                      value={diffBrainBoxOne.name}
                      onChange={(e) => {
                        setDiffBrainBoxOne({...diffBrainBoxOne, name: e.target.value})
                      }}
                    />
                  </Box>
                ) : (
                  <Typography sx={styles.infosNotEdit}>{brainBoxOne.name}</Typography>
                )}
              </Grid>
              <Grid item xs={3} sx={styles.gridButtonsEdit}>
                {isEdit ? (
                  <CardActions sx={styles.cardActions}>
                    <Button
                      variant='outlined'
                      onClick={() => {
                        setIsEdit(false)
                        setDiffBrainBoxOne(brainBoxOne)
                      }}
                      sx={[styles.button, styles.cancelButton]}>
                      Cancelar
                    </Button>
                    <LoadingButton
                      size='small'
                      onClick={() => {
                        updateSituation(diffBrainBoxOne)
                        setIsEdit(false)
                        setDiffBrainBoxOne(brainBoxOne)
                      }}
                      loading={false}
                      variant='contained'
                      sx={[styles.button, styles.addButton]}>
                      Salvar
                    </LoadingButton>
                  </CardActions>
                ) : (
                  <Button
                    variant='contained'
                    onClick={() => setIsEdit(true)}
                    startIcon={<Avatar src={editPen} sx={styles.editIcon} />}
                    sx={[styles.button, styles.editButton]}>
                    Editar
                  </Button>
                )}
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid xs={3} sx={styles.gridContainerTwo}>
          <Box sx={width < 1368 ? {display: 'flex', flexDirection: 'column'} : styles.boxInfosGridTwo}>
            <Box sx={[styles.gridItem3]}>
              <Typography sx={styles.titleSubmenusBrainBox}>Status</Typography>
              <Box sx={[styles.brainBoxStatus, {backgroundColor: `${brainBoxOne.isArmed ? '#20bc30' : '#f44336'}`}]}>
                {brainBoxOne.isArmed ? 'Armada' : 'Desarmada'}
                <GreenSwitch size='small' onChange={() => updateArmed(Number(brainBoxOne.id), !brainBoxOne.isArmed)} checked={brainBoxOne.isArmed} />
              </Box>
            </Box>
            <Box sx={[styles.gridItem3]}>
              <Typography sx={styles.titleSubmenusBrainBox}>Situação</Typography>
              <Box sx={[styles.brainBoxSituation, {backgroundColor: `${brainBoxOne.isOnline ? '#20bc30' : '#f44336'}`}]}>
                {brainBoxOne.isOnline ? 'Online' : 'Offline'}
              </Box>
            </Box>
          </Box>
          <Box sx={styles.boxButtonRestart}>
            <LoadingButton
              loadingPosition='start'
              loading={isReboot}
              startIcon={<Avatar src={restart} sx={styles.restartIcon} />}
              variant='contained'
              sx={[styles.restartButton, styles.button]}
              onClick={() => rebootBrainBox(Number(brainBoxOne.id))}>
              Reiniciar
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

const styles = createStyleSheet({
  ipInput: {
    fontSize: '16px',
    width: '100%',
    marginBottom: '16px',
    justifyContent: 'center',
    borderRadius: '6px',
    height: '40px',
    padding: '10px',
    border: '#C6C1C1 solid 1px',
    '&:hover': {
      border: '#00639f solid 1px',
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gridContainerOne: {
    borderRadius: 1,
    padding: '7px 15px 15px 22px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  },
  gridContainerTwo: {
    borderRadius: 1,
    display: 'grid',
    padding: '5px 22px 15px 22px',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
  },
  itemsBoxOne: {
    display: 'flex',
    borderRadius: 3,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  gridInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 3,
    gap: '10px',
  },
  boxInfosGridTwo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infosNotEdit: {
    height: '40px',
    paddingTop: 0.5,
    paddingLeft: 0,
  },
  infosEdit: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  gridInfosEdit: {
    display: 'flex',
    width: '45%',
    justifyContent: 'space-between',
  },
  infoHost: {
    height: '40px',
    paddingTop: 0.5,
    paddingLeft: 0,
  },
  gridButtonsEdit: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
  editButton: {
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
    margin: 1,
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
  },
  restartButton: {
    backgroundColor: '#20bc30',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00850e',
    },
  },
  boxButtonRestart: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'end',
    margin: '0 0 0.5em 0',
  },
  editIcon: {
    height: '22px',
    width: '22px',
  },
  restartIcon: {
    height: '20px',
    width: '20px',
  },
  titleSubmenusBrainBox: {
    fontSize: '13px',
    marginBottom: '3px',
    fontWeight: 'bold',
    width: '50%',
    fontFamily: "'Inter', 'sans-serif'",
    alignItems: 'center',
    display: 'flex',
  },
  brainBoxStatus: {
    borderRadius: '1em',
    width: '8.8rem',
    padding: '0.3em 0.4em 0.3em 0.8em',
    color: 'white',
    transition: '0.3s',
    textTransform: 'none',
    fontSize: '14px',
    textAlign: 'center',
  },
  brainBoxSituation: {
    borderRadius: '1em',
    padding: '0.4em 0.8em 0.4em 0.8em',
    color: 'white',
    transition: '0.3s',
    textTransform: 'none',
    fontSize: '14px',
    width: '75px',
    textAlign: 'center',
  },
  brainBoxStatus2: {
    borderRadius: '1em',
  },
  cameraStatus: {
    borderRadius: '1.25em',
    color: 'white',
    width: '120px',
    height: '40px',
    marginTop: 2,
    textTransform: 'none',
    backgroundColor: '#20bc30',
    '&:hover': {
      backgroundColor: '#00850e',
    },
  },
  buttonEdit: {
    marginTop: 4,
    color: 'white',
    width: '180px',
    height: '40px',
    textTransform: 'none',
    backgroundColor: '#20bc30',
    '&:hover': {
      backgroundColor: '#00850e',
    },
  },
  cancelButton: {
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  addButton: {
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  armed: {
    background: 'green',
    '&:hover': {
      backgroundColor: 'green',
    },
  },
  desarmed: {
    background: 'red',
    '&:hover': {
      backgroundColor: 'red',
    },
  },
  outsideTime: {
    background: 'orange',
    '&:hover': {
      backgroundColor: 'orange',
    },
  },
  gridItem: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  button: {
    height: '2.7em',
    width: '10em',
    maxWidth: '12em',
    borderRadius: '5px',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '0.9rem',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  gridItem2: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  gridItem3: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0px',
  },
})

export default BrainBoxConfigGeneralData
