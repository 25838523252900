import styled from 'styled-components'

export const StepperBubble = styled.div<{isActive: boolean; indexLowerThanActive: boolean}>`
  border-radius: 50%;

  ${({isActive, indexLowerThanActive}) => {
    if (isActive) {
      return `
                background-color: transparent;
                color: #0088FF;
                border: 2px solid #0088FF; 
            `
    }

    if (indexLowerThanActive) {
      return `
                background-color: transparent;
                color: #0088FF;
                 border: 2px solid #0088FF; 
            `
    }

    return `
            background-color: transparent;
            color: #344054;
            border: 2px solid #D0D5DD; 
        `
  }}
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  height: 35px;
  width: 35px;
`

export const TitleOnStep = styled.div<{color: string}>`
  margin-top: 10px;
  font-size: 14px;
  color: ${({color}) => color};
  text-wrap: nowrap;
  text-align: center;
  width: fit-content;

  @media (max-width: 767px) {
    font-size: 10px;
  }
`

export const DivisionStepper = styled.div<{color: string}>`
  width: 100px;
  height: 2px;
  background-color: ${({color}) => color};
  margin: 0 20px;
  margin-top: 16px;
  position: relative;

  @media (max-width: 767px) {
    width: 50px;
  }
`
