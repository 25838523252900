import styled from 'styled-components'

export const TermFooterBaseContainer = styled.div`
  margin-top: 72px;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  gap: 32px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border-top: 1px solid #d0d5dd;
`

export const TermFooterSuperLink = styled.a`
  color: #667085;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`

export const TermFooterImageContainer = styled.img`
  width: 200px;
  height: 44.898px;
`

export const TermPublishDateContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TermPublishDate = styled.p`
  font-family: 'Poppins', sans-serif;
  color: #667085;
  font-size: 14px;
  line-height: 21px;
  display: inline;
`
