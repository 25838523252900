import styled from 'styled-components'

export const TitleConnection = styled.p`
  font-size: 14px;
  color: #575757;
`

export const TitleConnectionContent = styled.p`
  font-size: 14px;
  line-height: 16px;
`

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
`

export const AccessContainerLastBox = styled.div`
  display: flex;
  gap: 20px;
  min-height: 75px;
  align-items: flex-end;
`
