import {Box} from '@viptech/react-components'
import {useState} from 'react'
import createStyleSheet from 'src/utilities/createStyleSheet'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import TransactionsFilter from './components/TransactionsFIlter'
import TransactionsGridTable from './components/TransactionsGridTable'
import TransactionsContextProvider, {useTransactionsContext} from './contexts/TransactionsContext'

const filtersTypes = [{id: '1', label: 'ID'}]

function TransactionsInner() {
  const {reloadTransactions, reloadingTransactions, transactionSearch, setTransactionSearch} = useTransactionsContext()

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={reloadingTransactions} onClick={() => reloadTransactions()} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={filtersTypes}
              setSearchSelected={setShowSearchSelected}
              searchSelected={showSearchSelected}
              inputValue={transactionSearch}
              inputSearch={setTransactionSearch}
            />
          </Box>
        </Box>
      </TopBar>
      <TransactionsFilter />
      <TransactionsGridTable />
    </Box>
  )
}

function Transactions() {
  return (
    <TransactionsContextProvider>
      <TransactionsInner />
    </TransactionsContextProvider>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default Transactions
