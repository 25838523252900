import styled from 'styled-components'
import {TableDataProps} from './TableDataProps'

export const TableDataBase = styled.td<TableDataProps>`
  text-align: ${({textAlign, theme}) => textAlign ?? theme.table.tableBody.textAlign};
  width: ${(props) => props.width};

  font-size: ${({fontSize, theme}) => fontSize ?? theme.table.tableBody.fontSize};
  font-family: ${({fontFamily, theme}) => fontFamily ?? theme.table.tableBody.fontFamily};
  font-weight: ${({fontWeight, theme}) => fontWeight ?? theme.table.tableBody.fontWeight};

  border-left: ${(props) => props.borderLeft && props.borderLeft};
  border-right: ${(props) => props.borderRight && props.borderRight};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  border-color: ${(props) => props.borderColor && props.borderColor};

  padding: ${(props) => props.padding && props.padding};
  padding-top: ${(props) => props.paddingTop && props.paddingTop};
  padding-left: ${(props) => props.paddingLeft && props.paddingLeft};
  padding-right: ${(props) => props.paddingRight ?? '2ch'};
  padding-bottom: ${(props) => props.paddingBottom && props.paddingBottom};

  color: ${({color, theme}) => color ?? theme.table.tableBody.color};
  background-color: ${({backgroundColor, theme}) => backgroundColor ?? theme.table.tableBody.backgroundColor};

  @media only screen and (max-width: 760px), (min-device-width: 761px) and (max-device-width: 1024px) {
    border-bottom: ${({theme}) => theme.table.tableBody.borderBottom};
    overflow: block;
    text-align: left;
    text-align: -webkit-left;
    align-content: center;
    padding: 0;
    padding-right: 0;
    min-height: ${({minHeight}) => minHeight ?? '0.5rem'};
    min-width: 11em;
    max-width: ${({maxWidthResponsive}) => maxWidthResponsive};
    width: ${({maxWidthResponsive}) => maxWidthResponsive};
  }
`
