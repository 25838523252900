import {useSnackbar} from 'notistack'
import {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {CameraOriginType, Client, EnumServer, Server} from 'src/services/api'
import {DguardServer} from 'src/services/api/endpoints/DguardEndpoint'
import {generateLinkRtsp} from 'src/utilities/LinkGenerator'
import {EnumCameraManufacturer} from 'src/utilities/enums/EnumCameraManufacturer'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {TransferListData} from '../components/CameraAddModal/CameraRegisterModal/TransferList'
import {Item} from '../components/CameraAddModal/CameraRegisterModal/TransferList/types'
import {CameraRegisterData} from './CameraRegisterContextData'
import {useCameraContext} from './CamerasContext'
import {AxiosError} from 'axios'
export interface AddCamera {
  name: string
  serverId: number
  clientId: number
  dguardInfo?: DguardInfo
}

export type DguardInfo = {
  serverGuid: string
  streamId: number
  dguardCameraId: number
  serverName: string
}

export type CameraRTSP = {
  name: string
  clientId: number
  serverId: number
  host: string
  stream: number
  rtspPort: number
  manufacturer: string

  user?: string
  password?: string
  codec?: string
  httpPort?: number

  channel?: number
}

export class AnalyticCamera {
  name = ''
  macAddress = ''
  code?: string = undefined
  id?: string = undefined
}

export type ServerRTSP = {
  name: string
  ip: string
  serverTypeId: number
  auth: string
}

export type ListCam = {
  client: Client
  linkRTSP: string
  channel: number
  name: string
  isChecked: boolean
}

const CameraRegisterContext = createContext({} as CameraRegisterData)

const CameraRegisterProvider = ({children}: React.PropsWithChildren<any>) => {
  const {reloadCameras} = useCameraContext()
  const {selectedCompanies} = useCompany()
  const {enqueueSnackbar} = useSnackbar()
  const [step, setStep] = useState<number>(0)

  const cameraNameRef = useRef<HTMLInputElement>()

  const [clients, setClients] = useState<Client[]>([])
  const [servers, setServers] = useState<Server[]>([])
  const [serverShow, setServerShow] = useState<Server[]>([])
  const [serversSearch, setServersSearch] = useState('')
  const debouncedServerSearch = useDebounce(serversSearch, 400)

  const [rightItems, setRightItems] = useState<Item<AddCamera>[]>([])
  const [leftItems, setLeftItems] = useState<Item<AddCamera>[]>([])

  const [selectedClient, setSelectedClient] = useState<Client>()
  const [isLoadingClients, setIsLoadingClients] = useState(false)
  const [loadingClients, setLoadingClients] = useState(true)
  const [reloadClientCount, setReloadClientCount] = useState(0)
  const [isLoadingCameras, setIsLoadingCameras] = useState(false)
  const [loadingThirdStep, setLoadingThirdStep] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isStepsOpen, setIsStepsOpen] = useState(false)
  const [dguardServers, setDguardServers] = useState<DguardServer[]>([])
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const abortRef = useRef<AbortController | null>(null)
  const blockServerRequests = useRef(false)

  const [selectedServer, setSelectedServer] = useState<Server | null>(null)
  const [selectedTypeIntegration, setSelectedTypeIntegration] = useState<CameraOriginType | null>(null)

  const [selectedModelDevice, setSelectedModelDevice] = useState<number>(1)
  const [selectedModelCamera, setSelectedModelCamera] = useState<number>(1)
  const [_channel, setChannel] = useState<number | null>(null)
  const [listCam, setListCam] = useState<ListCam[]>([])
  const [selectedButton, setSelectedButton] = useState<boolean>(false)

  const [fieldNameUser, setFieldNameUser] = useState<string>('')
  const [fieldPasswordUser, setFieldPasswordUser] = useState<string>('')
  const [fieldIpDomain, setFieldIpDomain] = useState<string>('')
  const [fieldRtspPort, setFieldRtspPort] = useState<string>('')
  const [fieldHttpPort, setFieldHttpPort] = useState<string>('80')
  const [fieldCodec, setFieldCodec] = useState<string>('')
  const [fieldPerfil, setFieldPerfil] = useState<string>('')
  const [fieldCodeStream, setFieldCodeStream] = useState<number>(0)
  const [fieldChannel, setFieldChannel] = useState<string>('')

  const [erroInputHost, setErroInputHost] = useState<boolean>(false)
  const [hostInput, setHostInput] = useState<string>('')
  const [selectedCompanyId, setSelectedCompanyId] = useState<number[]>([selectedCompanies[0]])
  const hasPermissionReadClients = ValidatePermissions('clients:read')
  const hasPermissionReadServers = ValidatePermissions('servers:read')
  const blockLoadingClients = useRef(false)
  const blockLoadingServers = useRef(false)

  function stepsOpen() {
    setIsStepsOpen(true)
    setSelectedTypeIntegration(null)
    reloadClients()
  }

  function step1(client: Client) {
    setSelectedClient(client)
    setStep(1)
  }
  function step2() {
    if (step !== 3) {
      setFieldNameUser('')
      setFieldPasswordUser('')
      setFieldIpDomain('')
      setFieldRtspPort('')
      setFieldCodec('')
      setFieldPerfil('')
      setFieldCodeStream(0)
      setSelectedModelDevice(1)
      setSelectedModelCamera(1)
      setErroInputHost(false)
      setChannel(null)
      setCameraRTSP({name: '', clientId: -1, serverId: -1, host: '', stream: -1, rtspPort: -1, httpPort: 80, manufacturer: ''})
      setServerRTSP({name: '', ip: '', serverTypeId: 5, auth: ''})
      setAnalyticCamera(new AnalyticCamera())
    }
    setStep(2)
  }
  function step3() {
    setStep(3)
  }

  function stepsClose() {
    setIsStepsOpen(false)
    setStep(0)
    setLeftItems([])
    setRightItems([])
    setServers([])
    setServersSearch('')
    setSelectedTypeIntegration(null)
  }

  function stepsPrevious() {
    setStep((x) => Math.max(x - 1, 0))
    setSelectedTypeIntegration(null)
    setSelectedButton(false)
    setErroInputHost(false)
  }

  const [cameraRTSP, setCameraRTSP] = useState<CameraRTSP>({
    name: '',
    clientId: -1,
    serverId: -1,
    host: '',
    stream: -1,
    rtspPort: -1,
    httpPort: 80,
    manufacturer: '',
  })

  const [serverRTSP, setServerRTSP] = useState<ServerRTSP>({
    name: '',
    ip: '',
    serverTypeId: 5,
    auth: '',
  })

  const [analyticCamera, setAnalyticCamera] = useState<AnalyticCamera>(new AnalyticCamera())

  async function createListServers() {
    if (isSaving) return

    const manufacturer = EnumCameraManufacturer[selectedModelCamera]
    try {
      if (!selectedCompanyId) return
      setIsSaving(true)

      listCam.forEach(async (cam, index) => {
        if (!cam.isChecked) return
        try {
          const addressInfo = {
            host: fieldIpDomain,
            stream: Number(fieldCodeStream),
            rtspPort: Number(fieldRtspPort),
            manufacturer: manufacturer,
            user: fieldNameUser,
            password: fieldPasswordUser,
            codec: fieldCodec || 'h.264',
            httpPort: 80,
          }
          const link = generateLinkRtsp({addressInfo, camera: null, enqueueSnackbar})
          if (!link) return
          const rtspInfo = {
            host: fieldIpDomain,
            stream: Number(fieldCodeStream),
            rtspPort: Number(fieldRtspPort),
            manufacturer: manufacturer,
            user: fieldNameUser,
            password: fieldPasswordUser,
            codec: fieldCodec || 'h.264',
            link,
          }
          await api.camera.addMany({
            origin: 'RTSP-DVR',
            data: [
              {
                name: cam.name,
                clientId: cam.client?.id!,
                isOnline: false,
                rtspInfo: rtspInfo,
              },
            ],
            companyId: selectedCompanyId[0],
            soft: true,
            channels: Number(_channel) || undefined,
          })
          enqueueSnackbar('Dispositivos adicionados com sucesso', {
            variant: 'success',
          })

          stepsClose()
          reloadCameras()
        } catch (error) {
          handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar dispositivo')
          stepsClose()
        } finally {
          setIsSaving(false)
        }
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar servidores')
    } finally {
      setIsSaving(false)
    }
  }

  const createServer = async () => {
    try {
      if (isSaving) return
      const manufacturer = EnumCameraManufacturer[selectedModelCamera]
      let stream = Number(fieldCodeStream)

      if (selectedModelCamera === EnumCameraManufacturer['Tecvoz']) stream = Number(fieldPerfil)
      if (!selectedCompanyId) return

      setIsSaving(true)

      let codec = undefined
      if (manufacturer === 'Hikvision') codec = 'h.264'

      const addressInfo = {
        host: fieldIpDomain,
        stream: Number(fieldCodeStream),
        rtspPort: Number(fieldRtspPort),
        manufacturer: manufacturer,
        user: fieldNameUser,
        password: fieldPasswordUser,
        codec: fieldCodec || 'h.264',
        channel: Number(fieldChannel),
        httpPort: 80,
      }

      const link = generateLinkRtsp({addressInfo, camera: null, enqueueSnackbar})
      if (!link) return

      const rtspInfo = {
        host: fieldIpDomain,
        stream: stream,
        rtspPort: Number(fieldRtspPort),
        manufacturer: manufacturer,
        user: fieldNameUser,
        password: fieldPasswordUser,
        codec: codec,
        httpPort: 80,
        channel: Number(fieldChannel) || Number(cameraRTSP.channel),
        link,
      }

      const response = await api.camera.addMany({
        origin: 'RTSP',
        data: [{name: cameraRTSP.name, clientId: selectedClient!.id, isOnline: false, rtspInfo}],
        companyId: selectedCompanyId[0],
        soft: true,
      })

      if (!response.data.data.totalRejected) {
        enqueueSnackbar('Câmera adicionada com sucesso', {
          variant: 'success',
        })
        stepsClose()
        reloadCameras()
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar a(s) câmera(s)')
    } finally {
      setIsSaving(false)
    }
  }

  const stepsCompleted = async () => {
    if (isSaving) return

    if (selectedTypeIntegration === 'Hikvision') stepsClose()
    else if (selectedTypeIntegration === 'RTSP') return createServer()

    try {
      if (!selectedCompanyId) return
      setIsSaving(true)
      if (!selectedTypeIntegration) return

      const items = rightItems
        .map((item) => {
          if (!item.data) return {} as AddCamera
          return item.data
        })
        .filter((it) => !!it)

      const data = items.map((item) => ({
        name: item.name,
        clientId: item.clientId,
        serverId: item.serverId,
        isOnline: false,
      }))

      const response = await api.camera.addMany({
        origin: selectedTypeIntegration,
        data: data,
        companyId: selectedCompanyId[0],
        soft: true,
      })

      if (!response.data.data.totalRejected) {
        enqueueSnackbar('Câmera(s) adicionada(s) com sucesso', {
          variant: 'success',
        })
        stepsClose()
        reloadCameras()
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar a(s) câmera(s)')
    } finally {
      setIsSaving(false)
    }
  }

  const loadAllServers = useCallback(async () => {
    if (!isStepsOpen) return
    if (hasPermissionReadServers) {
      if (!selectedCompanyId) return
      try {
        if (blockLoadingServers.current) return
        blockLoadingServers.current = true
        const response = await api.server.findManyPaged({
          page: 1,
          pageSize: 100,
          filter: {
            integrationType: 'ENTRADA',
            companyIds: selectedCompanyId,
          },
        })
        setServers(response.data.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar servidores de integração')
      } finally {
        blockLoadingServers.current = false
      }
    }
  }, [enqueueSnackbar, hasPermissionReadServers, isStepsOpen, selectedCompanyId])

  useEffect(() => {
    loadAllServers()
  }, [loadAllServers, selectedCompanyId])

  useEffect(() => {
    if (!abortRef.current) return
    if (step !== 2) abortRef.current.abort('aborted by client')
  }, [step])

  const loadServers = useCallback(async () => {
    setLoadingThirdStep(true)
    try {
      if (!selectedCompanyId) return
      if (blockLoadingServers.current) return
      blockLoadingServers.current = true
      const response = await api.server.findManyPaged({
        page: 1,
        pageSize: 100,
        filter: {integrationType: 'ENTRADA', serverTypeIds: selectedTypeIntegration === 'D-Guard' ? [4] : [2], companyIds: selectedCompanyId},
        includes: ['serverType'],
      })
      blockLoadingServers.current = false
      const serversList: Server[] = []
      response.data.data.entities.forEach(async (it) => {
        if (it.serverTypeId === SERVER_TYPE_DGUARD) {
          try {
            setLoadingThirdStep(true)
            const dguardResponse = await api.dguard.getDguardServers({serverId: it.id})
            dguardResponse.data.data.forEach(({guid, name}) => {
              serversList.push({id: it.id, ip: it.ip, name: it.name, serverTypeId: it.serverTypeId, dguardServerGuid: guid, dguardServerName: name})
            })
            setServerShow(serversList)
            setSelectedServer(serversList[0])
          } catch (error) {
            setSelectedServer(null)
            stepsPrevious()
            setStep(1)
            handleErrorWithSnackbar(enqueueSnackbar, error, `Erro ao buscar servidor ${it.name} do D-Guard`)
          } finally {
            setLoadingThirdStep(false)
          }
        } else {
          serversList.push({id: it.id, ip: it.ip, name: it.name, serverTypeId: it.serverTypeId})
          setServerShow(serversList)
          setSelectedServer(serversList[0])
        }
      })
    } catch (error) {
      setSelectedServer(null)
      stepsPrevious()
      setStep(1)
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar servidores de integração')
    } finally {
      setLoadingThirdStep(false)
    }
  }, [enqueueSnackbar, selectedCompanyId, selectedTypeIntegration])

  useEffect(() => {
    if (!isStepsOpen) return
    setLeftItems([])
    setRightItems([])
    loadServers()
  }, [isStepsOpen, loadServers, selectedTypeIntegration])

  useEffect(() => {
    async function loadCamerasFromServer() {
      if (selectedServer === null || !selectedClient || step !== 2 || selectedServer.id < 0 || blockServerRequests.current) return
      blockServerRequests.current = true
      try {
        const controller = new AbortController()
        abortRef.current = controller
        setIsLoadingCameras(true)
        const serverId = selectedServer.id
        if (selectedServer.dguardServerGuid) {
          const response = await api.dguard.getCameras({serverId, serverGuid: selectedServer.dguardServerGuid, signal: abortRef.current?.signal})
          setLeftItems(
            response.data.data.map((it, index) => {
              const dguardInfo: DguardInfo = {
                serverGuid: selectedServer.dguardServerGuid!,
                streamId: 0,
                dguardCameraId: it.id,
                serverName: selectedServer.dguardServerName!,
              }
              return {
                checked: false,
                key: `${it.name}-${dguardInfo.serverGuid}-${index}`,
                label: `${it.id} - ${it.name}`,
                data: {
                  name: it.name,
                  clientId: selectedClient.id,
                  serverId,
                  dguardInfo,
                },
              }
            }),
          )
        } else if (selectedServer.serverTypeId === EnumServer.DIGIFORT) {
          const response = await api.digifort.getCameras({serverId, signal: abortRef.current?.signal})
          setLeftItems(
            response.data.data.map((it, index) => ({
              checked: false,
              key: `${it.name}-${index}`,
              label: it.name,
              data: {
                name: it.name,
                clientId: selectedClient.id,
                serverId,
              },
            })),
          )
        }
        setRightItems([])
      } catch (error) {
        const cancelError = error as AxiosError
        if (cancelError.code && cancelError.code === 'ERR_CANCELED') return
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Falha ao carregar câmeras do servidor selecionado')
        setLeftItems([])
        setRightItems([])
      } finally {
        setIsLoadingCameras(false)
        blockServerRequests.current = false
      }
    }

    loadCamerasFromServer()
  }, [enqueueSnackbar, selectedClient, selectedServer, step])

  useEffect(() => {
    if (!isStepsOpen) return
    if (!hasPermissionReadClients) return
    async function loadClients() {
      try {
        if (!selectedCompanyId) return
        if (blockLoadingClients.current) return
        blockLoadingClients.current = true
        const response = await api.client.getMany({
          page: 1,
          paginate: true,
          filter: {companyIds: selectedCompanyId},
          search: {
            name: debouncedServerSearch,
          },
        })
        setClients(response.data.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar os Clientes')
      } finally {
        setLoadingClients(false)
        setIsLoadingClients(false)
        blockLoadingClients.current = false
      }
    }

    loadClients()
  }, [enqueueSnackbar, debouncedServerSearch, reloadClientCount, hasPermissionReadClients, selectedCompanies, selectedCompanyId])
  const canSave = rightItems.length > 0

  const reloadClients = () => {
    if (loadingClients) return
    setIsLoadingClients(true)
    setReloadClientCount((x) => x + 1)
  }

  const handleFilterClick = () => {
    setOpenFilter(!openFilter)
  }

  const onChangeTransferList = (data: TransferListData) => {
    setLeftItems(data.leftItems)
    setRightItems(data.rightItems)
  }

  function isLinkGeneratable() {
    return !fieldNameUser || !fieldPasswordUser || !fieldRtspPort || !fieldIpDomain || Number(fieldCodeStream) > -1
  }

  return (
    <CameraRegisterContext.Provider
      value={{
        fieldChannel,
        fieldNameUser,
        fieldPasswordUser,
        fieldIpDomain,
        fieldRtspPort,
        fieldHttpPort,
        fieldCodec,
        fieldPerfil,
        fieldCodeStream,

        erroInputHost,
        setErroInputHost,
        hostInput,
        setHostInput,
        handleFilterClick,
        setFieldChannel,
        setFieldNameUser,
        setFieldPasswordUser,
        setFieldIpDomain,
        setFieldRtspPort,
        setFieldHttpPort,
        setFieldCodec,
        setFieldPerfil,
        setFieldCodeStream,

        selectedModelCamera,
        setSelectedModelCamera,
        selectedModelDevice,
        setSelectedModelDevice,
        _channel,
        setChannel,
        cameraNameRef,
        loadingThirdStep,
        selectedButton,
        setSelectedButton,
        createListServers,
        clients,
        selectedClient,
        setSelectedClient,
        reloadClients,
        servers,
        selectedServer,
        setSelectedServer,
        serversSearch,
        setServersSearch,
        rightItems,
        leftItems,
        onChangeTransferList,
        loadServers,
        loadAllServers,
        isLoadingClients,
        isLoadingCameras,
        canSave,
        isSaving,
        listCam,
        setListCam,
        stepsOpen,
        isStepsOpen,
        step1,
        step2,
        step3,
        step,
        stepsPrevious,
        stepsClose,
        stepsCompleted,
        hasPermissionReadClients,
        hasPermissionReadServers,
        dguardServers,
        setDguardServers,
        serverShow,
        cameraRTSP,
        setCameraRTSP,
        serverRTSP,
        setServerRTSP,
        selectedTypeIntegration,
        setSelectedTypeIntegration,

        analyticCamera,
        setAnalyticCamera,
        isLinkGeneratable,
        setSelectedCompanyId,
        selectedCompanyId,
        setStep,
        setLeftItems,
      }}>
      {children}
    </CameraRegisterContext.Provider>
  )
}

function useCamRegisterModalContext2() {
  return useContext(CameraRegisterContext)
}

export {useCamRegisterModalContext2}
export default CameraRegisterProvider

export const SERVER_TYPE_DIGIFORT = 2
export const SERVER_TYPE_DGUARD = 4
