import {Ord} from './_types'

type PrimitiveBase = string | number | boolean | undefined
type PrimitiveArray = PrimitiveBase[]
type Primitive = PrimitiveBase | PrimitiveArray

type PartialParams = {
  includes?: string[]
  filter?: Record<string, Primitive>
  search?: Record<string, Primitive>
  orderBy?: Record<string, 'ASC' | 'DESC'> | Ord
  [key: string]: any
}

export function transformSearch(search?: Record<string, Primitive>): string {
  if (!search) return ''
  return Object.entries(search)
    .filter((pair) => pair[1])
    .map(([key, value]) => `${key}:${value}`)
    .join(',')
}

export function transformFilter(filter?: Record<string, Primitive>): string {
  if (!filter) return ''
  return Object.entries(filter)
    .filter((pair) => pair[1] !== undefined)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        const validValues = (value as any[])
          .filter((it) => it !== undefined && it !== null)
          .map(String)
          .filter((it) => it.length > 0)
        if (validValues.length === 0) {
          return [key, '']
        } else {
          return [key, validValues.join(';') + ';'] as const
        }
      } else {
        return [key, value!.toString()]
      }
    })
    .filter((pair) => pair[1].length > 0)
    .map(([key, value]) => `${key}:${value}`)
    .join(',')
}

export function prepareParams<T extends PartialParams>({includes, filter, search, orderBy, ...params}: T): Record<string, string> {
  const result: Record<string, string> = {...params}
  const filterT = transformFilter(filter)
  const searchT = transformSearch(search)
  if (searchT) result.search = searchT
  if (filterT) result.filter = filterT
  if (includes) result.includes = includes.join(',')
  if (orderBy === 'ASC' || orderBy === 'DESC') {
    result.orderBy = orderBy
  } else if (orderBy) {
    result.orderBy = Object.entries(orderBy)
      .slice(0, 1) // Allow only one orderBy
      .map(([key, value]) => `${key}:${value}`)
      .join(',')
  }
  return result
}
