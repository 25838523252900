import {Box, Button, Dropdown, DropdownItem, Input, Modal} from '@viptech/react-components'

import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import useClients from '../../hooks/useClients'
import {List} from '../../hooks/useLists'

export type EditListModalProps = {
  isOpen: boolean
  closeModal: () => void
  list: List
  cancelCallback: () => void
}

function EditListModal(props: EditListModalProps) {
  const {isOpen, closeModal, list, cancelCallback} = props
  const [name, setName] = useState(list.name || '')
  const [listType, setListType] = useState(list.type === 'Faces' ? 'Facial' : 'Placas')
  const [clientSelected, setClientSelected] = useState({} as DropdownItem)
  const {enqueueSnackbar} = useSnackbar()
  const {clients: clientItems, refresh} = useClients()
  const hasFacialUpdatePermission = ValidatePermissions('face:update')
  const hasLicensePlateUpdatePermission = ValidatePermissions('lpr:update')

  if ((listType === 'Facial' && !hasFacialUpdatePermission) || (listType === 'Placas' && !hasLicensePlateUpdatePermission)) handleClose()

  useEffect(() => {
    if (list.companyId) {
      refresh(list.companyId)
    }
  }, [list])

  useEffect(() => {
    const client = clientItems.find((it) => it.id === `${list.clientId}`)
    setName(list.name)
    setListType(list.type === 'Faces' ? 'Facial' : 'Placas')
    if (!client) return
    setClientSelected(client)
  }, [clientItems])

  function clearFields() {
    setName('')
    setListType('')
    setClientSelected({} as DropdownItem)
  }

  function handleClose() {
    clearFields()
    closeModal()
  }

  async function handleEditList() {
    try {
      const body = {
        name: name !== list.name ? name : undefined,
        clientId: +clientSelected.id !== list.clientId ? +clientSelected.id : undefined,
      }
      if (listType === 'Facial') {
        await api.facialRecognition.updateFacialItemList(list.id, body)
      } else {
        await api.lpr.updateLprList(list.id, body)
      }
      enqueueSnackbar('Lista editada com sucesso', {variant: 'success'})
      handleClose()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao editar lista')
    }
  }

  function diff() {
    if (name !== props.list.name) return false
    if (+clientSelected.id !== props.list.clientId) return false
    return true
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => cancelCallback()}
      backgroundColor='white'
      width='600px'
      height='310px'
      padding='16px'
      paddingBottom='16px'
      paddingLeft='16px'
      paddingRight='16px'
      paddingTop='16px'>
      <Box position='absolute' right='14px' top='14px'>
        <Icon src={CloseIcon} cursor='pointer' onClick={() => cancelCallback()} />
      </Box>
      <Box paddingBottom='20px' fontWeight='600' fontSize='22px' color='black'>
        Editar Lista
      </Box>
      <Box width='100%' display='flex' direction='column' justify='center' align='center' rowGap='20px'>
        <Input.Root width='97%' controlled value={name} label='Nome da lista' onChange={(e) => setName(e.target.value)} maxLength={99} />
        <Dropdown width='97%' selected={clientSelected} items={clientItems || []} label='Cliente (Opcional)' onChangeSelected={(e) => setClientSelected(e)} />
      </Box>
      <Box display='flex' direction='row' columnGap='10px' align='center' justify='flex-end' paddingTop='20px' paddingRight='8px'>
        <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => cancelCallback()}>
          Cancelar
        </Button>
        <Button fontSize='12px' height='40px' width='150px' disabledTextColor='#8E8E8E' disabled={diff()} onClick={() => handleEditList()}>
          Salvar
        </Button>
      </Box>
    </Modal>
  )
}

export default EditListModal
