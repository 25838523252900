import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Ord, Paginate} from './_types'
import {AuthLoginResponse} from './AuthEndpoint'

const prefix = process.env.REACT_APP_COMPANIES_BASE_URL

type CompanyIncludes = 'permissions'

export type Company = {
  id: number
  name: string
  document: string
  apiUser?: string
  isDeleted?: boolean
  expirationDate?: string
  permissions?: Permission[]
}

export type Permission = {
  codename: string
  scope: string
}

export type UpdatePermissions = {
  id?: number
  permissions: string[]
}

export type CreateCompany = {
  id?: number
  name: string
  document: string
  expirationDate?: string
}

export type UpdateCompany = {
  id?: number
  name?: string
  document?: string
  expirationDate?: string
}

export type CompanyCredentials = {
  apiUser: string
  apiPassword: string
}

export type GetCompaniesParams = {
  orderBy?: {
    names: Ord
  }
  page?: number
  pageSize?: number
  filter?: {
    ids?: number[]
    from?: string
  }
  search?: {
    name?: string
    document?: string
  }
  includes?: CompanyIncludes[]
}

export type SelfRegisterBody = {
  name: string
  companyName?: string
  email: string
  cpf: string
  phone: string
  password: string
  code: string
}

type SelfLogin = {
  token: AuthLoginResponse
}

export class CompanyEndpoint {
  async getMany(params: GetCompaniesParams): Promise<AxiosResult<Paginate<Company>>> {
    return await axios.get(`${prefix}/companies`, {
      params: prepareParams(params),
    })
  }

  async updatePermissions(body: UpdatePermissions): Promise<AxiosResult<UpdatePermissions>> {
    return await axios.patch(`${prefix}/companies/permissions`, body)
  }

  async create(body: CreateCompany): Promise<AxiosResult<CreateCompany>> {
    return await axios.post(`${prefix}/companies`, body)
  }

  async update(body: UpdateCompany): Promise<AxiosResult<UpdateCompany>> {
    return await axios.patch(`${prefix}/companies`, body)
  }

  async delete(ids: number[]): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/companies`, {data: {ids}})
  }

  async selfRegister(data: SelfRegisterBody): Promise<AxiosResult<SelfLogin>> {
    return await axios.post(`${prefix}/self-register-company`, data)
  }
}
