import {Box} from '@viptech/react-components'
import deleteIcon from 'src/images/deleteIcon.svg'
import createStyleSheet from '../../../../../../../../utilities/createStyleSheet'

type CardProps = {
  initialState: boolean
  selected?: boolean
  label: string
  onClick: (cardSelected: string) => void
  multipleActions?: boolean
  inputLabel?: string
  valueInput?: string | number
  brainBox?: string
  listName?: string
}

const Card = (props: CardProps) => {
  const {selected, label, onClick, initialState, multipleActions, inputLabel, valueInput, brainBox, listName} = props

  return (
    <>
      {multipleActions ? (
        <Box style={styles.multipleActionsCard}>
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', width: '100%'}}>
            {listName && (
              <p style={styles.multipleActionsLabel}>
                <b>Lista: </b>
                {listName}
              </p>
            )}
            <p style={styles.multipleActionsLabel}>
              <b>Despacho de Evento: </b>
              {label}
            </p>
            {brainBox && (
              <p style={styles.multipleActionsLabel}>
                <b>Brain Box: </b>
                {brainBox}
              </p>
            )}
            {inputLabel && (
              <p style={styles.multipleActionsLabel}>
                <b>{inputLabel}: </b>
                {valueInput}
              </p>
            )}
          </div>

          <button style={{background: 'transparent'}} onClick={() => onClick(label)}>
            <img src={deleteIcon} alt='deletar ação de saída' style={{width: '20px', height: '20px'}} />
          </button>
        </Box>
      ) : (
        <Box style={initialState ? styles.card : selected ? styles.selected : styles.disabledCard} onClick={() => onClick(label)}>
          <span style={initialState ? styles.label : selected ? styles.selectedLabel : styles.disabledLabel}>{label}</span>
        </Box>
      )}
    </>
  )
}

export default Card

const styles = createStyleSheet({
  multipleActionsCard: {
    display: 'flex',
    padding: '20px 10px',
    justifyContent: 'center',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #009EFF',
    background: '#FFF',
    minHeight: '100px',
    maxHeight: '43px',
  },
  disabledCard: {
    display: 'flex',
    cursor: 'pointer',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #009dff73',
    background: '#FFF',
    maxHeight: '43px',
  },
  card: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: '1px solid #009EFF',
    background: '#FFF',
    maxHeight: '43px',
  },
  selected: {
    display: 'flex',
    padding: '12px 16px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    fontSize: '14px',
    borderRadius: '5px',
    background: '#009EFF',
    maxHeight: '43px',
  },
  multipleActionsLabel: {
    color: '#686868',
    fontFamily: 'Roboto, sans-serif',
    fontSize: ' 12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  label: {
    color: '#009EFF',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: ' 14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textOverflow: 'ellipsis',
    maxWidth: '210px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selectedLabel: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: ' 14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textOverflow: 'ellipsis',
    maxWidth: '210px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  disabledLabel: {
    color: '#009dff73',
    textAlign: 'center',
    fontFamily: 'Roboto, sans-serif',
    fontSize: ' 14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    textOverflow: 'ellipsis',
    maxWidth: '210px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
})
