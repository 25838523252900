import {Box, Input, Typography} from '@viptech/react-components'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import Tooltip from 'src/components/Tooltip'
import InfoIconBlack from 'src/images/infoIconBlack.svg'
import {ConnectionModes, IpSources, useBrainBoxContext} from 'src/pages/brain-box/context/BrainBoxContext'
import Dropdown from '../../../../../components/dropdown/Dropdown'

function BrainBoxData() {
  const {brainBox, setBrainBox} = useBrainBoxContext()

  const ipSourcesMap = {
    DHCP: {id: 'DHCP', label: 'DHCP'},
    Static: {id: 'Static', label: 'Estático'},
  }

  return (
    <Box width='49%' height='100%' border='0.5px solid #5F5B5B' borderRadius='8px' overflow='hidden' padding='16px'>
      <Box>
        <Box>
          <Box display='flex' direction='column'>
            <Box>
              <Box fontSize='24px' fontWeight='500'>
                Informações da Brain box
              </Box>
              <Box color='#8E8E8E' paddingBottom='12px'>
                Preencha os campos abaixo para cadastrar a sua Brain box
              </Box>
            </Box>
            <Box>
              <Input.Root
                label='Nome da Brain box'
                labelColor='rgba(54, 65, 82, 0.88)'
                paddingBottom='12px'
                defaultValue={brainBox.name}
                onChange={(e) => setBrainBox({...brainBox, name: e.target.value})}
              />
              <Box>
                <Box display='flex' direction='row'>
                  <Typography variant='span' color='rgba(54, 65, 82)' family='Inter' size='14px'>
                    Código da box
                  </Typography>
                  <Tooltip content='O código da box pode ser encontrado do lado de trás da box' containerProps={{display: 'flex', paddingLeft: '5px'}}>
                    <Icon src={InfoIconBlack} width='18px' height='18px' />
                  </Tooltip>
                </Box>
                <Input.Root
                  paddingBottom='12px'
                  paddingTop='5px'
                  defaultValue={brainBox.code}
                  onChange={(e) => setBrainBox({...brainBox, code: e.target.value})}
                />
              </Box>
              <Dropdown
                label='Obtenção de IP'
                paddingBottom='12px'
                items={[
                  {id: 'DHCP', label: 'DHCP'},
                  {id: 'Static', label: 'Estático'},
                ]}
                selected={ipSourcesMap[brainBox.ipSource]}
                onChangeSelected={(e) => setBrainBox({...brainBox, ipSource: e.id as IpSources})}
              />
              <Dropdown
                label='Modo de conexão'
                paddingBottom='12px'
                items={[
                  {id: 'Ethernet', label: 'Ethernet'},
                  {id: 'Wi-Fi', label: 'Wi-Fi'},
                ]}
                selected={{id: brainBox.connectionMode, label: brainBox.connectionMode}}
                onChangeSelected={(e) => setBrainBox({...brainBox, connectionMode: e.id as ConnectionModes})}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default BrainBoxData
