import styled from 'styled-components'

export const LayoutContentWrapper = styled.div`
  padding: 1.35em;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    padding: 0;
  }
`
export const ContainerPaperContent = styled.div`
  border-radius: 8px;
  flex-direction: column;
  background: white;
  display: flex;
  overflow: hidden;

  @media (max-width: 767px) {
    border-radius: 0px;
  }
`

export const LayoutContentContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: #eaeaea;

  @media (max-width: 767px) {
    background-color: white;
  }
`
