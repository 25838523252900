import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {Box, Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import actionsObject from 'src/images/actionsObject.svg'
import Config from 'src/images/config.svg'
import Delete from 'src/images/deleteIconBlack.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'
import CopieIconChangeColor from '../../../images/CopieIcon'

type CameraTableOptionProps = {
  oneObject: string
  onClick: (event: string) => void
  hasDeletePermission: boolean
  hasUpdatePermission: boolean
  isCameraOnline: boolean
}

export const CameraTableOption = (props: CameraTableOptionProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Tooltip title='Opções' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            props.onClick('copy-config')
          }}
          disabled={!props.hasDeletePermission}
        >
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
            <CopieIconChangeColor color='#444D4A' />
            <Box sx={{fontFamily: 'Inter'}}>Copiar configuração</Box>
          </Box>
        </MenuItem>

        {!props.isCameraOnline && (
          <MenuItem
            sx={styles.menuInfos}
            onClick={() => {
              setAnchorEl(null)
              props.onClick('online')
            }}
            disabled={!props.hasUpdatePermission}>
            <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
              <Box style={{width: 16, height: 18, paddingLeft: '1px', alignItems: 'center', fontSize: '1em', justifyContent: 'center', verticalAlign: 'top'}}>
                {'🟢'}
              </Box>
              <Box sx={{fontFamily: 'Inter'}}>Habilitar</Box>
            </Box>
          </MenuItem>
        )}
        {props.isCameraOnline && (
          <MenuItem
            sx={styles.menuInfos}
            onClick={() => {
              setAnchorEl(null)
              props.onClick('offline')
            }}
            disabled={!props.hasUpdatePermission}>
            <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
              <Box style={{width: 16, height: 18, paddingLeft: '1px', alignItems: 'center', fontSize: '1em', justifyContent: 'center', verticalAlign: 'top'}}>
                {'🔴'}
              </Box>
              <Box sx={{fontFamily: 'Inter'}}>Desabilitar</Box>
            </Box>
          </MenuItem>
        )}
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            props.onClick?.('edit-camera')
          }}
          disabled={!props.hasUpdatePermission}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
            <img src={Config} alt='Menu logo' style={{width: 15, height: 16}} />
            <Box sx={{fontFamily: 'Inter'}}>Ir para configuração</Box>
          </Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            props.onClick?.('to-events')
          }}
          disabled={!props.hasUpdatePermission}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
            <CalendarMonthIcon sx={{width: 15, height: 16}} />
            <Box sx={{fontFamily: 'Inter'}}>Ir para eventos</Box>
          </Box>
        </MenuItem>

        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            props.onClick('delete')
          }}
          disabled={!props.hasDeletePermission}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center'}}>
            <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
            <Box sx={{fontFamily: 'Inter'}}>Deletar</Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = createStyleSheet({
  menuInfos: {
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
  },
})
