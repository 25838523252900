import {useEffect, useRef} from 'react'
import {TableDataBase} from './TableDataBase'
import {TableDataProps} from './TableDataProps'

export default function TableData(props: TableDataProps) {
  const ref = useRef<HTMLTableCellElement>(null)

  useEffect(() => {
    if (!ref.current) return
    const parent = ref.current.parentElement
    if (!parent) return
    let currentIndex = 0
    parent.childNodes.forEach((child, index) => {
      if (child === ref.current) currentIndex = index
    })
    const th = parent.parentElement?.previousSibling?.firstChild?.childNodes.item(currentIndex)
    const header = th?.textContent
    ref.current.setAttribute('data-label', String(header))
  }, [])

  return (
    <TableDataBase
      ref={ref}
      width={props.width}
      fontSize={props.fontSize}
      fontFamily={props.fontFamily}
      fontWeight={props.fontWeight}
      borderLeft={props.borderLeft}
      borderRight={props.borderRight}
      borderRadius={props.borderRadius}
      borderColor={props.borderColor}
      padding={props.padding}
      paddingTop={props.paddingTop}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      paddingBottom={props.paddingBottom}
      color={props.color}
      backgroundColor={props.backgroundColor}
      {...props}
    />
  )
}
