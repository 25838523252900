import styled from 'styled-components'
import {TableStyleProps} from './TableProps'
import {StyledTheme as defaultTheme} from 'src/StyledTheme'

export const TableContainer = styled.div<TableStyleProps>`
  overflow-y: auto;
  overflow-x: auto;

  margin: ${(props) => props.margin ?? '0'};
  width: ${(props) => props.width ?? '100%'};
  padding: ${(props) => props.padding ?? '0'};
  height: ${(props) => props.height ?? 'auto'};

  ::-webkit-scrollbar {
    width: ${({theme}) => theme.dropdown.scrollbarWidth ?? defaultTheme.dropdown.scrollbarWidth};
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${({theme}) => theme.dropdown.scrollbarBackground ?? defaultTheme.dropdown.scrollbarBackground};
    border-radius: 100vw;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`

export const TableComponent = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
  position: relative;
  color: ${({theme}) => theme.colors.text ?? 'black'};

  @media only screen and (max-width: 760px), (min-device-width: 761px) and (max-device-width: 1024px) {
    border: 0;
  }
`
