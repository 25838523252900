import {Client} from './ClientEndpoint'
import {vincLayout} from './LayoutEndpoint'
import {Server} from './ServerEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, CreateResponse, Ord, Paginate} from './_types'

const prefix = process.env.REACT_APP_CAMERAS_BASE_URL

type CameraInclude = 'configuration' | 'configuration.receiveMode' | 'company' | 'client' | 'status' | 'server' | 'layouts' | 'layouts.layout' | 'addressInfo'

export interface ReceiveMode {
  id: number
  description: string
}

export interface CameraStatus {
  id: number
  description: string
}

export type CameraConfiguration = {
  id: number
  receiveMode: ReceiveMode
  receiveModeId: number
  brainBoxId?: number
  isOnline: boolean
  snapshotHeight?: number
  snapshotWidth?: number
  outputIntegrationId?: number | null
  lprIntegrationId?: number | null
  detectionSensitivity: number
  partition: string
  largeQueue: boolean
}

export type AddressInfo = {
  channel: number
  codec: string
  host: string
  httpPort: number
  id: number
  manufacturer: string
  password: string
  rtspPort: number
  stream: number
  user: string
  link: string | null
}

export type Camera = {
  id: number
  name: string
  lastUpdate: string
  lastTriggeringAt: string | null
  lastTriggerReceived: string | null
  isDeleted: boolean
  hasFilterByObject: boolean
  configurationId: number
  statusId: number
  serverId: number
  companyId: number
  clientId: number
  status: CameraStatus
  configuration: CameraConfiguration
  server: Server
  longitude: number
  latitude: number
  client: Client
  layouts: vincLayout[]
  hasLpr?: boolean
  hasActiveTrigger?: boolean
  isOnline: boolean
  origin: string
  addressInfo: AddressInfo
}

export type UpdateCamera = {
  latitude?: number
  longitude?: number
  unboundLprBlacklist?: boolean
  configuration?: Partial<CameraConfiguration>
  addressInfo?: EditAddressInfo
  name?: string
}

export type GetCamerasParams = {
  page?: number
  pageSize?: number
  paginate?: boolean
  includes?: CameraInclude[]
  filter?: {
    ids?: number[]
    companyIds?: number[]
    clientIds?: number[]
    serverIds?: number[]
    cameraName?: string[]
    hasLpr?: true
    serverTypeIds?: number[]
    isOnline?: boolean
    statuses?: string[]
    origin?: CameraOriginType
  }
  search?: {
    clientName?: string
    serverName?: string
  }
}

export type GetCameraParams = {
  includes?: CameraInclude[]
}

export type CameraOriginType = 'D-Guard' | 'Digifort' | 'RTSP' | 'RTSP-DVR' | 'Hikvision' | 'RTMP'

export type RtspCameraInfo = {
  host?: string
  stream?: number
  rtspPort?: number
  manufacturer?: string

  user?: string
  password?: string
  codec?: string
  httpPort?: number

  channel?: number
  link: string
}

export type EditAddressInfo = {
  host?: string
  stream?: number
  rtspPort?: number
  manufacturer?: string

  user?: string
  password?: string
  codec?: string
  httpPort?: number

  channel?: number
  link?: string | null
}

export type AddCameras = {
  origin: CameraOriginType
  data: Array<{
    clientId: number
    name?: string
    isOnline?: boolean
    rtspInfo?: RtspCameraInfo
  }>
  companyId?: number
  channels?: number
  soft?: boolean
}

export type Trigger = {
  id: number
  isActive: boolean
  boxOutputDeviceId: number
  triggerTime: number
}

export type TestTriggerParams = {
  ip?: string
  port?: number
}

export type CameraAnalyticDTO = {
  client: {id: number; name: string; companyId: number}
  clientId: number
  id: number
  macAddress: string
  name: string
}

export type UpdateAnalyticCameraBodyDTO = {
  name: string
  macAddress: string
  analytics: Array<string>
}

export type CameraHistoric = {
  id: number
  cameraId: number
  statusId: number
  isOnline: boolean
  createdAt: string
}

export type GetCameraHistoricParams = {
  page?: number
  pageSize?: number
  from?: string
  to?: string
  filter?: {
    cameraId: number
    statuses?: string[]
  }
  orderBy?: {
    timestamp?: Ord
  }
}

export type ConnectionTimeMultiple = {
  id: number
  connectionTime: number
}

export type ConnectionTime = {
  time: number
}

export type GetSnapshot = {
  time: {
    base64: string
  }
}

export type PasteConfigType = {
  sourceId: number
  targetIds: number[]
  attributes: string[]
}

export type PasteConfigResponseType = {
  targetId: string
  status: {
    type: 'success' | 'error'
    attribute: string
    message: string
  }
}

export class CameraEndpoint {
  async getMany(params: GetCamerasParams): Promise<AxiosResult<Paginate<Camera>>> {
    return await axios.get(`${prefix}/cameras`, {
      params: prepareParams(params),
    })
  }

  async getById(cameraId: number, params?: GetCameraParams): Promise<AxiosResult<Camera>> {
    return await axios.get(`${prefix}/camera/single/`, {
      params: prepareParams({id: cameraId, ...params}),
    })
  }

  async deleteMany(cameraIds: number[]) {
    return await axios.delete(`${prefix}/cameras`, {data: {ids: cameraIds}})
  }

  async addMany(body: AddCameras): Promise<AxiosResult<CreateResponse<Camera>>> {
    return await axios.post(`${prefix}/cameras`, body)
  }

  async updateCamera(cameraId: number, body: UpdateCamera) {
    return await axios.patch(`${prefix}/camera`, {id: cameraId, ...body})
  }

  async testTrigger(params: TestTriggerParams): Promise<AxiosResult<any>> {
    return await axios.get(`${prefix}/trigger/test`, {
      params: prepareParams(params),
    })
  }

  async getCameraHistoric(params: GetCameraHistoricParams): Promise<AxiosResult<Paginate<CameraHistoric>>> {
    return await axios.get(`${prefix}/camera-historic`, {params: prepareParams(params)})
  }

  async getConnectionTime(id: number): Promise<AxiosResult<ConnectionTime>> {
    return await axios.get(`${prefix}/camera/${id}/connection-time`)
  }

  getConnetionTimes(ids: number[]) {
    const qs = ids?.map((id) => `ids[]=${id}`).join('&')
    const customHeaders: Record<string, string> = {
      'Transfer-Encoding': 'chunked',
      'Content-Type': 'text/plain',
      Accept: 'text/plain',
      Authorization: 'Bearer ' + localStorage.getItem('@Brain-token'),
    }
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), 7000)

    const res = fetch(`${prefix}/cameras/connection-time?` + qs, {
      method: 'GET',
      headers: customHeaders,
      signal: controller.signal,
    })
    clearTimeout(id)
    return res
  }

  async setCamerasStatus(ids: number[], status: boolean): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/cameras/status`, {ids, enabled: status})
  }

  async getSnapshot(cameraId: number): Promise<AxiosResult<GetSnapshot>> {
    return await axios.get(`${prefix}/camera/${cameraId}/snapshot`)
  }

  async updateAdvancedConfig(body: {id: number; detectionSensitivity?: number; largeQueue?: boolean}) {
    return await axios.patch(`${prefix}/camera/config`, body)
  }

  async pasteConfig(body: PasteConfigType) {
    return await axios.post(`${prefix}/cameras/copy`, body)
  }
}
