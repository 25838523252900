import {Box, Button, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useState} from 'react'
import NoImage from 'src/images/noImage.jpg'
import {CameraImageDraw} from '../../../../../../../../common/components/camera-image-draw/CameraImageDraw'
import Dropdown from '../../../../../../../../components/dropdown/Dropdown'
import {DropdownItem} from '../../../../../../../../components/dropdown/DropdownProps'
import api, {Coordinate} from '../../../../../../../../services/api'
import createStyleSheet from '../../../../../../../../utilities/createStyleSheet'
import handleErrorWithSnackbar from '../../../../../../../../utilities/handleErrorWithSnackbar'
import {useCameraConfigContext} from '../../../../../../context/CameraConfigContext'
import {ObjectDetectionProps} from '../../../../ObjectDetection'
import {StepperModalActionsButtonContainer, StepperModalBarrierContainer} from '../../../styles'
import {ChosenObject} from '../../StepperModal'
import Card from '../../common/components/Card'
import Separation from '../../common/components/Separation'
import {firstStepResourceIdSet, skipThirdStep} from '../Constants'

type ChooseBarrierStepProps = {
  nextFunction: (index: number, resume: string, advanceTo?: number) => void
  returnFunction: (index: number) => void
  setChosen: (choices: Partial<ChosenObject>) => void
  chosen: ChosenObject | null
  detectionDisableLineCrossing: boolean
  hasLineCrossingPermission: boolean
  registeredObjects: ObjectDetectionProps[]
}

export type BarrierChosenType = {
  id: string
  cameraConfigurationId?: number
  label: string
  coordinates?: Array<Coordinate>
  points?: any
}

const ChooseBarrierStep = (props: ChooseBarrierStepProps) => {
  const {nextFunction, returnFunction, setChosen, chosen, registeredObjects} = props
  const {enqueueSnackbar} = useSnackbar()
  const {camera, snapshot, setAreaItems, _dimImage, getSnapshotRtsp} = useCameraConfigContext()

  const barrierOptions = ['Cruzamento de linha', 'Detecção em uma área']
  if (props.detectionDisableLineCrossing || !props.hasLineCrossingPermission) barrierOptions.shift()
  const [barrierOptionsSelectedCard, setBarrierOptionsSelectedCard] = useState('')
  const showSeletectedBarrier = barrierOptionsSelectedCard === 'Cruzamento de linha' ? 'linha' : 'área'

  const [chosenAreaOrLine, setChosenAreaOrLine] = useState<BarrierChosenType>()
  const [listingOfAllAreasOrLines, setListingOfAllAreasOrLines] = useState<BarrierChosenType[]>([])

  const snapshotSrc = `data:image/jpeg;base64,${snapshot}`

  const listAreas = useCallback(async (cameraId: number) => {
    try {
      const response = await api.interestArea.getInterestAreas({cameraId})
      const allAreas = response.data.data.map((it) => {
        const points = it.coordinates.map((it2) => {
          return {
            id: it2.id,
            x: it2.x * _dimImage.width,
            y: it2.y * _dimImage.height,
            interestAreaId: it2.interestAreaId,
          }
        })
        return {
          id: String(it.id),
          key: `${it.name} ${it.id}`,
          label: it.name,
          points,
          data: {area: it, updated: false},
        }
      })
      setListingOfAllAreasOrLines(allAreas)
      setAreaItems(allAreas)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao listar áreas cadastradas')
    }
  }, [])

  useEffect(() => {
    if (!camera?.id) return
    if (barrierOptionsSelectedCard === 'Cruzamento de linha') listLines(camera?.id)
    else if (barrierOptionsSelectedCard === 'Detecção em uma área') listAreas(camera?.id)
    getSnapshotRtsp(camera.id)
  }, [barrierOptionsSelectedCard])

  const listLines = useCallback(async (cameraId: number) => {
    try {
      const response = await api.lineCrossing.getLineCrossing(cameraId)
      const allLines = response.data.data.map((it) => {
        const points = it.coordinates?.map((it2) => {
          return {
            id: it2.id,
            x: it2.x * _dimImage.width,
            y: it2.y * _dimImage.height,
            lineCrossingId: it2.lineCrossingId,
          }
        })

        return {
          id: String(it.id),
          key: `${it.name} ${it.id}`,
          label: it.name,
          points,
          data: {area: it, updated: false},
        }
      })
      setListingOfAllAreasOrLines(allLines)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao listar linhas cadastradas')
    }
  }, [])

  const handleChosenBarrier = (e: DropdownItem) => {
    const registeredAreaIds = registeredObjects.map((it) => it.interestArea.id)
    const registeredObjectIds = registeredObjects.map((it) => it.objectType.id)
    const alreadyHaveThisResourceRegistered =
      registeredObjectIds.includes(chosen?.objectTypeId!) && firstStepResourceIdSet.includes(chosen?.detectionTypeChosen!)
    const alreadyHaveThisAreaRegistered = registeredAreaIds.includes(+e.id)
    if (alreadyHaveThisAreaRegistered && alreadyHaveThisResourceRegistered)
      return enqueueSnackbar('Já existe uma detecção desse objeto nessa área, por favor selecione outra', {variant: 'warning'})
    setChosenAreaOrLine(e)
    if (barrierOptionsSelectedCard === 'Cruzamento de linha') {
      setChosen({
        lineCrossingId: +e.id,
      })
    } else {
      setChosen({
        interestAreaId: +e.id,
      })
    }
  }

  const handleNextFunction = () => {
    if (skipThirdStep.includes(chosen?.detectionTypeChosen!)) return nextFunction(2, barrierOptionsSelectedCard, 3)
    nextFunction(2, barrierOptionsSelectedCard)
  }

  return (
    <>
      <StepperModalBarrierContainer>
        <Box flex={1 / 2} display='flex' direction='column' rowGap='24px' padding='10px 40px'>
          <Box>
            <Typography variant='span' size='16px' style={{fontWeight: 500}}>
              Qual tipo de barreira você deseja configurar?
            </Typography>
            <Typography variant='p' size='12px' color='#8E8E8E'>
              Escolha abaixo o tipo de barreira para configuração
            </Typography>
          </Box>

          <Box display='flex' direction='column' flexWrap='wrap' rowGap='16px'>
            {barrierOptions.map((it) => {
              return (
                <Card
                  label={it}
                  selected={barrierOptionsSelectedCard === it}
                  onClick={setBarrierOptionsSelectedCard}
                  initialState={!barrierOptionsSelectedCard}
                />
              )
            })}
          </Box>
        </Box>

        <Box className='separation'>
          <Separation />
        </Box>

        {barrierOptionsSelectedCard && (
          <Box display='flex' direction='row' flex={1} columnGap='18px' justifyContent='center'>
            <Box>
              <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                Qual {showSeletectedBarrier} você deseja configurar?
              </Typography>
              <Typography variant='p' size='12px' color='#8E8E8E'>
                Escolha abaixo qual linha que você deseja configurar
              </Typography>

              <Box paddingTop='30px'>
                <Dropdown
                  height='40px'
                  fontSize='12px'
                  selected={chosenAreaOrLine}
                  placeholder={`Selecione uma das ${showSeletectedBarrier}s cadastradas`}
                  items={listingOfAllAreasOrLines}
                  onChangeSelected={(e) => {
                    handleChosenBarrier(e)
                  }}></Dropdown>
                <Box minHeight='13em' paddingTop='72px'>
                  {chosenAreaOrLine?.id && (
                    <div style={styles.image}>
                      <CameraImageDraw width={300} points={chosenAreaOrLine.points} editable={false} src={snapshot ? snapshotSrc : NoImage} />
                    </div>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </StepperModalBarrierContainer>
      <StepperModalActionsButtonContainer style={{paddingTop: '64px'}}>
        <Button fontSize='12px' height='40px' width='150px' color='#8E8E8E' variant='outlined' onClick={() => returnFunction(0)}>
          Voltar
        </Button>
        <Button fontSize='12px' height='40px' disabledTextColor='#8E8E8E' width='150px' disabled={!chosenAreaOrLine} onClick={() => handleNextFunction()}>
          Próximo
        </Button>
      </StepperModalActionsButtonContainer>
    </>
  )
}

export default ChooseBarrierStep

export const styles = createStyleSheet({
  image: {
    height: '100%',
    maxHeight: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
  },
})
