import {Box, Card, CardContent, CircularProgress, Typography} from '@mui/material'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {BorderLinearProgress} from './BorderLinearProgress'

type HitCounterProps = {
  title: string
  value: number
  loading: boolean
  menCount: number
  womanCount: number
  height?: string
}

function HitCounter({title, value, loading, menCount, womanCount, height}: HitCounterProps) {
  return (
    <Card sx={[styles.card, {height: height}]} variant='outlined'>
      <CardContent>
        <Typography style={styles.title}>{title}</Typography>
        <Typography style={styles.value}>{loading ? <CircularProgress /> : `${value}%`}</Typography>

        <Box>
          <Box style={styles.labels}>
            <Typography style={styles.typographyLabels} sx={{color: '#364152'}}>
              Homens
            </Typography>
            <Typography style={styles.typographyLabels} sx={{color: '#009EFF'}}>
              {menCount.toFixed(0)}%
            </Typography>
          </Box>

          <BorderLinearProgress variant='determinate' value={menCount} />
        </Box>

        <Box sx={{paddingTop: '15px'}}>
          <Box style={styles.labels}>
            <Typography style={styles.typographyLabels} sx={{color: '#364152'}}>
              Mulheres
            </Typography>
            <Typography style={styles.typographyLabels} sx={{color: '#9c27b0'}}>
              {womanCount.toFixed(0)}%
            </Typography>
          </Box>

          <BorderLinearProgress variant='determinate' value={womanCount} color='secondary' />
        </Box>
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  card: {
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '2px 10px',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#364152',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontSize: '16px',
    textAlign: 'center',
  },
  value: {
    margin: '0px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '2.5rem',
    color: '#697586',
    fontWeight: 700,
    textAlign: 'center',
    padding: '10px',
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  typographyLabels: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
})

export default HitCounter
