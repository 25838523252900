import {Box} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import Loading from 'src/components/Loading'
import {useAuth} from 'src/contexts/AuthContext'
import {useCompany} from 'src/contexts/CompanyContext'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api, {CompaniesReleaseNotes, ReleasesNotes, RolesReleaseNotes, UserViews} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isNumber} from 'src/utilities/regexValidation'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import TopBar from '../../components/top-bar/TopBar'
import NotificationList from './components/NotificationList'
import NotificationRegisterModal from './components/NotificationRegisterModal'

const ReleaseNotes = () => {
  const {user} = useAuth()
  const hasCreateNotificationPermission = ValidatePermissions('notifications:create')
  const initialReleaseNote = {id: 0, title: '', subtitle: '', description: '', createdAt: '', companies: [], roles: []}
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [wasViewed, setWasViewed] = useState<UserViews | null>(null)
  const [selectedByUrl, setSelectedByUrl] = useState<number | null>(null)
  const [releaseNote, setReleaseNote] = useState<ReleasesNotes>(initialReleaseNote)
  const [companiesReleaseNotes, setCompaniesReleaseNotes] = useState<CompaniesReleaseNotes[]>([])
  const [companies, setCompanies] = useState<CompaniesReleaseNotes[]>([])
  const [roles, setRoles] = useState<RolesReleaseNotes[]>([])
  const [rolesReleaseNotes, setRolesReleaseNotes] = useState<RolesReleaseNotes[]>([])
  const {enqueueSnackbar} = useSnackbar()
  const [notifications, setNotifications] = useState<ReleasesNotes[]>([])
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const {setUpdateNotifications} = useApp()
  const {selectedCompanies} = useCompany()
  const blockRequests = useRef(false)

  const scrollToNotification = useCallback(() => {
    const idFromUrl = searchParams.get('id')
    if (isNumber(String(idFromUrl))) {
      setSelectedByUrl(Number(idFromUrl))
      const selectedElement = document.getElementById(idFromUrl!)
      if (selectedElement) selectedElement!.scrollIntoView()
    }
  }, [searchParams])

  const createReleasesViews = async (notification: ReleasesNotes) => {
    try {
      const response = await api.transaction.create(notification.id)
      setWasViewed(response.data.data)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar novas visualizações')
    }
  }

  useEffect(() => {
    getNewReleases()
  }, [wasViewed, selectedCompanies])

  useEffect(() => {
    scrollToNotification()
  }, [scrollToNotification])

  const getNewReleases = async () => {
    if (blockRequests.current) return
    blockRequests.current = true
    setLoadingList(true)
    try {
      const response = await api.transaction.getAll({
        page: 1,
        pageSize: 100,
        orderBy: {createdAt: 'DESC'},
        filter: {companyIds: selectedCompanies},
      })
      setNotifications(response.data.data.entities)
      setCompaniesReleaseNotes(response.data.data.entities.flatMap((it) => it.companies))
      setRolesReleaseNotes(response.data.data.entities.flatMap((it) => it.roles))
      scrollToNotification()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar novas atualizações')
    } finally {
      setLoadingList(false)
      blockRequests.current = false
    }
  }

  const deleteReleaseNote = async (id: number) => {
    try {
      await api.transaction.deleteNote(id)
      enqueueSnackbar('Notificação removida com sucesso', {
        variant: 'success',
      })
      getNewReleases()
      setUpdateNotifications((prev) => !prev)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover notificação')
    }
  }

  function openEditModal(notes: ReleasesNotes) {
    setIsCreate(false)
    setOpen(true)
    setReleaseNote(notes)
    setCompanies(companiesReleaseNotes.filter((it) => it.releaseNoteId === notes.id))
    setRoles(rolesReleaseNotes.filter((it) => it.releaseNoteId === notes.id))
  }

  return (
    <Box sx={styles.paper}>
      {hasCreateNotificationPermission && (
        <TopBar>
          <Box sx={{display: 'flex', width: '100%'}}>
            <AppAddOrOptionsButton
              text='Adicionar'
              onClickAdd={() => {
                setIsCreate(true)
                setOpen(true)
                navigate('/notifications')
              }}
            />
          </Box>
        </TopBar>
      )}
      {notifications.length !== 0 ? (
        <Box sx={user?.role === 'admin' ? styles.gridItem : styles.gridItem2}>
          {notifications.map((it) => {
            return (
              <NotificationList
                key={it.id}
                defaultOpen={selectedByUrl ? selectedByUrl === it.id : false}
                notification={it}
                onEdit={openEditModal}
                onDelete={deleteReleaseNote}
                createReleasesViews={createReleasesViews}
              />
            )
          })}
        </Box>
      ) : loadingList ? (
        <Box sx={styles.notReleases}>
          <Loading />
        </Box>
      ) : (
        <Box sx={styles.notReleases}>Nenhuma notificação</Box>
      )}
      {open && (
        <NotificationRegisterModal
          releaseNote={releaseNote}
          setReleaseNote={setReleaseNote}
          loadingNotes={getNewReleases}
          isCreate={isCreate}
          companiesReleaseNotes={companies}
          rolesReleaseNotes={roles}
          open={open}
          handleClose={() => {
            if (isCreate) setReleaseNote(initialReleaseNote)
            setOpen(false)
          }}
        />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: 1.5,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '77vh',
    overflow: 'scroll',
  },
  gridItem2: {
    display: 'flex',
    flexDirection: 'column',
    height: '82vh',
    overflow: 'scroll',
  },
  notReleases: {
    fontSize: '1rem',
    justifyContent: 'center',
    height: '77vh',
    alignItems: 'center',
    display: 'flex',
  },
})

export default ReleaseNotes
