import _axios, {Axios} from 'axios'
import config from '../config'

let count = 0

function getRequestResult(response: any) {
  const method = response.config.method.toUpperCase()
  const baseUrl = response.config.baseURL || ''
  const url = response.config.url.startsWith('http') ? response.config.url : baseUrl + response.config.url
  const params = response.config.params ?? {}
  const {status, statusText, data} = response
  count++
  return {count, method, url, params, status, statusText, data}
}

export function injectAxiosDebug(axios: Axios): void {
  if (!config.debugApi) return

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (_axios.isAxiosError(error) && error.response?.config) {
        console.error(getRequestResult(error.response))
      } else {
        console.error('Unknown ERROR', error)
      }
      throw error
    },
  )
}
