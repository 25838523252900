import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Ord, Paginate} from './_types'

const prefix = process.env.REACT_APP_COMPANIES_BASE_URL

type layoutIncludes = 'cameras'

export type Layout = {
  id: number
  name: string
  maxCameras: number
  cameras?: vincLayout[]
  companyId?: number
  clientId?: number
  layoutTypeId: number
  isClientDefault?: boolean
}

export type vincLayout = {
  id: number
  cameraId: number
  layoutId: number
}

export type CreateLayout = {
  name: string
  maxCameras: number
  layoutTypeId: number
  cameraIds: number[]
  clientId?: number
  isClientDefault?: boolean
  companyId?: number
}

export type UpdateLayout = {
  name?: string
  maxCameras?: number
  clientId?: number
  isClientDefault?: boolean
  companyId?: number
}

export type UpdateCameraLayout = {
  layoutCameras: {
    adding: number[]
  }
}

export type GetLayoutsParams = {
  page?: number
  pageSize?: number
  search?: {
    layoutName?: string
  }
  filter?: {
    companyIds?: number[]
    ids?: number[]
    clientIds?: number[]
  }
  includes?: layoutIncludes[]
  orderBy?: {
    layoutType: Ord
    id?: Ord
    layoutName?: Ord
  }
}

export class LayoutEndpoint {
  async getMany(params: GetLayoutsParams): Promise<AxiosResult<Paginate<Layout>>> {
    return await axios.get(`${prefix}/layout`, {
      params: prepareParams(params),
    })
  }

  async create(body: CreateLayout): Promise<AxiosResult<CreateLayout>> {
    return await axios.post(`${prefix}/layout`, body)
  }

  async update(id: number, body: UpdateLayout): Promise<AxiosResult<UpdateLayout>> {
    return await axios.patch(`${prefix}/layout/${id}`, body)
  }

  async updateCameras(id: number, body: UpdateCameraLayout): Promise<AxiosResult<UpdateLayout>> {
    return await axios.patch(`${prefix}/layout/${id}/cameras`, body)
  }

  async delete(ids: number[]): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/layout`, {data: {ids}})
  }
}
