import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {EnumRecurrence, useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import PlanCard from './components/PlanCard/PlanCard'
import {CardContainer} from './components/PlanCard/PlanCardBase'
import {Container, Subtitle, Title} from './components/PlanSelectBase'
import RecurrenceButton from './components/RecurrenceButton/RecurrenceButton'
import Loading from 'src/components/Loading'

function PlanSelect() {
  const {selectedRecurrence, setSelectedRecurrence, plans, getPlans, setSelectedPlan, isLoading} = useB2CContext()
  const navigate = useNavigate()

  useEffect(() => {
    getPlans()
  }, [])

  const customPlan = {
    id: -1,
    name: 'Personalizado',
    price: selectedRecurrence === EnumRecurrence.MONTHLY ? 20 : 220,
    recurrence: EnumRecurrence.MONTHLY,
    description: '5 câmeras ou mais',
  }

  return (
    <Container>
      <Title>
        Escolha o melhor plano <br /> para sua segurança
      </Title>
      <Subtitle>Sem compromisso, cancele quando quiser</Subtitle>
      {isLoading ? (
        <CardContainer>
          <Loading color={'#258BE3'} width='50px' height='50px' />
        </CardContainer>
      ) : (
        plans.length > 0 && (
          <>
            <RecurrenceButton selected={selectedRecurrence} setSelected={setSelectedRecurrence} />
            <CardContainer>
              {plans
                .filter((plan) => plan.recurrence === selectedRecurrence)
                .sort((a, b) => a.price - b.price)
                .map((plan) => (
                  <PlanCard
                    isDefaultPlan={plan.description === '4 Câmeras'}
                    plan={plan}
                    recurrency={selectedRecurrence}
                    onClick={() => {
                      setSelectedPlan(plan)
                      navigate('../payment-method')
                    }}
                  />
                ))}
              <PlanCard plan={customPlan} recurrency={selectedRecurrence} onClick={() => navigate('/status?type=custom')} />
            </CardContainer>
          </>
        )
      )}
    </Container>
  )
}

export default PlanSelect
