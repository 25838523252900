export const CancelButtonModal = {
  width: '150px',
  height: '40px',
  marginRight: 2.5,
  marginBottom: 2,
  border: '1px solid',
  fontFamily: 'Inter',
  boxShadow: 'none',
  textTransform: 'none',
}
export const AddButtonModal = {
  width: '150px',
  height: '40px',
  fontFamily: 'Inter',
  boxShadow: 'none',
  textTransform: 'none',
  marginRight: 2,
  marginBottom: 2,
}
