import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_CAMERAS_BASE_URL

export type ScheduleByCameraParams = {
  cameraId: number
}

export type Schedule = {
  id: number
  startTime: string
  endTime: string
  cameraConfigurationId: number
  scheduleHasDays: Array<{
    dayOfWeekId: number
    scheduleId: number
    id: number
  }>
}

type AddSchedule = {
  start: string
  end: string
  cameraId: number
  daysSchedule: Array<{dayOfWeekId: number}>
}

export class ScheduleEndpoint {
  getByCamera(params: ScheduleByCameraParams): Promise<AxiosResult<Schedule[]>> {
    return axios.get(`${prefix}/schedule/camera/`, {params})
  }

  addSchedule(body: AddSchedule): Promise<AxiosResult<any>> {
    return axios.post(`${prefix}/schedule`, body)
  }

  deleteSchedule(id: number): Promise<AxiosResult<any>> {
    return axios.delete(`${prefix}/schedule/${id}`)
  }
}
