import {Link} from 'react-router-dom'
import {Box} from '@viptech/react-components'
import {useBrainBoxContext} from 'src/pages/brain-box/context/BrainBoxContext'

type BrainBoxInstructionsProps = {
  isFullHd: boolean
  userName: string
  companyName: string
  brainBoxCode: string
}

const phoneNumber = '4498590808'

function BrainBoxInstructions({isFullHd, userName, companyName, brainBoxCode}: BrainBoxInstructionsProps) {
  const {generateQrCode} = useBrainBoxContext()
  const message = `Olá, sou ${userName} da empresa ${companyName} e preciso de ajuda com a minha Brain box.\nO código da minha Brain box é ${brainBoxCode}`

  return (
    <Box width='100%' overflow='auto'>
      <Box fontSize='24px' fontWeight='500' paddingBottom={isFullHd ? '24px' : '12px'}>
        Instruções para configurar a box
      </Box>
      <Box paddingLeft='32px'>
        <Box display='flex' direction='column' rowGap={isFullHd ? '42px' : '24px'} paddingBottom={isFullHd ? '24px' : '12px'} overflow='auto'>
          <Box>1. Verifique se o dispositivo se encontra contectado à energia.</Box>
          <Box>2. Segure o botão de reset por 10 segundos, o LED verde deverá piscar 3 vezes.</Box>
          <Box>
            3. Caso o LED vermelho não esteja aceso repita o passo 2. Caso não resolva:{' '}
            <Link to={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`} target='_blank' rel='noopener noreferrer'>
              contate o suporte.
            </Link>
          </Box>
          <Box>
            4. Conecte-se à rede Wi-Fi da box. Clique aqui para ver{' '}
            <Link to='https://rmelocaviptech.notion.site/rmelocaviptech/Brain-Box-66f6eaa70a8742a98b5c00673b8146bd' target='_blank' rel='noopener noreferrer'>
              como se conectar.
            </Link>
          </Box>
          <Box>5. Leia o QR Code abaixo usando o mesmo dispositivo conectado à box:</Box>
        </Box>
        <Box display='flex' justify='center' align='center' paddingBottom={isFullHd ? '42px' : '24px'}>
          <img src={generateQrCode(isFullHd ? 300 : 200)} alt='QRCode' />
        </Box>
        <Box display='flex' justify='center' align='center' color='#A77800'>
          Não finalize antes de ter realizado todos os passos.
        </Box>
      </Box>
    </Box>
  )
}

export default BrainBoxInstructions
