import {AreaData} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import {AddAreaItem} from 'src/common/types/AddAreaItem'
import {Dim} from 'src/common/types/generics/ImageDetections'
import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import mkKey from 'src/utilities/mkKey'

export enum TypeDetectionActionsEnum {
  EDIT_INTEREST_AREA = 'edit-interest-area',
  DELETE_INTEREST_AREA = 'delete-interest-area',
  ALL_OBJECT_CLICKED = 'all-object-clicked',
  OBJECT_CLICKED = 'object-clicked',
  NEW_INTEREST_AREA = 'new-interest-area',
}

export async function addInterestArea(
  id: number,
  areaItem: AddAreaItem,
  areaItems: AreaData[],
  _dimImage: Dim,
  enqueueSnackbar: any,
  loadInterestAreas: () => void,
) {
  const areaDataList = areaItems.concat([
    {
      key: mkKey(),
      label: areaItem.name,
      points: areaItem.points,
      data: {adding: true},
    },
  ])

  const newAreas = areaDataList.filter((it) => it.data?.adding)
  const areasCreatedMemory = []
  for (const area of newAreas) {
    try {
      const res = await api.interestArea.addInterestArea({
        cameraId: id,
        coordinates: area.points.map((it) => ({
          x: Math.round(it.x) / _dimImage.width,
          y: Math.round(it.y) / _dimImage.height,
        })),
        name: area.label,
      })
      areasCreatedMemory.push(res.data.data)
      enqueueSnackbar('Área salva com sucesso', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao salvar a área')
    } finally {
      loadInterestAreas()
    }
  }

  return areasCreatedMemory
}

export async function editInterestArea(areaItem: AreaData, areaItems: AreaData[], _dimImage: Dim, enqueueSnackbar: any, loadInterestAreas: () => void) {
  const editAreaItem = {
    ...areaItem,
    data: {...areaItem.data, updated: true},
  }
  const areaDataList = areaItems.map((it) => (it.key === areaItem.key ? editAreaItem : it))
  const updatingAreas = areaDataList.filter((it) => it.data?.updated)

  for (const area of updatingAreas) {
    const interestAreaId = area.data?.area?.id
    try {
      await api.interestArea.updateInterestArea({
        coordinates: area.points.map((it) => ({
          x: Math.round(it.x) / _dimImage.width,
          y: Math.round(it.y) / _dimImage.height,
        })),
        name: area.label,
        id: interestAreaId!,
      })
      enqueueSnackbar('Área atualizada com sucesso', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar área')
    } finally {
      loadInterestAreas()
    }
  }
}

export async function deleteLineCrossing(lineCrossingId: number, enqueueSnackbar: any) {
  try {
    await api.lineCrossing.deleteLineCrossing(lineCrossingId)
    enqueueSnackbar('Cruzamento de linha removido com sucesso', {
      variant: 'success',
    })
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover cruzamento de linha')
  }
}

export async function deleteInterestArea(
  areaItem: AreaData,
  areaItems: AreaData[],
  updateAreaItems: (areaItems: AreaData[]) => void,
  enqueueSnackbar: any,
  loadInterestAreas: () => void,
) {
  updateAreaItems(areaItems.filter((it) => it.key !== areaItem.key))

  try {
    await api.interestArea.removeInterestArea(areaItem.data!.area!.id)
    enqueueSnackbar('Área removida com sucesso', {
      variant: 'success',
    })
    loadInterestAreas()
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover área')
  }
}
