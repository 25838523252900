import CenterFocusIcon from '@mui/icons-material/CenterFocusWeak'
import CloseIcon from '@mui/icons-material/Close'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import {Backdrop, Box, Button, Dialog, Grid, Tooltip} from '@mui/material'
import {useEffect, useReducer} from 'react'
import {KonvaImageDetections} from 'src/common/components/konva-image-detections/KonvaImageDetections'
import {State} from 'src/common/types/State'
import {Detection} from 'src/common/types/generics/ImageDetections'
import {useDebounce, useWindowSize} from 'usehooks-ts'
import {kovaImageDetectionFullscreenStyles} from './KonvaImageDetectionsFullscreenBase'
import {DEFAULT_INITIAL_ZOOM, DEFAULT_ZOOMS, INITIAL_STATE, reducer} from './KonvaImageDetectionsFullscreenFunctions'

type KonvaImageDetectionsFullscreenProps = {
  open: boolean
  onClose: () => void
  initialZoomIdx?: number
  typeDemarcatedArea?: string
  zooms?: number[]
  detections: Detection[]
  src: string
}

export function KonvaImageDetectionsFullscreen(konvaImageDetectionsFullscreenProps: KonvaImageDetectionsFullscreenProps) {
  const {open, onClose, src, detections, initialZoomIdx = DEFAULT_INITIAL_ZOOM, zooms = DEFAULT_ZOOMS, typeDemarcatedArea} = konvaImageDetectionsFullscreenProps
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (initialState: State) => ({
    ...initialState,
    zoomIdx: initialZoomIdx,
    zoomTotal: zooms.length - 1,
  }))
  const {hideDetections, zoomIdx, translate, key} = state
  const zoom = zooms[zoomIdx]
  const windowSize = useWindowSize()
  const zoomDebounced = useDebounce(zoom, 250)

  const _onClose = () => {
    dispatch({type: 'reset'})
    onClose()
  }

  useEffect(() => {
    function onMouseDown() {
      dispatch({type: 'mouseDown'})
    }
    function onMouseUp() {
      dispatch({type: 'mouseUp'})
    }
    function onMouseMove(e: MouseEvent) {
      dispatch({type: 'mouseMove', payload: {x: e.x, y: e.y}})
    }
    function onKeyPress(e: KeyboardEvent) {
      dispatch({
        type: 'keyPress',
        payload: {key: e.key.toLowerCase(), shift: e.shiftKey},
      })
    }

    window.addEventListener('keydown', onKeyPress)
    window.addEventListener('mousedown', onMouseDown)
    window.addEventListener('mousemove', onMouseMove)
    window.addEventListener('mouseup', onMouseUp)
    return () => {
      window.removeEventListener('keypress', onKeyPress)
      window.removeEventListener('mousedown', onMouseDown)
      window.removeEventListener('mousemove', onMouseMove)
      window.removeEventListener('mouseup', onMouseUp)
    }
  }, [])

  useEffect(() => {
    if (key === 'x') _onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  return (
    <Dialog open={open}>
      <Backdrop open={open} sx={kovaImageDetectionFullscreenStyles.container}>
        <Box
          sx={{
            transform: `translate(${translate.x}px, ${translate.y}px)`,
          }}>
          <KonvaImageDetections
            src={src}
            detections={detections}
            width={windowSize.width}
            height={windowSize.height}
            scale={zoomDebounced}
            hideDetections={hideDetections}
            typeDemarcatedArea={typeDemarcatedArea}
          />
        </Box>
        <Box sx={kovaImageDetectionFullscreenStyles.toolsContainer}>
          <Grid sx={kovaImageDetectionFullscreenStyles.toolsGrid} container direction='column'>
            <Grid item sx={[kovaImageDetectionFullscreenStyles.centerItem, {pt: 0}]}>
              <Tooltip title='Fechar (x)' placement='left'>
                <Button variant='outlined' sx={kovaImageDetectionFullscreenStyles.button} onClick={onClose}>
                  <CloseIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sx={kovaImageDetectionFullscreenStyles.centerItem}>
              <Tooltip title='Resetar zoom (r)' placement='left'>
                <Button variant='outlined' onClick={() => dispatch({type: 'resetZoom'})} sx={kovaImageDetectionFullscreenStyles.button}>
                  <CenterFocusIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sx={kovaImageDetectionFullscreenStyles.centerItem}>
              <Tooltip title='+ zoom (q)' placement='left'>
                <Button variant='outlined' onClick={() => dispatch({type: 'zoomIn'})} sx={kovaImageDetectionFullscreenStyles.button}>
                  <ZoomInIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sx={kovaImageDetectionFullscreenStyles.centerItem}>
              <Tooltip title='- zoom (e)' placement='left'>
                <Button variant='outlined' onClick={() => dispatch({type: 'zoomOut'})} sx={kovaImageDetectionFullscreenStyles.button}>
                  <ZoomOutIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item sx={kovaImageDetectionFullscreenStyles.centerItem}>
              <Tooltip title='Esconder detecções (f)' placement='left'>
                <Button variant='outlined' onClick={() => dispatch({type: 'toggleHideDetections'})} sx={kovaImageDetectionFullscreenStyles.button}>
                  {hideDetections ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Backdrop>
    </Dialog>
  )
}
