import styled from 'styled-components'
import {DayPickerStyleProps} from './DayPickerProps'

export const DayPickerCalendarGrid = styled.div<DayPickerStyleProps>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
`

export const DayPickerDay = styled.div<DayPickerStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
  height: 2rem;
  width: 100%;
  border-radius: ${({checkOne, checkTwo}) => (checkOne ? '5px 0 0 5px' : checkTwo ? '0 5px 5px 0 ' : 0)};
  border-radius: ${({checkOne, checkTwo, isSelected}) => checkOne && checkTwo && isSelected && '5px'};

  background-color: ${({isSelected, theme, checkOne, checkTwo}) =>
    checkOne || checkTwo ? theme.calendar.selectedDayBackgroundColor : isSelected ? '#E7EFFF' : theme.calendar.backgroundColor};
  color: ${({theme, checkOne, checkTwo}) => (checkOne || checkTwo ? theme.calendar.selectedDayColor : theme.calendar.color)};

  ${({isToday, isSelected, theme}) => isToday && !isSelected && `border: 1px solid ${theme.calendar.actualDateBorder}`};
  background-color: ${({isCurrentMonth, isSelected, theme}) => !isSelected && !isCurrentMonth && theme.calendar.notActualMonthBackgroundColor};
  color: ${({isCurrentMonth, isSelected, theme}) => !isSelected && !isCurrentMonth && theme.calendar.notActualMonthColor};
  font-family: ${({theme}) => theme.calendar.fontFamily};
  font-size: ${({theme}) => theme.calendar.fontSize};
  font-weight: ${({isToday, theme}) => (isToday ? 'bold' : theme.calendar.fontWeight)};

  ${({isToday, isSelected, theme}) => isToday && !isSelected && `border: 1px solid ${theme.calendar.actualDateBorder};`};

  ${({isToday, isSelected, theme}) => isToday && !isSelected && `color: ${theme.calendar.actualDateColor};`};
  ${({isToday, checkOne, checkTwo}) => isToday && (checkOne || checkTwo) && `color: white;`};

  ${({disabled, theme}) => disabled && `background-color: ${theme.calendar.disabledBackgroundColor}`};
  ${({disabled, theme}) => disabled && `color: ${theme.calendar.disabledColor}`};

  :hover {
    background-color: ${({theme, isSelected, disabled}) => !disabled && !isSelected && theme.calendar.hoverBackgroundColor};
    color: ${({theme, isSelected, disabled}) => !disabled && !isSelected && theme.calendar.hoverColor};
  }
`
export const DayPickerWeekdaysContainer = styled.div`
  display: flex;
  grid-gap: 0rem 0.5rem;
  margin-bottom: 0.5rem;
  align-items: center;
`

export const DayPickerWeekday = styled.div`
  flex: 1;
  text-align: center;
  color: #344054;
  font-weight: bold;
  font-size: 12px;
`
