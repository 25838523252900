import styled from 'styled-components'

export const ContainerColumn = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: 'center';
  align-items: 'center';
  gap: 10px;
  padding-top: 35px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
