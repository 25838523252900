import CloseIcon from '@mui/icons-material/Close'
import {IconButton} from '@mui/material'
import {useSnackbar} from 'notistack'

function SnackbarCloseButton({key}: any) {
  const {closeSnackbar} = useSnackbar()

  return (
    <IconButton sx={{color: 'white'}} onClick={() => closeSnackbar(key)} key={key}>
      <CloseIcon fontSize='small' key={key} />
    </IconButton>
  )
}

export default SnackbarCloseButton
