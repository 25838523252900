import {Box} from '@mui/material'
import config from 'src/services/config'
import createStyleSheet from 'src/utilities/createStyleSheet'

type AppVersionProps = {
  color?: string
  sideBar?: boolean
  changePassword?: boolean
}

function AppVersion({color, sideBar, changePassword}: AppVersionProps) {
  return (
    <Box sx={[{color: color}, sideBar === true ? styles.versionSideBar : styles.version, changePassword === true ? styles.versionChangePassword : {}]}>
      Versão: {config.version}
    </Box>
  )
}

const styles = createStyleSheet({
  version: {
    fontSize: 9,
    position: 'absolute',
    bottom: '2em',
    left: '2em',
  },
  versionSideBar: {
    fontSize: 9,
  },
  versionChangePassword: {
    fontSize: 9,
    position: 'absolute',
    bottom: '2em',
    left: '2em',
  },
})

export default AppVersion
