import {Box, Button, Checkbox, Typography} from '@viptech/react-components'
import {enqueueSnackbar} from 'notistack'
import {useEffect, useMemo, useRef, useState} from 'react'
import Slider from 'src/components/slider'
import {getLabelList} from '../../../../../../../../common/components/camera-register-object/components/object-selector/ObjectsSelectorFunctions'
import Dropdown from '../../../../../../../../components/dropdown/Dropdown'
import {DropdownItem} from '../../../../../../../../components/dropdown/DropdownProps'
import {Input} from '../../../../../../../../components/input/Input'
import {ObjectType} from '../../../../../../../../services/api'
import {ObjectDetectionProps} from '../../../../ObjectDetection'
import {StepperModalActionsButtonContainer} from '../../../styles'
import {ChosenObject} from '../../StepperModal'
import Card from '../../common/components/Card'
import {skipFourthStep, skipSecondStep} from '../Constants'
import {ContainerColumn, Grid, LabelsSlider} from './ConfigStepBase'

type ConfigStepProps = {
  nextFunction: (index: number, resume: string) => void
  returnFunction: (index: number) => void
  setChosen: (choices: Partial<ChosenObject>) => void
  updateTable: () => void
  chosen: ChosenObject | null
  finishFunction: () => void
  registeredObjects: ObjectDetectionProps[]
  allObjects: ObjectType[]
  isEdit: boolean
}

const ConfigStep = (props: ConfigStepProps) => {
  const {nextFunction, returnFunction, updateTable, chosen, setChosen, finishFunction, registeredObjects, allObjects, isEdit} = props

  const [valueSlider, setValueSlider] = useState<number | null>(null)
  const [selectedCard, setSelectedCard] = useState({id: '', label: ''})
  const [items, setItems] = useState<DropdownItem[]>([])

  const amountOfDetection = ['1', '2', '3', '4', '5']
  const [amountOfDetectionSelected, setAmountOfDetectionSelected] = useState(chosen?.threshold?.toString() ?? '')

  const skipSecond = useMemo(() => {
    return skipSecondStep.includes(chosen?.detectionTypeChosen!)
  }, [chosen])

  const skipFourth = useMemo(() => {
    return skipFourthStep.includes(chosen?.detectionTypeChosen!)
  }, [chosen])

  const skipSecondAndFourth = useMemo(() => {
    return skipSecond && skipFourth
  }, [skipFourth, skipSecond])

  const [createMultipleAction, setCreateMultipleAction] = useState(!skipFourth)
  const showDropdownObjects = useRef(true)

  const [showThresholdInput, setShowThresholdInput] = useState(false)

  useEffect(() => {
    if (chosen?.confidence) {
      setValueSlider(chosen.confidence * 100)
    }
    if (chosen?.threshold) {
      if (chosen.threshold > 5) setShowThresholdInput(true)
      setAmountOfDetectionSelected(chosen.threshold.toString())
    }
  }, [chosen])

  useEffect(() => {
    const itemsToShowOnDropdown = () => {
      if (chosen && chosen!.detectionTypeChosen) {
        const itemsLabels = getLabelList(chosen!.detectionTypeChosen).includes ?? []
        const filteredItems = allObjects.filter((it) => itemsLabels.includes(it.label)).map((it) => ({id: String(it.id), label: it.label}))
        if (!filteredItems.length) {
          showDropdownObjects.current = false
          setSelectedCard({id: `${chosen?.objectTypeId!}`, label: `${chosen?.detectionTypeChosen}`})
        }
        return filteredItems
      }
      return []
    }
    setItems(itemsToShowOnDropdown())
  }, [chosen])

  const labelNextButton = () => {
    return createMultipleAction ? 'Próximo' : 'Finalizar'
  }

  const functionNextButton = () => {
    if (createMultipleAction) return nextFunction(3, 'Concluído')

    finishFunction()
    updateTable()
  }

  const allowGoNextFunction = () => {
    if (chosen?.detectionTypeChosen === 'Aglomeração') return false
    else if (chosen?.detectionTypeChosen === 'Pessoa' && amountOfDetectionSelected) return false
    else if (amountOfDetectionSelected && selectedCard.id && chosen?.detectionTypeChosen !== 'Aglomeração') return false
    return true
  }

  useEffect(() => {
    if (amountOfDetectionSelected && valueSlider) {
      setChosen({
        threshold: +amountOfDetectionSelected,
        confidence: valueSlider / 100,
      })
    }
  }, [amountOfDetectionSelected, valueSlider])

  return (
    <Box display='flex' direction='column' flexWrap='wrap' justify='flex-end'>
      <Box padding='20px' display='flex' direction='column' rowGap='25px'>
        <Grid>
          {showDropdownObjects.current && chosen && (
            <Box flex={1} align='center' justifyContent='center'>
              <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                Escolha o tipo de {chosen!.detectionTypeChosen?.toLowerCase()}
              </Typography>
              <Typography variant='p' size='12px' color='#8E8E8E'>
                Escolha abaixo o tipo de {chosen!.detectionTypeChosen?.toLowerCase()} que deseja detectar
              </Typography>

              <Box paddingTop='20px'>
                <Dropdown
                  height='40px'
                  fontSize='12px'
                  selected={selectedCard}
                  placeholder={chosen!.detectionTypeChosen}
                  items={items}
                  onChangeSelected={(e) => {
                    const registeredAreaIds = registeredObjects.map((it) => it.interestArea.id)
                    const registeredObjectIds = registeredObjects.map((it) => it.objectType.id)
                    const alreadyHaveThisResourceRegistered = registeredObjectIds.includes(+e.id)
                    const alreadyHaveThisAreaRegistered = registeredAreaIds.includes(chosen?.interestAreaId!)
                    if (alreadyHaveThisAreaRegistered && alreadyHaveThisResourceRegistered) {
                      enqueueSnackbar('Já existe uma detecção desse objeto nessa área, por favor selecione outra', {variant: 'warning'})
                    } else {
                      setSelectedCard(e)
                      setChosen({
                        objectTypeId: +e.id,
                      })
                    }
                  }}
                />
              </Box>
            </Box>
          )}

          {chosen?.detectionTypeChosen !== 'Relatório de Contagem' && chosen && (
            <Box>
              <Box align='center' justifyContent='center'>
                {chosen?.detectionTypeChosen === 'Aglomeração' ? (
                  <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                    Personalize sua aglomeração
                  </Typography>
                ) : (
                  <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                    Disparar após quantas detecções?
                  </Typography>
                )}
                {chosen?.detectionTypeChosen === 'Aglomeração' ? (
                  <Typography variant='p' size='12px' color='#8E8E8E'>
                    Informe a quantidade mínima de pessoas necessárias para que ocorra o disparo. Escolha o número de pessoas que configuram uma aglomeração.
                  </Typography>
                ) : (
                  <Typography variant='p' size='12px' color='#8E8E8E'>
                    Informe a quantidade que precisa ser detectada para ocorrer o disparo
                  </Typography>
                )}

                <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                  <ContainerColumn>
                    {amountOfDetection.map((it) => {
                      return (
                        <Card
                          label={it}
                          selected={amountOfDetectionSelected === it}
                          initialState={!amountOfDetectionSelected}
                          onClick={setAmountOfDetectionSelected}
                          key={it}
                        />
                      )
                    })}
                  </ContainerColumn>
                  {!showThresholdInput ? (
                    <Button
                      fontSize='12px'
                      height='40px'
                      width='150px'
                      variant='outlined'
                      color='#009EFF'
                      marginTop='18px'
                      onClick={() => setShowThresholdInput(true)}>
                      Digitar quantidade
                    </Button>
                  ) : (
                    <Input.Root
                      height='40px'
                      width='150px'
                      labelFontSize='12px'
                      fontSize='12px'
                      labelColor='#344054'
                      marginTop='18px'
                      defaultValue={amountOfDetectionSelected}
                      value={amountOfDetectionSelected}
                      onChange={(e) => setAmountOfDetectionSelected(e.target.value)}
                      borderColor='#009EFF'
                      mask={(value) => {
                        if (+value <= 100 && value.match(/^-?([0-9]+)?$/)) return value
                        return amountOfDetectionSelected
                      }}
                    />
                  )}
                </div>
              </Box>
            </Box>
          )}
        </Grid>

        <Box>
          <Typography variant='span' size='16px' style={{fontWeight: 500}}>
            Nível de confiança
          </Typography>
          <Typography variant='p' size='12px' color='#8E8E8E'>
            Escolha abaixo o nível de sensibilidade para detecção de {chosen && chosen!.detectionTypeChosen?.toLowerCase()}. Uma sensibilidade muito alta irá
            disparar menos eventos, e um nível muito baixo irá disparar muitos eventos, podendo resultar em falsos positivos.
          </Typography>
          {valueSlider && (
            <Slider
              background='linear-gradient(90deg, #FFA800, #009D10, #FFA800)'
              max={100}
              min={1}
              width='100%'
              bubble={true}
              value={valueSlider}
              setValue={setValueSlider}
            />
          )}
          <Box display='flex' justifyContent='space-between' align='center'>
            <LabelsSlider>Baixo demais</LabelsSlider>
            <LabelsSlider>Ideal</LabelsSlider>
            <LabelsSlider>Alto demais</LabelsSlider>
          </Box>
        </Box>

        <Box display='flex' direction='column' marginRight='24px'>
          {!skipFourth && (
            <>
              <Checkbox
                labelSize='14px'
                label='Desejo criar uma ação de saída'
                checked={createMultipleAction}
                onChange={() => setCreateMultipleAction(!createMultipleAction)}
              />
              <Typography variant='span' size='12px' color='#8E8E8E'>
                Essa ação permite você selecionar o que deverá acontecer caso haja a detecção
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <StepperModalActionsButtonContainer>
        <Button
          disabled={isEdit || skipSecondAndFourth}
          fontSize='12px'
          height='40px'
          width='150px'
          variant='outlined'
          color='#8E8E8E'
          onClick={() => returnFunction(1)}>
          Voltar
        </Button>
        <Button fontSize='12px' disabledTextColor='#8E8E8E' height='40px' width='150px' onClick={() => functionNextButton()} disabled={allowGoNextFunction()}>
          {labelNextButton()}
        </Button>
      </StepperModalActionsButtonContainer>
    </Box>
  )
}

export default ConfigStep
