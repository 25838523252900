import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_OBJECTS_BASE_URL

export type Point = {
  x: number
  y: number
}

export type Coordinate = {
  id: number
  x: number
  y: number
  interestAreaId?: number
  lineCrossingId?: number
}

export type InterestArea = {
  id: number
  cameraConfigurationId: number
  coordinates: Coordinate[]
  name: string
}

export type GetInterestAreaParams = {
  cameraId: number
}

export type AddInterestArea = {
  cameraId: number
  coordinates: Point[]
  name: string
}

export type UpdateInterestArea = {
  coordinates: Point[]
  name?: string
  id: number
}

export class InterestAreaEndpoint {
  async getInterestAreas(params: GetInterestAreaParams): Promise<AxiosResult<InterestArea[]>> {
    return axios.get(`${prefix}/interest-area`, {params})
  }

  async addInterestArea(body: AddInterestArea): Promise<AxiosResult<InterestArea>> {
    return axios.post(`${prefix}/interest-area`, body)
  }

  async updateInterestArea(body: UpdateInterestArea): Promise<AxiosResult<InterestArea>> {
    return axios.patch(`${prefix}/interest-area`, body)
  }

  async removeInterestArea(interestAreaId: number): Promise<AxiosResult<any>> {
    return axios.delete(`${prefix}/interest-area/${interestAreaId}`)
  }
}
