import createStyleSheet from 'src/utilities/createStyleSheet'

export const konvaImageDrawToolbarStyles = createStyleSheet({
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    background: '#F2F4F7',
    borderRadius: '50px',
    alignItems: 'center',
    minWidth: '261px',
    maxWidth: '261px',
  },
  cancelButton: {
    width: '120px',
    height: '40px',
    marginRight: 1,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  addButton: {
    width: '120px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    background: '#009EFF',
  },
})
