import {AxiosError} from 'axios'
import {useEffect, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import api from 'src/services/api'
import {LicensePlateList} from 'src/services/api/endpoints/LprEndpoint'

type ListProps = {
  companyId: number
}

function useLists(props: ListProps) {
  const [lists, setLists] = useState<LicensePlateList[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError | null>(null)
  const hasListLprPermission = ValidatePermissions('lpr:read')

  async function getLists(replace: boolean = true) {
    setIsLoading(true)
    if (props.companyId === 0) return []
    try {
      let lpr: LicensePlateList[] = []
      if (hasListLprPermission) {
        lpr = (await api.lpr.getManyLists({page: 1, pageSize: 100, filter: {companyIds: [props.companyId]}})).data.data.entities
      }
      setLists(lpr)
      setError(null)
    } catch (err) {
      const error = err as AxiosError
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLists()
  }, [])

  return {lists, isLoading, error, refresh: getLists}
}

export default useLists
