import {useMemo} from 'react'

function useImage(src: any) {
  return useMemo(() => {
    const img = new Image()
    img.src = src
    return img
  }, [src])
}

export default useImage
