import styled from 'styled-components'

export const PaymentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 90px;
  position: relative;

  @media (max-width: 767px) {
    padding-top: 60px;
  }
`
