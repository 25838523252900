import {AnalyticCameraEndpoint} from './endpoints/AnalyticCameraEndpoint'
import {AuthEndpoint} from './endpoints/AuthEndpoint'
import {BrainBoxButtonControlEndpoint} from './endpoints/BrainBoxButtonsControl'
import {BrainBoxEndpoint} from './endpoints/BrainBoxEndpoint'
import {BrainBoxOutputDevices} from './endpoints/BrainBoxOutputEndpoint'
import {CameraEndpoint} from './endpoints/CameraEndpoint'
import {ClientEndpoint} from './endpoints/ClientEndpoint'
import {CompanyEndpoint} from './endpoints/CompanyEndpoint'
import {CompanyQuotaEndpoint} from './endpoints/CompanyQuotaEndpoint'
import {CrmIntegrationEndpoint} from './endpoints/CrmIntegrationEndpoint'
import {DguardEndpoint} from './endpoints/DguardEndpoint'
import {DigifortEndpoint} from './endpoints/DigifortEndpoint'
import {EventStatusEndpoint} from './endpoints/EventStatusEndpoint'
import {EventsEndpoint} from './endpoints/EventsEndpoint'
import {FacialRecognitionEndpoint} from './endpoints/FacialRecognitionEndpoint'
import {InterestAreaEndpoint} from './endpoints/InterestAreaEndpoint'
import {LayoutEndpoint} from './endpoints/LayoutEndpoint'
import {LineCrossingEndpoint} from './endpoints/LineCrossingEndpoint'
import {LprEndpoint} from './endpoints/LprEndpoint'
import {MonitoringEndpoint} from './endpoints/MonitoringEndpoint'
import {ObjectEndpoint} from './endpoints/ObjectEndpoint'
import {PaymentEndpoint} from './endpoints/PaymentEndpoint'
import {PlanEndpoint} from './endpoints/PlansEndpoint'
import {ReportsEndpoint} from './endpoints/ReportsEndpoint'
import {ScheduleEndpoint} from './endpoints/ScheduleEndpoint'
import {ServerEndpoint} from './endpoints/ServerEndpoint'
import {SnapshotIngesterEndpoint} from './endpoints/SnapshotIngesterEndpoint'
import {StreamingEndpoint} from './endpoints/StreamingEndpoint'
import {TransactionsEndpoint} from './endpoints/TransactionsEndpoint'
import {UsersEndpoint} from './endpoints/UserEndpoint'
import axios, {removeBearerToken, setBearerToken} from './endpoints/_axios'

const api = {
  analyticCamera: new AnalyticCameraEndpoint(),
  auth: new AuthEndpoint(),
  camera: new CameraEndpoint(),
  object: new ObjectEndpoint(),
  lineCrossing: new LineCrossingEndpoint(),
  client: new ClientEndpoint(),
  digifort: new DigifortEndpoint(),
  events: new EventsEndpoint(),
  eventStatus: new EventStatusEndpoint(),
  interestArea: new InterestAreaEndpoint(),
  reports: new ReportsEndpoint(),
  schedule: new ScheduleEndpoint(),
  server: new ServerEndpoint(),
  users: new UsersEndpoint(),
  company: new CompanyEndpoint(),
  dguard: new DguardEndpoint(),
  quota: new CompanyQuotaEndpoint(),
  transaction: new TransactionsEndpoint(),
  layout: new LayoutEndpoint(),
  lpr: new LprEndpoint(),
  snapshotIngester: new SnapshotIngesterEndpoint(),
  streaming: new StreamingEndpoint(),
  brainBox: new BrainBoxEndpoint(),
  brainBoxControl: new BrainBoxButtonControlEndpoint(),
  brainBoxOutput: new BrainBoxOutputDevices(),
  monitoring: new MonitoringEndpoint(),
  facialRecognition: new FacialRecognitionEndpoint(),
  plans: new PlanEndpoint(),
  payments: new PaymentEndpoint(),
  crmIntegration: new CrmIntegrationEndpoint(),
  setBearerToken,
  removeBearerToken,
}

export type Api = typeof api

export default api
export {axios}
