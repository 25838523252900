import React, {useEffect, useId, useState} from 'react'
import {SliderBubble, SliderBubbleText, SliderContainer, SliderInput} from './SliderBase'
import {SliderProps} from './SliderProps'
import useWindowSize from './hooks/useWindowSize'

const Slider = (props: SliderProps) => {
  const {value, setValue} = props
  const [showBubble, setShowBubble] = useState(false)
  const bubbleId = useId()
  const onMouseEnter = () => setShowBubble(true)
  const onMouseLeave = () => setShowBubble(false)
  const {width: windowWidth} = useWindowSize()

  function getSliderWidth() {
    if (!props.width) return 500
    if (props.width.includes('%')) return (parseInt(props.width.replace('%', '')) / 100) * windowWidth
    return parseInt(props.width)
  }

  function getCoefficient(sliderWidth: number): number {
    const breakpoints = [168, 200, 252, 300, 336, 350, 400, 450, 504, 550, 600, 672, 700, 750, 800, 850, 900, 1008, 1176, 1344, 1512]
    const coefficients = [17.8, 15.6, 12.2, 10.5, 9.1, 8.6, 7.4, 6.5, 5.9, 5.3, 4.8, 4.2, 4.2, 4.0, 3.7, 3.5, 3.3, 3.1, 2.6, 2.4, 2.0]
    for (let i = 0; i < breakpoints.length; i++) {
      if (sliderWidth <= breakpoints[i]) {
        return coefficients[i] + (1 - sliderWidth / breakpoints[i])
      }
    }
    return 1.8
  }

  useEffect(() => {
    const scale = ((value - props.min) / (props.max - props.min)) * 100
    const sliderWidth = getSliderWidth()
    const correction = (scale * getCoefficient(sliderWidth)) / 100
    const correctedScale = scale - correction
    const bubble = document.getElementById(bubbleId)
    if (bubble) {
      bubble.style.left = `${correctedScale}%`
    }
  }, [value, bubbleId, showBubble, props.disabled])

  return (
    <SliderContainer width={props.width || '500px'}>
      {((props.bubble && showBubble) || props.disabled) && (
        <SliderBubble id={bubbleId} showBubble={showBubble}>
          <SliderBubbleText> {value} </SliderBubbleText>
        </SliderBubble>
      )}
      <SliderInput
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        type='range'
        thumbColor={props.thumbColor}
        min={props.min}
        max={props.max}
        value={value}
        disabled={props.disabled}
        onChange={({target: {value: radius}}) => {
          setValue(+radius)
        }}
        background={props.background}
      />
    </SliderContainer>
  )
}

export default Slider
