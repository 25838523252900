import Forbidden from 'src/images/forbiddenVetor.svg'
import {Container, ContainerErrorSSO, ContainerImageErrorSSO, ContainerInfosErrorSSO, ErrorContent, TitleError} from './ForbiddenBase'

const ForbiddenPage = () => {
  return (
    <Container>
      <ContainerErrorSSO>
        <ContainerImageErrorSSO>
          <img src={Forbidden} alt='foto' />
        </ContainerImageErrorSSO>

        <ContainerInfosErrorSSO>
          <TitleError>Oops, acesso não permitido!</TitleError>
          <ErrorContent>
            <b>Desculpe, você não tem permissão para acessar esta página.</b> Entre em contato com o administrador para adicionar permissões ao seu usuário.
          </ErrorContent>
        </ContainerInfosErrorSSO>
      </ContainerErrorSSO>
    </Container>
  )
}
export default ForbiddenPage
