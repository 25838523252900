import {useCallback, useState} from 'react'

import useIsMounted from './useIsMounted'

function useLoading(initialState: boolean) {
  const isMounted = useIsMounted()

  const [loading, setLoading] = useState(initialState)

  const applyLoading = useCallback(
    async function _applyLoading<T>(lazyPromise: () => Promise<T>): Promise<T> {
      try {
        setLoading(true)

        const result = await lazyPromise()

        return result
      } catch (err) {
        throw err
      } finally {
        if (isMounted) setLoading(false)
      }
    },
    [isMounted],
  )

  return {loading, setLoading, applyLoading}
}

export default useLoading
