import {Box, DropdownItem} from '@viptech/react-components'
import sub from 'date-fns/sub'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import TopBar from 'src/components/top-bar/TopBar'
import {AdvancedFilters, FilterSelectedFinal} from 'src/components/advancedFilters/AdvancedFilters'
import {TransparentButton} from 'src/components/advancedFilters/AdvancedFiltersBase'
import {MultipleSearchDropdownItem, SelectWithSearchComponent} from 'src/components/selectWithSearchComponent/SelectWithSearchComponent'
import {useCompany} from 'src/contexts/CompanyContext'
import api from 'src/services/api'
import {Lpr, LprFilterType} from 'src/services/api/endpoints/LprEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import CustomCalendar from '../../components/customCalendar/CustomCalendar'
import NewReloadButton from '../../components/reloadButton/ReloadButton'
import deleteIconBlue from '../../images/deleteIconBlue.svg'
import {ContainerFilters, FiltersSelectedContainer} from '../alarms/AlarmsBase'
import LicensePlateGridTable from './components/lpr-grid-table/LprGridTable'

export type DateFilter = {
  start?: string
  end?: string
}

export type SentToFilter = {
  sentToHarpia?: boolean
  sentToSpia?: boolean
}

const initialSentToItems = [
  {id: '1', label: 'Harpia'},
  {id: '2', label: 'Spia'},
]

export function LicensePlate() {
  const {selectedCompanies} = useCompany()
  const [checked, setChecked] = useState(false)

  const [loadingInformation, setLoadingInformation] = useState(true)
  const [lprEvents, setLprEvents] = useState<Lpr[]>([])
  const [canLoadMore, setCanLoadMore] = useState(true)

  const [dateFilter, setDateFilter] = useState<DateFilter>({start: '', end: ''})
  const {enqueueSnackbar} = useSnackbar()

  const [timeToReload, setTimeToReload] = useState<number>(0)
  const [startId, setStartId] = useState<number | null>(null)
  const [loadingMore, setLoadingMore] = useState(false)
  const loadMore = useRef(false)

  const initalSearchTypes = [
    {id: '3', label: 'Placa', searchBy: 'plates'},
    {id: '4', label: 'Cidade', searchBy: 'city'},
    {id: '5', label: 'Cor', searchBy: 'color'},
    {id: '6', label: 'Marca', searchBy: 'brand'},
    {id: '7', label: 'Modelo', searchBy: 'model'},
  ]

  const [searchTypes, setSearchTypes] = useState(initalSearchTypes)

  const [searchSelected, setSearchSelected] = useState<MultipleSearchDropdownItem>({id: '', label: '', searchBy: ''})
  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [chosenFilters, setChosenFilters] = useState<FilterSelectedFinal[]>([])
  const [inputSearch, setInputSearch] = useState('')

  const [sentTo, setSentTo] = useState<SentToFilter>({sentToHarpia: undefined, sentToSpia: undefined})
  const [sentToItems, setSentToItems] = useState<Array<DropdownItem>>(initialSentToItems)

  const [disabledSelectFilters, setDisabledSelectFilters] = useState(false)
  const [filter, setFilter] = useState<LprFilterType>({companyIds: selectedCompanies})
  const initalCamerasValue = useRef<DropdownItem[]>([])
  const [cameras, setCameras] = useState<Array<DropdownItem>>([])
  const blockCameraRequests = useRef(false)

  function handleMultipleSearch() {
    let newFilter = {}
    if (searchSelected.searchBy === 'plates') {
      newFilter = {
        [searchSelected.searchBy]: [inputSearch],
      }
    } else {
      newFilter = {[searchSelected.searchBy]: inputSearch}
    }

    setFilter((prev) => {
      return {
        ...prev,
        ...newFilter,
      }
    })

    const setSearch = searchTypes.filter((it) => it.searchBy !== searchSelected.searchBy)
    setSearchTypes(setSearch)

    const newChosenFilters = {
      id: searchSelected.id,
      label: searchSelected.label,
      itemsOnMenu: [],
      selectedItem: {
        id: '',
        label: inputSearch,
      },
    }
    setChosenFilters((prev) => {
      return [...prev, newChosenFilters]
    })

    setShowMoreFilters(true)
    setInputSearch('')
  }

  const getCameras = useCallback(async () => {
    const controller = new AbortController()
    if (blockCameraRequests.current) return
    blockCameraRequests.current = true
    try {
      const response = await api.camera.getMany({
        paginate: false,
        filter: {
          companyIds: selectedCompanies,
          origin: 'RTSP',
        },
      })
      const initialCameras = response.data.data.entities.map((camera) => {
        return {id: String(camera.id), label: camera.name}
      })

      setCameras(initialCameras)
      initalCamerasValue.current = initialCameras
    } catch (error: any) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar dispositivo integrados')
    }
    blockCameraRequests.current = false
    return () => controller.abort()
  }, [enqueueSnackbar, selectedCompanies])

  async function getAllLprEvents(filter: LprFilterType, dateFilter: DateFilter, sentTo: SentToFilter) {
    try {
      if (loadingInformation) return
      if (loadMore.current) setLoadingMore(true)
      else setLoadingInformation(true)
      const response = await api.lpr.getAllLPR({
        batchSize: 20,
        startId: loadMore.current ? startId : null,
        filter: {
          ...filter,
          companyIds: selectedCompanies,
        },
        sentToHarpia: sentTo.sentToHarpia ?? undefined,
        sentToSpia: sentTo.sentToSpia ?? undefined,
        start: dateFilter.start !== '' ? moment(dateFilter.start).format() : moment(sub(new Date(), {minutes: 30})).format(),
        end: dateFilter.end !== '' ? moment(dateFilter.end).format() : moment(new Date()).format(),
        orderBy: {createdAt: 'DESC'},
        includes: ['camera', 'plate.vehicle', 'situation'],
      })
      if (loadMore.current) {
        if (!(response.data.data.items.length === 1 && response.data.data.items[0].id === startId)) {
          setLprEvents((prev) => [...prev, ...response.data.data.items])
        }
      } else {
        setLprEvents(response.data.data.items)
      }
      setStartId(response.data.data.nextId)
      setCanLoadMore(response.data.data.items.length === 20)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar informações do(s) veículo(s)')
    } finally {
      loadMore.current = false
      setLoadingInformation(false)
      setLoadingMore(false)
    }
  }

  async function reloadLprEvents() {
    loadMore.current = true
    getAllLprEvents(filter, dateFilter, sentTo)
  }

  useEffect(() => {
    const controller = new AbortController()
    getAllLprEvents(filter, dateFilter, sentTo)
    return () => controller.abort()
  }, [filter, dateFilter, selectedCompanies, sentTo])

  useEffect(() => {
    if (!checked) return
    const id = setInterval(() => getAllLprEvents(filter, dateFilter, sentTo), timeToReload)
    return () => clearInterval(id)
  }, [checked, timeToReload, filter, dateFilter, sentTo])

  const handleChangeTimeStart = (dates: [Date, Date]) => {
    let start = moment(dates[0]).format('YYYY-MM-DDTHH:mm')
    let end = moment(dates[1]).format('YYYY-MM-DDTHH:mm')
    if (dates[0] === dates[1]) {
      start = moment(dates[0].setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm')
      end = moment(dates[1].setHours(23, 59, 59, 59)).format('YYYY-MM-DDTHH:mm')
    }
    setDateFilter({
      start,
      end,
    })
  }

  const eventFilters = [
    {
      id: '1',
      label: 'Enviado',
      itemsOnMenu: sentToItems,
    },
    {
      id: '2',
      label: 'Câmeras',
      itemsOnMenu: cameras,
    },
  ]

  function handlePartialSetFilter(newFilter: FilterSelectedFinal) {
    if (!sentToItems.length && newFilter.id === '1') return
    if (!cameras.length && newFilter.id === '2') return

    setDisabledSelectFilters(true)
    const moreFilters = [...chosenFilters, newFilter]
    setChosenFilters(moreFilters)
    setShowMoreFilters(true)
  }

  const handleSetPartialFilters = (value: DropdownItem, index: number) => {
    setDisabledSelectFilters(false)
    const newFilter = [...chosenFilters]
    newFilter[index].selectedItem = value

    const newList = sentToItems.filter((it) => it.label !== value.label)
    setSentToItems(newList)

    const newCameras = cameras.filter((it) => it.label !== value.label)
    setCameras(newCameras)

    if (newFilter[index].id === '1') {
      newFilter[index].itemsOnMenu = newList

      if (value.label === 'Spia') {
        setSentTo((prev) => {
          return {
            ...prev,
            sentToSpia: true,
          }
        })
      } else {
        setSentTo((prev) => {
          return {
            ...prev,
            sentToHarpia: true,
          }
        })
      }
    } else {
      const selectedId = newFilter[index].selectedItem?.id
      if (!selectedId) return
      const previousCamerasIds = filter.cameraIds ?? []
      const newArrayOfCamerasFilter = [...previousCamerasIds, +selectedId]
      setFilter((prev) => {
        return {
          ...prev,
          cameraIds: newArrayOfCamerasFilter,
        }
      })
      newFilter[index].itemsOnMenu = newCameras
    }

    setChosenFilters(newFilter)
  }

  const deleteFilter = (index: number) => {
    const deleteItem = chosenFilters[index].selectedItem?.label

    if (!deleteItem) return

    const deleted = {
      id: chosenFilters[index].selectedItem?.id ?? '',
      label: deleteItem,
    }

    if (chosenFilters[index].id === '1') {
      if (deleteItem === 'Spia') {
        setSentTo((prev) => {
          return {
            ...prev,
            sentToSpia: undefined,
          }
        })
      } else {
        setSentTo((prev) => {
          return {
            ...prev,
            sentToHarpia: undefined,
          }
        })
      }

      setSentToItems((prev) => [...prev, deleted])
    } else if (chosenFilters[index].id === '2') {
      const selectedId = chosenFilters[index].selectedItem?.id
      if (!selectedId) return
      const newArrayOfCamerasFilter = filter.cameraIds?.filter((it) => it !== +selectedId) ?? []

      setFilter((prev) => {
        return {
          ...prev,
          cameraIds: newArrayOfCamerasFilter,
        }
      })

      setCameras((prev) => [...prev, deleted])
    } else {
      const typeToDelete = initalSearchTypes.find((it) => it.label === chosenFilters[index].label)

      const keyToRemove = typeToDelete?.searchBy
      const newFilter = filter
      delete newFilter[keyToRemove as keyof typeof filter]
      setFilter(newFilter)
      setSearchTypes((prev) => [...prev, typeToDelete!])
      setInputSearch('')
      reloadLprEvents()
    }

    const deletedFilters = chosenFilters.filter((it) => it.selectedItem?.label !== deleteItem)
    setChosenFilters(deletedFilters)
    if (deletedFilters.length <= 0) {
      setShowMoreFilters(false)
    }
  }

  const deleteAllFilters = () => {
    setSentTo((prev) => {
      return {
        ...prev,
        sentToSpia: undefined,
        sentToHarpia: undefined,
      }
    })
    setSentToItems(initialSentToItems)
    setCameras(initalCamerasValue.current)
    setFilter({companyIds: selectedCompanies})
    setSearchTypes(initalSearchTypes)
    setDisabledSelectFilters(false)
    setShowMoreFilters(false)
    setChosenFilters([])
  }

  const clearEmptyFilter = () => {
    const newFilters = chosenFilters.filter((it) => it.selectedItem)
    setChosenFilters(newFilters)
    setDisabledSelectFilters(false)
  }

  useEffect(() => {
    getCameras()
  }, [getCameras])

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' columnGap='16px' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <NewReloadButton clickButton={() => getAllLprEvents(filter, dateFilter, sentTo)} setReload={setChecked} setTimeToReload={setTimeToReload} />

            <Box direction='column' display='flex' rowGap='20px'>
              <SelectWithSearchComponent
                searchBy={searchTypes}
                setSearchSelected={setSearchSelected}
                searchSelected={searchSelected}
                inputValue={inputSearch}
                inputSearch={setInputSearch}
                filterBy={eventFilters}
                setSelectedFilter={handlePartialSetFilter}
                disabledSelectFilter={disabledSelectFilters}
                handleMultipleSearch={handleMultipleSearch}
                multipleSearch
              />
            </Box>
          </Box>

          <CustomCalendar setDate={handleChangeTimeStart} sizeToHideRuler={1500} />
        </Box>

        <Box marginTop='15px' align='center' display='flex' justifyContent='center'>
          {showMoreFilters && (
            <ContainerFilters>
              <FiltersSelectedContainer>
                {chosenFilters.map((it, index) => {
                  return (
                    <AdvancedFilters
                      key={`${it}_${index}`}
                      clearEmptyFilter={clearEmptyFilter}
                      index={index}
                      filtersSelected={it}
                      setFiltersSelected={handleSetPartialFilters}
                      deleteFilter={deleteFilter}
                    />
                  )
                })}
              </FiltersSelectedContainer>

              <TransparentButton onClick={() => deleteAllFilters()}>
                <Box display='flex' columnGap='10px'>
                  <img src={deleteIconBlue} alt='limpar filtro' />
                  <p>Limpar Seleção</p>
                </Box>
              </TransparentButton>
            </ContainerFilters>
          )}
        </Box>
      </TopBar>
      <LicensePlateGridTable
        lprEvents={lprEvents}
        loadingInformation={loadingInformation}
        loadingMore={loadingMore}
        filter={filter}
        setFilter={setFilter}
        reloadLprEvents={reloadLprEvents}
        canLoadMore={canLoadMore}
      />
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    flexDirection: 'column',
    background: 'white',
  },
})
