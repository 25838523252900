import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Ord, Paginate} from './_types'

const prefix = process.env.REACT_APP_REPORTS_BASE_URL

type Dict<T> = Record<string, T>

const statsMapCamera: Dict<string> = {
  ARMADO: 'armed',
  DESARMADO: 'desarmed',
  'FORA DO HORÁRIO': 'outOfHour',
}

const statsMapEvent: Dict<string> = {
  INICIADO: 'initiated',
  EXPIRADO: 'expired',
  PROCESSADO: 'processed',
  DESPACHADO: 'dispatched',
  IGNORADO: 'ignored',
  ERRO: 'mistake',
}

export type CameraStat1 = {
  id: number
  status: string
  count: number
}

export type CameraReports = {
  statuses: CameraStat1[]
  online: number
  offline: number
}

export type CameraStats = {
  armed: CameraStat1
  desarmed: CameraStat1
  outOfHour: CameraStat1
  online: number
  offline: number
}

export type StatsParams = {
  filter?: {
    companyId?: number
  }
}

export type EventStat1 = {
  id: number
  status: string
  count: number
}

export type EventStats = {
  initiated?: EventStat1
  processed?: EventStat1
  expired?: EventStat1
  dispatched?: EventStat1
  ignored?: EventStat1
  mistake?: EventStat1
}

export type EventFeedbackParams = {
  period: string
  filter?: {
    companyId?: number
  }
}

export type EventFeedBack = {
  period: string
  reports: Reports[]
}

type Reports = {
  userId: number
  name: string
  current: number
  previous: number
}

export type EventChart = {
  companyId: number
  aggregation: string
  total: number
  series: EventSerieTypes | []
}

export type EventSerieTypes = {
  Eventos: EventSerie[]
  Despachado: EventSerie[]
  Erro: EventSerie[]
  Expirado: []
  Ignorado: EventSerie[]
  Iniciado: EventSerie[]
  Processado: EventSerie[]
}

export type EventSerie = {
  count: number
  year: number
  month: number
  week: number
  day: number
  hour: number
  minute: number
}

export type GetEventsChartsParams = {
  end: string
  start: string
  filter?: {
    status?: string[]
    companyIds?: number[]
  }
}

export type GetEventsChartsMaxParams = {
  end: string
  start: string
  orderBy: Ord
  aggregateBy?: string
  operation?: string
  filter?: {
    companyId: number
  }
}

export type EventCamMax = {
  cameraId?: number
  clientId?: number
  data: number
}

export type PeopleCountParams = {
  end: string
  start: string
  filter?: {
    cameraIds?: Array<number | undefined>
    clientIds?: Array<number | undefined>
  }
}

export type GenderCountParams = {
  end: string | number
  start: string | number
  filter?: {
    cameraIds?: Array<number | undefined>
    clientIds?: Array<number | undefined>
  }
  countByDay?: boolean
}

export type CountByCategoriesParams = {
  end: string
  start: string
  groupBy: string
  categorizeBy: string
  filter?: {
    cameraIds?: Array<number | undefined>
    clientIds?: Array<number | undefined>
  }
}

export type RateByGroups = {
  end: string
  start: string
  groupBy: string
  filter?: {
    cameraIds?: Array<number | undefined>
    clientIds?: Array<number | undefined>
  }
}

export type RateBySensitiveData = {
  end: string
  start: string
  groups: Array<string>
  filter?: {
    cameraIds?: Array<number | undefined>
    clientIds?: Array<number | undefined>
  }
}

export type RateByGroupsDTO = {
  series: Array<number>
  labels: Array<string>
}

export type CountByCategoriesDTO = {
  series: Array<{
    name: string
    data: Array<number>
  }>
  categories: Array<string | number>
}

export type PeopleCountDTO = {
  clientId: number
  count: number
  passed: number
  notPassed: number
  passedAtMorning: number
  passedAtAfternoon: number
}

export type RateGenderByDayPeriodDTO = [
  {
    quantity: number
    period: string
    gender: string
  },
]

export type getDetectorCounts = {
  start: string
  end: string
  filter?: {
    cameraIds?: number[]
    clientIds?: number[]
  }
}

export type DetectorCountsObjects = {
  objects: DetectorCountObjects[]
}

export type DetectorCountObjects = {
  objectType: string
  cameraId: number
  count: string
}

function mapData_<A, B>(response: AxiosResult<A>, data: B): AxiosResult<any> {
  const responseAny = response as any
  responseAny.data = {
    ...response.data,
    data,
  }
  return responseAny
}

export class ReportsEndpoint {
  async camerasStats(params: StatsParams): Promise<AxiosResult<CameraStats>> {
    return await axios.get(`${prefix}/cameras`, {params: prepareParams(params)}).then((res: AxiosResult<CameraReports>) =>
      mapData_(
        res,
        Object.fromEntries(
          res.data.data.statuses.map((it2) => {
            if (it2.status in statsMapCamera) {
              return [statsMapCamera[it2.status], it2]
            } else {
              throw new Error('invalid camera status: ' + it2.status)
            }
          }),
        ),
      ),
    )
  }

  async camerasReports(params: StatsParams): Promise<AxiosResult<CameraReports>> {
    return await axios.get(`${prefix}/cameras`, {params: prepareParams(params)})
  }

  async eventsStats(params: StatsParams): Promise<AxiosResult<EventStats>> {
    return axios.get(`${prefix}/events`, {params: prepareParams(params)}).then((res: AxiosResult<EventStat1[]>) =>
      mapData_(
        res,
        Object.fromEntries(
          res.data.data.map((it) => {
            if (it.status in statsMapEvent) {
              return [statsMapEvent[it.status], it]
            } else {
              throw new Error('invalid event status: ' + it.status)
            }
          }),
        ),
      ),
    )
  }

  async eventClosed(params: any): Promise<AxiosResult<EventFeedBack>> {
    return axios.get(`${prefix}/events/closed`, {params: prepareParams(params)})
  }

  async eventFeedBack(params: EventFeedbackParams): Promise<AxiosResult<EventFeedBack>> {
    return axios.get(`${prefix}/feedbacks`, {params: prepareParams(params)})
  }

  async eventCharts(params: GetEventsChartsParams): Promise<AxiosResult<Paginate<EventChart>>> {
    return axios.get(`${prefix}/events/count`, {params: prepareParams(params)})
  }

  async eventCamMax(params: GetEventsChartsMaxParams): Promise<AxiosResult<EventCamMax[]>> {
    return axios.get(`${prefix}/events/aggregation`, {params: prepareParams(params)})
  }

  async peopleCount(params: PeopleCountParams): Promise<AxiosResult<PeopleCountDTO>> {
    return axios.get(`${prefix}/retail-dashboard/people-count`, {params: prepareParams(params)})
  }

  async categoriesCount(params: CountByCategoriesParams): Promise<AxiosResult<CountByCategoriesDTO>> {
    return axios.get(`${prefix}/retail-dashboard/rate-by-categories`, {params: prepareParams(params)})
  }

  async genderCount(params: GenderCountParams): Promise<AxiosResult<CountByCategoriesDTO>> {
    return axios.get(`${prefix}/retail-dashboard/count-by-gender`, {params: prepareParams(params)})
  }

  async groupsCount(params: RateByGroups): Promise<AxiosResult<RateByGroupsDTO>> {
    return axios.get(`${prefix}/retail-dashboard/rate-by-groups`, {params: prepareParams(params)})
  }

  async sensitiveData(params: RateBySensitiveData): Promise<AxiosResult<RateByGroupsDTO>> {
    return axios.get(`${prefix}/retail-dashboard/sensitive-data-rate`, {params: prepareParams(params)})
  }

  async rateGenderByDayPeriod(params: GenderCountParams): Promise<AxiosResult<RateGenderByDayPeriodDTO>> {
    return axios.get(`${prefix}/retail-dashboard/rate-gender-by-day-period`, {params: prepareParams(params)})
  }

  async getDetectorCounts(params: getDetectorCounts) {
    return await axios.get(`${prefix}/tracker-logs`, {
      params: prepareParams(params),
    })
  }
}
