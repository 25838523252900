import {Box, Button, Typography} from '@viptech/react-components'
import {CardResponsive} from 'src/components/cards/CardResponsive'
import {useCameraContext} from '../context/CamerasContext'
import {useNavigate} from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'

type CameraListCardsResponsiveProps = {
  redirectToAddCamera: () => void
}

export const CameraListCardsResponsive = (props: CameraListCardsResponsiveProps) => {
  const {cameras} = useCameraContext()
  const navigate = useNavigate()

  return (
    <Box display='flex' direction='column' padding='0px 15px' width='100%' height='80vh' justifyContent='space-between'>
      <Box display='flex' direction='column' rowGap='20px' height='85%' overflow='auto' padding='5px' paddingRight='8px'>
        {cameras.map((it) => {
          const statusOnCamera = it.configuration.isOnline ? 'Armado' : 'Desarmado'
          return <CardResponsive title={it.name} status={statusOnCamera} onClickFunction={() => navigate(it.id.toString())} />
        })}
      </Box>

      <Box margin='20px 0' width='100%'>
        <Button variant='contained' width='100%' onClick={() => props.redirectToAddCamera()}>
          <Box display='flex' columnGap={'8px'} align='center' justifyContent='center'>
            <AddCircleOutlineIcon />
            <Typography variant='span'>Adicionar uma câmera</Typography>
          </Box>
        </Button>
      </Box>
    </Box>
  )
}
