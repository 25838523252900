import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export async function patchLicensePlate(idListLpr: string, plate: string, description: string, enqueueSnackbar: any) {
  try {
    await api.lpr.createPlate(idListLpr, {
      licensePlate: plate,
      description: description,
    })
    enqueueSnackbar('Placa adicionada a lista com sucesso', {
      variant: 'success',
    })
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar a lista')
  }
}
