import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormControl, InputBase} from '@mui/material'
import {useState} from 'react'

import {Icon} from 'src/common/generics/components/icon/IconBase'
import AppVersion from 'src/components/AppVersion'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import api from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {isEmailLoginValid} from 'src/utilities/regexValidation'
import ViptechLogo from '../../images/viptech-logo.svg'
import {ButtonDiv, Container, Form, InputDiv, LoginBox, MessageSpan, TopDiv, ViptechLogoDiv} from './Styles'
import CountdownButton from './components/CountdownButton'

function ForgotPassword() {
  const [email, setEmail] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [responseOk, setResponseOk] = useState<boolean>(false)

  async function handleSubmit() {
    try {
      const response = await api.auth.forgotPassword(email)
      if (response.status === 200) {
        setIsOpen(true)
        setResponseOk(true)
      }
    } catch (error) {
      setIsOpen(true)
      setResponseOk(false)
    }
  }

  async function resendEmail() {
    try {
      const response = await api.auth.resendMail(email, 'forgot-password')
      if (response.status === 200) {
        setIsOpen(true)
        setResponseOk(true)
      }
    } catch (error) {
      setIsOpen(true)
      setResponseOk(false)
    }
  }

  function handleKeyPress(e: any) {
    if (e.key === 'Enter') {
      e.preventDefault()
      if (isEmailLoginValid(email)) handleSubmit()
    }
  }

  return (
    <Container>
      <Dialog maxWidth='md' open={isOpen} onClose={() => setIsOpen(false)} sx={{textAlign: 'center'}}>
        <DialogTitle sx={styles.title}>
          Recuperação de senha
          <Box
            sx={{
              position: 'absolute',
              right: '23px',
              top: '13px',
              minWidth: '0',
              background: 'none',
            }}>
            <Icon src={CloseIcon} cursor='pointer' onClick={() => setIsOpen(false)} />
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContent>
            {responseOk ? (
              <>
                <DialogContentText>
                  <Box sx={styles.dialogText}>
                    <Box>
                      Foi enviado um e-mail de recuperação de senha para:&nbsp;
                      <span style={{color: '#009EFF'}}>{email}</span>
                    </Box>
                    <Box>Por favor verifique sua caixa de entrada.</Box>
                    <Box>Caso não tenha recebido clique no botão abaixo:</Box>
                  </Box>
                </DialogContentText>
                <CountdownButton
                  seconds={30}
                  onClick={() => resendEmail()}
                  variant='contained'
                  sx={{marginTop: '15px', padding: '12px 18px', fontFamily: 'Inter', boxShadow: 'none', textTransform: 'none', width: '150px'}}>
                  Reenviar e-mail
                </CountdownButton>
              </>
            ) : (
              <DialogContentText>
                <Box sx={styles.dialogText}>
                  <Box>
                    Não foi possível enviar sua recuperação para:&nbsp;
                    <span style={{color: '#009EFF'}}>{email}</span>
                  </Box>
                  <Box>Verifique se o e-mail está correto e tente novamente.</Box>
                </Box>
              </DialogContentText>
            )}
          </DialogContent>
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '15px'}}>
            <Button
              onClick={() => (window.location.href = '/login')}
              variant='contained'
              sx={{
                width: '100%',
                height: '50px',
                fontFamily: 'Inter',
                boxShadow: 'none',
                textTransform: 'none',
                marginTop: '15px',
                marginRight: '15px',
                marginBottom: '15px',
                background: '#009EFF',
                color: '#ffffff',
              }}>
              Voltar para o login
            </Button>
            {!responseOk && (
              <Button
                onClick={() => setIsOpen(false)}
                variant='outlined'
                sx={{
                  width: '100%',
                  height: '50px',
                  fontFamily: 'Inter',
                  boxShadow: 'none',
                  textTransform: 'none',
                  marginTop: '15px',
                  marginRight: '15px',
                  marginBottom: '15px',
                  background: '#ffffff',
                  color: '#009EFF',
                }}>
                Tentar novamente
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <LoginBox>
        <TopDiv>
          <MessageSpan color={'#ffffff'}>
            Insira seu <MessageSpan color={'#009EFF'}>e-mail</MessageSpan> para redefinir a senha da sua conta<MessageSpan color={'#009EFF'}>.</MessageSpan>
          </MessageSpan>
        </TopDiv>
        <Form>
          <InputDiv>
            <FormControl>
              <InputBase
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                sx={styles.inputPassword}
                autoFocus={true}
                placeholder={'E-mail'}
                onKeyPress={(event) => handleKeyPress(event)}
              />
            </FormControl>
          </InputDiv>
          <ButtonDiv>
            <Button onClick={() => (window.location.href = '/login')} variant='contained' sx={styles.cancelButton}>
              Cancelar
            </Button>
            <LoadingButton
              variant='contained'
              sx={styles.confirmButton}
              disabled={!isEmailLoginValid(email)}
              onClick={(e) => {
                e.preventDefault()
                handleSubmit()
              }}>
              Enviar
            </LoadingButton>
          </ButtonDiv>
        </Form>
      </LoginBox>
      <ViptechLogoDiv>
        <img src={ViptechLogo} alt={'Logo Viptech'} />
      </ViptechLogoDiv>
      <AppVersion color='white' />
    </Container>
  )
}

const styles = createStyleSheet({
  dialogText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  confirmButton: {
    width: '100%',
    height: '50px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    '&:disabled': {
      background: '#808080',
    },
  },
  inputPassword: {
    fontSize: '16px',
    color: '#ffffff',
    backgroundColor: '#46464f',
    height: '3.5em',
    borderRadius: '10px',
    padding: '0px 15px 0px 15px',
    border: 'none',
    textIndent: '4%',
    outline: '0',
    '&:focus': {
      outline: 'none',
    },
  },
  cancelButton: {
    width: '100%',
    height: '50px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginBottom: '15px',
    background: '#808080',
  },
  title: {
    paddingTop: 3,
    color: '#353535',
    textAlign: 'initial',
  },
})

export default ForgotPassword
