import {Box} from '@mui/material'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import ImageScaled from 'src/components/ImageScaled'

type LprLoadingPlateScaledProps = {
  croppedImageKey: string
}

export const LprLoadingPlateScaled = ({croppedImageKey}: LprLoadingPlateScaledProps) => {
  const hasPermissionEventsLpr = ValidatePermissions('lpr:read')
  return (
    <Box>{!hasPermissionEventsLpr ? <></> : <ImageScaled src={`${process.env.REACT_APP_LPR_CROPPED_IMAGES_BASE_URL}/${croppedImageKey}`} width={80} />}</Box>
  )
}
