import {Box, Typography} from '@viptech/react-components'
import {useEffect} from 'react'
import {CameraImageDraw} from 'src/common/components/camera-image-draw/CameraImageDraw'
import {AreaItem} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import {DetectionObjectsActions} from 'src/common/types/DetectionObjectsActions'
import {Pos} from 'src/common/types/generics/ImageDetections'
import NoImage from 'src/images/noImage.jpg'
import {SxProps} from 'src/utilities/createStyleSheet'
import {Input} from '../../../components/input/Input'

const DEFAULT_IMAGE_WIDTH = 390

type CameraRegisterAreaDetectionProps = {
  src: string
  areas: AreaItem[]
  subscribe: (data: DetectionObjectsActions) => void
  containerStyles?: SxProps
  isOpen: boolean
  setOpen: (isOpen: boolean) => void
  editingArea?: AreaItem | null
  setEditingArea: (edit: AreaItem | null) => void
  setSelectedArea: (area: AreaItem | null) => void
  setCancel: (type?: string) => void
  setSave: (type?: string) => void
  drawEditable?: boolean
  setDrawEditable: (drawedit: boolean) => void
  setNameAreaInterest: (name: string) => void
  nameAreaInterest: string | undefined
  getPoints: (points: Pos[]) => void
  cancel?: string
  save?: string
  _selectedArea: AreaItem | null
  height: number
}

export function CameraRegisterAreaDetection(props: CameraRegisterAreaDetectionProps) {
  const {
    src,
    subscribe,
    setOpen,
    editingArea,
    setEditingArea,
    setSelectedArea,
    drawEditable,
    setDrawEditable,
    setCancel,
    setSave,
    setNameAreaInterest,
    getPoints,
    save,
    cancel,
    _selectedArea,
    nameAreaInterest,
    height,
  } = props
  const selectedArea = drawEditable ? editingArea : _selectedArea

  useEffect(() => {
    setDrawEditable(true)
  }, [setDrawEditable])

  return (
    <Box display='flex' rowGap='15px' direction='column'>
      <Input.Root
        label='Informe um nome para sua área'
        value={nameAreaInterest}
        defaultValue={nameAreaInterest}
        onChange={(e) => {
          if (editingArea) setEditingArea({...editingArea, label: e.target.value})
          else setNameAreaInterest(e.target.value)
        }}
      />

      <Typography variant='p' size='14px' style={{fontWeight: 500}} color='rgb(54, 65, 82);'>
        Crie os pontos para formar uma área
      </Typography>

      <Box display='flex' direction='column' justifyContent='center'>
        <span
          onMouseEnter={() => {
            if (editingArea) setDrawEditable(true)
          }}>
          <CameraImageDraw
            isKonvaImageDrawToolbar
            width={DEFAULT_IMAGE_WIDTH}
            height={height}
            points={selectedArea?.points!}
            editable={drawEditable!}
            src={src === `data:image/jpeg;base64,null` ? NoImage : src}
            onCancel={() => {
              setEditingArea(null)
              setDrawEditable(false)
              setOpen(false)
              setCancel(undefined)
            }}
            onSave={(points) => {
              if (editingArea !== null && editingArea) {
                const newArea = {...editingArea, points, label: editingArea.label}
                subscribe({
                  type: 'edit-interest-area',
                  payload: newArea,
                })
                setEditingArea(null)
                setSelectedArea(newArea)
                setDrawEditable(false)
                setOpen(false)
              } else {
                const newArea = {points: points, name: nameAreaInterest}
                subscribe({type: 'new-interest-area', payload: newArea})
                setEditingArea(null)
                setDrawEditable(false)
                setOpen(false)
              }
              setSave(undefined)
            }}
            getPoints={getPoints}
            cancel={cancel}
            save={save}
          />
        </span>
      </Box>
    </Box>
  )
}
