import {useEffect} from 'react'
import TableRow from '../table/components/tableRow/TableRowBase'

export type RowHoverProps = {
  children: React.ReactNode
  onClick?: Function
  id?: string
  height?: string
}

function RowHover({children, onClick, id, height = '2.5rem'}: RowHoverProps) {
  useEffect(() => {
    const rowHover = document.getElementById(id!)
    rowHover?.addEventListener('mouseover', () => {
      rowHover.style.backgroundColor = '#F5F5F5'
    })
    rowHover?.addEventListener('mouseout', () => {
      rowHover.style.backgroundColor = '#FFFFFF'
    })
  }, [id])

  return (
    <TableRow id={id} onClick={() => (onClick ? onClick() : null)} rowHeight={height}>
      {children}
    </TableRow>
  )
}

export default RowHover
