import {Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {useEffect} from 'react'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Company} from 'src/services/api'

type CompaniesSelectProps = {
  selectLabel?: string
  selectSize?: 'small' | 'medium'
  selectedCompaniesIds?: number[]
  setSelectedCompaniesIds?: (ids: number[]) => void
  companiesScroll: Company[]
  setCompaniesScroll: (companies: Company[]) => void
  pageCount: number
  setPageCount: (pageCount: number) => void
  companyNames: string[]
  setCompanyNames: (companyNames: string[]) => void
  handleChange?: (event: SelectChangeEvent<string[]>) => void
  defaultValueIds?: number[]
  multiple?: boolean
  disabled?: boolean
  blockUnselect?: boolean
}

function CompaniesSelect({
  selectLabel,
  selectSize,
  setSelectedCompaniesIds,
  companiesScroll,
  setCompaniesScroll,
  pageCount,
  setPageCount,
  companyNames,
  setCompanyNames,
  handleChange,
  defaultValueIds,
  multiple = true,
  disabled = false,
  blockUnselect = false,
}: CompaniesSelectProps) {
  const {companies, _pageCount, totalPage, selectedCompanies} = useCompany()

  const handleChangeDefault = (event: SelectChangeEvent<string[]>) => {
    const {
      target: {value},
    } = event
    if (blockUnselect && value.length === 0) return
    setCompanyNames(typeof value === 'string' ? value.split(',') : value)
    if (setSelectedCompaniesIds) setSelectedCompaniesIds(companies.filter((it) => value.includes(it.name)).map((it) => it.id))
    if (handleChange) handleChange(event)
  }

  useEffect(() => {
    if (defaultValueIds && defaultValueIds.length) {
      const selectedCompanyNames = companies.filter((it) => defaultValueIds.includes(it.id)).map((it) => it.name)

      setCompanyNames(selectedCompanyNames)
      handleChangeDefault({target: {value: selectedCompanyNames}} as SelectChangeEvent<typeof companyNames>)
    } else if (defaultValueIds && defaultValueIds.length === 0) {
      setCompanyNames([])
    } else {
      setCompanyNames([companies.find((it) => it.id === selectedCompanies[0])?.name!])
    }
  }, [])

  useEffect(() => {
    setCompaniesScroll(companies)
    setPageCount(_pageCount)
  }, [_pageCount, companies])

  async function loadMoreItems(e: any) {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight
    if (bottom) {
      if (pageCount < totalPage) {
        const response = await api.company.getMany({
          page: pageCount + 1,
        })

        response.data.data.entities.forEach((it) => {
          setCompaniesScroll([...companiesScroll, it])
        })
      }
      setPageCount(pageCount + 1)
    }
  }

  return (
    <FormControl sx={{width: '100%', fontFamily: 'Inter, sans-serif'}}>
      <InputLabel htmlFor='companies' sx={{fontFamily: 'Inter, sans-serif'}}>
        {selectLabel}
      </InputLabel>
      <Select
        multiple={multiple}
        label={selectLabel}
        required
        disabled={disabled}
        size={selectSize || 'small'}
        value={companyNames}
        renderValue={(selected) => selected.join(', ')}
        onChange={handleChangeDefault}
        MenuProps={{
          PaperProps: {
            onScroll: loadMoreItems,
          },
          style: {
            maxHeight: 500,
            fontFamily: 'Inter, sans-serif',
          },
        }}>
        {companiesScroll.map((company, index) => (
          <MenuItem key={'role-' + index} value={company.name}>
            {multiple && <Checkbox checked={companyNames.indexOf(company.name) > -1} />}
            {company.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CompaniesSelect
