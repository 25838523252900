import {CardContent, CardHeader, CircularProgress, Grid} from '@mui/material'
import {Box} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {InterestAreaSelect} from 'src/common/components/camera-detection-config/components/interest-area-select/InterestAreaSelect'
import {CameraImageDraw} from 'src/common/components/camera-image-draw/CameraImageDraw'
import InterestAreaBottom from 'src/common/components/interest-area-bottom/InterestAreaBottom'
import {AreaData, AreaItem} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import NoImage from 'src/images/noImage.jpg'
import {CameraModalRegisterAreaDetection} from 'src/pages/camera-config/components/camera-modal-register-area-detection/CameraModalRegisterAreaDetection'
import CameraModalRegisterLineCrossing from 'src/pages/camera-config/components/camera-modal-register-line-crossing/CameraModalRegisterLineCrossing'
import {deleteInterestArea, deleteLineCrossing} from 'src/pages/camera-config/components/detection-area/DetectionAreaFunctions'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import {Coordinate} from 'src/services/api'
import {LineCrossingDTO} from 'src/services/api/endpoints/LineCrossingEndpoint'
import {SxProps} from 'src/utilities/createStyleSheet'
import {DetectionsContainer, cameraDetectionConfigStyles} from './CameraDetectionConfigBase'
import {LineCrossingSelect} from './components/line-crossing-select/LineCrossingSelect'

type CameraDetectionConfigProps = {
  src: string
  areas: AreaItem[]
  lineCrossings: LineCrossingDTO[]
  reloadLineCrossings: () => void
  containerStyles?: SxProps
  imageWidth?: number
  cameraId: number
  isRtsp: boolean
}

export function CameraDetectionConfig(props: CameraDetectionConfigProps) {
  const [openCreateAreaModal, setOpenCreateAreaModal] = useState(false)
  const [lineCrossingOpen, setLineCrossingOpen] = useState(false)
  const [drawEditable, setDrawEditable] = useState(false)
  const [_selectedArea, setSelectedArea] = useState<AreaItem | null>(null)
  const [editingArea, setEditingArea] = useState<AreaItem | null>(null)
  const [_selectedLineCrossing, setSelectedLineCrossing] = useState<LineCrossingDTO | null>(null)
  const [editingLineCrossing, setEditingLineCrossing] = useState<LineCrossingDTO | null>(null)
  const {camera, loadingSnapshot, konvaImageRef, setAreaItems, areaItems, _dimImage, getInterestAreas} = useCameraConfigContext()
  const {enqueueSnackbar} = useSnackbar()
  const {src, areas, lineCrossings, cameraId, reloadLineCrossings, isRtsp} = props
  const selectedArea = drawEditable ? editingArea : _selectedArea
  const selectedLineCrossing = drawEditable ? editingLineCrossing : _selectedLineCrossing
  const updateAreaItems = (areaItems: AreaData[]) => setAreaItems(areaItems)
  const hasListLineCrossingPermission = ValidatePermissions('line-crossing:read')
  const hasEditLineCrossingPermission = ValidatePermissions('line-crossing:update')
  const hasCreateLineCrossingPermission = ValidatePermissions('line-crossing:create')
  const hasDeleteLineCrossingPermission = ValidatePermissions('line-crossing:delete')
  const snapshotHeight = camera && camera.configuration && camera?.configuration.snapshotHeight! < 320 ? camera?.configuration.snapshotHeight : 320

  useEffect(() => {
    if (editingLineCrossing === null) {
      setSelectedLineCrossing(null)
      setEditingLineCrossing(null)
    }
  }, [editingLineCrossing])

  function handleCloseLineCrossingModal() {
    setEditingArea(null)
    setSelectedLineCrossing(null)
    setEditingLineCrossing(null)
    setDrawEditable(false)
    setLineCrossingOpen(false)
  }

  function resizePoints(points?: Coordinate[]) {
    if (!points) return []
    return points.map((it) => {
      return {
        x: it.x * _dimImage.width,
        y: it.y * _dimImage.height,
      }
    })
  }

  return (
    <Grid container sx={[cameraDetectionConfigStyles.gridContainer, {minHeight: '500px', width: '100%', height: '100%'}]}>
      <Grid item>
        <DetectionsContainer>
          {loadingSnapshot ? (
            <Box style={cameraDetectionConfigStyles.image}>
              <CircularProgress />
            </Box>
          ) : (
            <Box style={{width: '900px'}}>
              <CameraImageDraw
                width={konvaImageRef.current!.dim.width}
                height={snapshotHeight}
                points={selectedArea?.points || resizePoints(selectedLineCrossing?.coordinates) || []}
                editable={false}
                src={src === `data:image/jpeg;base64,null` ? NoImage : src}
                onCancel={() => {
                  setEditingArea(null)
                  setDrawEditable(false)
                }}
              />
            </Box>
          )}
        </DetectionsContainer>
      </Grid>

      <>
        <Grid item sx={{maxHeight: '400px', minWidth: '390px', height: '100%', paddingTop: '8px'}}>
          <Grid container sx={[cameraDetectionConfigStyles.card]}>
            <Grid item sx={cameraDetectionConfigStyles.card2}>
              <CardHeader
                sx={cameraDetectionConfigStyles.cardHeader}
                titleTypographyProps={{variant: 'subtitle1', fontWeight: 600}}
                title='Áreas de Detecção'
              />
              <CardContent sx={cameraDetectionConfigStyles.cardContent}>
                <InterestAreaSelect
                  ListProps={{sx: {overflow: 'auto'}}}
                  disabled={false}
                  areas={areas}
                  selected={selectedArea}
                  onClickEdit={(it) => {
                    setOpenCreateAreaModal(true)
                    setEditingArea(it)
                    setDrawEditable(true)
                    if (_selectedArea !== null) {
                      setSelectedArea({
                        ..._selectedArea,
                        points: _selectedArea.points.slice(),
                      })
                    }
                  }}
                  onClickDelete={async (areaItem) => {
                    await deleteInterestArea(areaItem, areaItems, updateAreaItems, enqueueSnackbar, () => getInterestAreas(camera!.id, _dimImage))
                    setSelectedArea(null)
                  }}
                  onSelectChange={(it) => {
                    setSelectedLineCrossing(null)
                    if (selectedArea === null) setSelectedArea(it)
                    else if (selectedArea.key === it.key) setSelectedArea(null)
                    else setSelectedArea(it)
                  }}
                />
                <Box style={{display: 'flex', justifyContent: 'center'}}>
                  <InterestAreaBottom
                    isArea
                    disabled={src === `data:image/jpeg;base64,null`}
                    variant={'add'}
                    onClick={() => {
                      setOpenCreateAreaModal(true)
                      setSelectedArea(null)
                    }}
                  />
                </Box>
              </CardContent>
            </Grid>
          </Grid>
        </Grid>
        {isRtsp && hasListLineCrossingPermission && (
          <Grid item xs={3} sx={cameraDetectionConfigStyles.gridItem}>
            <Grid container sx={[cameraDetectionConfigStyles.card]}>
              <Grid item sx={cameraDetectionConfigStyles.card2}>
                <CardHeader
                  sx={cameraDetectionConfigStyles.cardHeader}
                  titleTypographyProps={{variant: 'subtitle1', fontWeight: 600}}
                  title='Cruzamento de linha'
                />
                <CardContent sx={cameraDetectionConfigStyles.cardContent}>
                  <LineCrossingSelect
                    ListProps={{sx: {overflow: 'auto'}}}
                    disabled={false}
                    lineCrossings={lineCrossings}
                    selected={selectedLineCrossing}
                    hasEditPermission={hasEditLineCrossingPermission}
                    hasDeletePermission={hasDeleteLineCrossingPermission}
                    onClickEdit={(it) => {
                      setLineCrossingOpen(true)
                      setEditingLineCrossing(it)
                      setDrawEditable(true)
                      if (_selectedLineCrossing !== null) {
                        setSelectedLineCrossing({
                          ..._selectedLineCrossing,
                        })
                      }
                    }}
                    onClickDelete={async (lineCrossingItem) => {
                      await deleteLineCrossing(lineCrossingItem.id!, enqueueSnackbar)
                      setSelectedLineCrossing(null)
                      reloadLineCrossings()
                    }}
                    onSelectChange={(it) => {
                      setSelectedArea(null)
                      if (selectedLineCrossing === null) setSelectedLineCrossing(it)
                      else if (selectedLineCrossing.id === it.id) setSelectedLineCrossing(null)
                      else setSelectedLineCrossing(it)
                    }}
                  />
                  <Box style={{display: 'flex', justifyContent: 'center'}}>
                    <InterestAreaBottom
                      isArea
                      disabled={src === `data:image/jpeg;base64,null` || !hasCreateLineCrossingPermission}
                      label='Adicionar novo cruzamento de linha'
                      variant={'add'}
                      onClick={() => {
                        setSelectedLineCrossing(null)
                        setLineCrossingOpen(true)
                      }}
                    />
                  </Box>
                </CardContent>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>

      {openCreateAreaModal && (
        <CameraModalRegisterAreaDetection
          open={openCreateAreaModal}
          setOpen={setOpenCreateAreaModal}
          selectedArea={selectedArea}
          editingArea={editingArea}
          setEditingArea={setEditingArea}
          setSelectedArea={setSelectedArea}
          drawEditable={drawEditable}
          setDrawEditable={setDrawEditable}
          height={snapshotHeight!}
        />
      )}
      {lineCrossingOpen && (
        <CameraModalRegisterLineCrossing
          open={lineCrossingOpen}
          setOpen={setLineCrossingOpen}
          imgSrc={src}
          lineCrossing={selectedLineCrossing}
          cameraId={cameraId}
          enqueueSnackbar={enqueueSnackbar}
          onClose={handleCloseLineCrossingModal}
          reloadLineCrossings={reloadLineCrossings}
          height={snapshotHeight!}
        />
      )}
    </Grid>
  )
}
