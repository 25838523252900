import {Box, Detection, Dim, Pos} from '../types/generics/ImageDetections'

export function posDefault(pos: Pos, def: Pos): Pos {
  return {
    x: isNaN(pos.x) ? def.x : pos.x,
    y: isNaN(pos.y) ? def.y : pos.y,
  }
}

export function posTuple(pos: Pos): [number, number] {
  return [pos.x, pos.y]
}

export function posScale(pos: Pos, scalar: number): Pos {
  return {x: pos.x * scalar, y: pos.y * scalar}
}

export function posMove(pos: Pos, move: Pos): Pos {
  return {x: pos.x + move.x, y: pos.y + move.y}
}

export function dim2pos(dim: Dim): Pos {
  return {x: dim.width, y: dim.height}
}

export function pos2dim(pos: Pos): Dim {
  return {width: pos.x, height: pos.y}
}

export function posNeg(pos: Pos): Pos {
  return {x: -pos.x, y: -pos.y}
}

export function coordsToPoints(box: number[]): number[] {
  if (box.length !== 4) throw new Error('invalid box input')
  const [x1, y1, x2, y2] = box
  return [x1, y1, x2, y1, x2, y2, x1, y2]
}

export function boxScale(box: Box, scalar: number): Box {
  return {p1: posScale(box.p1, scalar), p2: posScale(box.p2, scalar)}
}

export function boxToPoints(box: Box): number[] {
  return coordsToPoints([box.p1.x, box.p1.y, box.p2.x, box.p2.y])
}

export function detectionToBox(detection: Detection): Box {
  return {
    p1: {
      x: detection.x,
      y: detection.y,
    },
    p2: {
      x: detection.width + detection.x,
      y: detection.height + detection.y,
    },
  }
}

export function uniqBy<T>(xs: T[], keygen: (x: T) => string) {
  const set = new Set()
  return xs.filter((x) => {
    const key = keygen(x)
    if (set.has(key)) {
      return false
    } else {
      set.add(key)
      return true
    }
  })
}

export function computeScalar(scalar: number, dimImage: Dim, {width, height}: Partial<Dim>): number {
  const widthIsNotNull = typeof width === 'number'
  const heightIsNotNull = typeof height === 'number'
  if (widthIsNotNull && heightIsNotNull) {
    if (width !== 0 && height !== 0) {
      if (width < height) {
        const proportion = width / dimImage.width
        return proportion * scalar
      } else {
        const proportion = height / dimImage.height
        return proportion * scalar
      }
    } else if (width === 0) {
      const proportion = height / dimImage.height
      return proportion * scalar
    } else {
      const proportion = width / dimImage.width
      return proportion * scalar
    }
  } else if (widthIsNotNull && width !== 0) {
    const proportion = width / dimImage.width
    return proportion * scalar
  } else if (heightIsNotNull && height !== 0) {
    const proportion = height / dimImage.height
    return proportion * scalar
  } else {
    return scalar
  }
}

export const COLORS = [
  '#fa3a2f',
  '#993bb3',
  '#0099e0',
  '#00a284',
  '#00d066',
  '#fe9800',
  '#e34900',
  '#bcc3c7',
  '#7b8d8d',
  '#273e51',
  '#cf281e',
  '#a651bb',
  '#0081be',
  '#01c099',
  '#00b257',
  '#f9c300',
  '#f47800',
  '#ebf0f1',
  '#91a6a6',
  '#2d4960',
]
COLORS.push(...COLORS.splice(0, 2))

export function mkColorByKey(colors?: Record<string, any>) {
  const colorsMap: Record<string, any> = {...colors}
  let warnByKey = false
  let colorsCount = 0

  function colorByKey(key?: string) {
    if (!colors) return 'blue'
    if (!key) {
      if (!warnByKey) {
        console.warn('colors was configured but has itens without key')
        warnByKey = true
      }

      return 'blue'
    }

    if (!colorsMap[key]) {
      colorsMap[key] = COLORS[colorsCount % COLORS.length]
      colorsCount++
    }

    return colorsMap[key]
  }

  return colorByKey
}

export function clamp(x: number, min: number, max: number) {
  return Math.min(Math.max(x, min), max)
}
