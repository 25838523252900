import {allSidebarItems} from '../layouts/main-layout/sidebar/computeSidebarItems'
import {User} from '../services/api'

export function InitialRoutesWithPermissions(user?: User | null): {name: string; path: any} {
  const sidebar = allSidebarItems
  const newSidebar: any[] = []
  if (user) {
    for (const oneSidebar of sidebar) {
      if (oneSidebar.permission && oneSidebar.permission.every((permission) => user.permissions.includes(permission.name))) {
        if (oneSidebar.name !== 'Acesso Negado')
          newSidebar.push({
            name: oneSidebar.name,
            path: oneSidebar.link,
          })
      }
      if (newSidebar.length === 0 && oneSidebar.submenu) {
        for (const subMenu of oneSidebar.submenu) {
          if (subMenu.permission.every((permission) => user.permissions.includes(permission.name))) {
            newSidebar.push({
              name: subMenu.name,
              path: subMenu.link,
            })
          }
        }
      }
    }
    if (!newSidebar.length) {
      newSidebar.push({
        name: 'Acesso Negado',
        path: '/forbidden',
      })
      return {name: 'Acesso Negado', path: '/forbidden'}
    } else {
      return {name: newSidebar[0].name, path: newSidebar[0].path}
    }
  }
  return {name: 'Acesso Negado', path: '/forbidden'}
}
