import styled from 'styled-components'

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
`

export const ModalContent = styled.div`
  padding: 24px;

  @media (max-width: 767px) {
    max-width: 300px;
  }
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;

  @media (max-width: 767px) {
    flex-direction: column;
    min-width: 300px;
  }
`
