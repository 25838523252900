import {useSnackbar} from 'notistack'
import api, {Company, CompanyCredentials} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {ConfirmationModal} from '../../../../../components/ConfirmationModal/ConfirmationModal'

export type ConfirmCredentialsDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  setOpenCredentialsDialog: (open: boolean) => void
  setCredentials: (credentials: CompanyCredentials) => void
  company?: Company
}

function ConfirmCredentialsDialog({open, setOpen, setCredentials, setOpenCredentialsDialog, company}: ConfirmCredentialsDialogProps) {
  const {enqueueSnackbar} = useSnackbar()

  async function updateCredentials() {
    try {
      if (!company || !company.id) throw new Error('Empresa não encontrada')
      const response = await api.auth.updateCredentials(company.id)
      setCredentials(response.data.data)
      enqueueSnackbar('Credenciais atualizadas com sucesso', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar credenciais')
    }
  }

  return (
    <ConfirmationModal
      isOpen={open}
      title={'Renovar suas credenciais de acesso'}
      content={'Ao confirmar, suas credenciais de comunicação com os servidores de integração serão redefinidas.'}
      onClose={() => setOpen(false)}
      buttonsContent={[
        {
          label: 'Cancelar',
          onClick: () => setOpen(false),
          variant: 'outlined',
          color: '#8E8E8E',
        },
        {
          label: 'Confirmar',
          onClick: () => {
            setOpen(false)
            updateCredentials()
            setOpenCredentialsDialog(true)
          },
          variant: 'contained',
          color: '#009EFF',
        },
      ]}
    />
  )
}

export default ConfirmCredentialsDialog
