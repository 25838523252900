import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {Box, Button, Card, CardContent, CardHeader, List, ListItem, ListItemButton, ListItemText, Typography} from '@mui/material'
import {useState} from 'react'
import CompaniesSelect from 'src/layouts/main-layout/header/components/CompaniesSelect'
import {useAnalyticCamerasContext} from 'src/pages/analytic-cameras/context/AnalyticCamerasContext'
import {Company} from 'src/services/api'
import AppSearch from '../../../../../components/AppSearch'
import AppReloadButton from '../../../../../components/appReloadButton/AppReloadButton'
import createStyleSheet from '../../../../../utilities/createStyleSheet'

function CameraClientModal() {
  const {clients, step1, reloadClients, isLoadingClients, serversSearch, setServersSearch, loadAllServers, hasPermissionReadClients, setSelectedCompanyId} =
    useAnalyticCamerasContext()
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])

  return (
    <Box sx={styles.container}>
      <Card>
        <Box sx={styles.boxHeader}>
          <Box sx={{display: 'flex'}}>
            <CardHeader container sx={{}} title='Clientes' />
          </Box>
          <Box sx={{display: 'flex', gap: '10px'}}>
            <AppReloadButton loading={isLoadingClients} onClick={() => reloadClients()} sx={{marginRight: '16px'}} />
            {hasPermissionReadClients && (
              <Button
                startIcon={<AddCircleOutlineIcon sx={{width: 20, height: 20}} />}
                variant='contained'
                sx={styles.buttonAdd}
                onClick={() => {
                  window.open('/registers/clients?new')
                }}>
                Adicionar cliente
              </Button>
            )}
          </Box>
        </Box>
        <CardContent>
          <Box sx={{width: '100%', paddingBottom: '10px'}}>
            <CompaniesSelect
              selectLabel='Empresa'
              selectSize='small'
              setSelectedCompaniesIds={setSelectedCompanyId}
              companiesScroll={companiesScroll}
              setCompaniesScroll={setCompaniesScroll}
              pageCount={pageCount}
              setPageCount={setPageCount}
              companyNames={companyNames}
              setCompanyNames={setCompanyNames}
              multiple={false}
            />
          </Box>
          <Box sx={styles.searchContainer}>
            <AppSearch value={serversSearch} onChange={(e) => setServersSearch(e.target.value)} />
          </Box>
          {hasPermissionReadClients ? (
            <Box style={styles.listContainer}>
              <List>
                {clients.map((client, index) => (
                  <ListItem key={`client-${index}-${client.id}`} disablePadding onClick={() => step1(client)}>
                    <ListItemButton>
                      <ListItemText primary={client.name} onClick={() => loadAllServers()} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Box style={styles.listContainer}>
              <Typography sx={{color: '#FF9900'}}>*Você não possui permissão para ver a lista de clientes</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

const styles = createStyleSheet({
  container: {
    paddingTop: '1rem',
  },
  searchContainer: {
    paddingBottom: '1rem',
  },
  listContainer: {
    maxHeight: '42vh',
    overflowY: 'auto',
    height: '400px',
  },
  buttonAdd: {
    justifyContent: 'space-evenly',
    height: '2.7em',
    width: '13.5em',
    fontFamily: 'Inter',
    margin: '0px 16px 0px 0px',
    fontSize: '0.9rem',
    display: 'flex',
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  boxHeader: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    alignItems: 'center',
    gap: 1.5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default CameraClientModal
