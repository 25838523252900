import {Box, Button, Input, Modal, Typography} from '@viptech/react-components'
import {useEffect, useState} from 'react'
import {CameraImageDraw} from 'src/common/components/camera-image-draw/CameraImageDraw'
import {Pos} from 'src/common/types/generics/ImageDetections'
import {BtnClose} from 'src/components/BtnClose'
import NoImage from 'src/images/noImage.jpg'
import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {transformCoordinatesIntoPoints, transformPointsIntoCoordinates} from '../../../../common/generics/components/image-draw/ImageDrawFunctions'
import {useCameraConfigContext} from '../../context/CameraConfigContext'
import {CameraModalRegisterLineCrossingProps} from './CameraModalRegisterLineCrossingProps'

const DEFAULT_IMAGE_WIDTH = 390

export type KonvaLineCrossingPoints = {
  startX: number
  startY: number
  endX: number
  endY: number
}

function CameraModalRegisterLineCrossing(props: CameraModalRegisterLineCrossingProps) {
  const {open, imgSrc, lineCrossing, cameraId, enqueueSnackbar, onClose, reloadLineCrossings, height} = props
  const [lineCrossingPoints, setLineCrossingPoints] = useState<Array<Pos>>([])
  const [disableSaveButton, setDisableSaveButton] = useState(true)

  const [lineCrossingName, setLineCrossingName] = useState<string | undefined>(lineCrossing?.name)
  const {_dimImage} = useCameraConfigContext()
  const isEditing = !!lineCrossing

  function handleCloseModal() {
    setLineCrossingName('')
    setLineCrossingPoints([])
    onClose()
  }

  async function handleSaveLineCrossing(points: Pos[]) {
    try {
      if (!lineCrossingName) return
      const coordinates = transformPointsIntoCoordinates(points, _dimImage)

      if (isEditing) {
        await api.lineCrossing.updateLineCrossing({
          id: lineCrossing.id!,
          name: lineCrossingName,
          coordinates: coordinates,
        })
        enqueueSnackbar('Cruzamento de linha atualizado com sucesso', {variant: 'success'})
      } else {
        await api.lineCrossing.createLineCrossing({
          cameraId: cameraId,
          name: lineCrossingName,
          coordinates: coordinates,
        })
        enqueueSnackbar('Cruzamento de linha cadastrado com sucesso', {variant: 'success'})
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, `Falha ao tentar ${isEditing ? 'editar' : 'salvar'} cruzamento de linha`)
    } finally {
      reloadLineCrossings()
      handleCloseModal()
    }
  }

  const countPointsToDisableButton = (points: Pos[]) => {
    if (!lineCrossingName || points.length < 2) setDisableSaveButton(true)
    else setDisableSaveButton(false)
  }

  const [save, setSave] = useState<string>()

  useEffect(() => {
    const coordinates = lineCrossing?.coordinates
    if (isEditing && coordinates) setLineCrossingPoints(transformCoordinatesIntoPoints(coordinates, _dimImage))
  }, [])

  return (
    <Modal isOpen={open} closeModal={() => handleCloseModal()} backgroundColor='white'>
      <BtnClose onClose={() => handleCloseModal()} />
      <Box padding='40px' minWidth='600px'>
        <Typography variant='p' size='20px' style={{fontWeight: 500}}>
          Cadastro de Cruzamento de Linha
        </Typography>
        <Box display='flex' direction='column' rowGap='20px' marginTop='20px'>
          <Input.Root
            label='Informe um nome para o cruzamento de linha'
            onChange={(e) => setLineCrossingName(e.target.value)}
            defaultValue={lineCrossingName}
            value={lineCrossingName}
          />
        </Box>
        <Box display='flex' direction='column' rowGap='10px' marginTop='20px'>
          <Typography variant='p' size='14px' style={{fontWeight: 500}} color='rgb(54, 65, 82);'>
            Selecione os pontos do cruzamento de linha
          </Typography>
          <Box>
            <CameraImageDraw
              width={DEFAULT_IMAGE_WIDTH}
              height={height}
              points={lineCrossingPoints}
              editable={true}
              src={imgSrc === `data:image/jpeg;base64,null` ? NoImage : imgSrc}
              onSave={(points) => handleSaveLineCrossing(points)}
              getPoints={(points) => countPointsToDisableButton(points)}
              save={save}
              maxAmountOfPoints={2}
            />
          </Box>
        </Box>

        <Box display='flex' justify='flex-end' marginTop='30px' columnGap='10px'>
          <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => handleCloseModal()}>
            Cancelar
          </Button>

          <Button fontSize='12px' disabledTextColor='#8E8E8E' height='40px' width='150px' onClick={() => setSave('save')} disabled={disableSaveButton}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default CameraModalRegisterLineCrossing
