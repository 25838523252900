import {Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {Box} from '@viptech/react-components'
import {useState} from 'react'
import actionsObject from 'src/images/actionsObject.svg'
import Delete from 'src/images/deleteIconBlack.svg'
import List from 'src/images/iconSeeList.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'

type ContextMenuProps = {
  rowId: string
  onClick: (event: string, oneObject: string) => void
  hasDeletePermission: boolean
  hideLists?: boolean
}

export const ListsContextMenu = (listsContextProps: ContextMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const {onClick, hasDeletePermission, rowId, hideLists} = listsContextProps
  return (
    <>
      <Tooltip title='Opções' placement='top'>
        <Button
          style={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        {!hideLists && (
          <MenuItem
            style={styles.menuInfos}
            onClick={() => {
              setAnchorEl(null)
              onClick('list-items', rowId)
            }}>
            <img src={List} alt='Menu logo' style={{width: 20, height: 20}} />
            <Box style={{fontFamily: 'Inter', paddingLeft: 0.9, fontSize: '14px'}}>Listar itens</Box>
          </MenuItem>
        )}

        <MenuItem
          style={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick('delete', rowId)
          }}
          disabled={!hasDeletePermission}>
          <img src={Delete} alt='Menu logo' style={{width: 20, height: 20}} />
          <Box style={{fontFamily: 'Inter', paddingLeft: 0.9, fontSize: '14px'}}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = createStyleSheet({
  menuInfos: {
    fontSize: '12px',
    padding: '8px 16px',
    display: 'flex',
    gap: '4px',
  },
})
