const isLetter = (value: string) => /^[a-zA-Z]+$/.test(value)
const isNumber = (value: string) => /^[0-9]+$/.test(value)

export const applyMask = (value: string, mask: string, cursorPosition: number): {outputValue: string; newCursorPosition: number} => {
  if (!mask) return {outputValue: value, newCursorPosition: cursorPosition}
  // eslint-disable-next-line no-useless-escape
  const normalizedValue = value.replace(/[\(\)\-\.\s]/g, '')
  if (!normalizedValue.length) return {outputValue: '', newCursorPosition: 0}
  const isLetterMask = mask.includes('a') || mask.includes('A')
  const isNumberMask = mask.includes('0')
  let outputValue = ''
  let normalizedValueIndex = 0

  const maskValues = ['a', 'A', '0']
  let newCursorPosition = cursorPosition

  if (!maskValues.includes(mask[cursorPosition - 1])) newCursorPosition++

  for (let i = 0; i < mask.length; i++) {
    if (normalizedValue.length === normalizedValueIndex) break
    let char = mask[i]

    if (!maskValues.includes(char)) {
      outputValue += char
      char = mask[i + 1]
      if (maskValues.includes(char)) i++
    }

    if (mask[cursorPosition] === ' ') newCursorPosition++

    if (char === '0') {
      if (!isNumber(normalizedValue[normalizedValueIndex])) {
        normalizedValueIndex++
        i--
        if (!isLetterMask && !isNumberMask) newCursorPosition--
        continue
      }
      outputValue += normalizedValue[normalizedValueIndex]
      normalizedValueIndex++
    } else if (char === 'a') {
      if (!isLetter(normalizedValue[normalizedValueIndex])) {
        normalizedValueIndex++
        i--
        if (!isLetterMask && !isNumberMask) newCursorPosition--
        continue
      }
      outputValue += normalizedValue[normalizedValueIndex].toLowerCase()
      normalizedValueIndex++
    } else if (char === 'A') {
      if (!isLetter(normalizedValue[normalizedValueIndex])) {
        normalizedValueIndex++
        i--
        if (!isLetterMask && !isNumberMask) newCursorPosition--
        continue
      }
      outputValue += normalizedValue[normalizedValueIndex].toUpperCase()
      normalizedValueIndex++
    }
  }

  return {newCursorPosition, outputValue}
}
