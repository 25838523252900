import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #009eff;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 30px;

  @media (max-width: 767px) {
    margin-top: 10px;
    font-size: 10px;
  }
`

export const Button = styled.button<{selected?: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  min-width: 210px;
  ${({selected}) =>
    selected
      ? `}
    background-color: #009EFF;
    color: white;
    `
      : `
    background-color: white;
    color: #344054;
    `}
  font-size: 14px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  padding: 10px 32px;
  transition: background-color 0.3s;

  @media (max-width: 767px) {
    min-width: 10px;
  }
`
