import {Box} from '@viptech/react-components'
import {useTheme} from 'styled-components'
import {SeparationLine, SeparationTitle} from './SeparationBase'
import {SeparationProps} from './SeparationProps'

export default function Separation({title, ...props}: SeparationProps) {
  const theme = useTheme()

  return (
    <Box display='flex' align='center' width='100%' {...props}>
      {title && (
        <SeparationTitle variant={props.variant ?? 'h3'} color={props.color ?? theme.colors.primary} margin='0'>
          {title}
        </SeparationTitle>
      )}
      <SeparationLine title={title} color={props.color ?? theme.colors.primary} height={props.height} width={props.width} />
    </Box>
  )
}
