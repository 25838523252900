import {AxiosError} from 'axios'
import {useEffect, useRef, useState} from 'react'
import {ListFacialRecognitionDTO} from 'src/common/models/facial-recognition/ListFacialRecognitionDTO'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import api from 'src/services/api'
import {LicensePlateList} from 'src/services/api/endpoints/LprEndpoint'
import {Paginate} from 'src/services/api/endpoints/_types'

export type List = {
  id: number
  name: string
  clientId: number
  companyId: number
  client: string
  type: 'Faces' | 'Placas'
}

type ListProps = {
  filter?: string
  page: number
  companyIds?: number[]
}

function useLists(props: ListProps) {
  const {filter: nameFilter, page, companyIds = undefined} = props
  const [lists, setLists] = useState<List[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError | null>(null)
  const [canLoadMore, setCanLoadMore] = useState(true)
  const hasListFacePermission = ValidatePermissions('face:read')
  const hasListLprPermission = ValidatePermissions('lpr:read')
  const blockRequests = useRef(false)
  const faceReachedMaximum = useRef(false)
  const lprReachedMaximum = useRef(false)

  async function getLists(replace: boolean = true) {
    if (blockRequests.current) return
    blockRequests.current = true
    setIsLoading(true)
    try {
      let facialRes: Paginate<ListFacialRecognitionDTO> = {} as Paginate<ListFacialRecognitionDTO>
      let lprRes: Paginate<LicensePlateList> = {} as Paginate<LicensePlateList>
      if (hasListFacePermission) {
        facialRes = (await api.facialRecognition.getList({search: {listName: nameFilter}, filter: {companyIds}})).data.data
      }
      if (hasListLprPermission) {
        lprRes = (await api.lpr.getManyLists({page, pageSize: 20, search: {listName: nameFilter}, filter: {companyIds}})).data.data
      }
      const facial = facialRes.entities
      const lpr = lprRes.entities
      if (facialRes.totalElements !== 20) faceReachedMaximum.current = true
      if (lprRes.totalElements !== 20) lprReachedMaximum.current = true
      if (faceReachedMaximum.current && lprReachedMaximum.current) setCanLoadMore(false)
      const clientsIds = [...facial.map((it) => it.clientId), ...lpr.map((it) => it.clientId)].filter((value, index, array) => array.indexOf(value) === index)
      const clients = (await api.client.getMany({page, pageSize: 100, filter: {ids: clientsIds}})).data.data.entities
      const mergedList: List[] = replace ? [] : lists
      if (facial.length > 0) {
        facial.map((f) => {
          return mergedList.push({
            id: f.id,
            name: f.name,
            clientId: f.clientId,
            companyId: f.companyId,
            client: clients.find((client) => client.id === f.clientId)?.name || 'Lista compartilhada',
            type: 'Faces',
          })
        })
      }
      if (lpr.length > 0) {
        lpr.map((l) => {
          return mergedList.push({
            id: l.id,
            name: l.name,
            clientId: l.clientId,
            companyId: l.companyId,
            client: clients.find((client) => client.id === l.clientId)?.name || 'Lista compartilhada',
            type: 'Placas',
          })
        })
      }
      setLists(mergedList.filter((value, index, array) => array.findIndex((t) => t.id === value.id && t.type === value.type) === index))
      setError(null)
    } catch (err) {
      const error = err as AxiosError
      setError(error)
    } finally {
      blockRequests.current = false
      setIsLoading(false)
    }
  }

  async function deleteManyLists(facesIds: number[], platesIds: number[]) {
    if (!hasListFacePermission || !hasListLprPermission) return
    try {
      setIsLoading(true)
      if (hasListFacePermission && facesIds.length) {
        await api.facialRecognition.deleteManyFacialList(facesIds)
      }

      if (hasListLprPermission && platesIds.length) {
        await api.lpr.deleteManyPlateLists(platesIds)
      }
    } catch (err) {
      const error = err as AxiosError
      throw error
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getLists()
  }, [])

  return {lists, isLoading, error, canLoadMore, refresh: getLists, deleteManyLists}
}

export default useLists
