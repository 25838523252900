import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Checkbox, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {Params, useNavigate, useParams} from 'react-router-dom'
import {SidebarOption, initialSidebarOptions} from 'src/components/ListCheckboxToPermissions'
import Loading from 'src/components/Loading'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api, {Company} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useAuth} from '../../../contexts/AuthContext'

const CompanyRegisterPermissions = () => {
  const [sidebarOptions, setSidebarOptions] = useState<Array<SidebarOption>>(
    JSON.parse(JSON.stringify(initialSidebarOptions.filter((option) => option.scope === 'company'))),
  )
  const [hiddenItems, setHiddenItems] = useState<Array<SidebarOption>>([])
  const {id: maybeId} = useParams<Params>()
  const {enqueueSnackbar} = useSnackbar()
  const initialCompany = {id: Number(maybeId), name: '', document: ''}
  const [company, setCompany] = useState<Company>(initialCompany)
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false)
  const navigate = useNavigate()
  const {sidebarTitle, setSidebarTitle} = useApp()
  const itemsToHide = ['clients']
  const {refreshToken} = useAuth()

  async function updatePermissions(list: string[]) {
    hiddenItems.forEach((item) => {
      item.crudItems.forEach((crudItem) => {
        crudItem.value = true
      })
      if (item.label === 'layouts' && !list.find((permission) => permission === 'livestream:read')) {
        item.crudItems.forEach((crudItem) => {
          crudItem.value = false
        })
      }
    })
    const flatHiddenItems = hiddenItems.flatMap((option) => option.crudItems.filter((item) => item.value).flatMap((item) => item.permission))
    list.push(...flatHiddenItems)
    try {
      await api.company.updatePermissions({
        id: company.id,
        permissions: list,
      })
      enqueueSnackbar('Permissões atualizadas com sucesso', {
        variant: 'success',
      })
      navigate('/administrative/companies')
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar permissões da empresa')
    }
  }

  async function handleRefreshToken() {
    localStorage.removeItem('needsRefresh')
    await refreshToken()
  }

  useEffect(() => {
    const id = Number(maybeId)
    if (!id || isNaN(id)) {
      navigate('administrative/companies')
    } else {
      if (company.name) {
        const title = `${sidebarTitle} > ${company && company.name}`
        setSidebarTitle(title)
      }
    }
  }, [company, maybeId])

  useEffect(() => {
    async function getAllCompanies() {
      setLoadingPermissions(true)
      try {
        if (localStorage.getItem('needsRefresh') === 'true') await handleRefreshToken()
        const response = await api.company.getMany({
          filter: {
            ids: [company.id],
          },
          includes: ['permissions'],
        })
        let resCompany = response.data.data.entities[0]
        resCompany.permissions = resCompany.permissions?.filter((permission) => permission.scope === 'company')
        setCompany(resCompany)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar permissões da empresa')
      } finally {
        setLoadingPermissions(false)
      }
    }
    getAllCompanies()
    itemsToHide.forEach((item) => {
      const itemIndex = sidebarOptions.findIndex((option) => option.label === item)
      if (itemIndex !== -1) {
        const item = sidebarOptions.splice(itemIndex, 1)[0]
        setHiddenItems((prev) => [...prev, item])
      }
    })
  }, [])

  useEffect(() => {
    setSidebarOptions((prev) => {
      prev.forEach((option) => {
        option.crudItems.forEach((crudItem) => {
          if (crudItem.permission && company.permissions?.map((it) => it.codename).includes(crudItem.permission)) {
            crudItem.value = true
          }
        })
      })
      return [...prev]
    })
  }, [company.permissions])

  const handlePermissionChange = (optionIndex: number, itemIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedOptions = [...sidebarOptions]

    if (itemIndex === 0) {
      const newValue = !updatedOptions[optionIndex].crudItems[0].value
      if (newValue && updatedOptions[optionIndex].label === 'rtsp-camera') {
        updatedOptions
          .find((option) => option.label === 'cameras')
          ?.crudItems.forEach((item, i) => {
            item.value = newValue
          })
      } else if (!newValue && updatedOptions[optionIndex].label === 'cameras') {
        updatedOptions
          .find((option) => option.label === 'rtsp-camera')
          ?.crudItems.forEach((item, i) => {
            item.value = newValue
          })
        updatedOptions
          .find((option) => option.label === 'layouts')
          ?.crudItems.forEach((item, i) => {
            item.value = newValue
          })
      } else if (newValue && updatedOptions[optionIndex].label === 'layouts') {
        updatedOptions
          .find((option) => option.label === 'rtsp-camera')
          ?.crudItems.forEach((item, i) => {
            item.value = newValue
          })
        updatedOptions
          .find((option) => option.label === 'cameras')
          ?.crudItems.forEach((item, i) => {
            item.value = newValue
          })
      } else if (!newValue && updatedOptions[optionIndex].label === 'rtsp-camera') {
        updatedOptions
          .find((option) => option.label === 'layouts')
          ?.crudItems.forEach((item, i) => {
            item.value = newValue
          })
      }
      updatedOptions[optionIndex].crudItems.forEach((item, i) => {
        item.value = newValue
      })
      setSidebarOptions(updatedOptions)
    }
  }

  const handleSavePermissions = () => {
    const filterPermissions = sidebarOptions.flatMap((option) => option.crudItems.filter((item) => item.value).flatMap((item) => item.permission))
    updatePermissions(filterPermissions)
  }

  if (loadingPermissions) {
    return (
      <Box sx={styles.loadingPaper}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box sx={styles.paper}>
      <Box sx={styles.contentPermissions}>
        <Box sx={styles.boxWithContent}>
          <Box sx={styles.listPermissions}>
            <Typography variant='h4' sx={styles.title}>
              Definir permissões
            </Typography>
            <Box sx={styles.listContainer}>
              {sidebarOptions.map((option, i) => (
                <Box key={i} sx={styles.itemContainer}>
                  <Checkbox checked={option.crudItems.every((perm) => perm.value === true)} onChange={handlePermissionChange(i, 0)} sx={{marginBottom: 0.5}} />
                  <Typography style={{marginBottom: '0.5rem', marginTop: '0.5rem'}}>{option.name}</Typography>
                </Box>
              ))}
            </Box>
          </Box>

          <Box sx={styles.boxButtons}>
            <Button onClick={() => navigate('/administrative/companies')} sx={styles.cancelButton}>
              Cancelar
            </Button>
            <LoadingButton variant='contained' onClick={() => handleSavePermissions()} sx={styles.addButton}>
              Salvar
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CompanyRegisterPermissions
const styles = createStyleSheet({
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    width: '100%',
    maxHeight: '85vh',
    borderTop: '5px solid #FFFFFF',
    borderBottom: '25px solid #FFFFFF',
    overflow: 'auto',
  },
  addButton: {
    color: '#FFFFFF',
    width: '180px',
    fontFamily: 'Inter',
    borderRadius: '4px',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    textTransform: 'none',
  },
  cancelButton: {
    width: '180px',
    height: '40px',
    marginRight: 1,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  loadingPaper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: '10px 30px',
    height: '86vh',
    overflow: 'scroll',
  },
  title: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    padding: '1rem 0 0 0',
    zIndex: 1,
    paddingBottom: '1rem',
  },
  notPermissions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: '10px 30px',
    height: '86vh',
    overflow: 'scroll',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '1rem',
  },
  contentPermissions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0rem 1.5rem',
  },
  boxWithContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
  },
  listPermissions: {
    overflowY: 'auto',
    height: '90%',
  },
  checkboxs: {
    width: '170px',
    marginBottom: '1.3rem',
    marginTop: 2,
  },
  itemsCheck: {
    width: '130px',
    marginBottom: 3,
  },
  checkboxAllItems: {
    width: '8%',
    marginBottom: '0.5rem',
    position: 'sticky',
    backgroundColor: '#FFFFFF',
    top: 56,
    zIndex: 1,
  },
  checkboxes: {
    width: '130px',
    marginBottom: '0.5rem',
  },
  labelContainer: {
    height: '67px',
  },
  listContainer: {
    display: 'flex',
    paddingTop: '15px',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexGrow: 1,
    flexBasis: 'auto',
    gap: '40px',
    overflowY: 'hidden',
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    padding: '0.5rem 1rem',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    width: '410px',
  },
})
