import {Box, Button} from '@viptech/react-components'
import lodash from 'lodash'
import {useEffect, useRef, useState} from 'react'
import CopieIconChangeColor from 'src/images/CopieIcon'
import {AddressInfo, Camera, EditAddressInfo} from 'src/services/api'
import {generateLinkRtsp, generateLinkRtspDVR} from 'src/utilities/LinkGenerator'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Dropdown from '../../../../components/dropdown/Dropdown'
import {DropdownItem} from '../../../../components/dropdown/DropdownProps'
import {Input} from '../../../../components/input/Input'
import CopieIcon from '../../../../images/copieIcon.svg'
import {AccessContainerBox} from '../../../cameras/newComponents/styles'
import {AccessContainerLastBox, TitleConnection, TitleContainer} from './CameraConfigConnectionBase'

type CameraConfigConnectionProps = {
  camera: Camera
  isEditing: boolean
  setAddressInfo: (addressInfo: EditAddressInfo) => void
  isCancel: boolean
  enqueueSnackbar: any
}

const EnumCameraManufacturer: DropdownItem[] = [
  {label: 'Intelbras', id: '1'},
  {label: 'Hikvision', id: '2'},
]

const streamOptions: DropdownItem[] = [
  {id: '0', label: 'Main-stream'},
  {id: '1', label: 'Sub-stream'},
]

export function CameraConfigConnection({camera, isEditing, setAddressInfo, isCancel, enqueueSnackbar}: CameraConfigConnectionProps) {
  const initialAddressInfo = camera.addressInfo ?? {
    channel: 1,
    codec: '',
    host: '',
    httpPort: 0,
    id: 0,
    manufacturer: '',
    password: '',
    rtspPort: 0,
    stream: 0,
    user: '',
    link: null,
  }

  const [linkRTSP, setLinkRTSP] = useState<AddressInfo>(initialAddressInfo)

  const [selectedManufacturer, setSelectedManufacturer] = useState({
    id: '',
    label: '',
  })

  const [selectedStream, setSelectedStream] = useState({
    id: '',
    label: '',
  })

  useEffect(() => {
    if (initialAddressInfo.manufacturer && initialAddressInfo.stream >= 0) {
      const manufacturerId = EnumCameraManufacturer.find((it) => it.label === initialAddressInfo.manufacturer) ?? {id: '', label: ''}
      setSelectedManufacturer({
        id: manufacturerId.id,
        label: initialAddressInfo.manufacturer,
      })

      const stremLabel = streamOptions.find((it) => +it.id === initialAddressInfo.stream)?.label ?? ''
      setSelectedStream({
        id: String(initialAddressInfo.stream),
        label: stremLabel,
      })
    }
  }, [initialAddressInfo.manufacturer, initialAddressInfo.stream])

  const setPartialLinkRTSP = (addressInfo: Partial<AddressInfo>) => {
    setLinkRTSP((link) => {
      return {...link, ...addressInfo}
    })
  }

  const cameraCreatedWithDirectLink = () => {
    if (camera.addressInfo && camera.addressInfo.host == null && camera.addressInfo.link) return true
    return false
  }

  const cameraIsDVR = camera.origin === 'RTSP-DVR'

  const showDirectLink = cameraCreatedWithDirectLink()

  function maskValue(value: string, max: number): string {
    const numericValue = +value.replace(/\D/g, '')
    const isValid = numericValue <= max && numericValue >= 0
    if (isValid) {
      return numericValue ? String(numericValue) : ''
    } else {
      return value.slice(0, -1)
    }
  }

  const checkIsValidLink = useRef(false)
  const errorMessage = useRef<string | undefined>(undefined)

  const validateLink = () => {
    if (initialAddressInfo.link) {
      const hasProtocol = initialAddressInfo.link.includes('://')
      const hasUsernamePassword = initialAddressInfo.link.includes('@')

      const isValidLink = hasProtocol && hasUsernamePassword
      checkIsValidLink.current = isValidLink
      if (!isValidLink) errorMessage.current = 'Link inválido'
      else errorMessage.current = undefined
    } else {
      checkIsValidLink.current = false
      errorMessage.current = 'Link inválido'
    }
  }

  const handleCopieLink = () => {
    if (isEditing) copieEditedLink()
    else {
      if (!initialAddressInfo.link) {
        enqueueSnackbar('Ops, você ainda não tem um link para copiar, preencha todos os campos', {variant: 'warning'})

        return
      }
      navigator.clipboard.writeText(initialAddressInfo.link)
      enqueueSnackbar('Copiado com sucesso', {variant: 'success'})
    }
  }

  const copieEditedLink = () => {
    if (showDirectLink) {
      if (!linkRTSP.link) return
      navigator.clipboard.writeText(linkRTSP.link)
      enqueueSnackbar('Copiado com sucesso', {variant: 'success'})
    } else {
      const link = cameraIsDVR
        ? generateLinkRtspDVR({addressInfo: linkRTSP, camera, enqueueSnackbar})
        : generateLinkRtsp({addressInfo: linkRTSP, camera, enqueueSnackbar})
      if (link) {
        navigator.clipboard.writeText(link)
        enqueueSnackbar('Copiado com sucesso', {variant: 'success'})
      }
    }
  }

  useEffect(() => {
    if (!showDirectLink) {
      const validFields = lodash.clone(linkRTSP)
      validFields.link = cameraIsDVR ? generateLinkRtspDVR({addressInfo: linkRTSP, camera, enqueueSnackbar}) : generateLinkRtsp({addressInfo: linkRTSP, camera})
      Object.entries(validFields).forEach(([key, value]) => {
        if (initialAddressInfo[key as keyof typeof initialAddressInfo] === value) {
          delete validFields[key as keyof typeof validFields]
        }
      })
      if (Object.values(validFields).length > 0) {
        setAddressInfo(validFields)
      }
    } else {
      validateLink()
      if (linkRTSP.link) setAddressInfo({link: linkRTSP.link})
    }
  }, [linkRTSP, setAddressInfo])

  useEffect(() => {
    if (isCancel) {
      setForceReloadForms(forceReloadForms + 1)
      setLinkRTSP(initialAddressInfo)
      setAddressInfo(initialAddressInfo)
    }
  }, [isCancel, initialAddressInfo])

  const [forceReloadForms, setForceReloadForms] = useState(0)
  return (
    <Box style={styles.container}>
      <TitleContainer>
        <TitleConnection>
          <b>Nome da Câmera:</b> {camera.name}
        </TitleConnection>

        <TitleConnection>
          <b>Origem:</b> {camera.origin}
        </TitleConnection>
      </TitleContainer>

      <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center', width: '600px'}}>
        {showDirectLink ? (
          <div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '20px', width: '600px'}}>
            <Input.Root
              maxLength={150}
              labelFontWeight='400'
              key={forceReloadForms + 2}
              width='70%'
              label='Link RTSP'
              labelFontSize='12px'
              value={initialAddressInfo.link || ''}
              defaultValue={initialAddressInfo.link || ''}
              disabled={!isEditing}
              onChange={(e) =>
                setPartialLinkRTSP({
                  link: e.target.value,
                })
              }
              error={!checkIsValidLink.current}
              errorMessage={errorMessage.current}
            />

            <Button fontSize='12px' variant='outlined' disabledTextColor='#8E8E8E' height='40px' width='170px' onClick={() => handleCopieLink()}>
              <img src={CopieIcon} alt='Copiar link RTSP' />
              {isEditing ? 'Copiar link editado' : 'Copiar link RTSP'}
            </Button>
          </div>
        ) : (
          <div>
            <AccessContainerBox>
              <div style={{width: '50%'}}>
                <Dropdown
                  key={forceReloadForms + 1}
                  height='40px'
                  fontSize='12px'
                  label='Fabricante da câmera'
                  labelFontSize='12px'
                  placeholder='Fabricante da câmera'
                  items={EnumCameraManufacturer}
                  disabled={!isEditing}
                  selected={selectedManufacturer || ''}
                  onChangeSelected={(e) => {
                    setSelectedManufacturer(e)
                    setPartialLinkRTSP({
                      manufacturer: e.label,
                    })
                  }}></Dropdown>
              </div>

              <div style={{width: '50%', display: 'flex', gap: '20px'}}>
                <Dropdown
                  height='40px'
                  fontSize='12px'
                  key={forceReloadForms + 3}
                  placeholder='Stream'
                  labelFontSize='12px'
                  label='Stream'
                  disabled={!isEditing}
                  selected={selectedStream || ''}
                  items={streamOptions}
                  onChangeSelected={(e) => {
                    setSelectedStream(e)
                    setPartialLinkRTSP({
                      stream: +e.id,
                    })
                  }}></Dropdown>
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  label='Canal'
                  labelFontSize='12px'
                  key={forceReloadForms + 4}
                  type='number'
                  disabled={!isEditing}
                  placeholder='Informe o canal'
                  defaultValue={String(initialAddressInfo.channel)}
                  min={1}
                  mask={(value) => maskValue(value, 100)}
                  value={`${initialAddressInfo.channel}` || ''}
                  onChange={(e) => {
                    setPartialLinkRTSP({
                      channel: +e.target.value,
                    })
                  }}
                />
              </div>
            </AccessContainerBox>

            <AccessContainerBox>
              <div style={{width: '50%'}}>
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  placeholder='Insira o host'
                  key={forceReloadForms + 5}
                  label='Host'
                  labelFontSize='12px'
                  defaultValue={initialAddressInfo.host}
                  disabled={!isEditing}
                  value={initialAddressInfo.host ?? ''}
                  onChange={(e) => {
                    setPartialLinkRTSP({
                      host: e.target.value,
                    })
                  }}
                />
              </div>

              <div style={{width: '50%', display: 'flex', gap: '20px'}}>
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  type='number'
                  defaultValue={String(initialAddressInfo.httpPort)}
                  key={forceReloadForms + 6}
                  label='Porta HTTP'
                  labelFontSize='12px'
                  disabled={!isEditing}
                  placeholder='Insira a porta HTTP'
                  value={`${initialAddressInfo.httpPort}` || ''}
                  mask={(value) => maskValue(value, 65535)}
                  onChange={(e) => {
                    setPartialLinkRTSP({
                      httpPort: +e.target.value,
                    })
                  }}
                />
                <Input.Root
                  maxLength={50}
                  labelFontWeight='400'
                  labelFontSize='12px'
                  key={forceReloadForms + 7}
                  type='number'
                  defaultValue={String(initialAddressInfo.rtspPort)}
                  placeholder='Insira a porta RTSP'
                  label='Porta RTSP'
                  disabled={!isEditing}
                  mask={(value) => maskValue(value, 65535)}
                  value={`${initialAddressInfo.rtspPort}` || ''}
                  onChange={(e) => {
                    setPartialLinkRTSP({
                      rtspPort: +e.target.value,
                    })
                  }}
                />
              </div>
            </AccessContainerBox>

            <AccessContainerBox>
              <Input.Root
                maxLength={50}
                labelFontWeight='400'
                key={forceReloadForms + 8}
                placeholder='Insira o usuário'
                labelFontSize='12px'
                label='Usuário'
                defaultValue={initialAddressInfo.user}
                disabled={!isEditing}
                value={initialAddressInfo.user || ''}
                onChange={(e) => {
                  setPartialLinkRTSP({
                    user: e.target.value,
                  })
                }}
              />
              <Input.Root
                maxLength={50}
                labelFontWeight='400'
                placeholder='Insira a senha'
                key={forceReloadForms + 9}
                labelFontSize='12px'
                label='Senha'
                defaultValue={initialAddressInfo.password}
                disabled={!isEditing}
                type='password'
                hasIcon
                value={initialAddressInfo.password || ''}
                onChange={(e) => {
                  setPartialLinkRTSP({
                    password: e.target.value,
                  })
                }}
              />
            </AccessContainerBox>

            <AccessContainerLastBox>
              <Button fontSize='12px' variant='outlined' disabledTextColor='#8E8E8E' height='40px' width='170px' onClick={() => handleCopieLink()}>
                <CopieIconChangeColor color='#009EFF' />
                {isEditing ? 'Copiar link editado' : 'Copiar link RTSP'}
              </Button>
            </AccessContainerLastBox>
          </div>
        )}
      </div>
    </Box>
  )
}

const styles = createStyleSheet({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '30px',
    gap: '30px',
  },
})
