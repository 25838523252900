import {objectIsInterestArea} from 'src/pages/camera-config/components/object-detection/newComponents/stepperModal/StepperModal'
import api, {CameraHasObject, Trigger} from 'src/services/api'
import {LineCrossingObject} from 'src/services/api/endpoints/ObjectEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export type RowType = {
  id: number
  typeObject: string
  barrierType: string
  barrier: string
  active: boolean
  description?: string
  confidence: string
  drive: string
  trigger: Trigger[] | null | undefined
  self: LineCrossingObject | CameraHasObject
}

export const listTypes = ['Área de interesse', 'Cruzamento de linha']

export function objectDetectionRow(objectItemsInterest: Array<CameraHasObject>, listLineCrossing: Array<LineCrossingObject>): Array<RowType> {
  const lineCrossingObjects = listLineCrossing.map((it) => {
    return {
      id: it.id!,
      typeObject: it.objectType.label,
      barrierType: 'Cruzamento de linha',
      barrier: it.lineCrossing!.name,
      active: it.isActive,
      description: it.description !== null ? it.description : 'Sem descrição',
      confidence: `${Math.round(Number(it.confidence) * 100)}%`,
      drive: it.actions.length > 0 ? 'Sim' : 'Não',
      trigger: undefined,
      self: it,
    }
  })
  const interestAreaObjects = objectItemsInterest.map((it) => {
    return {
      id: it.id,
      typeObject: it.objectType.label,
      barrierType: 'Área de interesse',
      barrier: it.interestArea?.name!,
      active: it.isActive,
      description: it.description !== null ? it.description : 'Sem descrição',
      confidence: `${Math.round(Number(it.confidence) * 100)}%`,
      drive: it.actions.length > 0 ? 'Sim' : 'Não',
      trigger: it.trigger,
      self: it,
    }
  })
  return [...interestAreaObjects, ...lineCrossingObjects]
}
export async function deleteObject(self: any, loadObjects: () => void, enqueueSnackbar: any) {
  try {
    if (self.lineCrossing) {
      await api.lineCrossing.deleteLineCrossing(self.lineCrossing.id)
    } else {
      await api.object.removeInterestAreaObject(self.id!)
    }
    enqueueSnackbar('Objeto excluido com sucesso', {
      variant: 'success',
    })
    loadObjects()
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Falha ao excluir objetos')
  }
}

async function updateObject(checked: boolean, row: RowType, enqueueSnackbar: any, getLoadObjetcs: () => void) {
  try {
    if (!objectIsInterestArea(row.self)) await api.object.updateLineCrossingObject({id: row.id, isActive: checked})
    else await api.object.updateInterestAreaObjects({id: row.id, isActive: checked})
    enqueueSnackbar('Objeto atualizado com sucesso', {
      variant: 'success',
    })
    getLoadObjetcs()
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar objeto')
  }
}

export async function updateIsActive(checked: boolean, row: RowType, enqueueSnackbar: any, getLoadObjetcs: () => void) {
  await updateObject(checked, row, enqueueSnackbar, getLoadObjetcs)
}
