import createStyleSheet from 'src/utilities/createStyleSheet'

export const cameraConfigStyles = createStyleSheet({
  buttonEdit: {
    color: 'white',
    width: '180px',
    height: '40px',
    textTransform: 'none',
    backgroundColor: '#20bc30',
    '&:hover': {
      backgroundColor: '#00850e',
    },
  },
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: 1.5,
    height: '100vh',
    overflow: 'auto',
  },
  cancelButton: {
    width: '180px',
    height: '40px',
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  addButton: {
    width: '180px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  content: {
    overflow: 'auto',
  },
  loading: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
  },
})
