import {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {MenuContainer, MenuContainerWrapper, MenuContent, MenuTriangle, MenuTriangleContainer} from './MenuBase'
import {MenuProps} from './MenuProps'

const MenuVerticalSafeArea = 200
const MenuHorizontalSafeArea = 200

const Menu = ({anchor, children, onClose, ...props}: MenuProps) => {
  const [isNearScreenBottomEdge, setIsNearScreenBottomEdge] = useState(false)
  const [isNearScreenRightEdge, setIsNearScreenRightEdge] = useState(false)
  const [isNearScreenLeftEdge, setIsNearScreenLeftEdge] = useState(false)
  const menuWrapperRef = useRef<HTMLDivElement>(null)

  const handleDocumentOverflow = () => {
    if (document.body.style.overflow === 'hidden') return
    else if (anchor) document.body.style.overflow = 'hidden'
    else document.body.style.overflow = 'auto'
  }

  useEffect(() => {
    if (!anchor || !menuWrapperRef.current) return
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose?.()
    }

    const handleDocumentClick = (event: MouseEvent) => {
      if (!menuWrapperRef.current?.contains(event.target as Node)) onClose?.()
    }

    document.addEventListener('mousedown', handleDocumentClick)

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [anchor])

  useLayoutEffect(() => {
    handleDocumentOverflow()
    if (!anchor || !menuWrapperRef.current) return

    const {top: anchorTop, left: anchorLeft, right: anchorRight, bottom: anchorBottom, width: anchorWidth} = anchor.getBoundingClientRect()

    const width = window.innerWidth
    const height = window.innerHeight

    const {width: menuElementWidth, height: menuElementHeight} = menuWrapperRef.current.getBoundingClientRect()

    const isNearScreenBottomEdge = anchorBottom + menuElementHeight > height - MenuVerticalSafeArea
    const isNearScreenRightEdge = anchorRight + menuElementWidth > width - MenuVerticalSafeArea
    const isNearScreenLeftEdge = anchorLeft - menuElementWidth < MenuHorizontalSafeArea && anchorLeft < menuElementWidth
    const bottom = isNearScreenBottomEdge ? Math.max(anchorTop - menuElementHeight, 0) : anchorBottom

    if (isNearScreenBottomEdge)
      menuWrapperRef.current.style.transform = `translate(${isNearScreenLeftEdge ? anchorLeft : anchorLeft - (menuElementWidth - anchorWidth)}px, ${bottom}px)`
    else if (isNearScreenRightEdge) menuWrapperRef.current.style.transform = `translate(${anchorLeft - (menuElementWidth - anchorWidth)}px, ${bottom}px)`
    else if (isNearScreenLeftEdge) menuWrapperRef.current.style.transform = `translate(${anchorLeft}px, ${bottom}px)`
    else menuWrapperRef.current.style.transform = `translate(${anchorLeft - (menuElementWidth - anchorWidth) / 2}px, ${bottom}px)`

    setIsNearScreenBottomEdge(isNearScreenBottomEdge)
    setIsNearScreenRightEdge(isNearScreenRightEdge)
    setIsNearScreenLeftEdge(isNearScreenLeftEdge)

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [anchor])

  return anchor === null ? (
    <></>
  ) : (
    <>
      {createPortal(
        <>
          <MenuContainerWrapper ref={menuWrapperRef}>
            <MenuContainer {...props}>
              <MenuTriangleContainer alignItems={isNearScreenLeftEdge ? 'flex-start' : isNearScreenRightEdge ? 'flex-end' : 'center'}>
                {!isNearScreenBottomEdge && <MenuTriangle {...props} />}
                <MenuContent {...props}>{children}</MenuContent>
                {isNearScreenBottomEdge && <MenuTriangle {...props} transform='rotate(180deg)' />}
              </MenuTriangleContainer>
            </MenuContainer>
          </MenuContainerWrapper>
        </>,
        document.body,
      )}
    </>
  )
}

export default Menu
