import {Box, CardActions, CardContent} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Loading from 'src/components/Loading'
import {useAuth} from 'src/contexts/AuthContext'
import {EditAddressInfo} from 'src/services/api'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import TabPanel from '../../components/TabPanel'
import {useApp} from '../../layouts/main-layout/contexts/AppContext'
import {cameraConfigStyles} from './CameraConfigBase'
import {advancedConfigCamera, CameraOptionsEnum, disableSaveButton, updateGeneralData, whichTabNotPermissionEditing} from './CameraConfigFunctions'
import {CameraConfigConnection} from './components/camera-config-connection/CameraConfigConnection'
import CameraConfigHistoric from './components/camera-config-historic/CameraConfigHistoric'
import CameraConfigReceiveEvent from './components/camera-config-receive-event/CameraConfigReceiveEvent'
import {CameraGeneralData} from './components/camera-general-data/CameraGeneralData'
import {LatLong} from './components/camera-general-data/CameraGeneralDataProps'
import {CameraReceiveEventModal} from './components/camera-receive-event-modal/CameraReceiveEventModal'
import {CameraTabsHeader} from './components/camera-tabs-header/CameraTabsHeader'
import {DetectionArea} from './components/detection-area/DetectionArea'
import {ObjectDetection} from './components/object-detection/ObjectDetection'
import {CameraConfigProvider, useCameraConfigContext} from './context/CameraConfigContext'
import {Button} from '@viptech/react-components'
import CameraAdvancedConfig from './components/camera-advanced-config/CameraAdvancedConfig'

function CameraConfigInner() {
  const [isEditing, setIsEditing] = useState(false)
  const [isCancel, setIsCancel] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [canSave, setCanSave] = useState(false)
  const [loadingUpdateConfig, setLoadingUpdateConfig] = useState(false)
  const [tab, setTab] = useState(0)
  const [latLong, setLatLong] = useState<LatLong>({
    latitude: undefined,
    longitude: undefined,
  })
  const {camera, getCamera, diffConfig, setDiffConfig, clientIntegration} = useCameraConfigContext()
  const [detectionSensitivity, setDetectionSensitivity] = useState(camera?.configuration.detectionSensitivity || 50)
  const [largeQueue, setLargeQueue] = useState<boolean>(camera?.configuration.largeQueue || false)
  const {sidebarTitle, setSidebarTitle} = useApp()
  const {enqueueSnackbar} = useSnackbar()
  const {id} = useParams<{id?: string}>()
  const navigate = useNavigate()
  const [addressInfo, setAddressInfo] = useState<EditAddressInfo>(camera?.addressInfo as unknown as EditAddressInfo)
  const changeLoadingUpdate = (loading: boolean) => setLoadingUpdateConfig(loading)
  const {user, userIsB2c} = useAuth()

  const getGeneralData = useCallback((latLong: LatLong, isConfirmed: boolean) => {
    setLatLong(latLong)
    setIsConfirmed(isConfirmed)
  }, [])

  useEffect(() => {
    if (!id || isNaN(Number(id))) return navigate('registers/devices')

    if (userIsB2c && user.role !== 'admin' ) return navigate(`/camera-config?id=${id}`, {replace: true}) 
    if (camera) {
      const title = `${sidebarTitle} > ${camera && camera.name}`
      setSidebarTitle(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera])

  useEffect(() => {
    if (!id) return
    getCamera(Number(id))
  }, [getCamera, id])

  useEffect(() => {
    setIsEditing(false)
    if (camera !== null) setDiffConfig(camera.configuration)
  }, [tab, camera, setDiffConfig])

  useEffect(() => {
    if (!camera || !diffConfig || !isConfirmed) return
    setCanSave(!disableSaveButton(diffConfig!, camera, addressInfo))
  }, [diffConfig, isConfirmed, camera, addressInfo])

  return camera === null ? (
    <Box sx={[cameraConfigStyles.paper, cameraConfigStyles.loading]}>
      <Loading height='50px' width='50px' />
    </Box>
  ) : (
    <Box sx={cameraConfigStyles.paper}>
      <CameraTabsHeader tab={tab} setTab={setTab} hasLpr={camera.hasLpr! || false} isEditing={isEditing} />

      <CardContent sx={cameraConfigStyles.content}>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_GENERAL_DATA}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_GENERAL_DATA}>
            <CameraGeneralData isEditing={isEditing} isCancel={isCancel} getGeneralData={getGeneralData} />
          </RenderIfConditionIsMet>
        </TabPanel>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_ADVANCED_CONFIG}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_ADVANCED_CONFIG}>
            <CameraAdvancedConfig
              setConfigs={(detectionSensitivity: number, largeQueue: boolean) => {
                setDetectionSensitivity(detectionSensitivity)
                setLargeQueue(largeQueue)
              }}
              isEditing={isEditing}
              isCancel={isCancel}
            />
          </RenderIfConditionIsMet>
        </TabPanel>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_CONFIG_LINK_RTSP}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_CONFIG_LINK_RTSP}>
            <CameraConfigConnection
              camera={camera}
              isEditing={isEditing}
              isCancel={isCancel}
              setAddressInfo={setAddressInfo}
              enqueueSnackbar={enqueueSnackbar}
            />
          </RenderIfConditionIsMet>
        </TabPanel>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_DETECTION_CONFIG}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_DETECTION_CONFIG}>
            <DetectionArea user={user} />
          </RenderIfConditionIsMet>
        </TabPanel>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_AREA_DETECTION_CONFIG}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_AREA_DETECTION_CONFIG}>
            <ObjectDetection />
          </RenderIfConditionIsMet>
        </TabPanel>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_CONFIG_RECEIVE_EVENT}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_CONFIG_RECEIVE_EVENT}>
            <CameraConfigReceiveEvent isEditing={isEditing} outputIntegration={clientIntegration} />
          </RenderIfConditionIsMet>
        </TabPanel>
        <TabPanel activeTab={tab} tab={CameraOptionsEnum.CAMERA_CONFIG_HISTORIC}>
          <RenderIfConditionIsMet condition={tab === CameraOptionsEnum.CAMERA_CONFIG_HISTORIC}>
            <CameraConfigHistoric />
          </RenderIfConditionIsMet>
        </TabPanel>
      </CardContent>

      {whichTabNotPermissionEditing(tab) ? (
        <></>
      ) : isEditing ? (
        <CardActions sx={cameraConfigStyles.cardActions}>
          <Button
            fontSize='12px'
            variant='outlined'
            color='#8E8E8E'
            height='40px'
            width='180px'
            onClick={() => {
              setIsEditing(false)
              setIsCancel(true)
              setDiffConfig(camera.configuration)
            }}>
            Cancelar
          </Button>

          <Button
            fontSize='12px'
            height='40px'
            width='180px'
            onClick={async () => {
              if (tab === CameraOptionsEnum.CAMERA_ADVANCED_CONFIG) {
                await advancedConfigCamera(camera, enqueueSnackbar, detectionSensitivity, largeQueue)
              } else {
                await updateGeneralData(diffConfig!, camera!, latLong, enqueueSnackbar, Number(id!), getCamera, changeLoadingUpdate, addressInfo)
              }
              setIsEditing(false)
              setIsCancel(false)
            }}
            isLoading={loadingUpdateConfig}
            disabled={canSave}>
            Salvar
          </Button>
        </CardActions>
      ) : (
        <CardActions sx={cameraConfigStyles.cardActions}>
          <Button
            fontSize='12px'
            color='#20bc30'
            hoverColor='#00850e'
            height='40px'
            width='180px'
            onClick={() => {
              setIsEditing(true)
              setIsCancel(false)
            }}>
            Editar
          </Button>
        </CardActions>
      )}
    </Box>
  )
}

export function CameraConfig() {
  return (
    <CameraConfigProvider>
      <CameraConfigInner />
      <CameraReceiveEventModal />
    </CameraConfigProvider>
  )
}
