import {Camera} from './CameraEndpoint'
import {AlarmStatus} from './EventStatusEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, NoPaginate, Ord, Paginate} from './_types'
import {FaceRecognitionDTO} from 'src/common/models/facial-recognition/FaceRecognitionDTO'
import {LineCrossingDTO} from 'src/common/models/line-crossing/LineCrossingDTO'

const prefix = process.env.REACT_APP_EVENTS_BASE_URL

export interface DetectionArea {
  id: number
  startX: number
  endX: number
  startY: number
  endY: number
  accuracy: number
  imageId: number
  class: string
  additionalData: {
    plate: string
  }
}

export interface SnapshotEvent {
  id: number
  eventId: number
  additionDate: string
  awsKey: string
  base64?: string
  demarcatedAreas: DetectionArea[]
}

export interface Image {
  id: number
  image: string
  alarm: number
  additionDate: string
  detectionAreas: DetectionArea[]
}

type EventType = {
  id: number
  name: string
}

export interface Alarm {
  id: number
  camera: Camera
  cameraId: number
  images: Image[]
  lastUpdate: string
  statusId: number
  createdAt: string
  status: AlarmStatus
  hasTrigger: boolean
  eventType?: EventType
  snapshots?: Array<SnapshotEvent>
  lineCrossingLogs?: Array<LineCrossingDTO>
  faceRecognitions?: Array<FaceRecognitionDTO>
  objects: string | null
}

export type GetEventsParams = {
  batchSize?: number
  startId: number | null
  includes?: string[]
  search?: {
    cameraName?: string
    id?: string
    clientName?: string
  }
  filter?: {
    companyIds?: Array<number>
    eventTypeIds?: Array<string>
    cameraIds?: Array<number>
    statuses?: Array<string>
    statusCameras?: string
    isOnline?: boolean
    from?: string
    to?: string
  }
  orderBy?: {
    ids?: Ord
    timestamp?: Ord
  }
}

export type GetSingleEventParams = {
  id?: number
  includes?: Array<string>
}

export type GetSnapshotEvent = {
  includes?: string[]
  filter: {
    eventIds: string[]
  }
}

export class EventsEndpoint {
  async getMany(params: GetEventsParams): Promise<AxiosResult<NoPaginate<Alarm>>> {
    return await axios.get(`${prefix}`, {params: prepareParams(params)})
  }

  async getById(params: GetSingleEventParams): Promise<AxiosResult<Alarm>> {
    return await axios.get(`${prefix}/single/`, {
      params: prepareParams({
        ...params,
      }),
    })
  }

  async getSnapshots(params: GetSnapshotEvent): Promise<AxiosResult<Paginate<SnapshotEvent>>> {
    return await axios.get(`${prefix}/snapshots`, {
      params: prepareParams({
        includes: ['demarcatedAreas'],
        ...params,
      }),
    })
  }
}
