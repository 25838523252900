import styled from 'styled-components'

export const WrapperContentArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media (max-width: 767px) {
    max-width: 300px;
  }
`
