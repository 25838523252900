export const objectTypeList = [
  {id: 15, name: 'plate', code: 'B002', label: 'Placa', resourceId: 5},
  {id: 89, name: 'safety-clothes', code: 'B013', label: 'Roupa de Segurança', resourceId: 9},
  {id: 92, name: 'body', code: 'B013', label: 'Corpo', resourceId: 7},
  {id: 96, name: 'face', code: 'B001', label: 'Facial', resourceId: 14},
  {id: 2, name: 'fork', code: 'B004', label: 'Garfo', resourceId: 7},
  {id: 3, name: 'knife', code: 'B004', label: 'Faca', resourceId: 7},
  {id: 4, name: 'cell phone', code: 'B005', label: 'Celular', resourceId: 7},
  {id: 5, name: 'scissors', code: 'B004', label: 'Tesoura', resourceId: 7},
  {id: 6, name: 'car', code: 'B002', label: 'Carro', resourceId: 7},
  {id: 7, name: 'bottle', code: 'B004', label: 'Garrafa', resourceId: 7},
  {id: 9, name: 'cup', code: 'B004', label: 'Copo', resourceId: 7},
  {id: 10, name: 'spoon', code: 'B004', label: 'Colher', resourceId: 7},
  {id: 11, name: 'book', code: 'B008', label: 'Livro', resourceId: 7},
  {id: 12, name: 'clock', code: 'B008', label: 'Relógio', resourceId: 7},
  {id: 13, name: 'mouse', code: 'B005', label: 'Mouse', resourceId: 7},
  {id: 14, name: 'motorbike', code: 'B002', label: 'Moto', resourceId: 7},
  {id: 21, name: 'bicycle', code: 'B002', label: 'Bicicleta', resourceId: 7},
  {id: 23, name: 'airplane', code: 'B002', label: 'Avião', resourceId: 7},
  {id: 24, name: 'bus', code: 'B002', label: 'Ônibus', resourceId: 7},
  {id: 25, name: 'train', code: 'B002', label: 'Trem', resourceId: 7},
  {id: 26, name: 'truck', code: 'B002', label: 'Caminhão', resourceId: 7},
  {id: 27, name: 'boat', code: 'B002', label: 'Barco', resourceId: 7},
  {id: 28, name: 'traffic light', code: 'B007', label: 'Semáforo', resourceId: 7},
  {id: 29, name: 'fire hydrant', code: 'B007', label: 'Hidrante', resourceId: 7},
  {id: 30, name: 'stop sign', code: 'B007', label: 'Sinal de Parada', resourceId: 7},
  {id: 32, name: 'bench', code: 'B007', label: 'Banco de Sentar', resourceId: 7},
  {id: 33, name: 'bird', code: 'B006', label: 'Pássaro', resourceId: 7},
  {id: 34, name: 'cat', code: 'B006', label: 'Gato', resourceId: 7},
  {id: 35, name: 'dog', code: 'B006', label: 'Cão', resourceId: 7},
  {id: 36, name: 'horse', code: 'B006', label: 'Cavalo', resourceId: 7},
  {id: 37, name: 'sheep', code: 'B006', label: 'Ovelha', resourceId: 7},
  {id: 38, name: 'cow', code: 'B006', label: 'Vaca', resourceId: 7},
  {id: 43, name: 'backpack', code: 'B008', label: 'Mochila', resourceId: 7},
  {id: 44, name: 'umbrella', code: 'B008', label: 'Guarda-Chuva', resourceId: 7},
  {id: 45, name: 'handbag', code: 'B008', label: 'Bolsa', resourceId: 7},
  {id: 47, name: 'suitcase', code: 'B008', label: 'Mala de Viagem', resourceId: 7},
  {id: 53, name: 'baseball bat', code: 'B009', label: 'Taco de Beisebol', resourceId: 7},
  {id: 57, name: 'tennis racket', code: 'B009', label: 'Raquete de Tênis', resourceId: 7},
  {id: 69, name: 'chair', code: 'B004', label: 'Cadeira', resourceId: 7},
  {id: 71, name: 'potted plant', code: 'B004', label: 'Vaso de Planta', resourceId: 7},
  {id: 75, name: 'tv', code: 'B005', label: 'Televisão', resourceId: 7},
  {id: 76, name: 'laptop', code: 'B005', label: 'Computador Portátil', resourceId: 7},
  {id: 78, name: 'keyboard', code: 'B005', label: 'Teclado', resourceId: 7},
  {id: 84, name: 'vase', code: 'B004', label: 'Vaso', resourceId: 7},
  {id: 88, name: 'crowd', code: 'B011', label: 'Aglomeração', resourceId: 8},
  {id: 93, name: 'without-safety-clothes', code: 'B014', label: 'Sem Roupa de Segurança', resourceId: 9},
  {id: 94, name: 'without-helmet', code: 'B014', label: 'Sem Capacete', resourceId: 9},
  {id: 90, name: 'helmet', code: 'B013', label: 'Capacete', resourceId: 9},
  {id: 98, name: 'scene change', code: 'B015', label: 'Mudança de Cenário', resourceId: 16},
  {id: 1, name: 'person', code: 'B001', label: 'Pessoa', resourceId: 6},
  {id: 99, name: 'object removal', code: 'B015', label: 'Remoção de objeto', resourceId: 21},
]
