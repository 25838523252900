import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ConfirmationModal} from '../../../../components/ConfirmationModal/ConfirmationModal'
import Pagination from '../../../../components/pagination/Pagination'
import {useClientsContext} from '../../contexts/ClientsContext'
import {ClientsTableOption} from './components/clients-table-option/ClientsTableOption'

function ClientsGridTable() {
  const navigate = useNavigate()

  const {
    clientsPage,
    setClientsPage,
    setClientsPageSize,
    clientsTotalCount,
    clients,
    openClientModal,
    loadingClients,
    selectedClientsIds,
    setSelectedClientsIds,
    deleteClient,
  } = useClientsContext()
  const {getCompanyName} = useCompany()
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [clientIdToDelete, setClientIdToDelete] = useState<number>(0)

  const control = {
    currentPage: clientsPage.page,
    setCurrentPage: setClientsPage,
    nextPage: () => setClientsPage(clientsPage.page + 1),
    prevPage: () => setClientsPage(clientsPage.page - 1),
    changePageSize: (pageSize: number) => setClientsPageSize(pageSize),
  }

  const columns = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'id',
      name: 'ID',
    },
    {
      id: 'name',
      name: 'Nome do Cliente',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'email',
      name: 'E-mail',
    },
    {
      id: 'integration',
      name: 'Integração',
    },
    {
      id: 'account',
      name: 'Conta',
    },
    {
      id: 'cameras',
      name: 'N° de câmeras',
    },
    {
      id: 'contextMenu',
      name: '',
    },
  ]
  const onSelectAll = () => {
    if (selectedClientsIds.length) return setSelectedClientsIds([])
    const allIds = clientsRender.map((row) => row.id)
    setSelectedClientsIds(allIds)
  }

  const clientsRender = clients.map((client) => {
    function getIntegration() {
      if (client.sigmaAccount) return {integration: 'Sigma-Cloud', account: client.sigmaAccount || '-'}
      if (client.moniClientInfo) return {integration: 'Moni', account: client.moniClientInfo.clientCode || '-'}
      if (client.onePortariaClientInfo) return {integration: 'One Portaria', account: client.onePortariaClientInfo.clientCode || '-'}
      return {integration: 'Sem Integração', account: '-'}
    }
    const {integration, account} = getIntegration()
    const clientData = {
      id: client.id,
      name: client.name,
      email: client.email || '-',
      integration: integration,
      account: account,
      cameras: client.totalOfCameras,
      self: client,
    }

    return clientData
  })

  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={selectedClientsIds.length ? clientsRender.length === selectedClientsIds.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )

  function withConfirmation(clientId: number) {
    setClientIdToDelete(clientId)
    setOpenModal(true)
  }

  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table columns={columns} skeletonRowsAmount={clientsPage.pageSize} isLoading={loadingClients} tableHeaderChildren={customHeader}>
          {clientsRender.map((row) => (
            <RowHover id={`row-${row.id}`}>
              <TableData>
                <CheckboxTable id={row.id} selectedIds={selectedClientsIds} setSelectedIds={setSelectedClientsIds} />
              </TableData>
              <TableData>{row.id}</TableData>
              <TableData>
                <LinkWithAction onClick={() => openClientModal(row.self)}>{row.name}</LinkWithAction>
              </TableData>
              <TableData>{getCompanyName(row.self.companyId)}</TableData>
              <TableData>{row.email}</TableData>
              <TableData>{row.integration}</TableData>
              <TableData>{row.account}</TableData>
              <TableData>{row.cameras}</TableData>
              <TableData>
                <ClientsTableOption
                  oneObject={row.id.toString()}
                  onClick={(event, oneObject) => {
                    if (event === 'delete') withConfirmation(+oneObject)
                    else if (event === 'edit-user') openClientModal(row.self)
                    else if (event === 'list-devices') navigate(`/registers/devices?client=${row.id}`)
                  }}
                  totalOfCameras={row.cameras}
                />
              </TableData>
            </RowHover>
          ))}
        </Table>
      </Box>

      {!loadingClients && clientsRender.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={clientsTotalCount}
            itemsPerPage={clientsPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}

      <ConfirmationModal
        title={`Deletar cliente`}
        content={`Os registros deste cliente e todas as câmeras associadas serão removidos. Esta ação pode ser revertida.`}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenModal(false)
              deleteClient(clientIdToDelete)
            },
            variant: 'contained',
            color: '#009EFF',
          },
        ]}
      />
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default ClientsGridTable
