import createStyleSheet from 'src/utilities/createStyleSheet'

export const kovaImageDetectionFullscreenStyles = createStyleSheet({
  container: {
    backgroundColor: '#fff',
    zIndex: '2',
  },
  image: {
    height: '100%',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
  toolsContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    paddingTop: 3,
    paddingRight: 3,
  },
  toolsGrid: {
    backgroundColor: '#fff',
    padding: 2,
    borderRadius: 2,
  },
  centerItem: {
    pt: 1,
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
  },
  button: {
    backgroundColor: '#fff',
    p: 1,
    minWidth: 0,
    fontWeight: 'bold',
  },
})
