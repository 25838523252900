import styled from 'styled-components'

export const VideoContainer = styled.div`
  overflow: hidden;
  width: 550px;
  height: 300px;
`

export const VideoFrame = styled.iframe`
  height: 100%;
  width: 100%;
  border-radius: 8px;
`
