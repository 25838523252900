import TabPanel from 'src/components/TabPanel'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'

import BrainBoxConfigGeneralData from './BrainBoxConfigGeneralData'
import BrainBoxConfigDeviceControl from './BrainBoxControl/BrainBoxConfigDeviceControl'
import BrainBoxOutputDevices from './BrainBoxDevicesOut/BrainBoxOutputDevices'

type BrainBoxConfigTabsContentProps = {
  tab: number
  brainBoxOne: BrainBox
  setBrainBoxOne: (brainBox: BrainBox) => void
  setIsEdit: (isEdit: boolean) => void
  isEdit: boolean
  updateSituation: (diffBrainBoxOne: BrainBox) => void
}

const BrainBoxConfigTabsContent = ({tab, brainBoxOne, setBrainBoxOne, setIsEdit, isEdit, updateSituation}: BrainBoxConfigTabsContentProps) => {
  return (
    <>
      <TabPanel activeTab={tab} tab={0}>
        <BrainBoxConfigGeneralData
          brainBoxOne={brainBoxOne}
          setBrainBoxOne={setBrainBoxOne}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          updateSituation={updateSituation}
        />
      </TabPanel>
      <TabPanel activeTab={tab} tab={1}>
        <BrainBoxOutputDevices brainBoxOne={brainBoxOne} />
      </TabPanel>
      <TabPanel activeTab={tab} tab={2}>
        <BrainBoxConfigDeviceControl brainBoxOne={brainBoxOne} />
      </TabPanel>
    </>
  )
}

export default BrainBoxConfigTabsContent
