import {Box, Button, Input, Modal, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import api from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export type CreatePlateProps = {
  isOpen: boolean
  closeModal: () => void
  listId: number
  refreshList: () => void
}

function CreatePlateModal(props: CreatePlateProps) {
  const {isOpen, closeModal, listId, refreshList} = props
  const [plate, setPlate] = useState('')
  const [description, setDescription] = useState('')
  const {enqueueSnackbar} = useSnackbar()

  function handleClose() {
    setPlate('')
    setDescription('')
    closeModal()
  }

  async function handleSaveList() {
    try {
      const plateDTO = {
        licensePlate: plate,
        description,
      }
      await api.lpr.createPlate(String(listId), plateDTO)
      enqueueSnackbar('Placa vinculada com sucesso', {variant: 'success'})
      refreshList()
      handleClose()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao vincular placa')
    }
  }

  function plateIsValid(value: string) {
    return /^[A-Z]{3}[0-9]{1}[A-Z0-9]{1}[0-9]{2}$/i.test(value) ? value : ''
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => handleClose()}
      backgroundColor='white'
      width='510px'
      height='385px'
      padding='32px'
      paddingBottom='32px'
      paddingLeft='32px'
      paddingRight='32px'
      paddingTop='32px'>
      <Box position='absolute' right='14px' top='14px'>
        <Icon src={CloseIcon} cursor='pointer' onClick={() => handleClose()} />
      </Box>
      <Box fontWeight='600' fontSize='22px' color='black'>
        Cadastro de Placa
      </Box>
      <Box display='flex' direction='column'>
        <Typography variant='span' family='Inter' size='15px' weight={400}>
          Informe a placa do veículo, utilize somente números e letras.
        </Typography>
        <Typography variant='span' family='Inter' size='15px' weight={400}>
          Exemplo: ABC1234
        </Typography>
      </Box>
      <Box paddingTop='20px' width='100%' display='flex' direction='column' justify='center' align='center' rowGap='10px'>
        <Box display='flex' width='100%' direction='column' justify='flex-start' align='flex-start' rowGap='10px' paddingBottom='10px'>
          <Input.Root width='100%' label='Placa' onChange={(e) => setPlate(e.target.value)} mask={(value) => value.toUpperCase()} />
          <Input.Root width='100%' label='Descrição' onChange={(e) => setDescription(e.target.value)} />
        </Box>
      </Box>
      <Box display='flex' direction='row' columnGap='10px' align='center' justify='flex-end' paddingTop='20px'>
        <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => handleClose()}>
          Cancelar
        </Button>
        <Button
          fontSize='12px'
          height='40px'
          width='150px'
          disabledTextColor='#8E8E8E'
          disabled={!plateIsValid(plate) || plate === '' || !description}
          onClick={() => handleSaveList()}>
          Salvar
        </Button>
      </Box>
    </Modal>
  )
}

export default CreatePlateModal
