import {axios} from '../api'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_CAMERAS_BASE_URL

export type CameraAnalyticDTO = {
  client: {id: number; name: string; companyId: number}
  clientId: number
  id: number
  macAddress: string
  name: string
}

export type UpdateAnalyticCameraBodyDTO = {
  name: string
  macAddress: string
  analytics: Array<string>
}

export type Analytic = {
  analytic: string
  label: string
}

export type CameraAnalyticParams = {
  page?: number
  pageSize?: number
  search?: {
    clientName?: string
    cameraName?: string
  }
  filter?: {
    companyIds?: Array<number | undefined>
    clientIds?: Array<number | undefined>
  }
}

export class AnalyticCameraEndpoint {
  async getAnalytics(cameraId: number): Promise<AxiosResult<string[]>> {
    return await axios.get(`${prefix}/analytic-cameras/${cameraId}/analytics`)
  }

  async getAllAnalytics(): Promise<AxiosResult<Analytic[]>> {
    return await axios.get(`${prefix}/analytic-cameras/all-analytics`)
  }

  async getAnalyticCameras(params: CameraAnalyticParams): Promise<AxiosResult<Paginate<CameraAnalyticDTO>>> {
    return await axios.get(`${prefix}/analytic-cameras/`, {params: prepareParams(params)})
  }

  async createAnalyticCamera(body: any): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/analytic-cameras/`, body)
  }

  async updateCameraAnalytics(cameraId: number, body: UpdateAnalyticCameraBodyDTO): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/analytic-cameras/${cameraId}`, {...body})
  }

  async deleteAnalyticCameras(ids: number[]): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/analytic-cameras/`, {data: {idsToDelete: ids}})
  }
}
