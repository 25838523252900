import {CompanyCredentials} from './CompanyEndpoint'
import {User} from './UserEndpoint'

import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_AUTH_BASE_URL

export type AuthLogin = {
  email: string
  password: string
  keepLoggedIn: boolean
}

export type AuthLoginResponse = {
  accessToken: string
  refreshToken: string
}

export type AuthPassword = {
  newPassword: string
  currentPassword?: string
}

export type AuthPasswordResponse = {
  statusCode: number
  error?: string
  message: string
}

export type SendConfirmationEmailBody = {
  email: string
  username: string
}

export type OperationType = 'confirm-identity' | 'forgot-password'
export class AuthEndpoint {
  async login(data: AuthLogin): Promise<AxiosResult<AuthLoginResponse>> {
    return await axios.post(`${prefix}/login`, data)
  }

  async refresh(keepLoggedIn: boolean): Promise<AxiosResult<AuthLoginResponse>> {
    return await axios.post(`${prefix}/refresh`, {keepLoggedIn})
  }

  async me(): Promise<AxiosResult<User>> {
    return await axios.post(`${prefix}/me`)
  }

  async updatePassword(password: AuthPassword): Promise<AxiosResult<AuthPasswordResponse>> {
    return await axios.patch(`${prefix}/user/password`, password)
  }

  async updateCredentials(companyId: number): Promise<AxiosResult<CompanyCredentials>> {
    return await axios.patch(`${prefix}/companies/credentials`, {companyId})
  }

  async validateToken(token: string): Promise<AxiosResult<AuthLoginResponse>> {
    return await axios.post(`${prefix}/confirm-identity`, {token})
  }

  async forgotPassword(email: string): Promise<AxiosResult<boolean>> {
    return await axios.post(`${prefix}/forgot-password`, {email})
  }

  async resendMail(email: string, operation: OperationType): Promise<AxiosResult<boolean>> {
    return await axios.post(`${prefix}/resend-email`, {email, operation})
  }

  async sendConfirmationEmail(data: SendConfirmationEmailBody): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/self-register/send-confirmation-email`, data)
  }
}
