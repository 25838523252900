import {CardFlagsEnum} from 'src/layouts/b2c-layout/contexts/B2CContext'
import axios from './_axios'
import {AxiosResult} from './_types'
import {prepareParams} from './_internal'

const prefix = process.env.REACT_APP_PAYMENTS_BASE_URL

export type CardInfoBody = {
  paymentInfoId?: number
  companyId: number
  cardDigits: string
  flag: CardFlagsEnum
  cardName: string
  validity: string
  cardCvv: string
}

export type CreateBillingBody = {
  planId: number
  fullPaymentInfo: CardInfoBody
  installments: number
}

export type CardInfoResponse = {
  firstCardDigits: string
  lastCardDigits: string
  cardName: string
  validity: string
  flag: string
  companyId: number
  id: number
}

export type BillingResponse = {
  status: string
  price: string
  dueDate: string
  paymentInfoId: number
  planId: number
  installments: number
  id: number
}

export type UserHasBilling = {
  id: number
  firstCardDigits: string
  lastCardDigits: string
  cardName: string
  validity: string
  flag: string
  companyId: number
  billings: BillingResponse[]
}

export type CreateBillResponse = {
  bill: BillingResponse
}

export class PaymentEndpoint {
  async saveCardInfo(data: CardInfoBody): Promise<AxiosResult<CardInfoResponse>> {
    return await axios.post(`${prefix}/payment`, data)
  }

  async createBilling(data: CreateBillingBody): Promise<AxiosResult<CreateBillResponse>> {
    return await axios.post(`${prefix}/billing`, data)
  }

  async getBillingStatus(id: number): Promise<AxiosResult<string>> {
    return await axios.get(`${prefix}/billing/${id}/status`)
  }

  async getUserCurrentBilling(filter: {companyId: string}): Promise<AxiosResult<UserHasBilling>> {
    return await axios.get(`${prefix}/billing/current-billing`, {
      params: prepareParams(filter),
    })
  }
}
