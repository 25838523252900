import styled from 'styled-components'

interface ContainerProps {
  backgroundColor?: string
  backgroundImage?: string
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: auto;
  ${({backgroundColor}) => backgroundColor && `background-color: ${backgroundColor};`}
  ${({backgroundImage}) =>
    backgroundImage &&
    `
    background-image: url(${backgroundImage});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  `}
`

export const ViptechLogoDiv = styled.div`
  display: flex;
  position: absolute;
  bottom: 2em;
  right: 2em;
`

export const LoginBox = styled.div`
  width: 27.75em;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 8em;
  gap: 50px;
  @media (max-width: 500px) {
    width: 19em;
    margin-left: 3.5em;
  }
`

export const TopDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 125%;
  justify-content: space-between;
`

export const Logo = styled.img`
  width: 200px;
`

export const MessageSpan = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2.5rem;
  color: ${(props) => props.color};
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`

export const Form = styled.form`
  height: 10.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 1540px) {
    margin-top: 1em;
  }
`

export const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
`

export const Input = styled.input`
  font-size: 16px;
  color: #ffffff;
  background-color: #46464f;
  height: 2.5em;
  border-radius: 10px;
  border: none;
  text-indent: 4%;
  outline: 0;
  :focus {
    outline: none;
  }
`

export const ButtonDiv = styled.div`
  width: 100%;
  margin-top: 0.8em;
`

export const Button = styled.button`
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #004677;
  color: white;
  font-size: 19px;
  background-color: #009eff;
  width: 100%;
  height: 2em;
  transition: 0.4s;
  font-weight: bold;
  :hover {
    background-color: #00639f;
  }
  :disabled {
    opacity: 0.4;
    cursor: default;
    :hover {
      background-color: #009eff;
    }
  }
`

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`

export const ContainerOptions = styled.div`
  color: white;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  @media (max-width: 500px) {
    font-size: 10px;
    margin-top: 10px;
  }
`

export const LinkText = styled.span`
  color: white;
  font-size: 13.5px;
  text-decoration: underline;
  @media (max-width: 500px) {
    font-size: 10px;
  }
`

export const ContainerSSO = styled.div<{marginTop?: string}>`
  margin-top: ${(props) => props.marginTop};
  width: 100%;
`

export const SSOButton = styled.button`
  width: 100%;
  background: transparent;
  color: white;
  height: 47.5px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid #009eff;
  border-radius: 10px;
`

export const SxLoadingButtonLogin = {
  cursor: 'pointer',
  borderRadius: '10px',
  border: '1px solid #004677',
  color: 'white',
  fontSize: '19px',
  backgroundColor: '#009eff',
  width: '100%',
  height: '2.5em',
  transition: '0.4s',
  textTransform: 'none',
  fontWeight: 'bold',
  fontFamily: 'Inter',
  '&:hover': {
    backgroundColor: '#00639f',
  },
  '@media (min-width:1540px)': {
    // marginTop: '1.2em',
  },
}

export const IncorrectUser = styled.label`
  font-family: 'Inter', sans-serif;
  color: tomato;
  font-size: 0.8rem;
  margin: 0.5em 0;
`
