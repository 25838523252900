import styled from 'styled-components'
import {StyledTheme} from '../../StyledTheme'
import {MenuStyleProps} from './MenuProps'

export const MenuContainer = styled.div<MenuStyleProps>`
  width: fit-content;
  height: fit-content;

  ${(props) => props.margin && `margin: ${props.margin}`};
  ${(props) => props.padding && `padding: ${props.padding}`};

  ${(props) => props.marginTop && `margin-top: ${props.marginTop}`};
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft}`};
  ${(props) => props.marginRight && `margin-right: ${props.marginRight}`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom}`};

  ${(props) => props.top && `top: ${props.top}px`};
  ${(props) => props.left && `left: ${props.left}px`};
  ${(props) => props.right && `right: ${props.right}px`};
  ${(props) => props.bottom && `bottom: ${props.bottom}px`};

  ${(props) => props.top && `padding-top: ${props.paddingTop}`};
  ${(props) => props.left && `padding-left: ${props.paddingLeft}`};
  ${(props) => props.right && `padding-right: ${props.paddingRight}`};
  ${(props) => props.bottom && `padding-bottom: ${props.paddingBottom}`};

  color: ${(props) => props.color ?? `${StyledTheme.colors.black}`};
  font-size: ${(props) => props.fontSize ?? `${StyledTheme.input.fontSize}`};
  ${({boxShadow}) =>
    boxShadow ? `box-shadow: ${boxShadow};` : `filter: drop-shadow(1px 0px 3px rgba(0, 0, 0, 0.15)) drop-shadow(-1px 0px 3px rgba(0, 0, 0, 0.15));`}
`

export const MenuTriangleContainer = styled.div<{alignItems?: string}>`
  display: flex;
  flex-direction: column;
  align-items: ${({alignItems}) => alignItems ?? 'center'};
`

export const MenuContainerWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: max-content;
  z-index: auto;
`

export const MenuTriangle = styled.div<{hideTriangle?: boolean; transform?: string; bgColor?: string}>`
  position: relative;
  width: 0;
  height: 0;
  margin: 0 0.5rem;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 10px solid ${({bgColor}) => bgColor ?? 'white'};
  transform: ${({transform}) => transform};
  ${({hideTriangle}) => hideTriangle && 'display: none;'};
`

export const MenuContent = styled.div<MenuStyleProps>`
  display: ${({display}) => display ?? 'flex'};
  align-items: ${({align}) => align ?? 'center'};
  justify-content: ${({justify}) => justify ?? 'flex-start'};
  background-color: ${(props) => props.bgColor ?? StyledTheme.colors.white};
  ${(props) => props.border && `border: ${props.border}`}
  border-radius: ${(props) => props.borderRadius ?? '0'};
`
