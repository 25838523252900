import React from 'react';

interface props {
  color?: string;
}

const RealodIconSmallChangeColor: React.FC<props> = ({color}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.2884 8.69314C13.1625 9.65311 12.7777 10.5607 12.1752 11.3186C11.5727 12.0765 10.7752 12.656 9.86836 12.9952C8.9615 13.3343 7.97943 13.4201 7.02749 13.2435C6.07554 13.0669 5.18962 12.6345 4.46475 11.9927C3.73988 11.3508 3.20339 10.5238 2.91283 9.60023C2.62227 8.67667 2.58859 7.69144 2.81542 6.75019C3.04224 5.80895 3.52101 4.9472 4.20036 4.25737C4.87972 3.56754 5.73405 3.07566 6.67172 2.83447C9.27105 2.1678 11.9617 3.5058 12.955 5.99913" stroke={color ? color: '#039855'}  strokeWidth="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M13.3333 2.66602V5.99935H10" stroke={color ? color: '#039855'}  stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default RealodIconSmallChangeColor