export type AxiosErrorGeneric = {
  message: string
  details: string
  status: number
  config?: {
    url: string
  }
  response: {
    status: number
    data: {
      message: string
      details: string
      developerMessage: string
      detail: {
        message: string
      }
    }
  }
}

export function RequestError(error: AxiosErrorGeneric): string {
  if (!!error.response) {
    if (!!error.response.data.message) return error.response.data.message
    else if (!!error.response.data.details) return error.response.data.details
    else if (!!error.response.data.detail) return error.response.data.detail.message
    switch (error.response.status) {
      case 400:
        return 'Os dados estão incorretos'
      case 204:
        return 'Não foi possível localizar'
      case 503:
        return 'Problema com o servidor'
      default:
        return 'Ocorreu algum erro inesperado'
    }
  }
  return 'Problema na conexão'
}

export function LoginErrorHandler(error: AxiosErrorGeneric): string {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 401:
        return 'Usuário ou senha inválidos.'
      case 500:
        return 'Usuário ou senha inválidos.'
      case 404:
        return 'Usuário não encontrado.'
      default:
        return `Ocorreu um erro inesperado: Error ${error.response.status}`
    }
  }
  return 'Problema na conexão.'
}

export function createUserErrorHandler(error: AxiosErrorGeneric): string {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 500:
        if (error.response.data.developerMessage === 'User or User Login is already registered') return 'Usuário ou Login já registrados.'
        return 'Ocorreu algum erro inesperado'
      case 400:
        return 'Os dados estão incorretos'
      default:
        return `Ocorreu um erro inesperado: Error ${error.response.status}`
    }
  }
  return `Ocorreu um erro inesperado.`
}

export function ErrorHandler401(error: AxiosErrorGeneric): string {
  if (error.response !== undefined) {
    switch (error.response.status) {
      case 401:
        return 'Acesso não autorizado'
    }
  }
  return 'Problema na conexão.'
}
