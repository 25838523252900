import {Box, Button, Loading, Typography} from '@viptech/react-components'

import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import ReactPlayer from 'react-player'
import ModalCustom from '../../../../components/modal/ModalCustom'
import api from '../../../../services/api/api'
import storage from '../../../../services/storage'
import handleErrorWithSnackbar from '../../../../utilities/handleErrorWithSnackbar'
import {useCameraConfigContext} from '../../../camera-config/context/CameraConfigContext'
import {ModalButtonsContainer, ModalContent, ModalHeader} from './ConfirmationConfigurationModalBase'

type ConfirmationConfigurationModalProps = {
  isOpen: boolean
  onClose: () => void
  cameraId: number
  confirmationFunction: () => void
}

export const playerConfig = {
  file: {
    attributes: {
      style: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        maxHeight: '345px',
        maxWidth: '700px',
      },
    },
    hlsOptions: {
      forceHLS: true,
      debug: false,
      xhrSetup: function (xhr: any) {
        xhr.setRequestHeader('Authorization', `Bearer ${storage.get('token')}`)
      },
    },
  },
}

export function ConfirmationConfigurationModal(props: ConfirmationConfigurationModalProps) {
  const {isOpen, onClose, cameraId, confirmationFunction} = props
  const {enqueueSnackbar} = useSnackbar()
  const {getSnapshotRtsp} = useCameraConfigContext()

  const [isPlaying, setIsPlaying] = useState(false)
  const [playerURL, setPlayerURL] = useState('')

  const isLive = async () => {
    try {
      const response = await api.streaming.getStream(cameraId)
      const url = api.streaming.startStream(response.data.data)
      setPlayerURL(url)
      setIsPlaying(true)
    } catch (error: any) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao abrir stream da câmera')
      setIsPlaying(false)
    }
  }

  useEffect(() => {
    isLive()

    setTimeout(() => {
      setFakeLoadingTime(true)
      getSnapshotRtsp(+cameraId)
    }, 15000)
  }, [])

  const [fakeLoadingTime, setFakeLoadingTime] = useState(false)

  return (
    <ModalCustom isOpen={isOpen} closeModal={onClose} backgroundColor='white'>
      <Box padding='20px'>
        <ModalHeader>
          <Typography variant='span' size='20px' weight={600} color='#344054'>
            Confirmar configuração
          </Typography>

          <Box display='flex' direction='column'>
            <Typography variant='span' size='16px' color='#344054'>
              Confira a imagem da câmera. Se estiver correta, clique em “Confirmar”.
            </Typography>
            <Typography variant='span' size='16px' color='#344054'>
              Se sua imagem não apareceu ou deu erro, retorne e configure novamente
            </Typography>
          </Box>
        </ModalHeader>

        <ModalContent>
          {!fakeLoadingTime ? (
            <Loading size='30px' />
          ) : (
            <ReactPlayer url={playerURL} config={playerConfig} onReady={() => setIsPlaying(true)} playing={isPlaying} width='100%' height='100%' />
          )}
        </ModalContent>

        <ModalButtonsContainer>
          <Button
            variant='outlined'
            height='37px'
            borderRadius='8px'
            color='#667085'
            onClick={() => {
              setIsPlaying(false)
              onClose()
            }}>
            Cancelar
          </Button>

          <Button variant='contained' height='37px' borderRadius='8px' onClick={confirmationFunction}>
            Confirmar
          </Button>
        </ModalButtonsContainer>
      </Box>
    </ModalCustom>
  )
}
