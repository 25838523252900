import {Checkbox} from '@viptech/react-components'

export type CheckboxTableProps<T> = {
  id: T
  selectedIds: T[]
  setSelectedIds: (id: T[]) => void
  disabled?: boolean
}

function CheckboxTable<T>(props: CheckboxTableProps<T>) {
  const {selectedIds, setSelectedIds, id, disabled = false} = props

  function handleChange(id: T) {
    const selectedIdsCopy = [...selectedIds]
    if (selectedIds.includes(id)) {
      const index = selectedIdsCopy.indexOf(id)
      selectedIdsCopy.splice(index, 1)
      setSelectedIds(selectedIdsCopy)
    } else {
      selectedIdsCopy.push(id)
      setSelectedIds(selectedIdsCopy)
    }
  }

  return <Checkbox disabled={disabled} checked={selectedIds.includes(id)} onChange={() => handleChange(id)} />
}

export default CheckboxTable
