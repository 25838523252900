import styled from 'styled-components'

export const PercentageFacial = styled.div`
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color: #8e8e8e;
`

export const ImageAlarm = styled.img`
  width: 165px;
  height: 218px;
`
export const ModalContainer = styled.div`
  width: 40%;
  overflow: auto;
  border: 1px solid #bbbbbb7b;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;

  @media (max-width: 767px) {
    width: 100%;
  }
`
export const ContainerTable = styled.div<{isOpen: boolean}>`
  display: flex;
  width: 100%;
  height: 90%;
  flex: 8;
  gap: 16px;
  flex-direction: ${({isOpen}) => (isOpen ? 'row' : 'column')};
`
