import {LinearProgress, linearProgressClasses} from '@mui/material'
import styled from 'styled-components'

export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 8,
  borderRadius: 5,
  marginTop: 5,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
}))
