import {Box, Button, Typography} from '@viptech/react-components'
import ModalCustom from 'src/components/modal/ModalCustom'
import {TitleAlt} from 'src/pages/self-register/select-plan/components/PlanSelectBase'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import {useNavigate} from 'react-router-dom'
import {ButtonContainerModal} from './ChangePlanModalContactBase'

type ChangePlanModalContactProps = {
  isOpen: boolean
}

export function ChangePlanModalContact(props: ChangePlanModalContactProps) {
  const {isOpen} = props
  const navigate = useNavigate()

  return (
    <ModalCustom isOpen={isOpen} backgroundColor='white'>
      <Box display='flex' direction='column' align='center' justifyContent='center' padding='30px' rowGap='50px'>
        <Box display='flex' direction='column' rowGap='10px' maxWidth='550px'>
          <TitleAlt>Parece que você já cadastrou a quantidade de câmeras permitidas para seu plano</TitleAlt>
          <Typography variant='p' style={{textAlign: 'center'}} size='16px'>
            Entre em contato com nosso comercial para alterar seu plano.
          </Typography>
        </Box>

        <ButtonContainerModal>
          <Button variant='outlined' width='50%' onClick={() => navigate('/registers/devices')}>
            Voltar para dispositivos
          </Button>
          <Button
            leftIcon={<WhatsAppIcon />}
            width='50%'
            color='#25D366'
            hoverColor='#25D366'
            onClick={() => window.open('https://wa.me/5544999320512', 'blank')}>
            Falar com comercial
          </Button>
        </ButtonContainerModal>
      </Box>
    </ModalCustom>
  )
}
