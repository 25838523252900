import {User} from './UserEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResponse, AxiosResult, Ord, Paginate} from './_types'

const prefix = process.env.REACT_APP_NOTIFICATIONS_BASE_URL

export type TransactionStatusValue = 'PROCESSING' | 'DELIVERED' | 'MISSED' | 'ERROR'

export type Transaction = {
  metadata: any
  transactionId: string
  detectionFeedback: DetectionFeedback[] | null
  status: string
  createdAt: string
}

export type DetectionFeedback = {
  name: string
  accuracy: number
  area: number[]
  additionalData?: object
}

export type GetTransactionsParams = {
  page?: number
  pageSize?: number
  filter?: {
    ids?: string[]
    status?: TransactionStatusValue
    onlyDetection?: boolean
    companyIds?: number[]
  }
  orderBy: {
    createdAt: Ord
  }
}

export type ReleasesNotes = {
  id: number
  subtitle: string
  title: string
  description: string
  createdAt: string
  userViews?: UserViews[]
  companies: CompaniesReleaseNotes[]
  roles: RolesReleaseNotes[]
}

export type CompaniesReleaseNotes = {
  id: number
  companyId: number
  releaseNoteId: number
}

export type RolesReleaseNotes = {
  id: number
  roleName: string
  releaseNoteId: number
}

export type UserViews = {
  id: number
  userId: User
  releaseNoteId: ReleasesNotes
  createdAt: string
}

export type GetNewReleasesParams = {
  page?: number
  pageSize?: number
  start?: string
  end?: string
  filter?: {
    companyIds?: number[]
  }
  orderBy: {
    createdAt: Ord
  }
}

export type CreateReleaseNote = {
  id?: number
  subtitle: string
  title: string
  description: string
  companies: number[]
  roles: string[]
}

export type UpdateReleaseNote = {
  title: string
  subtitle: string
  description: string
  resetViews: boolean
  companies: number[]
  roles: string[]
}

export class TransactionsEndpoint {
  async getMany(params: GetTransactionsParams): Promise<AxiosResponse<Paginate<Transaction>>> {
    return await axios.get(`${prefix}`, {
      params: prepareParams(params),
    })
  }

  async getById(transactionId: string): Promise<AxiosResponse<Transaction>> {
    return await axios.get(`${prefix}/single/${transactionId}`)
  }

  async getAll(params: GetNewReleasesParams): Promise<AxiosResult<Paginate<ReleasesNotes>>> {
    return await axios.get(`${prefix}/release-notes`, {
      params: prepareParams(params),
    })
  }

  async create(id: number): Promise<AxiosResult<UserViews>> {
    return await axios.post(`${prefix}/release-note/${id}/view`)
  }

  async createReleaseNote(body: CreateReleaseNote): Promise<AxiosResult<CreateReleaseNote>> {
    return await axios.post(`${prefix}/release-note`, body)
  }

  async deleteNote(id: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/release-note/${id}`)
  }

  async updateReleaseNote(id: number, body: UpdateReleaseNote): Promise<AxiosResult<UpdateReleaseNote>> {
    return await axios.patch(`${prefix}/release-note/${id}`, body)
  }
}
