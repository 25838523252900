import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Checkbox, FormControl, IconButton, InputAdornment, InputBase} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import AppVersion from 'src/components/AppVersion'
import {useAuth} from 'src/contexts/AuthContext'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {isEmailLoginValid, isPasswordLoginValid} from 'src/utilities/regexValidation'
import Loading from '../../components/Loading'
import useLoading from '../../hooks/useLoading'
import LogoIcon from '../../images/fullViptechLogo.svg'
import IconLogin from '../../images/iconLogin.svg'
import LoginBackground from '../../images/login-background.png'
import ViptechLogo from '../../images/viptech-logo.svg'
import api from '../../services/api'
import {AxiosErrorGeneric} from '../../utilities/RequestError'
import handleErrorWithSnackbar from '../../utilities/handleErrorWithSnackbar'
import {RedirectErrors} from './LoginFunctions'
import {
  ButtonDiv,
  Container,
  ContainerButton,
  ContainerOptions,
  ContainerSSO,
  Form,
  Input,
  InputDiv,
  IncorrectUser as InvalidState,
  LinkText,
  LoginBox,
  Logo,
  MessageSpan,
  SSOButton,
  SxLoadingButtonLogin,
  TopDiv,
  ViptechLogoDiv,
} from './Styles'
import BlockedUserModal from './components/BlockedUserModal'
import LogoutSSO from './components/logoutSSO/LogoutSSO'
import {getDecodedToken} from 'src/utilities/getDecodedToken'

function Login() {
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [invalidUsername, setInvalidUsername] = useState<boolean>(false)
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const {loading, applyLoading} = useLoading(false)
  const {login, authFromUrlToken, logout} = useAuth()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const navigate = useNavigate()
  const location = useLocation()
  const blockAuthRequests = useRef<boolean>(false)
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('otp')
  const redirectError = queryParams.get('error')
  const {enqueueSnackbar} = useSnackbar()

  const handleClickShowPassword = () => {
    setShowPassword((previous) => !previous)
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  async function handleKeyPress(event: any) {
    if (event.key === 'Enter') {
      if (username.length > 0 && password.length > 0) await loginPost()
      else setMessage('Este campo é obrigatório.')
    }
  }

  async function returnB2cUserWhereHeLeftOff() {
    const decodedToken = getDecodedToken()
    const authorizedCompanies = decodedToken.payload.authorizedCompanies

    const hasSelfRegistrationPermission = decodedToken.payload.userPermissions.includes('self-registration:create')
    if (!hasSelfRegistrationPermission) return navigate('/')

    const userHasMoreThanOneCompany = authorizedCompanies.length > 1
    if (userHasMoreThanOneCompany) return navigate('/')

    const companies = await api.company.getMany({filter: {ids: [authorizedCompanies[0]]}})

    const userAreLegalPerson = companies.data.data.entities[0].document.length >= 15
    if (userAreLegalPerson) return navigate('/status?type=company')

    const plan = await api.payments.getUserCurrentBilling({companyId: String(authorizedCompanies[0])})
    if (!plan.data.data) return navigate('/select-plan')

    const cameras = (
      await api.camera.getMany({
        page: 1,
        pageSize: 100,
        filter: {
          companyIds: [authorizedCompanies[0]],
        },
        includes: ['configuration'],
      })
    ).data.data.entities

    if (!cameras.length) return navigate('/camera-config')

    const objects = (await api.object.getInterestAreaObjects({configurationId: cameras[0].configurationId})).data.data
    if (!objects.length) return navigate('/camera-config')
    return navigate('/')
  }

  async function loginPost() {
    if (loading) return
    if (!isEmailLoginValid(username)) {
      setMessage('Insira um e-mail válido')
      setInvalidUsername(true)
    } else if (!isPasswordLoginValid(password)) {
      setMessage('A senha deve conter no mínimo 8 e no máximo 40 caracteres, e deve conter um número e uma letra')
      setInvalidPassword(true)
    } else if (username.length > 0 && password.length > 0) {
      setMessage('')
      try {
        await applyLoading(async () => await login(username, password, isSelected))
        returnB2cUserWhereHeLeftOff()
      } catch (error: any) {
        if (error.response.status === 428) return setIsOpen(true)
        const err = error as AxiosErrorGeneric
        handleErrorWithSnackbar(enqueueSnackbar, err, 'Não foi possível fazer login')
      }
    }
  }

  useEffect(() => {
    if (invalidUsername && isEmailLoginValid(username)) {
      setMessage('')
      setInvalidUsername(false)
    }
  }, [username, invalidUsername])

  useEffect(() => {
    if (invalidPassword && isPasswordLoginValid(password)) {
      setMessage('')
      setInvalidPassword(false)
    }
  }, [password, invalidPassword])

  const label = {inputProps: {'aria-label': 'Checkbox demo'}}

  const redirectSSO = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/brain-authentication/sso/msal/login`
  }

  async function authenticateSSO() {
    if (blockAuthRequests.current) return
    blockAuthRequests.current = true
    if (token && !redirectError) {
      logout()
      const response = await authFromUrlToken(token)
      if (response) navigate('/')
      else {
        navigate('/login')
        setMessage('Não foi possível fazer login')
      }
    }
    blockAuthRequests.current = false
  }

  useEffect(() => {
    authenticateSSO()
  }, [token, redirectError])

  return (
    <Container backgroundColor={redirectError ? '#1D1E28' : undefined} backgroundImage={!redirectError ? LoginBackground : undefined}>
      <BlockedUserModal isOpen={isOpen} setIsOpen={setIsOpen} email={username} />
      {!redirectError ? (
        <LoginBox>
          <TopDiv>
            <Logo src={LogoIcon} />
            <MessageSpan color={'#ffffff'}>
              Bem-vindo à Inteligência Artificial da Viptech
              <MessageSpan color={'#009EFF'}>.</MessageSpan>
            </MessageSpan>
          </TopDiv>
          <Form
            onSubmit={async (event: any) => {
              event.preventDefault()
              await loginPost()
            }}>
            <InputDiv>
              <Input
                value={username}
                onChange={(e: any) => {
                  setUsername(e.target.value.toLowerCase().trim())
                }}
                type={'text'}
                placeholder={'Login'}
                autoFocus={true}
                onKeyDown={async (event: any) => await handleKeyPress(event)}
              />
              <FormControl focused={false}>
                <InputBase
                  sx={styles.password}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onKeyDown={async (event) => await handleKeyPress(event)}
                  onChange={(e) => {
                    setPassword(e.target.value.trim())
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{color: '#FFFFFF'}}
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge='end'>
                        {showPassword === false ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder={'Senha'}
                />
              </FormControl>
            </InputDiv>
            <ContainerOptions>
              <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Checkbox
                  {...label}
                  onClick={() => {
                    setIsSelected((prev) => !prev)
                  }}
                  value={isSelected}
                  size='small'
                  sx={styles.check}
                />
                Manter-se conectado
              </Box>
              <Box>
                <Link to='/forgot-password'>
                  <LinkText>Esqueci minha senha</LinkText>
                </Link>
              </Box>
            </ContainerOptions>
            <Box>
              <InvalidState>{message}</InvalidState>
            </Box>
            <ButtonDiv>
              <LoadingButton
                size='small'
                type='submit'
                loading={loading}
                loadingIndicator={<Loading color='#fff' width='15px' height='15px' />}
                sx={SxLoadingButtonLogin}>
                Entrar
              </LoadingButton>
            </ButtonDiv>
          </Form>
          <ContainerSSO marginTop={message ? '5em' : '2em'}>
            <SSOButton onClick={redirectSSO}>
              <ContainerButton>
                <img src={IconLogin} alt='microsoft icon' style={{paddingRight: '8px'}}></img>
                <span>Entrar com minha conta Microsoft</span>
              </ContainerButton>
            </SSOButton>
          </ContainerSSO>
        </LoginBox>
      ) : (
        <LogoutSSO errorOnSSOLogin={RedirectErrors[redirectError] ?? 'erro inesperado'} />
      )}
      {!redirectError && (
        <ViptechLogoDiv>
          <img src={ViptechLogo} alt={'Logo Viptech'} />
        </ViptechLogoDiv>
      )}
      <AppVersion color='white' />
    </Container>
  )
}
const styles = createStyleSheet({
  password: {
    fontSize: '16px',
    fontFamily: 'Inter, sans-serif',
    paddingLeft: '20px',
    paddingRight: '15px',
    color: '#ffffff',
    backgroundColor: '#46464f',
    height: '2.63em',
    borderRadius: '10px',
    border: 'none',
    textIndent: '4%',
    outline: 0,
  },
  check: {
    color: 'white',
    padding: 0,
    margin: '10px 5px 10px 0px',
  },
})

export default Login
