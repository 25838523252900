import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'

import {Box} from '@viptech/react-components'
import {useCompany} from 'src/contexts/CompanyContext'
import useLoading from 'src/hooks/useLoading'
import UserOptionsMenu from 'src/pages/users/components/UserOptionsMenu'
import api, {IntegrationType, Server, ServerStatus} from 'src/services/api'
import {Page} from 'src/types'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {ConfirmationModal} from '../../../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../../../components/top-bar/TopBar'
import AppReloadButton from '../../../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../../../components/selectWithSearchComponent/SelectWithSearchComponent'
import ServerGridTable from './components/ServerGridTable'
import ServerRegisterModal from './components/ServerRegisterModal'

type ServersProps = {
  integrationType: IntegrationType
}

const searchServerBy = [{id: '1', label: 'Nome da Integração'}]

function Servers({integrationType}: ServersProps) {
  const hasCreateServerPermission = ValidatePermissions('servers:create')
  const initialServer: Server = {id: 0, name: '', ip: '', serverType: {id: 0, description: '', integrationType: 'ENTRADA'}}
  const [serverSearch, setServerSearch] = useState<string>('')
  const serverSearchDebounced = useDebounce(serverSearch, 500)
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [servers, setServers] = useState<Server[]>([])
  const [loadingServers, setLoadingServers] = useState<boolean>(false)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [serversPage, setServersPage] = useState<Page>({page: 1, pageSize: 20})
  const [open, setOpen] = useState<boolean>(false)
  const [optButton, setOptButton] = useState<boolean>(false)
  const [server, setServer] = useState<Server>(initialServer)
  const [isCreate, setIsCreate] = useState<boolean>(true)
  const {enqueueSnackbar} = useSnackbar()
  const {loading, applyLoading} = useLoading(false)
  const location = useLocation()
  const {selectedCompanies, selectedCompanyId} = useCompany()
  const [selectedServerIds, setSelectedServerIds] = useState<number[]>([])
  const [serverStatus, setServerStatus] = useState<{[x: string]: 'OK' | 'ERROR'}>({})
  const menuOpen = Boolean(menuEl)
  const quantity = selectedServerIds.length
  const isPlural: string = quantity === 1 ? '' : 's'
  const isPluralEs: string = quantity === 1 ? '' : 'es'
  const isPluralAo: string = quantity === 1 ? 'á' : 'ão'
  const blockServersRequests = useRef(false)
  const blockServersStatusRequests = useRef(false)
  const [fetchError, setHasFetchError] = useState(false)

  const navigate = useNavigate()

  function showOptionButton(ids: number[]) {
    if (ids.length > 0) {
      setOptButton(true)

      setSelectedServerIds(ids)
    } else {
      setOptButton(false)
    }
  }

  useEffect(() => {
    showOptionButton(selectedServerIds)
  }, [selectedServerIds])

  function withConfirmation(message: string, title: string) {
    return () => {
      setMenuEl(null)
      setModalContent(title)
      setOpenModal(true)
      setModalTitle(message)
    }
  }

  async function deleteServer() {
    if (loading) return
    if (selectedServerIds.length === 0) throw new Error('unreachable')
    try {
      await applyLoading(async () => {
        await api.server.deleteMany(selectedServerIds, selectedCompanyId)
        setSelectedServerIds([])
      })
      enqueueSnackbar(`Servidor${isPluralEs} removido${isPlural} com sucesso`, {
        variant: 'success',
      })
      loadServers()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover os servidores integrados')
    }
  }

  async function loadServers() {
    if (blockServersRequests.current) return
    blockServersRequests.current = true
    setLoadingServers(true)
    try {
      const response = await api.server.findManyPaged({
        page: serversPage.page === 0 ? 1 : serversPage.page,
        pageSize: 100,
        search: {
          name: serverSearchDebounced,
        },
        includes: ['serverType'],
        filter: {companyIds: selectedCompanies, integrationType, serverTypeIds: integrationType === 'ENTRADA' ? [4, 2] : undefined},
        orderBy: {names: 'ASC'},
      })
      setServers(response.data.data.entities)
      setTotalElements(response.data.data.totalElements)
      getServersStatus(response.data.data.entities.map((server) => server.id))
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar servidores de integração')
    } finally {
      setLoadingServers(false)
      blockServersRequests.current = false
    }
  }

  async function getServersStatus(ids: number[]) {
    if (ids.length === 0) return
    if (blockServersStatusRequests.current) return
    blockServersStatusRequests.current = true
    setHasFetchError(false)
    try {
      api.server.getServerStatus(ids).then((response) => {
        if (!response.body) return
        const reader = response.body.getReader()
        return new ReadableStream({
          start(controller) {
            function push() {
              reader.read().then(({done, value}) => {
                if (done) return controller.close()
                const splitedChunk = new TextDecoder().decode(value).split('\n')
                try {
                  const statuses = splitedChunk.filter((chunk) => chunk !== '').map((chunk) => JSON.parse(chunk))
                  statuses.forEach((status: ServerStatus) => {
                    if (Object.keys(status).includes('id')) serverStatus[String(status.id)] = status.status
                  })
                  setServerStatus(Object.assign({}, serverStatus))
                  push()
                } catch (error) {
                  setHasFetchError(true)
                  handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar status dos servidores')
                  push()
                }
              })
            }
            push()
          },
        })
      })
    } catch (error) {
      setHasFetchError(true)
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar status dos servidores')
    }
    blockServersStatusRequests.current = false
  }

  useEffect(() => {
    if (serversPage.page === 0) return
    loadServers()
  }, [serverSearchDebounced, serversPage, selectedCompanies])

  function openEditModal(server: Server) {
    setIsCreate(false)
    setOpen(true)
    setServer(server)
  }
  useEffect(() => {
    async function openServerModal() {
      setOpen(true)
    }
    const params = new URLSearchParams(location.search)
    if (params.has('new')) {
      openServerModal()
    }
  }, [location, enqueueSnackbar])

  useEffect(() => {
    if (open && location.pathname.includes('inputs')) {
      if (location.search.length === 0) {
        navigate(`/settings/inputs?new`)
      }
    }
  }, [location, navigate])

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  const onSelectChange = (e: DropdownItem) => {
    setShowSearchSelected(e)
  }

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton onClick={() => loadServers()} loading={loadingServers} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchServerBy}
              setSearchSelected={onSelectChange}
              searchSelected={showSearchSelected}
              inputValue={serverSearch}
              inputSearch={setServerSearch}
              widthOnSelect={'310px'}
            />
          </Box>
          <RenderIfConditionIsMet condition={hasCreateServerPermission}>
            <AppAddOrOptionsButton
              showOptions={optButton}
              onClickAdd={() => {
                setIsCreate(true)
                setOpen(true)
              }}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
              text='Adicionar um servidor'
            />
          </RenderIfConditionIsMet>
        </Box>
      </TopBar>
      <ConfirmationModal
        title={modalTitle}
        content={modalContent}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenModal(false)
              setMenuEl(null)
              deleteServer()
            },
            variant: 'contained',
            dangerAction: true,
          },
        ]}
      />
      <UserOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onDelete={withConfirmation(
          `Deletar servidor${isPluralEs}`,
          `Ser${isPluralAo} removido${isPlural} o${isPlural} servidor${isPluralEs} e tudo que está vinculado a ele${isPlural}. A ação não poderá ser desfeita.`,
        )}
      />
      <ServerGridTable
        servers={servers}
        loadingServers={loadingServers}
        totalElements={totalElements}
        setServersPage={setServersPage}
        serversPage={serversPage}
        openEditModal={openEditModal}
        selectedServerIds={selectedServerIds}
        setSelectedServerIds={setSelectedServerIds}
        serverStatus={serverStatus}
        fetchError={fetchError}
      />
      {open && (
        <ServerRegisterModal
          isCreate={isCreate}
          openModal={open}
          closeModal={() => {
            setServer(initialServer)
            setOpen(false)
          }}
          server={server}
          setServer={setServer}
          loadServers={loadServers}
        />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default Servers
