import {Box, Button, Card, Grid, Tooltip, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {AspectRatio} from 'react-aspect-ratio'
import ReactPlayer from 'react-player'
import {useNavigate} from 'react-router-dom'
import Loading from 'src/components/Loading'
import api, {Camera, Snapshot} from 'src/services/api'
import storage from 'src/services/storage'
import MosaicIconSelected from '../../../images/mosaicIconSelected.svg'
import LPREventList from './LPREventList'
import {AxiosErrorGeneric} from 'src/utilities/RequestError'

export type lprProps = {
  cameras: number[]
  mosaico: number
  openCameraModal: (stream: StreamsAsync) => void
}

type StreamsAsync = {tag: 'Empty'} | {tag: 'Loading'} | {tag: 'Success'; data: Camera; hash: string} | {tag: 'Error'; message?: any; data?: Snapshot | null}

function LayoutLPR(props: lprProps) {
  const {cameras, mosaico, openCameraModal} = props
  const [streams, setStreams] = useState<StreamsAsync[]>([])
  const tooltipCam = `Exibir player <br/> da câmera`
  const navigate = useNavigate()

  function getMessageHtml() {
    return <div dangerouslySetInnerHTML={{__html: tooltipCam}} />
  }
  const getColumnQuantity = () => {
    switch (mosaico) {
      case 1:
        return {sm: 12, xl: 12}
      case 4:
        return {xs: 12, md: 6, sm: 6, lg: 6, xl: 6}
      case 9:
        return {xs: 12, sm: 6, md: 4, lg: 4, xl: 4}
      case 16:
        return {xs: 12, sm: 6, md: 3, xl: 3}
      case 32:
        return {xs: 12, sm: 6, md: 3, xl: 3}
      default:
        break
    }
  }

  const getItemsQuantityByMosaico = () => {
    switch (mosaico) {
      case 1:
        return 1
      case 4:
        return 2
      case 9:
        return 3
      case 16:
        return 4
      case 32:
        return 6.4
      default:
        return 1
    }
  }

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      if (document.fullscreenElement) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        }
      } else {
        navigate(-1)
      }
    }
  }

  function handleFullscreenChange() {
    if (!document.fullscreenElement) {
      navigate(-1)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange)
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    async function loadConnection() {
      if (!cameras) return
      setStreams(Array.from({length: mosaico}, (_, index) => (index < cameras.length ? {tag: 'Loading'} : {tag: 'Empty'})))

      await Promise.allSettled(
        cameras.map(async (camera, index) => {
          const response2 = await api.camera.getById(camera, {includes: ['server']})
          const cam = response2.data.data

          try {
            const response = await api.streaming.getStream(camera)
            const liveStream = response.data.data
            setStreams((lastStream) => {
              const newStream = [...lastStream]
              if (liveStream !== null) {
                newStream[index] = {tag: 'Success', data: cam, hash: liveStream}
              } else {
                newStream[index] = {tag: 'Error'}
              }
              return newStream
            })
          } catch (error: any) {
            const axiosError = error as AxiosErrorGeneric
            let snapshot: Snapshot | null = null
            setStreams((lastStream) => {
              const newStream = [...lastStream]
              newStream[index] = {tag: 'Error', message: axiosError.response?.data.message, data: snapshot}
              return newStream
            })
          }
        }),
      )
    }
    loadConnection()
  }, [cameras])

  return (
    <Grid container xs={12} sx={styles.container}>
      {streams.map((streamAsync, index) => (
        <Grid item key={index} {...getColumnQuantity()} sx={{...styles.gridItem, height: `calc(100vh / (${mosaico / getItemsQuantityByMosaico()}))`}}>
          <Card sx={mosaico === 1 ? styles.card : styles.cardSmall}>
            {streamAsync.tag === 'Loading' ? (
              <Box>
                <Loading />
              </Box>
            ) : streamAsync.tag === 'Empty' ? (
              <Box sx={{color: 'white', justifyContent: 'center', fontSize: '20px', display: 'flex'}}>Sem Câmera</Box>
            ) : streamAsync.tag === 'Success' ? (
              <Box sx={{width: '100%', height: '100%'}}>
                <Box sx={{display: 'flex', color: '#fff', justifyContent: 'space-between', alignItems: 'center'}}>
                  <Typography
                    sx={mosaico === 1 ? styles.cameraInfo1 : mosaico === 4 ? styles.cameraInfo4 : mosaico === 16 ? styles.cameraInfo16 : styles.cameraInfo}>
                    #({streamAsync.data.id}) {streamAsync.data.name}
                  </Typography>
                  <Tooltip title={getMessageHtml()}>
                    <Button
                      sx={styles.cameraPlayer}
                      onClick={() => {
                        openCameraModal(streamAsync)
                      }}>
                      <img
                        src={MosaicIconSelected}
                        alt='Ver camera'
                        style={
                          mosaico === 1
                            ? {width: 30, height: 30, marginRight: 20}
                            : mosaico === 16
                              ? {width: 12, height: 12}
                              : mosaico === 32
                                ? {width: 12, height: 12}
                                : {}
                        }
                      />
                    </Button>
                  </Tooltip>
                </Box>
                <AspectRatio ratio={16 / 9}>
                  <>
                    <Box sx={{position: 'relative'}}>
                      <ReactPlayer
                        url={`${api.streaming.startStream(streamAsync.hash)}`}
                        config={{
                          file: {
                            hlsOptions: {
                              forceHLS: true,
                              debug: false,
                              xhrSetup: function (xhr: any) {
                                xhr.setRequestHeader('Authorization', `Bearer ${storage.get('token')}`)
                              },
                            },
                          },
                        }}
                        autoPlay
                        preload={'auto'}
                        playing={true}
                        width={'100%'}
                        height={'100%'}
                      />
                    </Box>
                  </>
                </AspectRatio>
              </Box>
            ) : (
              <Box sx={styles.streamError}>
                <img src={`data:image/jpeg;base64,${streamAsync.data?.base64}`} alt='' style={styles.image} />
                <Box sx={mosaico === 32 ? styles.messageError32 : styles.messageError}>{streamAsync.message}</Box>
              </Box>
            )}
          </Card>
          <Box sx={mosaico === 1 ? styles.sidebar : styles.sidebarSmall}>
            <Box sx={styles.mosaicoSidebar}>
              <LPREventList mosaico={mosaico} camera={cameras[index]} />
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

const styles = {
  container: {
    height: '100%',
    backgroundColor: '#000000',
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '10px',
    backgroundColor: '#000000',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    backgroundColor: '#000000',
    border: '1px solid #3E3E3E',
  },
  cardSmall: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80%',
    backgroundColor: '#000000',
    border: '1px solid #3E3E3E',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#3E3E3E',
    width: '20%',
  },
  sidebarSmall: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#3E3E3E',
    width: '20%',
  },
  image: {
    backgroundColor: '#000000',
    height: '100%',
    width: '100%',
  },
  mosaicoSidebar: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    padding: '8px',
    backgroundColor: '#1D1E28',
    overflow: 'scroll',
  },
  cameraPlayer: {
    zIndex: 9999,
    width: '35px',
    minWidth: 0,
    height: '22px',
  },
  cameraInfo: {
    fontSize: '10px',
    fontFamily: 'Inter',
  },
  cameraInfo16: {
    fontSize: '10px',
    fontFamily: 'Inter',
  },
  cameraInfo1: {
    margin: '5px',
    fontSize: '25px',
    fontFamily: 'Inter',
  },
  cameraInfo4: {
    fontSize: '16px',
    fontFamily: 'Inter',
  },
  icons16: {
    color: 'white',
    fontSize: '10px',
    width: 12,
    height: 12,
  },
  icons: {
    color: 'white',
    marginBottom: '2px',
    fontSize: '22px',
    width: 30,
    height: 30,
  },
  icons4: {
    color: 'white',
    fontSize: '10px',
    width: 22,
    height: 22,
  },
  icons32: {
    color: 'white',
    fontSize: '5px',
    width: 12,
    height: 12,
  },
  icons9: {
    color: 'white',
    fontSize: '12px',
    width: 15,
    height: 15,
  },
  buttonIcon32: {
    width: '18px',
    minWidth: '10px',
    height: '27px',
  },
  buttonIcon16: {
    width: '20px',
    minWidth: '20px',
    height: '28px',
  },
  buttonIcon9: {
    width: '15px',
    minWidth: '25px',
    height: '25px',
  },
  buttonIcon4: {
    width: '35px',
    minWidth: '20px',
    height: '38px',
  },
  buttonIcon1: {
    width: '45px',
    minWidth: '20px',
    height: '47px',
  },
  messageError: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    justifyContent: 'center',
    fontSize: '20px',
    display: 'flex',
    backgroundColor: '#444444',
    zIndex: '1',
  },
  messageError32: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    justifyContent: 'center',
    fontSize: '14px',
    width: '200px',
    display: 'flex',
    backgroundColor: '#444444',
    zIndex: '1',
  },
  streamError: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
}

export default LayoutLPR
