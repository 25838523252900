import styled from 'styled-components'

type IconProps = {
  width?: string
  height?: string
  open?: boolean
  margin?: string
  cursor?: string
  padding?: string
  position?: string
}

export const Icon = styled.img<IconProps>`
  ${(props) => !!props.width && `width: ${props.width};`}
  ${(props) => !!props.height && `height: ${props.height};`}
  ${(props) => !!props.margin && `margin: ${props.margin};`}
  ${(props) => !!props.cursor && `cursor: ${props.cursor};`}
  ${(props) => !!props.padding && `padding: ${props.padding};`}
  ${(props) => !!props.open && `transform: rotate(180deg);`};
  ${(props) => !props.open && `transform: none;`};
  ${(props) => !!props.position && `position: ${props.position};`};
  right: 0;
  transition: 0.3s;
`
