export function validateCPF(cpf: string): boolean {
  cpf = cpf.replace(/[^\d]+/g, '')

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false
  }

  let sum = 0
  let dif

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i)
  }
  dif = (sum * 10) % 11

  if (dif === 10 || dif === 11) {
    dif = 0
  }
  if (dif !== parseInt(cpf.substring(9, 10))) {
    return false
  }

  sum = 0
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i)
  }
  dif = (sum * 10) % 11

  if (dif === 10 || dif === 11) {
    dif = 0
  }
  if (dif !== parseInt(cpf.substring(10, 11))) {
    return false
  }

  return true
}

export function validateCNPJ(document: string): boolean {
  document = document.replace(/[^\d]+/g, '')

  if (document.length !== 14 || /^(\d)\1+$/.test(document)) {
    return false
  }

  let size = document.length - 2
  let numbers = document.substring(0, size)
  let digitos = document.substring(size)
  let sum = 0
  let pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return false
  }

  size++
  numbers = document.substring(0, size)
  sum = 0
  pos = size - 7

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11)

  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return false
  }

  return true
}
