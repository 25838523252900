import {DayPickerCalendarGrid, DayPickerDay, DayPickerWeekday, DayPickerWeekdaysContainer} from './DayPickerBase'
import {DayPickerProps} from './DayPickerProps'

const WEEKDAYS = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab']

export default function DayPicker({min, max, currentMonth, currentYear, selectedDate, onChangeDay, initialDate, finalDate}: DayPickerProps) {
  const handleIsDisabled = (date: Date) => (min && date.getTime() < min.getTime()) || (max && date.getTime() > max.getTime()) || false

  const startDate = new Date()
  startDate.setFullYear(currentYear)
  startDate.setMonth(currentMonth)
  startDate.setDate(1)

  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 1)
  const daysInMonth = []

  let currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    daysInMonth.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  const amountOfWeeksToCompleteSixRows = startDate.getDay() + daysInMonth.length >= 27 && startDate.getDay() + daysInMonth.length < 36

  const renderLastMonthDays = (startDate: Date, count: number) => {
    const newDate = new Date(startDate)
    newDate.setMonth(currentMonth - 1)
    newDate.setDate(1)

    const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1)
    const daysInMonth: Array<Date> = []

    let currentDate = new Date(newDate)

    while (currentDate <= endDate) {
      daysInMonth.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    const lastMonthDays = []
    for (let i = daysInMonth.length - count; i < daysInMonth.length; i++) {
      lastMonthDays.push(
        <DayPickerDay
          key={`empty-${i}`}
          checkOne={initialDate && daysInMonth[i].getDate() === initialDate.getDate() && daysInMonth[i].getMonth() === initialDate.getMonth()}
          checkTwo={finalDate && daysInMonth[i].getDate() === finalDate.getDate() && daysInMonth[i].getMonth() === finalDate.getMonth()}
          isSelected={
            initialDate &&
            finalDate &&
            new Date(daysInMonth[i].toDateString()) >= new Date(initialDate.toDateString()) &&
            new Date(daysInMonth[i].toDateString()) <= new Date(finalDate.toDateString())
          }
          isCurrentMonth={false}
          isToday={false}
          disabled={handleIsDisabled(daysInMonth[i])}
          onClick={() => !handleIsDisabled(daysInMonth[i]) && onChangeDay(daysInMonth[i])}>
          {daysInMonth[i].getDate()}
        </DayPickerDay>,
      )
    }
    return lastMonthDays
  }

  const renderNextMonthDays = (startDate: Date, count: number, renderOneMoreWeek: boolean) => {
    const newDate = new Date(startDate)
    newDate.setMonth(currentMonth + 1)
    newDate.setDate(1)

    const endDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1)
    const firstDayInTheMonth = new Date(newDate.getFullYear(), newDate.getMonth(), 1)
    const lastDayInTheMonth = new Date(newDate.getFullYear(), newDate.getMonth(), -1)
    const daysInMonth: Array<Date> = []

    let currentDate = new Date(newDate)

    while (currentDate <= endDate) {
      daysInMonth.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    const nextMonthDays = []

    const weekAmount = currentMonth === 1 && lastDayInTheMonth.getDate() <= 28 && firstDayInTheMonth.getDay() === 0
    const repeatAmount = renderOneMoreWeek ? (weekAmount ? count + 14 : count + 7) : count

    for (let i = 0; i < repeatAmount; i++) {
      nextMonthDays.push(
        <DayPickerDay
          key={`empty-${i}`}
          checkOne={initialDate && daysInMonth[i].getDate() === initialDate.getDate() && daysInMonth[i].getMonth() === initialDate.getMonth()}
          checkTwo={finalDate && daysInMonth[i].getDate() === finalDate.getDate() && daysInMonth[i].getMonth() === finalDate.getMonth()}
          isSelected={
            initialDate &&
            finalDate &&
            new Date(daysInMonth[i].toDateString()) >= new Date(initialDate.toDateString()) &&
            new Date(daysInMonth[i].toDateString()) <= new Date(finalDate.toDateString())
          }
          isCurrentMonth={false}
          isToday={false}
          disabled={handleIsDisabled(daysInMonth[i])}
          onClick={() => !handleIsDisabled(daysInMonth[i]) && onChangeDay(daysInMonth[i])}>
          {daysInMonth[i].getDate()}
        </DayPickerDay>,
      )
    }
    return nextMonthDays
  }

  return (
    <>
      <DayPickerWeekdaysContainer>
        {WEEKDAYS.map((weekday, index) => (
          <DayPickerWeekday key={`${weekday}_${index}`}>{weekday}</DayPickerWeekday>
        ))}
      </DayPickerWeekdaysContainer>

      <DayPickerCalendarGrid>
        {renderLastMonthDays(startDate, startDate.getDay())}
        {daysInMonth.map((date) => (
          <DayPickerDay
            key={date.toDateString()}
            disabled={handleIsDisabled(date)}
            checkOne={initialDate && date.getDate() === initialDate.getDate() && date.getMonth() === initialDate.getMonth()}
            checkTwo={finalDate && date.getDate() === finalDate.getDate() && date.getMonth() === finalDate.getMonth()}
            isSelected={
              initialDate &&
              finalDate &&
              new Date(date.toDateString()) >= new Date(initialDate.toDateString()) &&
              new Date(date.toDateString()) <= new Date(finalDate.toDateString())
            }
            isCurrentMonth={date.getMonth() === startDate.getMonth()}
            isToday={date.toDateString() === new Date().toDateString()}
            onClick={() => {
              !handleIsDisabled(date) && onChangeDay(date)
            }}>
            {date.getDate()}
          </DayPickerDay>
        ))}
        {renderNextMonthDays(startDate, 6 - daysInMonth[daysInMonth.length - 1].getDay(), amountOfWeeksToCompleteSixRows)}
      </DayPickerCalendarGrid>
    </>
  )
}
