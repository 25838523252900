import React from 'react';

interface PlayIconChangeColorProps {
  color?: string;
}

const PlayIconChangeColor: React.FC<PlayIconChangeColorProps> = ({color}) => {
  return (
     <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0417 6.21096C13.6632 7.12305 14.4739 7.57909 14.744 8.17834C14.9794 8.70082 14.9794 9.29918 14.744 9.82166C14.4739 10.4209 13.6632 10.877 12.0417 11.789L5.76883 15.3175C4.2012 16.1993 3.41738 16.6402 2.77534 16.5676C2.21522 16.5043 1.70782 16.2076 1.37802 15.7504C0.999999 15.2264 0.999999 14.3271 0.999999 12.5285V5.47151C0.999999 3.67288 0.999999 2.77357 1.37802 2.24957C1.70782 1.79242 2.21522 1.49567 2.77534 1.43235C3.41738 1.35977 4.2012 1.80067 5.76884 2.68247L12.0417 6.21096Z" stroke={color ? color: '#009EFF'} troke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default PlayIconChangeColor