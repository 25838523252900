import {Box} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import api from 'src/services/api'
import {Lpr} from 'src/services/api/endpoints/LprEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type Mosaico = {
  mosaico: number
  camera: number
}

function LPREventList(props: Mosaico) {
  const {enqueueSnackbar} = useSnackbar()
  const [lprAlarms, setLprAlarms] = useState<Lpr[]>([])
  const {mosaico, camera} = props

  async function loadLpr() {
    try {
      const response = await api.lpr.getAllLPR({
        filter: {
          cameraIds: camera ? [camera] : [0],
        },
        start: moment().subtract(1, 'hours').format(),
        end: moment().format(),
        batchSize: 50,
        startId: null,
        orderBy: {
          createdAt: 'DESC',
        },
        includes: ['camera', 'plate'],
      })
      setLprAlarms(response.data.data.items)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar placas detectadas')
    }
  }

  useEffect(() => {
    if (lprAlarms.length === 0) loadLpr()
    const id = setInterval(() => {
      loadLpr()
    }, 10000)
    return () => clearInterval(id)
  }, [])

  return (
    <Box>
      {lprAlarms.map((plate) => {
        return (
          <Box sx={{display: 'flex', justifyContent: 'column'}}>
            <Box>
              <Box
                sx={
                  mosaico === 1
                    ? styles.titlePlate
                    : mosaico === 4
                      ? styles.titlePlateMosaico4
                      : mosaico === 9
                        ? styles.titlePlateMosaico9
                        : mosaico === 16
                          ? styles.titlePlateMosaico16
                          : styles.titlePlateMosaico32
                }>
                BRAIN
              </Box>
              <Box
                sx={
                  mosaico === 1
                    ? styles.dataPlate
                    : mosaico === 4
                      ? styles.dataPlateMosaico4
                      : mosaico === 9
                        ? styles.dataPlateMosaico9
                        : mosaico === 16
                          ? styles.dataPlateMosaico16
                          : styles.dataPlateMosaico32
                }>
                {plate.plate.licensePlate ?? '#ERRO'}
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

const styles = createStyleSheet({
  plate: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '14.075em',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.6em',
    border: '2px solid #3E3E3E',
    borderLeft: '12px solid #0080ff',
    borderRadius: '0.3125em',
    margin: '0.625em 0',
  },
  plateGreen: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '14.075em',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.6em',
    border: '2px solid #3E3E3E',
    borderLeft: '12px solid #1fe22e',
    borderRadius: '0.3125em',
    margin: '0.625em 0',
  },
  plateRed: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '14.075em',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '0.6em',
    border: '2px solid #3E3E3E',
    borderLeft: '12px solid #ed1909',
    borderRadius: '0.3125em',
    margin: '0.625em 0',
  },
  plateMosaico4: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '115px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px',
    border: '2px solid #3E3E3E',
    borderLeft: '10px solid #0080ff',
    borderRadius: '3px',
    height: '50px',
    marginBottom: 1,
  },
  plateMosaico4Green: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '115px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px',
    height: '50px',
    border: '2px solid #3E3E3E',
    borderLeft: '10px solid #1fe22e',
    borderRadius: '3px',
    marginBottom: 1,
  },
  plateMosaico4Red: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '115px',
    height: '50px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '10px',
    border: '2px solid #3E3E3E',
    borderLeft: '10px solid #ed1909',
    borderRadius: '3px',
    marginBottom: 1,
  },
  plateMosaico9: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '80px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2px',
    border: '2px solid #3E3E3E',
    borderLeft: '7px solid #0080ff',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico9Green: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '80px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2px',
    border: '2px solid #3E3E3E',
    borderLeft: '7px solid #1fe22e',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico9Red: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '80px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2px',
    border: '2px solid #3E3E3E',
    borderLeft: '7px solid #ed1909',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico16: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '58px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1px',
    border: '2px solid #3E3E3E',
    borderLeft: '5px solid #0080ff',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico16Green: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '58px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1px',
    border: '2px solid #3E3E3E',
    borderLeft: '5px solid #1fe22e',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico16Red: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '58px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1px',
    border: '2px solid #3E3E3E',
    borderLeft: '5px solid #ed1909',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico32: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '55px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2px',
    border: '2px solid #3E3E3E',
    borderLeft: '5px solid #0080ff',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico32Green: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '55px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2px',
    border: '2px solid #3E3E3E',
    borderLeft: '5px solid #1fe22e',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  plateMosaico32Red: {
    backgroundColor: 'transparent',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '55px',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '2px',
    border: '2px solid #3E3E3E',
    borderLeft: '5px solid #ed1909',
    borderRadius: '2px',
    marginBottom: 0.5,
  },
  dataPlate: {
    fontSize: '1.7em',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '2px',
    wordBreak: 'break-all',
    backgroundColor: 'white',
    width: '190px',
    letterSpacing: '2px',
  },
  dataPlateMosaico9: {
    fontSize: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: ' 1px',
    wordBreak: 'break-all',
    backgroundColor: 'white',
    width: '70px',
    letterSpacing: '1px',
  },
  dataPlateMosaico16: {
    fontSize: '7px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: ' 2px',
    wordBreak: 'break-all',
    backgroundColor: 'white',
    width: '50px',
    letterSpacing: '1px',
  },
  dataPlateMosaico32: {
    fontSize: '6.5px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: ' 2px',
    wordBreak: 'break-all',
    backgroundColor: 'white',
    width: '45px',
    letterSpacing: '1px',
  },
  dataPlateMosaico4: {
    fontSize: '15px',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: ' 1px',
    wordBreak: 'break-all',
    backgroundColor: 'white',
    width: '100px',
    letterSpacing: '2px',
  },
  titlePlate: {
    width: '14.9em',
    backgroundColor: 'blue',
    fontSize: '0.8rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '1px 0',
  },
  titlePlateMosaico9: {
    width: '70px',
    backgroundColor: 'blue',
    fontSize: '0.280rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '1px 0',
  },
  titlePlateMosaico16: {
    width: '50px',
    backgroundColor: 'blue',
    fontSize: '0.270rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '1px 0',
  },
  titlePlateMosaico32: {
    width: '46px',
    backgroundColor: 'blue',
    fontSize: '0.210rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '1px 0',
  },
  titlePlateMosaico4: {
    width: '100px',
    backgroundColor: 'blue',
    fontSize: '0.500rem',
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '1px 0',
  },
})

export default LPREventList
