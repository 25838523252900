import {forwardRef} from 'react'
import {ButtonBase, ButtonIconContainer} from './ButtonBase'
import {ButtonProps} from './ButtonProps'
import Loading from 'src/components/Loading'

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <ButtonBase ref={ref} type={'button' ?? props.type} disabled={props.isLoading ?? props.disabled ?? false} {...props}>
      {props.isLoading ? (
        <Loading color={props.loadingColor} width={props.loadingWidth} height={props.loadingHeight} />
      ) : (
        <>
          {props.leftIcon && (
            <ButtonIconContainer fontSize={props.fontSize} size={props.size}>
              {props.leftIcon}
            </ButtonIconContainer>
          )}
          {props.children}
          {props.rightIcon && (
            <ButtonIconContainer fontSize={props.fontSize} size={props.size}>
              {props.rightIcon}
            </ButtonIconContainer>
          )}
        </>
      )}
    </ButtonBase>
  )
})
