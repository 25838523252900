import {Box} from '@mui/material'
import {useState} from 'react'
import {KonvaImageDetectionsFullscreen} from 'src/common/components/konva-image-detections-fullscreen/KonvaImageDetectionsFullscreen'
import {KonvaImageDetections} from 'src/common/components/konva-image-detections/KonvaImageDetections'
import Loading from '../../../components/Loading'
import createStyleSheet from '../../../utilities/createStyleSheet'
import {useTransactionsContext} from '../contexts/TransactionsContext'

function TransactionsImagesModal() {
  const {transaction, loadingTransactions, imageSelected} = useTransactionsContext()

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false)
  const [detections, setDetections] = useState<any[]>([])

  if (loadingTransactions) {
    return (
      <Box style={styles.loadingContainer}>
        <Loading />
      </Box>
    )
  }

  if (!transaction) {
    return <Box style={{textAlign: 'center'}}>Imagem não encontrada!</Box>
  }

  const renderImage = () => {
    const detectionFeedbacks = transaction.detectionFeedback ?? []
    const detections = detectionFeedbacks.map((it) => ({
      x: it.area[0],
      y: it.area[1],
      width: it.area[2],
      height: it.area[3],
      data: {
        accuracy: it.accuracy,
        additionalData: it.additionalData,
        class: it.name,
        endX: it.area[3],
        endY: it.area[2],
        startX: it.area[0],
        startY: it.area[1],
      },
    }))

    return (
      <Box
        key={`transaction`}
        onClick={() => {
          setIsFullscreen(true)
          setDetections(detections)
        }}>
        <KonvaImageDetections detections={detections} src={imageSelected} height={235} />
      </Box>
    )
  }

  return (
    <Box sx={styles.container}>
      {renderImage()}
      {isFullscreen && (
        <KonvaImageDetectionsFullscreen open={isFullscreen} src={imageSelected} detections={detections} onClose={() => setIsFullscreen(false)} />
      )}
    </Box>
  )
}
const styles = createStyleSheet({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
})

export default TransactionsImagesModal
