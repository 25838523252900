import {Card, CardContent, CircularProgress, Tooltip, Typography} from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import infoIcon from 'src/images/infoIcon.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'

type DonutInfoProps = {
  title: string
  loading: boolean
  data: ApexCharts.ApexOptions['series']
  labelsXaxis?: Array<string>
  height?: string
  colors?: Array<string>
  infos?: string
}

function DonutChartsCard({title, loading, data, labelsXaxis, height, colors, infos}: DonutInfoProps) {
  const opts: ApexCharts.ApexOptions = {
    series: data ?? [],

    chart: {
      type: 'donut',
      fontFamily: 'Inter, sans-serif',
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: true,
      },
    },
    tooltip: {
      fillSeriesColor: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: '70%',
        },
      },
    },
    yaxis: {
      show: true,
      labels: {
        formatter: function (val) {
          return val.toFixed(0) + '%'
        },
      },
      decimalsInFloat: 0,
    },
    noData: {
      text: 'Nenhum dado foi encontrado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 5,
      offsetY: -40,
      style: {
        color: '#000000',
        fontSize: '10px',
        fontFamily: "'Inter', 'sans-serif",
      },
    },
    colors: colors ? colors : ['#BA281E', '#02C7C1', '#26BA02', '#F2B252', '#0085FF', '#653783', '#7A2B00', '#0D7A77', '#284561', '#F1F1F1', '#1D1D1D'],
    labels: labelsXaxis ?? [],
    legend: {
      show: true,
      position: 'bottom',
    },
  }

  return (
    <Card sx={[styles.card, {height, position: 'relative'}]} variant='outlined'>
      <CardContent>
        <Typography style={styles.title} sx={{paddingBottom: '15px'}}>
          {title}
        </Typography>

        <Tooltip title={`Resultados possíveis: ${infos}`}>
          <Icon src={infoIcon} cursor='pointer' style={{position: 'absolute', top: '20px', right: '20px'}} />
        </Tooltip>

        {loading ? <CircularProgress /> : <ReactApexChart options={opts} series={opts.series} type='donut' height={250} />}
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  card: {
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '2px 5px',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#364152',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontSize: '16px',
    textAlign: 'center',
  },
})

export default DonutChartsCard
