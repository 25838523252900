import {StyledLoadingTableRow} from './styles'
import {LoadingTableRowProps} from './types'
import {TableDataBase} from '../tableData/TableDataBase'
import {Skeleton} from '@viptech/react-components'

export default function LoadingTableRow({columns, loadingDataHeight}: LoadingTableRowProps) {
  return (
    <StyledLoadingTableRow>
      {columns.map(() => {
        return (
          <TableDataBase>
            <Skeleton width={`${Math.floor(Math.random() * 50) + 40}%`} height={loadingDataHeight ?? '1rem'} borderRadius='5px' />
          </TableDataBase>
        )
      })}
    </StyledLoadingTableRow>
  )
}
