import {Box, Button, Typography} from '@mui/material'
import {GridCloseIcon} from '@mui/x-data-grid'
import {useCallback, useState} from 'react'
import {Link} from 'react-router-dom'
import {KonvaImageDetectionsFullscreen} from 'src/common/components/konva-image-detections-fullscreen/KonvaImageDetectionsFullscreen'
import {FaceRecognitionDTO} from 'src/common/models/facial-recognition/FaceRecognitionDTO'
import {RegisteredFace} from 'src/pages/alarms/components/alarms-modal/components/registered-face/RegisteredFace'
import {UnregisteredFace} from 'src/pages/alarms/components/alarms-modal/components/unregistered-face/UnregisteredFace'
import {useAlarmsContext} from 'src/pages/alarms/contexts/AlarmsContext'
import {Alarm} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ValidatePermissions} from '../../../../../../common/utils/ValidatePermissions'
import {AddFaceModalEvent} from './AddFaceModalEvent'

type FacialModalProps = {
  alarm: Alarm
  faceRecognition: FaceRecognitionDTO
  isDisabled: boolean
}

export const FacialModal = (facialModalProps: FacialModalProps) => {
  const [open, setOpen] = useState(false)
  const [isDetectionAccurate, setIsDetectionAccurate] = useState<boolean>()
  const [imageSelected, setImageSelected] = useState('')
  const [detections, setDetections] = useState<Array<any>>([])
  const {self, closeAlarmModal, hasPermissionReadCamera} = useAlarmsContext()
  const hasPermissionUpdate = ValidatePermissions('face:update')

  const updateSetOpen = () => setOpen((prev) => !prev)
  const onClose = () => {
    setImageSelected('')
    setDetections([])
  }

  const updateSetIsDetectionAccurate = useCallback((isDetectionAccurate?: boolean) => setIsDetectionAccurate(isDetectionAccurate), [])
  const handleGetImageDemarcated = useCallback((src: string, detections?: Array<any>) => {
    setImageSelected(src)
    if (detections !== undefined && detections.length > 0) setDetections(detections)
  }, [])

  if (!self) return null

  function getImageBase64() {
    const imageUrl = process.env.REACT_APP_FACE_IMAGES_BASE_URL || ''
    const alarm = facialModalProps.alarm
    if (alarm && alarm.faceRecognitions) {
      if (alarm.faceRecognitions[0].face && alarm.faceRecognitions[0].face.name !== null) return `${imageUrl}/${alarm.faceRecognitions[0].face.cropImageKey}`
      else return `${imageUrl}/${alarm.faceRecognitions[0].cropImageKey}`
    }
  }

  return (
    <>
      <Box
        sx={{
          width: '446px',
          height: '100%',
          padding: '0 36px 36px 36px',
          border: '1px solid #bbbbbb7b',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box width={'100%'} display={'flex'} justifyContent={'center'}>
          {hasPermissionReadCamera && (
            <Button
              variant='contained'
              sx={styles.configButton}
              component={Link}
              to={`/registers/devices/${self.cameraId}`}
              disabled={facialModalProps.isDisabled}>
              Configuração do Dispositivo
            </Button>
          )}
        </Box>
        <Box flexDirection={'row'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
          <Box flexDirection={'row'} display={'flex'}>
            <Typography fontSize={'16px'} lineHeight={'22px'} fontWeight={700}>
              Dispositivo:
            </Typography>
            <Typography fontSize={'16px'} lineHeight={'22px'} fontWeight={400} marginLeft={'8px'}>
              {self.camera.name}
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: '50%',
              width: '1.6em',
              height: '1.6em',
              paddingLeft: '0.7px',
              alignItems: 'end',
              color: '#1976D2',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: '#8383eb62',
              },
            }}
            onClick={closeAlarmModal}>
            <GridCloseIcon />
          </Box>
        </Box>
        {facialModalProps.faceRecognition.face !== null && facialModalProps.faceRecognition.face.name !== null ? (
          <RegisteredFace
            face={facialModalProps.faceRecognition.face!}
            id={facialModalProps.alarm.id}
            imageKey={facialModalProps.faceRecognition.imageKey}
            accuracy={facialModalProps.faceRecognition.accuracy}
            cropImageKey={facialModalProps.faceRecognition.cropImageKey}
            getSelectedImage={handleGetImageDemarcated}
            getIsDetectionAccurate={updateSetIsDetectionAccurate}
          />
        ) : (
          <UnregisteredFace
            id={facialModalProps.alarm.id}
            imageKey={facialModalProps.faceRecognition.imageKey}
            cropImageKey={facialModalProps.faceRecognition.cropImageKey}
            getSelectedImage={handleGetImageDemarcated}
            getIsDetectionAccurate={updateSetIsDetectionAccurate}
          />
        )}
        <Box display={'flex'} justifyContent={'center'} height={'60%'} alignItems={'end'}>
          {hasPermissionUpdate &&
            (facialModalProps.faceRecognition.face?.name !== null ? (
              isDetectionAccurate === false ? (
                <></>
              ) : (
                <Button
                  variant='contained'
                  onClick={updateSetOpen}
                  sx={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '0.665em',
                    fontWeight: 'bold',
                    textTransform: 'initial',
                    height: '40px',
                    backgroundColor: '#009eff',
                    transition: '0.1s',
                    '&:hover': {
                      backgroundColor: '#00639f',
                    },
                  }}>
                  Adicionar à lista
                </Button>
              )
            ) : isDetectionAccurate === false ? (
              <></>
            ) : (
              <Button
                variant='contained'
                onClick={updateSetOpen}
                sx={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '0.665em',
                  fontWeight: 'bold',
                  textTransform: 'initial',
                  textAlign: 'center',
                  height: '40px',
                  backgroundColor: '#009eff',
                  transition: '0.1s',
                  '&:hover': {
                    backgroundColor: '#00639f',
                  },
                }}>
                Cadastrar face
              </Button>
            ))}
        </Box>
      </Box>
      <AddFaceModalEvent
        isOpenModal={open}
        closeModal={() => updateSetOpen()}
        nameFromEvent={facialModalProps.faceRecognition.face?.name!}
        imageId={facialModalProps.faceRecognition.face?.id!}
        image={getImageBase64()!}
        camera={self.camera}
      />
      {imageSelected && (
        <KonvaImageDetectionsFullscreen open={Boolean(imageSelected)} src={imageSelected} detections={detections} onClose={onClose} typeDemarcatedArea='face' />
      )}
    </>
  )
}

const styles = createStyleSheet({
  configButton: {
    width: '82.5%',
    height: '40px',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'white',
    marginBottom: '20px',
    marginLeft: 1,
    fontFamily: 'Inter, sans-serif',
    fontSize: '0.665em',
    fontWeight: 'bold',
    textTransform: 'initial',
    textAlign: 'center',
    marginTop: '9.6px',
    backgroundColor: '#009eff',
    transition: '0.1s',
    '&:hover': {
      backgroundColor: '#00639f',
      color: 'white',
    },
  },
})
