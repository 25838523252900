import styled from 'styled-components'

export const InfoIcon = styled.div`
  background-color: #0088ff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const CardCVVContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  padding: 40px;
  width: 616px;
  border-radius: 16px;
  background-color: white;
  position: absolute;
  color: black;
  overflow: hidden;
  z-index: 5;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  top: 24px;
  border: 1px solid #eaecf0;
`
