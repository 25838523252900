import styled from 'styled-components'
import {DivCountObject} from './CountObjectsProps'

export const GridDiv = styled.div<DivCountObject>`
  display: grid;
  gap: 10px;
  height: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({length}) => length};
`
