import {DropdownItem, Stepper, StepsTitles} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {BtnClose} from 'src/components/BtnClose'
import {useCompany} from 'src/contexts/CompanyContext'
import {updateCamera} from 'src/pages/camera-config/CameraConfigFunctions'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import {BrainBox, CreateTriggerDTO} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import {LineCrossingObject} from 'src/services/api/endpoints/ObjectEndpoint'
import api, {CameraHasObject, ObjectType, Server} from '../../../../../../services/api'
import handleErrorWithSnackbar from '../../../../../../utilities/handleErrorWithSnackbar'
import {MappedActions, ObjectDetectionProps} from '../../ObjectDetection'
import {StepperModalContainer} from '../styles'
import {detectionDisableLineCrossing, detectionTypeToSearchObjectType, skipFourthStep, skipSecondStep, skipThirdStep} from './components/Constants'
import WarningModal from './components/WarningModal'
import ChooseBarrierStep from './components/choose-barrier-step/ChooseBarrierStep'
import ConfigStep from './components/config-step/ConfigStep'
import CreateMultipleActions from './components/create-multiple-actions/CreateMultipleActions'
import {MultipleActionsType} from './components/create-multiple-actions/types/CreateMultipleActionsTypes'
import DetectionTypeStep from './components/detection-type-step/DetectionTypeStep'

type StepperModalProps = {
  closeModal: (isOpen: boolean) => void
  registeredObjects: ObjectDetectionProps[]
  allObjects: ObjectType[]
  isEdit: boolean
  editingObject?: CameraHasObject | LineCrossingObject
  mappedActions: MappedActions[]
  brainBox: BrainBox[]
  servers: Server[]
  setWarningModalIsOpen: (isOpen: boolean) => void
  updateTable: () => void
}

export type ChosenObject = {
  detectionTypeChosen?: string
  confidence?: number
  interestAreaId?: number
  lineCrossingId?: number
  isActive: boolean
  objectTypeId?: number
  trigger?: CreateTriggerDTO[]
  threshold: number
  isObjectCounting?: boolean
}

export function objectIsInterestArea(object: CameraHasObject | LineCrossingObject): object is CameraHasObject {
  return 'interestArea' in object
}

const LPRIntegrations = ['Placa', 'Leitura de Placas']

const StepperModal = (props: StepperModalProps) => {
  const {closeModal, registeredObjects, allObjects, isEdit, editingObject, mappedActions, brainBox, servers, setWarningModalIsOpen, updateTable} = props
  const {diffConfig, camera} = useCameraConfigContext()
  const {enqueueSnackbar} = useSnackbar()
  const [brainBoxItems, setBrainBoxItems] = useState<DropdownItem[]>([])
  const [outputServersItems, setOutputServersItems] = useState<DropdownItem[]>([])
  const [actions, setActions] = useState<MultipleActionsType[]>([])
  const [selectedListId, setSelectedListId] = useState<number>(0)
  const hasReadBrainBoxPermission = ValidatePermissions('brainbox:read')
  const hasCreateTelegramPermission = ValidatePermissions('telegram:create')
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const hasReadLineCrossingPermission = ValidatePermissions('line-crossing:read')
  const setDiffOnChosenObject = useCallback((newChosen: Partial<ChosenObject>) => {
    setObjectChosenToCreate((chosen) => {
      if (chosen !== null) return {...chosen, ...newChosen}
      return null
    })
  }, [])

  const {companies} = useCompany()

  const nextFunction = (index: number, resume: string, advanceTo?: number) => {
    const newSteps = [...steps]
    newSteps[index - 1].resume = resume
    newSteps[index - 1].stepperStatus = 'concluded'
    if (advanceTo) newSteps[advanceTo].stepperStatus = 'active'
    else newSteps[index].stepperStatus = 'active'
    setSteps(newSteps)
  }

  const returnFunction = (index: number, goBackTo?: number) => {
    const newSteps = [...steps]
    delete newSteps[index + 1].resume
    newSteps[index + 1].stepperStatus = 'pending'
    if (goBackTo || goBackTo === 0) newSteps[goBackTo].stepperStatus = 'active'
    else newSteps[index].stepperStatus = 'active'
    setSteps(newSteps)
  }

  const finishFunction = async () => {
    try {
      const toCreate = {...objectChosenToCreate}
      if (toCreate) {
        delete toCreate!.detectionTypeChosen
        delete toCreate!.trigger
        if (toCreate.interestAreaId) {
          if (isEdit) {
            const objectResponse = await api.object.updateInterestAreaObjects(toCreate)
            enqueueSnackbar('Objeto salvo com sucesso', {
              variant: 'success',
            })
            closeModal(false)
            return objectResponse.data.data
          } else {
            const objectResponse = await api.object.createInterestAreaObjects(toCreate)
            enqueueSnackbar('Objeto salvo com sucesso', {
              variant: 'success',
            })
            closeModal(false)
            return objectResponse.data.data
          }
        } else {
          if (isEdit) {
            const lineCrossingObject = await api.object.updateLineCrossingObject(toCreate)
            enqueueSnackbar('Objeto salvo com sucesso', {
              variant: 'success',
            })
            closeModal(false)
            return lineCrossingObject.data.data
          } else {
            const lineCrossingObject = await api.object.createLineCrossingObject(toCreate)
            enqueueSnackbar('Objeto salvo com sucesso', {
              variant: 'success',
            })
            updateCamera(diffConfig!, camera!, {latitude: '-', longitude: '-'})
            closeModal(false)
            return lineCrossingObject.data.data
          }
        }
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar o objeto')
    } finally {
      updateTable()
    }
  }

  const findObjectType = async () => {
    const getAll = await api.object.getAllObjectTypes()
    let detection = objectChosenToCreate?.detectionTypeChosen
    if (detection === 'Reconhecimento Facial') detection = 'Facial'
    if (detection === 'Leitura de Placas') detection = 'Placa'

    const id = getAll.data.data.filter((it) => it.label === detection)
    if (id.length)
      setDiffOnChosenObject({
        objectTypeId: id[0].id,
      })
  }

  const [objectChosenToCreate, setObjectChosenToCreate] = useState<ChosenObject | null>(null)

  useEffect(() => {
    const items: DropdownItem[] = brainBox.map((it) => {
      return {
        id: String(it.id),
        label: it.name,
      }
    })
    setBrainBoxItems(items)
  }, [brainBox])

  const populateOutput = useCallback(
    (detection: string) => {
      const items: DropdownItem[] = servers.map((it) => {
        return {
          id: String(it.id),
          label: it.serverType?.description ?? it.name,
        }
      })
      const cameraCompany = companies.find((company) => company.id === camera?.companyId!)
      const cameraCompanyPermissions = cameraCompany && cameraCompany.permissions
      const companyHasTelegramPermission = cameraCompanyPermissions?.find((permission) => permission.codename === 'telegram:create')
      const companyHasLPRIntegrationPermission = cameraCompanyPermissions?.find((permission) => permission.codename === 'lpr-integration:create')
      if (hasReadBrainBoxPermission && brainBox.length) items.unshift({id: '-1', label: 'Brain Box'})
      if (!!companyHasTelegramPermission && hasCreateTelegramPermission) items.unshift({id: '-2', label: 'Telegram'})
      if (LPRIntegrations.includes(detection)) {
        if (!!companyHasLPRIntegrationPermission) items.unshift({id: '-3', label: 'Spia'}, {id: '-4', label: 'Harpia'})
      }
      setOutputServersItems(items.filter((value, index, array) => array.indexOf(value) === index))
    },
    [servers],
  )

  function populateActions(
    filteredActions: MappedActions,
    outputDevices: BrainBoxOutput[],
    brainBox: BrainBox[],
    servers: Server[],
    objectChosenToCreate: ChosenObject,
  ) {
    const actions: MultipleActionsType[] = []
    filteredActions.objectAction.map((action) => {
      if (action.boxOutputDeviceId) {
        const brainboxEntity = outputDevices.filter((it) => it.id === action.boxOutputDeviceId)[0]
        const actionCard: MultipleActionsType = {
          outputId: action.boxOutputDeviceId!,
          label: 'Brain Box',
          valueInput: brainboxEntity?.description || '',
          inputLabel: 'Canal',
          brainBox: brainBox.filter((it) => it.id === brainboxEntity.brainBoxId)[0]?.name || '',
          idToRemove: action.idToDelete,
          listId: action.listId || undefined,
          triggerId: action.triggerId,
        }
        return actions.push(actionCard)
      }
      if (action.outputIntegrationId) {
        const serverEntity = servers.filter((it) => it.id === action.outputIntegrationId)[0]
        const serverType = serverEntity?.serverType?.description || ''
        const actionCard: MultipleActionsType = {
          outputId: action.outputIntegrationId!,
          label: serverType,
          inputLabel: serverType === 'One Portaria' ? 'Setor' : 'Partição',
          valueInput: camera?.configuration.partition || '',
          idToRemove: action.idToDelete,
          listId: action.listId || undefined,
        }
        return actions.push(actionCard)
      }
      if (action.telegramChatId) {
        const actionCard: MultipleActionsType = {
          outputId: +action.telegramChatId,
          label: 'Telegram',
          inputLabel: 'Id do chat',
          valueInput: `${action.telegramChatId}`,
          idToRemove: action.idToDelete,
          listId: action.listId || undefined,
        }
        return actions.push(actionCard)
      }
      if (action.lprIntegrationId) {
        const actionCard: MultipleActionsType = {
          outputId: action.lprIntegrationId === 4 ? -3 : -4,
          label: action.lprIntegrationId === 4 ? 'Spia' : 'Harpia',
          inputLabel: '',
          valueInput: `${action.lprIntegrationId}`,
          idToRemove: action.idToDelete,
          listId: action.listId || -2,
        }
        return actions.push(actionCard)
      }
      return ''
    })
    setActions(actions)
    if (objectIsInterestArea(editingObject!)) {
      objectChosenToCreate.interestAreaId = editingObject.interestArea?.id
    } else {
      objectChosenToCreate.lineCrossingId = editingObject?.lineCrossing?.id
    }

    setObjectChosenToCreate(objectChosenToCreate)
    nextFunction(1, objectChosenToCreate.detectionTypeChosen!)
    nextFunction(2, objectChosenToCreate.interestAreaId ? 'Detecção em uma área' : 'Cruzamento de linha')
    if (skipThirdStep.includes(objectChosenToCreate.detectionTypeChosen!)) {
      nextFunction(3, 'Configurações')
      const newSteps = [...steps]
      newSteps[2].stepperStatus = 'concluded'
      setSteps(newSteps)
    }
  }

  useEffect(() => {
    if (!objectChosenToCreate) return
    const detection = objectChosenToCreate.detectionTypeChosen!
    populateOutput(detection)
  }, [objectChosenToCreate])

  useEffect(() => {
    if (isEdit && editingObject) {
      let triggers: CreateTriggerDTO[] = []
      if (editingObject.trigger) {
        editingObject.trigger.forEach((trigger) => {
          triggers.push({
            outputDeviceId: trigger.boxOutputDeviceId,
            triggerTime: trigger.triggerTime,
            objectId: editingObject.id,
          })
        })
        setSelectedListId(
          mappedActions
            .filter((it) => it.id === editingObject.id)[0]
            .objectAction.map((it) => {
              return it.listId || 0
            })[0],
        )
      }

      const objectChosenToCreate: ChosenObject = {
        id: editingObject.id,
        detectionTypeChosen: editingObject.objectType.label,
        confidence: +editingObject.confidence,
        isActive: editingObject.isActive,
        objectTypeId: editingObject.objectType.id,
        threshold: editingObject.threshold,
        trigger: triggers,
      } as ChosenObject

      const outputDevices: BrainBoxOutput[] = brainBox.map((it) => it.outputDevices).flat()

      const filteredActions: MappedActions | null = mappedActions.filter((it) => it.id === editingObject.id)[0]

      if (filteredActions) return populateActions(filteredActions, outputDevices, brainBox, servers, objectChosenToCreate)

      if (objectIsInterestArea(editingObject)) {
        objectChosenToCreate.interestAreaId = editingObject.interestArea?.id
      } else {
        objectChosenToCreate.lineCrossingId = editingObject.lineCrossing?.id
      }

      setObjectChosenToCreate(objectChosenToCreate)
      nextFunction(1, objectChosenToCreate.detectionTypeChosen!)
      nextFunction(2, objectChosenToCreate.interestAreaId ? 'Detecção em uma área' : 'Cruzamento de linha')
      if (skipThirdStep.includes(objectChosenToCreate.detectionTypeChosen!)) {
        nextFunction(3, 'Configurações')
        const newSteps = [...steps]
        newSteps[2].stepperStatus = 'concluded'
        setSteps(newSteps)
      }
    } else {
      setObjectChosenToCreate({
        detectionTypeChosen: '',
        confidence: 0.4,
        isActive: true,
        objectTypeId: 0,
        threshold: 1,
      })
    }
  }, [isEdit, editingObject])

  const handleDifferentFlows = () => {
    const shouldSkip = (newSteps: StepsTitles[], skipArray: string[], detection: string) => {
      if (skipArray.includes(detection)) {
        return true
      }
      return false
    }
    const newSteps = [...steps]
    const detection = objectChosenToCreate?.detectionTypeChosen

    newSteps[1].stepperStatus = shouldSkip(newSteps, skipSecondStep, detection!) ? 'disabled' : 'pending'
    newSteps[2].stepperStatus = shouldSkip(newSteps, skipThirdStep, detection!) ? 'disabled' : 'pending'
    newSteps[3].stepperStatus = shouldSkip(newSteps, skipFourthStep, detection!) ? 'disabled' : 'pending'

    if (isEdit) {
      if (newSteps[0].stepperStatus === 'pending') newSteps[0].stepperStatus = 'concluded'
      if (newSteps[1].stepperStatus === 'pending') newSteps[1].stepperStatus = 'concluded'
      if (shouldSkip(newSteps, skipThirdStep, detection!)) {
        newSteps[2].stepperStatus = 'concluded'
        newSteps[3].stepperStatus = 'active'
      } else {
        newSteps[2].stepperStatus = 'active'
      }
    }
    setSteps(newSteps)
  }

  useEffect(() => {
    const newSteps = [...steps]
    newSteps.map((it, index) => (it.children = ChildrenComponentEnum[index]))
    setSteps(newSteps)
  }, [objectChosenToCreate])

  useEffect(() => {
    handleDifferentFlows()
    if (detectionTypeToSearchObjectType.includes(objectChosenToCreate?.detectionTypeChosen!)) findObjectType()
  }, [objectChosenToCreate?.detectionTypeChosen])

  type ChildrenComponentEnumType = {
    [key: number]: JSX.Element
  }

  const ChildrenComponentEnum: ChildrenComponentEnumType = useMemo(() => {
    return {
      0: (
        <DetectionTypeStep
          nextFunction={nextFunction}
          returnFunction={closeModal}
          setChosen={setDiffOnChosenObject}
          selected={objectChosenToCreate?.detectionTypeChosen}
        />
      ),
      1: (
        <ChooseBarrierStep
          nextFunction={nextFunction}
          returnFunction={returnFunction}
          setChosen={setDiffOnChosenObject}
          chosen={objectChosenToCreate}
          registeredObjects={registeredObjects}
          detectionDisableLineCrossing={detectionDisableLineCrossing.includes(objectChosenToCreate?.detectionTypeChosen!)}
          hasLineCrossingPermission={hasReadLineCrossingPermission}
        />
      ),
      2: (
        <ConfigStep
          nextFunction={nextFunction}
          returnFunction={returnFunction}
          updateTable={updateTable}
          chosen={objectChosenToCreate}
          registeredObjects={registeredObjects}
          setChosen={setDiffOnChosenObject}
          finishFunction={finishFunction}
          allObjects={allObjects}
          isEdit={isEdit}
        />
      ),
      3: (
        <CreateMultipleActions
          finishFunction={finishFunction}
          returnFunction={returnFunction}
          setChosen={setDiffOnChosenObject}
          chosen={objectChosenToCreate}
          brainBox={brainBox}
          isEdit={isEdit}
          brainBoxItems={brainBoxItems}
          outputServersItems={outputServersItems}
          setOutputServersItems={setOutputServersItems}
          actions={actions}
          listSelectedId={selectedListId}
          openWarningModal={() => setWarningModalIsOpen(true)}
          updateTable={updateTable}
        />
      ),
    }
  }, [objectChosenToCreate, registeredObjects, allObjects, brainBox, outputServersItems, actions, selectedListId])

  const [steps, setSteps] = useState<StepsTitles[]>([
    {
      stepperStageTitle: 'Passo 01',
      stepperTitle: 'Tipo de Detecção',
      stepperStatus: 'active',
      children: ChildrenComponentEnum[0],
    },
    {
      stepperStageTitle: 'Passo 02',
      stepperTitle: 'Tipo de Barreira',
      stepperStatus: 'pending',
      children: ChildrenComponentEnum[1],
    },
    {
      stepperStageTitle: 'Passo 03',
      stepperTitle: 'Configurações',
      stepperStatus: 'pending',
      children: ChildrenComponentEnum[2],
    },
    {
      stepperStageTitle: 'Passo 04',
      stepperTitle: 'Tipo de Ação',
      stepperStatus: 'pending',
      children: ChildrenComponentEnum[3],
    },
  ])

  useEffect(() => {
    setSteps((steps) => {
      return steps.map((step, index) => {
        return {
          ...step,
          children: ChildrenComponentEnum[index],
        }
      })
    })
  }, [ChildrenComponentEnum])

  return (
    <StepperModalContainer>
      <WarningModal isOpen={warningModalOpen} closeModal={() => setWarningModalOpen(false)} />
      <BtnClose onClose={() => closeModal(false)} />
      {objectChosenToCreate && <Stepper steps={steps} />}
    </StepperModalContainer>
  )
}

export default StepperModal
