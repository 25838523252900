import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import {Box} from '@mui/material'
import {useEffect, useRef, useState} from 'react'
import {Image, Layer, Stage} from 'react-konva'
import useImage from 'use-image'
import {Dim, Pos} from '../common/types/generics/ImageDetections'
import {computeScalar, dim2pos, pos2dim, posDefault, posScale} from '../common/utils/Internal'
import Loading from './Loading'

type Crop = Dim & Pos

type LprImageProps = {
  src: string
  scale?: number
  width?: number
  height?: number
  crop?: Crop
  isFullImage?: boolean
}
function ImageScaled({src, crop, scale = 1, width, height, isFullImage}: LprImageProps) {
  const [_dimImage, setDimImage] = useState<Dim>({width: 0, height: 0})
  const [imageHTML, status] = useImage(src)
  const imageNode = useRef<any>()
  const internalScale = computeScalar(scale, _dimImage, {width, height})
  const posImage: Pos = dim2pos(_dimImage)
  const dimScaled: Dim = pos2dim(posDefault(posScale(posImage, internalScale), posImage))
  useEffect(() => {
    imageHTML?.decode().then(() => {
      setDimImage({width: imageHTML.width, height: imageHTML.height})
    })
  }, [imageHTML])
  if (status === 'loading') {
    return (
      <Box sx={{marginTop: '7.5em'}}>
        <Loading />
      </Box>
    )
  }

  if (status === 'failed') {
    if (isFullImage) return <Box sx={{marginTop: '7.5em'}}>Erro ao carregar imagem</Box>
    else
      return (
        <Box>
          <ImageNotSupportedIcon fontSize='large' />
        </Box>
      )
  }

  if (status === 'loaded') {
    if (crop && imageNode.current) imageNode.current.crop(crop)

    return (
      <Stage {...dimScaled}>
        <Layer>
          <Image
            {...dimScaled}
            image={imageHTML}
            ref={(node) => {
              imageNode.current = node
            }}
          />
        </Layer>
      </Stage>
    )
  }

  return <Box></Box>
}

export default ImageScaled
