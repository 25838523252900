import {Grid} from '@mui/material'

import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'

import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import api from 'src/services/api'

import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

import createStyleSheet from 'src/utilities/createStyleSheet'

import {useCompany} from 'src/contexts/CompanyContext'
import {EventSerieTypes} from 'src/services/api/endpoints/ReportsEndpoint'

export type ChartEventFilterDate = {
  start: string
  end: string
}

export type SeriesTime = {
  name?: string
  data: SeriesCharts[]
}

type SeriesCharts = {
  name: string
  data: []
}

type Data = {
  count: number
  year: number
  month: number
  week: number
  day: number
  hour: number
  minute: number
}

type DashboardTimeEventsProps = {
  filterDate: ChartEventFilterDate
}

function DashboardTimeEvents({filterDate}: DashboardTimeEventsProps) {
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanyId} = useCompany()

  const [seriesData, setSeriesData] = useState<SeriesCharts[]>([])

  const [typeChartGroup, setTypeChartGroup] = useState<ApexXAxis>({
    type: undefined,
    labels: {
      format: '',
    },
  })
  const blockEventsSeriesRequests = useRef(false)

  const yesterday = moment(filterDate.start).subtract(1, 'days').format('YYYY-MM-DD')

  async function mapTypeDates(element: Data[], aggregation: string): Promise<any> {
    const data = await Promise.all(
      element.map((it) => {
        switch (aggregation) {
          case 'minute':
            setTypeChartGroup({labels: {format: 'HH:mm'}})
            return {x: `${it.hour <= 9 ? '0' + it.hour : it.hour}:${it.minute <= 9 ? '0' + it.minute : it.minute}`, y: it.count}
          case 'hour':
            setTypeChartGroup({labels: {format: 'HH'}})
            return {x: `${it.hour <= 9 ? '0' + it.hour : it.hour}h`, y: it.count}
          case 'day':
            setTypeChartGroup({labels: {format: 'dd/MM/yyyy'}})
            return {x: `${it.day <= 9 ? '0' + it.day : it.day}/${it.month <= 9 ? '0' + it.month : it.month}/${it.year}`, y: it.count}
          case 'week':
            setTypeChartGroup({type: 'numeric'})
            return {x: `${it.week}`, y: it.count}
          case 'month':
            setTypeChartGroup({labels: {format: 'MM'}})
            return {x: `${it.month <= 9 ? '0' + it.month : it.month}/${it.year}`, y: it.count}
        }
        setTypeChartGroup({labels: {format: 'yyyy'}})
        return {x: ` ${it.year}`, y: it.count}
      }),
    )
    return data
  }

  async function loadEventChart() {
    if (!selectedCompanyId) return
    if (blockEventsSeriesRequests.current) return
    blockEventsSeriesRequests.current = true
    setSeriesData([])
    try {
      const response1 = await api.reports.eventCharts({
        filter: {
          status: ['TODOS'],
          companyIds: [selectedCompanyId],
        },
        start: moment(filterDate.start!).format('YYYY-MM-DDTHH:mm'),
        end: moment(filterDate.end!).format('YYYY-MM-DDTHH:mm'),
      })

      const response2 = await api.reports.eventCharts({
        filter: {
          status: ['TODOS'],
          companyIds: [selectedCompanyId],
        },
        start: moment(filterDate.start!).format('YYYY-MM-DDTHH:mm'),
        end: moment(filterDate.end!).format('YYYY-MM-DDTHH:mm'),
      })

      response1.data.data.entities.forEach(async (element) => {
        if (!Array.isArray(element.series)) {
          const series = element.series as EventSerieTypes
          const chatX: SeriesCharts[] = await Promise.all(
            Object.keys(series).map(async (key) => {
              return {
                name: filterDate.start !== moment().format('YYYY-MM-DD') ? filterDate.start : yesterday,
                data: await mapTypeDates(series[key as keyof EventSerieTypes], element.aggregation),
              } as SeriesCharts
            }),
          )
          setSeriesData((prev) => [...prev, chatX[0]])
        }
      })

      response2.data.data.entities.forEach(async (element) => {
        if (!Array.isArray(element.series)) {
          const series = element.series as EventSerieTypes
          const chatX: SeriesCharts[] = await Promise.all(
            Object.keys(series).map(async (key) => {
              return {
                name: filterDate.end,
                data: await mapTypeDates(series[key as keyof EventSerieTypes], element.aggregation),
              } as SeriesCharts
            }),
          )
          setSeriesData((prev) => [...prev, chatX[0]])
        }
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar gráficos')
    } finally {
      blockEventsSeriesRequests.current = false
    }
  }

  useEffect(() => {
    setSeriesData([])
    loadEventChart()
  }, [filterDate.start, selectedCompanyId])

  const opts: ApexCharts.ApexOptions = {
    series: seriesData,
    chart: {
      height: '10%',
      type: 'line',
      zoom: {
        enabled: false,
      },
    },

    dataLabels: {
      enabled: true,
    },
    colors: ['#41EDC5', '#8F65DF'],
    xaxis: typeChartGroup,
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    title: {
      text: 'Eventos por Hora',
      margin: 41,
      align: 'left',
      offsetX: -7,
      offsetY: -10,
      style: {
        fontSize: '18px',
        fontWeight: 550,
        fontFamily: 'Inter',
        color: '#1D1E28',
      },
    },
    subtitle: {
      text: 'Quantidade de Eventos',
      align: 'left',
      offsetX: 6,
      offsetY: 65,
      style: {
        fontSize: '14px',
        fontWeight: 'none',
        fontFamily: 'Inter',
        color: '#8E8E8E',
      },
    },
    grid: {
      show: true,
    },
    markers: {
      size: 1,
    },

    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: false,
      offsetY: -101,
      offsetX: 1300,
      formatter: function (seriesName) {
        return moment().format('DD/MM/YYYY') === moment(seriesName).format('DD/MM/YYYY') ? 'Hoje' : moment(seriesName).format('DD/MM/YYYY')
      },
      fontFamily: 'Inter',
    },
    noData: {
      text: 'Nenhum evento foi encontrado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '14px',
        fontFamily: 'Helvetica',
      },
    },
    responsive: [
      {
        breakpoint: 1920,
        options: {
          legend: {
            offsetX: 500,
            offsetY: -100,
          },
          chart: {
            width: '100%',
            height: 380,
          },
        },
      },
      {
        breakpoint: 1560,
        options: {
          legend: {
            offsetX: 890,
          },
          chart: {
            width: '100%',
            height: 380,
          },
        },
      },
    ],
  }

  return (
    <Grid container sx={styles.grid} height={'399px'}>
      <Grid item xs={12}>
        <ReactApexChart options={opts} series={opts.series} type='line' height={370} />
      </Grid>
    </Grid>
  )
}

const styles = createStyleSheet({
  grid: {
    padding: 2,
    paddingLeft: 3.5,
    borderRadius: '8px',
    background: 'white',
  },
  title: {
    margin: '0px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '1.05em',
    lineHeight: '2',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'left',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'left',
  },
  FilterRangeTimeButton: {
    width: '100px',
    height: '38.5px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginTop: '9.5px',
  },
})

export default DashboardTimeEvents
