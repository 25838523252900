import styled from 'styled-components'

export const CotainerPaymentExpiration = styled.div`
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  @media (max-width: 767px) {
    padding: 30px;
    min-width: 455px;
  }
  @media (max-width: 427px) {
    min-width: 355px;
  }
`
export const ContainerButtonsExpirations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 10px;
  }
`

export const ImageExpirationModal = styled.img`
  width: 350px;

  @media (max-width: 767px) {
    width: 280px;
  }
`

export const TextContainerExpiration = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 550px;
  margin-top: 40px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
`
