/* eslint-disable array-callback-return */
import {Box, Button, Checkbox, Input, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {TitleAlt} from 'src/pages/self-register/select-plan/components/PlanSelectBase'
import api from '../../../services/api/api'
import {CameraHasObject, ObjectIncludesType, PutActions} from '../../../services/api/endpoints/ObjectEndpoint'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'
import {ContainerPageSelfRegister, WrapperContent} from '../camera-rtmp-configuration/CameraRTMPConfigurationBase'
import Stepper from '../components/Stepper/Stepper'
import {WrapperContentArea} from '../camera-rtmp-area/CameraRtmpAreaBase'
import {useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import YoutubeEmbed from '../components/youtube-embed/YoutubeEmbed'
import { AxiosResult } from 'src/services/api/endpoints/_types'

type CameraRtmpActionsProps = {
  areaIdCreated: number[]
  cameraConfigId: number
  cameraId: number
}

type DetectionOptions = 'person' | 'car' | 'motorbike'

type Detections = {
  [key in DetectionOptions]: boolean
}

function CameraRtmpActions(props: CameraRtmpActionsProps) {
  const {areaIdCreated, cameraConfigId, cameraId} = props
  const {enqueueSnackbar} = useSnackbar()
  const {cameraCreatedPrevious} = useB2CContext()

  const [detections, setDetections] = useState<Detections>({
    person: false,
    car: false,
    motorbike: false,
  })

  const [telegram, setTelegram] = useState(false)
  const [telegramChatId, setTelegramChatId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [cameraName, setCameraName] = useState(cameraCreatedPrevious?.name ?? '')
  const [registeredObjects, setRegisteredObjects] = useState<CameraHasObject[]>([])
  const blockDeleteRequests = useRef(false)

  useEffect(() => {
    async function getObjects() {
      try {
        if (blockDeleteRequests.current) return
        blockDeleteRequests.current = true
        const includes: ObjectIncludesType[] = ['interestArea', 'objectType', 'actions']
        const response = await api.object.getInterestAreaObjects({configurationId: cameraConfigId, includes})
        const objects = response.data.data
        const objectNames = objects.map((it) => it.objectType.name)
        const chatId = objects.find((it) => it.actions.filter((it) => it.telegramChatId).length)?.actions.find((it) => it.telegramChatId)?.telegramChatId
        if (chatId) {
          setTelegram(true)
          setTelegramChatId(`${chatId}`)
        }
          setRegisteredObjects(objects)
        setDetections((prev) => {
          return {
            ...prev,
            person: objectNames.includes('person'),
            car: objectNames.includes('car'),
            motorbike: objectNames.includes('motorbike'),
          }
        })
      } catch (error) {
        return
      } finally {
        blockDeleteRequests.current = false
      }
    }
    getObjects()
  }, [cameraConfigId])

  const handleCreateObjectAndAction = async () => {
    try {
      setIsLoading(true)

      if (cameraName) await api.camera.updateCamera(cameraId, {name: cameraName})
      const getAllTypes = await api.object.getAllObjectTypes()
      const options: DetectionOptions[] = ['car', 'person', 'motorbike']
      const objectsChoosen = options.flatMap((it) => {
        if (detections[it]) return getAllTypes.data.data.filter((obj) => obj.name === it)
      })

      const createObjectsDTO = objectsChoosen.map((obj) => {
        if (!obj) return
        if (registeredObjects.find((it) => it.objectType.name === obj.name)) return
        return {
          confidence: 0.4,
          isActive: true,
          threshold: 1,
          interestAreaId: areaIdCreated[0],
          objectTypeId: obj.id,
        }
      })

      const removedIds: Array<number> = []

      Object.entries(detections).map(async ([key, value]) => {
        if (value) return
        const objects = registeredObjects.filter((it) => it.objectType.name === key)
        if (!objects.length) return
        const deleteObjectsPromise = objects.map(async (it) => {
          if (!it) return
          removedIds.push(it.id)
          return await api.object.removeInterestAreaObject(it.id)
        })
        await Promise.all(deleteObjectsPromise)
      })

      const responseObject = createObjectsDTO.map(async (it) => {
        if (!it) return
        return await api.object.createInterestAreaObjects(it)
      })

      const resolvedResponses = await (await Promise.all(responseObject)).filter((it) => !!it)
      const resolvedResponsesIds = resolvedResponses.map((it) => it?.data.data.id)

      const objectsWithTelegramIds: number[] = []
      const objectsWithoutTelegramIds = [...resolvedResponsesIds]
      
      registeredObjects.map((it) => {
        if (!it) return
        if (removedIds.includes(it.id)) return
        if (!!it.actions.filter((it) => it.telegramChatId).length) return objectsWithTelegramIds.push(it.id)
        objectsWithoutTelegramIds.push(it.id)
      })

      let promises: Promise<AxiosResult<void> | undefined>[] = []

      if (telegram) {
        const telegramAction: PutActions = {type: 'telegram', outputActionId: +telegramChatId}

        promises.push(...objectsWithoutTelegramIds.map(async (it) => {
          if (!it) return
          return await api.object.putActions(it, [telegramAction])
        }))
      } else if (objectsWithTelegramIds.length) {
        promises.push(...objectsWithTelegramIds.map(async (it) => {
          if (!it) return
          const id = registeredObjects.find((itt) => itt.id === it)?.actions.find((ittt) => ittt.telegramChatId)?.id
          if (!id) return
          return await api.object.deleteAction(id)
        }))
      }

      await Promise.allSettled(promises)

      window.location.href = '/status?type=success'
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao finalizar configuração')
    } finally {
      setIsLoading(false)
    }
  }

  const handleDisabledButton = () => {
    if (detections.car || detections.motorbike || detections.person) return false
    return true
  }

  return (
    <ContainerPageSelfRegister>
      <Box display='flex' direction='column' align='center' justifyContent='center'>
        <Stepper activeStep={2} stepTitles={['Dados da câmera', 'Personalizar área', 'Finalizar']} />

        <WrapperContentArea>
          <TitleAlt>
            Chegamos a última etapa, agora pra finalizar <br /> preencha as informações sobre a detecção configurada
          </TitleAlt>
          <WrapperContent>
            {telegram && <YoutubeEmbed embedId='SVfiwRCNST4' />}
            <Box display='flex' direction='column' align='flex-start' justifyContent='space-between' rowGap='25px' height='100%' minHeight='250px'>
              <Input.Root
                labelFontWeight='400'
                placeholder={cameraCreatedPrevious?.name ?? ''}
                label='Descrição da câmera'
                value={cameraName}
                controlled
                onChange={(e) => setCameraName(e.target.value)}
              />

              <Box display='flex' direction='column' align='flex-start' justifyContent='flex-start' rowGap='0px'>
                <Typography variant='span' size='14px' color='#344054'>
                  Selecione abaixo o que você deseja detectar
                </Typography>

                <Box display='flex' columnGap='30px'>
                  <Checkbox
                    labelSize='14px'
                    color='#344054'
                    label='Pessoa'
                    checked={detections.person}
                    onChange={() =>
                      setDetections((prev) => {
                        return {
                          ...prev,
                          person: !prev.person,
                        }
                      })
                    }
                  />
                  <Checkbox
                    labelSize='14px'
                    color='#344054'
                    label='Carro'
                    checked={detections.car}
                    onChange={() =>
                      setDetections((prev) => {
                        return {
                          ...prev,
                          car: !prev.car,
                        }
                      })
                    }
                  />
                  <Checkbox
                    labelSize='14px'
                    color='#344054'
                    label='Moto'
                    checked={detections.motorbike}
                    onChange={() =>
                      setDetections((prev) => {
                        return {
                          ...prev,
                          motorbike: !prev.motorbike,
                        }
                      })
                    }
                  />
                </Box>
              </Box>

              <Box display='flex' direction='column' align='flex-start' justifyContent='flex-start' minWidth='310px' rowGap='0px'>
                <Typography variant='span' size='14px' color='#344054'>
                  Selecione abaixo qual a forma de notificação
                </Typography>

                <Box display='flex' columnGap='30px'>
                  <Checkbox labelSize='14px' label='Portal Brain' checked={true} />
                  <Checkbox labelSize='14px' label='App Brain' checked={true} />
                  <Checkbox labelSize='14px' label='Telegram' checked={telegram} onChange={() => setTelegram((prev) => !prev)} />
                </Box>
                {telegram && (
                  <Box marginTop='12px' width='100%'>
                    <Input.Root
                      labelFontWeight='400'
                      controlled
                      label={'Informe o código do telegram'}
                      value={telegramChatId}
                      onChange={(e) => setTelegramChatId(e.target.value)}
                      mask={(value) => {
                        if (value.length <= 19 && value.match(/^-?([0-9]+)?$/)) return value
                        return telegramChatId
                      }}
                    />
                  </Box>
                )}
              </Box>

              <Box display='flex' align='center' justifyContent='center' width='310px'>
                <Button
                  variant='contained'
                  themes='primary'
                  fontSize='14px'
                  width='150px'
                  isLoading={isLoading}
                  disabledBgColor='#9E9E9E'
                  onClick={() => handleCreateObjectAndAction()}
                  disabled={handleDisabledButton()}>
                  Finalizar
                </Button>
              </Box>
            </Box>
          </WrapperContent>
        </WrapperContentArea>
      </Box>
    </ContainerPageSelfRegister>
  )
}

export default CameraRtmpActions
