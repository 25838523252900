import {AxiosError} from 'axios'
import {jwtDecode} from 'jwt-decode'
import React, {createContext, useContext, useRef, useState} from 'react'
import api, {User, axios} from 'src/services/api'
import storage from 'src/services/storage'
import {getDecodedToken} from 'src/utilities/getDecodedToken'

type AuthData = {
  isAuth: boolean
  user: User
  noPasswordAllowed: boolean
  login: (username: string, password: string, keepLoggedIn: boolean) => Promise<void>
  logout: () => Promise<void>
  userToken: UserTokenPayload
  refreshToken: () => Promise<any>
  authFromUrlToken: (token: string) => Promise<boolean>
  checksToken: () => Promise<void>
  userB2cHasCompanyExpired: boolean
  setUserB2cHasCompanyExpired: (areExpired: boolean) => void
  userIsB2c: boolean
  checkDueDateInB2cUserCompany: () => void
}

export type UserToken = {
  payload: UserTokenPayload
}

export type UserTokenPayload = {
  name: string
  email: string
  id: number
  userPermissions: string[]
  authorizedCompanies: number[]
  authorizedClients: number[]
}

export const AuthContext = createContext({} as AuthData)

export function AuthProvider({children}: React.PropsWithChildren<any>) {
  const [isAuth, setIsAuth] = useState(false)
  const [user, setUser] = useState<User>({} as User)
  const [userToken, setUserToken] = useState<UserTokenPayload>({} as UserTokenPayload)
  const [noPasswordAllowed, setNoPasswordAllowed] = useState<boolean>(false)
  const [userB2cHasCompanyExpired, setUserB2cHasCompanyExpired] = useState(false)
  const [userIsB2c, setUserIsB2c] = useState(false)

  const blockRequests = useRef(false)

  function unlockAndLogout() {
    blockRequests.current = false
    logout()
  }

  function checkIfUserIsB2c() {
    const decodedToken = getDecodedToken()
    const authorizedCompanies = decodedToken.payload.authorizedCompanies
    const hasSelfRegistrationPermission = decodedToken.payload.userPermissions.includes('self-registration:create')
    const userIsB2c = hasSelfRegistrationPermission && authorizedCompanies.length <= 1
    setUserIsB2c(userIsB2c)
  }

  async function checkDueDateInB2cUserCompany() {
    const decodedToken = getDecodedToken()
    const authorizedCompanies = decodedToken.payload.authorizedCompanies

    const companies = await api.company.getMany({filter: {ids: [authorizedCompanies[0]]}})
    const company = companies.data.data.entities[0]
    if (!company.expirationDate) return
    const today = new Date()
    const companyExpired = new Date(company.expirationDate) < today
    if (userIsB2c) setUserB2cHasCompanyExpired(companyExpired)
  }

  axios.interceptors.response.use(
    (response) => response,
    async (error: any) => {
      try {
        if (error.response.config.url.includes('/refresh')) unlockAndLogout()
        if (error.response.status !== 401) throw error
        if (blockRequests.current) return
        blockRequests.current = true
        if (error.config.url.includes('/login') || error.config.url.includes('user/password')) throw error

        await refreshToken()
      } catch (error) {
        throw error
      } finally {
        blockRequests.current = false
      }
    },
    undefined,
  )

  async function refreshToken() {
    try {
      const tokenLocal = storage.get2('refreshToken', 'local')
      if (!tokenLocal) return logout()
      const keepLoggedIn = storage.get('keepLoggedIn') === 'true'

      const refreshResponse = await api.auth.refresh(keepLoggedIn)
      if (!refreshResponse) return logout()
      const userToken = jwtDecode(refreshResponse.data.data.accessToken) as UserToken
      storage.set('token', refreshResponse.data.data.accessToken, 'local')
      storage.set('refreshToken', refreshResponse.data.data.refreshToken, 'local')
      api.setBearerToken(refreshResponse.data.data.accessToken)
      setUserToken(userToken.payload)
      verifyCachedCompanies(userToken.payload)

      const meResponse = await api.auth.me()
      checkIfUserIsB2c()
      setUser(meResponse.data.data)
      setIsAuth(true)
    } catch (error) {
      logout()
    }
  }

  const checksToken = async () => {
    const token = storage.get('token')
    if (token) {
      api.setBearerToken(token)
      await loadUser()
      const userToken = jwtDecode(token) as UserToken
      setUserToken(userToken.payload)
    } else {
      setIsAuth(false)
      api.removeBearerToken()
    }
  }

  async function loadUser() {
    try {
      const response = await api.auth.me()
      checkIfUserIsB2c()
      setUser(response.data.data)
      setIsAuth(true)
    } catch (error: any) {
      refreshToken()
    }
  }

  async function authFromUrlToken(token: string) {
    try {
      const newTokens = await api.auth.validateToken(token)
      if (newTokens) {
        storage.set('token', newTokens.data.data.accessToken)
        storage.set('refreshToken', newTokens.data.data.refreshToken)
        storage.set('keepLoggedIn', 'false')
        api.setBearerToken(newTokens.data.data.accessToken)
        const userToken = jwtDecode(newTokens.data.data.accessToken) as UserToken
        setNoPasswordAllowed(userToken.payload.userPermissions.includes('users:change-password:verified'))
        setUserToken(userToken.payload)
        verifyCachedCompanies(userToken.payload)
        const meResponse = await api.auth.me()
        setUser(meResponse.data.data)
        setIsAuth(true)
        return true
      }
    } catch (error) {
      setIsAuth(false)
      return false
    }
    return false
  }

  async function login(username: string, password: string, keepLoggedIn: boolean) {
    api.removeBearerToken()
    try {
      const loginResponse = await api.auth.login({email: username, password, keepLoggedIn})
      storage.set('token', loginResponse.data.data.accessToken)
      storage.set('refreshToken', loginResponse.data.data.refreshToken)
      storage.set('keepLoggedIn', `${keepLoggedIn}`)
      api.setBearerToken(loginResponse.data.data.accessToken)
      const userToken = jwtDecode(loginResponse.data.data.accessToken) as UserToken
      setUserToken(userToken.payload)
      verifyCachedCompanies(userToken.payload)

      const meResponse = await api.auth.me()
      setUser(meResponse.data.data)
      checkIfUserIsB2c()
      setIsAuth(true)
    } catch (err) {
      const error = err as AxiosError
      throw error
    }
  }

  function verifyCachedCompanies(userToken: UserTokenPayload) {
    const selectedCompaniesState = JSON.parse('[' + localStorage.getItem('selectedCompaniesState') + ']') as number[] | null
    if (selectedCompaniesState && !selectedCompaniesState.every((it) => userToken.authorizedCompanies?.includes(it))) {
      localStorage.removeItem('selectedCompaniesState')
    }

    const selectedCompanyState = localStorage.getItem('selectedCompanyState')
    if (selectedCompanyState && !userToken.authorizedCompanies?.includes(Number(selectedCompanyState))) {
      localStorage.removeItem('selectedCompanyState')
    }
  }

  async function logout() {
    storage.clearKeys(['token', 'refreshToken', 'keepLoggedIn', 'companiesMemo'])
    api.removeBearerToken()
    setIsAuth(false)
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuth,
        login,
        logout,
        noPasswordAllowed,
        userToken,
        refreshToken,
        authFromUrlToken,
        checksToken,
        userB2cHasCompanyExpired,
        setUserB2cHasCompanyExpired,
        userIsB2c,
        checkDueDateInB2cUserCompany,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  return useContext(AuthContext)
}
