import createStyleSheet from 'src/utilities/createStyleSheet'

export const lprGridTableStyles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    minHeight: '72vh',
    '@media(max-width:1540px)': {
      minHeight: '70vh',
    },
  },
  boxSeeImage: {
    cursor: 'pointer',
  },
  iconCam: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
  },
  boxSituationRegistered: {
    backgroundColor: '#00AB80',
    color: '#FFFFFF',
    borderRadius: '0.3125rem',
    padding: ' 0.0625rem 0.3125rem',
    fontSize: '0.65rem',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  boxSituationProcessing: {
    backgroundColor: '#2FA1DE',
    color: '#FFFFFF',
    borderRadius: '0.3125rem',
    padding: ' 0.0625rem 0.3125rem',
    fontSize: '0.65rem',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  boxSituationTheft: {
    backgroundColor: '#E33535',
    color: '#FFFFFF',
    borderRadius: '0.3125rem',
    padding: ' 0.0625rem 0.3125rem',
    fontSize: '0.65rem',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  boxSituationNotRegistered: {
    backgroundColor: '#FF8C00',
    color: '#FFFFFF',
    borderRadius: '0.3125rem',
    padding: ' 0.0625rem 0.3125rem',
    fontSize: '0.65rem',
    fontWeight: '600',
    fontFamily: 'Inter',
  },
  boxImagePlate: {
    backgroundColor: '#ccc',
    height: '40px',
    width: '100px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    fontWeight: 450,
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
})
