import {Grid} from '@mui/material'

import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'

import moment from 'moment'
import ReactApexChart from 'react-apexcharts'
import api from 'src/services/api'

import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

import createStyleSheet from 'src/utilities/createStyleSheet'

import {useCompany} from 'src/contexts/CompanyContext'
import {EventSerieTypes} from 'src/services/api/endpoints/ReportsEndpoint'

export type ChartEventFilterDate = {
  start: string
  end: string
}

export type SeriesTime = {
  name?: string
  data: SeriesCharts[]
}

type SeriesCharts = {
  name: string
  data: []
}

type Data = {
  count: number
  year: number
  month: number
  week: number
  day: number
  hour: number
  minute: number
}

type DashboardTimeEventsProps = {
  filterDate: ChartEventFilterDate
}

function DashboardEventsStatus({filterDate}: DashboardTimeEventsProps) {
  const {enqueueSnackbar} = useSnackbar()

  const [seriesData, setSeriesData] = useState<SeriesCharts[]>([])

  const [typeChartGroup, setTypeChartGroup] = useState<ApexXAxis>({
    type: undefined,
    labels: {
      format: '',
    },
  })
  const blockEventChartsRequests = useRef(false)

  const {selectedCompanyId} = useCompany()

  async function mapTypeDates(element: Data[], aggregation: string): Promise<any> {
    const data = await Promise.all(
      // eslint-disable-next-line array-callback-return
      element.map((it) => {
        switch (aggregation) {
          case 'minute':
            setTypeChartGroup({labels: {format: 'HH:mm'}})
            return {x: `${it.hour <= 9 ? '0' + it.hour : it.hour}:${it.minute <= 9 ? '0' + it.minute : it.minute}`, y: it.count}
          case 'hour':
            setTypeChartGroup({labels: {format: 'HH'}})
            return {x: `${it.hour <= 9 ? '0' + it.hour : it.hour}h`, y: it.count}
          case 'day':
            setTypeChartGroup({labels: {format: 'dd/MM/yyyy'}})
            return {x: `${it.day <= 9 ? '0' + it.day : it.day}/${it.month <= 9 ? '0' + it.month : it.month}/${it.year}`, y: it.count}
          case 'week':
            setTypeChartGroup({type: 'numeric'})
            return {x: `${it.week}`, y: it.count}
          case 'month':
            setTypeChartGroup({labels: {format: 'MM'}})
            return {x: `${it.month <= 9 ? '0' + it.month : it.month}/${it.year}`, y: it.count}
          case 'year':
            setTypeChartGroup({labels: {format: 'yyyy'}})
            return {x: ` ${it.year}`, y: it.count}
        }
      }),
    )
    return data
  }

  async function loadEventChart() {
    if (!selectedCompanyId) return
    if (blockEventChartsRequests.current) return
    blockEventChartsRequests.current = true
    setSeriesData([])
    try {
      let addHour
      if (moment(filterDate.end).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
        addHour = moment(Date.now()).format('HH:mm')
      } else {
        addHour = '23.99'
      }
      const response = await api.reports.eventCharts({
        start:
          filterDate.start !== moment().format('YYYY-MM-DD')
            ? moment(filterDate.start!).format('YYYY-MM-DDTHH:mm')
            : moment(filterDate.start!).format('YYYY-MM-DDTHH:mm'),
        end:
          filterDate.start !== moment().format('YYYY-MM-DD')
            ? moment(filterDate.start!).add(23.99, 'hours').format('YYYY-MM-DDTHH:mm')
            : moment(filterDate.start!).add(addHour, 'hours').format('YYYY-MM-DDTHH:mm'),
        filter: {companyIds: [selectedCompanyId]},
      })

      response.data.data.entities.forEach(async (element, index) => {
        const series = element.series as EventSerieTypes
        const chatX: SeriesCharts[] = await Promise.all(
          Object.keys(series).map(async (key) => {
            return {
              name: key,
              data: await mapTypeDates(series[key as keyof EventSerieTypes], element.aggregation),
            } as SeriesCharts
          }),
        )
        setSeriesData(chatX)
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar gráficos')
    } finally {
      blockEventChartsRequests.current = false
    }
  }

  useEffect(() => {
    setSeriesData([])
    setTimeout(() => loadEventChart(), 1050)
  }, [filterDate.start, selectedCompanyId])

  const opts: ApexCharts.ApexOptions = {
    series: seriesData.filter((it) => it.name !== 'Eventos'),

    chart: {
      width: '100%',
      height: 180,
      type: 'line',
      zoom: {
        enabled: false,
      },
      background: '#ffff',
    },

    dataLabels: {
      enabled: true,
    },
    colors: ['#137DDF', '#F3E244', '#EB5564', '#69E067', '#A04EE0', '#EB9D55'],
    xaxis: typeChartGroup,
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    title: {
      text: `Eventos por Hora (Status do Evento) - ${
        filterDate.start !== '' ? moment(filterDate.start).format('DD/MM/YYYY') : moment(filterDate.start!).format('DD/MM/YYYY')
      }`,
      margin: 41,
      align: 'left',
      offsetX: -7,
      offsetY: -10,
      style: {
        fontSize: '18px',
        fontWeight: 550,
        fontFamily: 'Inter',
        color: '#1D1E28',
      },
    },
    subtitle: {
      text: 'Quantidade de Eventos',
      align: 'left',
      offsetX: 6,
      offsetY: 65,
      style: {
        fontSize: '14px',
        fontWeight: 'none',
        fontFamily: 'Inter',
        color: '#8E8E8E',
      },
    },
    grid: {
      show: true,
    },
    markers: {
      size: 1,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      floating: true,
      offsetY: -100,
      offsetX: 915.5,
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      fontFamily: 'Inter',
    },
    noData: {
      text: 'Nenhum evento foi encontrado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000000',
        fontSize: '14px',
        fontFamily: 'Inter',
      },
    },
    responsive: [
      {
        breakpoint: 1920,
        options: {
          legend: {
            offsetX: 500,
            offsetY: -98,
          },
          chart: {
            width: '100%',
            height: 380,
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          legend: {
            offsetX: 470,
          },
          chart: {
            width: '100%',
            height: 380,
          },
        },
      },
    ],
  }

  return (
    <Grid container padding={1.5} sx={styles.grid}>
      <Grid item xs={12}>
        <ReactApexChart options={opts} series={opts.series} type='line' height={390} />
      </Grid>
    </Grid>
  )
}

const styles = createStyleSheet({
  grid: {
    padding: 2,
    paddingLeft: 3.5,
    borderRadius: '8px',
    background: 'white',
  },
  title: {
    margin: '0px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: 600,
    fontSize: '1.05em',
    lineHeight: '2',
    textAlign: 'center',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'left',
  },
  FilterRangeTimeButton: {
    width: '100px',
    height: '38.5px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginTop: '9.5px',
  },
})

export default DashboardEventsStatus
