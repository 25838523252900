import {Box, Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import actionsObject from 'src/images/actionsObject.svg'
import Delete from 'src/images/deleteIconBlack.svg'
import IconEditUser from 'src/images/iconEditUser.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'

type TooltipEvent = 'to-view' | 'edit' | 'delete'

type LprInfos = {
  oneObject: number
  onClick?: (event: TooltipEvent, oneObject: number) => void
}

const MenuMoreInformationsObject = ({oneObject, onClick}: LprInfos) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Tooltip title='Mais Informações' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('edit', oneObject)
          }}>
          <img src={IconEditUser} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Editar</Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('delete', oneObject)
          }}>
          <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  )
}

export default MenuMoreInformationsObject

const styles = createStyleSheet({
  menuInfos: {
    padding: 1,
    fontSize: '12px',
  },
})
