import {Label, Layer, Tag, Text} from 'react-konva'
import {DetectionTooltipProps} from '../../generics/props/DetectionTooltipProps'

export function DetectionTooltip(detectionTooltipProps: DetectionTooltipProps) {
  const {x, y, visible, text} = detectionTooltipProps
  return (
    <Layer>
      <Label x={x} y={y} opacity={0.75} visible={visible} listening={false}>
        <Tag
          fill='black'
          pointerDirection='down'
          pointerWidth={10}
          pointerHeight={10}
          lineJoin='round'
          shadowColor='black'
          shadowBlur={10}
          shadowOffsetX={10}
          shadowOffsetY={10}
          shadowOpacity={0.2}
        />
        <Text text={text} fontFamily='Calibri' fontSize={18} padding={5} fill='white' />
      </Label>
    </Layer>
  )
}
