import {BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_BRAINBOX_BASE_URL

export type BrainBoxInclude = 'client' | 'outputDevices'

type GetBrainBoxParams = {
  page?: number
  pageSize?: number
  search?: {
    brainBoxName?: string
    clientName?: string
  }
  includes?: BrainBoxInclude[]
  filter?: {
    ids?: number[]
    companyIds?: number[]
    clientIds?: number[]
    isArmed?: boolean
    isOnline?: boolean
  }
}

export type ClientbrainBox = {
  id: number
  name: string
  isDeleted: boolean
  companyId: number
}

export type BrainBox = {
  id: number | null
  client?: ClientbrainBox
  name: string
  outputDevices: BrainBoxOutput[]
  host?: string
  port?: string
  isArmed?: boolean
  isOnline?: boolean
  isDeleted?: boolean
}

export type BrainBoxUpdate = {
  name?: string
  host?: string
  port?: number | null
  isArmed?: boolean
}

export type BrainBoxCreateProps = {
  clientId: number
  name: string
  thingName: string
}

export type CreateTriggerDTO = {
  objectId: number
  outputDeviceId: number
  triggerTime?: number
}

export type Trigger = {
  id: number
  outputDeviceId: number
  triggerTime?: number
  lastTrigger: Date
  lastTriggerEject: Date
  isActive: boolean
  objectId: number
}

export class BrainBoxEndpoint {
  async getAll(usersParams: GetBrainBoxParams): Promise<AxiosResult<Paginate<BrainBox>>> {
    return await axios.get(`${prefix}/brain-boxes`, {
      params: prepareParams(usersParams),
    })
  }

  async createBrainBox(body: BrainBoxCreateProps): Promise<AxiosResult<BrainBox>> {
    return await axios.post(`${prefix}/brain-box/create`, body)
  }

  async updateBrainBoxStatus(id: number, body: BrainBoxUpdate) {
    return await axios.patch(`${prefix}/brain-box/${id}`, body)
  }

  async deleteMany(ids: number[]): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/brain-box/`, {data: {ids: ids}})
  }

  async verifyBrainBoxCode(code: string): Promise<AxiosResult<boolean>> {
    return await axios.get(`${prefix}/brain-box/device/find-one?code=${code}`)
  }

  async createTrigger(body: CreateTriggerDTO): Promise<AxiosResult<Trigger>> {
    return await axios.post(`${prefix}/trigger`, body)
  }

  async deleteTrigger(id: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/trigger/${id}`)
  }
}
