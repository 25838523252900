import {Box} from '@viptech/react-components'
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../components/top-bar/TopBar'
import {AdvancedFilters, FilterSelectedFinal} from '../../components/advancedFilters/AdvancedFilters'
import {TransparentButton} from '../../components/advancedFilters/AdvancedFiltersBase'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import Modal from '../../components/modal/ModalCustom'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import deleteIconBlue from '../../images/deleteIconBlue.svg'
import {Camera} from '../../services/api'
import {RenderIfConditionIsMet} from '../../utilities/RenderIfConditionIsMet'
import {ContainerFilters, FiltersSelectedContainer} from '../alarms/AlarmsBase'
import CameraGridTable from './components/CameraGridTable'
import CameraOptionsMenu from './components/CameraOptionsMenu'
import CopyConfigCameraModal from './components/CopyConfigCameraModal'
import CameraRegisterProvider, {useCamRegisterModalContext2} from './context/CameraRegisterContext'
import CamerasProvider, {initalCameraFilterSituation, initalCameraFilterStatus, useCameraContext} from './context/CamerasContext'
import CreateCameraStepper from './newComponents/CreateCameraStepper'
import {useNavigate} from 'react-router-dom'
import {TopBarContainer} from './CamerasBase'
import {useAuth} from 'src/contexts/AuthContext'
import {useWindowSize} from 'usehooks-ts'
import {ContainerPaperContent} from 'src/layouts/main-layout/LayoutBase'
import {CameraListCardsResponsive} from './components/CameraListCardsResponsive'

const searchFilterData = [
  {id: '1', label: 'Nome do Dispositivo'},
  {id: '2', label: 'ID do Cliente'},
  {id: '3', label: 'Nome do Cliente'},
]

function CamerasInner() {
  const {
    showOptionsButton,
    reloadCameras,
    deleteCameras,
    setCamerasSearch,
    camerasSearch,
    selectedCamerasIds,
    setSearchFilterSelected,
    camerasPage,
    loadingCameras,
    setCamerasStatus,
    toggleStatusFilter,
    toggleSituationFilter,
    searchFilterSelected,
  } = useCameraContext()

  const {userIsB2c} = useAuth()

  const hasCreateCameraPermission = ValidatePermissions('cameras:create')

  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [typeMethod, setTypeMethod] = useState<string>('')
  const [modalContent, setModalContent] = useState<string | ReactElement>('')
  const [modalTitle, setModalTitle] = useState<string>('')

  const {stepsClose} = useCamRegisterModalContext2()
  const {width} = useWindowSize()

  const menuOpen = Boolean(menuEl)
  const quantity = selectedCamerasIds.length
  const isPlural1: string = quantity <= 1 ? '' : 's'
  const isPlural2: string = quantity <= 1 ? '' : 'em'

  const [showSearchFilterSelected, setshowSearchFilterSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  const [openCreateCameraModal, setOpenCreateCameraModal] = useState(false)

  const [cameraFilterStatus, setCameraFilterStatus] = useState<DropdownItem[]>([])
  const [cameraFilterSituation, setCameraFilterSituation] = useState<DropdownItem[]>([])
  const statusMemory = useRef<DropdownItem[]>([])
  const situationMemory = useRef<DropdownItem[]>([])

  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [chosenFilters, setChosenFilters] = useState<FilterSelectedFinal[]>([])
  const [disabledSelectFilters, setDisabledSelectFilters] = useState(false)

  const [openCopyConfigModal, setOpenCopyConfigModal] = useState(false)

  const cameraFilters = [
    {
      id: '1',
      label: 'Status',
      itemsOnMenu: cameraFilterStatus,
    },
    {
      id: '2',
      label: 'Situação',
      itemsOnMenu: cameraFilterSituation,
    },
  ]

  useEffect(() => {
    setshowSearchFilterSelected(searchFilterData.find((it) => it.label === searchFilterSelected) ?? {id: '', label: ''})
  }, [searchFilterSelected])

  useEffect(() => {
    const filters = initalCameraFilterStatus.map((it, index) => {
      return {
        id: `${index}`,
        label: it,
      }
    })

    setCameraFilterStatus(filters)
    statusMemory.current = filters
    const situation = initalCameraFilterSituation.map((it, index) => {
      return {
        id: `${index}`,
        label: it,
      }
    })

    setCameraFilterSituation(situation)
    situationMemory.current = situation
  }, [])

  function withConfirmation(title: string, typeOption: string, content: string | ReactElement) {
    setMenuEl(null)
    setModalContent(content)
    setOpenModal(true)
    setTypeMethod(typeOption)
    setModalTitle(title)
  }

  function isConfirmation(string: string) {
    setMenuEl(null)
    if (string === 'delete') deleteCameras()
    else if (string === 'offline') setCamerasStatus(false)
    else if (string === 'online') setCamerasStatus(true)
    else if (string === 'copy-config') setOpenCopyConfigModal(true)
  }

  const loadNavigationState = () => {
    const savedState = localStorage.getItem('navigationState')
    return savedState ? JSON.parse(savedState) : {prevPage: 'register/devices', query: '', page: 0, itemSearch: 'Nome do Dispositivo'}
  }

  const onChangeSelect = (event: DropdownItem) => {
    const value = event.label
    setshowSearchFilterSelected(event)
    setSearchFilterSelected(value)
    updateValueInLocalStorage('itemSearch', value)
    if (camerasSearch) reloadCameras()
  }

  useEffect(() => {
    updateValueInLocalStorage('query', camerasSearch)
  }, [camerasSearch])

  useEffect(() => {
    const savedState = loadNavigationState()
    savedState['page'] = camerasPage.page
    localStorage.setItem('navigationState', JSON.stringify(savedState))
  }, [camerasPage.page])

  const updateValueInLocalStorage = useCallback((field: 'query' | 'page' | 'itemSearch', newValue: any) => {
    const savedState = loadNavigationState()
    savedState[field] = newValue
    localStorage.setItem('navigationState', JSON.stringify(savedState))
  }, [])

  function handleCloseModal() {
    stepsClose()
    setOpenCreateCameraModal(false)
  }

  const handlePartialSetFilter = (newFilter: FilterSelectedFinal) => {
    if (!cameraFilterStatus.length && newFilter.id === '1') return
    if (!cameraFilterSituation.length && newFilter.id === '2') return
    setDisabledSelectFilters(true)

    const moreFilters = [...chosenFilters, newFilter]
    setChosenFilters(moreFilters)
    setShowMoreFilters(true)
  }

  const handleSetPartialFilters = (value: DropdownItem, index: number) => {
    setDisabledSelectFilters(false)

    const newFilter = [...chosenFilters]
    newFilter[index].selectedItem = value

    if (newFilter[index].label === 'Status') {
      setCameraFilterStatus((prev) => prev.filter((it) => it.label !== value.label))
      newFilter[index].itemsOnMenu = cameraFilterStatus

      toggleStatusFilter(value.label)
    }
    if (newFilter[index].label === 'Situação') {
      setCameraFilterSituation((prev) => prev.filter((it) => it.label !== value.label))
      newFilter[index].itemsOnMenu = cameraFilterSituation
      toggleSituationFilter(value.label)
    }

    setChosenFilters(newFilter)
  }

  const deleteFilter = (index: number) => {
    const deleteItem = chosenFilters[index].selectedItem?.label
    if (!deleteItem) return

    const deleted = {
      id: chosenFilters[index].selectedItem?.id ?? '',
      label: deleteItem,
    }

    if (chosenFilters[index].label === 'Status') {
      toggleStatusFilter(deleteItem)
      setCameraFilterStatus((prev) => [...prev, deleted])
    }

    if (chosenFilters[index].label === 'Situação') {
      toggleSituationFilter(deleteItem)
      setCameraFilterSituation((prev) => [...prev, deleted])
    }

    const deletedFilters = chosenFilters.filter((it) => it.selectedItem?.label !== deleteItem)
    setChosenFilters(deletedFilters)

    if (deletedFilters.length <= 0) {
      setShowMoreFilters(false)
    }
  }

  const deleteAllFilters = () => {
    const findStatusFilter = chosenFilters.filter((it) => it.label === 'Status')
    const findSituationFilter = chosenFilters.filter((it) => it.label === 'Situação')

    if (findStatusFilter) {
      findStatusFilter.forEach((it) => {
        if (!it.selectedItem) return
        toggleStatusFilter(it.selectedItem.label)
      })
    }

    if (findSituationFilter) {
      findSituationFilter.forEach((it) => {
        if (!it.selectedItem) return
        toggleSituationFilter(it.selectedItem.label)
      })
    }

    setDisabledSelectFilters(false)
    setShowMoreFilters(false)
    setChosenFilters([])

    setCameraFilterSituation(situationMemory.current)
    setCameraFilterStatus(statusMemory.current)
  }
  const navigate = useNavigate()

  const [cameratocopy, setCameraToCopy] = useState<Camera>({} as Camera)

  const choosenCameraToCopy = (cameraToCopy: Camera) => {
    setCameraToCopy(cameraToCopy)

    withConfirmation('Copiar configuração', 'copy-config', contentCopyConfig())
  }

  const contentCopyConfig = () => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <p> Ao copiar uma configuração, você está copiando:</p>

        <ul>
          <li style={{paddingLeft: '-10px'}}>Áreas e linhas cadastradas</li>
          <li>Detecção de objetos e suas respectivas ações</li>
          <li>Configuração de arme e desmarme</li>
        </ul>

        <p>Tem certeza que deseja prosseguir?</p>
      </div>
    )
  }

  const handleAddNewCamera = () => {
    if (userIsB2c) navigate('/camera-config?type=new-camera')
    else setOpenCreateCameraModal(true)
  }
  return (
    <ContainerPaperContent>
      <TopBar>
        <TopBarContainer>
          <Box display='flex' columnGap='16px' width={width >= 768 ? undefined : '100%'}>
            <AppReloadButton loading={loadingCameras} onClick={() => reloadCameras()} />
            <SelectWithSearchComponent
              searchBy={searchFilterData}
              setSearchSelected={onChangeSelect}
              searchSelected={showSearchFilterSelected}
              inputValue={camerasSearch}
              inputSearch={setCamerasSearch}
              filterBy={cameraFilters}
              setSelectedFilter={handlePartialSetFilter}
              disabledSelectFilter={disabledSelectFilters}
              widthOnSelect={'310px'}
              dontSetSearchBy
            />
          </Box>

          <RenderIfConditionIsMet condition={hasCreateCameraPermission && width >= 768}>
            <AppAddOrOptionsButton
              text={`Adicionar ${userIsB2c ? 'uma Câmera' : 'um dispositivo'}`}
              showOptions={showOptionsButton}
              onClickAdd={() => handleAddNewCamera()}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
            />
          </RenderIfConditionIsMet>
        </TopBarContainer>

        <Box marginTop='15px' align='center' display='flex' justifyContent='center'>
          {showMoreFilters && (
            <ContainerFilters>
              <FiltersSelectedContainer>
                {chosenFilters.map((it, index) => {
                  return <AdvancedFilters index={index} filtersSelected={it} setFiltersSelected={handleSetPartialFilters} deleteFilter={deleteFilter} />
                })}
              </FiltersSelectedContainer>

              <TransparentButton onClick={() => deleteAllFilters()}>
                <Box display='flex' columnGap='10px'>
                  <img src={deleteIconBlue} alt='limpar filtro' />
                  <p>Limpar Seleção</p>
                </Box>
              </TransparentButton>
            </ContainerFilters>
          )}
        </Box>
      </TopBar>

      <Modal isOpen={openCreateCameraModal} closeModal={() => handleCloseModal()} backgroundColor='white' width='60%' minHeight='700px'>
        <CreateCameraStepper closeModal={() => handleCloseModal()} reloadCameras={reloadCameras} />
      </Modal>

      {width <= 767 && userIsB2c ? (
        <CameraListCardsResponsive redirectToAddCamera={() => navigate('/camera-config?type=new-camera')} />
      ) : (
        <CameraGridTable
          setCameraOffline={() =>
            withConfirmation(
              `Desabilitar câmera${isPlural1}`,
              'offline',
              `Esta ação desabilitará as operações desta${isPlural1} câmera${isPlural1} e fará com que os disparos não sejam processados.`,
            )
          }
          setCameraOnline={() =>
            withConfirmation(
              `Habilitar câmera${isPlural1}`,
              'online',
              `Esta ação habilita a${isPlural1} câmera${isPlural1} a voltar${isPlural2} a operar quando estiver${isPlural2} armada${isPlural1}.`,
            )
          }
          deleteCamera={() =>
            withConfirmation(
              `Deletar câmera${isPlural1}`,
              'delete',
              `Esta ação removerá esta${isPlural1} câmera${isPlural1} do cliente e deixará de gerar eventos.`,
            )
          }
          setOpenCopyConfigModal={choosenCameraToCopy}
        />
      )}

      <ConfirmationModal
        content={modalContent}
        isOpen={openModal}
        title={modalTitle}
        onClose={() => setOpenModal(false)}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenModal(false)
              setMenuEl(null)
              isConfirmation(typeMethod)
            },
            variant: 'contained',
            color: '#009EFF',
          },
        ]}
      />
      <CameraOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onClickOffline={() =>
          withConfirmation(
            `Desabilitar câmera${isPlural1}`,
            'offline',
            `Esta ação desabilitará as operações desta${isPlural1} câmera${isPlural1} e fará com que os disparos não sejam processados.`,
          )
        }
        onClickOnline={() =>
          withConfirmation(
            `Habilitar câmera${isPlural1}`,
            'online',
            `Esta ação habilita a${isPlural1} câmera${isPlural1} a voltar${isPlural2} a operar quando estiver${isPlural2} armada${isPlural1}.`,
          )
        }
        onClickDelete={() =>
          withConfirmation(
            `Deletar câmera${isPlural1}`,
            'delete',
            `Esta ação removerá esta${isPlural1} câmera${isPlural1} do cliente e deixará de gerar eventos.`,
          )
        }
      />

      <Modal isOpen={openCopyConfigModal} closeModal={() => setOpenCopyConfigModal(false)} backgroundColor='white'>
        <CopyConfigCameraModal cameraToCopy={cameratocopy} closeModal={() => setOpenCopyConfigModal(false)} />
      </Modal>
    </ContainerPaperContent>
  )
}

function Devices() {
  return (
    <CamerasProvider>
      <CameraRegisterProvider>
        <CamerasInner />
      </CameraRegisterProvider>
    </CamerasProvider>
  )
}

export default Devices
