import {Box, Button, Modal, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {CameraRegisterAreaDetection} from 'src/common/components/camera-register-area-detection/CameraRegisterAreaDetection'
import {Pos} from 'src/common/types/generics/ImageDetections'
import {BtnClose} from 'src/components/BtnClose'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import {addInterestArea, editInterestArea} from '../detection-area/DetectionAreaFunctions'
import {CameraModalRegisterAreaDetectionProps} from './CameraModalRegisterAreaDetectionProps'

export function CameraModalRegisterAreaDetection({
  open,
  setOpen,
  editingArea,
  drawEditable,
  setEditingArea,
  setSelectedArea,
  setDrawEditable,
  selectedArea,
  height,
}: CameraModalRegisterAreaDetectionProps) {
  const [nameAreaInterest, setNameAreaInterest] = useState(editingArea?.label)
  const [cancelString, setCancelString] = useState<string>()
  const [saveString, setSaveString] = useState<string>()
  const [points, setPoints] = useState<Pos[]>([])
  const {snapshot, areaItems, _dimImage, camera, getInterestAreas} = useCameraConfigContext()
  const {enqueueSnackbar} = useSnackbar()

  const handleDisabled = () => {
    if (points.length < 3 || !nameAreaInterest) return true
    return false
  }

  return (
    <Modal
      isOpen={open}
      closeModal={() => {
        setEditingArea(null)
        setDrawEditable(false)
        setOpen(false)
      }}
      backgroundColor='white'>
      <Box padding='40px' minWidth='600px'>
        <Typography variant='p' size='20px' style={{fontWeight: 500}}>
          Cadastro de Área
        </Typography>

        <BtnClose onClose={() => setCancelString('cancel')} />

        <Box paddingTop='20px'>
          <CameraRegisterAreaDetection
            areas={areaItems}
            _selectedArea={selectedArea}
            setNameAreaInterest={setNameAreaInterest}
            nameAreaInterest={nameAreaInterest}
            drawEditable={drawEditable}
            setDrawEditable={setDrawEditable}
            editingArea={editingArea}
            setSelectedArea={setSelectedArea}
            setEditingArea={setEditingArea}
            src={`data:image/jpeg;base64,${snapshot}`}
            subscribe={async (action) => {
              switch (action.type) {
                case 'edit-interest-area':
                  return await editInterestArea(action.payload, areaItems, _dimImage, enqueueSnackbar, () => getInterestAreas(camera!.id, _dimImage))
                case 'new-interest-area':
                  return await addInterestArea(camera!.id, action.payload, areaItems, _dimImage, enqueueSnackbar, () => getInterestAreas(camera!.id, _dimImage))
              }
            }}
            isOpen={open}
            setOpen={setOpen}
            getPoints={(points) => setPoints(points)}
            setCancel={setCancelString}
            setSave={setSaveString}
            cancel={cancelString}
            save={saveString}
            height={height}
          />
        </Box>

        <Box display='flex' justify='flex-end' marginTop='30px' columnGap='10px'>
          <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => setCancelString('cancel')}>
            Cancelar
          </Button>

          <Button fontSize='12px' disabledTextColor='#8E8E8E' height='40px' width='150px' onClick={() => setSaveString('save')} disabled={handleDisabled()}>
            Salvar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
