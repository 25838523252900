import {Box} from '@viptech/react-components'
import StepperDoneIcon from 'src/images/stepDoneSymbol.svg'
import {DivisionStepper, StepperBubble, TitleOnStep} from './StepperBase'

type StepperProps = {
  activeStep: number
  stepTitles: string[]
}

function Stepper(props: StepperProps) {
  const {activeStep, stepTitles} = props
  return (
    <Box display='flex' align='flex-start' justifyContent='center' width='850px' height='70px'>
      {stepTitles.map((title, index) => {
        return (
          <Box display='flex' direction='row' align='flex-start'>
            <Box key={index} display='flex' align='center' direction='column' width='35px' height='35px' overflow='visible'>
              <Box display='flex' align='center' justifyContent='center' width='100%'>
                {index < activeStep ? (
                  <img src={StepperDoneIcon} alt='done' width='35px' height='35px' />
                ) : (
                  <StepperBubble isActive={index === activeStep} indexLowerThanActive={index < activeStep}>
                    0{index + 1}
                  </StepperBubble>
                )}
              </Box>
              <TitleOnStep color={index <= activeStep ? '#00A3FF' : '#344054'}>{title}</TitleOnStep>
            </Box>
            {index < stepTitles.length - 1 && <DivisionStepper color={index < activeStep ? '#00A3FF' : '#E5E5E5'}></DivisionStepper>}
          </Box>
        )
      })}
    </Box>
  )
}

export default Stepper
