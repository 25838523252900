import {Button} from '@viptech/react-components'
import brainLogo from 'src/images/fullViptechLogo.svg'
import LogoutIcon from 'src/images/logoutSmallIcon.svg'
import {useAuth} from '../../../contexts/AuthContext'
import storage from '../../../services/storage'
import {HeaderContainer, HeaderExitButtonContainer, ImageContainer, ImageLogoutContainer} from './HeaderBase'

function Header() {
  const {logout} = useAuth()
  const userLogged = storage.get('token')

  return (
    <HeaderContainer>
      <ImageContainer src={brainLogo} alt='logo' onClick={() => (window.location.href = '/login')} />

      {userLogged && (
        <HeaderExitButtonContainer>
          <Button
            leftIcon={<ImageLogoutContainer src={LogoutIcon} alt='sair' />}
            color='transparent'
            onClick={() => {
              logout()
              window.location.href = '/login'
            }}>
            Sair
          </Button>
        </HeaderExitButtonContainer>
      )}
    </HeaderContainer>
  )
}

export default Header
