
export default function ArrowIcon({ disabled, transform }: { disabled?: boolean; transform?: string }) {
  return (
    <svg
      style={{
        transform: transform,
      }}
      width="12"
      height="12"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.62373 1.73833C5.42454 1.93758 5.31265 2.20778 5.31265 2.48952C5.31265 2.77126 5.42454 3.04146 5.62373 3.24071L10.8831 8.50008L5.62373 13.7595C5.43019 13.9598 5.32309 14.2282 5.32552 14.5068C5.32794 14.7854 5.43968 15.0519 5.63667 15.2489C5.83367 15.4459 6.10016 15.5576 6.37874 15.56C6.65733 15.5625 6.92572 15.4554 7.12611 15.2618L13.1367 9.25127C13.3359 9.05202 13.4478 8.78182 13.4478 8.50008C13.4478 8.21835 13.3359 7.94814 13.1367 7.7489L7.12611 1.73833C6.92686 1.53914 6.65666 1.42725 6.37492 1.42725C6.09318 1.42725 5.82298 1.53914 5.62373 1.73833Z"
        fill={disabled ? '#B9B9B9' : '#009eff'}
      />
    </svg>
  )
}
