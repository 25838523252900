import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField} from '@mui/material'
import {useSnackbar} from 'notistack'
import {BtnClose} from 'src/components/BtnClose'
import api from 'src/services/api'
import {BrainBoxButtonControl} from 'src/services/api/endpoints/BrainBoxButtonsControl'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type BrainBoxModalButtonControlProps = {
  isRegisterButtonControlOpen: boolean
  setIsRegisterButtonControlOpen: (isOpen: boolean) => void
  brainBoxButtonControlOne: BrainBoxButtonControl
  setBrainBoxButtonControlOne: (brainControl: BrainBoxButtonControl) => void
  getButtonControlRegister: () => void
  getButtonControlPendents: () => void
  isEdit: boolean
  setIsEdit: (isEdit: boolean) => void
}

const typesActions = [
  {id: 1, description: 'Armar'},
  {id: 2, description: 'Desarmar'},
  {id: 3, description: 'Reiniciar'},
]

function BrainBoxModalRegisterControl({
  isRegisterButtonControlOpen,
  setIsRegisterButtonControlOpen,
  brainBoxButtonControlOne,
  setBrainBoxButtonControlOne,
  getButtonControlRegister,
  getButtonControlPendents,
  isEdit,
  setIsEdit,
}: BrainBoxModalButtonControlProps) {
  const {enqueueSnackbar} = useSnackbar()

  async function updateButtonControl(id: number, action: number, description: string) {
    try {
      await api.brainBoxControl.updateBrainBoxStatus(id, {
        actionId: action,
        description: description,
      })
      enqueueSnackbar('Botão atualizado com sucesso', {
        variant: 'success',
        preventDuplicate: true,
      })
      getButtonControlRegister()
      getButtonControlPendents()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar botão')
    }
  }

  return (
    <Dialog open={isRegisterButtonControlOpen} onClose={() => setIsRegisterButtonControlOpen(false)}>
      <DialogTitle sx={styles.title}>{isEdit ? 'Editar Botão do Controle da Brain Box' : 'Cadastro do Botão do Controle da Brain Box'}</DialogTitle>
      <BtnClose onClose={() => setIsRegisterButtonControlOpen(false)} />
      <DialogContent>
        <Box>
          <FormControl fullWidth sx={{marginTop: 2}}>
            <InputLabel size='small' id='demo-simple-select-label'>
              Selecione a Ação
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              size='small'
              label='Seleciona a Ação'
              value={brainBoxButtonControlOne.actionId}
              onChange={(e) => setBrainBoxButtonControlOne({...brainBoxButtonControlOne, actionId: Number(e.target.value)})}>
              {typesActions.map((it) => (
                <MenuItem key={'server-' + it.id} value={it.id}>
                  {it.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{marginTop: 2}}>
            <TextField
              type='text'
              label='Descrição'
              size='small'
              value={brainBoxButtonControlOne.description}
              onChange={(e) => setBrainBoxButtonControlOne({...brainBoxButtonControlOne, description: e.target.value})}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{margin: '17px'}}>
          <Button
            sx={styles.cancelButton}
            onClick={() => {
              setIsRegisterButtonControlOpen(false)
              setBrainBoxButtonControlOne({
                actionId: -1,
                brainBoxId: -1,
                code: '',
                id: -1,
                status: undefined,
                statusId: -1,
                brainBox: undefined,
              })
            }}>
            Cancelar
          </Button>
          <Button
            sx={styles.confirmButton}
            disabled={brainBoxButtonControlOne.description! === null}
            variant='contained'
            onClick={() => {
              updateButtonControl(brainBoxButtonControlOne.id, brainBoxButtonControlOne.actionId, brainBoxButtonControlOne.description!)
              setIsRegisterButtonControlOpen(false)
            }}>
            Salvar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: 2.5,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  title: {
    paddingTop: 3,
    color: '#353535',
    width: '27em',
  },
  description: {
    width: '118.75em',
  },
})

export default BrainBoxModalRegisterControl
