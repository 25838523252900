import styled from 'styled-components'
import {TableRowProps} from './TableRowProps'

const TableRow = styled.tr<TableRowProps>`
  height: ${({rowHeight, theme}) => rowHeight ?? theme.table.tableBody.rowHeight};

  border-bottom: ${({borderBottom, theme}) => borderBottom ?? theme.table.tableBody.borderBottom};
  border-color: ${({borderColor, theme}) => borderColor ?? theme.table.tableBody.borderColor};
  border-radius: ${({borderRadius}) => borderRadius && borderRadius};
`

export default TableRow
