import {Box, Button, Table, TableData} from '@viptech/react-components'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import {Client, Layout} from 'src/services/api'
import {Page} from 'src/types'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Pagination from '../../../components/pagination/Pagination'

type LayoutGridTableProps = {
  layouts: Layout[]
  loadingLayouts: boolean
  totalElements: number
  layoutsPage: Page
  setLayoutsPage: (page: number) => void
  setLayoutsPageSize: (pageSize: number) => void
  openEditModal: (layout: Layout) => void
  layoutsIds: (ids: number[]) => void
  clients: Client[]
}

function LayoutGridTable(props: LayoutGridTableProps) {
  const {layouts, loadingLayouts, totalElements, layoutsPage, setLayoutsPage, setLayoutsPageSize, openEditModal, clients} = props
  const hasUpdateLayoutPermission = ValidatePermissions('layouts:update')
  const hasReadLiveStreamPermission = ValidatePermissions('livestream:read')
  const hasReadClientsPermission = ValidatePermissions('clients:read')
  const {getCompanyName} = useCompany()
  const navigate = useNavigate()

  const control = {
    currentPage: layoutsPage.page,
    setCurrentPage: setLayoutsPage,
    nextPage: () => setLayoutsPage(layoutsPage.page + 1),
    prevPage: () => setLayoutsPage(layoutsPage.page - 1),
    changePageSize: (pageSize: number) => setLayoutsPageSize(pageSize),
  }

  const columns = [
    {
      id: 'id',
      name: 'ID',
    },
    {
      id: 'name',
      name: 'Nome do Layout',
    },
    {
      id: 'client',
      name: 'Cliente',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'type',
      name: 'Tipo de Layout',
    },
    {
      id: 'cameras',
      name: 'Cameras',
    },
    {
      id: 'maxCameras',
      name: 'Capacidade',
    },
    {
      id: 'show',
      name: '',
    },
  ]

  if (!hasReadClientsPermission) {
    columns.splice(2, 1)
  }

  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table isLoading={loadingLayouts} skeletonRowsAmount={20} columns={columns}>
          {layouts.map((row) => (
            <RowHover id={`row-${row.id}`}>
              <TableData width='100px'>{row.id}</TableData>
              <TableData>
                <LinkWithAction
                  onClick={() => {
                    const {id, name, maxCameras, cameras, companyId, clientId, layoutTypeId} = row
                    if (hasUpdateLayoutPermission) openEditModal({id, name, maxCameras, cameras, companyId, clientId, layoutTypeId})
                  }}>
                  {row.name}
                </LinkWithAction>
              </TableData>
              {hasReadClientsPermission && <TableData>{row.clientId ? clients.find((it) => it.id === row.clientId)?.name : ' - '}</TableData>}
              <TableData width='400px'>{getCompanyName(row.companyId)}</TableData>
              <TableData width='130px'>{row.layoutTypeId === 1 ? 'Padrão' : 'LPR'}</TableData>
              <TableData width='130px'>{row.cameras ? row.cameras.length : 0}</TableData>
              <TableData width='130px'>{row.maxCameras}</TableData>
              <TableData width='130px'>
                {hasReadLiveStreamPermission && (
                  <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'flex-end'}}>
                    <Button
                      variant='contained'
                      fontSize='12px'
                      height='27px'
                      color='#009EFF'
                      onClick={(event) => {
                        navigate(`/camera-mosaic?id=${row.id}`)
                        event.stopPropagation()
                      }}>
                      Ver Layout
                    </Button>
                  </div>
                )}
              </TableData>
            </RowHover>
          ))}
        </Table>
      </Box>

      {!loadingLayouts && layouts.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={totalElements}
            itemsPerPage={layoutsPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default LayoutGridTable
