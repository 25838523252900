import styled from 'styled-components'

export const ContainerPageSelfRegister = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  gap: 64px;
  position: relative;
  @media (max-width: 767px) {
    padding: 55px 40px 40px;
  }
`

export const ContainerContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 16px;
  width: 800px;

  @media (max-width: 767px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`
export const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 150px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
`

export const ContainerButtonCopie = styled.div`
  width: 115px;

  @media (max-width: 767px) {
    width: 208px;
  }
`

export const InnerBoxContent = styled.div<{marginTop?: string}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-top: ${({marginTop}) => marginTop};
`
