import {Card, CardContent, CircularProgress, Typography} from '@mui/material'
import createStyleSheet from 'src/utilities/createStyleSheet'

type PeopleCountCardProps = {
  title: string
  value: number | undefined
  loading: boolean
  height?: string
}

function PeopleCountCard({title, value, loading, height}: PeopleCountCardProps) {
  return (
    <Card sx={[styles.card, {height}]} variant='outlined'>
      <CardContent>
        <Typography style={styles.value}>{loading ? <CircularProgress /> : value ? value : ' - '}</Typography>
        <Typography style={styles.title}>{title}</Typography>
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  card: {
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    margin: '0px',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#009EFF',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontSize: '1em',
    textAlign: 'center',
    overflow: 'clip',
  },
  value: {
    margin: '0px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '3rem',
    color: '#697586',
    fontWeight: 700,
    textAlign: 'center',
  },
})

export default PeopleCountCard
