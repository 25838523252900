import {Input} from 'src/components/input/Input'
import Envelope from 'src/images/envelopeIcon.svg'
import Lock from 'src/images/lockIconb2c.svg'
import {useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {Box} from '@viptech/react-components'

type SecondFormPageProps = {
  setPasswordConfirmation: (password: string) => void
  handleKeyPress: (event: KeyboardEvent) => void
}

function SecondFormPage(props: SecondFormPageProps) {
  const handleKeyPress = props.handleKeyPress
  const {setPasswordConfirmation} = props
  const {setRegisterData, registerData} = useB2CContext()

  return (
    <Box style={{display: 'flex', flexDirection: 'column', gap: '22px', width: '100%', alignItems: 'center'}}>
      <Input.Root
        label='E-mail'
        onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
        labelFontSize='14px'
        value={registerData.email}
        controlled
        leftIcon={<img src={Envelope} alt='ícone envelope' />}
        onChange={(e) => setRegisterData({email: e.target.value})}
      />
      {registerData.type === 'person' && (
        <>
          <Input.Root
            label='Senha'
            labelFontSize='14px'
            onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
            leftIcon={<img src={Lock} alt='ícone cadeado' />}
            type='password'
            controlled
            value={registerData.password}
            onChange={(e) => setRegisterData({password: e.target.value})}
          />
          <Input.Root
            label='Confirme a senha'
            labelFontSize='14px'
            onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
            leftIcon={<img src={Lock} alt='ícone cadeado' />}
            type='password'
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
        </>
      )}
    </Box>
  )
}

export default SecondFormPage
