export function datesDiffToString(date: Date) {
  const currentDate = new Date()
  const timeDifferenceInMilliseconds = Math.abs(date.getTime() - currentDate.getTime())

  const seconds = Math.floor(timeDifferenceInMilliseconds / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  return {days, hours: hours % 24, minutes: minutes % 60, seconds: seconds % 60}
}
