import moment from 'moment'
import {useState} from 'react'
import {Outlet, useOutletContext} from 'react-router-dom'
import {CompanyProvider} from 'src/contexts/CompanyContext'
import {ChartEventFilterDate} from 'src/pages/dashboard/components/DashboardEventsStatus'
import {AppProvider} from './contexts/AppContext'
import Sidebar from './sidebar/SideBar'
import {Header} from './header/Header'
import {Box} from '@viptech/react-components'
import {LayoutContentContainer, LayoutContentWrapper} from './LayoutBase'

type ContextType = {filterDate: ChartEventFilterDate}

function MainLayout() {
  const [filterDate, setFilterDate] = useState<ChartEventFilterDate>({
    start: moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm'),
    end: moment().format('YYYY-MM-DDTHH:mm'),
  })

  const handleChangeTimeStart = (dates: [Date, Date]) => {
    let start = moment(dates[0]).format('YYYY-MM-DDTHH:mm')
    let end = moment(dates[1]).format('YYYY-MM-DDTHH:mm')
    if (dates[0] === dates[1]) {
      start = moment(dates[0].setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm')
      end = moment(dates[1].setHours(23, 59, 59, 59)).format('YYYY-MM-DDTHH:mm')
    }
    setFilterDate({
      start,
      end,
    })
  }

  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <AppProvider>
      <CompanyProvider>
        <LayoutContentContainer>
          <Sidebar openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />

          <Box width='100%' overflow='auto' display='flex' direction='column'>
            <Header handleChangeTimeStart={handleChangeTimeStart} setOpenDrawer={setOpenDrawer} />
            <LayoutContentWrapper>
              <Outlet context={{filterDate}} />
            </LayoutContentWrapper>
          </Box>
        </LayoutContentContainer>
      </CompanyProvider>
    </AppProvider>
  )
}

export function useHeader() {
  return useOutletContext<ContextType>()
}

export default MainLayout
