import styled from 'styled-components'
import anchorIcon from 'src/images/linkIcon.svg'

export const TermParagraph = styled.p`
  font-family: 'Poppins', sans-serif;
  color: #667085;
  font-size: 14px;
  line-height: 21px;
  display: inline;
`

export const TermLI = styled.li`
  padding: 0;
  margin: 20px 0px;
  list-style-position: inside;
  &::marker {
    font-weight: bold;
  }
`

export const TermOL = styled.ol`
  padding: 0;

  ol {
    padding-left: 16px;
  }
`

export const TermSectionTitle = styled.h2`
  margin-top: 72px;
  margin-bottom: 32px;
  color: #1d2939;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (max-width: 480px) {
    margin-top: 56px;
    margin-bottom: 24px;

    &::after {
      content: url(${anchorIcon});
      position: absolute;
      margin-left: 5px;
      margin-top: 2px;
    }
  }

  cursor: pointer;

  &:hover::after {
    content: url(${anchorIcon});
    position: absolute;
    margin-left: 5px;
    margin-top: 2px;
  }
`
