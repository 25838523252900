import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_LIVESTREAMS_BASE_URL

export type Snapshot = {
  base64: string
  type: string
  size: number
}

export type testStream = {
  link: string
}

export class StreamingEndpoint {
  async getStream(cameraId: number): Promise<AxiosResult<string>> {
    return await axios.get(`${prefix}/open-stream/${cameraId}`)
  }

  async GetTestStream(params: testStream): Promise<AxiosResult<string>> {
    return await axios.get(`${prefix}/test`, {params: prepareParams(params)})
  }

  startStream(hashCode: string): string {
    return `${process.env.REACT_APP_BASE_URL}/live-streams/streaming/${hashCode}.m3u8`
  }
}
