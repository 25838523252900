import {Box, Button, Checkbox, Input, Loading, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {generateLinkRtsp, generateLinkRtspDVR} from '../../../utilities/LinkGenerator'
import {StepperModalActionsButtonContainer} from '../../camera-config/components/object-detection/newComponents/styles'
import CameraRtspLiveCamTest from '../components/CameraAddModal/CameraRegisterRTSP/CameraRtspLiveCamTest'
import {ChosenObject} from './CreateCameraStepper'
import {
  ChannelsTitles,
  CloseButton,
  ContainerCameras,
  ContainerCamerasVisualization,
  ContainerChannels,
  ContainerChannelsTitles,
  ContainerLoading,
  ContainerSteps,
  ContainerStepsRow,
  PlayButton,
} from './styles'

import Dropdown from '../../../components/dropdown/Dropdown'
import {DropdownItem} from '../../../components/dropdown/DropdownProps'
import PlayIconChangeColor from '../../../images/PlayIconBlue'
import CloseIcon from '../../../images/closeIconSmallGray.svg'
import api from '../../../services/api'
import {AxiosErrorGeneric} from '../../../utilities/RequestError'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'

type ConfigurationChannelsStepProps = {
  nextFunction: () => void
  returnFunction: (index: number, goBackTo?: number) => void
  setChosen: (choices: DvrCameras[]) => void
  chosen: ChosenObject
  isLoading: boolean
}

export type DvrCameras = {
  checkbox: boolean
  name: string
  chosedStream: DropdownItem
  channel: number
}

const streamItems: DropdownItem[] = [
  {id: '0', label: 'Main-stream'},
  {id: '1', label: 'Sub-stream'},
]

const ConfigurationChannelsStep = (props: ConfigurationChannelsStepProps) => {
  const {nextFunction, returnFunction, chosen, setChosen, isLoading} = props
  const {enqueueSnackbar} = useSnackbar()

  const creatingCamera = chosen?.serverName === 'Câmera RTSP'

  const length = creatingCamera || chosen.createRTSPWithLink ? 1 : chosen.cameraRTSP.channel
  const amountOfChannelsOnDVR = Array.from({length}, (_, index) => index)

  const [disableButton, setDisableButton] = useState(false)

  const [nameToReplaceOnAllCameras, setNameToReplaceOnAllCameras] = useState('Câmera')

  const [hashCode, setHashCode] = useState<string | null>(null)
  const [isLoadingStream, setIsLoadingStream] = useState(false)
  const [chosenCameraNameTest, setChosenCameraNameTest] = useState('')

  const [cameras, setCameras] = useState<DvrCameras[]>([])

  function generateCorrectLinkToTest(chosenChannel: number) {
    const manufacturer = chosen.cameraRTSP.manufacturer
    let codec = undefined
    if (manufacturer === 'Hikvision') codec = 'h.264'

    const addressInfo = {
      host: chosen.cameraRTSP.host,
      stream: chosen.cameraRTSP.stream,
      rtspPort: chosen.cameraRTSP.rtspPort,
      manufacturer: chosen.cameraRTSP.manufacturer,
      user: chosen.cameraRTSP.user,
      password: chosen.cameraRTSP.password,
      codec: codec,
      channel: chosenChannel,
    }

    if (chosen.serverName === 'DVR / NVR') {
      return generateLinkRtspDVR({addressInfo, enqueueSnackbar})
    }

    if (chosen.serverName === 'Câmera RTSP') {
      return generateLinkRtsp({addressInfo, enqueueSnackbar})
    }
    return null
  }

  function handleOpenStreamTest(chosenChannel: number) {
    setIsLoadingStream(true)

    if (chosen.createRTSPWithLink && chosen.cameraRTSP.link) openTestStreamCorrect(chosen.cameraRTSP.link)
    else {
      const link = generateCorrectLinkToTest(chosenChannel)

      if (link === null) {
        setChosenCameraNameTest('')
        enqueueSnackbar('Não conseguimos gerar o link de conexão', {
          variant: 'error',
        })
        return
      }
      openTestStreamCorrect(link)
    }
  }

  function channelsOnDvr() {
    const chosedStream = streamItems.find((it) => +it.id === chosen.cameraRTSP.stream) ?? {id: '0', label: 'Main-stream'}

    const cameras = amountOfChannelsOnDVR.map((_, index) => {
      let sumindex = index + 1
      let channel = creatingCamera ? chosen.cameraRTSP.channel : sumindex
      const cam = {
        checkbox: true,
        name: `${nameToReplaceOnAllCameras} ${sumindex}`,
        chosedStream: chosedStream,
        channel: channel,
      }

      return cam as DvrCameras
    })

    setCameras(cameras)
  }

  function replaceAllCamerasName() {
    const newCameras = cameras.map((it, index) => {
      let sumindex = index + 1
      return {
        ...it,
        name: `${nameToReplaceOnAllCameras} ${sumindex}`,
      }
    })
    setCameras(newCameras)
  }

  function replaceOneCheckbox(index: number) {
    const newCameras = [...cameras]
    newCameras[index].checkbox = !newCameras[index].checkbox
    setCameras(newCameras)
  }

  function replaceOneName(index: number, newName: string) {
    const newCameras = [...cameras]
    newCameras[index].name = newName
    setCameras(newCameras)
  }

  function replaceOneStream(index: number, newSteam: DropdownItem) {
    const newCameras = [...cameras]
    newCameras[index].chosedStream = newSteam
    setCameras(newCameras)
  }

  async function openTestStreamCorrect(link: string) {
    try {
      setIsLoadingStream(true)
      const response = await api.streaming.GetTestStream({
        link,
      })
      setHashCode(response.data.data)
    } catch (error) {
      const axiosError = error as AxiosErrorGeneric
      const message = axiosError.response?.data.message ? axiosError.response?.data.message : 'Não conseguimos abrir stream da câmera'
      handleErrorWithSnackbar(enqueueSnackbar, axiosError, message)
    } finally {
      setIsLoadingStream(false)
    }
  }

  useEffect(() => {
    channelsOnDvr()
  }, [length])

  useEffect(() => {
    setChosen(cameras)

    const checkBoxAreTrue = cameras.filter((it) => it.checkbox)
    if (checkBoxAreTrue.length) setDisableButton(false)
    else setDisableButton(true)
  }, [cameras])

  return (
    <ContainerSteps>
      <ContainerStepsRow>
        <Box display='flex' direction='column' rowGap='25px' width='100%'>
          <Box align='center' justifyContent='center'>
            <Typography variant='span' size='16px' style={{fontWeight: 500}}>
              Renomeie todas as câmeras
            </Typography>
            <Typography variant='p' size='12px' color='#8E8E8E'>
              O nome digitado no campo abaixo será aplicado a todas as câmeras
            </Typography>

            <Box paddingTop='15px' display='flex' columnGap='20px' paddingRight='8px'>
              <Input.Root
                maxLength={50}
                labelFontWeight='400'
                placeholder='Digite aqui'
                value={nameToReplaceOnAllCameras}
                onChange={(e) => setNameToReplaceOnAllCameras(e.target.value)}
                height='35px'
              />

              <Button fontSize='12px' height='35px' width='150px' variant='outlined' color='#009EFF' onClick={() => replaceAllCamerasName()}>
                Aplicar
              </Button>
            </Box>
          </Box>

          <Box align='center' justifyContent='center'>
            <Typography variant='span' size='16px' style={{fontWeight: 500}}>
              Canais
            </Typography>
            <Typography variant='p' size='12px' color='#8E8E8E'>
              Edite e teste abaixo todas as câmeras
            </Typography>

            <Box display='flex' direction='row'>
              <Box width='100%'>
                <ContainerChannelsTitles>
                  <div></div>
                  <ChannelsTitles fontWeight={700}>Nome da câmera</ChannelsTitles>
                  {!chosen.createRTSPWithLink ? <ChannelsTitles fontWeight={700}>Stream</ChannelsTitles> : <div></div>}
                  <div></div>
                </ContainerChannelsTitles>

                <ContainerCameras>
                  {cameras.map((it, index) => {
                    let sumIndex = index + 1
                    return (
                      <ContainerChannels>
                        <div>
                          <Checkbox checked={it.checkbox} onChange={() => replaceOneCheckbox(index)} />
                        </div>

                        <Input.Root
                          maxLength={50}
                          labelFontWeight='400'
                          placeholder={`${it.name}`}
                          value={it.name}
                          disabled={!it.checkbox}
                          onChange={(e) => replaceOneName(index, e.target.value)}
                        />

                        {!chosen.createRTSPWithLink && (
                          <Dropdown
                            selected={it.chosedStream}
                            disabled={!it.checkbox}
                            items={streamItems}
                            onChangeSelected={(e) => replaceOneStream(index, e)}
                            height='40px'
                          />
                        )}

                        <PlayButton
                          color='#009EFF'
                          border='#009EFF'
                          disabled={!it.checkbox || isLoadingStream}
                          onClick={() => {
                            setChosenCameraNameTest(it.name)
                            handleOpenStreamTest(sumIndex)
                          }}>
                          <PlayIconChangeColor color={!it.checkbox || isLoadingStream ? 'gray' : '#009EFF'} />
                        </PlayButton>
                      </ContainerChannels>
                    )
                  })}
                </ContainerCameras>
              </Box>

              {isLoadingStream && (
                <ContainerLoading>
                  <Loading />
                </ContainerLoading>
              )}

              {chosenCameraNameTest && hashCode && !isLoadingStream && (
                <ContainerCamerasVisualization>
                  <div style={{marginLeft: '20px', display: 'flex', position: 'relative'}}>
                    <ChannelsTitles fontWeight={700}>Visualização de câmera</ChannelsTitles>
                    <CloseButton
                      onClick={() => {
                        setHashCode(null)
                        setChosenCameraNameTest('')
                      }}>
                      <img src={CloseIcon} alt='close' />
                    </CloseButton>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                    <ChannelsTitles fontWeight={500}>{chosenCameraNameTest}</ChannelsTitles>
                    <CameraRtspLiveCamTest hash={hashCode} />
                  </div>
                </ContainerCamerasVisualization>
              )}
            </Box>
          </Box>
        </Box>
      </ContainerStepsRow>

      <StepperModalActionsButtonContainer>
        <Button
          fontSize='12px'
          height='40px'
          width='150px'
          variant='outlined'
          color='#8E8E8E'
          onClick={() => {
            returnFunction(1)
          }}>
          Voltar
        </Button>
        <Button
          fontSize='12px'
          disabledTextColor='#8E8E8E'
          height='40px'
          width='150px'
          onClick={() => {
            nextFunction()
          }}
          isLoading={isLoading}
          disabled={disableButton}>
          Finalizar
        </Button>
      </StepperModalActionsButtonContainer>
    </ContainerSteps>
  )
}

export default ConfigurationChannelsStep
