import {Box} from '@mui/material'
import {DataGrid, ptBR} from '@mui/x-data-grid'
import createStyleSheet from 'src/utilities/createStyleSheet'
import CustomPagination from '../../../components/CustomPagination'

import CustomToolbar from '../../../components/CustomToolbar'
import {useTransactionsContext} from '../contexts/TransactionsContext'

import TransactionsModal from './TransactionsModal'

function TransactionsGridTable() {
  const {loadingTransactions, transactionsPage, transactionsTotalCount, transactionsPageChange, openTransactionModal, rows, columns} = useTransactionsContext()

  return (
    <Box style={styles.gridItem}>
      <DataGrid
        rows={rows}
        columns={columns}
        density='compact'
        paginationMode='server'
        loading={loadingTransactions}
        rowCount={transactionsTotalCount}
        slots={{toolbar: CustomToolbar, pagination: CustomPagination}}
        slotProps={{
          pagination: {
            page: transactionsPage.page,
            count: transactionsTotalCount,
            onChange: (page: any) => {
              transactionsPageChange(page)
            },
          },
        }}
        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        components={{Toolbar: CustomToolbar, Pagination: CustomPagination}}
        disableRowSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        onRowClick={(params) => {
          openTransactionModal(params.row.id)
        }}
      />
      <TransactionsModal />
    </Box>
  )
}
const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'row',
    height: '78vh',
    padding: '20px',
  },
})

export default TransactionsGridTable
