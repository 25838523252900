import styled from 'styled-components'

export const ReloadButtonCotainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #98a2b3;
  background: #fff;
  width: 270px;
`

export const ReloadButtonStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-width: 40px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  background: transparent;
  text-transform: initial;
  font-family: 'Inter; sans-serif';
  font-size: 0.875em;
  font-weight: 500;
  font-style: normal;
  transition: 0.3s;
  :hover {
    background-color: rgba(0, 158, 255, 0.05);
  }
`
