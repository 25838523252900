import {Tooltip} from '@mui/material'
import {useEffect, useRef, useState} from 'react'
import {Company} from 'src/services/api'
import iconExpirationCompany from 'src/images/expirationCompanyIcon.svg'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import moment from 'moment'
import {useCompany} from 'src/contexts/CompanyContext'
import {useSnackbar} from 'notistack'
import {useApp} from '../../contexts/AppContext'
import {Box, Button} from '@viptech/react-components'
import {BoxHeaderWrapper, ExpiredChip} from '../HeaderBase'
import {capitalize} from 'lodash'
import Menu from 'src/components/menu/Menu'

export function CompaniesExpirationDateMenu() {
  const [showComponent, setShowComponent] = useState<boolean>(false)

  const [companiesData, setCompaniesData] = useState<Company[]>([])
  const [anchorExpirationDate, setAnchorExpirationDate] = useState<null | HTMLElement>(null)
  const blockCompaniesRequests = useRef(false)
  const {companies} = useCompany()
  const {enqueueSnackbar} = useSnackbar()
  const {updateExpirationDate} = useApp()

  const handleClickMenuExpirationDate = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorExpirationDate(event.currentTarget)
  }

  async function loadDateValidation() {
    try {
      if (blockCompaniesRequests.current) return
      blockCompaniesRequests.current = true
      const companiesData = companies.map((it) => {
        const numDays = dateDiff(String(it.expirationDate))
        if (numDays <= 15) setShowComponent(true)

        return {
          id: it.id,
          name: it.name,
          expirationDate: numDays.toString(),
        }
      }) as Company[]
      setCompaniesData(companiesData)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar tempo de expiração')
    } finally {
      blockCompaniesRequests.current = false
    }
  }

  const dateDiff = (inExpiration: string) => {
    const days = moment().format('YYYY-MM-DD')
    const numDays = moment(inExpiration).diff(days, 'day')
    return numDays
  }

  function renderChip(days: number | null, name: string) {
    if (days === null) return null
    if (days === 0)
      return (
        <Box width='300px'>
          <span>{capitalize(name)}</span> -<ExpiredChip color='#FF7c4A'> Expira Hoje</ExpiredChip>
        </Box>
      )
    else if (days <= 15 && days > 0)
      return (
        <Box width='300px'>
          <span>{capitalize(name)}</span> - <ExpiredChip color='#FFA500'>{` Expira em ${days} di${days === 1 ? 'a' : 'as'}`}</ExpiredChip>
        </Box>
      )
    else if (days < 0)
      return (
        <Box width='300px'>
          <span>{capitalize(name)}</span> - <ExpiredChip color='#FF4347'>{` Expirado há ${days * -1} di${days === -1 ? 'a' : 'as'}`}</ExpiredChip>
        </Box>
      )

    return null
  }

  useEffect(() => {
    loadDateValidation()
  }, [companies, updateExpirationDate])

  return (
    <BoxHeaderWrapper>
      {showComponent && companiesData.length > 0 && (
        <>
          <Tooltip title={'Visualizar datas de expiração'}>
            <Button variant='outlined' color='transparent' hoverColor='transparent' padding='0px' onClick={handleClickMenuExpirationDate}>
              <img src={iconExpirationCompany} alt='Data de expiração das empresas' />
            </Button>
          </Tooltip>

          <Menu anchor={anchorExpirationDate} onClose={() => setAnchorExpirationDate(null)} borderRadius='8px'>
            <Box padding={'20px'} display='flex' direction='column' rowGap='15px' width='400px' overflow='auto' maxHeight='500px'>
              {companiesData.map((company) => {
                const expirationDate = Number(company.expirationDate ?? null)

                if (renderChip(expirationDate, company.name) !== null)
                  return (
                    <Box width='100%' display='inline-flex' columnGap='10px' key={company.id} onClick={() => setAnchorExpirationDate(null)}>
                      {renderChip(expirationDate, company.name)}
                    </Box>
                  )
              })}
            </Box>
          </Menu>
        </>
      )}
    </BoxHeaderWrapper>
  )
}
