import {Paper, Switch, alpha, styled} from '@mui/material'
import {common, green} from '@mui/material/colors'

export const ContainerBoxes = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: 14,
  width: '100%',
  color: theme.palette.text.secondary,
}))

export const GreenSwitch = styled(Switch)(({theme}) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: common.white,
    '&:hover': {
      backgroundColor: alpha(common.white, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[900],
  },
}))
