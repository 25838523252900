import {Box, Typography} from '@mui/material'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import FacialRecoginitonLinedUpIcon from 'src/images/facialRecognitionLinedUpIcon.svg'
import FacialRecoginitonPerimeterIcon from 'src/images/facialRecognitionPerimeterIcon.svg'
import FacialRecognitionNotAllowedIco from 'src/images/facialRecognitionNotAllowedIcon.svg'

export const InfoFacialRecognition = () => {
  return (
    <>
      <Box display={'flex'} flexDirection={'row'} columnGap={'16px'} padding={'8px 0px'}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <Icon src={FacialRecoginitonLinedUpIcon} alt='Foto alinhada' />
          <Typography fontSize={'12px'} fontWeight={400} textAlign={'center'} paddingTop={'8px'} lineHeight={'15px'}>
            Mantenha sempre a cabeça alinhada, olhando para a câmera e centralizada.
          </Typography>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <Icon src={FacialRecoginitonPerimeterIcon} alt='Foto dentro do perimetro' />
          <Typography fontSize={'12px'} fontWeight={400} textAlign={'center'} paddingTop={'8px'} lineHeight={'15px'}>
            Mantenha uma boa distância da câmera. Não muito perto e nem muito longe.
          </Typography>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
          <Icon src={FacialRecognitionNotAllowedIco} alt='Foto não permite items sobre o rosto' />
          <Typography fontSize={'12px'} fontWeight={400} textAlign={'center'} paddingTop={'8px'} lineHeight={'15px'}>
            Não utilize nenhum acessório no rosto que dificulte a visualização da face.
          </Typography>
        </Box>
      </Box>
      <Typography fontSize={'11px'} fontWeight={500} color={'#8E8E8E'} lineHeight={'16px'}>
        Os requisitos da foto de rosto devem seguir as seguintes regras: Ser tirada em visão completa, de frente para a câmera. Não use chapéu ou acessórios ao
        tirar a foto do rosto. O formato deve ser JPEG ou JPG. A resolução deve ser de 150 × 300 pixels ou mais. O tamanho da imagem deve ser entre 10KB e
        300KB.
      </Typography>
    </>
  )
}
