export const detections = [
  'Pessoa',
  'Reconhecimento Facial',
  'Leitura de Placas',
  'Mudança de Cenário',
  'Aglomeração',
  'Veículos',
  'Eletrônicos',
  'Animais',
  'Objetos Pessoais',
  'Informática',
  'Armas',
  "EPI's",
  'Utensílios',
  'Relatório de Contagem',
]

export const detectionTypeToSearchObjectType = ['Mudança de Cenário', 'Reconhecimento Facial', 'Leitura de Placas', 'Aglomeração', 'Pessoa', 'Utensílios']

export const detectionDisableLineCrossing = ['Mudança de Cenário', 'Aglomeração', 'Reconhecimento Facial', 'Leitura de Placas', 'Placa']

export const firstStepResourceIdSet = ['Pessoa', 'Reconhecimento Facial', 'Leitura de Placas', 'Mudança de Cenário', 'Aglomeração']

export const skipSecondStep = ['Relatório de Contagem']

export const skipThirdStep = ['Mudança de Cenário', 'Facial', 'Placa', 'Leitura de Placas', 'Reconhecimento Facial']

export const skipFourthStep = ['Relatório de Contagem']
