import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, Checkbox, FormControlLabel, Typography} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {Params, useNavigate, useParams} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {SidebarOption, initialSidebarOptions} from 'src/components/ListCheckboxToPermissions'
import Loading from 'src/components/Loading'
import {useAuth} from 'src/contexts/AuthContext'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api, {User} from 'src/services/api'
import {AxiosErrorGeneric} from 'src/utilities/RequestError'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

const selfManagedRoles = ['admin', 'manager']

const UserRegisterPermissions = () => {
  const [userOptions, setUserOptions] = useState<Array<SidebarOption>>(
    JSON.parse(JSON.stringify(initialSidebarOptions.filter((option) => option.scope === 'company'))),
  )
  const [globalOptions, setGlobalOptions] = useState<Array<SidebarOption>>(
    JSON.parse(JSON.stringify(initialSidebarOptions.filter((option) => option.scope === 'global'))),
  )
  const {user} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {id: maybeId} = useParams<Params>()
  const [listPermissions, setListPermissions] = useState<string[]>([])
  const [loadingPermissions, setLoadingPermissions] = useState<boolean>(false)
  const hasPermissionReadCompanyPermissions = ValidatePermissions('companies:read')
  const hasPermissionUpdateUserPermissions = ValidatePermissions('users:update')
  const initialUser = {
    name: '',
    email: '',
    id: Number(maybeId),
    role: user!.role,
    company: user!.company,
    forcePasswordUpdate: false,
    permissions: [],
    authorizedCompanies: [],
    authorizedClients: [],
    password: '',
  }
  const [userSelected, setUserSelected] = useState<User>(initialUser)
  const [notSelfManaged, setNotSelfManaged] = useState<boolean>(false)
  const [isLowestRole, setIsLowestRole] = useState(false)
  const navigate = useNavigate()
  const {sidebarTitle, setSidebarTitle} = useApp()
  const [isAllChecked, setIsAllChecked] = useState(false)
  const loggedUserPermissions = user?.permissions
  const [errorMessage, setErrorMessage] = useState<string>('')
  const blockPossiblePermissionsRequests = useRef(false)
  const blockUserHasPermissionsRequests = useRef(false)
  const setter = {
    company: setUserOptions,
    global: setGlobalOptions,
  }

  async function updatePermissions(list: string[], listRemove: string[]) {
    try {
      const permissionsToAdd = list.filter((permission) => !userSelected.permissions.includes(permission))
      const permissionsToRemove = userSelected.permissions.filter((permission) => listRemove.includes(permission))
      if (permissionsToAdd.length > 0) await api.users.addPermissions(userSelected.id, {permissions: permissionsToAdd})
      if (permissionsToRemove.length > 0) await api.users.deletePermissions(userSelected.id, {permissions: permissionsToRemove})
      enqueueSnackbar('Permissões atualizadas com sucesso', {
        variant: 'success',
      })
      navigate('/registers/users')
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar permissões do usuário')
    }
  }

  useEffect(() => {
    const id = Number(maybeId)
    if (!id || isNaN(id)) {
      navigate('registers/users')
    } else {
      if (userSelected.name) {
        const title = `${sidebarTitle} > ${userSelected && userSelected.name}`
        setSidebarTitle(title)
      }
    }
    if (!hasPermissionReadCompanyPermissions && !hasPermissionUpdateUserPermissions) {
      enqueueSnackbar('Você não tem permissão para editar esse usuário', {
        variant: 'warning',
      })
    }
  }, [userSelected, maybeId])

  useEffect(() => {
    if (!userSelected) return
    if (!hasPermissionReadCompanyPermissions && !hasPermissionUpdateUserPermissions) return
    async function getAllAuthorizedCompaniesPermissions() {
      if (blockPossiblePermissionsRequests.current) return
      blockPossiblePermissionsRequests.current = true
      setLoadingPermissions(true)
      try {
        const response = await api.users.getAllAuthorizedCompaniesPermissions(userSelected.id)
        setListPermissions(response.data.data)
      } catch (error) {
        const axiosError = error as AxiosErrorGeneric
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar lista de permissões')
        setErrorMessage(axiosError.response?.data.message)
      } finally {
        setLoadingPermissions(false)
        blockPossiblePermissionsRequests.current = false
      }
    }
    getAllAuthorizedCompaniesPermissions()
  }, [userSelected])

  useEffect(() => {
    if (!hasPermissionReadCompanyPermissions && !hasPermissionUpdateUserPermissions) return
    async function getAllPermissionsUser() {
      try {
        if (blockUserHasPermissionsRequests.current) return
        blockUserHasPermissionsRequests.current = true
        if (userSelected?.id !== user?.id) {
          const response = await api.users.getAll({
            filter: {
              ids: [userSelected?.id],
            },
            includes: ['permissions'],
          })
          const responseRoles = await api.users.getAllRoles()
          const user = response.data.data.entities[0]
          setUserSelected(user)
          setNotSelfManaged(!selfManagedRoles.includes(user.role))
          const lowestRolePriority = responseRoles.data.data.sort((roleA, roleB) => {
            if (roleA.priority > roleB.priority) return 1
            return -1
          })[0]
          setIsLowestRole(user.role === lowestRolePriority.name)
        } else {
          setUserSelected(user)
        }
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar permissões do usuário')
      } finally {
        blockUserHasPermissionsRequests.current = false
      }
    }
    getAllPermissionsUser()
  }, [])

  useEffect(() => {
    setUserOptions((prev) => {
      prev.forEach((option) => {
        option.crudItems.forEach((crudItem) => {
          if (crudItem.permission && userSelected.permissions.includes(crudItem.permission)) {
            crudItem.value = true
          }
        })
      })
      return [...prev]
    })
    setGlobalOptions((prev) => {
      prev.forEach((option) => {
        option.crudItems.forEach((crudItem) => {
          if (crudItem.permission && userSelected.permissions.includes(crudItem.permission)) {
            crudItem.value = true
          }
        })
      })
      return [...prev]
    })
  }, [userSelected])

  function getGlobalEntries() {
    if (isLowestRole) {
      return globalOptions.filter((entry) => loggedUserPermissions.includes(entry.crudItems[0].permission)).filter((permission) => permission.label !== 'users')
    }
    return globalOptions.filter((entry) => loggedUserPermissions.includes(entry.crudItems[0].permission))
  }

  const filteredUserEntries = userOptions
    .filter((entry) => listPermissions.some((permission) => permission.startsWith(`${entry.label}:`)))
    .filter((entry) => loggedUserPermissions.includes(entry.crudItems[0].permission))
  const filteredGlobalEntries = getGlobalEntries()

  useEffect(() => {
    filteredUserEntries.forEach((entry) => {
      const crudItems = entry.crudItems.filter((item) => listPermissions.some((permission) => permission === item.permission))
      entry.crudItems = crudItems
    })
  }, [filteredUserEntries])

  const handleVisualizationChange = (optionIndex: number, updatedOptions: SidebarOption[]) => {
    return updatedOptions.map((option, i) =>
      i === optionIndex
        ? {
            ...option,
            crudItems: option.crudItems.map((item) => ({...item, value: false})),
          }
        : option,
    )
  }

  const handleManageChange = (optionIndex: number, updatedOptions: SidebarOption[]) => {
    return updatedOptions.map((option, i) =>
      i === optionIndex
        ? {
            ...option,
            crudItems: option.crudItems.map((item, j) => {
              if (j === 3) item.value = false
              return item
            }),
          }
        : option,
    )
  }

  const handlePermissionChange = (optionIndex: number, itemIndex: number, scope: 'company' | 'global') => (event: React.ChangeEvent<HTMLInputElement>) => {
    let updatedOptions
    if (scope === 'company') {
      updatedOptions = [...filteredUserEntries]
    } else {
      updatedOptions = [...filteredGlobalEntries]
    }
    if (itemIndex === 1) {
      const newValue = !updatedOptions[optionIndex].crudItems[1].value
      updatedOptions[optionIndex].crudItems.forEach((item, i) => {
        item.value = newValue
      })
    } else {
      const newValue = !updatedOptions[optionIndex].crudItems[0].value
      if (updatedOptions[optionIndex].crudItems.length > 4) {
        updatedOptions[optionIndex].crudItems.forEach((item, i) => {
          if (item.permission.split(':')[1] === 'read') item.value = newValue
        })
      } else {
        updatedOptions[optionIndex].crudItems[0].value = newValue
      }
    }
    setter[scope](updatedOptions)
    if (itemIndex === 0 && !updatedOptions[optionIndex].crudItems[itemIndex].value) setter[scope](handleVisualizationChange(optionIndex, updatedOptions))
    else if (itemIndex === 1 && !updatedOptions[optionIndex].crudItems[itemIndex].value) setter[scope](handleManageChange(optionIndex, updatedOptions))
  }

  useEffect(() => {
    checkIfAllChecked()
  }, [handlePermissionChange])

  const checkAllForScope = (scope: 'company' | 'global', isChecked: boolean) => {
    let updatedOptions
    if (scope === 'company') {
      updatedOptions = [...filteredUserEntries]
    } else {
      updatedOptions = [...filteredGlobalEntries]
    }
    updatedOptions.forEach((option) => {
      option.crudItems.forEach((item) => {
        if (item.permission) {
          item.value = isChecked
        }
      })
    })

    setter[scope](updatedOptions)
  }

  const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked
    if (notSelfManaged) {
      checkAllForScope('company', isChecked)
      checkAllForScope('global', isChecked)
      return setIsAllChecked(isChecked)
    }
    checkAllForScope('global', isChecked)
    setIsAllChecked(isChecked)
  }

  const checkIfAllChecked = () => {
    const allCheckedGlobal = filteredGlobalEntries.every((option) => {
      if (option.crudItems.length > 1) {
        return option.crudItems[1].value
      }
      return option.crudItems[0].value
    })
    let allChecked = allCheckedGlobal
    let allCheckedUser = filteredUserEntries.every((option) => option.crudItems.every((item) => item.value))
    if (notSelfManaged) allChecked = allCheckedGlobal && allCheckedUser
    setIsAllChecked(allChecked)
  }

  const handleSavePermissions = () => {
    let filterPermissions: string[] = []
    const globalPermissions = filteredGlobalEntries.flatMap((option) => option.crudItems.filter((item) => item.value).flatMap((item) => item.permission))
    const globalPermissionsUnchecked = filteredGlobalEntries.flatMap((option) =>
      option.crudItems.filter((item) => !item.value).flatMap((item) => item.permission),
    )
    if (notSelfManaged) {
      filterPermissions = userOptions.flatMap((option) => option.crudItems.filter((item) => item.value).flatMap((item) => item.permission))
    }
    filterPermissions.push(...globalPermissions)
    const itemsToRemove = userSelected.permissions.filter((permission) => !filterPermissions.includes(permission))
    const listRemove = notSelfManaged ? itemsToRemove : globalPermissionsUnchecked
    updatePermissions(filterPermissions, listRemove)
    checkIfAllChecked()
  }

  if (loadingPermissions) {
    return (
      <Box sx={styles.loadingPaper}>
        <Loading />
      </Box>
    )
  }

  return (
    <Box sx={styles.paper}>
      <Box sx={styles.contentPermissions}>
        {listPermissions.length === 0 ? (
          <Box sx={styles.notPermissions}>{errorMessage}</Box>
        ) : (
          <Box sx={styles.boxWithContent}>
            <Box sx={styles.listPermissions}>
              <Typography variant='h4' sx={styles.title}>
                Definir permissões
              </Typography>
              <FormControlLabel
                sx={styles.checkboxs}
                disabled={user?.id === userSelected.id}
                control={<Checkbox checked={isAllChecked} onChange={handleCheckAllChange} sx={{marginBottom: 0.5}} />}
                label={'Marcar tudo'}
              />

              <>
                <Typography variant='h6' sx={{fontWeight: 'bold', fontSize: '1.3rem', paddingBottom: '10px'}}>
                  Permissões globais
                </Typography>

                <Box sx={styles.listContainer}>
                  {filteredGlobalEntries.map((option, i) => (
                    <Box key={i} sx={styles.itemContainer}>
                      <Box sx={styles.labelContainer}>
                        <h6 style={{marginBottom: '0.5rem'}}>{option.name}</h6>
                      </Box>
                      <FormControlLabel
                        sx={styles.itemsCheck}
                        key={0}
                        disabled={user?.id === userSelected.id}
                        control={<Checkbox checked={option.crudItems[0].value} onChange={handlePermissionChange(i, 0, 'global')} sx={{marginBottom: 0.5}} />}
                        label={option.crudItems[0].name}
                      />
                      {option.crudItems.length > 1 && (
                        <FormControlLabel
                          sx={styles.itemsCheck}
                          key={1}
                          disabled={user?.id === userSelected.id}
                          control={
                            <Checkbox
                              checked={option.crudItems.every((perm) => perm.value === true)}
                              onChange={handlePermissionChange(i, 1, 'global')}
                              sx={{marginBottom: 0.5}}
                            />
                          }
                          label='Gerenciar'
                        />
                      )}
                    </Box>
                  ))}
                </Box>
                <Typography variant='h6' sx={{fontWeight: 'bold', fontSize: '1.3rem', paddingBottom: '10px', paddingTop: '10px'}}>
                  Permissões da empresa
                </Typography>

                <Box sx={styles.listContainer}>
                  {filteredUserEntries.map((option, i) => (
                    <Box key={i} sx={styles.itemContainer}>
                      <Box sx={styles.labelContainer}>
                        <h6 style={{marginBottom: '0.5rem'}}>{option.name}</h6>
                      </Box>
                      <FormControlLabel
                        sx={styles.itemsCheck}
                        key={0}
                        disabled={user?.id === userSelected.id || userSelected.role === 'manager'}
                        control={
                          <Checkbox
                            checked={option.crudItems[0].value}
                            onChange={handlePermissionChange(i, 0, 'company')}
                            sx={{marginBottom: 0.5}}
                            disabled={userSelected.role === 'manager'}
                          />
                        }
                        label={option.crudItems[0].name}
                      />
                      {option.crudItems.length > 1 && (
                        <FormControlLabel
                          sx={styles.itemsCheck}
                          key={1}
                          disabled={user?.id === userSelected.id || userSelected.role === 'manager'}
                          control={
                            <Checkbox
                              checked={option.crudItems.every((perm) => perm.value === true)}
                              onChange={handlePermissionChange(i, 1, 'company')}
                              sx={{marginBottom: 0.5}}
                              disabled={userSelected.role === 'manager'}
                            />
                          }
                          label='Gerenciar'
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            </Box>

            <Box sx={styles.boxButtons}>
              <Button onClick={() => navigate('/registers/users')} sx={styles.cancelButton}>
                Cancelar
              </Button>
              <LoadingButton disabled={user?.id === userSelected.id} variant='contained' onClick={() => handleSavePermissions()} sx={styles.addButton}>
                Salvar
              </LoadingButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default UserRegisterPermissions
const styles = createStyleSheet({
  paper: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    width: '100%',
    maxHeight: '85vh',
    borderTop: '5px solid #FFFFFF',
    borderBottom: '25px solid #FFFFFF',
  },
  addButton: {
    color: '#FFFFFF',
    width: '180px',
    borderRadius: '4px',
    fontFamily: 'Inter',
    padding: '0.5rem 1rem',
    cursor: 'pointer',
    textTransform: 'none',
  },
  cancelButton: {
    width: '180px',
    height: '40px',
    marginRight: 1,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  loadingPaper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: '10px 30px',
    height: '86vh',
    overflow: 'scroll',
  },
  title: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    padding: '1rem 0 0 0',
    zIndex: 1,
  },
  notPermissions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    background: 'white',
    padding: '10px 30px',
    height: '86vh',
    overflow: 'scroll',
  },
  boxButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: '1rem',
  },
  contentPermissions: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '0rem 1.5rem',
  },
  boxWithContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80vh',
  },
  listPermissions: {
    overflowY: 'auto',
    height: '90%',
  },
  checkboxs: {
    width: '99%',
    marginBottom: '0.5rem',
    position: 'sticky',
    backgroundColor: '#FFFFFF',
    top: 46,
    zIndex: 1,
  },
  itemsCheck: {
    width: '130px',
  },
  labelContainer: {
    height: '50px',
  },
  listContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flexGrow: 1,
    flexBasis: 'auto',
    gap: '20px',
    overflowY: 'hidden',
  },
  itemContainer: {
    overflow: 'hidden',
    padding: '1rem 1rem',
    border: '1px solid #E0E0E0',
    borderRadius: '4px',
    width: '200px',
  },
})
