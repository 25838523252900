import { createTheme } from '@mui/material'
import { DefaultTheme } from 'styled-components'

export const StyledTheme: DefaultTheme = {
  '*': {
    fontFamily: 'Inter, sans-serif',
  },
  skeleton: {
    backgroundColor: '#e9e8e9',
  },
  fonts: {
    primary: 'Inter, sans-serif',
  },
  colors: {
    text: '#364152',
    primary: '#258BE3',
    secondary: '#364152',
    required: '#FF0000',
    placeholder: '#B9B9B9',
    focus: '#258BE3',
    success: '#4A934A',
    error: '#F04438',
    disabled: '#B9B9B9',
    disabledBackground: '#F5F5F5',
    white: '#FFFFFF',
    black: '#000000',
  },
  hoverColors: {
    primary: '#0F4D80',
    secondary: '#28303B',
    error: '#B42318',
    success: '#376E37',
  },
  input: {
    height: '40px',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    labelFontSize: '14px',
    labelFontWeight: 500,
    labelFontFamily: 'Inter, sans-serif',
    borderColor: '#8E8E8E',
    paddingVertical: '8px',
    paddingHorizontal: '14px',
    fontFamily: 'Inter, sans-serif',
    labelColor: '#364152',
    backgroundColor: '#FFFFFF',
  },
  colorPicker: {
    backgroundColor: '#FFFFFF',
    borderRadius: '8px',
    boxShadow: '1px 1px 1px 1px #000000',
    colorsBoxShadow: ' 1px 2px 5px #000000',
    colorsWidth: '1.35rem',
    colorsHeight: '1.35rem',
    colorsBorderRadius: '3px',
  },
  calendar: {
    color: '#344054',
    fontSize: '14px',
    fontWeight: 500,
    hoverColor: '#000',
    disabledColor: '#B9B9B9',
    headerFontWeight: 700,
    headerFontSize: '16px',
    backgroundColor: 'transparent',
    selectedDayColor: '#fff',
    actualDateColor: '#009EFF',
    actualDateBorder: 'transparent',
    navigationArrowsColor: '#fff',
    notActualMonthColor: 'gray',
    fontFamily: 'Inter, sans-serif',
    disabledBackgroundColor: 'transparent',
    actualDateBackgroundColor: 'transparent',
    hoverBackgroundColor: '#E7EFFF',
    notActualMonthBackgroundColor: 'transparent',
    selectedDayBackgroundColor: '#009EFF',
    headerFontFamily: 'Inter, sans-serif',
    navigationArrowsBackgroundColor: '#fff',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    padding: '1rem',
  },
  checkbox: {
    size: '18px',
    gap: '8px',
    borderRadius: '4px',
    marginVertical: '8px',
    labelColor: '#364152',
  },
  button: {
    fontSize: '16px',
    boxShadow: 'none',
    borderRadius: '8px',
  },
  dropdown: {
    fontSize: '14px',
    fontWeight: 500,
    scrollbarWidth: '12px',
    paddingVertical: '8px',
    itemHoverColor: '#f5f5f5',
    paddingHorizontal: '14px',
    backgroundColor: '#FFFFFF',
    scrollbarBackground: '#8E8E8E',
  },
  modal: {
    borderRadius: '8px',
    backgroundColor: '#8E8E8E',
    boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.5)',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    marginBottom: '0px',
    marginTop: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    borderColor: '#8E8E8E',
    padding: '0px',
    margin: '0px',
    border: '1px solid #8E8E8E',
    bgBackgroundColor: 'rgba(0,0,0,0.7)',
  },
  snackbar: {
    errorStyle: {
      color: '#000',
      iconColor: '#FF6363',
      backgroundColor: '#fff',
    },
    warningStyle: {
      color: '#000',
      iconColor: '#ff9800',
      backgroundColor: '#fff',
    },
    successStyle: {
      color: '#000',
      iconColor: '#54B45C',
      backgroundColor: '#fff',
    },
    infoStyle: {
      color: '#000',
      iconColor: '#2196f3',
      backgroundColor: '#fff',
    },
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    height: '4rem',
  },
  table: {
    tableHeader: {
      padding: '0px',
      fontWeight: 400,
      color: '#364152',
      fontSize: '14px',
      height: '3rem',
      textAlign: 'start',
      borderColor: '#6D7392',
      backgroundColor: '#FFFFFF',
      fontFamily: 'Inter, sans-serif',
      borderBottom: '2px solid #6D7392',
    },
    tableBody: {
      padding: '0px',
      fontWeight: 100,
      color: '#000',
      fontSize: '14px',
      textAlign: 'start',
      rowHeight: '2.5rem',
      borderColor: '#B9B9B9',
      backgroundColor: 'inherit',
      fontFamily: 'Inter, sans-serif',
      borderBottom: '1px solid #B9B9B9',
    },
    tableAdvancedFilter: {
      backgroundColor: '#F5F5F5',
      borderColor: '#F5F5F5',
      width: '95%',
      height: '2.5rem',
    },
  },
  textArea: {
    height: '40px',
    fontSize: '14px',
    fontWeight: 500,
    borderRadius: '8px',
    borderColor: '#8E8E8E',
    paddingVertical: '8px',
    paddingHorizontal: '14px',
    fontFamily: 'Inter, sans-serif',
    backgroundColor: '#FFFFFF',
  },
  stepper: {
    concludedColor: '#009D10',
    activedColor: '#009EFF',
    pendingColor: '#D6E2F3',
  },
  toggle: {
    backgroundColor: '#B9B9B9',
    disabledKnob: '#F5F5F5',
  },
  tr: {
    borderBottom: '1px solid #B9B9B9',
  },
  accordion: {
    hoverBackgroundColor: '#F5F5F5',
  },
  tooltip: {
    backgroundColor: '#364152',
    borderRadius: '8px',
    width: 'auto',
    height: 'auto',
    margin: '0px',
    padding: '2px 10px',
    fontSize: '14px',
    color: '#FFFFFF',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    position: 'top',
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
    paddingTop: '0px',
    paddingBottom: '0px',
    paddingLeft: '0px',
    paddingRight: '0px',
    border: '1px solid #364152',
    opacity: '1',
    left: '0px',
    top: '0px',
    pointerEvents: 'none',
    transform: 'none',
  },
}

export const muiTheme = createTheme({
  palette: {
    primary: {
      main: '#009EFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          color: 'white',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: 'white',
        },
      },
    },
  },
})
