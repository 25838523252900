import { SVGIconPath, SVGIconWrapper } from 'src/common/generics/components/svgIcon/SVGIconBase'
import { IconProps } from 'src/common/generics/props/IconProps'

function AttachFileIcon(iconProps: IconProps) {
  return (
    <SVGIconWrapper
    width="18px" 
    height="15px" 
    viewBox="0 0 18 15" 
    fill="none"
      xmlns='http://www.w3.org/2000/svg'
      {...iconProps}
      onClick={iconProps.onClick}
    >
      <SVGIconPath
        id='b27d3b9b8fc9fa9896dc6ca08ec8db7e'
        d='M10.1276 9.51649L11.6667 11.0556M9.05577 2.22244L8.94423 2.1109C8.63676 1.80343 8.48303 1.64969 8.30362 1.53975C8.14456 1.44228 7.97114 1.37045 7.78975 1.3269C7.58515 1.27778 7.36773 1.27778 6.9329 1.27778H3.84444C2.8488 1.27778 2.35097 1.27778 1.97068 1.47154C1.63617 1.64199 1.36421 1.91395 1.19377 2.24846C1 2.62875 1 3.12657 1 4.12222V10.8778C1 11.8734 1 12.3713 1.19377 12.7515C1.36421 13.086 1.63617 13.358 1.97068 13.5285C2.35097 13.7222 2.8488 13.7222 3.84444 13.7222H14.1556C15.1512 13.7222 15.649 13.7222 16.0293 13.5285C16.3638 13.358 16.6358 13.086 16.8062 12.7515C17 12.3713 17 11.8734 17 10.8778V5.9C17 4.90435 17 4.40653 16.8062 4.02624C16.6358 3.69173 16.3638 3.41976 16.0293 3.24932C15.649 3.05556 15.1512 3.05556 14.1556 3.05556H11.0671C10.6323 3.05556 10.4149 3.05556 10.2103 3.00644C10.0289 2.96289 9.85544 2.89106 9.69638 2.79358C9.51698 2.68364 9.36324 2.52991 9.05577 2.22244ZM10.7778 7.94445C10.7778 9.17174 9.78286 10.1667 8.55556 10.1667C7.32826 10.1667 6.33333 9.17174 6.33333 7.94445C6.33333 6.71715 7.32826 5.72222 8.55556 5.72222C9.78286 5.72222 10.7778 6.71715 10.7778 7.94445Z'
        fill='white'
        stroke="white" 
        stroke-width="1.5" 
        stroke-linecap="round" 
        stroke-linejoin="round"
        {...iconProps}
      />
    </SVGIconWrapper>
  )
}

export default AttachFileIcon
