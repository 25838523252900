import {Box, CircularProgress} from '@mui/material'
import {useCompany} from '../../contexts/CompanyContext'
import Dashboard from '../dashboard/Dashboard'
import DashRetail from './DashboardRetail'

function RedirectToDashboard() {
  const {isLoading, checkRetailDashboardAccess} = useCompany()
  const isRetail = checkRetailDashboardAccess()

  return (
    <>
      {isLoading ? (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
          <CircularProgress size={100} />
        </Box>
      ) : isRetail ? (
        <DashRetail />
      ) : (
        <Dashboard />
      )}
    </>
  )
}
export default RedirectToDashboard
