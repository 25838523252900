import {Box, Button} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import FirstStepIllust from 'src/images/IconFirstStep.svg'
import SecondStepIllust from 'src/images/IconSecondStep.svg'
import {useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import api from 'src/services/api'
import {FormContainer, Image, MessageLabel, MessageLabelLink, RegistrationContainer, Subtitle, Title} from './components'
import FirstFormPage from './components/FirstFormPage'
import SecondFormPage from './components/SecondFormPage'
import {useQueryParams} from 'src/hooks/useQuery'
import {GoBackButton, TitleOnGoBackButton} from '../select-plan/components/PlanSelectBase'
import ArrowLeft from 'src/images/goBackArrow.svg'

function B2CIndex() {
  const {registerData, setRegisterData} = useB2CContext()
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const {enqueueSnackbar} = useSnackbar()
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const isPerson = registerData.type === 'person'
  const [disabledButton, setDisabledButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const query = useQueryParams()
  const queryType = query.get('page')
  if (queryType) setPage(+queryType)

  async function handleClickNext() {
    if (disabledButton) return enqueueSnackbar('Preencha todos os campos corretamente', {variant: 'error'})
    if (!registerData.phone || !(registerData.phone.length >= 14)) return enqueueSnackbar('Preencha o campo de telefone corretamente', {variant: 'error'})
    if (!registerData.name || !registerData.document || (!isPerson && !registerData.companyName))
      return enqueueSnackbar('Preencha todos os campos', {variant: 'error'})
    if (page === 0) return setPage(1)

    if (registerData.type === 'person') {
      if (registerData.password !== passwordConfirmation) {
        enqueueSnackbar('As senhas não coincidem', {variant: 'error'})
        return
      }
    }
    try {
      setIsLoading(true)

      await api.auth.sendConfirmationEmail({email: registerData.email, username: registerData.name})
      navigate('/confirmation')
    } catch (error) {
      enqueueSnackbar('Erro ao realizar cadastro', {variant: 'error'})
    } finally {
      setIsLoading(false)
    }
  }

  function handleKeyPress(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      handleClickNext()
    }
  }

  return (
    <RegistrationContainer>
      {page !== 0 && (
        <GoBackButton onClick={() => setPage(0)}>
          <img src={ArrowLeft} alt='Arrow Left' />
          <TitleOnGoBackButton> Voltar</TitleOnGoBackButton>
        </GoBackButton>
      )}

      <Image src={page === 0 ? FirstStepIllust : SecondStepIllust} alt='Illustration' />

      <FormContainer>
        <Box style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <Title>Bom ter você aqui, preciso de alguns dados básicos para iniciar sua jornada</Title>
          <Subtitle>
            O Brain é uma IA de detecção de objetos e pessoas por imagem <br /> com alta precisão.
          </Subtitle>
        </Box>
        <Subtitle bold>Informe os dados abaixo para criar um cadastro</Subtitle>
        {page === 0 ? (
          <FirstFormPage
            handleKeyPress={handleKeyPress}
            personType={registerData.type}
            setPersonType={(personType) => setRegisterData({type: personType})}
            setDisabledButton={setDisabledButton}
          />
        ) : (
          <SecondFormPage setPasswordConfirmation={setPasswordConfirmation} handleKeyPress={handleKeyPress} />
        )}
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', width: '100%'}}>
          <Button
            isLoading={isLoading}
            variant='contained'
            width='150px'
            fontSize='14px'
            disabledTextColor='#8E8E8E'
            onClick={() => handleClickNext()}
            disabled={disabledButton}>
            Próximo
          </Button>
          <MessageLabel>
            Já passou por aqui e criou uma conta? <MessageLabelLink href='/login'>Clique aqui</MessageLabelLink>
          </MessageLabel>
        </Box>
      </FormContainer>
    </RegistrationContainer>
  )
}

export default B2CIndex
