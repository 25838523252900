import {Collapse} from '@mui/material'
import React, {useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useAuth} from 'src/contexts/AuthContext'
import {useApp} from '../contexts/AppContext'
import {Permission} from 'src/common/utils/PermissionsList'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import AppVersion from 'src/components/AppVersion'
import LogoCloseIcon from '../../../images/iconViptech.svg'
import LogoOpenIcon from '../../../images/logoOpenIcon.svg'
import DrawerCustom from './components/DrawerCustom'
import DrawerToggle from './components/DrawerToggle'
import {allSidebarItems, SidebarItem} from './computeSidebarItems'
import {
  ButtonCollapsedSubMenu,
  ContainerButton,
  ContainerTitlesOnCollapse,
  LogoContainer,
  MenuOnSideBar,
  MenuWithSubItems,
  SideBarContainer,
  SuspendedMenuBox,
} from './SideBarBase'
import {Box, Typography} from '@viptech/react-components'
import Menu from 'src/components/menu/Menu'
import {useWindowSize} from 'usehooks-ts'

type SidebarProps = {
  openDrawer: boolean
  setOpenDrawer: (open: boolean) => void
}

function Sidebar(props: SidebarProps) {
  const {openDrawer, setOpenDrawer} = props
  const {user, userToken, userIsB2c} = useAuth()
  const {sidebarTitle, setSidebarTitle} = useApp()

  const listPermissions = ['face:read', 'lpr:read']
  const userHasListPermissions = user.permissions.some((it) => listPermissions.includes(it))

  const [selectedMenu, setSelectedMenu] = useState<SidebarItem>()
  const urlFull = window.location.href
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const {width} = useWindowSize()
  const variantOnSidebar = width <= 767 ? 'temporary' : 'permanent'

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, menu: SidebarItem) => {
    setSelectedMenu(menu)
    setAnchorEl(event.currentTarget)
  }

  const getSideBarItems = () => {
    const shouldDisplayCompanyData = userToken.authorizedCompanies && userToken.authorizedCompanies.length === 1
    let sidebarItems = allSidebarItems.map((it) => {
      if (it.name === 'Configurações') {
        if (!shouldDisplayCompanyData) {
          const newSubmenu = it.submenu?.filter((submenu) => submenu.name !== 'Dados da Empresa' && submenu.name !== 'Credenciais')
          return {...it, submenu: newSubmenu}
        }
        return it
      }
      if (it.name === 'Cadastros') {
        let newSubmenu = it.submenu

        if (!userHasListPermissions) newSubmenu = it.submenu?.filter((submenu) => submenu.name !== 'Listas') ?? []
        if (userIsB2c) {
          newSubmenu = newSubmenu?.filter((submenu) => submenu.name === 'Dispositivos')
          if (newSubmenu?.length) newSubmenu[0].name = 'Câmeras'
        }

        if (newSubmenu?.length) return {...it, submenu: newSubmenu}
      }
      return it
    })

    if (userIsB2c) sidebarItems = sidebarItems.filter((it) => it?.name !== 'Mosaico')

    return sidebarItems
  }

  const {sidebarItems} = useMemo(() => {
    const sidebarItems = getSideBarItems()
    return {
      sidebarItems,
    }
  }, [])

  const subMenus = sidebarItems.filter((it) => it.submenu).flatMap((submenu) => submenu.submenu!.map((item) => item))
  const subMenuName = subMenus?.find((it) => urlFull.includes(it.link))

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleClick = (menu: SidebarItem) => {
    setSelectedMenu((prev) => (menu.name === prev?.name ? undefined : menu))
    if (width <= 767 && !menu.submenu) setOpenDrawer(false)
  }

  const hasToOpenSubMenu = (menu: SidebarItem) => {
    const urlCondition = subMenuName && urlFull.includes(subMenuName.link) && menu.link?.length && urlFull.includes(menu.link)
    return urlCondition || selectedMenu?.name === menu.name
  }

  function filterSideBarByPermissions(permission: Permission[]) {
    if (permission?.every((it) => ValidatePermissions(it.name))) return permission
    else return undefined
  }

  return (
    <Box>
      {variantOnSidebar === 'permanent' && (
        <DrawerToggle
          open={openDrawer}
          onClick={() => {
            if (openDrawer) setSelectedMenu(undefined)
            setOpenDrawer(!openDrawer)
          }}
        />
      )}

      <DrawerCustom
        variant={variantOnSidebar}
        open={openDrawer}
        onClose={() => {
          if (openDrawer) setSelectedMenu(undefined)
          setOpenDrawer(!openDrawer)
        }}
        key='6'>
        <SideBarContainer isOpen={openDrawer}>
          <LogoContainer isOpen={openDrawer}>
            <img height={'32px'} src={openDrawer ? LogoOpenIcon : LogoCloseIcon} alt='logo' />
          </LogoContainer>

          <Box>
            {sidebarItems
              .filter((it) => it.permission === filterSideBarByPermissions(it.permission))
              .map((menu, index) => {
                const isSelectedMenu = sidebarTitle === menu.name
                return !menu.submenu ? (
                  <Link
                    hidden={menu.hidden}
                    key={'sidebar-item-1-' + index + '-' + menu.name}
                    to={menu.link}
                    style={{textDecoration: 'none'}}
                    onClick={() => {
                      setSidebarTitle(menu.name)
                      handleClick(menu)
                    }}>
                    <MenuOnSideBar isSelected={isSelectedMenu}>
                      <img src={isSelectedMenu ? menu.iconSelected : menu.icon} alt={menu.name} />

                      <Typography variant='p' size='0.800em' hidden={!openDrawer}>
                        {menu.name}
                      </Typography>
                    </MenuOnSideBar>
                  </Link>
                ) : (
                  <Box key={'sidebar-item-2-' + index + '-' + menu.name}>
                    {openDrawer ? (
                      <Box key={'sidebar-subitem-2-' + index + '-' + menu.name}>
                        <Box onClick={() => handleClick(menu)}>
                          {menu.submenu.filter((it) => it.permission === filterSideBarByPermissions(it.permission)).length > 0 && (
                            <MenuWithSubItems>
                              <ContainerTitlesOnCollapse>
                                <img src={hasToOpenSubMenu(menu) ? menu.iconSelected : menu.icon} alt={menu.name} />

                                <Typography variant='p' size='0.800em' hidden={!openDrawer}>
                                  {menu.name}
                                </Typography>
                              </ContainerTitlesOnCollapse>

                              <img src={hasToOpenSubMenu(menu) ? menu.iconOpenSelected : menu.iconOpen} alt={menu.name} />
                            </MenuWithSubItems>
                          )}
                        </Box>

                        <Collapse in={hasToOpenSubMenu(menu)} timeout='auto' unmountOnExit sx={{overflow: 'hidden'}}>
                          {menu.submenu
                            .filter((it) => it.permission === filterSideBarByPermissions(it.permission))
                            .map((submenu, submenuIndex) => {
                              const isSubSelected = urlFull.includes(submenu.link)
                              return (
                                <Link
                                  key={'sidebar-item-3-' + submenuIndex + '-' + submenu.name}
                                  to={submenu.link}
                                  style={{textDecoration: 'none'}}
                                  onClick={() => {
                                    setSidebarTitle(menu.name)
                                    if (width <= 767) setOpenDrawer(false)
                                  }}>
                                  <MenuOnSideBar isSelected={isSubSelected} paddingLeft='2.5em' gap='14px'>
                                    <img src={isSubSelected ? submenu.iconSelected : submenu.icon} alt={submenu.name} />

                                    <Typography variant='p' size='0.800em' hidden={!openDrawer}>
                                      {submenu.name}
                                    </Typography>
                                  </MenuOnSideBar>
                                </Link>
                              )
                            })}
                        </Collapse>
                      </Box>
                    ) : (
                      menu.submenu.filter((it) => it.permission === filterSideBarByPermissions(it.permission)).length > 0 && (
                        <ButtonCollapsedSubMenu isSelected={urlFull.includes(menu.link)} onClick={(e) => handleClickMenu(e, menu)}>
                          <ContainerButton>
                            <img src={hasToOpenSubMenu(menu) ? menu.iconSelected : menu.icon} alt={menu.name} />

                            <Typography variant='p' size='0.800em' hidden={!openDrawer}>
                              {menu.name}
                            </Typography>
                          </ContainerButton>
                        </ButtonCollapsedSubMenu>
                      )
                    )}
                  </Box>
                )
              })}
          </Box>

          {!openDrawer && (
            <Menu anchor={anchorEl} onClose={handleCloseMenu} hideTriangle marginTop='-42px' marginLeft='56px' borderRadius='5px'>
              <Box display='flex' direction='column'>
                {selectedMenu?.submenu
                  ?.filter((it) => it.permission === filterSideBarByPermissions(it.permission))
                  .map((submenuSuspended) => {
                    const isSubSelected = urlFull.includes(submenuSuspended.link)
                    return (
                      <Link
                        to={submenuSuspended.link}
                        style={{textDecoration: 'none'}}
                        onClick={() => {
                          setSidebarTitle(`${selectedMenu.name} - ${submenuSuspended.name}`)
                          handleCloseMenu()
                        }}>
                        <SuspendedMenuBox isSelected={isSubSelected}>
                          <img src={isSubSelected ? submenuSuspended.iconSelected : submenuSuspended.icon} alt={submenuSuspended.name} />
                          <Typography variant='p' size='0.800em' color='white'>
                            {submenuSuspended.name}
                          </Typography>
                        </SuspendedMenuBox>
                      </Link>
                    )
                  })}
              </Box>
            </Menu>
          )}

          {openDrawer && (
            <Box display='flex' fontWeight='500' align='center' direction='column' height='100%' justifyContent='flex-end' padding='20px'>
              <AppVersion color='white' sideBar={true} />
            </Box>
          )}
        </SideBarContainer>
      </DrawerCustom>
    </Box>
  )
}

export default Sidebar
