import {useReducer, useRef, useState} from 'react'
import ArrowIcon from '../../images/ArrowIcon'
import {
  CalendarContainer,
  CalendarDropdownButton,
  CalendarHeader,
  CalendarHeaderText,
  CalendarMonthButton,
  CalendarMonthNavigation,
  CalendarZoomFadeInWrapper,
} from './CalendarBase'
import {CalendarProps} from './CalendarProps'
import DayPicker from './components/dayPicker/DayPicker'
import MonthPicker from './components/monthPicker/MonthPicker'
import YearPicker from './components/yearPicker/YearPicker'
import {reduceDate} from './utils/reduceDate'

const Calendar = ({min, max, value, language = 'pt-br', onChange, setInitalDate, setFinalDate, finalDateValue, initialDateValue}: CalendarProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value ?? null)
  const [step, setStep] = useState<'year' | 'month' | 'day'>('day')

  const [reducedDate, dispatchDate] = useReducer(reduceDate, {
    year: value ? value.getFullYear() : new Date().getFullYear(),
    month: value ? value.getMonth() : new Date().getMonth(),
  })

  const initialDate = useRef<Date | null>(initialDateValue ?? null)
  const finalDate = useRef<Date | null>(finalDateValue ?? null)

  const handleDateClick = (date: Date) => {
    setSelectedDate(date)
    if (initialDate.current !== null && finalDate.current !== null) {
      initialDate.current = null
      finalDate.current = null
    }

    if (!initialDate.current) {
      initialDate.current = date
    } else if (initialDate.current > date) {
      finalDate.current = initialDate.current
      initialDate.current = date
    } else if (initialDate.current <= date) {
      finalDate.current = date
    }
    onChange?.(date)
    setInitalDate?.(initialDate.current)
    setFinalDate?.(finalDate.current)
  }

  const handlePrevMonth = () => dispatchDate({type: 'DECREMENT'})
  const handleNextMonth = () => dispatchDate({type: 'INCREMENT'})
  const handleStep = () => setStep((prevStep) => (prevStep === 'day' ? 'month' : 'year'))

  const handleYear = (year: number) => {
    setStep('month')
    dispatchDate({type: 'SET', newDate: {year: year}})
  }

  const handleMonth = (month: number) => {
    setStep('day')
    dispatchDate({type: 'SET', newDate: {month: month}})
  }

  const handleMonthName = () => {
    const date = new Date(new Date().setDate(1)).setMonth(reducedDate.month)
    return new Date(date).toLocaleString(language, {month: 'long'})
  }

  return (
    <CalendarContainer>
      <CalendarMonthNavigation>
        <CalendarMonthButton onClick={handlePrevMonth} disabled={step !== 'day'}>
          <ArrowIcon transform={'rotate(180deg)'} disabled={step !== 'day'} />
        </CalendarMonthButton>

        <CalendarHeader>
          <CalendarDropdownButton onClick={handleStep}>
            {step === 'day' && (
              <>
                <CalendarHeaderText variant='p' margin='0' textTransform='capitalize'>
                  {handleMonthName()}
                </CalendarHeaderText>
                <CalendarHeaderText variant='p' margin='0'>
                  de
                </CalendarHeaderText>
              </>
            )}

            <CalendarHeaderText variant='p' margin='0'>
              {reducedDate.year}
            </CalendarHeaderText>
          </CalendarDropdownButton>
        </CalendarHeader>

        <CalendarMonthButton onClick={handleNextMonth} disabled={step !== 'day'}>
          <ArrowIcon disabled={step !== 'day'} />
        </CalendarMonthButton>
      </CalendarMonthNavigation>

      {step === 'year' ? (
        <CalendarZoomFadeInWrapper key={'year'}>
          <YearPicker currentYear={reducedDate.year} onChangeYear={handleYear} />
        </CalendarZoomFadeInWrapper>
      ) : step === 'month' ? (
        <CalendarZoomFadeInWrapper key={'month'}>
          <MonthPicker currentMonth={reducedDate.month} startYear={reducedDate.year} onChangeMonth={handleMonth} />
        </CalendarZoomFadeInWrapper>
      ) : (
        <CalendarZoomFadeInWrapper key={'day'}>
          <DayPicker
            initialDate={initialDate.current}
            finalDate={finalDate.current}
            min={min}
            max={max}
            currentYear={reducedDate.year}
            currentMonth={reducedDate.month}
            selectedDate={selectedDate}
            onChangeDay={handleDateClick}
          />
        </CalendarZoomFadeInWrapper>
      )}
    </CalendarContainer>
  )
}

export default Calendar
