
export default function ShowPasswordIcon() {
  return (
    <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 3.3C8.57865 3.3 9.13361 3.53178 9.54278 3.94436C9.95195 4.35694 10.1818 4.91652 10.1818 5.5C10.1818 6.08348 9.95195 6.64306 9.54278 7.05564C9.13361 7.46822 8.57865 7.7 8 7.7C7.42135 7.7 6.86639 7.46822 6.45722 7.05564C6.04805 6.64306 5.81818 6.08348 5.81818 5.5C5.81818 4.91652 6.04805 4.35694 6.45722 3.94436C6.86639 3.53178 7.42135 3.3 8 3.3ZM8 0C11.6364 0 14.7418 2.28067 16 5.5C14.7418 8.71933 11.6364 11 8 11C4.36364 11 1.25818 8.71933 0 5.5C1.25818 2.28067 4.36364 0 8 0ZM1.58545 5.5C2.17328 6.71023 3.08604 7.72988 4.21998 8.44305C5.35393 9.15622 6.66356 9.5343 8 9.5343C9.33644 9.5343 10.6461 9.15622 11.78 8.44305C12.914 7.72988 13.8267 6.71023 14.4145 5.5C13.8267 4.28977 12.914 3.27012 11.78 2.55695C10.6461 1.84378 9.33644 1.4657 8 1.4657C6.66356 1.4657 5.35393 1.84378 4.21998 2.55695C3.08604 3.27012 2.17328 4.28977 1.58545 5.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
