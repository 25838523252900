import {TermContent} from './components/Content/TermContent'
import {TermFooter} from './components/Footer/TermFooter'
import {TermPublishDate, TermPublishDateContainer} from './components/Footer/TermFooterBase'
import {TermHeader} from './components/Header/TermHeader'
import {TermTitle, TermsContentContainer} from './TermsBase'

export default function Terms() {
  return (
    <div>
      <TermHeader />

      <TermsContentContainer>
        <TermTitle>POLÍTICA DE PRIVACIDADE E TERMOS DE USO</TermTitle>

        <TermPublishDateContainer>
          <TermPublishDate>Publicado em: 11 de julho de 2024</TermPublishDate>
          <TermPublishDate>Última atualização: 11 de julho de 2024</TermPublishDate>
        </TermPublishDateContainer>

        <TermContent />
      </TermsContentContainer>

      <TermFooter />
    </div>
  )
}
