import React, {createContext, useContext, useState, useEffect, useRef} from 'react'
import {BrainBoxContextData} from './BrainBoxContextData'
import {EnqueueSnackbar, useSnackbar} from 'notistack'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import api, {Server, Client} from 'src/services/api'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useDebounce} from 'usehooks-ts'
import {useCompany} from 'src/contexts/CompanyContext'
import {BrainBoxCreateProps} from 'src/services/api/endpoints/BrainBoxEndpoint'

export const BrainBoxContext = createContext<BrainBoxContextData>({} as BrainBoxContextData)

const DEFAULT_BRAINBOX_IP = '192.168.4.1'

export type IpSources = 'DHCP' | 'Static'

export type ConnectionModes = 'Ethernet' | 'Wi-Fi'

export type BrainBoxDataProps = {
  clientId: number
  name: string
  code: string
  companyUsername: string
  ipSource: IpSources
  connectionMode: ConnectionModes
}

type BrainBoxProviderProps = {
  children: JSX.Element | JSX.Element[]
}

const initialBrainBoxData = {
  clientId: 0,
  name: '',
  code: '',
  companyUsername: '',
  ipSource: 'DHCP',
  connectionMode: 'Ethernet',
} as BrainBoxDataProps

export const BrainBoxProvider = ({children}: BrainBoxProviderProps) => {
  const {selectedCompanies} = useCompany()
  const [isOpen, setIsOpen] = useState(false)
  const [loadingClients, setLoadingClients] = useState(true)
  const [isLoadingClients, setIsLoadingClients] = useState(false)
  const [reloadClientCount, setReloadClientCount] = useState(0)
  const [clients, setClients] = useState<Client[]>([])
  const [servers, setServers] = useState<Server[]>([])
  const [selectedClient, setSelectedClient] = useState<Client>({} as Client)
  const [selectedIdsBrainBox, setSelectedIdsBrainBox] = useState<number[]>([])
  const [serversSearch, setServersSearch] = useState<string>('')
  const [brainBox, setBrainBox] = useState<BrainBoxDataProps>(initialBrainBoxData)
  const [selectedCompanyId, setSelectedCompanyId] = useState<number[] | null>([selectedCompanies[0]] || null)
  const blockClientRequests = useRef(false)
  const [step, setStep] = useState(1)

  const {enqueueSnackbar} = useSnackbar()
  const debouncedServerSearch = useDebounce(serversSearch, 400)

  const showOptionsButton = selectedIdsBrainBox.length > 1
  const hasPermissionReadClients = ValidatePermissions('clients:read')

  const openBrainBoxAddModal = () => {
    setIsOpen(true)
  }

  const dialogClose = () => {
    setIsOpen(false)
    setBrainBox(initialBrainBoxData)
    setStep(1)
  }

  async function verifyBrainBoxCode(enqueueSnackbar: EnqueueSnackbar) {
    try {
      const res = await api.brainBox.verifyBrainBoxCode(brainBox.code)
      const isBBCodeValid = res.data.data
      if (!isBBCodeValid) enqueueSnackbar('Erro ao verificar código da Brain Box: verifique se o código digitado está correto', {variant: 'error'})
      return isBBCodeValid
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao verificar código da Brain Box')
      return false
    }
  }

  async function createBrainBox() {
    try {
      setBrainBox({...brainBox})
      const brainboxCreateDto: BrainBoxCreateProps = {
        clientId: brainBox.clientId,
        name: brainBox.name,
        thingName: brainBox.code,
      }
      await api.brainBox.createBrainBox(brainboxCreateDto)
      enqueueSnackbar('Brain Box criada com sucesso!', {variant: 'success'})
      dialogClose()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar Brain Box')
    }
  }

  useEffect(() => {
    if (!hasPermissionReadClients) return
    if (!isOpen) return
    async function loadClients() {
      try {
        if (!selectedCompanyId) return
        if (blockClientRequests.current) return
        blockClientRequests.current = true
        const response = await api.client.getMany({
          page: 1,
          paginate: false,
          filter: {companyIds: selectedCompanyId},
          search: {name: debouncedServerSearch},
          includes: ['company'],
        })
        setClients(response.data.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar os Clientes')
      } finally {
        setLoadingClients(false)
        setIsLoadingClients(false)
        blockClientRequests.current = false
      }
    }

    loadClients()
  }, [enqueueSnackbar, reloadClientCount, debouncedServerSearch, hasPermissionReadClients, selectedCompanyId])

  const reloadClients = () => {
    if (loadingClients) return
    setIsLoadingClients(true)
    setReloadClientCount((x) => x + 1)
  }

  function createBrainboxLink() {
    const topic = `${brainBox.companyUsername}-${brainBox.code}`
    const link = `http://${DEFAULT_BRAINBOX_IP}?topic=${topic}&connectionType=${brainBox.connectionMode.toLowerCase()}&dhcp=${brainBox.ipSource === 'DHCP'}`
    return encodeURIComponent(link)
  }

  function generateQrCode(size: number) {
    const link = createBrainboxLink()
    const qrCode = `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${link}`
    return qrCode
  }

  return (
    <BrainBoxContext.Provider
      value={{
        isOpen,
        setIsOpen,
        step,
        setStep,
        dialogClose,
        selectedIdsBrainBox,
        setSelectedIdsBrainBox,
        showOptionsButton,
        openBrainBoxAddModal,
        selectedClient,
        setSelectedClient,
        servers,
        setServers,
        clients,
        setClients,
        reloadClients,
        isLoadingClients,
        serversSearch,
        setServersSearch,
        hasPermissionReadClients,
        brainBox,
        setBrainBox,
        createBrainBox,
        setSelectedCompanyId,
        generateQrCode,
        verifyBrainBoxCode,
      }}>
      {children}
    </BrainBoxContext.Provider>
  )
}

export function useBrainBoxContext() {
  return useContext(BrainBoxContext)
}

export default BrainBoxProvider
