import {Box, Button, Loading} from '@viptech/react-components'
import ReplayIcon from '@mui/icons-material/Replay'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect,  useState} from 'react'
import {Pos} from 'src/common/types/generics/ImageDetections'
import NoImage from 'src/images/noImage.jpg'
import {TitleAlt} from 'src/pages/self-register/select-plan/components/PlanSelectBase'
import {CameraImageDraw} from '../../../common/components/camera-image-draw/CameraImageDraw'
import {AreaData} from '../../../common/models/detection-objects/DetectionObjectsDTO'
import {addInterestArea} from '../../camera-config/components/detection-area/DetectionAreaFunctions'
import {useCameraConfigContext} from '../../camera-config/context/CameraConfigContext'
import {ContainerPageSelfRegister} from '../camera-rtmp-configuration/CameraRTMPConfigurationBase'
import Stepper from '../components/Stepper/Stepper'
import {WrapperContentArea} from './CameraRtmpAreaBase'
import {useWindowSize} from 'usehooks-ts'
import api from 'src/services/api/api'
import {InterestArea} from 'src/services/api'

type CameraRtmpAreaProps = {
  cameraId: number
  setPage: (page: number) => void
  setAreaIdCreated: (id: number[]) => void
}

function CameraRtmpArea(props: CameraRtmpAreaProps): React.JSX.Element {
  const {cameraId, setPage, setAreaIdCreated} = props
  const {_dimImage} = useCameraConfigContext()
  const {enqueueSnackbar} = useSnackbar()
  const [areaItems] = useState<AreaData[]>([])
  const [loadingSnapshot, setIsLoadingSnapshot] = useState(false)
  const [snapshot, setSnapshot] = useState<string | null>(null)
  const [isEditable, setIsEditable] = useState(false)
  const [areaEdit, setAreaEdit] = useState<InterestArea | null>(null)

  const [points, setPoints] = useState<Pos[]>([
    {x: 200, y: 200},
    {x: 400, y: 200},
    {x: 400, y: 400},
    {x: 200, y: 400},
  ])

  const [save, setSave] = useState('')
  const {width} = useWindowSize()

  const [widthOnKonva, setWidthOnKonva] = useState(819)

  useEffect(() => {
    if (width <= 767) setWidthOnKonva(350)
    else setWidthOnKonva(819)

    getInterestAreas()
  }, [width])

  const getInterestAreas = async () => {
    const response = await api.interestArea.getInterestAreas({cameraId})
    const area = response.data.data[0]
    if (!area) return
    const points = area.coordinates.map((coordinate) => {
      return {
        x: coordinate.x * _dimImage.width,
        y: coordinate.y * _dimImage.height,
      }
    })
    setPoints(points)
    setIsEditable(true)
    setAreaEdit(area)
  }

  const reloadSnapshot = useCallback(() => {
    async function loadSnapshot() {
      try {
        setIsLoadingSnapshot(true)
        const response = await api.camera.getSnapshot(cameraId)
        setSnapshot(`data:image/jpeg;base64,${response?.data?.data?.time.base64}`)
      } catch (error) {
        enqueueSnackbar('Erro ao carregar snapshot', {variant: 'error'})
      } finally {
        setIsLoadingSnapshot(false)
      }
    }

    loadSnapshot()
  }, [snapshot, cameraId])

  useEffect(() => {
    reloadSnapshot()
  }, [cameraId])

  return (
    <ContainerPageSelfRegister>
      <Box display='flex' direction='column' align='center' justifyContent='center'>
        <Stepper activeStep={1} stepTitles={['Dados da câmera', 'Personalizar área', 'Finalizar']} />

        <WrapperContentArea>
          <TitleAlt>Personalize a área de detecção de sua câmera cadastrada</TitleAlt>
          {!loadingSnapshot ? (
            <>
              <Box>
                <Box style={{display: 'flex', justifyContent: 'flex-end', paddingRight: '16px', right: '16px', width: '100%'}}>
                  <Box
                    style={{
                      height: '32px',
                      padding: '8px 3px 6px 3px',
                      borderRadius: '20px',
                      transform: 'scaleX(-1) rotate(0.85turn)',
                    }}>
                    <button onClick={reloadSnapshot} style={{backgroundColor: 'transparent'}}>
                      <ReplayIcon color='primary' />
                    </button>
                  </Box>
                </Box>

                <CameraImageDraw
                  isKonvaImageDrawToolbar
                  width={widthOnKonva}
                  height={390}
                  points={points}
                  editable={true}
                  src={!snapshot ? NoImage : snapshot}
                  onSave={async (points) => {
                    const newArea = {points: points, name: 'Area de interesse'}
                    if (isEditable) {
                      const coordinates = points.map((it) => ({
                        x: Math.round(it.x) / _dimImage.width,
                        y: Math.round(it.y) / _dimImage.height,
                      }))
                      if (!areaEdit) return
                      await api.interestArea.updateInterestArea({id: areaEdit.id, coordinates})
                      setAreaIdCreated([areaEdit.id])
                      setPage(2)
                      return
                    }
                    const res = await addInterestArea(cameraId, newArea, areaItems, _dimImage, enqueueSnackbar, () => setPage(2))
                    setAreaIdCreated(res.map((it) => it.id))
                  }}
                  save={save}
                />
              </Box>

              <Button variant='contained' padding='12px 64px' width='200px' themes='primary' onClick={() => setSave('save')}>
                Avançar
              </Button>
            </>
          ) : (
            <Box height='440px' style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <Loading />
            </Box>
          )}
        </WrapperContentArea>
      </Box>
    </ContainerPageSelfRegister>
  )
}

export default CameraRtmpArea
