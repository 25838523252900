import { CreateObjectDTO } from '../../../common/models/camera/CreateObjectDTO'
import { ListFacialRecognitionDTO } from '../../../common/models/facial-recognition/ListFacialRecognitionDTO'
import { LineCrossingDTO } from './LineCrossingEndpoint'
import axios from './_axios'
import { prepareParams } from './_internal'
import { AxiosResult } from './_types'

const prefix = process.env.REACT_APP_OBJECTS_BASE_URL

export type ObjectIncludesType =
  | 'interestArea'
  | 'objectType'
  | 'trigger'
  | 'trigger.action'
  | 'interestArea.cameraConfiguration'
  | 'interestArea.cameraConfiguration.camera'
  | 'faceRecognition'
  | 'faceLists'
  | 'actions'

export type LineCrossingInclude =
  | 'objectType'
  | 'objectType.resource'
  | 'trigger'
  | 'trigger.boxOutputDevice'
  | 'lineCrossing'
  | 'lineCrossing.cameraConfiguration'
  | 'lineCrossing.cameraConfiguration.camera'
  | 'actions'

export type UpdateCameraObjects = {
  objectsAdding: NewUpdateObjects[]
  objectsRemoving: number[]
}
export type UpdateCamObjects = {
  id: number
  confidence?: number
  description?: string | null
  brainBoxId?: number
  isActive?: boolean
  interestAreaId?: number
  objectTypeId?: number
  threshold?: number
  triggerId?: number
  trigger?: CreateTrigger | null
  faceListIds?: Array<number>
  blockUnknownFaces?: boolean
}

export type NewUpdateObjects = {
  objectId: number
  threshold: number
}

export type CreateObject = {
  confidence: string
  description: string | null
  isActive: boolean
  interestAreaId?: number
  objectTypeId?: number
  threshold: number
  trigger?: CreateTrigger | null
  faceListIds?: Array<number>
  blockUnknownFaces?: boolean
}

export type CreateTrigger = {
  boxOutputDeviceId: number | null
  triggerTime?: number | null
  actionId?: number
}

export type BarrierType = 'interest-area' | 'line-crossing'

export type FaceRecognition = {
  objectId: number
  blockUnknownFaces: boolean
}

export type CameraHasObjectAbstract = {
  id: number
  confidence: string
  type: BarrierType
  description: string | null
  objectTypeId: number
  isActive: boolean
  threshold: number
  objectType: ObjectType
  actions: ResponseActionType[] 
  trigger?: Trigger[] | null
}

export type CameraHasObject = CameraHasObjectAbstract & {
  interestAreaId: number | null
  isObjectCounting: boolean | null
  interestArea: InterestAreaCam | null
  faceRecognition: FaceRecognition | null
  faceLists: ListFacialRecognitionDTO[]
}

export type InterestAreaCam = {
  id: number
  name: string
  cameraConfigurationId: number
}

export type ObjectType = {
  id: number
  code: string
  name: string
  label: string
  resourceId: number
}

type Trigger = {
  id: number
  isActive: boolean
  boxOutputDeviceId: number
  triggerTime: number
}

export type ResponseActionType = {
  id: number
  objectId: number
  outputIntegrationId: number | null
  triggerId: number | null,
  licensePlateListId: number | null,
  blockUnknown: number | null,
  faceListId: number | null,
  telegramChatId: number | null,
  lprIntegrationId: number | null,
  mobileDeviceId: number | null
}

export type CameraObjectCreatedResponse = {
  confidence: number
  type: CameraObjectActionTypes
  description?: string
  objectTypeId: number
  isActive: boolean
  threshold?: number
  interestAreaId?: number
  lineCrossingId?: number
  trigger: ResponseTriggerType[] | null
  triggerId: number | null
  isObjectCounting: boolean | null
  id: number
  actions: ResponseActionType[]
}

export type CameraObjectActionTypes = 'output-integration' | 'trigger'

export type CreateTriggerSchemaDTO = {
  boxOutputDeviceId: number
  actionId: number
  triggerTime?: number
}

export type ResponseTriggerType = {
  id: number
} & CreateTriggerSchemaDTO

export type CameraObjectParams = {
  configurationId?: number
  includes?: ObjectIncludesType[]
}

export type GetLineCrossingObjectsParams = {
  configurationId: number
  includes: LineCrossingInclude[]
}

export type LineCrossingObject = CameraHasObjectAbstract & {
  lineCrossingId: number
  lineCrossing: LineCrossingDTO
}

export type UpdateLineCrossingObject = {
  id?: number
  confidence?: number
  description?: string | null
  isActive?: boolean
  interestAreaId?: number
  lineCrossingId?: number
  objectTypeId?: number
  threshold?: number
  triggerId?: number | null
  trigger?: Partial<Trigger>[] | null
  faceListsIds?: number[]
  blockUnknownFaces?: boolean
}

export type CameraObject = {
  id: number
  label: string
  name: string
  resourceId: number
  code: string
}

export type ActionType = 'output-integration' | 'trigger' | 'telegram'

export type PutActions = {
  type: ActionType
  outputActionId: number
  listIds?: number[]
  blockUnknownByClient?: boolean
  telegramChatId?: number
}

export class ObjectEndpoint {
  async getAllObjectTypes(): Promise<AxiosResult<CameraObject[]>> {
    return await axios.get(`${prefix}/object-types`)
  }

  async putActions(id: number, body: PutActions[]): Promise<AxiosResult<void>> {
    return await axios.put(`${prefix}/objects/${id}/actions`, body)
  }

  async deleteAction(actionId: number): Promise<AxiosResult<void>> {
    return await axios.delete(`${prefix}/objects/action/${actionId}`)
  }

  async createInterestAreaObjects(body: CreateObjectDTO): Promise<AxiosResult<CameraObjectCreatedResponse>> {
    return await axios.post(`${prefix}/interest-area/objects`, body)
  }

  async updateInterestAreaObjects(body: CreateObjectDTO): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/interest-area/objects`, body)
  }

  async removeInterestAreaObject(objectId: number): Promise<AxiosResult<any>> {
    return axios.delete(`${prefix}/interest-area/objects/${objectId}`)
  }

  async getInterestAreaObjects(params: CameraObjectParams): Promise<AxiosResult<CameraHasObject[]>> {
    return await axios.get(`${prefix}/interest-area/objects`, {
      params: prepareParams(params),
    })
  }

  async createLineCrossingObject(body: any): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/line-crossing/objects`, body)
  }

  async getLineCrossingsObjects(params: GetLineCrossingObjectsParams): Promise<AxiosResult<Array<LineCrossingObject>>> {
    return await axios.get(`${prefix}/line-crossing/objects`, { params: prepareParams(params) })
  }

  async updateLineCrossingObject(body: UpdateLineCrossingObject): Promise<AxiosResult<LineCrossingObject>> {
    return await axios.patch(`${prefix}/line-crossing/objects`, body)
  }

  async deleteLineCrossingObject(id: number): Promise<AxiosResult<void>> {
    return await axios.delete(`${prefix}/line-crossing/objects/${id}`)
  }
}
