import {Box, Dialog, DialogContent, DialogTitle} from '@mui/material'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import CountdownButton from 'src/pages/password-change/components/CountdownButton'
import api from 'src/services/api'

type BlockedUserModalProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  email: string
}

function BlockedUserModal(props: BlockedUserModalProps) {
  const {isOpen, setIsOpen, email} = props

  async function resendEmail() {
    try {
      const response = await api.auth.resendMail(email, 'confirm-identity')
      if (response.status === 200) {
        setIsOpen(true)
      }
    } catch (error) {
      setIsOpen(true)
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth='lg'>
      <DialogTitle>
        Usuário bloqueado
        <Box
          sx={{
            position: 'absolute',
            right: '23px',
            top: '13px',
            minWidth: '0',
            background: 'none',
          }}>
          <Icon src={CloseIcon} cursor='pointer' onClick={() => setIsOpen(false)} />
        </Box>
      </DialogTitle>
      <DialogContent sx={{fontSize: '16px'}}>
        <Box>Sua conta foi bloqueada devido ao elevado número de tentativas de acesso malsucedidas.</Box>
        <Box sx={{display: 'inline'}}>
          Enviamos um e-mail para o seguinte endereço: &nbsp;
          <Box sx={{color: '#009EFF', width: 'fit-content', display: 'inline'}}>{email}</Box>
        </Box>
        <Box>Verifique sua caixa de entrada e acesse o link contido no e-mail para liberar seu acesso.</Box>
        <Box>Caso não tenha recebido clique no botão abaixo:</Box>
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CountdownButton
            seconds={30}
            onClick={() => resendEmail()}
            sx={{marginTop: '15px', padding: '12px 18px', fontFamily: 'Inter', boxShadow: 'none', textTransform: 'none', width: '150px'}}
            variant='contained'>
            Reenviar e-mail
          </CountdownButton>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default BlockedUserModal
