import {HeaderContainer, ImageContainer} from './TermHeaderBase'
import brainLogo from 'src/images/fullViptechLogo.svg'

export function TermHeader() {
  return (
    <HeaderContainer>
      <ImageContainer src={brainLogo} alt='logo' onClick={() => (window.location.href = '/login')} />
    </HeaderContainer>
  )
}
