import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import HighlightOffOutlineIcon from '@mui/icons-material/HighlightOffOutlined'
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import {Box, Button} from '@mui/material'
import React from 'react'
import {interestAreaBottomStyles} from './InterestAreaBottomBase'

type Variant = 'add' | 'close' | 'remove'

type InterestAreaAddProps = {
  onClick: () => void
  variant: Variant
  isArea?: boolean
  disabled?: boolean
  label?: string
}

const Icons: Record<Variant, React.ReactNode> = {
  add: <AddCircleOutlineIcon sx={interestAreaBottomStyles.icon} />,
  close: <HighlightOffOutlineIcon sx={interestAreaBottomStyles.icon} />,
  remove: <RemoveCircleOutlineOutlinedIcon sx={interestAreaBottomStyles.icon} />,
}

export default function InterestAreaBottom(interestAreaAddProps: InterestAreaAddProps) {
  const {onClick, variant, disabled, isArea, label = 'Adicionar nova área'} = interestAreaAddProps
  return (
    <Box>
      <Button disabled={disabled} sx={isArea ? interestAreaBottomStyles.iconButton : interestAreaBottomStyles.addButton} onClick={onClick}>
        {isArea ? (
          <>
            {Icons[variant]}
            <span style={{paddingLeft: 6}}>{label}</span>
          </>
        ) : (
          'Adicionar'
        )}
      </Button>
    </Box>
  )
}
