import {Box, capitalize, Tooltip} from '@mui/material'
import {Checkbox, Column} from '@viptech/react-components'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import {Camera} from 'src/services/api/endpoints/CameraEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {datesDiffToString} from 'src/utilities/datetimeUtils'
import Pagination from '../../../components/pagination/Pagination'
import TriggerAlert from '../../../images/triggerAlert.svg'
import {useCameraContext} from '../context/CamerasContext'
import {CameraStatusConection} from './CameraStatusConection'
import {CameraTableOption} from './CameraTableOption'
import {useAuth} from 'src/contexts/AuthContext'
import {TableHeaderColumn, TableHeaderRow} from 'src/components/table/components/tableHeader/TableHeaderBase'
import Table from 'src/components/table/Table'
import TableData from 'src/components/table/components/tableData/TableData'

export type IsCameraConected = {
  id: number
  isConected: any
}

export type CameraGridTableProps = {
  deleteCamera: () => void
  setCameraOnline: () => void
  setCameraOffline: () => void
  setOpenCopyConfigModal: (open: Camera) => void
}

function CamerasGridTable(props: CameraGridTableProps) {
  const navigate = useNavigate()
  const hasUpdateCameraPermission = ValidatePermissions('cameras:update')
  const hasDeleteCameraPermission = ValidatePermissions('cameras:delete')
  const {cameras, loadingCameras, camerasTotalCount, camerasPage, setCamerasPage, setCamerasPageSize, selectedCamerasIds, setSelectedCamerasIds} =
    useCameraContext()
  const {deleteCamera, setCameraOnline, setCameraOffline, setOpenCopyConfigModal} = props
  const {getCompanyName} = useCompany()
  const {userIsB2c} = useAuth()

  const control = {
    currentPage: camerasPage.page,
    setCurrentPage: setCamerasPage,
    changePageSize: setCamerasPageSize,
    nextPage: () => {
      setCamerasPage(camerasPage.page + 1)
    },
    prevPage: () => {
      setCamerasPage(camerasPage.page - 1)
    },
  }

  function getLastTriggerReceived(camera: Camera, days: number, hours: number, minutes: number) {
    if (camera.lastTriggerReceived === null) return 'Câmera ainda não enviou nenhum disparo'
    return camera.lastTriggerReceived && minutes > 5
      ? `Há ${days ? days + ' dias' : ''}${days && hours ? ',' : ''} ${hours ? hours + ' horas' : ''} ${minutes && (days || hours) ? 'e' : ''} ${
          minutes ? minutes + ' minutos' : ''
        } sem detecção de movimento`
      : ''
  }

  type renderableCameraObject = {
    id: number
    name: string
    client?: string
    origin?: string
    status: string
    online: boolean
    hasActiveTrigger: boolean | undefined
    lastTriggerReceived: string
    self: Camera
  }

  const rows = cameras
    .map((camera) => ({
      ...camera,
      status: {
        id: camera.status.id,
        description: capitalize(camera.status.description.toLowerCase()),
      },
    }))
    .map((camera) => {
      const lastTriggerDate = camera.lastTriggerReceived ? new Date(camera.lastTriggerReceived) : new Date()
      const {days, hours, minutes} = datesDiffToString(lastTriggerDate)
      if (userIsB2c) {
        return {
          id: camera.id,
          name: camera.name,
          status: camera.status.description,
          online: camera.configuration.isOnline,
          self: camera,
          hasActiveTrigger: camera.hasActiveTrigger,
          lastTriggerReceived: getLastTriggerReceived(camera, days, hours, minutes) || '',
        }
      }

      return {
        id: camera.id,
        name: camera.name,
        client: camera.client.name,
        origin: camera.origin || '-',
        status: camera.status.description,
        online: camera.configuration.isOnline,
        self: camera,
        hasActiveTrigger: camera.hasActiveTrigger,
        lastTriggerReceived: getLastTriggerReceived(camera, days, hours, minutes) || '',
      }
    })

  const renderConnectionColumn = (row: renderableCameraObject) => {
    const daysLastTrigger = row.lastTriggerReceived
    const newDaysLastTrigger = daysLastTrigger.split(' ')
    const days = newDaysLastTrigger[1]
    const status = row.online ? 'habilitada' : 'desabilitada'
    return (
      <Box sx={{display: 'flex', gap: '10px', padding: '0px 24px', justifyContent: 'start'}}>
        <Tooltip title='Status da conexão com a câmera' placement='top'>
          <Box sx={{cursor: 'pointer'}}>
            <CameraStatusConection camera={row.self} />
          </Box>
        </Tooltip>

        <Tooltip title={`Câmera ${status} no Brain `} placement='top'>
          <Box sx={{cursor: 'pointer'}}> {row.online ? '🟢' : '🔴'}</Box>
        </Tooltip>

        {Number(days) < 1 || days === undefined ? (
          <></>
        ) : (
          <>
            <Tooltip title={row.lastTriggerReceived} placement='top'>
              <Box sx={{cursor: 'pointer'}}>
                <img src={TriggerAlert} alt='Alerta' />
              </Box>
            </Tooltip>
          </>
        )}
      </Box>
    )
  }

  const renderContextMenu = (row: renderableCameraObject) => {
    return (
      <>
        {selectedCamerasIds.length <= 1 && (
          <CameraTableOption
            oneObject={row.id.toString()}
            onClick={(event) => {
              if (event === 'to-events') return navigate(`/events?camera=${row.name}`)
              if (event === 'edit-camera') navigate(row.id.toString())
              if (event === 'copy-config') setOpenCopyConfigModal(row.self)
              if (event === 'delete') {
                setSelectedCamerasIds([+row.id])
                deleteCamera()
              }
              if (event === 'online') {
                setSelectedCamerasIds([+row.id])
                setCameraOnline()
              }
              if (event === 'offline') {
                setSelectedCamerasIds([+row.id])
                setCameraOffline()
              }
            }}
            hasDeletePermission={hasDeleteCameraPermission}
            hasUpdatePermission={hasUpdateCameraPermission}
            isCameraOnline={row.online}
          />
        )}
      </>
    )
  }

  const columns: Column[] = !userIsB2c
    ? [
        {
          id: 'checkbox',
          name: '',
        },
        {
          id: 'connection',
          name: '',
        },
        {
          id: 'name',
          name: 'Nome do Dispositivo',
        },
        {
          id: 'client',
          name: 'Cliente',
        },
        {
          id: 'company',
          name: 'Empresa',
        },
        {
          id: 'origin',
          name: 'Origem',
        },
        {
          id: 'status',
          name: 'Status',
        },
        {
          id: 'contextMenu',
          name: '',
        },
      ]
    : [
        {
          id: 'checkbox',
          name: '',
        },
        {
          id: 'connection',
          name: '',
        },
        {
          id: 'name',
          name: 'Nome do Dispositivo',
        },
        {
          id: 'status',
          name: 'Status',
        },
        {
          id: 'contextMenu',
          name: '',
        },
      ]

  const onClickOpenInNewTab = (id: number) => {
    window.open(`/registers/devices/${id}`, '')
  }

  const onSelectAll = () => {
    if (selectedCamerasIds.length) return setSelectedCamerasIds([])

    const allIds = rows.map((row) => row.id)
    setSelectedCamerasIds(allIds)
  }
  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={rows.length ? selectedCamerasIds.length === rows.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )
  return (
    <Box sx={styles.gridItem}>
      <Box flex={1} display={'flex'} flexDirection={'row'}>
        <Table columns={columns} isLoading={loadingCameras} skeletonRowsAmount={20} height='100%' tableHeaderChildren={customHeader}>
          {rows.map((row) => (
            <RowHover id={`row-${row.id}`}>
              <TableData maxWidthResponsive='40px'>
                <CheckboxTable id={row.id} selectedIds={selectedCamerasIds} setSelectedIds={setSelectedCamerasIds} />
              </TableData>
              <TableData width='16px'>{renderConnectionColumn(row)}</TableData>
              <TableData>
                <LinkWithAction onAuxClick={() => onClickOpenInNewTab(row.id)} onClick={() => navigate(row.id.toString())}>
                  {row.name}
                </LinkWithAction>
              </TableData>
              {!userIsB2c && <TableData>{row.client}</TableData>}
              {!userIsB2c && <TableData>{getCompanyName(row.self.companyId)}</TableData>}
              {!userIsB2c && <TableData>{row.origin}</TableData>}
              <TableData>{row.status}</TableData>
              <TableData>{renderContextMenu(row)}</TableData>
            </RowHover>
          ))}
        </Table>
      </Box>

      {!loadingCameras && rows.length === 0 ? (
        <Box sx={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} margin={'15px 0px'}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={camerasTotalCount}
            itemsPerPage={camerasPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}
const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '73vh',
  },
  links: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    marginRight: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#00639f',
    },
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '68vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
})

export default CamerasGridTable
