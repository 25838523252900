import {GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector} from '@mui/x-data-grid'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton sx={styles.items} onResize={undefined} onResizeCapture={undefined} />
      <GridToolbarDensitySelector sx={styles.items} onResize={undefined} onResizeCapture={undefined} />
    </GridToolbarContainer>
  )
}

const styles = {
  items: {
    marginLeft: '7px',
  },
}

export default CustomToolbar
