import {Loading} from '@viptech/react-components'
import React, {forwardRef, useCallback, useEffect, useId, useMemo, useRef, useState} from 'react'
import ArrowDownIcon from '../../images/ArrowDownIcon'
import {
  DropdownButton,
  DropdownContainer,
  DropdownErrorText,
  DropdownHeaderContainer,
  DropdownHiddenInput,
  DropdownIcon,
  DropdownInfiniteScrollLoading,
  DropdownInput,
  DropdownInputContainer,
  DropdownLabel,
  DropdownList,
  DropdownListItem,
  DropdownRequired,
} from './DropdownBase'
import {DropdownItem, DropdownProps} from './DropdownProps'

const initialState = {id: '', label: ''}

const onTextChanged = (item: DropdownItem, search: string) => {
  if (search.length) {
    const regex = new RegExp(`^${search}`, 'i')
    return item.label.match(regex)
  }
  return true
}

const Dropdown = forwardRef<HTMLInputElement, DropdownProps>(({onSearch, ...props}: DropdownProps, ref) => {
  const [value, setValue] = useState('')
  const [search, setSearch] = useState(props.selected?.label ?? '')
  const [isFocused, setIsFocused] = useState(false)
  const [isFirstPress, setIsFirstPress] = useState(true)
  const [isOpen, setIsOpen] = useState(props.open)
  const [selectedItem, setSelectedItem] = useState<DropdownItem>(props.selected ?? initialState)
  const selectedItemRef = useRef<HTMLLIElement | null>(null)
  const listId = useId()
  const inputContainerId = useId()
  const dropdownButtonId = useId()
  const filteredList = useMemo(() => props.items.filter((item) => onTextChanged(item, search)), [props.items, search])
  const screenBottomMargin = 20

  useEffect(() => {
    const listElement = document.getElementById(listId)
    const inputElement = document.getElementById(inputContainerId)

    if (!inputElement || !listElement) return
    const {height: inputElementHeight} = inputElement.getBoundingClientRect()
    const {bottom: listElementBottom} = listElement.getBoundingClientRect()

    if (listElementBottom > window.screen.height - screenBottomMargin) listElement.style.bottom = `${inputElementHeight + 8}px`
  }, [inputContainerId, isOpen, listId])

  const openList = () => {
    if (props.preventEditing) return
    if (!props.disabled || !props.preventEditing) {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    if (props.alwaysOpen) {
      setIsOpen(true)
    }
  }, [isOpen])

  const changeSelectedItem = (item: DropdownItem) => {
    setIsOpen(false)
    setValue(item.label)
    if (!props.dontReflectValueSelectedOnLabel) setSelectedItem(item)
    setSearch('')
    props.onChangeSelected?.(item)
    updateRefValue(item)
  }

  const updateRefValue = (item: DropdownItem) => {
    if (ref) {
      const input = ref as React.MutableRefObject<HTMLInputElement>
      if (input && input.current) input.current.value = JSON.stringify(item)
    }
  }

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.preventEditing) {
      setIsOpen(false)
      return
    }
    setIsOpen(true)
    setValue(event.target.value)
    setSearch(event.target.value)
  }

  useEffect(() => {
    props.disabled && setIsOpen(false)
  }, [props.disabled])

  useEffect(() => {
    onSearch?.(search)
  }, [search])

  useEffect(() => {
    if (!props.selected) return
    const hasLabel = !!props.selected.label
    const selectedItem = hasLabel ? props.selected : props.items.find((item) => item.id === props.selected?.id)
    setValue(selectedItem?.label || initialState.label)
    setSelectedItem(selectedItem || initialState)
  }, [props.selected, props.items])

  useEffect(() => {
    if (selectedItem && !isOpen) {
      setValue(selectedItem.label || '')
      setSearch('')
    }
  }, [selectedItem, isOpen])

  useEffect(() => {
    if (!isOpen || !props.onScrollEnd) return
    const list = document.getElementById(listId)
    if (list === null) return

    const handleScroll = () => {
      const isAtBottom = list.scrollTop + list.clientHeight >= list.scrollHeight
      if (isAtBottom) props.onScrollEnd?.()
    }

    list.addEventListener('scroll', handleScroll)
    return () => list.removeEventListener('scroll', handleScroll)
  }, [isOpen, listId, props.onScrollEnd])

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      const hasClickedInListItem = target.parentElement?.id === listId
      const isInputContainerChild = target.parentElement?.id === inputContainerId
      const isDropdownButtonChild = target.parentElement?.id === dropdownButtonId
      if (hasClickedInListItem || isInputContainerChild || isDropdownButtonChild) return

      if (!props.open) setIsOpen(false)
    }

    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [dropdownButtonId, inputContainerId, listId])

  const handleArrowKey = useCallback(
    (isArrowUp: boolean, isFirstPress: boolean) => {
      if (!selectedItemRef.current) return

      const currentIndex = filteredList.findIndex((item) => item.id === selectedItemRef.current?.id)
      let nextIndex
      if (isArrowUp) nextIndex = (currentIndex - 1 + filteredList.length) % filteredList.length
      else {
        if (isFirstPress) {
          nextIndex = 0
        } else nextIndex = currentIndex === -1 ? 0 : (currentIndex + 1) % filteredList.length
      }

      const nextItem = filteredList[nextIndex]
      if (!nextItem) return

      selectedItemRef.current.blur()
      selectedItemRef.current = document.getElementById(nextItem.id.toString()) as HTMLLIElement
      selectedItemRef.current.focus()
    },
    [filteredList],
  )

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isFocused && (event.key === 'ArrowDown' || (isOpen && event.key === 'Tab'))) {
        handleArrowKey(false, isFirstPress)
        setIsFirstPress(false)
        event.preventDefault()
      } else if (isFocused && event.key === 'ArrowUp') {
        handleArrowKey(true, isFirstPress)
        setIsFirstPress(false)
        event.preventDefault()
      } else if (isFocused && event.key === 'Enter') {
        if (!selectedItemRef.current) return
        selectedItemRef.current.click()
        setIsFirstPress(true)
      } else if (!isFocused && !isFirstPress) {
        setIsFirstPress(true)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleArrowKey, isFocused, isFirstPress, isOpen])

  const handleUlKeyDown = (event: React.KeyboardEvent<HTMLUListElement>) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') event.preventDefault()
  }

  return (
    <DropdownContainer
      className={props.className}
      width={props.width}
      height={props.height}
      margin={props.margin}
      padding={props.padding}
      fontSize={props.fontSize}
      marginTop={props.marginTop}
      fontFamily={props.fontFamily}
      fontWeight={props.fontWeight}
      marginLeft={props.marginLeft}
      paddingTop={props.paddingTop}
      marginRight={props.marginRight}
      paddingLeft={props.paddingLeft}
      borderColor={props.borderColor}
      paddingRight={props.paddingRight}
      marginBottom={props.marginBottom}
      paddingBottom={props.paddingBottom}>
      {props.label && (
        <DropdownHeaderContainer>
          <DropdownLabel labelFontSize={props.labelFontSize} disabled={props.disabled}>
            {props.label}
          </DropdownLabel>
          {props.required && <DropdownRequired disabled={props.disabled}>*</DropdownRequired>}
        </DropdownHeaderContainer>
      )}
      <DropdownInputContainer
        disabledBorderColor={props.disabledBorderColor}
        paddingLeftInsideInput={props.paddingLeftInsideInput}
        open={isOpen}
        id={inputContainerId}
        borderColor={props.borderColor}
        bgColor={props.bgColor}
        disabled={props.disabled}
        error={!!props.errorMessage || ((value.length > 0 || props.required) && value !== selectedItem.label)}>
        <DropdownHiddenInput id={props.id} name={props.name} ref={ref} disabled={props.disabled} />
        <DropdownInput
          fontSize={props.fontSize}
          fontFamily={props.fontFamily}
          fontWeight={props.fontWeight}
          value={value}
          onChange={onChangeInput}
          onClick={openList}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          disabled={props.disabled}
          placeholder={props.placeholder ?? 'Selecione um item'}
          placeholderColor={props.placeholderColor}
        />
        {props.icon && (
          <DropdownIcon color={props.iconColor} disabled={props.disabled}>
            {props.icon}
          </DropdownIcon>
        )}

        {props.isLoading ? (
          <Loading size='1rem' disabled={props.disabled} />
        ) : (
          !props.hideIcon && (
            <DropdownButton open={isOpen} id={dropdownButtonId} disabled={props.disabled} onClick={props.disabled ? undefined : () => setIsOpen(!isOpen)}>
              <ArrowDownIcon transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'} disabled={props.disabled} color={props.iconColor} />
            </DropdownButton>
          )
        )}
      </DropdownInputContainer>

      {isOpen && (
        <DropdownList
          className={props.className}
          id={listId}
          borderColor={props.borderColor}
          onBlur={() => setIsFocused(false)}
          onFocus={() => setIsFocused(true)}
          onKeyDown={handleUlKeyDown}>
          {props.isLoading ? (
            <DropdownListItem>Carregando...</DropdownListItem>
          ) : (
            <>
              {filteredList.length === 0 && <DropdownListItem>Nenhum item encontrado...</DropdownListItem>}
              {filteredList.map((item, index) => {
                return item.svgComponent ? (
                  <DropdownListItem ref={index === 0 ? selectedItemRef : null} tabIndex={0} key={item.id} id={item.id} onClick={() => changeSelectedItem(item)}>
                    {item.svgComponent}
                  </DropdownListItem>
                ) : (
                  <DropdownListItem ref={index === 0 ? selectedItemRef : null} tabIndex={0} key={item.id} id={item.id} onClick={() => changeSelectedItem(item)}>
                    {item.label}
                  </DropdownListItem>
                )
              })}
              {props.isLoadingNextPage && (
                <DropdownInfiniteScrollLoading>
                  <Loading size='1.5rem' />
                </DropdownInfiniteScrollLoading>
              )}
            </>
          )}
        </DropdownList>
      )}

      {(props.errorMessage || props.helperText) && (
        <DropdownErrorText disabled={props.disabled} error={!!props.errorMessage}>
          {props.errorMessage || props.helperText}
        </DropdownErrorText>
      )}
    </DropdownContainer>
  )
})

export default Dropdown
