import {Action} from 'src/common/types/Action'
import {KeyPress} from 'src/common/types/KeyPress'
import {Position} from 'src/common/types/Position'
import {State} from 'src/common/types/State'
import {clamp} from 'src/common/utils/Internal'

export const DEFAULT_ZOOMS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 3, 4]

export const DEFAULT_INITIAL_ZOOM = DEFAULT_ZOOMS.findIndex((it) => it === 1)

export const INITIAL_STATE: State = {
  key: '',
  mouseIsDown: false,
  updates: 0,
  zoomIdx: DEFAULT_INITIAL_ZOOM,
  zoomTotal: DEFAULT_ZOOMS.length - 1,
  currentPosition: {x: 0, y: 0},
  previosPosition: null,
  translate: {x: 0, y: 0},
  hideDetections: false,
}

export function computeTranslate(state: State): Position {
  if (state.updates < 2) return state.translate
  if (!state.mouseIsDown) return state.translate
  if (state.previosPosition === null) return state.translate

  const x = clamp(state.currentPosition.x - state.previosPosition.x, -40, 40)
  const y = clamp(state.currentPosition.y - state.previosPosition.y, -40, 40)

  return {x: state.translate.x + x, y: state.translate.y + y}
}

export function moveByKey(translate: Position, {key, shift}: KeyPress) {
  const speed = shift ? 1 : 5
  const move = 10 * speed
  switch (key) {
    case 'w':
      return {x: translate.x, y: translate.y + move}
    case 's':
      return {x: translate.x, y: translate.y - move}
    case 'a':
      return {y: translate.y, x: translate.x + move}
    case 'd':
      return {y: translate.y, x: translate.x - move}
    default:
      return translate
  }
}

export function zoomIn(state: State): number {
  return Math.min(state.zoomIdx + 1, state.zoomTotal)
}
export function zoomOut(state: State): number {
  return Math.max(state.zoomIdx - 1, 0)
}

export function resetZoom(state: State): State {
  return {
    ...state,
    zoomIdx: INITIAL_STATE.zoomIdx,
    translate: INITIAL_STATE.translate,
  }
}

export function zoomByKey(state: State, {key}: KeyPress): number {
  switch (key) {
    case 'q':
      return zoomIn(state)
    case 'e':
      return zoomOut(state)
    default:
      return state.zoomIdx
  }
}

export function hideDetectionByKey(hideDetections: boolean, {key}: KeyPress): boolean {
  return key === 'f' ? !hideDetections : hideDetections
}

export function resetZoomByKey(state: State, {key}: KeyPress): State {
  return key === 'r' ? resetZoom(state) : state
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'toggleHideDetections':
      return {...state, hideDetections: !state.hideDetections}
    case 'mouseDown':
      return {...state, mouseIsDown: true}
    case 'mouseUp':
      return {...state, mouseIsDown: false, previosPosition: null, updates: 0}
    case 'zoomIn':
      return {...state, zoomIdx: zoomIn(state)}
    case 'zoomOut':
      return {...state, zoomIdx: zoomOut(state)}
    case 'mouseMove': {
      return {
        ...state,
        updates: state.updates + 1,
        previosPosition: state.currentPosition,
        currentPosition: action.payload,
        translate: computeTranslate(state),
      }
    }
    case 'keyPress': {
      const newState = {
        ...state,
        key: action.payload.key,
        zoomIdx: zoomByKey(state, action.payload),
        translate: moveByKey(state.translate, action.payload),
        hideDetections: hideDetectionByKey(state.hideDetections, action.payload),
      }
      return resetZoomByKey(newState, action.payload)
    }
    case 'resetZoom': {
      return resetZoom(state)
    }
    case 'reset':
      return INITIAL_STATE
    default:
      console.error('Action not implemented', {state, action})
  }
  return state
}
