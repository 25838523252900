import styled from 'styled-components'

export const CardContainer = styled.div`
  border-radius: 6px;
  background: #fff;
  box-shadow:
    0px 1px 3px 0px rgba(16, 24, 40, 0.1),
    0px 1px 2px 0px rgba(16, 24, 40, 0.06);
  min-height: 85px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TagStatus = styled.div<{color: string; bg: string}>`
  color: ${({color}) => color};
  font-size: 10px;
  font-weight: bold;
  background-color: ${({bg}) => bg};
  padding: 2px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
`
