import {FaceDTO} from 'src/common/models/facial-recognition/FaceDTO'
import {FacialListDTO} from 'src/common/models/facial-recognition/FacialListDTO'
import {ListFacialRecognitionDTO} from 'src/common/models/facial-recognition/ListFacialRecognitionDTO'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_FACIAL_BASE_URL

export type CreateFaceListDTO = {
  name: string
  companyId: number
  clientId?: number
  actionId: number
}

type CreateFaceDTO = {
  name: string
  listId: number
  image: string
}

type getFaceListParams = {
  page: number
  pageSize: number
  search?: {
    faceName?: string
  }
  includes?: Array<string>
  filter?: {
    clientIds?: Array<number>
    ids?: Array<number>
    isKnown?: boolean
    listIds?: Array<number>
  }
}

type getListParams = {
  filter?: {
    clientIds?: Array<number>
    companyIds?: Array<number>
  }
  search?: {
    listName?: string
  }
  includes?: Array<string>
}

type ResponseFacialDTO = {
  face: FaceDTO
  isNew: boolean
  distance: any | null
}

export type UpdateFace = {
  id: number
  name?: string
  listId?: number
  image?: string
}

export class FacialRecognitionEndpoint {
  async getList(params: getListParams): Promise<AxiosResult<Paginate<ListFacialRecognitionDTO>>> {
    return await axios.get(`${prefix}/face-lists`, {params: prepareParams(params)})
  }

  async createFacialList(body: CreateFaceListDTO): Promise<AxiosResult<any>> {
    return await axios.post(`${prefix}/face-list`, body)
  }

  async updateFacialItemList(id: number, body: any): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/face-list/${id}`, body)
  }

  async deleteFacialItemList(id: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/face-list/${id}`)
  }
  async deleteManyFacialList(ids: number[]): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/face-lists`, {data: {ids}})
  }
  async createFacial(body: CreateFaceDTO): Promise<AxiosResult<ResponseFacialDTO>> {
    return await axios.post(`${prefix}/face`, body)
  }

  async getFacialList(params: getFaceListParams): Promise<AxiosResult<Paginate<FacialListDTO>>> {
    return await axios.get(`${prefix}/faces`, {params: prepareParams(params)})
  }

  async updateFacial(body: UpdateFace): Promise<AxiosResult<ResponseFacialDTO>> {
    return await axios.patch(`${prefix}/face`, body)
  }

  async deleteFacial(id: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/face/${id}`)
  }
}
