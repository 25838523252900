import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItem,
  ListItemIcon,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {BtnClose} from 'src/components/BtnClose'
import {useAuth} from 'src/contexts/AuthContext'
import {useApp} from 'src/layouts/main-layout/contexts/AppContext'
import api, {CompaniesReleaseNotes, ReleasesNotes, RolesReleaseNotes} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import EditorToolbar, {formats, modules} from '../../../components/EditorToolbar'

type NotesProps = {
  releaseNote: ReleasesNotes
  setReleaseNote: (releaseNote: ReleasesNotes) => void
  loadingNotes: Function
  open: boolean
  handleClose: () => void
  isCreate: boolean
  companiesReleaseNotes: CompaniesReleaseNotes[]
  rolesReleaseNotes: RolesReleaseNotes[]
}

type CompaniesList = {
  id: number
  name: string
  checked: boolean
}

type RolesList = {
  name: string
  label: string
  checked: boolean
}

const ReleaseNotesRegisterModal = ({
  releaseNote,
  setReleaseNote,
  loadingNotes,
  open,
  handleClose,
  isCreate,
  companiesReleaseNotes,
  rolesReleaseNotes,
}: NotesProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const steps = [isCreate ? 'Adicionar notificação' : 'Editar notificação', 'Informe quem receberá essa notificação']
  const [activeStep, setActiveStep] = useState<number>(0)
  const [companies, setCompanies] = useState<CompaniesList[]>([])
  const [roles, setRoles] = useState<RolesList[]>([])
  const {setUpdateNotifications} = useApp()
  const hasCompaniesReadPermission = ValidatePermissions('companies:read')
  const {user, userToken} = useAuth()

  useEffect(() => {
    if (isCreate) setReleaseNote({...releaseNote, subtitle: '', description: '', title: ''})
    loadCompanies()
    loadRoles()
  }, [])

  const toggleChecked = (index: number, key: string) => {
    if (key === 'companies') {
      const newCompanies = [...companies]
      newCompanies[index] = {
        ...newCompanies[index],
        checked: !newCompanies[index].checked,
      }
      setCompanies(newCompanies)
    } else if (key === 'roles') {
      const newRoles = [...roles]
      newRoles[index] = {
        ...newRoles[index],
        checked: !newRoles[index].checked,
      }
      setRoles(newRoles)
    }
  }

  async function loadCompanies() {
    if (!hasCompaniesReadPermission) return
    const companiesIds = companiesReleaseNotes.map((it) => it.companyId)

    const response = await api.company.getMany({
      page: 1,
      pageSize: 100,
      filter: {ids: userToken.authorizedCompanies},
      orderBy: {
        names: 'ASC',
      },
    })
    const companiesEntities = response.data.data.entities

    const newCompanies: CompaniesList[] = []
    companiesEntities.forEach(({id, name}, index) => {
      if (!isCreate) {
        if (companiesIds.includes(id)) {
          newCompanies[index] = {id, name, checked: true}
        } else {
          newCompanies[index] = {id, name, checked: false}
        }
      } else {
        newCompanies[index] = {id, name, checked: true}
      }
    })
    setCompanies(newCompanies)
  }

  async function loadRoles() {
    const rolesNames = rolesReleaseNotes.map((it) => it.roleName)
    const roles = (await api.users.getAllRoles()).data.data
    const newRoles = roles.map((role) => ({name: role.name, label: role.label, checked: false}))
    roles.forEach(({name, label}, index) => {
      if (!isCreate) {
        if (rolesNames.includes(name)) {
          newRoles[index] = {name, label, checked: true}
        } else {
          newRoles[index] = {name, label, checked: false}
        }
      } else {
        newRoles[index] = {name, label, checked: true}
      }
    })
    setRoles(newRoles)
  }

  const onDescription = (value: any) => {
    setReleaseNote({...releaseNote, description: value})
  }

  const editReleaseNote = async () => {
    setIsLoading(true)
    try {
      await api.transaction.updateReleaseNote(releaseNote.id, {
        title: releaseNote.title,
        subtitle: releaseNote.subtitle,
        description: releaseNote.description,
        resetViews: true,
        companies: !hasCompaniesReadPermission && user ? user.authorizedCompanies : companies.filter((it) => it.checked).map((it) => it.id),
        roles: !hasCompaniesReadPermission && user ? ['admin', 'operator'] : roles.filter((it) => it.checked).map((it) => it.name),
      })
      enqueueSnackbar('Notificação atualizada com sucesso', {
        variant: 'success',
      })
      loadingNotes()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar notificação')
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  const createReleaseNotes = async () => {
    setIsLoading(true)
    try {
      await api.transaction.createReleaseNote({
        id: releaseNote?.id,
        subtitle: releaseNote.subtitle,
        title: releaseNote.title,
        description: releaseNote.description,
        companies: !hasCompaniesReadPermission && user ? user.authorizedCompanies : companies.filter((it) => it.checked).map((it) => it.id),
        roles: !hasCompaniesReadPermission && user ? ['admin', 'operator'] : roles.filter((it) => it.checked).map((it) => it.name),
      })
      enqueueSnackbar('Notificação criada com sucesso', {
        variant: 'success',
      })
      setUpdateNotifications((prev) => !prev)
      loadingNotes()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar notificação')
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} scroll='paper' maxWidth='lg' fullWidth>
      <DialogTitle sx={styles.title}>
        <BtnClose onClose={handleClose} />
        <Stepper nonLinear activeStep={activeStep} sx={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel sx={{fontSize: '13px'}}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      {activeStep === 0 ? (
        <DialogContent sx={{height: 600}}>
          <FormControl fullWidth>
            <TextField
              label='Título'
              required
              placeholder='Insira um título'
              type='text'
              size='small'
              sx={{marginBottom: 2, marginTop: 1}}
              InputLabelProps={{shrink: true}}
              value={releaseNote.title}
              onChange={(e) => setReleaseNote({...releaseNote, title: e.target.value})}
              inputProps={{
                maxLength: 60,
              }}
            />
          </FormControl>
          <FormControl fullWidth>
            <TextField
              label='Subtítulo'
              required
              placeholder='Insira um subtítulo'
              type='text'
              size='small'
              sx={{marginBottom: 2}}
              InputLabelProps={{shrink: true}}
              value={releaseNote.subtitle}
              onChange={(e) => setReleaseNote({...releaseNote, subtitle: e.target.value})}
              inputProps={{
                maxLength: 60,
              }}
            />
          </FormControl>
          <EditorToolbar toolbarId={'t1'} />
          <ReactQuill
            style={{height: 300}}
            theme='snow'
            value={releaseNote.description}
            onChange={onDescription}
            placeholder={'Digite sua descrição...'}
            modules={modules('t1')}
            formats={formats}
          />
        </DialogContent>
      ) : (
        <DialogContent sx={{height: 600, display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <Box sx={styles.companies}>
            <Box sx={styles.companiesTitle}>Empresas</Box>
            <Box sx={styles.companiesList}>
              {companies.map((company, index) => (
                <Box key={'company-' + company.name}>
                  <ListItem role='listitem' sx={{padding: '0px 12px'}}>
                    <ListItemIcon>
                      <Checkbox
                        checked={company.checked}
                        tabIndex={-1}
                        disableRipple
                        onChange={() => {
                          toggleChecked(index, 'companies')
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`${company.id}`}>{company.name}</ListItemText>
                  </ListItem>
                  <ListItem />
                </Box>
              ))}
            </Box>
          </Box>
          <Box sx={styles.roles}>
            <Box sx={styles.rolesTitle}>Funções</Box>
            <Box sx={styles.rolesList}>
              {roles.map((role, index) => (
                <Box key={'transfer-' + role.label}>
                  <ListItem role='listitem' sx={{padding: '0px 12px'}}>
                    <ListItemIcon>
                      <Checkbox checked={role.checked} disableRipple onChange={() => toggleChecked(index, 'roles')} />
                    </ListItemIcon>
                    <ListItemText>{role.label}</ListItemText>
                  </ListItem>
                  <ListItem />
                </Box>
              ))}
            </Box>
          </Box>
        </DialogContent>
      )}
      <DialogActions sx={{height: '7%', alignItems: 'center', display: 'flex', margin: '15px 15px'}}>
        {activeStep === 0 && hasCompaniesReadPermission ? (
          <Box sx={styles.stepContainer}>
            <Button sx={styles.cancelButton} onClick={handleClose}>
              Cancelar
            </Button>
            <LoadingButton
              disabled={!releaseNote.subtitle || !releaseNote.title || !releaseNote.description}
              variant='contained'
              sx={styles.confirmButton}
              onClick={() => setActiveStep(1)}>
              Avançar
            </LoadingButton>
          </Box>
        ) : (
          <Box sx={styles.stepContainer}>
            <Button onClick={() => setActiveStep(0)} sx={styles.cancelButton}>
              Voltar
            </Button>
            <LoadingButton
              variant='contained'
              loading={isLoading}
              sx={styles.confirmButton}
              onClick={() => {
                if (isCreate) createReleaseNotes()
                else editReleaseNote()
              }}>
              Salvar
            </LoadingButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  companies: {
    width: '49%',
  },
  companiesTitle: {
    height: '40px',
    fontFamily: 'Inter',
    fontSize: '22px',
    marginLeft: 3,
  },
  companiesList: {
    paddingTop: 1.2,
    overflowY: 'auto',
    boxShadow: ' rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    '&:hover': {
      boxShadow: ' rgba(0, 0, 0, 0.70) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    },
    height: '93%',
    borderRadius: '5px',
  },
  roles: {
    width: '49%',
  },
  rolesTitle: {
    height: '40px',
    fontFamily: 'Inter',
    fontSize: '22px',
    marginLeft: 3,
  },
  rolesList: {
    paddingTop: 1.2,
    height: '93%',
    overflowY: 'auto',
    borderRadius: '5px',
    boxShadow: ' rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    '&:hover': {
      boxShadow: ' rgba(0, 0, 0, 0.70) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
    },
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '2px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: '20px',
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  title: {
    fontSize: '2.5rem',
    lineHeight: '0.4rem',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  stepper: {
    paddingTop: 5,
    paddingBottom: 2,
    paddingLeft: 25,
    paddingRight: 25,
  },
  stepContainer: {
    display: 'flex',
    marginBottom: 0.5,
  },
})

export default ReleaseNotesRegisterModal
