import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {Box, Button, Typography} from '@viptech/react-components'
import {useEffect, useState} from 'react'
import AppSearch from 'src/components/AppSearch'
import {useCompany} from 'src/contexts/CompanyContext'
import CompaniesSelect from 'src/layouts/main-layout/header/components/CompaniesSelect'
import {useBrainBoxContext} from 'src/pages/brain-box/context/BrainBoxContext'
import {Company} from 'src/services/api'
import AppReloadButton from '../../../../../components/appReloadButton/AppReloadButton'

function BrainBoxClient(props: {isFullHd: boolean; setCompanySelectedName: (companyName: string) => void}) {
  const {isLoadingClients, clients, reloadClients, serversSearch, setServersSearch, hasPermissionReadClients, brainBox, setBrainBox, setSelectedCompanyId} =
    useBrainBoxContext()
  const {selectedCompanies} = useCompany()
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])

  useEffect(() => {
    props.setCompanySelectedName(companyNames[0])
  }, [companyNames])

  useEffect(() => {
    if (!clients.map((client) => client.id).includes(brainBox.clientId)) setBrainBox({...brainBox, clientId: 0})
  }, [clients])

  useEffect(() => {
    setSelectedCompanyId([selectedCompanies[0]])
  }, [])
  return (
    <Box width='49%' height='100%' border='0.5px solid #5F5B5B' borderRadius='8px' overflow='hidden' padding='16px'>
      <Box>
        <Box>
          {hasPermissionReadClients ? (
            <>
              <Box display='flex' rowGap='23px' direction='column'>
                <Box rowGap='12px'>
                  <Box fontSize='24px' fontWeight='500'>
                    Clientes
                  </Box>
                  <Box color='#8E8E8E'>Selecione o cliente abaixo</Box>
                </Box>
                <Box display='flex' align='center' justify='space-between'>
                  <AppReloadButton loading={isLoadingClients} onClick={() => reloadClients} sx={{marginRight: '16px'}} />
                  {hasPermissionReadClients && (
                    <Button
                      variant='contained'
                      onClick={() => {
                        window.open('/registers/clients?new')
                      }}
                      height='2.7em'
                      width='13.5em'
                      fontFamily='Inter'
                      fontSize='0.9rem'>
                      <AddCircleOutlineIcon width='20px' height='20px' />
                      Adicionar Cliente
                    </Button>
                  )}
                </Box>
                <Box width='100%' display='flex' direction='column' rowGap='10px'>
                  <CompaniesSelect
                    selectLabel='Empresa'
                    selectSize='small'
                    setSelectedCompaniesIds={setSelectedCompanyId}
                    companiesScroll={companiesScroll}
                    setCompaniesScroll={setCompaniesScroll}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    companyNames={companyNames}
                    setCompanyNames={setCompanyNames}
                    multiple={false}
                  />
                  <AppSearch value={serversSearch} onChange={(e) => setServersSearch(e.target.value)} />
                </Box>
                <Box>
                  <Box overflow='auto' display='flex' justify='flex-start' align='center' direction='column' height='440px'>
                    {clients.map((client, index) => (
                      <Box
                        key={`client-${index}-${client.id}`}
                        onClick={() => {
                          const apiUser = client.company?.apiUser
                          if (!apiUser) return
                          setBrainBox({...brainBox, clientId: client.id, companyUsername: apiUser})
                        }}
                        width={props.isFullHd ? '600px' : '435px'}>
                        <Button variant={brainBox.clientId === client.id ? 'contained' : 'outlined'} width='100%' margin='16px 0px'>
                          {client.name}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            <Box>
              <Typography color='#FF9900' variant='span'>
                *Você não possui permissão para ver a lista de clientes
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default BrainBoxClient
