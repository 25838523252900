import styled from 'styled-components'

export const TopBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  @media (max-width: 767px) {
    gap: 10px;
    overflow: auto;
    padding-bottom: 10px;
    width: 100%;
  }
`
