import {Box} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {useCompany} from 'src/contexts/CompanyContext'
import api from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import TopBar from '../../components/top-bar/TopBar'
import {AdvancedFilters, FilterSelectedFinal} from '../../components/advancedFilters/AdvancedFilters'
import {TransparentButton} from '../../components/advancedFilters/AdvancedFiltersBase'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import deleteIconBlue from '../../images/deleteIconBlue.svg'
import {ContainerFilters, FiltersSelectedContainer} from '../alarms/AlarmsBase'
import BrainBoxAddModal from './components/BrainBoxAddModal'
import BrainBoxGridTable from './components/BrainBoxGrid'
import BrainBoxOptionsMenu from './components/BrainBoxOptionsMenu'
import BrainBoxProvider, {useBrainBoxContext} from './context/BrainBoxContext'

type RegisterFilter = {
  isArmed: boolean | null
  isOnline: boolean | null
}

const initialFilterValue = {
  isArmed: null,
  isOnline: null,
}

const searchBrainBox = [
  {id: '1', label: 'Nome da Brain Box'},
  {id: '2', label: 'Nome do Cliente'},
]

function BrainBoxInner() {
  const hasDeleteBrainBoxPermission = ValidatePermissions('brainbox:delete')
  const hasCreateBrainBoxPermission = ValidatePermissions('brainbox:create')
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanies} = useCompany()
  const [_brainBox, setBrainBox] = useState<BrainBox[]>([])
  const menuOpen = Boolean(menuEl)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [brainBoxPage, _setBrainBoxPage] = useState({page: 1, pageSize: 20})
  const [loadingBrainBoxDataGrid, setLoadingBrainBoxDataGrid] = useState<boolean>(false)
  const [searchFilterSelected, setSearchFilterSelected] = useState<string>('Nome da Brain Box')
  const [brainBoxSearch, setBrainBoxSearch] = useState('')
  const [currentFilters, setCurrentFilters] = useState<RegisterFilter>(initialFilterValue)
  const brainBoxSearchDebounce = useDebounce(brainBoxSearch)
  const blockBrainboxRequests = useRef(false)

  function setBrainBoxPage(page: number) {
    _setBrainBoxPage((prev) => ({...prev, page}))
  }

  function setBrainBoxPageSize(pageSize: number) {
    _setBrainBoxPage((prev) => ({...prev, pageSize}))
  }

  const {openBrainBoxAddModal, selectedIdsBrainBox, showOptionsButton, setSelectedIdsBrainBox} = useBrainBoxContext()

  useEffect(() => {
    setBrainBoxPage(1)
  }, [currentFilters.isArmed, currentFilters.isOnline, brainBoxSearchDebounce, selectedCompanies])

  useEffect(() => {
    getAllBrainbox()
  }, [enqueueSnackbar, brainBoxPage])

  const getAllBrainbox = async () => {
    if (brainBoxPage.page === 0) return
    setLoadingBrainBoxDataGrid(true)
    if (blockBrainboxRequests.current) return
    blockBrainboxRequests.current = true
    try {
      const response = await api.brainBox.getAll({
        page: brainBoxPage.page === 0 ? 1 : brainBoxPage.page,
        pageSize: brainBoxPage.pageSize,
        includes: ['client'],
        filter: {
          companyIds: selectedCompanies,
          isArmed: currentFilters.isArmed !== null ? currentFilters.isArmed : undefined,
          isOnline: currentFilters.isOnline !== null ? currentFilters.isOnline : undefined,
        },
        search: {
          brainBoxName: searchFilterSelected === 'Nome da Brain Box' ? brainBoxSearchDebounce : undefined,
          clientName: searchFilterSelected === 'Nome do Cliente' ? brainBoxSearchDebounce : undefined,
        },
      })
      setBrainBox(response.data.data.entities)
      setTotalElements(response.data.data.totalElements)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar tabela de Brain Box(es)')
    } finally {
      blockBrainboxRequests.current = false
      setLoadingBrainBoxDataGrid(false)
    }
  }

  const handleDeleteBrainbox = async (brainBoxes?: number[]) => {
    try {
      let brainBoxToDelete = brainBoxes ?? selectedIdsBrainBox
      await api.brainBox.deleteMany(brainBoxToDelete)
      enqueueSnackbar('Brain Box(es) deletada(s) com sucesso', {variant: 'success'})
      setSelectedIdsBrainBox([])
      getAllBrainbox()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao excluir Brain Box')
    }
  }

  const [initalStatusFilter, setInitalStatusFilter] = useState([
    {id: '1', label: 'Online'},
    {id: '2', label: 'Offline'},
  ])

  const [initalSituationFilter, setInitalSituationFilter] = useState([
    {id: '1', label: 'Armado'},
    {id: '2', label: 'Desarmado'},
  ])

  const registerFilters = [
    {
      id: '1',
      label: 'Status',
      itemsOnMenu: initalStatusFilter,
    },
    {
      id: '2',
      label: 'Situação',
      itemsOnMenu: initalSituationFilter,
    },
  ]

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  const onChangeSelect = (e: DropdownItem) => {
    setShowSearchSelected(e)
    setSearchFilterSelected(e.label)
  }

  const handlePartialSetFilter = (newFilter: FilterSelectedFinal) => {
    if (!initalStatusFilter.length && newFilter.id === '1') return
    if (!initalSituationFilter.length && newFilter.id === '2') return
    setDisabledSelectFilters(true)
    const moreFilters = [...chosenFilters, newFilter]
    setChosenFilters(moreFilters)
    setShowMoreFilters(true)
  }

  const [showMoreFilters, setShowMoreFilters] = useState(false)
  const [chosenFilters, setChosenFilters] = useState<FilterSelectedFinal[]>([])
  const [disabledSelectFilters, setDisabledSelectFilters] = useState(false)

  const handleSetPartialFilters = (value: DropdownItem, index: number) => {
    setDisabledSelectFilters(false)
    const newFilter = [...chosenFilters]
    newFilter[index].selectedItem = value
    if (newFilter[index].label === 'Status') {
      setInitalStatusFilter((prev) => prev.filter((it) => it.label !== value.label))
      newFilter[index].itemsOnMenu = initalSituationFilter
      setCurrentFilters((prev) => {
        return {isOnline: value.label === 'Online', isArmed: prev.isArmed}
      })
    }
    if (newFilter[index].label === 'Situação') {
      setInitalSituationFilter((prev) => prev.filter((it) => it.label !== value.label))
      newFilter[index].itemsOnMenu = initalSituationFilter
      setCurrentFilters((prev) => {
        return {isOnline: prev.isOnline, isArmed: value.label === 'Armado'}
      })
    }
    setChosenFilters(newFilter)
  }

  const deleteFilter = (index: number) => {
    const deleteItem = chosenFilters[index].selectedItem?.label
    if (!deleteItem) return

    const deleted = {
      id: chosenFilters[index].selectedItem?.id ?? '',
      label: deleteItem,
    }

    if (chosenFilters[index].label === 'Status') {
      setCurrentFilters((prev) => {
        return {isOnline: null, isArmed: prev.isArmed}
      })

      setInitalStatusFilter((prev) => [...prev, deleted])
    }
    if (chosenFilters[index].label === 'Situação') {
      setCurrentFilters((prev) => {
        return {isOnline: prev.isOnline, isArmed: null}
      })
      setInitalSituationFilter((prev) => [...prev, deleted])
    }

    const deletedFilters = chosenFilters.filter((it) => it.selectedItem?.label !== deleteItem)
    setChosenFilters(deletedFilters)
    if (deletedFilters.length <= 0) {
      setShowMoreFilters(false)
    }
  }

  const deleteAllFilters = () => {
    setCurrentFilters({isOnline: null, isArmed: null})
    setDisabledSelectFilters(false)
    setShowMoreFilters(false)
    setChosenFilters([])
    setInitalStatusFilter([
      {id: '1', label: 'Online'},
      {id: '2', label: 'Offline'},
    ])
    setInitalSituationFilter([
      {id: '1', label: 'Armado'},
      {id: '2', label: 'Desarmado'},
    ])
  }

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton onClick={() => getAllBrainbox()} loading={loadingBrainBoxDataGrid} />
            <SelectWithSearchComponent
              searchBy={searchBrainBox}
              setSearchSelected={onChangeSelect}
              searchSelected={showSearchSelected}
              inputValue={brainBoxSearch}
              inputSearch={setBrainBoxSearch}
              filterBy={registerFilters}
              setSelectedFilter={handlePartialSetFilter}
              disabledSelectFilter={disabledSelectFilters}
              widthOnSelect={'310px'}
            />
          </Box>

          <RenderIfConditionIsMet condition={hasCreateBrainBoxPermission}>
            <AppAddOrOptionsButton
              text='Adicionar uma brain box'
              showOptions={showOptionsButton && hasDeleteBrainBoxPermission}
              onClickAdd={() => openBrainBoxAddModal()}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
              disabled={!hasCreateBrainBoxPermission && !showOptionsButton}
            />
          </RenderIfConditionIsMet>
        </Box>

        <Box marginTop='15px' align='center' display='flex' justifyContent='center'>
          {showMoreFilters && (
            <ContainerFilters>
              <FiltersSelectedContainer>
                {chosenFilters.map((it, index) => {
                  return <AdvancedFilters index={index} filtersSelected={it} setFiltersSelected={handleSetPartialFilters} deleteFilter={deleteFilter} />
                })}
              </FiltersSelectedContainer>

              <TransparentButton onClick={() => deleteAllFilters()}>
                <Box display='flex' columnGap='10px'>
                  <img src={deleteIconBlue} alt='limpar filtro' />
                  <p>Limpar Seleção</p>
                </Box>
              </TransparentButton>
            </ContainerFilters>
          )}
        </Box>
      </TopBar>

      <BrainBoxGridTable
        _brainBox={_brainBox}
        getAllBrainbox={getAllBrainbox}
        setBrainBoxPage={setBrainBoxPage}
        setBrainBoxPageSize={setBrainBoxPageSize}
        brainBoxPage={brainBoxPage}
        loadingBrainBoxDataGrid={loadingBrainBoxDataGrid}
        totalElements={totalElements}
        deleteBrainBox={handleDeleteBrainbox}
      />

      <BrainBoxOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onClickDelete={() => {
          handleDeleteBrainbox()
        }}
      />
    </Box>
  )
}

function BrainBoxPage() {
  return (
    <BrainBoxProvider>
      <BrainBoxInner />
      <BrainBoxAddModal />
    </BrainBoxProvider>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default BrainBoxPage
