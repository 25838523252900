import styled from 'styled-components'
import {ModalBackgroundStyleProps, ModalStyleProps} from './ModalProps'

export const ModalBackground = styled.div<ModalBackgroundStyleProps>`
  position: fixed;
  inset: 0px;
  background-color: ${({bgBackgroundColor, theme}) => bgBackgroundColor ?? theme.modal?.bgBackgroundColor ?? 'rgba(0, 0, 0, 0.7)'};
`

export const ModalContainer = styled.div<ModalStyleProps>`
  width: ${({width}) => width ?? 'fit-content'};
  height: ${({height}) => height ?? 'fit-content'};
  min-height: ${({minHeight}) => minHeight};

  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);

  background-color: ${({backgroundColor, theme}) => backgroundColor ?? theme.modal?.backgroundColor ?? 'inherit'};

  border-color: ${({borderColor, theme}) => borderColor ?? theme.modal?.borderColor ?? null};
  border-radius: ${({borderRadius, theme}) => borderRadius ?? theme.modal?.borderRadius ?? 'inherit'};

  box-shadow: ${({boxShadow, theme}) => boxShadow ?? theme.modal?.boxShadow ?? null};

  margin: ${({margin, theme}) => margin ?? theme.modal?.margin ?? null};
  margin-top: ${({marginTop, theme}) => marginTop ?? theme.modal?.marginTop ?? null};
  margin-left: ${({marginLeft, theme}) => marginLeft ?? theme.modal?.marginLeft ?? null};
  margin-right: ${({marginRight, theme}) => marginRight ?? theme.modal?.marginRight ?? null};
  margin-bottom: ${({marginBottom, theme}) => marginBottom ?? theme.modal?.marginBottom ?? null};
  padding: 0;
  @media (max-width: 1400px) {
    min-height: 400px;
    max-height: 670px;
  }
`
