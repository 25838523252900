import {Box, Menu, MenuItem} from '@mui/material'
import Delete from 'src/images/deleteIconBlack.svg'

type UserOptionsMenuProps = {
  open: boolean
  onClose: () => void
  onDelete: () => void
  anchorEl: HTMLElement | null
}

function UserOptionsMenu({open, anchorEl, onClose, onDelete}: UserOptionsMenuProps) {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='user-options-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{'aria-labelledby': 'user-options-menu'}}
      PaperProps={{style: {width: '8.9em', marginTop: 4}}}>
      <MenuItem onClick={onDelete}>
        <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', fontSize: '0.9em', alignItems: 'center'}}>
          <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter'}}>Deletar</Box>
        </Box>
      </MenuItem>
    </Menu>
  )
}

export default UserOptionsMenu
