import {Box, CircularProgress} from '@mui/material'

type LoadingProps = {
  color?: string
  width?: string
  height?: string
}

function Loading(loadingProps: LoadingProps) {
  return (
    <Box sx={styles.container}>
      <CircularProgress style={{color: loadingProps.color, width: loadingProps.width, height: loadingProps.height}} />
    </Box>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}
export default Loading
