import {Location} from 'react-router-dom'
import {
  AnalyticCamerasPermissions,
  B2cPermission,
  BrainboxPermissions,
  CameraMosaicPermissions,
  ClientsPermissions,
  CompaniesPermissions,
  CredentialsPermissions,
  DashboardPermissions,
  DevicesPermissions,
  EventsPermissions,
  FaceListPermissions,
  ImagensPermissions,
  LayoutsPermissions,
  LprPermissions,
  NotificationsPermissions,
  Permission,
  QuotasPermissions,
  ServerEntradaPermissions,
  ServerSaidaPermissions,
  UserPermissions,
} from 'src/common/utils/PermissionsList'

import clientsIcon from 'src/images/IconClient.svg'
import clientsIconSelected from 'src/images/IconClientSelected.svg'
import adminIcon from 'src/images/adminIcon.svg'
import adminIconSelected from 'src/images/adminIconSelected.svg'
import BrainboxSelected from 'src/images/brainboxSelected.svg'
import camerasIcon from 'src/images/cameras.svg'
import camerasIconSelected from 'src/images/camerasSelected.svg'
import companyIconSelected from 'src/images/companySelected.svg'
import credentialsIcon from 'src/images/credentialsIcon.svg'
import credentialsIconSelected from 'src/images/credentialsIconSelected.svg'
import dashboardIcon from 'src/images/dashboardIcon.svg'
import dashboardIconSelected from 'src/images/dashboardIconSelected.svg'
import dataCompanyIcon from 'src/images/dataCompanyIcon.svg'
import dataCompanyIconSelected from 'src/images/dataCompanyIconSelected.svg'
import eventIcon from 'src/images/eventIcon.svg'
import eventIconsSelected from 'src/images/eventIconsSelected.svg'
import facialRecognitionIcon from 'src/images/facialRecognitionIcon.svg'
import facialRecognitionSelectedIcon from 'src/images/facialRecognitionSelectedIcon.svg'
import IconBrainBox from 'src/images/iconBrainBox.svg'
import companyIcon from 'src/images/iconCompany.svg'
import IconOpen from 'src/images/iconOpen.svg'
import IconOpenSelected from 'src/images/iconOpenSelected.svg'
import quotaIcon from 'src/images/iconQuotas.svg'
import incomingServer from 'src/images/incomingServerIcon.svg'
import incomingServerSelected from 'src/images/incomingServerSelected.svg'
import layoutsIcon from 'src/images/layoutsIcon.svg'
import layoutsIconSelected from 'src/images/layoutsIconSelected.svg'
import licensePlateIcon from 'src/images/licensePlateIcon.svg'
import licensePlateIconSelected from 'src/images/licensePlateIconSelected.svg'
import MosaicIcon from 'src/images/mosaicIcon.svg'
import MosaicIconSelected from 'src/images/mosaicIconSelected.svg'
import outgoingServer from 'src/images/outgoingServer.svg'
import outgoingServerSelected from 'src/images/outgoingServerSelected.svg'
import quotaIconSelected from 'src/images/quotaSelected.svg'
import registersIcon from 'src/images/registersIcon.svg'
import registersIconSelected from 'src/images/registersIconSelected.svg'
import SettingsIcon from 'src/images/settings.svg'
import SettingsIconSelected from 'src/images/settingsSelected.svg'
import usersIcon from 'src/images/users.svg'
import usersIconSelected from 'src/images/usersSelected.svg'
import {User} from 'src/services/api'
import {InitialRoutesWithPermissions} from '../../../routes/RoutesPageFunctions'

export type SidebarItem = {
  name: string
  link: string
  icon: string
  iconOpen?: string
  iconOpenSelected?: string
  iconSelected: string
  submenu?: SidebarItem[]
  permission: Permission[]
  hidden?: boolean
}

export const allSidebarItems: SidebarItem[] = [
  {
    name: 'Acesso Negado',
    link: 'forbidden',
    icon: dashboardIcon,
    iconSelected: dashboardIconSelected,
    permission: [],
    hidden: true,
  },
  {
    name: 'Dashboard',
    link: 'dashboard',
    icon: dashboardIcon,
    iconSelected: dashboardIconSelected,
    permission: DashboardPermissions,
  },
  {
    name: 'Eventos',
    link: 'events',
    icon: eventIcon,
    iconSelected: eventIconsSelected,
    permission: EventsPermissions,
  },
  {
    name: 'Notificações',
    link: 'notifications',
    icon: '',
    iconSelected: '',
    permission: NotificationsPermissions,
    hidden: true,
  },
  {
    name: 'LPR',
    link: 'license-plate',
    icon: licensePlateIcon,
    iconSelected: licensePlateIconSelected,
    permission: LprPermissions,
  },
  {
    name: 'Mosaico',
    link: 'camera-mosaic',
    icon: MosaicIcon,
    iconSelected: MosaicIconSelected,
    permission: CameraMosaicPermissions,
  },
  {
    name: 'Imagens',
    link: 'transactions',
    icon: camerasIcon,
    iconSelected: camerasIconSelected,
    permission: ImagensPermissions,
  },
  {
    name: 'Camera Config B2C',
    link: 'camera-config',
    icon: dashboardIcon,
    iconSelected: dashboardIconSelected,
    permission: B2cPermission,
    hidden: true,
  },
  {
    name: 'Selecionar Planos',
    link: 'select-plan',
    icon: dashboardIcon,
    iconSelected: dashboardIconSelected,
    permission: B2cPermission,
    hidden: true,
  },
  {
    name: 'Status',
    link: 'status',
    icon: dashboardIcon,
    iconSelected: dashboardIconSelected,
    permission: B2cPermission,
    hidden: true,
  },
  {
    name: 'Cadastros',
    link: 'registers',
    icon: registersIcon,
    iconSelected: registersIconSelected,
    iconOpen: IconOpen,
    iconOpenSelected: IconOpenSelected,
    submenu: [
      {
        name: 'Dispositivos',
        link: 'registers/devices',
        icon: camerasIcon,
        iconSelected: camerasIconSelected,
        permission: DevicesPermissions,
      },
      {
        name: 'Câmeras Analíticas',
        link: 'registers/analytic-cameras',
        icon: camerasIcon,
        iconSelected: camerasIconSelected,
        permission: AnalyticCamerasPermissions,
      },
      {
        name: 'Brain Boxes',
        link: 'registers/brainbox',
        icon: IconBrainBox,
        iconSelected: BrainboxSelected,
        permission: BrainboxPermissions,
      },
      {
        name: 'Clientes',
        link: 'registers/clients',
        icon: clientsIcon,
        iconSelected: clientsIconSelected,
        permission: ClientsPermissions,
        iconOpen: IconOpen,
        iconOpenSelected: IconOpenSelected,
        submenu: [
          {
            name: 'Reconhecimento Facial',
            link: 'registers/clients/facial-recognition',
            icon: facialRecognitionIcon,
            iconSelected: facialRecognitionSelectedIcon,
            permission: [],
          },
        ],
      },
      {
        name: 'Layouts',
        link: 'registers/layouts',
        icon: layoutsIcon,
        iconSelected: layoutsIconSelected,
        permission: LayoutsPermissions,
      },
      {
        name: 'Listas',
        link: 'registers/lists',
        icon: registersIcon,
        iconSelected: registersIconSelected,
        permission: FaceListPermissions,
      },
      {
        name: 'Usuários',
        link: 'registers/users',
        icon: usersIcon,
        iconSelected: usersIconSelected,
        permission: UserPermissions,
      },
    ],
    permission: [],
  },
  {
    name: 'Configurações',
    link: 'settings',
    icon: SettingsIcon,
    iconSelected: SettingsIconSelected,
    iconOpen: IconOpen,
    iconOpenSelected: IconOpenSelected,
    submenu: [
      {
        name: 'Credenciais',
        link: 'settings/credentials',
        icon: credentialsIcon,
        iconSelected: credentialsIconSelected,
        permission: CredentialsPermissions,
      },
      {
        name: 'Dados da Empresa',
        link: 'settings/company-data',
        icon: dataCompanyIcon,
        iconSelected: dataCompanyIconSelected,
        permission: CompaniesPermissions,
      },
      {
        name: 'Integrações de Entrada',
        link: 'settings/inputs',
        icon: incomingServer,
        iconSelected: incomingServerSelected,
        permission: ServerEntradaPermissions,
      },
      {
        name: 'Integrações de Saída',
        link: 'settings/outputs',
        icon: outgoingServer,
        iconSelected: outgoingServerSelected,
        permission: ServerSaidaPermissions,
      },
    ],
    permission: [],
  },
  {
    name: 'Administrativo',
    link: 'administrative',
    icon: adminIcon,
    iconSelected: adminIconSelected,
    iconOpen: IconOpen,
    iconOpenSelected: IconOpenSelected,
    submenu: [
      {
        name: 'Cotas',
        link: 'administrative/quotas',
        icon: quotaIcon,
        iconSelected: quotaIconSelected,
        permission: QuotasPermissions,
      },
      {
        name: 'Empresas',
        link: 'administrative/companies',
        icon: companyIcon,
        iconSelected: companyIconSelected,
        permission: CompaniesPermissions,
      },
    ],
    permission: [],
  },
]

export function resolveSidebarTitle(location: Location, user?: User | null) {
  const pathname = location.pathname
  const locationSelected = allSidebarItems.find((it) => pathname.includes(it.link!))
  const submenu = locationSelected?.submenu?.find((it) => pathname.includes(it.link))
  return pathname === '/' ? InitialRoutesWithPermissions(user).name : `${locationSelected?.name}${submenu ? ` - ${submenu.name}` : ''}`
}
