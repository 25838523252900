import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {Box, BoxProps, Button, ButtonProps} from '@mui/material'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {TitleOnButton} from './AppAddButtonBase'

type AppAddButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  sx?: ButtonProps['sx']
  text?: string
  size?: number
  children?: React.ReactNode
  ButtonProps?: ButtonProps
  BoxProps?: BoxProps
  disabled?: boolean
}

function AppAddButton({text, onClick, sx, size = 18, ButtonProps, BoxProps, children, disabled}: AppAddButtonProps) {
  return (
    <Box {...BoxProps}>
      <Button variant='contained' sx={[styles.button].concat((sx as any) || {})} onClick={onClick} disabled={disabled} {...ButtonProps}>
        {children || (
          <Box display='flex' columnGap={'5px'} alignItems='center' justifyContent='center'>
            <AddCircleOutlineIcon sx={{width: size, height: size}} />
            <TitleOnButton>{text}</TitleOnButton>
          </Box>
        )}
      </Button>
    </Box>
  )
}

const styles = createStyleSheet({
  button: {
    display: 'flex',
    alignItems: 'center',

    height: '40px',

    borderRadius: '8px',
    cursor: 'pointer',
    color: 'white',

    textTransform: 'initial',

    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '0.875em',
    fontWeight: '500',
    fontStyle: 'normal',

    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
})

export default AppAddButton
