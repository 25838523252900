import AddIcon from '@mui/icons-material/Add'
import RefreshIcon from '@mui/icons-material/Refresh'
import LoadingButton from '@mui/lab/LoadingButton'
import {Button, FormControl, MenuItem, Select, Tooltip} from '@mui/material'
import Box from '@mui/material/Box'
import {useEffect} from 'react'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Loading from '../../../../../components/Loading'
import {CameraOriginType} from '../../../../../services/api/endpoints/CameraEndpoint'
import {SERVER_TYPE_DGUARD, SERVER_TYPE_DIGIFORT, useCamRegisterModalContext2} from '../../../context/CameraRegisterContext'
import TransferList, {TransferListData} from './TransferList'
import {Item} from './TransferList/types'

function selectServersToShow(servers: any[], selectedTypeIntegration?: CameraOriginType | null) {
  if (!selectedTypeIntegration) {
    return servers
  } else if (selectedTypeIntegration === 'Digifort') {
    return servers.filter((it) => it.serverTypeId === SERVER_TYPE_DIGIFORT)
  } else if (selectedTypeIntegration === 'D-Guard') {
    return servers.filter((it) => it.serverTypeId === SERVER_TYPE_DGUARD)
  } else {
    throw new Error('Unreachable')
  }
}

type CameraRegisterModalProps = {
  rightItems: Item[]
  setRightItems: (itens: Item[]) => void
}

function CameraRegisterModal(props: CameraRegisterModalProps) {
  const {rightItems, setRightItems} = props

  const {loadingThirdStep, serverShow, setLeftItems, loadServers, leftItems, selectedTypeIntegration, setSelectedServer, selectedServer, isLoadingCameras} =
    useCamRegisterModalContext2()

  const serversToShow = selectServersToShow(serverShow, selectedTypeIntegration)
  useEffect(() => {
    loadServers()
  }, [])

  const onChangeTransferList = (data: TransferListData) => {
    setLeftItems(data.leftItems)
    setRightItems(data.rightItems)
  }

  return loadingThirdStep ? (
    <Box sx={{display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
      <Loading color='blue' width='3em' height='3em' />
    </Box>
  ) : (
    <Box sx={{marginTop: 2}}>
      <Box sx={{display: 'flex', gap: 1.5}}>
        <FormControl fullWidth>
          <Select
            value={(selectedServer?.dguardServerGuid ? selectedServer.id + '-' + selectedServer.dguardServerGuid : selectedServer?.id) ?? ''}
            onChange={(e) => {
              const id = Number(e.target.value.toString().split('-')[0])
              const dguardServerGuid = e.target.value.toString().split('-')[1] ? '{' + e.target.value.toString().split('{')[1] : undefined
              setSelectedServer({...serversToShow.find((server) => server.id === id), id, dguardServerGuid, name: '', ip: ''})
            }}
            sx={styles.selectModal}
            disabled={isLoadingCameras}>
            {serversToShow.map((server) => {
              if (server.dguardServerGuid) {
                return (
                  <MenuItem
                    key={'server-' + server.id + '-' + server.dguardServerGuid}
                    value={server.id + '-' + server.dguardServerGuid}
                    sx={styles.selectModal}>
                    {`${server.name} - ${server.ip} - ${server.dguardServerName}`}
                  </MenuItem>
                )
              } else {
                return (
                  <MenuItem key={'server-' + server.id} value={server.id} sx={styles.selectModal}>
                    {`${server.name} - ${server.ip}`}
                  </MenuItem>
                )
              }
            })}
          </Select>
        </FormControl>
        <Tooltip arrow title='Atualizar servidores' placement='top-end'>
          <LoadingButton
            sx={styles.buttonReloadServer}
            size='medium'
            variant='contained'
            loading={isLoadingCameras}
            onClick={() => {
              loadServers()
            }}>
            <RefreshIcon />
          </LoadingButton>
        </Tooltip>
        <Tooltip arrow title='Adicionar servidor' placement='top-end'>
          <Button
            variant='contained'
            sx={styles.buttonAddServer}
            onClick={() => {
              window.open('/settings/inputs?new')
            }}>
            <AddIcon sx={{size: 'large'}} />
          </Button>
        </Tooltip>
      </Box>
      <TransferList disabled={isLoadingCameras} leftItems={leftItems} rightItems={rightItems} onChange={onChangeTransferList} />
    </Box>
  )
}
const styles = createStyleSheet({
  titleModal: {
    fontSize: '18px',
  },
  selectModal: {
    fontFamily: 'Inter',
    height: '2rem',
    fontSize: '14px',
  },
  buttonAddServer: {
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    minWidth: 0,
    minHeight: 0,
    height: '2rem',
    width: '2rem',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  buttonReloadServer: {
    textTransform: 'initial',
    minWidth: 0,
    minHeight: 0,
    height: '2rem',
    width: '2rem',
    backgroundColor: '#20bc30',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00850e',
    },
  },
})

export default CameraRegisterModal
