import {Stepper, StepsTitles} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {BtnClose} from 'src/components/BtnClose'
import {ValidatePermissions} from '../../../common/utils/ValidatePermissions'
import api, {CameraOriginType, RtspCameraInfo} from '../../../services/api'
import {generateLinkRtsp, generateLinkRtspDVR} from '../../../utilities/LinkGenerator'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'
import {StepperModalContainer} from '../../camera-config/components/object-detection/newComponents/styles'
import {Item} from '../components/CameraAddModal/CameraRegisterModal/TransferList/types'
import {AddCamera, CameraRTSP, useCamRegisterModalContext2} from '../context/CameraRegisterContext'
import AccessCredentialsStep from './AccessCredentialsStep'
import ConfigurationChannelsStep, {DvrCameras} from './ConfigurationChannelsStep'
import GeneralInformationStep from './GeneralInformationStep'

type CreateCameraStepperProps = {
  closeModal: (isOpen: boolean) => void
  reloadCameras: () => void
}

export type Camera = {
  host: string
  stream: number
  rtspPort: number
  manufacturer: string
  user: string
  password: string
  httpPort: number
  channel: number
  link?: string
}

export type ChosenObject = {
  clientId: number
  companyId: number
  serverName: string
  cameraRTSP: Camera
  createRTSPWithLink: boolean
  listsOfCamerasonDvr: DvrCameras[]
}

const OriginOnCamera: {[x: string]: CameraOriginType} = {
  'D-Guard': 'D-Guard',
  Digifort: 'Digifort',
  'Câmera RTSP': 'RTSP',
  'DVR / NVR': 'RTSP-DVR',
  Hikvision: 'Hikvision',
}

type ChildrenComponentEnumType = {
  [key: number]: JSX.Element
}

const CreateCameraStepper = (props: CreateCameraStepperProps) => {
  const {stepsClose} = useCamRegisterModalContext2()
  const {closeModal, reloadCameras} = props
  const {enqueueSnackbar} = useSnackbar()

  const hasRtspPermissionCreate = ValidatePermissions('rtsp-camera:create')

  const [objectChosenToCreate, setObjectChosenToCreate] = useState<ChosenObject>({
    clientId: 0,
    companyId: 0,
    serverName: '',
    createRTSPWithLink: false,
    cameraRTSP: {
      manufacturer: '',
      stream: -1,
      host: '',
      httpPort: 80,
      rtspPort: 554,
      user: '',
      password: '',
      channel: 1,
    },
    listsOfCamerasonDvr: [],
  })

  const [rightItems, setRightItems] = useState<Item[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const setDiffOnChosenObject = useCallback((newChosen: Partial<ChosenObject>) => {
    setObjectChosenToCreate((chosen) => {
      return {...chosen, ...newChosen}
    })
  }, [])

  const setDiffOnCameraObject = useCallback((newCamera: Partial<CameraRTSP>) => {
    setObjectChosenToCreate((chosen) => {
      const camera = chosen.cameraRTSP
      return {...chosen, cameraRTSP: {...camera, ...newCamera}}
    })
  }, [])

  const setDiffOnListsOfCamerasonDvr = (newCamera: DvrCameras[]) => {
    setObjectChosenToCreate((chosen) => {
      return {...chosen, listsOfCamerasonDvr: newCamera}
    })
  }

  const nextFunction = (index: number, resume: string, advanceTo?: number) => {
    const newSteps = [...steps]
    newSteps[index - 1].stepperStatus = 'concluded'
    newSteps[index - 1].resume = resume
    if (advanceTo) newSteps[advanceTo].stepperStatus = 'active'
    else newSteps[index].stepperStatus = 'active'
    setSteps(newSteps)
  }

  const returnFunction = (index: number, goBackTo?: number) => {
    const newSteps = [...steps]
    newSteps[index + 1].stepperStatus = 'pending'
    delete newSteps[index + 1].resume
    if (goBackTo || goBackTo === 0) newSteps[goBackTo].stepperStatus = 'active'
    else newSteps[index].stepperStatus = 'active'
    setSteps(newSteps)
  }

  const finishFunction = () => {
    setIsLoading(true)
    if (objectChosenToCreate.createRTSPWithLink) handleDecideCreatingCameraWithDirectLink()
    else handleDecideCreatingCameraWithAddressInfo()
  }

  const handleDecideCreatingCameraWithDirectLink = () => {
    if (objectChosenToCreate.serverName === 'DVR / NVR') handleCreateDVRWithDirectLink()

    if (objectChosenToCreate.serverName === 'Câmera RTSP') handleCreateCameraRTSPWithDirectLink()
  }

  const handleDecideCreatingCameraWithAddressInfo = () => {
    if (objectChosenToCreate.serverName === 'D-Guard' || objectChosenToCreate.serverName === 'Digifort') handleCreateCameraVMS()

    if (objectChosenToCreate.serverName === 'DVR / NVR') handleCreateDVR()

    if (objectChosenToCreate.serverName === 'Câmera RTSP') handleCreateCameraRTSP()
  }

  const handleCreateCameraRTSPWithDirectLink = async () => {
    try {
      if (!objectChosenToCreate.cameraRTSP.link) return
      const rtspInfo = {
        link: objectChosenToCreate.cameraRTSP.link,
      }

      const response = await api.camera.addMany({
        origin: 'RTSP',
        data: [{name: objectChosenToCreate.listsOfCamerasonDvr[0].name, clientId: objectChosenToCreate.clientId, isOnline: false, rtspInfo}],
        companyId: objectChosenToCreate.companyId,
        soft: true,
      })
      if (!response.data.data.totalRejected) {
        enqueueSnackbar('Câmera adicionada com sucesso', {
          variant: 'success',
        })

        reloadCameras()
        closeModal(false)
      } else {
        enqueueSnackbar(`Alguns dispositivos não foram criados`, {
          variant: 'warning',
        })
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar a(s) câmera(s)')
    } finally {
      stepsClose()
      setIsLoading(false)
    }
  }

  const handleCreateDVRWithDirectLink = async () => {
    try {
      if (!objectChosenToCreate.cameraRTSP.link) return
      const rtspInfo = {
        link: objectChosenToCreate.cameraRTSP.link,
      }

      await api.camera.addMany({
        origin: 'RTSP-DVR',
        data: [
          {
            name: objectChosenToCreate.listsOfCamerasonDvr[0].name,
            clientId: objectChosenToCreate.clientId,
            isOnline: false,
            rtspInfo: rtspInfo,
          },
        ],
        companyId: objectChosenToCreate.companyId,
        soft: true,
      })
      enqueueSnackbar('Dispositivos adicionados com sucesso', {
        variant: 'success',
      })

      reloadCameras()
      closeModal(false)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar dispositivo')
    } finally {
      setIsLoading(false)

      stepsClose()
    }
  }

  const handleCreateCameraRTSP = async () => {
    try {
      const manufacturer = objectChosenToCreate.cameraRTSP.manufacturer
      let codec = undefined
      if (manufacturer === 'Hikvision') codec = 'h.264'

      const addressInfo = {
        host: objectChosenToCreate.cameraRTSP.host,
        stream: objectChosenToCreate.cameraRTSP.stream,
        rtspPort: objectChosenToCreate.cameraRTSP.rtspPort,
        manufacturer: objectChosenToCreate.cameraRTSP.manufacturer,
        user: objectChosenToCreate.cameraRTSP.user,
        password: objectChosenToCreate.cameraRTSP.password,
        httpPort: objectChosenToCreate.cameraRTSP.httpPort,
        codec: codec,
        channel: objectChosenToCreate.listsOfCamerasonDvr[0].channel,
      }

      const link = generateLinkRtsp({addressInfo, camera: null, enqueueSnackbar})
      if (!link) {
        enqueueSnackbar('Link inválido, tente novamente', {variant: 'warning'})
        return
      }

      const rtspInfo = {
        host: objectChosenToCreate.cameraRTSP.host,
        rtspPort: objectChosenToCreate.cameraRTSP.rtspPort,
        manufacturer: objectChosenToCreate.cameraRTSP.manufacturer,
        user: objectChosenToCreate.cameraRTSP.user,
        password: objectChosenToCreate.cameraRTSP.password,
        codec: codec,
        httpPort: objectChosenToCreate.cameraRTSP.httpPort,
        stream: objectChosenToCreate.cameraRTSP.stream,
        channel: objectChosenToCreate.listsOfCamerasonDvr[0].channel,
        link,
      }

      const response = await api.camera.addMany({
        origin: 'RTSP',
        data: [{name: objectChosenToCreate.listsOfCamerasonDvr[0].name, clientId: objectChosenToCreate.clientId, isOnline: false, rtspInfo}],
        companyId: objectChosenToCreate.companyId,
        soft: true,
      })
      if (!response.data.data.totalRejected) {
        enqueueSnackbar('Câmera adicionada com sucesso', {
          variant: 'success',
        })

        reloadCameras()
        closeModal(false)
      } else {
        enqueueSnackbar(`Alguns dispositivos não foram criados`, {
          variant: 'warning',
        })
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar a(s) câmera(s)')
    } finally {
      stepsClose()
      setIsLoading(false)
    }
  }

  const handleCreateDVR = async () => {
    try {
      let codec: string | undefined = undefined
      if (objectChosenToCreate.cameraRTSP.manufacturer === 'Hikvision') codec = 'h.264'
      const dataToSend: Array<{
        name: string
        clientId: number
        isOnline?: boolean
        rtspInfo?: RtspCameraInfo
      }> = []

      objectChosenToCreate.listsOfCamerasonDvr.forEach((cam) => {
        if (!cam.checkbox) return
        const addressInfo = {
          host: objectChosenToCreate.cameraRTSP.host,
          stream: objectChosenToCreate.cameraRTSP.stream,
          rtspPort: objectChosenToCreate.cameraRTSP.rtspPort,
          manufacturer: objectChosenToCreate.cameraRTSP.manufacturer,
          user: objectChosenToCreate.cameraRTSP.user,
          password: objectChosenToCreate.cameraRTSP.password,
          codec: codec,
          channel: cam.channel,
          httpPort: objectChosenToCreate.cameraRTSP.httpPort,
        }
        const link = generateLinkRtspDVR({addressInfo, camera: null, enqueueSnackbar})
        if (!link) {
          enqueueSnackbar('Link inválido, tente novamente', {variant: 'warning'})
          return
        }

        const rtspInfo = {
          host: objectChosenToCreate.cameraRTSP.host,
          rtspPort: objectChosenToCreate.cameraRTSP.rtspPort,
          manufacturer: objectChosenToCreate.cameraRTSP.manufacturer,
          user: objectChosenToCreate.cameraRTSP.user,
          password: objectChosenToCreate.cameraRTSP.password,
          codec: codec,
          httpPort: objectChosenToCreate.cameraRTSP.httpPort,
          stream: +cam.chosedStream.id,
          channel: cam.channel,
          link,
        }

        const data = {
          name: cam.name,
          clientId: objectChosenToCreate.clientId,
          isOnline: false,
          rtspInfo: rtspInfo,
        }

        dataToSend.push(data)
      })

      const response = await api.camera.addMany({
        origin: 'RTSP-DVR',
        data: dataToSend,
        companyId: objectChosenToCreate.companyId,
        soft: true,
      })

      if (!response.data.data.totalRejected) {
        enqueueSnackbar('Dispositivos adicionados com sucesso', {
          variant: 'success',
        })

        reloadCameras()
        closeModal(false)
      } else {
        enqueueSnackbar(`Alguns dispositivos não foram criados`, {
          variant: 'warning',
        })
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, `Erro ao adicionar dispositivo`)
    } finally {
      stepsClose()
      setIsLoading(false)
    }
  }

  const handleCreateCameraVMS = async () => {
    try {
      const origin = OriginOnCamera[objectChosenToCreate.serverName]
      const items = rightItems
        .map((item) => {
          if (!item.data) return {} as AddCamera
          return item.data
        })
        .filter((it) => !!it)

      const data = items.map((item) => ({
        name: item.name,
        clientId: item.clientId,
        serverId: item.serverId,
        isOnline: false,
      }))

      const response = await api.camera.addMany({
        origin,
        data: data,
        companyId: objectChosenToCreate.companyId,
        soft: true,
      })

      if (!response.data.data.totalRejected) {
        enqueueSnackbar('Câmera(s) adicionada(s) com sucesso', {
          variant: 'success',
        })
        closeModal(false)
        reloadCameras()
      } else {
        enqueueSnackbar(`Alguns dispositivos não foram criados`, {
          variant: 'warning',
        })
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao adicionar a(s) câmera(s)')
    } finally {
      stepsClose()
      setIsLoading(false)
    }
  }

  const ChildrenComponentEnum: ChildrenComponentEnumType = useMemo(() => {
    return {
      0: <GeneralInformationStep chosen={objectChosenToCreate} nextFunction={nextFunction} returnFunction={closeModal} setChosen={setDiffOnChosenObject} />,
      1: (
        <AccessCredentialsStep
          chosen={objectChosenToCreate}
          setChosen={setDiffOnChosenObject}
          nextFunction={nextFunction}
          handleCreateCameraVMS={handleCreateCameraVMS}
          returnFunction={returnFunction}
          setCamera={setDiffOnCameraObject}
          setRightItems={setRightItems}
          rightItems={rightItems}
          clearContextComponent={stepsClose}
        />
      ),
      2: (
        <ConfigurationChannelsStep
          chosen={objectChosenToCreate}
          nextFunction={finishFunction}
          returnFunction={returnFunction}
          setChosen={setDiffOnListsOfCamerasonDvr}
          isLoading={isLoading}
        />
      ),
    }
  }, [objectChosenToCreate, setDiffOnChosenObject, rightItems, setRightItems])

  const [steps, setSteps] = useState<StepsTitles[]>([
    {
      stepperStageTitle: 'Passo 01',
      stepperTitle: 'Informações gerais',
      stepperStatus: 'active',
      children: ChildrenComponentEnum[0],
    },
    {
      stepperStageTitle: 'Passo 02',
      stepperTitle: 'Credenciais de acesso ',
      stepperStatus: 'pending',
      children: ChildrenComponentEnum[1],
    },
    {
      stepperStageTitle: 'Passo 03',
      stepperTitle: 'Configuração de canais ',
      stepperStatus: 'pending',
      children: ChildrenComponentEnum[2],
    },
  ])

  useEffect(() => {
    const newSteps = [...steps]
    newSteps.map((it, index) => (it.children = ChildrenComponentEnum[index]))
    setSteps(newSteps)
  }, [objectChosenToCreate, rightItems])

  useEffect(() => {
    if (!hasRtspPermissionCreate) {
      const newSteps = [steps[0], steps[1]]
      newSteps.map((it, index) => (it.children = ChildrenComponentEnum[index]))
      setSteps(newSteps)
    }

    if (objectChosenToCreate.serverName === 'Digifort' || objectChosenToCreate.serverName === 'D-Guard') {
      const newSteps = [...steps]
      newSteps.map((it, index) => (it.children = ChildrenComponentEnum[index]))
      newSteps[2].stepperStatus = 'disabled'
      setSteps(newSteps)
    } else {
      const newSteps = [...steps]
      newSteps.map((it, index) => (it.children = ChildrenComponentEnum[index]))
      newSteps[2].stepperStatus = 'pending'
      setSteps(newSteps)
    }
  }, [hasRtspPermissionCreate, objectChosenToCreate.serverName])

  return (
    <StepperModalContainer>
      <BtnClose
        onClose={() => {
          stepsClose()
          closeModal(false)
        }}
      />
      <Stepper steps={steps} />
    </StepperModalContainer>
  )
}

export default CreateCameraStepper
