import {Box, Button, Typography} from '@viptech/react-components'
import startOfDay from 'date-fns/startOfDay'
import sub from 'date-fns/sub'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useWindowSize} from 'usehooks-ts'
import CalendarIcon from '../../images/calendarIcon.svg'
import ClockIcon from '../../images/clockIcon.svg'
import Calendar from '../calendar/Calendar'
import {Input} from '../input/Input'
import Menu from '../menu/Menu'
import Separation from '../separation/Separation'
import {CalendarButton, ContainerInsideButton, RulerButton} from './CustomCalendarBase'

type CustomCalendarProps = {
  setDate: (dates: [Date, Date]) => void
  sizeToHideRuler?: number
  hideRuler?: boolean
  hideTime?: boolean
}

function CustomCalendar({setDate, sizeToHideRuler, hideRuler: isRulerHidden, hideTime}: CustomCalendarProps) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)
  const today = new Date()
  const tomorrow = new Date().setDate(today.getDate() + 1)
  const dateTomorrow = startOfDay(new Date(tomorrow))
  const hideRullerOnSize = sizeToHideRuler ?? 1420
  const valuesOnRuler = [
    {onClick: () => setDate([sub(new Date(), {minutes: 15}), new Date()]), label: '15min', responsiveLabel: 'Últimos 15 minutos'},
    {onClick: () => setDate([sub(new Date(), {minutes: 30}), new Date()]), label: '30min', responsiveLabel: 'Últimos 30 minutos'},
    {onClick: () => setDate([sub(new Date(), {hours: 1}), new Date()]), label: '1h', responsiveLabel: 'Última 1 hora'},
    {onClick: () => setDate([sub(new Date(), {hours: 6}), new Date()]), label: '6h', responsiveLabel: 'Últimas 6 horas'},
    {onClick: () => setDate([sub(new Date(), {hours: 12}), new Date()]), label: '12h', responsiveLabel: 'Últimas 12 horas'},
    {onClick: () => setDate([startOfDay(new Date()), new Date()]), label: 'Hoje', responsiveLabel: 'Hoje'},
  ]

  const [selectedButton, setSelectedButton] = useState<string>(valuesOnRuler[1].label)
  const [selectedButtonResponsive, setSelectedButtonResponsive] = useState<string>('')

  const [hideRuler, setHideRuler] = useState(isRulerHidden || false)
  const windowSize = useWindowSize()

  useEffect(() => {
    if (isRulerHidden) setHideRuler(true)
    else if (windowSize.width <= hideRullerOnSize) setHideRuler(true)
    else setHideRuler(false)
  }, [windowSize])

  const [initialDate, setInitialDate] = useState<Date | null>(new Date())
  const [finalDate, setFinalDate] = useState<Date | null>(new Date())

  const [dateToShow, setDateToShow] = useState<string>()

  const [initialTime, setInitialTime] = useState<string>('00:00')
  const [finalTime, setFinalTime] = useState<string>('23:59')
  const {enqueueSnackbar} = useSnackbar()

  const handleInsertDate = () => {
    if (initialDate !== null && finalDate !== null) {
      const addZeroInital = initialDate.getDate() < 10 ? '0' : ''
      const addZeroFinal = finalDate.getDate() < 10 ? '0' : ''

      const addZeroMonthInital = initialDate.getMonth() < 9 ? '0' : ''
      const addZeroMonthFinal = finalDate.getMonth() < 9 ? '0' : ''

      setDateToShow(
        ` ${addZeroInital}${initialDate.getDate()}/${addZeroMonthInital}${
          initialDate.getMonth() + 1
        }/${initialDate.getFullYear()} até ${addZeroFinal}${finalDate.getDate()}/${addZeroMonthFinal}${
          finalDate.getUTCMonth() + 1
        }/${finalDate.getFullYear()} `,
      )

      const [hour, minutes] = initialTime.split(':')
      initialDate.setHours(+hour)
      initialDate.setMinutes(+minutes)
      initialDate.setSeconds(0)

      const [finalHour, finalMinutes] = finalTime.split(':')
      finalDate.setHours(+finalHour)
      finalDate.setMinutes(+finalMinutes)
      finalDate.setSeconds(0)
      if (initialDate.getDate() === finalDate.getDate() && initialDate.getMonth() === finalDate.getMonth() && finalDate.getTime() < initialDate.getTime()) {
        setDateToShow('')
        enqueueSnackbar('O horário final não pode ser maior que o inicial', {variant: 'warning'})
        return
      }

      const sendDatesToFilter: [Date, Date] = [initialDate, finalDate]
      setDate(sendDatesToFilter)
    }
    setAnchor(null)
    setSelectedButton('')
    setSelectedButtonResponsive('')
  }

  return (
    <Box display='flex' columnGap='20px'>
      {!hideRuler && (
        <Box display='flex' border='1px solid #9AA4B2' borderRadius='8px' padding='0px 30px' height='38px' align='center' columnGap='28px'>
          {valuesOnRuler.map((values) => {
            return (
              <RulerButton
                key={values.label}
                selected={values.label === selectedButton}
                disabled={values.label === selectedButton}
                onClick={() => {
                  setSelectedButton(values.label)
                  values.onClick()
                  setDateToShow('')
                  setInitialDate(null)
                  setFinalDate(null)
                  setInitialTime('00:00')
                  setFinalTime('23:59')
                }}>
                {values.label}
              </RulerButton>
            )
          })}
        </Box>
      )}

      <Box>
        <CalendarButton onClick={(e) => setAnchor(e.currentTarget)}>
          <ContainerInsideButton>
            <img src={CalendarIcon} alt='personalizar data' />
            <p style={{minWidth: dateToShow ? '200px' : '140px', fontFamily: 'Inter, sans-serif', fontSize: '14px'}}>
              {dateToShow ? dateToShow : 'Personalizar data'}
            </p>
          </ContainerInsideButton>
        </CalendarButton>
        <Menu hideTriangle anchor={anchor} onClose={() => setAnchor(null)} borderRadius='8px' marginTop='10px' left='50em'>
          <Box display='flex' padding='15px' height='100%' columnGap='20px'>
            {hideRuler && !isRulerHidden && (
              <>
                <Box display='flex' direction='column' paddingTop='20px' justifyContent='flex-start' align='flex-start' rowGap='20px' paddingRight='10px'>
                  {valuesOnRuler.map((values) => {
                    return (
                      <RulerButton
                        key={values.label}
                        hover
                        selected={values.responsiveLabel === selectedButtonResponsive}
                        onClick={() => {
                          setSelectedButtonResponsive(values.responsiveLabel)
                          values.onClick()
                          setDateToShow(values.responsiveLabel)
                          setInitialDate(null)
                          setFinalDate(null)
                          setAnchor(null)
                        }}>
                        {values.responsiveLabel}
                      </RulerButton>
                    )
                  })}
                </Box>

                <Separation width='2px' height='500px' color='#D9D9D9' />
              </>
            )}
            <Box display='flex' direction='column' minWidth='332px'>
              <Calendar
                max={dateTomorrow}
                setInitalDate={setInitialDate}
                setFinalDate={setFinalDate}
                initialDateValue={initialDate}
                finalDateValue={finalDate}
              />
              <Box padding='10px 20px 20px' rowGap='5px' display='flex' direction='column'>
                {!hideTime && (
                  <>
                    <Typography variant='p' style={{fontWeight: 'bold'}}>
                      Horário
                    </Typography>

                    <Box display='flex' columnGap='20px' maxWidth='292px' minWidth='292px'>
                      <Input.Root
                        label='Início:'
                        height='32px'
                        labelFontSize='12px'
                        fontSize='12px'
                        labelColor='#344054'
                        type='time'
                        defaultValue={initialTime}
                        value={initialTime}
                        onChange={(e) => setInitialTime(e.target.value)}
                        borderColor='#D0D5DD'
                        leftIcon={<img src={ClockIcon} alt='horário inicial' />}
                      />
                      <Input.Root
                        label='Final:'
                        height='32px'
                        labelFontSize='12px'
                        fontSize='12px'
                        labelColor='#344054'
                        type='time'
                        defaultValue={finalTime}
                        value={finalTime}
                        onChange={(e) => setFinalTime(e.target.value)}
                        borderColor='#D0D5DD'
                        leftIcon={<img src={ClockIcon} alt='horário final' />}
                      />
                    </Box>
                  </>
                )}
                <Box style={{display: 'flex', alignSelf: 'flex-end', marginTop: hideTime ? '0px' : '50px'}}>
                  <Button
                    disabled={!initialDate || !initialTime || !finalDate || !finalTime}
                    disabledTextColor='#B9B9B9'
                    borderRadius='8px'
                    fontWeight={600}
                    fontSize='12px'
                    height='32px'
                    padding='0px 30px'
                    onClick={() => handleInsertDate()}>
                    Aplicar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Menu>
      </Box>
    </Box>
  )
}

export default CustomCalendar
