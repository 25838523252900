import api, {CameraConfiguration, Schedule} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export enum ReceiveModeOptionsEnum {
  CLIENT = 1,
  HOUR = 2,
  BRAIN_BOX = 3,
  ARMED = 4,
}

export const daysWeek = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S']

export function formatScheduleTime(schedule: Schedule) {
  const start = schedule.startTime.split('+', 1)[0]
  const end = schedule.endTime.split('+', 1)[0]
  return `${start} --> ${end}`
}

export function receiveMode(value: number) {
  switch (value) {
    case ReceiveModeOptionsEnum.CLIENT:
      return {
        receiveMode: {
          description: value,
          id: 1,
        },
        receiveModeId: 1,
      }

    case ReceiveModeOptionsEnum.HOUR:
      return {
        receiveMode: {
          description: value,
          id: 2,
        },
        receiveModeId: 2,
      }

    case ReceiveModeOptionsEnum.BRAIN_BOX:
      return {
        receiveMode: {
          description: value,
          id: 3,
        },
        receiveModeId: 3,
      }
    case ReceiveModeOptionsEnum.ARMED:
      return {
        receiveMode: {
          description: value,
          id: 4,
        },
        receiveModeId: 4,
      }
  }
}

export function isHidden(diffConfig: CameraConfiguration | null) {
  return diffConfig !== null && diffConfig.receiveModeId !== ReceiveModeOptionsEnum.HOUR && diffConfig.receiveModeId !== ReceiveModeOptionsEnum.BRAIN_BOX
}

export async function removeScheduleTime(id: number, enqueueSnackbar: any) {
  try {
    await api.schedule.deleteSchedule(id)
    enqueueSnackbar('Agendamento removido com sucesso', {
      variant: 'success',
    })
    return true
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover agendamento')
    return false
  }
}
