import {Box} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useAuth} from 'src/contexts/AuthContext'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Company} from 'src/services/api'
import {Quota} from 'src/services/api/endpoints/CompanyQuotaEndpoint'
import {Page} from 'src/types'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import QuotasGridTable from './components/QuotasGridTable'
import QuotasOptionsMenu from './components/QuotasOptionsMenu'
import QuotasRegisterModal from './components/QuotasRegisterModal'

function SettingsQuotas() {
  const initialQuota: Quota = {
    id: undefined,
    quota: undefined,
    used: undefined,
    resource: undefined,
  }
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)

  const [modalContent, setModalContent] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [typeMethod, setTypeMethod] = useState<string>('')
  const menuOpen = Boolean(menuEl)
  const [selectedQuotasIds, setSelectedQuotasIds] = useState<number[]>([])
  const [quotas, setQuotas] = useState<Quota[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const {user} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanies} = useCompany()
  const [quotasPage, setQuotasPage] = useState<Page>({page: 1, pageSize: 20})
  const [quotaSearch, setQuotaSearch] = useState<string>('')
  const quotaSearchDebounced = useDebounce(quotaSearch, 500)
  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [quota, setQuota] = useState<Quota>(initialQuota)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [companies, setCompanies] = useState<Company[]>([])
  const [companiesScrollQuotas, setCompaniesScrollQuotas] = useState<Company[]>([])

  const quantity = selectedQuotasIds.length
  const isPlural = quantity <= 1 ? '' : 's'
  const [loadingQuotas, setLoadingQuotas] = useState<boolean>(false)
  const [filterSelected, setFilterSelected] = useState<string>()
  const [filtersTypes] = useState(
    user.role === 'admin'
      ? [
          {id: '1', label: 'Empresa'},
          {id: '2', label: 'Recurso'},
        ]
      : [{id: '2', label: 'Recurso'}],
  )

  function withConfirmation(message: string, typeOption: string, title: string) {
    return () => {
      setMenuEl(null)
      setModalContent(title)
      setOpenModal(true)
      setTypeMethod(typeOption)
      setModalTitle(message)
    }
  }

  function isConfirmation(string: string) {
    setMenuEl(null)
  }

  function handleClose() {
    setOpen(false)
    setQuota(initialQuota)
  }

  async function loadQuotas() {
    setLoadingQuotas(true)
    try {
      const response2 = await api.quota.getAll({
        page: quotasPage.page === 0 ? 1 : quotasPage.page,
        pageSize: quotasPage.pageSize,
        search: {
          companyName: filterSelected?.includes('Empresa') ? quotaSearchDebounced : undefined,
          resourceName: filterSelected?.includes('Recurso') ? quotaSearchDebounced : undefined,
        },
        includes: ['resource', 'company'],
        filter: {companyIds: selectedCompanies},
      })

      const idsAll = response2.data.data.entities.map((it) => it.companyId!)

      const response = await api.company.getMany({
        filter: {
          ids: idsAll.filter((it, index, self) => index === self.indexOf(it)),
        },
      })

      setCompanies(response.data.data.entities)
      setQuotas(response2.data.data.entities)
      setTotalElements(response2.data.data.totalElements)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar servidores de integração')
    } finally {
      setLoadingQuotas(false)
    }
  }

  useEffect(() => {
    setQuotasPage((prev) => ({...prev, page: 1}))
  }, [quotaSearchDebounced, selectedCompanies])

  useEffect(() => {
    loadQuotas()
  }, [quotasPage])

  async function openEditModal(quota: Quota) {
    const response2 = await api.company.getMany({
      filter: {
        ids: [quota.companyId!],
      },
    })
    const companyUserScroll = companiesScrollQuotas.find((it) => it.id === quota.companyId!)

    if (!companyUserScroll) setCompaniesScrollQuotas([...companiesScrollQuotas, ...response2.data.data.entities])

    setIsCreate(false)
    setOpen(true)
    setQuota(quota)
  }

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  const onSelectChange = (e: DropdownItem) => {
    setShowSearchSelected(e)
    setFilterSelected(e.label)
  }

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={loadingQuotas} onClick={() => loadQuotas()} />

            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={filtersTypes}
              setSearchSelected={onSelectChange}
              searchSelected={showSearchSelected}
              inputValue={quotaSearch}
              inputSearch={setQuotaSearch}
            />
          </Box>
        </Box>
      </TopBar>
      <QuotasGridTable
        setSelectedQuotasIds={setSelectedQuotasIds}
        selectedQuotasIds={selectedQuotasIds}
        quotasUser={quotas}
        openEditModal={openEditModal}
        totalElements={totalElements}
        quotasPage={quotasPage}
        setQuotasPage={setQuotasPage}
        companies={companies}
        user={user!}
        loadingQuotas={loadingQuotas}
      />
      <QuotasRegisterModal
        isCreate={isCreate}
        open={open}
        handleClose={handleClose}
        loadQuotas={() => loadQuotas()}
        quotaUser={quota}
        setQuota={setQuota}
        closeModal={() => {
          if (!isCreate) {
            setQuota(initialQuota)
            setOpen(false)
          }
        }}
        companies={companiesScrollQuotas}
        setCompaniesScrollQuotas={setCompaniesScrollQuotas}
        user={user!}
      />
      <ConfirmationModal
        key={modalTitle}
        title={modalTitle}
        content={modalContent}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenModal(false)
              setMenuEl(null)
              isConfirmation(typeMethod)
            },
            variant: 'contained',
            color: '#009EFF',
          },
        ]}
      />

      <QuotasOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onDelete={withConfirmation(
          `Deletar cota${isPlural}`,
          'delete',
          `Esta ação resultará na exclusão da${isPlural} cota${isPlural} da${isPlural} empresa${isPlural}.`,
        )}
      />
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default SettingsQuotas
