import {Box, Menu, MenuItem} from '@mui/material'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import Delete from 'src/images/deleteIconBlack.svg'

type CameraOptionsMenuProps = {
  open: boolean
  anchorEl: null | HTMLElement
  onClose: () => void
  onClickOnline: () => void
  onClickOffline: () => void
  onClickDelete: () => void
}

function CameraOptionsMenu({open, anchorEl, onClose, onClickOnline, onClickOffline, onClickDelete}: CameraOptionsMenuProps) {
  const hasDeleteCameraPermission = ValidatePermissions('cameras:delete')
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id='camera-options-menu'
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{'aria-labelledby': 'camera-options-menu'}}
      PaperProps={{style: {width: '9.97em', marginTop: 4, fontSize: '0.875em'}}}>
      <MenuItem onClick={onClickOnline}>
        <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', fontSize: '0.9em', alignItems: 'center'}}>
          <Box style={{width: 16, height: 16, fontSize: '0.8em'}}> {'🟢'}</Box>
          <Box sx={{fontFamily: 'Inter'}}>Habilitar</Box>
        </Box>
      </MenuItem>
      <MenuItem onClick={onClickOffline}>
        <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', fontSize: '0.9em', alignItems: 'center'}}>
          <Box style={{width: 16, height: 16, fontSize: '0.8em'}}> {'🔴'}</Box>
          <Box sx={{fontFamily: 'Inter'}}>Desabilitar</Box>
        </Box>
      </MenuItem>
      {hasDeleteCameraPermission && (
        <MenuItem onClick={onClickDelete}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', fontSize: '0.9em', alignItems: 'center'}}>
            <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
            <Box sx={{fontFamily: 'Inter'}}>Deletar</Box>
          </Box>
        </MenuItem>
      )}
    </Menu>
  )
}

export default CameraOptionsMenu
