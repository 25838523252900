import {Switch} from '@mui/material'
import {Box, Table, TableData, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import RowHover from 'src/components/RowHover/RowHover'
import api from 'src/services/api'
import {BrainBoxInput, BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import MenuMoreInformationsBrainBoxInOut from './MenuMoreInformationsBrainBoxInOut'

type BrainBoxOutputsProps = {
  brainBoxDevicesOutputs: BrainBoxOutput[]
  brainBoxDevicesInputs: BrainBoxInput[]
  onTriggerOutputDevice: (brainbox: BrainBoxInput) => void
  onDelete: (id: number) => void
  loadingList: boolean
  getOutputDevices: Function
  getInputDevices: Function
  onEdit: (brainBox: BrainBoxOutput, deviceType: 'input' | 'output') => void
  triggerShoot: (id: number) => void
  stopShoot: (id: number) => void
}

function BrainBoxConfigGridDevice({
  brainBoxDevicesOutputs,
  brainBoxDevicesInputs,
  onTriggerOutputDevice,
  onDelete,
  loadingList,
  getOutputDevices,
  onEdit,
  triggerShoot,
  stopShoot,
  getInputDevices,
}: BrainBoxOutputsProps) {
  const {enqueueSnackbar} = useSnackbar()
  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    getInputDevices()
    getOutputDevices()
  }, [])

  const columns = [
    {
      id: 'description',
      name: 'Descrição',
    },
    {
      id: 'channel',
      name: 'Canal',
    },
    {
      id: 'active',
      name: 'Ativo',
    },
    {
      id: 'actions',
      name: 'Ações',
    },
  ]

  return (
    <Box display='flex' justify='space-between' align='center' columnGap='20px'>
      <Box flex={1} direction='column' rowGap='5px' display='flex'>
        <Typography variant='span' size='17px' weight={500}>
          Dispositivos de entrada
        </Typography>
        <Box border='1px solid #000' borderRadius='8px' padding='16px 16px'>
          <Table width={'100%'} isLoading={loadingList} columns={columns}>
            {brainBoxDevicesInputs.map((row) => (
              <RowHover height='80px'>
                <TableData>{row.description}</TableData>
                <TableData>
                  <Box width='100%' display='flex' justify='flex-start' align='center' paddingLeft='15px'>
                    {row.channel}
                  </Box>
                </TableData>
                <TableData>
                  <Box width='100%' display='flex' justify='flex-start' align='center' paddingLeft='-5px' marginLeft='-10px'>
                    <Box>
                      <Switch
                        checked={row.isActive}
                        onChange={async (e) => {
                          try {
                            await api.brainBoxOutput.updateBrainBoxInput(row.id, {isActive: e.target.checked})
                            enqueueSnackbar('Dispositivo atualizado com sucesso', {
                              variant: 'success',
                            })
                            getInputDevices()
                          } catch (error) {
                            handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar dispositivo')
                          }
                          setIsActive(!isActive)
                        }}
                        inputProps={{'aria-label': 'controlled'}}
                      />
                    </Box>
                  </Box>
                </TableData>
                <TableData>
                  <Box width='100%' display='flex' justify='flex-start' align='center' paddingLeft='15px'>
                    <MenuMoreInformationsBrainBoxInOut
                      deviceType='input'
                      oneObject={row.id}
                      onClick={(event, object) => {
                        if (event === 'edit') {
                          onEdit({...row}, 'input')
                        }
                        if (event === 'trigger-output-device') {
                          onTriggerOutputDevice(row)
                        }
                      }}
                    />
                  </Box>
                </TableData>
              </RowHover>
            ))}
          </Table>
        </Box>
      </Box>
      <Box flex={1} direction='column' rowGap='5px' display='flex'>
        <Typography variant='span' size='17px' weight={500}>
          Dispositivos de saída
        </Typography>
        <Box border='1px solid #000' borderRadius='8px' padding='16px 16px'>
          <Table isLoading={loadingList} columns={columns} width={'100%'}>
            {brainBoxDevicesOutputs.map((row) => (
              <RowHover height='80px'>
                <TableData>{row.description}</TableData>
                <TableData>
                  <Box width='100%' display='flex' justify='flex-start' align='center' paddingLeft='15px'>
                    {row.channel}
                  </Box>
                </TableData>
                <TableData>
                  <Box width='100%' display='flex' justify='flex-start' align='center' paddingLeft='-5px' marginLeft='-10px'>
                    <Box>
                      <Switch
                        checked={row.isActive}
                        onChange={async (e) => {
                          try {
                            await api.brainBoxOutput.updateBrainBoxOutput(row.id, {isActive: e.target.checked})
                            enqueueSnackbar('Dispositivo atualizado com sucesso', {
                              variant: 'success',
                            })
                            getOutputDevices()
                          } catch (error) {
                            handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar dispositivo')
                          }
                          setIsActive(!isActive)
                        }}
                        inputProps={{'aria-label': 'controlled'}}
                      />
                    </Box>
                  </Box>
                </TableData>
                <TableData>
                  <Box width='100%' display='flex' justify='flex-start' align='center' paddingLeft='15px'>
                    <MenuMoreInformationsBrainBoxInOut
                      deviceType='output'
                      oneObject={row.id}
                      onClick={(event, object) => {
                        if (event === 'edit') {
                          onEdit({...row}, 'output')
                        }
                        if (event === 'trigger-shoot-output') {
                          triggerShoot(row.id)
                        }
                        if (event === 'eject-shoot-output') {
                          stopShoot(row.id)
                        }
                      }}
                    />
                  </Box>
                </TableData>
              </RowHover>
            ))}
          </Table>
        </Box>
      </Box>
    </Box>
  )
}

export default BrainBoxConfigGridDevice
