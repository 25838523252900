import RefreshIcon from '@mui/icons-material/Refresh'
import {Box, Button, CircularProgress, Grid, IconButton, Typography} from '@mui/material'
import {Table, TableData} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import api from 'src/services/api'
import {BrainBoxButtonControl} from 'src/services/api/endpoints/BrainBoxButtonsControl'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import BrainBoxModalRegisterControl from './components/BrainBoxModalRegisterControl'
import MenuMoreInformationsBrainBoxButtonControl from './components/MoreInformationsButtonControl'
import RowHover from 'src/components/RowHover/RowHover'

type BrainBoxConfigDeviceControlProps = {
  brainBoxOne: BrainBox
}

function BrainBoxConfigDeviceControl({brainBoxOne}: BrainBoxConfigDeviceControlProps) {
  const [brainBoxControlButtonsPendents, setBrainBoxControlButtonsPendents] = useState<BrainBoxButtonControl[]>([])
  const [brainBoxControlButtonsRegister, setBrainBoxControlButtonsRegister] = useState<BrainBoxButtonControl[]>([])

  const {enqueueSnackbar} = useSnackbar()
  const [isRegisterButtonControlOpen, setIsRegisterButtonControlOpen] = useState<boolean>(false)
  const [brainBoxButtonControlOne, setBrainBoxButtonControlOne] = useState<BrainBoxButtonControl>({
    actionId: -1,
    brainBoxId: -1,
    code: '',
    id: -1,
    status: undefined,
    statusId: -1,
    brainBox: undefined,
  })
  const [loadingTableButtonRegister, setLoadingTableButtonRegister] = useState<boolean>(false)
  const [loadingTableButtonPendent, setLoadingTableButtoPendent] = useState<boolean>(false)
  const blockRegisteredButtonControlRequests = useRef(false)
  const blockPendentButtonControlRequests = useRef(false)

  const [isEdit, setIsEdit] = useState<boolean>(false)

  useEffect(() => {
    if (brainBoxOne.id === -1) return
    getButtonControlPendents()
    getButtonControlRegister()
  }, [brainBoxOne.id])

  async function getButtonControlPendents() {
    if (blockPendentButtonControlRequests.current) return
    blockPendentButtonControlRequests.current = true
    setLoadingTableButtoPendent(true)
    try {
      const response = await api.brainBoxControl.getAll({
        includes: ['status', 'brainBox'],
        brainBoxId: Number(brainBoxOne.id),
        filter: {
          status: 'PENDENTE',
        },
      })
      setBrainBoxControlButtonsPendents(response.data.data.entities)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar botões pendentes')
    } finally {
      setLoadingTableButtoPendent(false)
      blockPendentButtonControlRequests.current = false
    }
  }

  async function getButtonControlRegister() {
    if (blockRegisteredButtonControlRequests.current) return
    blockRegisteredButtonControlRequests.current = true
    setLoadingTableButtonRegister(true)
    try {
      const response = await api.brainBoxControl.getAll({
        includes: ['status', 'brainBox'],
        brainBoxId: Number(brainBoxOne.id),
        filter: {
          status: 'CADASTRADO',
        },
      })
      setBrainBoxControlButtonsRegister(response.data.data.entities)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar botões cadastrados')
    } finally {
      setLoadingTableButtonRegister(false)
      blockRegisteredButtonControlRequests.current = false
    }
  }

  function action(id: number) {
    switch (id) {
      case 1:
        return 'Armar'
      case 2:
        return 'Desarmar'
      case 3:
        return 'Reiniciar'
    }
  }

  async function ignoreButton(id: number) {
    try {
      await api.brainBoxControl.delete(id)
      getButtonControlPendents()
      enqueueSnackbar('Botão ignorado com sucesso', {
        variant: 'success',
        preventDuplicate: true,
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao ignorar botão')
    }
  }

  async function deleteButton(id: number) {
    try {
      await api.brainBoxControl.delete(id)
      getButtonControlRegister()
      enqueueSnackbar('Botão excluído com sucesso', {
        variant: 'success',
        preventDuplicate: true,
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao excluir botão')
    }
  }

  const pendingButtonControlColumns = [
    {
      id: 'code',
      name: 'Código do Botão',
    },
    {
      id: 'accept',
      name: '',
    },
    {
      id: 'ignore',
      name: '',
    },
  ]

  const registeredButtonsControlColumns = [
    {
      id: 'description',
      name: 'Descrição',
    },
    {
      id: 'code',
      name: 'Código do Botão',
    },
    {
      id: 'action',
      name: 'Tipo de Ação',
    },
    {
      id: 'actions',
      name: '',
    },
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Typography sx={[styles.textBodyTable, {fontWeight: 550}]}>Botões Pendentes</Typography>
          <IconButton sx={{height: 40}} onClick={() => getButtonControlPendents()}>
            {loadingTableButtonPendent ? <CircularProgress size={20} /> : <RefreshIcon />}
          </IconButton>
        </Box>
        <Box sx={{height: '60vh', border: '1px solid #E0E0E0', overflow: 'hidden', borderRadius: 3}}>
          <Table skeletonRowsAmount={0} isLoading={loadingTableButtonPendent} columns={pendingButtonControlColumns} padding={'0px 10px'}>
            {brainBoxControlButtonsPendents.map((row) => (
              <RowHover>
                <TableData>{row.code}</TableData>
                <TableData width={'60px'}>
                  {
                    <Button
                      sx={[styles.textBodyTable, styles.buttonApprove]}
                      onClick={() => {
                        setIsRegisterButtonControlOpen(true)
                        setBrainBoxButtonControlOne(row)
                      }}>
                      Aprovar
                    </Button>
                  }
                </TableData>
                <TableData width={'60px'}>
                  {
                    <Button
                      variant='outlined'
                      sx={[styles.textBodyTable, {textTransform: 'initial'}]}
                      onClick={() => {
                        ignoreButton(row.id)
                        getButtonControlPendents()
                      }}>
                      Ignorar
                    </Button>
                  }
                </TableData>
              </RowHover>
            ))}
            {loadingTableButtonPendent && brainBoxControlButtonsPendents.length === 0 && (
              <RowHover>
                <TableData>Nenhum botão pendente foi encontrado</TableData>
              </RowHover>
            )}
          </Table>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Typography sx={[styles.textBodyTable, {fontWeight: 550}]}>Botões Registrados</Typography>
          <IconButton sx={{height: 40}} onClick={() => getButtonControlRegister()}>
            {loadingTableButtonRegister ? <CircularProgress size={20} /> : <RefreshIcon />}
          </IconButton>
        </Box>
        <Box sx={{height: '60vh', border: '1px solid #E0E0E0', overflow: 'hidden', borderRadius: 3}}>
          <Table skeletonRowsAmount={0} isLoading={loadingTableButtonRegister} columns={registeredButtonsControlColumns} padding={'0px 10px'}>
            {brainBoxControlButtonsRegister.map((row) => (
              <RowHover>
                <TableData>{row.description}</TableData>
                <TableData>{row.code}</TableData>
                <TableData>{action(row.actionId)}</TableData>
                <TableData>
                  {
                    <MenuMoreInformationsBrainBoxButtonControl
                      oneObject={row}
                      onClick={(event, object) => {
                        if (event === 'edit') {
                          setBrainBoxButtonControlOne(object)
                          setIsRegisterButtonControlOpen(true)
                          setIsEdit(true)
                        }
                        if (event === 'delete') {
                          deleteButton(object.id)
                          getButtonControlRegister()
                        }
                      }}
                    />
                  }
                </TableData>
              </RowHover>
            ))}
            {loadingTableButtonRegister && brainBoxControlButtonsRegister.length === 0 && (
              <RowHover>
                <TableData>Nenhum botão foi encontrado</TableData>
              </RowHover>
            )}
          </Table>
        </Box>
      </Grid>
      <BrainBoxModalRegisterControl
        isRegisterButtonControlOpen={isRegisterButtonControlOpen}
        setIsRegisterButtonControlOpen={setIsRegisterButtonControlOpen}
        brainBoxButtonControlOne={brainBoxButtonControlOne!}
        setBrainBoxButtonControlOne={setBrainBoxButtonControlOne}
        getButtonControlRegister={getButtonControlRegister}
        getButtonControlPendents={getButtonControlPendents}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </Grid>
  )
}

const styles = createStyleSheet({
  textHeaderTable: {fontFamily: "'Inter', 'sans-serif'", fontWeight: 470, color: '#8E8E8E'},
  textBodyTable: {fontFamily: "'Inter', 'sans-serif'"},
  buttonApprove: {
    textTransform: 'initial',
    backgroundColor: '#009EFF',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1976d2',
    },
  },
})

export default BrainBoxConfigDeviceControl
