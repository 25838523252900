import {Box, Button, Typography} from '@viptech/react-components'
import {CardContainer, TagStatus} from './CardResponsiveBase'
import editPenBlue from 'src/images/editPenBlue.svg'
import {capitalize} from 'lodash'

type CardResponsiveProps = {
  title: string
  status: string
  onClickFunction: () => void
}

export const CardResponsive = (props: CardResponsiveProps) => {
  const {title, status, onClickFunction} = props

  return (
    <CardContainer>
      <Box display='flex' direction='column'>
        <TagStatus color={status === 'Armado' ? '#039855' : '#D92D20'} bg={status === 'Armado' ? ' #ECFDF3' : '#FEF3F2'}>
          {status}
        </TagStatus>
        <Box marginTop='5px'>
          <Typography variant='p' color='#344054' size='16px'>
            {capitalize(title)}
          </Typography>
        </Box>
      </Box>

      <Button color='transparent' hoverColor='transparent' padding='0' onClick={() => onClickFunction()}>
        <img src={editPenBlue} alt='ir para edição de câmera' />
      </Button>
    </CardContainer>
  )
}
