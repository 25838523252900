import {useEffect, useRef} from 'react'
import {CameraDetectionConfig} from 'src/common/components/camera-detection-config/CameraDetectionConfig'
import {Dim} from 'src/common/types/generics/ImageDetections'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import {User} from 'src/services/api'
import {SxMap} from 'src/utilities/createStyleSheet'

type DetectionAreaProps = {
  user: User
}

type RequestQueueProps = {
  cameraId: number
  image: Dim
}

export function DetectionArea({user}: DetectionAreaProps) {
  const {camera, snapshot, _dimImage, areaItems, getSnapshotRtsp, listLineCrossing, loadLineCrossing, getInterestAreas} = useCameraConfigContext()

  const requestQueue = useRef<RequestQueueProps[]>([])

  const validatePermission = (permission: string) => {
    return user.permissions.includes(permission)
  }
  function reloadLineCrossings() {
    if (validatePermission('line-crossing:read')) loadLineCrossing(camera?.id!)
  }

  useEffect(() => {
    reloadLineCrossings()
  }, [])

  useEffect(() => {
    if (camera === null) return
    if (camera?.origin.includes('RT')) getSnapshotRtsp(camera.id)
    requestQueue.current.push({cameraId: camera.id, image: _dimImage})
  }, [])

  useEffect(() => {
    const processQueue = async () => {
      if (requestQueue.current.length === 0) return
      const request = requestQueue.current.shift()
      if (requestQueue.current.length > 0) requestQueue.current = []
      if (request) getInterestAreas(request.cameraId, request.image)
      processQueue()
    }
    processQueue()
  }, [requestQueue.current])

  return (
    <CameraDetectionConfig
      cameraId={camera?.id!}
      isRtsp={!!camera?.origin!.includes('RT')}
      areas={areaItems}
      lineCrossings={listLineCrossing}
      reloadLineCrossings={reloadLineCrossings}
      src={`data:image/jpeg;base64,${snapshot}`}
      containerStyles={styles.container}
    />
  )
}

const styles: SxMap = {
  container: {
    minHeight: '500px',
    maxWidth: '1000px',
    width: '100%',
    height: '100%',
  },
}
