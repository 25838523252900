import {useNavigate} from 'react-router-dom'
import ArrowLeft from 'src/images/goBackArrow.svg'
import {Container, GoBackButton, TitleOnGoBackButton} from '../select-plan/components/PlanSelectBase'
import PaymentForm from './components/PaymentForm/PaymentForm'
import {PaymentContainer} from './PaymentBase'

function Payment() {
  const navigate = useNavigate()

  function handleClickGoBack() {
    navigate('../select-plan')
  }

  return (
    <PaymentContainer>
      <GoBackButton onClick={() => handleClickGoBack()}>
        <img src={ArrowLeft} alt='Arrow Left' />
        <TitleOnGoBackButton> Voltar</TitleOnGoBackButton>
      </GoBackButton>
      <Container>
        <PaymentForm />
      </Container>
    </PaymentContainer>
  )
}

export default Payment
