import React from 'react';

interface CopieIconChangeColorProps {
  color?: string;
}

const CopieIconChangeColor: React.FC<CopieIconChangeColorProps> = ({color}) => {
  return (
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M15.5791 3.72216L12.7778 0.920873C12.5063 0.649284 12.1461 0.5 11.7629 0.5H7.17949C5.48267 0.5 4.10256 1.8801 4.10256 3.57692V3.78205H3.07692C1.3801 3.78205 0 5.16215 0 6.85897V13.4231C0 15.1199 1.3801 16.5 3.07692 16.5H8.82051C10.5173 16.5 11.8974 15.1199 11.8974 13.4231V13.2179H12.9231C14.6199 13.2179 16 11.8378 16 10.141V4.73708C16 4.3539 15.8507 3.99292 15.5791 3.72216ZM12.7179 2.60056L13.8987 3.78125H13.5893C13.1093 3.78125 12.7179 3.38986 12.7179 2.90986V2.60056ZM10.6667 13.4231C10.6667 14.4413 9.83877 15.2692 8.82051 15.2692H3.07692C2.05867 15.2692 1.23077 14.4413 1.23077 13.4231V6.85897C1.23077 5.84072 2.05867 5.01282 3.07692 5.01282H4.10256V10.141C4.10256 11.8378 5.48267 13.2179 7.17949 13.2179H10.6667V13.4231ZM12.9231 11.9872H7.17949C6.16123 11.9872 5.33333 11.1593 5.33333 10.141V3.57692C5.33333 2.55867 6.16123 1.73077 7.17949 1.73077H11.4872V2.91066C11.4872 4.07004 12.43 5.01282 13.5893 5.01282H14.7692V10.141C14.7692 11.1585 13.9413 11.9872 12.9231 11.9872Z"  fill={color ? color: '#009EFF'}/>
</svg>
  )
}

export default CopieIconChangeColor
