import {Button, Typography} from '@viptech/react-components'
import ModalCustom from '../modal/ModalCustom'
import paymentImage from 'src/images/paymentImage.svg'
import {ContainerButtonsExpirations, CotainerPaymentExpiration, ImageExpirationModal, TextContainerExpiration} from './PaymentExpirationModalBase'
import {useNavigate} from 'react-router-dom'

type PaymentExpirationModalProps = {
  isOpen: boolean
  onClose: () => void
}

export function PaymentExpirationModal(props: PaymentExpirationModalProps) {
  const {isOpen, onClose} = props
  const navigate = useNavigate()

  return (
    <ModalCustom isOpen={isOpen} closeModal={onClose} backgroundColor='white'>
      <CotainerPaymentExpiration>
        <ImageExpirationModal src={paymentImage} />

        <TextContainerExpiration>
          <Typography variant='p' size='20px' weight={600} lineHeight='24px' color='#344054'>
            Não conseguimos renovar a cobrança do seu plano
          </Typography>
          <Typography variant='span' size='16px' color='#344054' lineHeight='20px'>
            Para continuar aproveitando todos os benefícios da nossa plataforma, por favor, efetue o pagamento.
          </Typography>
          <Typography variant='span' size='16px' color='#344054' lineHeight='20px'>
            Se precisar de ajuda ou tiver alguma dúvida,estamos à disposição!
          </Typography>
        </TextContainerExpiration>

        <ContainerButtonsExpirations>
          <Button variant='contained' height='40px' width='100%' borderRadius='8px' onClick={() => navigate('/select-plan')}>
            Ir para pagamento
          </Button>

          <Button variant='outlined' height='40px' width='100%' borderRadius='8px' onClick={() => onClose()}>
            Fechar
          </Button>
        </ContainerButtonsExpirations>
      </CotainerPaymentExpiration>
    </ModalCustom>
  )
}
