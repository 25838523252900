import {Camera, EditAddressInfo} from 'src/services/api'

type generateLinkRtspProps = {
  addressInfo: EditAddressInfo
  camera?: Camera | null
  enqueueSnackbar?: any
}

type VerifiedProps = {
  host: string
  rtspPort: number
  stream: number
  manufacturer: string
  codec: string
  user: string
  password: string
  channel: number
  auth: string
}

function verifyProps(props: generateLinkRtspProps): VerifiedProps | null {
  const {enqueueSnackbar, camera, addressInfo} = props
  if (!addressInfo) {
    if (enqueueSnackbar) enqueueSnackbar('Não foi possível gerar o link', {variant: 'error'})
    return null
  }
  const host = addressInfo.host || camera?.addressInfo?.host
  const rtspPort = addressInfo.rtspPort || camera?.addressInfo?.rtspPort
  const stream = addressInfo.stream !== null ? addressInfo.stream : camera?.addressInfo?.stream
  const manufacturer = addressInfo.manufacturer || camera?.addressInfo?.manufacturer || 'Outro'
  const codec = addressInfo.codec || camera?.addressInfo?.codec || 'h.264'
  const user = addressInfo.user || camera?.addressInfo?.user!
  const password = addressInfo.password || camera?.addressInfo?.password!
  const channel = addressInfo.channel !== null ? addressInfo.channel! : camera?.addressInfo?.channel!
  if (!host || !rtspPort || stream === null || stream === undefined) {
    if (enqueueSnackbar) enqueueSnackbar('Não foi possível gerar o link', {variant: 'error'})
    return null
  }
  const auth = user && password ? `${encodeURIComponent(user)}${':' + encodeURIComponent(password)}` : ''
  const extractedProps = {
    host,
    rtspPort,
    stream,
    manufacturer,
    codec,
    user,
    password,
    channel,
    auth,
  }
  return extractedProps
}

export function generateLinkRtsp(props: generateLinkRtspProps) {
  const verifiedProps = verifyProps(props)
  if (!verifiedProps) return null
  const {host, rtspPort, stream, manufacturer, codec, channel, auth} = verifiedProps
  switch (manufacturer) {
    case 'Intelbras':
      return `rtsp://${auth}@${host}:${rtspPort}/cam/realmonitor?channel=${channel}&subtype=${stream}`
    case 'Hikvision':
      return `rtsp://${auth}@${host}:${rtspPort}/${codec || 'h.264'}/ch${channel}/${stream}/av_stream`
    case 'Tecvoz':
      return `rtsp://${auth}@${host}:${rtspPort}/profile${stream + 1}`
    default:
      return `rtsp://${auth}@${host}:${rtspPort}/`
  }
}

export function generateLinkRtspDVR(props: generateLinkRtspProps) {
  const verifiedProps = verifyProps(props)
  if (!verifiedProps) return null
  const {host, rtspPort, stream, manufacturer, channel, auth} = verifiedProps
  switch (manufacturer) {
    case 'Intelbras':
      return `rtsp://${auth}@${host}:${rtspPort}/cam/realmonitor?channel=${channel}&subtype=${stream}`
    case 'Hikvision':
      return `rtsp://${auth}:${host}:${rtspPort}/Streaming/Channels/${channel}0${stream + 1}/`
    default:
      return `rtsp://${auth}@${host}:${rtspPort}/`
  }
}
