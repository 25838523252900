import {Action, PosDraggable, State, Statefull} from 'src/common/models/image-draw/ImageDrawDTO'
import {Dim, Pos} from 'src/common/types/generics/ImageDetections'
import {clamp, posScale} from 'src/common/utils/Internal'
import {Coordinate} from '../../../../services/api'

export const mkKey = () => Math.random().toString(36).slice(2, 10)

export const INITIAL_STATE: State = {
  pos: null,
  points: [],
  nextStates: [],
  prevStates: [],
}

export function safePos(pos: Pos, lastPos: Pos, dim: Dim): Pos {
  const x = Math.floor(clamp(pos.x, 0, dim.width))
  const y = Math.floor(clamp(pos.y, 0, dim.height))

  if (x === lastPos.x && y === lastPos.y) {
    const kx = x === 0 ? 1 : -1
    const ky = y === 0 ? 1 : -1
    return {x: x + kx, y: y + ky}
  }

  return {x, y}
}

export function reductibleState(state: State, statefull: Statefull): State {
  return {
    pos: null,
    points: statefull.points,
    nextStates: [],
    prevStates: state.prevStates.concat({points: state.points}),
  }
}

export function pointsToPos(points: Pos[], scale: number): PosDraggable[] {
  return points.map((it, index) => ({
    key: `p-${it.x}-${it.y}-${index}`,
    ...it,
    ...posScale(it, scale),
    editable: false,
  }))
}

export function invScalePoints(points: Pos[], scale: number): Pos[] {
  const invScale = 1 / scale
  return points.map((it) => posScale(it, invScale))
}

export function transformPointsIntoCoordinates(points: Pos[], _dimImage: Dim): {x: number; y: number}[] {
  const coordinates = points.map((it) => ({
    x: Math.round(it.x) / _dimImage.width,
    y: Math.round(it.y) / _dimImage.height,
  }))

  return coordinates
}

export const transformCoordinatesIntoPoints = (coordinates: Coordinate[], _dimImage: Dim) => {
  const points = coordinates.map((it) => ({
    x: it.x * _dimImage.width,
    y: it.y * _dimImage.height,
  }))

  return points
}

export function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'clear-last':
      return reductibleState(state, {points: state.points.slice(0, -1)})
    case 'clear':
      return reductibleState(state, {points: []})
    case 'undo':
      const prevState = state.prevStates.at(-1)
      if (!prevState) return state
      return {
        pos: null,
        points: prevState.points,
        nextStates: state.nextStates.concat({points: state.points}),
        prevStates: state.prevStates.slice(0, -1),
      }
    case 'redo':
      const nextState = state.nextStates.at(-1)
      if (!nextState) return state
      return {
        pos: null,
        points: nextState.points,
        nextStates: state.nextStates.slice(0, -1),
        prevStates: state.prevStates.concat({points: state.points}),
      }
    case 'save':
      return INITIAL_STATE
    case 'cancel':
      return reducer(state, {type: 'points-init', payload: action.payload})
    case 'points-init':
      return {
        pos: null,
        points: action.payload,
        nextStates: [],
        prevStates: [],
      }
    case 'point-new':
      return reductibleState(state, {
        points: state.points.concat([action.payload]),
      })
    case 'point-move':
      return reductibleState(state, {
        points: state.points.map((it) => (it.key === action.payload.key ? action.payload : it)),
      })
    case 'pos-move':
      return {...state, pos: action.payload}
    case 'pos-end':
      if (state.pos === null) return state
      const newState = reducer(state, {
        type: 'point-move',
        payload: state.pos,
      })
      newState.pos = null
      return newState
    case 'line-new':
      return {
        pos: null,
        points: action.payload,
        nextStates: [],
        prevStates: [],
      }
  }
}
