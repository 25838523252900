import {CompanyCredentials} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ConfirmationModal} from '../../../../../components/ConfirmationModal/ConfirmationModal'

export type CredentialsDialogProps = {
  open: boolean
  setOpen: (open: boolean) => void
  credentials: CompanyCredentials
  onClickConfirm: () => void
}

function CredentialsDialog({open, setOpen, credentials, onClickConfirm}: CredentialsDialogProps) {
  return (
    <ConfirmationModal
      isOpen={open}
      title={'Credenciais alteradas'}
      content={
        <div style={{textAlign: 'justify'}}>
          <p>Sua senha só será exibida uma vez. Caso perca-a, será necessário redefinir suas credenciais novamente. Suas novas credenciais são essas:</p>
          <p>
            <label style={styles.lableTitle}>Usuário:</label> {credentials.apiUser}
          </p>
          <p>
            <label style={styles.lableTitle}>Senha: </label> {credentials.apiPassword}
          </p>
        </div>
      }
      onClose={() => setOpen(false)}
      buttonsContent={[
        {
          label: 'Confirmar',
          onClick: () => {
            onClickConfirm()
          },
          variant: 'contained',
          color: '#009EFF',
        },
      ]}
    />
  )
}

const styles = createStyleSheet({
  lableTitle: {
    fontSize: '1rem',
    color: '#6f6f6f',
    fontWeight: 'bold',
  },
})

export default CredentialsDialog
