import {ObjectItem} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import {ObjectType} from 'src/services/api'

export const objectGroups = [
  'Sociedade',
  'Comportamentos Humanos',
  'Veículos',
  'EPI',
  'Informática',
  'Armas',
  'Utensílios Domésticos',
  'Eletrônicos',
  'Animais',
  'Objetos Públicos',
  'Objetos Pessoais',
  'Outros',
]

export const vehiclesData = [
  'Bicicleta',
  'Ciclomotor',
  'Motoneta',
  'Motocicleta',
  'Triciclo',
  'Quadriciclo',
  'Automóvel',
  'Micro-ônibus',
  'Ônibus',
  'Bonde',
  'Reboque',
  'Charrete',
  'Caminhonete',
  'Caminhão',
  'Carroça',
  'Carro-de-mão',
  'Carro',
  'Moto',
  'Barco',
  'Avião',
  'Trem',
]

export const kitchenUtensilsData = ['Colher', 'Garfo', 'Copo', 'Taça', 'Copo de vinho', 'Garrafa']
export const eletronicsData = ['Celular', 'Relógio', 'Secador de Cabelo', 'Torradeira', 'Microondas', 'Controle Remoto', 'Televisão', 'Forno']
export const informatics = ['Computador Portátil', 'Desktop', 'Teclado', 'Mouse']
export const animalsData = ['Girafa', 'Zebra', 'Urso', 'Elefante', 'Vaca', 'Ovelha', 'Cavalo', 'Cão', 'Gato', 'Pássaro', 'Rato']
export const objStreetData = ['Banco de Sentar', 'Parquímetro', 'Hidrante', 'Semáforo']
export const society = ['Pessoa', 'Facial']
export const humansBehaviors = ['Aglomeração']
export const objPersonal = ['Mochila', 'Mala de Viagem', 'Guarda-Chuva', 'Bolsa', 'Livro']
export const weapons = ['Faca', 'Taco de Beisebol', 'Raquete de Tênis', 'Tesoura']
export const epi = ['Capacete', 'Roupa de Segurança', 'Cabeça', 'Corpo', 'Sem Capacete', 'Sem Roupa de Segurança']
export const counting = ['Pessoa', ...animalsData]

export function getLabelList(selectedGroup: string): {includes?: string[]; exclude?: string[]} {
  if (selectedGroup.includes('Sociedade')) return {includes: society}
  if (selectedGroup.includes('Comportamentos Humanos')) return {includes: humansBehaviors}
  if (selectedGroup.includes('Informática')) return {includes: informatics}
  if (selectedGroup.includes('Veículos')) return {includes: vehiclesData}
  if (selectedGroup.includes('Utensílios Domésticos')) return {includes: kitchenUtensilsData}
  if (selectedGroup.includes('Eletrônicos')) return {includes: eletronicsData}
  if (selectedGroup.includes('Animais')) return {includes: animalsData}
  if (selectedGroup.includes('Objetos Públicos')) return {includes: objStreetData}
  if (selectedGroup.includes('Objetos Pessoais')) return {includes: objPersonal}
  if (selectedGroup.includes('Armas')) return {includes: weapons}
  if (selectedGroup.includes('EPI')) return {includes: epi}
  if (selectedGroup.includes('Relatório de Contagem')) return {includes: counting}
  if (selectedGroup.includes('Outros')) {
    return {
      exclude: [
        ...society,
        ...humansBehaviors,
        ...informatics,
        ...vehiclesData,
        ...kitchenUtensilsData,
        ...eletronicsData,
        ...animalsData,
        ...objStreetData,
        ...objPersonal,
        ...weapons,
        ...epi,
        ...counting,
      ],
    }
  }
  return {}
}

export function filterObjects(selected: string, objects: any[], selectedObject: string, getSelectedObject: (selected: string) => void) {
  if (selected && !selectedObject) getSelectedObject(selected)

  const {includes, exclude} = getLabelList(selected)
  if (includes) return objects.filter((it) => includes.includes(it.label))
  if (exclude) return objects.filter((it) => !exclude.includes(it.label))
  return []
}

type ObjectItemData = {
  object: ObjectType
  selected?: boolean
}

export function verifyObject(objectId: number, objects: ObjectItem<ObjectItemData>[]): string {
  const mappedObjects = objects.map((it) => it.data).map((it) => it && it.object)

  const filteredObjects = mappedObjects.filter((it) => it && it.id === objectId)

  const object = filteredObjects[0]

  if (!object) return 'Outros'

  if (society.includes(object.label)) return 'Sociedade'
  if (humansBehaviors.includes(object.label)) return 'Comportamentos Humanos'
  if (vehiclesData.includes(object.label)) return 'Veículos'
  if (epi.includes(object.label)) return 'EPI'
  if (informatics.includes(object.label)) return 'Informática'
  if (weapons.includes(object.label)) return 'Armas'
  if (kitchenUtensilsData.includes(object.label)) return 'Utensílios Domésticos'
  if (eletronicsData.includes(object.label)) return 'Eletrônicos'
  if (animalsData.includes(object.label)) return 'Animais'
  if (objStreetData.includes(object.label)) return 'Objetos Públicos'
  if (objPersonal.includes(object.label)) return 'Objetos Pessoais'
  return 'Outros'
}
