import {Box} from '@mui/material'
import ReactPlayer from 'react-player'
import api from 'src/services/api'
import storage from 'src/services/storage'
import NoImage from '../../../../../images//noImage.jpg'
import createStyleSheet from '../../../../../utilities/createStyleSheet'

type TestStreamProps = {
  hash: string
}

function CameraRtspLiveCamTest({hash}: TestStreamProps) {
  return (
    <Box sx={styles.stream}>
      {hash ? (
        <ReactPlayer
          url={api.streaming.startStream(hash)}
          config={{
            file: {
              hlsOptions: {
                forceHLS: true,
                debug: false,
                xhrSetup: function (xhr: any) {
                  xhr.setRequestHeader('Authorization', `Bearer ${storage.get2('token')}`)
                },
              },
            },
          }}
          playing={true}
          width={'100%'}
          height={'auto'}
        />
      ) : (
        <img src={NoImage} alt='Erro ao iniciar stream' style={styles.image} />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  image: {
    m: 1,
    backgroundColor: 'white',
    width: '50%',
    height: '50%',
  },
  stream: {
    m: 1,
    height: '80%',
  },
})

export default CameraRtspLiveCamTest
