import {Button} from '@mui/material'
import {Box, Modal} from '@viptech/react-components'
import {useNavigate} from 'react-router-dom'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import CloseBlueIcon from 'src/images/closeBlueIcon.svg'

type WarningModalProps = {
  isOpen: boolean
  closeModal: () => void
}

function WarningModal(props: WarningModalProps) {
  const {isOpen, closeModal} = props
  const navigate = useNavigate()
  const {camera} = useCameraConfigContext()
  const clientId = camera?.clientId!

  const handleConfigure = () => {
    navigate(`/clients?id=${clientId}`)
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} backgroundColor='white' width='47%'>
      <Box position='absolute' right='23px' top='15px' marginBottom='10px'>
        <Icon src={CloseBlueIcon} cursor='pointer' onClick={() => closeModal()} width='1.4em' />
      </Box>
      <Box>
        <Box fontWeight='500' fontSize='20px' margin='20px'>
          A integração de saída escolhida ainda não foi configurada
        </Box>
      </Box>
      <Box margin='40px 20px'>O cliente que possui esta câmera ainda não possui uma integração de saída, deseja configurar uma agora?</Box>
      <Box display='flex' direction='row' justify='flex-end' align='flex-end' columnGap='10px' margin='20px'>
        <Box width='140px'>
          <Button onClick={closeModal} variant='outlined' fullWidth={true}>
            Cancelar
          </Button>
        </Box>
        <Box width='140px'>
          <Button
            onClick={() => {
              handleConfigure()
            }}
            variant='contained'
            fullWidth={true}>
            Configurar
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default WarningModal
