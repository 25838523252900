import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {Page} from 'src/types'
import Pagination from '../../../components/pagination/Pagination'
import createStyleSheet from '../../../utilities/createStyleSheet'
import {useBrainBoxContext} from '../context/BrainBoxContext'
import {BrainBoxTableOption} from './BrainBoxTableOption'

type BrainBoxGridProps = {
  getAllBrainbox: () => void
  _brainBox: BrainBox[]
  brainBoxPage: Page
  setBrainBoxPage: (page: number) => void
  setBrainBoxPageSize: (pageSize: number) => void
  loadingBrainBoxDataGrid: boolean
  totalElements: number
  deleteBrainBox: (brainBoxes?: number[]) => void
}

function BrainBoxGridTable({
  _brainBox,
  brainBoxPage,
  setBrainBoxPage,
  loadingBrainBoxDataGrid,
  totalElements,
  setBrainBoxPageSize,
  deleteBrainBox,
}: BrainBoxGridProps) {
  const navigate = useNavigate()
  const hasDeleteBrainBoxPermission = ValidatePermissions('brainbox:delete')
  const hasUpdateBrainBoxPermission = ValidatePermissions('brainbox:update')
  const {selectedIdsBrainBox, setSelectedIdsBrainBox} = useBrainBoxContext()
  const {companies} = useCompany()

  const control = {
    currentPage: brainBoxPage.page,
    setCurrentPage: setBrainBoxPage,
    nextPage: () => setBrainBoxPage(brainBoxPage.page + 1),
    prevPage: () => setBrainBoxPage(brainBoxPage.page - 1),
    changePageSize: (pageSize: number) => setBrainBoxPageSize(pageSize),
  }

  const brainboxes = _brainBox.map((it) => {
    if (!it.client) return {}
    if (!it.id) return {}
    return {
      id: it.id,
      name: it.name,
      client: it.client.name,
      isArmed: it.isArmed ?? false,
      isOnline: it.isOnline ?? false,
      company: companies.find((company) => company.id === it.client?.companyId)?.name,
      self: it,
    }
  })
  const columns = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'name',
      name: 'Nome da Brain Box',
    },
    {
      id: 'client',
      name: 'Cliente',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'isArmed',
      name: 'Situação',
    },
    {
      id: 'isOnline',
      name: 'Status',
    },
    {
      id: 'self',
      name: '',
    },
  ]
  const onClickOpenInNewTab = (id: number) => {
    window.open(`/registers/brainbox/${id}`, '')
  }

  const onSelectAll = () => {
    if (selectedIdsBrainBox.length) return setSelectedIdsBrainBox([])
    const allIds = brainboxes.map((row) => row.id)
    setSelectedIdsBrainBox(allIds as number[])
  }

  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={brainboxes.length ? selectedIdsBrainBox.length === brainboxes.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )
  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table columns={columns} skeletonRowsAmount={brainBoxPage.pageSize} isLoading={loadingBrainBoxDataGrid} tableHeaderChildren={customHeader}>
          {brainboxes.map((row) => {
            if (!row.id) return null
            return (
              <RowHover id={`row-${row.id}`}>
                <TableData>
                  <CheckboxTable id={row.id} selectedIds={selectedIdsBrainBox} setSelectedIds={setSelectedIdsBrainBox} />
                </TableData>
                <TableData>
                  <LinkWithAction onAuxClick={() => onClickOpenInNewTab(row.id)} onClick={() => navigate(`/registers/brainbox/${row.id}`)}>
                    {row.name}
                  </LinkWithAction>
                </TableData>
                <TableData>{row.client}</TableData>
                <TableData>{row.company}</TableData>
                <TableData>{row.isArmed ? 'Armado' : 'Desarmado'}</TableData>
                <TableData>{row.isOnline ? 'Online' : 'Offline'}</TableData>
                <TableData>
                  <BrainBoxTableOption
                    oneObject={row.id.toString()}
                    onClick={(event, oneObject) => {
                      if (event === 'delete') deleteBrainBox([row.id])
                      else if (event === 'edit-box') if (hasUpdateBrainBoxPermission) navigate(row.id.toString())
                    }}
                    hasDeletePermission={hasDeleteBrainBoxPermission}
                    hasUpdatePermission={hasUpdateBrainBoxPermission}
                  />
                </TableData>
              </RowHover>
            )
          })}
        </Table>
      </Box>
      {!loadingBrainBoxDataGrid && brainboxes.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={totalElements}
            itemsPerPage={brainBoxPage.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '0 20px',
    minHeight: '73vh',
  },
  links: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    marginRight: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#00639f',
    },
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0 ',
  },
})

export default BrainBoxGridTable
