import SearchIcon from '@mui/icons-material/Search'
import {InputAdornment, OutlinedInput, InputProps} from '@mui/material'
import createStyleSheet from '../utilities/createStyleSheet'

export type AppSearchProps = InputProps

function AppSearch(props: AppSearchProps) {
  return (
    <OutlinedInput
      placeholder='Pesquisar'
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon sx={{fontSize: 'large'}} />
        </InputAdornment>
      }
      sx={styles.input}
      {...props}
    />
  )
}

const styles = createStyleSheet({
  input: {
    width: '100%',
    height: '2.825em',
    background: '#f4f4f4',
    fontSize: '0.875em',
    fontStyle: 'normal',
  },
})

export default AppSearch
