import createStyleSheet from 'src/utilities/createStyleSheet'
import styled from 'styled-components'

export const cameraDetectionConfigStyles = createStyleSheet({
  gridContainer: {
    m: 0,
    p: 0,
    height: '55vh',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    gap: '16px',
  },
  gridItem: {
    p: 1,
    minWidth: '390px',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    border: '1px solid #E0E0E0',
    minWidth: '300px',
    borderRadius: '5px',
    boxShadow: 'none',
    maxHeight: '400px',
  },
  card2: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  cardHeader: {
    paddingBottom: 0,
    fontSize: '10px',
    minWidth: '295px',
  },
  cardContent: {
    overflowY: 'auto',
    minHeight: '300px',
    minWidth: '300px',
    height: '355px',
  },
  actions1: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 0,
  },
  content2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    height: '320px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export const DetectionsContainer = styled.div`
  border: 1px solid #e0e0e0;
  padding: 2;
  margin-top: 8px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  max-width: 800px;
  max-height: 400px;
`
