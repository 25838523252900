import {Card, CardContent, CircularProgress, Typography} from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import createStyleSheet from 'src/utilities/createStyleSheet'

type ColumnChartProps = {
  title: string
  loading: boolean
  data: ApexCharts.ApexOptions['series']
  labelsXaxis: Array<string | number> | undefined
  height?: string
}

function ColumnChartsCard({title, loading, data, labelsXaxis, height}: ColumnChartProps) {
  const opts: ApexCharts.ApexOptions = {
    series: data ?? [],
    chart: {
      type: 'bar',
      height: 350,
      fontFamily: "'Inter', 'sans-serif",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      decimalsInFloat: 2,
      categories: labelsXaxis ?? [],
      labels: {
        rotate: 0,
      },
    },
    yaxis: {
      decimalsInFloat: 2,
      tickAmount: 4,
      labels: {
        formatter: function (val) {
          return val + '%'
        },
      },
      max: 100,
    },
    fill: {
      opacity: 1,
      colors: ['#0B62B9', '#9c27b0'],
    },
    legend: {
      show: true,
      position: 'bottom',
      showForSingleSeries: true,
      offsetY: 8,
      markers: {
        fillColors: ['#0B62B9', '#9c27b0'],
      },
    },
    noData: {
      text: 'Nenhum dado foi encontrado',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 20,
      offsetY: -15,
      style: {
        color: '#000000',
        fontSize: '10px',
        fontFamily: "'Inter', 'sans-serif",
      },
    },
  }
  return (
    <Card sx={[styles.card]} variant='outlined'>
      <CardContent>
        <Typography style={styles.title} sx={{paddingBottom: '15px'}}>
          {title}
        </Typography>

        {loading ? <CircularProgress /> : <ReactApexChart options={opts} series={opts.series} type='bar' height={height} />}
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  card: {
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '2px 5px',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#364152',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontSize: '16px',
    textAlign: 'center',
  },
})

export default ColumnChartsCard
