import styled from 'styled-components'

export const StyledLoadingTableRow = styled.tr`
  height: ${({ theme }) => theme.table.tableBody.rowHeight};
`

export const StyledLoadingTableData = styled.td`
  @media only screen and (max-width: 760px), (min-device-width: 761px) and (max-device-width: 1024px) {
    border-bottom: ${({ theme }) => theme.table.tableBody.borderBottom};
    display: block;
    text-align: right;
    align-content: center;
    padding: calc(${({ theme }) => theme.table.tableBody.rowHeight} / 4) 0.5rem;

    &::before {
      content: attr(data-label);
      float: left;
      font-weight: bold;
      padding-right: 1rem;
      padding-bottom: 1rem;
    }

    &:last-child {
      margin-bottom: 1rem;
    }
  }
`
