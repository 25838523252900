import {Alarm} from 'src/services/api'
import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_EVENTS_BASE_URL

export type Monitoring = {
  eventId: number
  resolution?: string
}

export interface EventMonitoring {
  event: Alarm
  camera: string
  userId: number
}

export interface UpdateMonitoring {
  eventIds: number[]
  resolution?: string
  treated?: boolean
}

export class MonitoringEndpoint {
  async getEvents(): Promise<AxiosResult<EventMonitoring[]>> {
    return await axios.get(`${prefix}/monitoring`)
  }

  async updateEvent(body: UpdateMonitoring): Promise<AxiosResult<any>> {
    return await axios.patch(`${prefix}/monitoring`, body)
  }
}
