import styled from 'styled-components'

export const ModalHeader = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: #1d1e28;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3.2em;
`
export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  color: white;
  padding-left: 1em;
`

export const IconButton = styled.button`
  position: absolute;
  right: 10px;
  top: 13px;
  background: none;
`

export const ModalContent = styled.div`
  padding: 24px;
  text-align: justify;
  max-width: 40em;
  overflow: auto;
`

export const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  padding: 24px;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-end;
    min-width: 300px;
    padding: 10px 24px;
  }
`
