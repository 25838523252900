interface Props {
  color?: string;
}

const SearchIconInputSmallChangeColor: React.FC<Props> = ({color}) => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.50163 2.5C9.93822 2.5 11.316 3.07068 12.3318 4.0865C13.3476 5.10233 13.9183 6.48008 13.9183 7.91667C13.9183 9.25833 13.4266 10.4917 12.6183 11.4417L12.8433 11.6667H13.5016L17.6683 15.8333L16.4183 17.0833L12.2516 12.9167V12.2583L12.0266 12.0333C11.0438 12.8723 9.79389 13.3333 8.50163 13.3333C7.06504 13.3333 5.68729 12.7627 4.67147 11.7468C3.65564 10.731 3.08496 9.35326 3.08496 7.91667C3.08496 6.48008 3.65564 5.10233 4.67147 4.0865C5.68729 3.07068 7.06504 2.5 8.50163 2.5ZM8.50163 4.16667C6.41829 4.16667 4.75163 5.83333 4.75163 7.91667C4.75163 10 6.41829 11.6667 8.50163 11.6667C10.585 11.6667 12.2516 10 12.2516 7.91667C12.2516 5.83333 10.585 4.16667 8.50163 4.16667Z" fill={ color ?? "#979797"}   />
    </svg>
  )
}

export default SearchIconInputSmallChangeColor