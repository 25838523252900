import {Button, FormControl, InputLabel, MenuItem, Select, Tooltip} from '@mui/material'
import {Box} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import Loading from 'src/components/Loading'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Layout} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import FullScreenIcon from '../../../src/images/fullScreenIcon.svg'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import LayoutShow from '../layouts/components/LayoutShow'

const CameraMosaic = () => {
  const [layouts, setLayouts] = useState<Layout[]>([])
  const [loadingLayouts, setLoadingLayouts] = useState<boolean>(true)
  const [layoutId, setLayoutId] = useState<number>(0)
  const navigate = useNavigate()
  const location = useLocation()
  const {enqueueSnackbar} = useSnackbar()
  const {selectedCompanies} = useCompany()
  const hasIdUrlParam = useRef(false)
  const blockLayoutRequests = useRef(false)

  useEffect(() => {
    if (layoutId > 0) navigate(`/camera-mosaic?id=${layoutId}`)
  }, [layoutId, navigate])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.has('id')) {
      const id = parseInt(params.get('id')!)!
      setLayoutId(id)
      hasIdUrlParam.current = true
    } else {
      hasIdUrlParam.current = false
    }
    loadLayout()
  }, [location, selectedCompanies])

  const loadLayout = useCallback(async () => {
    if (blockLayoutRequests.current) return
    blockLayoutRequests.current = true
    try {
      setLoadingLayouts(true)
      const response = await api.layout.getMany({
        includes: ['cameras'],
        orderBy: {layoutType: 'DESC'},
        filter: {companyIds: selectedCompanies},
      })
      setLayouts(response.data.data.entities)
      if (response.data.data.entities.length === 0) return enqueueSnackbar('Nenhum layout cadastrado', {variant: 'info'})
      if (!hasIdUrlParam.current) setLayoutId(response.data.data.entities[0].id)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar layouts')
    } finally {
      blockLayoutRequests.current = false
      setLoadingLayouts(false)
    }
  }, [selectedCompanies])

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      const event = new KeyboardEvent('keydown', {key: 'F11'})
      document.dispatchEvent(event)
    }
  }

  const handleButtonClick = () => {
    document.documentElement.requestFullscreen().then(handleFullscreenChange)
  }

  if (loadingLayouts) {
    return (
      <Box style={styles.pageLoading}>
        <Loading color='#009EFF' width='50px' height='50px' />
      </Box>
    )
  }

  return (
    <Box>
      {layoutId > 0 ? (
        <Box style={styles.paper}>
          <TopBar>
            <Box display='flex' direction='row' justifyContent='space-between'>
              <AppReloadButton
                sx={{marginBottom: 0.5}}
                onClick={() => {
                  if (!layoutId) return
                  navigate(`/camera-mosaic?id=${layoutId}`)
                }}
                loading={loadingLayouts}
              />

              <Box style={{display: 'flex'}}>
                <FormControl fullWidth>
                  <InputLabel size='small' id='demo-simple-select-label'>
                    Selecione um layout
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label=' Selecione um layout'
                    sx={{width: '300px', height: '42.5px', marginBottom: 0.5}}
                    value={layoutId}
                    onChange={(e) => {
                      setLayoutId(Number(e.target.value))
                    }}>
                    {layouts.map((layout, index) => {
                      return (
                        <MenuItem key={`role-${layout}-${index}`} value={layout.id}>
                          {layout.layoutTypeId === 1 ? `Padrão - ${layout.name}` : `LPR - ${layout.name}`}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <Tooltip title='Ver tela cheia' placement='top'>
                  <Button
                    sx={styles.seeFullScreen}
                    onClick={() => {
                      handleButtonClick()
                      navigate(`/layout?id=${layoutId}`)
                    }}>
                    <img src={FullScreenIcon} alt='FullScreen' />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </TopBar>

          <Box style={{width: '100%', display: 'flex', padding: '10px 20px'}}>
            <LayoutShow />
          </Box>
        </Box>
      ) : (
        <Box style={styles.paper}>
          <Box style={styles.notContent}>
            <Box>Nenhum layout cadastrado</Box>
            <Link to='/registers/layouts'>
              <Button variant='outlined'>Criar novo</Button>
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: 1.5,
  },
  pageLoading: {
    height: '90vh',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  seeFullScreen: {
    zIndex: 99999,
    width: '53px',
    minWidth: 0,
    marginLeft: 0.7,
    minHeight: 0,
    height: '43px',
    border: '1px solid #C0C0C0',
    '&:hover': {
      border: '1px solid #000',
    },
  },
  notContent: {
    fontSize: '1rem',
    justifyContent: 'center',
    height: '77vh',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
})

export default CameraMosaic
