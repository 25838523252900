export interface SidebarOption {
  name: string
  label: string
  crudItems: CrudItem[]
  scope: string
}

export interface CrudItem {
  name: string
  value: boolean
  permission: string
}

export const initialSidebarOptions: SidebarOption[] = [
  {
    name: 'Eventos',
    label: 'events',
    crudItems: [{name: 'Visualizar', value: false, permission: 'events:read'}],
    scope: 'company',
  },
  {
    name: 'Contagem de Objetos',
    label: 'detector-counter',
    crudItems: [{name: 'Visualizar', value: false, permission: 'detector-counter:read'}],
    scope: 'company',
  },
  {
    name: 'LPR',
    label: 'lpr',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'lpr:read'},
      {name: 'Criar', value: false, permission: 'lpr:create'},
      {name: 'Editar', value: false, permission: 'lpr:update'},
      {name: 'Excluir', value: false, permission: 'lpr:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Mudança de cenário',
    label: 'scene-change',
    crudItems: [{name: 'Visualizar', value: false, permission: 'scene-change:read'}],
    scope: 'company',
  },
  {
    name: 'Cotas',
    label: 'quotas',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'quotas:read'},
      {name: 'Criar', value: false, permission: 'quotas:create'},
      {name: 'Editar', value: false, permission: 'quotas:update'},
      {name: 'Excluir', value: false, permission: 'quotas:delete'},
    ],
    scope: 'global',
  },
  {
    name: 'Permissões',
    label: 'permissions',
    crudItems: [{name: 'Visualizar', value: false, permission: 'permissions:read'}],
    scope: 'global',
  },
  {
    name: 'Dashboard',
    label: 'dashboard',
    crudItems: [{name: 'Visualizar', value: false, permission: 'dashboard:read'}],
    scope: 'company',
  },
  {
    name: 'Processar imagens via API',
    label: 'transactions',
    crudItems: [{name: 'Visualizar', value: false, permission: 'transactions:read'}],
    scope: 'company',
  },
  {
    name: 'Reconhecimento Facial',
    label: 'face',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'face:read'},
      {name: 'Criar', value: false, permission: 'face:create'},
      {name: 'Editar', value: false, permission: 'face:update'},
      {name: 'Excluir', value: false, permission: 'face:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Cruzamento de linha',
    label: 'line-crossing',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'line-crossing:read'},
      {name: 'Criar', value: false, permission: 'line-crossing:create'},
      {name: 'Editar', value: false, permission: 'line-crossing:update'},
      {name: 'Excluir', value: false, permission: 'line-crossing:delete'},
      {name: 'Visualizar', value: false, permission: 'line-crossing-logs:read'},
    ],
    scope: 'company',
  },
  {
    name: 'Credenciais da empresa',
    label: 'credentials',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'credentials:read'},
      {name: 'Editar', value: false, permission: 'credentials:update'},
    ],
    scope: 'company',
  },
  {
    name: 'Brain Box',
    label: 'brainbox',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'brainbox:read'},
      {name: 'Criar', value: false, permission: 'brainbox:create'},
      {name: 'Editar', value: false, permission: 'brainbox:update'},
      {name: 'Excluir', value: false, permission: 'brainbox:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Layouts',
    label: 'layouts',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'layouts:read'},
      {name: 'Criar', value: false, permission: 'layouts:create'},
      {name: 'Editar', value: false, permission: 'layouts:update'},
      {name: 'Excluir', value: false, permission: 'layouts:delete'},
      {name: 'Visualizar', value: false, permission: 'livestream:read'},
    ],
    scope: 'company',
  },
  {
    name: 'Câmeras',
    label: 'cameras',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'cameras:read'},
      {name: 'Criar', value: false, permission: 'cameras:create'},
      {name: 'Editar', value: false, permission: 'cameras:update'},
      {name: 'Excluir', value: false, permission: 'cameras:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Câmeras RTSP',
    label: 'rtsp-camera',
    crudItems: [{name: 'Criar', value: false, permission: 'rtsp-camera:create'}],
    scope: 'company',
  },
  {
    name: 'Câmeras analíticas',
    label: 'analytic-cameras',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'analytic-cameras:read'},
      {name: 'Criar', value: false, permission: 'analytic-cameras:create'},
      {name: 'Editar', value: false, permission: 'analytic-cameras:update'},
      {name: 'Excluir', value: false, permission: 'analytic-cameras:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Servidores de Entrada/Saída',
    label: 'servers',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'servers:read'},
      {name: 'Criar', value: false, permission: 'servers:create'},
      {name: 'Editar', value: false, permission: 'servers:update'},
      {name: 'Excluir', value: false, permission: 'servers:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Clientes',
    label: 'clients',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'clients:read'},
      {name: 'Criar', value: false, permission: 'clients:create'},
      {name: 'Editar', value: false, permission: 'clients:update'},
      {name: 'Excluir', value: false, permission: 'clients:delete'},
    ],
    scope: 'company',
  },
  {
    name: 'Integração com Telegram',
    label: 'telegram',
    crudItems: [{name: 'Criar', value: false, permission: 'telegram:create'}],
    scope: 'company',
  },
  {
    name: 'Integrações LPR',
    label: 'lpr-integration',
    crudItems: [{name: 'Criar', value: false, permission: 'lpr-integration:create'}],
    scope: 'company',
  },
  {
    name: 'Usuários',
    label: 'users',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'users:read'},
      {name: 'Criar', value: false, permission: 'users:create'},
      {name: 'Editar', value: false, permission: 'users:update'},
      {name: 'Excluir', value: false, permission: 'users:delete'},
    ],
    scope: 'global',
  },
  {
    name: 'Notificações',
    label: 'notifications',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'notifications:read'},
      {name: 'Criar', value: false, permission: 'notifications:create'},
      {name: 'Editar', value: false, permission: 'notifications:update'},
      {name: 'Excluir', value: false, permission: 'notifications:delete'},
    ],
    scope: 'global',
  },
  {
    name: 'Empresas',
    label: 'companies',
    crudItems: [
      {name: 'Visualizar', value: false, permission: 'companies:read'},
      {name: 'Criar', value: false, permission: 'companies:create'},
      {name: 'Editar', value: false, permission: 'companies:update'},
      {name: 'Excluir', value: false, permission: 'companies:delete'},
    ],
    scope: 'global',
  },
  {
    name: 'Configuração de Parâmetros da Câmera',
    label: 'camera-parameter-config',
    crudItems: [{name: 'Editar', value: false, permission: 'camera-parameter-config:update'}],
    scope: 'global',
  },
]
