import {useEffect, useState} from 'react'
import {Navigate, useLocation, useNavigate} from 'react-router-dom'
import {InitialRoutesWithPermissions} from '../../routes/RoutesPageFunctions'
import {User} from '../../services/api'

type RedirectToProps = {
  user: User | null
}

const RedirectTo = (redirectToProps: RedirectToProps) => {
  const {user} = redirectToProps
  const navigate = useNavigate()
  const [redirectTo, setRedirectTo] = useState<string | null>(null)
  const {pathname} = useLocation()

  const loadRedirect = () => {
    const defaultRouteByRole = InitialRoutesWithPermissions(redirectToProps.user).path
    const locationSelected = pathname.includes(`/${defaultRouteByRole}`)

    if ((pathname === '/login' || pathname === '/') && defaultRouteByRole !== 'forbidden' && !locationSelected) {
      setRedirectTo(defaultRouteByRole)
      return
    }

    if (!locationSelected) {
      setRedirectTo(`/forbidden`)
      return
    }

    setRedirectTo(defaultRouteByRole)
  }

  useEffect(() => {
    loadRedirect()
  }, [navigate, user])

  return redirectTo ? <Navigate to={redirectTo} replace /> : null
}

export default RedirectTo
