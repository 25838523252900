import LoadingButton from '@mui/lab/LoadingButton'
import {Box, Button, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CompaniesSelect from 'src/layouts/main-layout/header/components/CompaniesSelect'
import api, {Company, DispatchTestEventResponse, Layout} from 'src/services/api'
import {EnumServer, Server} from 'src/services/api/endpoints/ServerEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useClientsContext} from '../contexts/ClientsContext'

export type ServerClient = {
  id: number
  name?: string
}

const ServersTypeOutputClient: Record<string, ServerClient> = {
  'Sem Integração': {id: -1, name: 'Sem Integração'},
  'Sigma-Cloud': {id: 1, name: 'Sigma-Cloud'},
  Moni: {id: 3, name: 'Moni'},
  'One Portaria': {id: 6, name: 'One Portaria'},
}

function ClientsRegisterContent({isCreate, isClientModalOpen}: {isCreate: boolean; isClientModalOpen: boolean}) {
  const {clientSelected, clientAdd, setClientAdd, setServerClient, serverClient} = useClientsContext()
  const [outputServers, setOutputServers] = useState<Server[]>([])
  const outputServerTypeIds = outputServers.map((it) => it.serverType?.id)
  const outputServerTypes = Object.keys(ServersTypeOutputClient).map((key) => ServersTypeOutputClient[key])
  const [clientLayouts, setClientLayouts] = useState<Layout[]>([])
  const code =
    serverClient.id === EnumServer.MONI
      ? clientAdd.moniClientInfo?.clientCode
      : serverClient.id === EnumServer.SIGMA_CLOUD
        ? clientAdd.sigmaAccount
        : clientAdd.onePortariaInfo?.clientCode
  const [testLoading, setTestLoading] = useState<boolean>(false)
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<number[]>([])
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])
  const {enqueueSnackbar} = useSnackbar()
  const hasPermissionViewDevices = ValidatePermissions('cameras:read')
  const hasReadLayoutsPermission = ValidatePermissions('layouts:read')
  const blockLayoutRequests = useRef(false)

  function handleTestEventResult({message, status}: DispatchTestEventResponse) {
    enqueueSnackbar(message, {variant: status === 200 ? 'success' : 'warning'})
  }

  async function dispatchTestEvent(code: string) {
    setTestLoading(true)
    try {
      const response = await api.client.dispatchTestEvent({code, companyId: clientAdd.companyId})
      handleTestEventResult(response.data.data)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Não foi possível gerar um evento', 'error')
    } finally {
      setTestLoading(false)
    }
  }

  const getOutputServers = useCallback(async () => {
    const queryCompanyId = isCreate ? selectedCompaniesIds[0] : clientAdd.companyId || selectedCompaniesIds[0]
    const response = await api.server.findManyPaged({
      page: 1,
      pageSize: 20,
      filter: {integrationType: 'SAÍDA', companyIds: [queryCompanyId]},
      includes: ['serverType'],
    })
    const servers = response.data.data.entities

    servers.unshift({id: -1, name: 'Sem Integração', ip: '', serverType: {id: -1}})
    if (!!selectedCompaniesIds[0]) setClientAdd({...clientAdd, companyId: selectedCompaniesIds[0]})
    if (isCreate) setServerClient({...serverClient, id: -1})
    setOutputServers(servers)
  }, [selectedCompaniesIds])

  useEffect(() => {
    if (isClientModalOpen && (clientAdd.companyId || selectedCompaniesIds[0]) && (isCreate || (!isCreate && selectedCompaniesIds.length !== 0)))
      getOutputServers()
  }, [selectedCompaniesIds])

  useEffect(() => {
    async function getLayoutsByClient() {
      if (!hasReadLayoutsPermission) return
      if (!clientSelected) return
      if (blockLayoutRequests.current) return
      blockLayoutRequests.current = true
      const response = await api.layout.getMany({
        filter: {
          clientIds: [clientSelected.id],
        },
      })
      setClientLayouts(response.data.data.entities)
      blockLayoutRequests.current = false
    }
    getLayoutsByClient()
  }, [])

  useEffect(() => {
    if (!isCreate) {
      getOutputServers()
      if (!clientSelected) return
      if (clientSelected.moniClientInfo) {
        setServerClient({...serverClient, id: EnumServer.MONI})
        setClientAdd({...clientAdd, moniClientInfo: clientSelected.moniClientInfo})
      } else if (clientSelected.sigmaAccount) {
        setServerClient({...serverClient, id: EnumServer.SIGMA_CLOUD})
        setClientAdd({...clientAdd, sigmaAccount: clientSelected.sigmaAccount})
      } else if (clientSelected.onePortariaClientInfo) {
        setServerClient({...serverClient, id: EnumServer.ONE_PORTARIA})
        setClientAdd({...clientAdd, onePortariaInfo: clientSelected.onePortariaClientInfo})
      } else setServerClient({...serverClient, id: -1})
    }
  }, [])

  return (
    <>
      <DialogContent>
        <FormControl sx={styles.form} fullWidth>
          <TextField
            label='Nome'
            variant='outlined'
            type={'text'}
            value={clientAdd.name}
            autoFocus={true}
            onChange={(e) => setClientAdd({name: e.target.value})}
            sx={styles.input}
            inputProps={{maxLength: 60}}
          />
          <TextField
            label='E-mail (opcional)'
            variant='outlined'
            type={'email'}
            value={clientAdd.email}
            sx={styles.input}
            onChange={(e) => setClientAdd({email: e.target.value === '' ? null : e.target.value})}
          />
          <Box sx={{width: '100%', marginBottom: '20px'}}>
            <CompaniesSelect
              selectLabel='Empresa'
              selectSize='medium'
              setSelectedCompaniesIds={setSelectedCompaniesIds}
              companiesScroll={companiesScroll}
              setCompaniesScroll={setCompaniesScroll}
              pageCount={pageCount}
              setPageCount={setPageCount}
              companyNames={companyNames}
              setCompanyNames={setCompanyNames}
              defaultValueIds={clientAdd.companyId ? [clientAdd.companyId] : []}
              multiple={false}
              disabled={!isCreate}
            />
          </Box>
          <Box sx={{marginBottom: '20px'}}>
            <FormControl fullWidth>
              <Select
                value={serverClient.id}
                onChange={(e) => {
                  setClientAdd({...clientAdd, sigmaAccount: undefined, moniClientInfo: undefined, onePortariaInfo: undefined})
                  setServerClient({...serverClient, id: Number(e.target.value)})
                }}>
                {outputServerTypes
                  .filter((it) => outputServerTypeIds.includes(it.id))
                  .map((it) => (
                    <MenuItem key={'server-' + it.id + 'client'} value={it.id}>
                      {it.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          {serverClient.id === EnumServer.MONI ? (
            <TextField
              label='Código da Central de Alarme no Moni'
              variant='outlined'
              type={'text'}
              value={clientAdd.moniClientInfo ? clientAdd.moniClientInfo.clientCode : ''}
              sx={styles.input}
              onChange={(e) => setClientAdd({moniClientInfo: {clientCode: e.target.value, moniCompanyId: '1', sector: '99'}, sigmaAccount: undefined})}
            />
          ) : serverClient.id === EnumServer.SIGMA_CLOUD ? (
            <TextField
              label='Código da Central de Alarme no Sigma Cloud'
              variant='outlined'
              type={'text'}
              value={clientAdd.sigmaAccount}
              sx={styles.input}
              onChange={(e) => setClientAdd({sigmaAccount: e.target.value, moniClientInfo: undefined, onePortariaInfo: undefined})}
            />
          ) : serverClient.id === EnumServer.ONE_PORTARIA ? (
            <TextField
              label='Código do cliente no sistema One'
              variant='outlined'
              type={'text'}
              value={clientAdd.onePortariaInfo?.clientCode || ''}
              sx={styles.input}
              onChange={(e) => setClientAdd({onePortariaInfo: {clientCode: e.target.value}, sigmaAccount: undefined, moniClientInfo: undefined})}
            />
          ) : null}
        </FormControl>

        {clientSelected?.id && clientLayouts.length > 0 && (
          <Box sx={{marginBottom: '20px'}}>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label' sx={{width: '320px'}}>
                Layout Padrão
              </InputLabel>
              <Select
                label='Layout Padrão'
                value={clientAdd.layoutId}
                onChange={(e) => {
                  setClientAdd({...clientAdd, layoutId: Number(e.target.value)})
                }}>
                {clientLayouts.map((layout, index) => (
                  <MenuItem key={'layout-' + layout.id + {index}} value={layout.id}>
                    {layout.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}
        <Box sx={{width: '100%', display: 'inline-flex', justifyContent: 'space-between'}}>
          <Tooltip title={`Envie um evento de teste (1601) do cliente para sua Central de Alarme`}>
            <LoadingButton
              variant='contained'
              loading={testLoading}
              onClick={() => {
                if (code) dispatchTestEvent(code)
              }}
              disabled={!clientAdd.moniClientInfo?.clientCode && !clientAdd.sigmaAccount && !clientAdd.onePortariaInfo?.clientCode}
              sx={[styles.buttonCameraClient, {width: '12em'}]}>
              Evento de Teste
            </LoadingButton>
          </Tooltip>
          {clientSelected?.id && hasPermissionViewDevices && (
            <Button
              variant='contained'
              component={Link}
              to={`/registers/devices?client=${clientSelected?.id}`}
              sx={[styles.buttonCameraClient]}
              disabled={clientSelected?.cameras && clientSelected.cameras.length <= 0}>
              Listar Dispositivos
            </Button>
          )}
        </Box>
      </DialogContent>
    </>
  )
}

const styles = createStyleSheet({
  container: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '0.4rem',
  },
  form: {
    paddingTop: 2,
  },
  input: {
    marginBottom: '20px',
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: 1,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  buttonCameraClient: {
    justifyContent: 'center',
    height: '2.7em',
    width: '13em',
    fontFamily: 'Inter',
    fontSize: '0.9rem',
    display: 'flex',
    marginBottom: '20px',
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
      color: '#ffff',
    },
  },
})

export default ClientsRegisterContent
