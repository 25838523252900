import {Box, List, Typography} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import {Input} from '@viptech/react-components'
import {useEffect, useMemo, useState} from 'react'
import AutoSizer from 'react-virtualized-auto-sizer'
import {FixedSizeList} from 'react-window'
import {useDebounce} from 'usehooks-ts'
import SearchIconInputSmallChangeColor from '../../../../../../images/SearchIconInputSmall'
import createStyleSheet from '../../../../../../utilities/createStyleSheet'
import {BoxTitle, CardSubtitle, GridBoxItemStyle, ListTransfer, TitleGridItems} from './styles'
import {Item} from './types'

type TransferOneListProps = {
  disabled: boolean
  title: string
  items: Item[]
  id: number
  toggleChecked: (item: Item) => void
  valueKey: (key: string, identity: number) => void
  objGender?: 'o' | 'a'
}

function TransferOneList({disabled, title, items, id, toggleChecked, valueKey, objGender}: TransferOneListProps) {
  const [search, setSearch] = useState('')
  const searchDebounced = useDebounce(search)
  const itemsFiltered = useMemo(() => items.filter((it) => it.label.toLowerCase().includes(searchDebounced.toLowerCase())), [items, searchDebounced])
  const itemsChecked = useMemo(() => itemsFiltered.filter((it) => it.checked), [itemsFiltered])
  if (!objGender) objGender = 'a'

  useEffect(() => {
    valueKey(search, id)
  }, [search])

  return (
    <Box style={GridBoxItemStyle}>
      <BoxTitle>
        <TitleGridItems>{title}</TitleGridItems>
        <CardSubtitle>{`${itemsChecked.length}/${items.length} selecionad${objGender}(s)`}</CardSubtitle>
      </BoxTitle>

      <div style={{margin: '10px 0', height: '2em'}}>
        <Input.Root
          hasIcon
          height='100%'
          maxLength={50}
          leftIcon={<SearchIconInputSmallChangeColor />}
          placeholder={`Pesquisar ${title.toLowerCase()}`}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <List sx={ListTransfer} dense component='div' role='list'>
        <AutoSizer>
          {({height, width}: {height: any; width: any}) => {
            if (itemsFiltered.length === 0) {
              return <Typography sx={[{height, width}, styles.notFoundText]}>Nenhum item na lista</Typography>
            }

            return (
              <FixedSizeList height={height} width={width} itemCount={itemsFiltered.length} itemSize={50} itemData={itemsFiltered}>
                {({data, index, style}) => {
                  const item = data[index]
                  const labelId = `transfer-list-item-${item.key}`

                  return (
                    <Box style={style} key={'transfer-' + item.key}>
                      <ListItem role='listitem' button disabled={disabled} onClick={() => toggleChecked(item)}>
                        <Checkbox
                          checked={item.checked}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                        <ListItemText sx={styles.label} id={labelId}>
                          {item.label}
                        </ListItemText>
                      </ListItem>
                      <ListItem />
                    </Box>
                  )
                }}
              </FixedSizeList>
            )
          }}
        </AutoSizer>
      </List>
    </Box>
  )
}

const styles = createStyleSheet({
  notFoundText: {
    textAlign: 'center',
    color: '#606060',
    fontSize: '10px',
    marginTop: '10px',
  },
  label: {
    maxHeight: '40px',
    overflowY: 'clip',
  },
})

export default TransferOneList
