import {Box, Button, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {BtnClose} from '../../../components/BtnClose'
import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal'
import api, {Camera} from '../../../services/api'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'
import TransferList, {TransferListData} from './CameraAddModal/CameraRegisterModal/TransferList'
import {Item} from './CameraAddModal/CameraRegisterModal/TransferList/types'

type CreateCameraStepperProps = {
  cameraToCopy: Camera
  closeModal: (isOpen: boolean) => void
}

const CopyConfigCameraModal = (props: CreateCameraStepperProps) => {
  const {cameraToCopy, closeModal} = props
  const {enqueueSnackbar} = useSnackbar()

  const getCamerasFromClient = async () => {
    try {
      const cameras = (
        await api.camera.getMany({
          page: 1,
          pageSize: 100,
          filter: {
            clientIds: [cameraToCopy.clientId],
          },
        })
      ).data

      const itens = cameras.data.entities
        .map((it) => {
          return {
            key: `${it.id}`,
            checked: false,
            label: it.name,
            data: it,
          }
        })
        .filter((it) => it.label !== cameraToCopy.name)
      setLeftItems(itens)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar dispositivos integrados do cliente escolhido')
    }
  }
  const [leftItems, setLeftItems] = useState<Item[]>([])
  const [rightItems, setRightItems] = useState<Item[]>([])

  const onChangeTransferList = (data: TransferListData) => {
    setLeftItems(data.leftItems)
    setRightItems(data.rightItems)
  }

  const pasteConfiguration = async () => {
    try {
      const targetIds = rightItems.map((it) => +it.key)
      const data = {
        sourceId: cameraToCopy.id,
        targetIds,
        attributes: ['interest-area', 'line-crossing', 'objects', 'schedules', 'actions'],
      }
      await api.camera.pasteConfig(data)
      enqueueSnackbar('Configuração colada com sucesso', {variant: 'success'})
      closeModal(false)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao colar configuração')
      setOpenConfirmationModal(false)
    }
  }

  useEffect(() => {
    getCamerasFromClient()
  }, [])

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  return (
    <div style={{padding: '40px', display: openConfirmationModal ? 'none' : 'flex', gap: '30px', flexDirection: 'column'}}>
      <BtnClose onClose={() => closeModal(false)} />
      <Box>
        <Typography variant='p' size='20px' style={{fontWeight: 500}} margin='0'>
          Colar Configuração
        </Typography>

        <Box display='flex' align='center' columnGap='8px'>
          <Typography variant='span' size='14px' color='#8E8E8E'>
            Câmera copiada:
          </Typography>

          <Typography variant='span' size='14px' color='#009eff'>
            {cameraToCopy.name}
          </Typography>
        </Box>
      </Box>

      <Box display='flex' rowGap='10px' direction='column'>
        <Box>
          <Typography variant='p' size='16px' margin='0' weight={500}>
            Selecione as câmeras para colar a configuração copiada
          </Typography>

          <Typography variant='p' size='14px' margin='0' color='#8E8E8E'>
            Selecione quais as câmeras que receberão as configurações copiadas
          </Typography>
        </Box>

        <TransferList leftItems={leftItems} rightItems={rightItems} onChange={onChangeTransferList} />

        <Box display='flex' columnGap='10px' justifyContent='flex-end'>
          <Button fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E' onClick={() => closeModal(false)}>
            Cancelar
          </Button>
          <Button
            color='#009eff'
            disabled={!rightItems.length}
            disabledTextColor='#8E8E8E'
            hoverColor='darkred'
            fontSize='12px'
            height='40px'
            width='150px'
            variant='contained'
            onClick={() => setOpenConfirmationModal(true)}>
            Colar Configuração
          </Button>
        </Box>
      </Box>

      <ConfirmationModal
        title={'Alterar configuração da câmera'}
        content={'Ao alterar a configuração você perderá os dados da configuração antiga. Deseja realizar a configuração agora?'}
        isOpen={openConfirmationModal}
        onClose={() => setOpenConfirmationModal(false)}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => closeModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => pasteConfiguration(),
            variant: 'contained',
          },
        ]}
      />
    </div>
  )
}

export default CopyConfigCameraModal
