import {Box, DropdownItem} from '@viptech/react-components'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {useSearchParams} from 'react-router-dom'
import TopBar from '../../components/top-bar/TopBar'
import {AdvancedFilters, FilterSelectedFinal} from '../../components/advancedFilters/AdvancedFilters'
import {TransparentButton} from '../../components/advancedFilters/AdvancedFiltersBase'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import CustomCalendar from '../../components/customCalendar/CustomCalendar'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import deleteIconBlue from '../../images/deleteIconBlue.svg'
import {ContainerFilters, FiltersSelectedContainer} from './AlarmsBase'
import {listTypesEvent} from './AlarmsFunctions'
import AlarmsGridTable from './components/AlarmsGridTable'
import AlarmsContextProvider, {useAlarmsContext} from './contexts/AlarmsContext'
import {ContainerPaperContent} from 'src/layouts/main-layout/LayoutBase'
import {OfferAppBrain} from './components/offer-app-brain/OfferAppBrain'
import {useWindowSize} from 'usehooks-ts'
import {useAuth} from 'src/contexts/AuthContext'
import {PaymentExpirationModal} from 'src/components/expirationDatePaymentModal/PaymentExpirationModal'

const searchTypes = [
  {id: '2', label: 'Câmera'},
  {id: '3', label: 'Cliente'},
]

function AlarmsInner() {
  const {
    reloadAlarms,
    reloadingAlarms,
    alarmStatusFilter,
    alarmSearch,
    setAlarmSearch,
    setFilterSelected,
    setFilterDate,
    toggleStatusFilter,
    listSelected,
    setListSelected,
  } = useAlarmsContext()

  const {userB2cHasCompanyExpired, setUserB2cHasCompanyExpired, checkDueDateInB2cUserCompany, userIsB2c} = useAuth()

  const [searchParams, setSearchParams] = useSearchParams()
  const [chosenFilters, setChosenFilters] = useState<FilterSelectedFinal[]>([])
  const [searchSelected, setSearchSelected] = useState<DropdownItem>({id: '', label: ''})
  const [showMoreFilters, setShowMoreFilters] = useState(true)
  const height = showMoreFilters ? '73vh' : '78vh'
  const [disabledSelectFilters, setDisabledSelectFilters] = useState(false)
  const {width} = useWindowSize()

  const handleChangeTimeStart = (dates: [Date, Date]) => {
    let from = moment(dates[0]).format('YYYY-MM-DDTHH:mm')
    let to = moment(dates[1]).format('YYYY-MM-DDTHH:mm')
    if (dates[0] === dates[1]) {
      from = moment(dates[0].setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm')
      to = moment(dates[1].setHours(23, 59, 59, 59)).format('YYYY-MM-DDTHH:mm')
    }
    setFilterDate({
      from,
      to,
    })
  }

  useEffect(() => {
    setFilterSelected(searchSelected.label)
  }, [searchSelected])

  const handlePartialSetFilter = (newFilter: FilterSelectedFinal) => {
    if (!listStatus.length && newFilter.label === 'Status') return
    if (!listType.length && newFilter.label === 'Tipo de Detecção') return
    setDisabledSelectFilters(true)

    const moreFilters = [...chosenFilters, newFilter]
    setChosenFilters(moreFilters)
    setShowMoreFilters(true)
  }

  useEffect(() => {
    checkDueDateInB2cUserCompany()

    const filterStatusDefault = alarmStatusFilter.map((it) => {
      const newList = listStatus.filter((item) => item.label !== it)
      setListStatus(newList)
      return {
        id: '1',
        label: 'Status',
        itemsOnMenu: [],
        selectedItem: {
          id: '',
          label: it,
        },
      }
    })

    setChosenFilters(filterStatusDefault)

    const hasQuery = searchParams.get('camera')
    if (hasQuery) {
      searchParams.delete('camera')
      setSearchParams(searchParams)
    }
  }, [])

  const initialStatusList = [
    {id: '1', label: 'Iniciado'},
    {id: '2', label: 'Processado'},
    {id: '3', label: 'Ignorado'},
    {id: '4', label: 'Expirado'},
    {id: '5', label: 'Erro'},
    {id: '6', label: 'Despachado'},
  ]

  const [listStatus, setListStatus] = useState(initialStatusList)
  const [listType, setListType] = useState<DropdownItem[]>([])
  const initialListTypeMemory = useRef<DropdownItem[]>([])

  const initialListType = listTypesEvent()

  const formatList = () => {
    const formatListType = initialListType.map((it) => {
      return {
        id: '',
        label: it,
      }
    })

    setListType(formatListType)
    initialListTypeMemory.current = formatListType
  }

  useEffect(() => {
    formatList()
  }, [])

  const eventFilters = [
    {
      id: '1',
      label: 'Status',
      itemsOnMenu: listStatus,
    },
    {
      id: '2',
      label: 'Tipo de Detecção',
      itemsOnMenu: listType,
    },
  ]

  const handleSetPartialFilters = (value: DropdownItem, index: number) => {
    setDisabledSelectFilters(false)

    const newFilter = [...chosenFilters]
    newFilter[index].selectedItem = value
    const newList = listStatus.filter((it) => it.label !== value.label)
    setListStatus(newList)

    const newListType = listType.filter((it) => it.label !== value.label)
    setListType(newListType)

    newFilter.forEach((it) => {
      if (it.label === 'Status') it.itemsOnMenu = newList
      else it.itemsOnMenu = newListType
    })

    setChosenFilters(newFilter)
  }

  useEffect(() => {
    const findStatusFilter = chosenFilters.filter((it) => it.label === 'Status')
    const findDetectionFilter = chosenFilters.filter((it) => it.label === 'Tipo de Detecção')

    if (findStatusFilter) {
      findStatusFilter.forEach((it) => {
        if (!it.selectedItem) return
        if (alarmStatusFilter.includes(it.selectedItem.label)) return
        toggleStatusFilter(it.selectedItem.label)
      })
    }

    if (findDetectionFilter) {
      findDetectionFilter.forEach((it) => {
        if (!it.selectedItem) return
        if (listSelected.includes(it.selectedItem.label)) return
        const newList = [...listSelected, it.selectedItem.label]
        setListSelected(newList)
      })
    }
  }, [chosenFilters])

  const deleteFilter = (index: number) => {
    const deleteItem = chosenFilters[index].selectedItem?.label
    if (!deleteItem) return

    if (chosenFilters[index].label === 'Status') {
      const a = initialStatusList.find((it) => it.label === deleteItem)
      toggleStatusFilter(deleteItem)
      if (a) setListStatus((prev) => [...prev, a])
    }

    if (chosenFilters[index].label === 'Tipo de Detecção') {
      const deletedItem = {id: '', label: deleteItem}
      setListType((prev) => [...prev, deletedItem])
      const newList = listSelected.filter((it) => it !== deleteItem)
      setListSelected(newList)
    }

    const deletedFilters = chosenFilters.filter((it) => it.selectedItem?.label !== deleteItem)
    setChosenFilters(deletedFilters)

    if (deletedFilters.length <= 0) {
      setShowMoreFilters(false)
    }
  }

  const deleteAllFilters = () => {
    const findStatusFilter = chosenFilters.filter((it) => it.label === 'Status')
    const findDetectionFilter = chosenFilters.filter((it) => it.label === 'Tipo de Detecção')

    if (findStatusFilter) {
      findStatusFilter.forEach((it) => {
        if (!it.selectedItem) return
        toggleStatusFilter(it.selectedItem.label)
      })
    }

    if (findDetectionFilter) {
      setListSelected([])
    }
    setDisabledSelectFilters(false)

    setShowMoreFilters(false)
    setChosenFilters([])
    setListStatus(initialStatusList)
    setListType(initialListTypeMemory.current)
  }

  const clearEmptyFilter = () => {
    const newFilters = chosenFilters.filter((it) => it.selectedItem)
    setChosenFilters(newFilters)
    setDisabledSelectFilters(false)
  }

  return (
    <ContainerPaperContent>
      {width <= 767 && userIsB2c ? (
        <OfferAppBrain />
      ) : (
        <>
          <TopBar>
            <Box display='flex' columnGap='16px' justifyContent='space-between'>
              <Box display='flex' columnGap='16px'>
                <AppReloadButton loading={reloadingAlarms} onClick={() => reloadAlarms()} />
                <Box direction='column' display='flex' rowGap='20px'>
                  <SelectWithSearchComponent
                    searchBy={searchTypes}
                    setSearchSelected={setSearchSelected}
                    searchSelected={searchSelected}
                    inputValue={alarmSearch}
                    inputSearch={setAlarmSearch}
                    filterBy={eventFilters}
                    setSelectedFilter={handlePartialSetFilter}
                    disabledSelectFilter={disabledSelectFilters}
                  />
                </Box>
              </Box>
              <CustomCalendar setDate={handleChangeTimeStart} />
            </Box>

            <Box marginTop='15px' align='center' display='flex' justifyContent='center'>
              {showMoreFilters && (
                <ContainerFilters>
                  <FiltersSelectedContainer>
                    {chosenFilters.map((it, index) => {
                      return (
                        <AdvancedFilters
                          index={index}
                          filtersSelected={it}
                          setFiltersSelected={handleSetPartialFilters}
                          deleteFilter={deleteFilter}
                          clearEmptyFilter={clearEmptyFilter}
                        />
                      )
                    })}
                  </FiltersSelectedContainer>

                  <TransparentButton onClick={() => deleteAllFilters()}>
                    <Box display='flex' columnGap='10px'>
                      <img src={deleteIconBlue} alt='limpar filtro' />
                      <p>Limpar Seleção</p>
                    </Box>
                  </TransparentButton>
                </ContainerFilters>
              )}
            </Box>
          </TopBar>
          <Box style={{height}}>
            <AlarmsGridTable />
          </Box>
        </>
      )}
      <PaymentExpirationModal isOpen={userB2cHasCompanyExpired} onClose={() => setUserB2cHasCompanyExpired(false)} />
    </ContainerPaperContent>
  )
}

function Events() {
  return (
    <AlarmsContextProvider>
      <AlarmsInner />
    </AlarmsContextProvider>
  )
}

export default Events
