import styled from 'styled-components'

export const LogoContainer = styled.div<{isOpen: boolean}>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: ${({isOpen}) => (isOpen ? '20px' : '27px 8px')};
`
export const SideBarContainer = styled.div<{isOpen: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1d1e28;
  height: 100%;
  width: 100%;
  gap: 20px;
  flex: 1;
  overflow: ${({isOpen}) => (isOpen ? 'auto' : 'hidden')};
`

export const MenuOnSideBar = styled.div<{isSelected: boolean; paddingLeft?: string; isOpen?: boolean; gap?: string}>`
  cursor: pointer;
  color: white;
  background-color: ${({isSelected}) => (isSelected ? '#46464f' : '#1d1e28')};
  display: flex;
  height: 3em;
  width: ${({paddingLeft}) => (paddingLeft ? '11.5em' : '13em')};
  padding-left: ${({paddingLeft}) => paddingLeft || '15px'};
  margin: 0 8px;
  transition: 0.4s;
  border: none;
  border-radius: 5px;
  align-items: center;
  gap: ${({gap}) => (gap ? gap : '15px')};
`

export const MenuWithSubItems = styled.div`
  cursor: pointer;
  color: white;
  display: flex;
  height: 3em;
  width: 13em;
  padding-left: 5px;
  transition: 0.4s;
  border: none;
  align-items: center;
  justify-content: space-between;
`

export const ContainerTitlesOnCollapse = styled.div`
  display: flex;
  gap: 14px;
  padding-left: 14px;
  margin: 0 5px;
`

export const ButtonCollapsedSubMenu = styled.button<{isSelected: boolean}>`
  cursor: pointer;
  color: white;
  background-color: ${({isSelected}) => (isSelected ? '#46464f' : '#1d1e28')};
  display: flex;
  height: 3em;
  width: 13em;
  padding-left: 0px;
  margin: 0 8px;
  transition: 0.4s;
  border: none;
  border-radius: 5px;
  align-items: center;
  gap: 15px;
`

export const ContainerButton = styled.div`
  cursor: pointer;
  color: white;
  display: flex;
  height: 3em;
  width: 13em;
  padding-left: 15px;
  transition: 0.4s;
  border: none;
  align-items: center;
`

export const SuspendedMenuBox = styled.div<{isSelected: boolean}>`
  cursor: pointer;
  color: white;
  display: flex;
  height: 3em;
  width: 12em;
  transition: 0.4s;
  border: none;
  padding: 0 20px;
  align-items: center;
  border-radius: 0;
  gap: 15px;
  background-color: ${({isSelected}) => (isSelected ? '#46464f' : '#1d1e28')};
`
