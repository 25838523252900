import {Box, Card, CardContent, CardHeader, FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {useAnalyticCamerasContext} from 'src/pages/analytic-cameras/context/AnalyticCamerasContext'
import createStyleSheet from '../../../../../utilities/createStyleSheet'

function CameraOriginModal() {
  const {step2, setSelectedTypeIntegration, selectedTypeIntegration} = useAnalyticCamerasContext()

  return (
    <Box sx={styles.container}>
      <Card sx={{boxShadow: 0}}>
        <Box sx={styles.boxHeader}>
          <Box sx={{display: 'flex'}}>
            <CardHeader container title='Integração de Entrada' />
          </Box>
        </Box>
        <CardContent>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Selecione o tipo de integração</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={selectedTypeIntegration}
              label='Selecione o tipo de integração'
              onChange={(e) => {
                setSelectedTypeIntegration(e.target.value)
                if (e.target.value === '') return
                else step2()
              }}>
              <MenuItem value={'Hikvision'}>Analítica Hikvision</MenuItem>
            </Select>
          </FormControl>
        </CardContent>
      </Card>
    </Box>
  )
}

const styles = createStyleSheet({
  container: {
    paddingTop: '1rem',
  },
  searchContainer: {
    paddingBottom: '1rem',
  },
  listContainer: {
    maxHeight: '42vh',
    overflowY: 'auto',
    height: '400px',
  },
  buttonAdd: {
    justifyContent: 'space-evenly',
    height: '2.7em',
    width: '13em',
    fontFamily: 'Inter',
    margin: '0px 16px 0px 16px',
    fontSize: '0.9rem',
    display: 'flex',
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  boxHeader: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    alignItems: 'center',
    gap: 1.5,
    paddingBottom: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default CameraOriginModal
