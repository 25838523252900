import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useAuth} from 'src/contexts/AuthContext'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {EditUser, RoleType, User} from 'src/services/api'
import {Page} from 'src/types'
import createStyleSheet from 'src/utilities/createStyleSheet'
import Pagination from '../../../components/pagination/Pagination'
import {Paragraph} from './Paragraph'
import UserMenuOptions from './UserButtonOptions'

type UserGridTableProps = {
  users: User[]
  totalUsersElements: number
  usersPage: Page
  usersPageChange: (page: number) => void
  usersPageChangeSize: (pageSize: number) => void
  loadingUsers: boolean
  openEditModal: (user: EditUser) => Promise<void>
  selectedUsersIds: number[]
  setSelectedUsersIds: (ids: number[]) => void
  notificationToDeleteUser: (userToDelete: number) => void
  roles: RoleType[]
}

export type UserRow = {
  id: number
  name: string
  email: string
  role: string
  companies: string
  self: User
}

function UserGridTable(props: UserGridTableProps) {
  const {
    users,
    totalUsersElements,
    usersPage,
    usersPageChange,
    usersPageChangeSize,
    loadingUsers,
    openEditModal,
    selectedUsersIds,
    setSelectedUsersIds,
    notificationToDeleteUser: deleteUser,
    roles,
  } = props
  const currentUserHasUpdateUsersPermissions = ValidatePermissions('users:update')
  const currentUserHasDeleteUsersPermissions = ValidatePermissions('users:delete')
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const {user: loggedUser} = useAuth()
  const {companies} = useCompany()

  const control = {
    currentPage: usersPage.page,
    setCurrentPage: usersPageChange,
    nextPage: () => usersPageChange(usersPage.page + 1),
    prevPage: () => usersPageChange(usersPage.page - 1),
    changePageSize: (pageSize: number) => usersPageChangeSize(pageSize),
  }

  function getRolePriorityFromUser(role: string) {
    return roles.filter((it) => it.name === role).map((it) => it.priority)[0]
  }

  function canEditUser(id: number, role: string) {
    const selectedIsMe = loggedUser?.id === id
    const selectedHaveSameOrMorePriority = getRolePriorityFromUser(role) >= getRolePriorityFromUser(loggedUser.role)
    if (selectedIsMe || !selectedHaveSameOrMorePriority) return true
    enqueueSnackbar('Você não pode editar esse usuário', {variant: 'error'})
    return false
  }

  async function sendEmail(email: string) {
    try {
      await api.auth.resendMail(email, 'forgot-password')
      enqueueSnackbar('E-mail enviado com sucesso', {variant: 'success'})
    } catch (error) {
      enqueueSnackbar('Não foi possível enviar o e-mail', {variant: 'error'})
    }
  }

  function canSelectUser(role: string) {
    if (role === 'Administrador' || role === 'admin') return false
    const selectedHaveSameOrMorePriority = getRolePriorityFromUser(role) >= getRolePriorityFromUser(loggedUser.role)
    if (selectedHaveSameOrMorePriority || !currentUserHasDeleteUsersPermissions) return false
    return true
  }

  function getDisabledRoles() {
    const myRolePriority = getRolePriorityFromUser(loggedUser.role)
    return roles.filter((it) => it.priority >= myRolePriority).map((it) => it.label)
  }

  const userRows = users.map((it) => {
    return {
      id: it.id,
      name: it.name,
      email: it.email,
      role: it.roleEntity?.label ?? 'Outro',
      companies: it.authorizedCompanies.map((companyId) => companies.find((company) => company.id === companyId)?.name).join(', '),
      self: it,
    } as UserRow
  })

  const columns = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'name',
      name: 'Nome do Usuário',
    },
    {
      id: 'email',
      name: 'E-mail',
    },
    {
      id: 'role',
      name: 'Função',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'show',
      name: '',
    },
  ]
  const onSelectAll = () => {
    if (selectedUsersIds.length) return setSelectedUsersIds([])
    const allIds = userRows.filter((row) => canSelectUser(row.role)).map((row) => row.id)
    setSelectedUsersIds(allIds)
  }
  const canSelectedUsers = userRows.filter((row) => canSelectUser(row.role)).map((row) => row.id)
  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={selectedUsersIds.length ? canSelectedUsers.length === selectedUsersIds.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )
  if (!currentUserHasUpdateUsersPermissions) columns.pop()

  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table skeletonRowsAmount={20} isLoading={loadingUsers} columns={columns} tableHeaderChildren={customHeader}>
          {userRows.map((row, index) => {
            const canSelect = canSelectUser(row.role)
            return (
              <RowHover id={`row-${row.id}`} key={index}>
                <TableData>
                  <CheckboxTable id={row.id} selectedIds={selectedUsersIds} setSelectedIds={setSelectedUsersIds} disabled={!canSelect} />
                </TableData>
                <TableData>
                  <LinkWithAction
                    onClick={() => {
                      if (canEditUser(row.id, row.role)) openEditModal(row)
                    }}>
                    {row.name}
                  </LinkWithAction>
                </TableData>
                <TableData>{row.email}</TableData>
                <TableData>{row.role}</TableData>
                <TableData>
                  <Paragraph>{row.companies}</Paragraph>
                </TableData>
                <TableData>
                  <UserMenuOptions
                    params={row}
                    onClick={(event, oneObject) => {
                      if (event === 'set-permissions') navigate(row.id.toString())
                      if (event === 'edit-user') openEditModal(row)
                      if (event === 'send-email') sendEmail(row.email)
                      if (event === 'delete') deleteUser(row.id)
                    }}
                    hasDeletePermission={currentUserHasDeleteUsersPermissions}
                    disabledRoles={getDisabledRoles()}
                  />
                </TableData>
              </RowHover>
            )
          })}
        </Table>
      </Box>
      <Box style={styles.pagination}>
        {!loadingUsers && users.length === 0 ? (
          <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
        ) : (
          <Box>
            <Pagination.Root
              backgroundColor='#009EFF'
              color='white'
              totalItems={totalUsersElements}
              itemsPerPage={usersPage.pageSize}
              possibleItemsPerPage={[20, 50, 100]}
              control={control}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}

const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  links: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    marginRight: 1,
    cursor: 'pointer',
    '&:hover': {
      color: '#00639f',
      textDecoration: 'underline',
    },
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default UserGridTable
