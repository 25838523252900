import {Box, Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import {useAuth} from 'src/contexts/AuthContext'
import Delete from 'src/images/deleteIconBlack.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'
import actionsObject from '../../../images/actionsObject.svg'
import IconEditUser from '../../../images/iconEditUser.svg'
import PermissionUser from '../../../images/userPermission.svg'
import IconEmail from '../../../images/iconEmail.svg'
import {UserRow} from './UserGridTable'

type LprTooltipEvent = 'set-permissions' | 'edit-user' | 'delete' | 'send-email'

type LprInfos = {
  params: UserRow
  onClick?: (event: LprTooltipEvent, id: number) => void
  hasDeletePermission: boolean
  disabledRoles: string[]
  isRoleAdmin?: boolean
}

const UserMenuOptions = ({params, onClick, hasDeletePermission, disabledRoles}: LprInfos) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isRoleAdmin = params.role === 'admin'
  const open = !isRoleAdmin && Boolean(anchorEl)
  const {user} = useAuth()

  return (
    <>
      <Tooltip title='Opções' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('edit-user', params.id)
          }}>
          <img src={IconEditUser} alt='Menu logo' style={{width: 15, height: 15}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Editar Usuário</Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('set-permissions', params.id)
          }}
          disabled={disabledRoles.includes(params.role) || params.id === user.id}>
          <img src={PermissionUser} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.8}}>Definir Permissões</Box>
        </MenuItem>
        {!params.email.includes('@localiza') && (
          <MenuItem
            sx={styles.menuInfos}
            onClick={() => {
              setAnchorEl(null)
              onClick?.('send-email', params.id)
            }}>
            <img src={IconEmail} alt='Menu logo' style={{width: 16, height: 16}} />
            <Box sx={{fontFamily: 'Inter', paddingLeft: 0.8}}>Enviar redefinição senha</Box>
          </MenuItem>
        )}
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('delete', params.id)
          }}
          disabled={!hasDeletePermission}>
          <img src={Delete} alt='Menu logo' style={{width: 16, height: 16}} />
          <Box sx={{fontFamily: 'Inter', paddingLeft: 0.9}}>Deletar</Box>
        </MenuItem>
      </Menu>
    </>
  )
}

export default UserMenuOptions

const styles = createStyleSheet({
  menuInfos: {
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
  },
})
