import {Box} from '@mui/material'

interface TabPanelProps {
  tab: number
  activeTab: number
  unstyled?: boolean
  children: JSX.Element
}

const TabPanel: React.FC<TabPanelProps> = ({children, activeTab, tab, unstyled}) => {
  return (
    <Box sx={unstyled ? {} : styles.panel} hidden={activeTab !== tab}>
      {children}
    </Box>
  )
}

const styles = {
  panel: {
    p: 3,
    minHeight: '50vh',
  },
}

export default TabPanel
