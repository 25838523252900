import api, {Camera, CameraConfiguration, EditAddressInfo} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {LatLong} from './components/camera-general-data/CameraGeneralDataProps'
import {EnqueueSnackbar} from 'notistack'
import {CameraConfigurationValidator} from 'src/types'

export enum CameraOptionsEnum {
  CAMERA_GENERAL_DATA,
  CAMERA_CONFIG_LINK_RTSP,
  CAMERA_DETECTION_CONFIG,
  CAMERA_AREA_DETECTION_CONFIG,
  CAMERA_CONFIG_RECEIVE_EVENT,
  CAMERA_CONFIG_HISTORIC,
  CAMERA_CONFIG_INTEGRATION_LPR,
  CAMERA_ADVANCED_CONFIG,
  CAMERA_CONFIG_LICENSE_PLATE,
}

export function whichTabNotPermissionEditing(tab: number) {
  return (
    tab === CameraOptionsEnum.CAMERA_AREA_DETECTION_CONFIG ||
    tab === CameraOptionsEnum.CAMERA_DETECTION_CONFIG ||
    tab === CameraOptionsEnum.CAMERA_CONFIG_HISTORIC ||
    tab === CameraOptionsEnum.CAMERA_CONFIG_LICENSE_PLATE
  )
}

export async function updateCamera(diffConfig: CameraConfiguration, camera: Camera, latLong: LatLong, addressInfo?: EditAddressInfo) {
  const diffConfigCopy = {...diffConfig} as Partial<CameraConfiguration>
  if (diffConfigCopy.receiveMode) {
    diffConfigCopy.receiveModeId = diffConfigCopy.receiveMode.id
  }
  const configuration = new CameraConfigurationValidator(diffConfigCopy)
  await api.camera.updateCamera(camera.id, {
    longitude: !latLong.longitude || latLong.longitude === '-' ? undefined : Number(latLong.longitude),
    latitude: !latLong.latitude || latLong.latitude === '-' ? undefined : Number(latLong.latitude),
    configuration,
    addressInfo: addressInfo,
  })
}

export const disableSaveButton = (diffConfig: CameraConfiguration, camera: Camera, addressInfo: EditAddressInfo) => {
  if (addressInfo && Object.values(addressInfo).length > 1) return true
  else if (
    !diffConfig ||
    diffConfig.partition.trim() === '' ||
    (diffConfig.receiveMode && diffConfig.receiveMode.description === 'BRAIN BOX' && !diffConfig.brainBoxId)
  ) {
    return false
  } else return true
}

export async function updateGeneralData(
  diffConfig: CameraConfiguration,
  camera: Camera,
  latLong: LatLong,
  enqueueSnackbar: any,
  id: number,
  getCamera: (id: number) => void,
  changeLoadingUpdate: (loading: boolean) => void,
  addressInfo: EditAddressInfo,
) {
  if (!camera) throw new Error('unreachable')
  if (!diffConfig) throw new Error('unreachable')
  changeLoadingUpdate(true)
  try {
    await updateCamera(diffConfig, camera, latLong, addressInfo)
    enqueueSnackbar('Alterações salvas com sucesso', {
      variant: 'success',
    })
  } catch (error) {
    handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar a configuração')
  } finally {
    getCamera(Number(id))
    changeLoadingUpdate(false)
  }
}

export async function advancedConfigCamera(camera: Camera, enqueueSnackbar: EnqueueSnackbar, detectionSensitivity?: number, largeQueue?: boolean) {
  if (!camera) return
  if (!detectionSensitivity && !largeQueue) return
  try {
    await api.camera.updateAdvancedConfig({id: camera.id, detectionSensitivity, largeQueue})
    enqueueSnackbar('Configurações atualizadas com sucesso', {variant: 'success'})
  } catch (error) {
    enqueueSnackbar('Erro ao atualizar configurações', {variant: 'error'})
  }
}
