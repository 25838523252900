import {Box, DialogContent, DialogTitle, Divider} from '@mui/material'
import {BtnClose} from 'src/components/BtnClose'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {useTransactionsContext} from '../contexts/TransactionsContext'
import TransactionsImagesModal from './TransactionsImagesModal'

function TransactionsModal() {
  const {transaction, closeTransactionModal} = useTransactionsContext()

  if (!transaction) return null

  return (
    <Box sx={styles.modalContainer}>
      <Divider />
      <DialogTitle sx={{position: 'relative'}}>
        <BtnClose onClose={closeTransactionModal} />
      </DialogTitle>
      <DialogContent>
        <TransactionsImagesModal />
      </DialogContent>
    </Box>
  )
}

const styles = createStyleSheet({
  modalContainer: {
    width: '41%',
    overflow: 'scroll',
    height: '78vh',
  },
})

export default TransactionsModal
