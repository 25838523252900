import LoadingButton from '@mui/lab/LoadingButton'
import {Avatar, Box, Button, TextField} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useAuth} from 'src/contexts/AuthContext'
import editPen from 'src/images/edit-pen.svg'
import api, {Company} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {cnpjMask, isLetter, isValidCnpj} from 'src/utilities/regexValidation'

export function SettingsCompanyData() {
  const [company, setCompany] = useState<Company>({id: 0, name: '-', document: '-', apiUser: '-'})
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState<string>('')
  const [document, setCnpj] = useState<string>('')
  const {enqueueSnackbar} = useSnackbar()
  const {user} = useAuth()
  const hasPermissionCompanyUpdate = ValidatePermissions('companies:update')

  async function updateCompany() {
    setIsLoading(true)
    try {
      await api.company.update({id: undefined, name, document: document})
      enqueueSnackbar('Dados da empresa atualizados com sucesso', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar dados da empresa')
    } finally {
      setIsLoading(false)
      setIsEditing(false)
    }
  }

  useEffect(() => {
    async function loadCompany() {
      try {
        const response = await api.company.getMany({
          filter: {
            ids: user.authorizedCompanies,
          },
        })
        setCompany(response.data.data.entities[0])
        setName(response.data.data.entities[0].name)
        setCnpj(response.data.data.entities[0].document)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar credenciais')
      }
    }
    loadCompany()
  }, [enqueueSnackbar, isEditing])

  return (
    <Box sx={styles.paper}>
      <Box sx={styles.section}>
        <Box>
          <label style={styles.lableTitle}>Nome</label>
          {isEditing ? (
            <Box>
              <TextField
                autoFocus
                variant={'standard'}
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  style: styles.input,
                }}
                sx={styles.editTextField}
              />
            </Box>
          ) : (
            <p>{company.name}</p>
          )}
        </Box>
      </Box>
      <Box sx={styles.sectionCNPJ}>
        <label style={styles.lableTitle}>CNPJ</label>
        {isEditing ? (
          <Box>
            <TextField
              variant={'standard'}
              value={document}
              inputProps={{maxLength: 18}}
              onChange={(e) => {
                const {value} = e.target
                if (!isLetter(value)) {
                  e.target.value = cnpjMask(value)
                  setCnpj(value)
                }
              }}
              InputProps={{
                style: styles.input,
              }}
              sx={styles.editTextFieldCNPJ}
            />
          </Box>
        ) : (
          <p>{company.document}</p>
        )}
      </Box>
      {hasPermissionCompanyUpdate && (
        <Box sx={styles.sectionButton}>
          {isEditing ? (
            <>
              <Button onClick={() => setIsEditing(false)} sx={[styles.button, styles.cancelButton]}>
                Cancelar
              </Button>
              <LoadingButton
                variant='contained'
                disabled={name === '' || document === '' || !isValidCnpj(document) || document.length !== 18}
                onClick={() => updateCompany()}
                loading={isLoading}
                sx={[styles.button, styles.editButton]}>
                Salvar
              </LoadingButton>
            </>
          ) : (
            <Button
              variant='contained'
              onClick={() => setIsEditing(true)}
              startIcon={<Avatar src={editPen} sx={styles.editIcon} />}
              sx={[styles.button, styles.editButton]}>
              Editar
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  section: {
    fontFamily: 'Inter',
    marginBottom: '2rem',
  },
  sectionCNPJ: {
    fontFamily: 'Inter',
  },
  sectionButton: {
    fontFamily: 'Inter',
    margin: '2rem 0 0.8rem 0',
  },
  lableTitle: {
    fontSize: '1rem',
    color: '#6f6f6f',
    fontWeight: 'bold',
  },
  editTextField: {
    marginTop: '0.3em',
    width: '400px',
  },
  editTextFieldCNPJ: {
    marginBottom: '0.2em',
    width: '400px',
  },
  input: {
    fontFamily: 'Inter',
    fontWeight: '400',
  },
  title: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
    fontWeight: '500',
  },
  button: {
    height: '3em',
    width: '12em',
    borderRadius: '5px',
    boxShadow: 'none',
    textTransform: 'none',
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '0.9rem',
    fontWeight: '500',
    fontStyle: 'normal',
  },
  editButton: {
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  cancelButton: {
    border: '1px solid',
    marginRight: '1.3em',
  },
  editIcon: {
    height: '25px',
    width: '25px',
  },
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: '22px 20px 10px 20px',
  },
})
