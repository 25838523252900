import {Box, Card, CardContent, CardHeader} from '@mui/material'
import {useAnalyticCamerasContext} from 'src/pages/analytic-cameras/context/AnalyticCamerasContext'
import createStyleSheet from '../../../../../utilities/createStyleSheet'

function SavedCameraModal() {
  const {analyticCamera, analytics} = useAnalyticCamerasContext()
  const cameraAnalytics = analyticCamera.analytics
  const created = analytics.filter((analytic) => cameraAnalytics?.includes(analytic.analytic)).map((analytic) => analytic.label)
  return (
    <Box sx={styles.container}>
      <CardHeader container title='Camera Analítica criada com sucesso' style={{textAlign: 'center'}} />
      <Card sx={{boxShadow: 0}}>
        <CardContent>
          <label style={styles.labelTitle}>ID</label>
          <p>{analyticCamera.id}</p>

          <label style={styles.labelTitle}>Nome</label>
          <p>{analyticCamera.name}</p>

          <label style={styles.labelTitle}>Endereço MAC</label>
          <p>{analyticCamera.macAddress.toUpperCase()}</p>

          <label style={styles.labelTitle}>Código de acesso</label>
          <p>{analyticCamera.code}</p>

          <label style={styles.labelTitle}>Analíticos</label>
          {created.map((analytic) => (
            <p>{analytic}</p>
          ))}
        </CardContent>
      </Card>
    </Box>
  )
}

const styles = createStyleSheet({
  container: {
    paddingTop: '1rem',
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchContainer: {
    paddingBottom: '1rem',
  },
  listContainer: {
    maxHeight: '42vh',
    overflowY: 'auto',
    height: '400px',
  },
  labelTitle: {
    fontSize: '1rem',
    color: '#6f6f6f',
    fontWeight: 'bold',
  },
  buttonAdd: {
    justifyContent: 'space-evenly',
    height: '2.7em',
    width: '13em',
    fontFamily: 'Inter',
    margin: '0px 16px 0px 16px',
    fontSize: '0.9rem',
    display: 'flex',
    textTransform: 'initial',
    backgroundColor: '#009eff',
    transition: '0.3s',
    '&:hover': {
      backgroundColor: '#00639f',
    },
  },
  boxHeader: {
    display: 'flex',
    height: 'auto',
    width: '100%',
    alignItems: 'center',
    gap: 1.5,
    paddingBottom: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export default SavedCameraModal
