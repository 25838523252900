import {EnumRecurrence} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {Button, ButtonContainer} from './RecurrenceButtonBase'

type RecurrenceButtonProps = {
  selected: EnumRecurrence.MONTHLY | EnumRecurrence.ANNUALY
  setSelected: (selected: EnumRecurrence.MONTHLY | EnumRecurrence.ANNUALY) => void
}

function RecurrenceButton(props: RecurrenceButtonProps) {
  const {selected, setSelected} = props

  function handleClick(value: EnumRecurrence.MONTHLY | EnumRecurrence.ANNUALY) {
    setSelected(value)
  }

  return (
    <ButtonContainer>
      <Button onClick={() => handleClick(EnumRecurrence.MONTHLY)} selected={selected === EnumRecurrence.MONTHLY}>
        Cobrança Mensal
      </Button>
      <Button onClick={() => handleClick(EnumRecurrence.ANNUALY)} selected={selected === EnumRecurrence.ANNUALY}>
        Cobrança Anual
      </Button>
    </ButtonContainer>
  )
}

export default RecurrenceButton
