import {useRef, useEffect} from 'react'

// https://www.debuggr.io/react-update-unmounted-component/

function useIsMounted() {
  const isMountedRef = useRef(true)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  return isMountedRef.current
}

export default useIsMounted
