import {Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import {BrainBoxButtonControl} from 'src/services/api/endpoints/BrainBoxButtonsControl'
import createStyleSheet from 'src/utilities/createStyleSheet'
import actionsObject from '../../../../../images/actionsObject.svg'

type TooltipEvent = 'to-view' | 'edit' | 'delete'

type LprInfos = {
  oneObject: BrainBoxButtonControl
  onClick?: (event: TooltipEvent, oneObject: BrainBoxButtonControl) => void
}

const MenuMoreInformationsBrainBoxButtonControl = ({oneObject, onClick}: LprInfos) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  return (
    <>
      <Tooltip title='Mais Informações' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('edit', oneObject)
          }}>
          Editar
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick?.('delete', oneObject)
          }}>
          Excluir
        </MenuItem>
      </Menu>
    </>
  )
}

export default MenuMoreInformationsBrainBoxButtonControl

const styles = createStyleSheet({
  menuInfos: {
    padding: 1,
    fontSize: '12px',
  },
})
