import {Camera} from 'src/services/api'
import Wifi1BarIcon from '@mui/icons-material/Wifi1Bar'
import Wifi2BarIcon from '@mui/icons-material/Wifi2Bar'
import WifiIcon from '@mui/icons-material/Wifi'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import {useCameraContext} from 'src/pages/cameras/context/CamerasContext'
import {Box, CircularProgress} from '@mui/material'

type CameraStatusConectionProps = {
  camera: Camera
}

export const CameraStatusConection = (cameraStatusConectionProps: CameraStatusConectionProps) => {
  const {timeData} = useCameraContext()
  const timeRequest = timeData[cameraStatusConectionProps.camera.id]
  if (timeRequest === undefined) {
    return (
      <Box sx={{paddingTop: '2px'}}>
        <CircularProgress size={18} />
      </Box>
    )
  }
  const timeInSec = Number(timeRequest)
  return (
    <>
      {timeInSec > 0 && timeInSec <= 2 ? (
        <WifiIcon />
      ) : timeInSec > 2 && timeInSec <= 3 ? (
        <Wifi2BarIcon />
      ) : timeInSec > 3 && timeInSec <= 9 ? (
        <Wifi1BarIcon />
      ) : (
        <WifiOffIcon />
      )}
    </>
  )
}
