import {Box, Typography} from '@mui/material'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import {ImageAlarm} from 'src/pages/alarms/components/alarms-modal/AlarmsModalBase'
import useImage from 'use-image'
import {useAlarmsContext} from 'src/pages/alarms/contexts/AlarmsContext'

type UnregisteredFaceProps = {
  id: number
  imageKey: string | null
  cropImageKey: string | null
  getSelectedImage: (selectedImage: string, detections?: Array<any>) => void
  getIsDetectionAccurate?: (isDetectionAccurate?: boolean) => void
}

export const UnregisteredFace = (unregisteredFaceProps: UnregisteredFaceProps) => {
  const {'1': imageKey} = useImage(`${process.env.REACT_APP_FACE_IMAGES_BASE_URL}/${unregisteredFaceProps.cropImageKey}`)
  const {snapshotsEvents} = useAlarmsContext()

  const handleSetImageDemarcated = async () => {
    const snapshotEvent = snapshotsEvents.find((value) => value.eventId === unregisteredFaceProps.id)
    const src = await `${process.env.REACT_APP_EVENT_IMAGES_BASE_URL}/${unregisteredFaceProps.imageKey}`

    unregisteredFaceProps.getSelectedImage(
      src,
      snapshotEvent?.demarcatedAreas.map((it) => ({
        x: it.startX,
        y: it.startY,
        width: it.endX,
        height: it.endY,
        data: it,
      })),
    )
  }

  const NotImage = () => {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'165px'} height={'218px'}>
        <ImageNotSupportedIcon fontSize='large' />
      </Box>
    )
  }

  return (
    <Box flexDirection={'row'} display={'flex'} marginTop={'40px'} columnGap={'16px'}>
      {imageKey === 'loaded' ? (
        <Box>
          <ImageAlarm
            src={`${process.env.REACT_APP_FACE_IMAGES_BASE_URL}/${unregisteredFaceProps.cropImageKey}`}
            onClick={async () => await handleSetImageDemarcated()}
          />
        </Box>
      ) : (
        <NotImage />
      )}
      <Typography>Nome: Desconhecido</Typography>
    </Box>
  )
}
