import {Box} from '@viptech/react-components'
import {Outlet} from 'react-router-dom'
import WhatsAppButton from 'src/images/wpp-button.svg'
import Header from './Header/Header'
import {FloatingImage} from './LayoutBase'
import B2CProvider from './contexts/B2CContext'
import {Button} from '@mui/material'

function B2CLayout() {
  return (
    <B2CProvider>
      <Box display='flex' direction='column' width='100%' overflow='auto' height='100vh'>
        <Header />

        <Box height='100%'>
          <Outlet />
        </Box>

        <Box display='flex' align='flex-end' justifyContent='flex-end' width='100%' margin='40px 0 0'>
          <Button onClick={() => window.open('https://wa.me/5544999320512', 'blank')}>
            <FloatingImage src={WhatsAppButton} alt='whatsapp button' />
          </Button>
        </Box>
      </Box>
    </B2CProvider>
  )
}

export default B2CLayout
