import React, {useReducer} from 'react'
import {TableHeaderColumn, TableHeaderContainer, TableHeaderRow, TableHeaderSortButton} from './TableHeaderBase'
import {Column, TableHeaderProps} from './TableHeaderProps'
import SortIcon from 'src/images/selectables/sortIcon/SortIcon'

type DispatchActionType = 'INCREMENT' | 'RESET'

function TableHeader({columns, headerExternalLeftBorder, headerExternalRightBorder, handleSort, ...props}: TableHeaderProps) {
  const reduceColumn = (columns: Array<Column>, action: {type: DispatchActionType; index: number}) => {
    let newColumn = {...columns[action.index]}
    switch (action.type) {
      case 'INCREMENT':
        if (newColumn.sort === 'asc') newColumn.sort = 'desc'
        else if (newColumn.sort === 'desc') newColumn.sort = 'none'
        else newColumn.sort = 'asc'

        for (const column of columns) {
          column.sort = 'none'
        }

        if (handleSort) handleSort(newColumn)
        return [...columns.slice(0, action.index), newColumn, ...columns.slice(action.index + 1)]

      default:
        newColumn.sort = 'none'

        if (handleSort) handleSort(newColumn)
        return [...columns.slice(0, action.index), newColumn, ...columns.slice(action.index + 1)]
    }
  }

  const [reducedColumns, dispatchColumns] = useReducer(reduceColumn, columns)

  return (
    <TableHeaderContainer>
      <TableHeaderRow headerExternalLeftBorder={headerExternalLeftBorder} headerExternalRightBorder={headerExternalRightBorder}>
        {reducedColumns.map((column, index) => {
          return (
            <TableHeaderColumn
              {...props}
              key={index}
              charAmount={column.name.length}
              cursor={column.name && handleSort ? 'pointer' : 'auto'}
              onClick={() => !!column.name && dispatchColumns({type: 'INCREMENT', index})}>
              {column.name}
              {column.name && handleSort && (
                <TableHeaderSortButton isOpen={!!column.sort && column.sort !== 'none'}>
                  <SortIcon transform={column.sort === 'desc' ? 'rotate(180deg)' : ''} />
                </TableHeaderSortButton>
              )}
            </TableHeaderColumn>
          )
        })}
      </TableHeaderRow>
    </TableHeaderContainer>
  )
}

export default TableHeader
