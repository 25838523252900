import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 16px;
  padding: 50px 0 0 0;

  @media (max-width: 1400px) {
    padding: 20px 0;
  }

  @media (max-width: 1200px) {
    gap: 10px;
  }
`

export const Title = styled.div`
  font-size: 28px;
  color: #344054;
  font-weight: 700;
  text-align: center;
  @media (max-width: 1200px) {
    font-size: 24px;
  }

  @media (max-width: 660px) {
    font-size: 20px;
  }
`

export const TitleAlt = styled.div`
  font-size: 20px;
  color: #344054;
  font-weight: 600;
  text-align: center;
  margin-top: 35px;

  @media (max-width: 767px) {
    font-size: 16px;
    margin-top: 45px;
  }
`

export const Subtitle = styled.span`
  font-size: 20px;
  color: #344054;
  font-weight: 400;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 18px;
  }

  @media (max-width: 660px) {
    font-size: 16px;
  }
`

export const GoBackButton = styled.div`
  display: flex;
  left: 56px;
  top: 56px;
  position: absolute;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  @media (max-width: 767px) {
    left: 30px;
    top: 30px;
  }
`

export const TitleOnGoBackButton = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`
