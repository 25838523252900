import styled from 'styled-components'
import {TooltipBaseProps} from './TooltipProps'
import {Box} from '@viptech/react-components'

export const TooltipChildrenContainer = styled(Box)`
  width: auto;
  height: auto;
`

export const TooltipContainer = styled.div<TooltipBaseProps & {left?: number; top?: number}>`
  background-color: ${({theme, backgroundColor}) => backgroundColor ?? theme.tooltip?.backgroundColor ?? theme.colors.white};
  border: ${({theme, border}) => border ?? theme.tooltip?.border};
  border-radius: ${({theme, borderRadius}) => borderRadius ?? theme.tooltip?.borderRadius};

  width: ${({theme, width}) => width ?? theme.tooltip?.width};
  height: ${({theme, height}) => height ?? theme.tooltip?.height};
  margin: ${({theme, margin}) => margin ?? theme.tooltip?.margin};
  margin-top: ${({theme, marginTop}) => marginTop ?? theme.tooltip?.marginTop};
  margin-bottom: ${({theme, marginBottom}) => marginBottom ?? theme.tooltip?.marginBottom};
  margin-left: ${({theme, marginLeft}) => marginLeft ?? theme.tooltip?.marginLeft};
  margin-right: ${({theme, marginRight}) => marginRight ?? theme.tooltip?.marginRight};
  padding: ${({theme, padding}) => padding ?? theme.tooltip?.padding ?? '2px 10px'};
  padding-top: ${({theme, paddingTop}) => paddingTop ?? theme.tooltip?.paddingTop};
  padding-bottom: ${({theme, paddingBottom}) => paddingBottom ?? theme.tooltip?.paddingBottom};
  padding-left: ${({theme, paddingLeft}) => paddingLeft ?? theme.tooltip?.paddingLeft};
  padding-right: ${({theme, paddingRight}) => paddingRight ?? theme.tooltip?.paddingRight};
  font-size: ${({theme, fontSize}) => fontSize ?? theme.tooltip?.fontSize};
  color: ${({theme, color}) => color ?? theme.tooltip?.color};
  font-weight: ${({theme, fontWeight}) => fontWeight ?? theme.tooltip?.fontWeight};
  white-space: ${({theme, whiteSpace}) => whiteSpace ?? theme.tooltip?.whiteSpace};
  position: absolute;
  opacity: ${(props) => props.opacity};
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
  pointer-events: none;
  transform: ${({position, theme}) =>
    position === 'top'
      ? 'translate(-50%, -2rem)'
      : position === 'bottom'
        ? 'translate(-50%, 1.75rem)'
        : position === 'left'
          ? 'translate(-5rem, 0)'
          : position === 'right'
            ? 'translate(1.5rem, 0)'
            : theme.tooltip?.position === 'top'
              ? 'translate(-50%, -2rem)'
              : theme.tooltip?.position === 'bottom'
                ? 'translate(-50%, 1.75rem)'
                : theme.tooltip?.position === 'left'
                  ? 'translate(-5rem, 0)'
                  : 'translate(1.5rem, 0)'};
`
