import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import Button from 'src/components/Button/Button'
import CheckboxTable from 'src/components/CheckboxTable'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Company} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import TopBar from '../../../../components/top-bar/TopBar'
import AppReloadButton from '../../../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../../../components/selectWithSearchComponent/SelectWithSearchComponent'
import useLists, {List} from '../../hooks/useLists'
import EditListModal from './EditListModal'
import ListOptionsMenu from './ListOptionsMenu'
import {ListsContextMenu} from './ListsContextMenu'

const columns = [
  {
    id: 'checkbox',
    name: '',
  },
  {
    id: 'name',
    name: 'Nome da Lista',
  },
  {
    id: 'type',
    name: 'Tipo de Lista',
  },
  {
    id: 'client',
    name: 'Cliente',
  },
  {
    id: 'company',
    name: 'Empresa',
  },
  {
    id: 'actions',
    name: '',
  },
]

type ListTableProps = {
  refresh: number
  isCreateListOpen: boolean
  setIsCreateListOpen: (open: boolean) => void
  setIsConfirmationOpen: (open: boolean) => void
  setCallback: (callback: () => void) => void
}

const searchFilterData = [{id: '1', label: 'Nome da Lista'}]

function ListTable(props: ListTableProps) {
  const {refresh: shouldRefresh, setIsCreateListOpen, setIsConfirmationOpen, setCallback} = props
  const {enqueueSnackbar} = useSnackbar()
  const [listSearch, setListSearch] = useState('')
  const listSearchDebounced = useDebounce(listSearch, 500)
  const hasFacialCreatePermission = ValidatePermissions('face:create')
  const hasLicensePlateCreatePermission = ValidatePermissions('lpr:create')
  const hasDeletePlatePermission = ValidatePermissions('lpr:delete')
  const hasDeleteFacePermission = ValidatePermissions('face:delete')
  const hasPermissionToCreate = hasFacialCreatePermission || hasLicensePlateCreatePermission
  const {selectedCompanies} = useCompany()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [selectedList, setSelectedList] = useState<List>({} as List)

  const {companies} = useCompany()
  const {lists, isLoading, error, canLoadMore, refresh, deleteManyLists} = useLists({filter: listSearchDebounced, page, companyIds: selectedCompanies})

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Erro ao carregar listas', {variant: 'error'})
    }
  }, [error])

  useEffect(() => {
    setPage(1)
    refresh(true)
  }, [listSearchDebounced, shouldRefresh, selectedCompanies])

  useEffect(() => {
    refresh(false)
  }, [page])

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Erro ao carregar listas', {variant: 'error'})
    }
  }, [error])

  useEffect(() => {
    setPage(1)
    refresh(true)
  }, [listSearchDebounced, shouldRefresh, selectedCompanies])

  useEffect(() => {
    refresh(false)
  }, [page])

  async function handleDeleteList(id: string) {
    try {
      const data = lists.filter((list) => list.id === +id)[0]!
      if (data.type === 'Faces') {
        await api.facialRecognition.deleteFacialItemList(data.id)
      } else {
        await api.lpr.deleteList(data.id)
      }
      enqueueSnackbar('Lista deletada com sucesso', {variant: 'success'})
      return refresh(true)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao deletar lista')
    }
  }

  async function deleteManySelectedLists() {
    const filteredIds = lists.filter((list) => selectedIds.includes(list.id))
    const facesIds = filteredIds.filter((list) => list.type === 'Faces').map((face) => face.id)
    const platesIds = filteredIds.filter((list) => list.type === 'Placas').map((plate) => plate.id)
    try {
      await deleteManyLists(facesIds, platesIds)
      await refresh(true)
      enqueueSnackbar('Listas deletadas com sucesso', {variant: 'success'})
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao deletar as listas selecionadas')
    } finally {
      setSelectedIds([])
    }
  }

  const onSelectAll = () => {
    if (selectedIds.length) return setSelectedIds([])

    const allIds = lists.map((row) => row.id)
    setSelectedIds(allIds)
  }

  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn>
              <Checkbox id='check' checked={lists.length ? selectedIds.length === lists.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )

  async function handleConfirmDelete(id: string) {
    await handleDeleteList(id)
    setIsConfirmationOpen(false)
  }

  function handleCloseModal() {
    setIsEditOpen(false)
    refresh(true)
  }

  function handleCancel() {
    setIsEditOpen(false)
  }
  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })
  return (
    <Box borderRadius='8px' align='center' direction='column' backgroundColor='white'>
      <EditListModal isOpen={isEditOpen} closeModal={() => handleCloseModal()} cancelCallback={() => handleCancel()} list={selectedList} />
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={isLoading} onClick={() => refresh()} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchFilterData}
              setSearchSelected={setShowSearchSelected}
              searchSelected={showSearchSelected}
              inputValue={listSearch}
              inputSearch={setListSearch}
              widthOnSelect={'270px'}
            />
          </Box>

          <AppAddOrOptionsButton
            text='Adicionar uma lista'
            onClickAdd={() => setIsCreateListOpen(true)}
            showOptions={selectedIds.length > 1}
            onClickOptions={(e) => setMenuEl(e.currentTarget)}
            disabled={!hasPermissionToCreate}
          />
        </Box>
      </TopBar>

      <Box height='72vh' overflow='auto' padding='0 20px'>
        <Box flex={1} display={'flex'}>
          <Table columns={columns} isLoading={isLoading} tableHeaderChildren={customHeader}>
            {lists.map((row) => {
              const filteredCompany: Company | undefined = companies.find((company) => company.id === row.companyId)
              const company = filteredCompany?.name || 'Lista pública'
              const key = `${row.id}-${row.type}`
              return (
                <RowHover key={key}>
                  <TableData>
                    <CheckboxTable id={row.id} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />
                  </TableData>
                  <TableData>
                    <LinkWithAction
                      onClick={() => {
                        setSelectedList(row)
                        setIsEditOpen(true)
                      }}>
                      {row.name}
                    </LinkWithAction>
                  </TableData>
                  <TableData>{row.type}</TableData>
                  <TableData>{row.client}</TableData>
                  <TableData>{company}</TableData>
                  <TableData>
                    <ListsContextMenu
                      hasDeletePermission={row.type === 'Faces' ? hasDeleteFacePermission : hasDeletePlatePermission}
                      rowId={`${row.id}`}
                      onClick={(event, oneObject) => {
                        if (event === 'delete') {
                          setIsConfirmationOpen(true)
                          setCallback(() => () => handleConfirmDelete(oneObject))
                        }
                        if (event === 'list-items') {
                          navigate(`${row.type === 'Faces' ? '/registers/lists/facial' : '/registers/lists/plates'}/id?=${row.id}`)
                        }
                      }}
                    />
                  </TableData>
                </RowHover>
              )
            })}
          </Table>
        </Box>

        {!isLoading && lists.length === 0 && (
          <Box display='flex' justify='center' align='center' height='80%'>
            Nenhum registro encontrado
          </Box>
        )}
      </Box>
      <Box display='flex' align='center' justify='center' paddingTop='3px' margin='10px'>
        <Button onClick={() => setPage((page) => page + 1)} isLoading={isLoading} disabled={!canLoadMore} />
      </Box>

      <ListOptionsMenu open={Boolean(menuEl)} anchorEl={menuEl} onClose={() => setMenuEl(null)} onDelete={deleteManySelectedLists} />
    </Box>
  )
}

export default ListTable
