import ClearIcon from '@mui/icons-material/Clear'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {BtnClose} from 'src/components/BtnClose'
import {useCompany} from 'src/contexts/CompanyContext'
import CompaniesSelect from 'src/layouts/main-layout/header/components/CompaniesSelect'
import {Item} from 'src/pages/cameras/components/CameraAddModal/CameraRegisterModal/TransferList/types'
import api, {Camera, Client, Company, Layout} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import LayoutCameraList, {TransferListData} from './LayoutCameraList'

type LayoutRegisterModalProps = {
  isCreate: boolean
  openModal: boolean
  closeModal: () => void
  layout: Layout
  setLayout: (layout: Layout) => void
  loadLayouts: Function
}

function mapCamaraToSelectItem(camera: Camera) {
  return {
    checked: false,
    key: `${camera.name}`,
    label: camera.name,
    data: {
      name: camera.name,
      clientId: camera.clientId,
      cameraId: camera.id,
    },
  }
}

function LayoutRegisterModal(props: LayoutRegisterModalProps) {
  const {selectedCompanies, companies} = useCompany()
  const {isCreate, openModal, closeModal, layout, setLayout, loadLayouts} = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const {enqueueSnackbar} = useSnackbar()
  const steps = [isCreate ? 'Adicionar Layout' : 'Editar Layout', 'Vincular câmeras']
  const [activeStep, setActiveStep] = useState<number>(0)
  const mosaico = [1, 4, 9, 16, 32]
  const layoutType = ['Padrão', 'LPR']
  const [mosaicoSelected, setMosaicoSelected] = useState<number>()
  const [typeSelected, setTypeSelected] = useState('')
  const [checked, setChecked] = useState<number[]>([])
  const [leftItems, setLeftItems] = useState<Item[]>([])
  const [rightItems, setRightItems] = useState<Item[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [, setClientLayout] = useState<number>()
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState<number[]>(selectedCompanies)
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])
  const [clientIdMessage, setClientIdMessage] = useState<string>('Selecione um cliente')
  const [clients, setClients] = useState<Client[]>([])
  const hasReadClientsPermission = ValidatePermissions('clients:read')
  const isLoadingClients = useRef(false)

  const listHasChanged = useRef(false)
  const camerasLeft = [] as any[]
  const camerasRight = [] as any[]

  const onChangeTransferList = useCallback((data: TransferListData) => {
    listHasChanged.current = true

    setLeftItems(data.leftItems)
    setRightItems(data.rightItems)

    setChecked(data.rightItems.map((it) => it.data.cameraId))
  }, [])

  const handleClearClick = () => {
    setLayout({...layout, clientId: undefined})
  }

  const fixedOrderOnClients = (clients: Client[]) => {
    return clients.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()

      if (nameA < nameB) return -1
      if (nameA > nameB) return 1
      return 0
    })
  }

  useEffect(() => {
    async function loadClients() {
      try {
        if (isLoadingClients.current) return
        isLoadingClients.current = true
        const response = await api.client.getMany({
          paginate: false,
          filter: {
            companyIds: selectedCompanyId,
          },
        })
        const sortClients = fixedOrderOnClients(response.data.data.entities)
        setClients(sortClients)
        if (!response.data.data.entities.length) {
          setClientIdMessage('Empresa não tem clientes')
          enqueueSnackbar('Empresa não tem clientes', {variant: 'warning'})
        } else {
          setClientIdMessage('Selecione um cliente')
        }
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao listar clientes')
      } finally {
        isLoadingClients.current = false
      }
    }
    loadClients()
  }, [selectedCompanyId])

  useEffect(() => {
    if (isCreate) {
      setLayout({...layout, name: '', maxCameras: 0, clientId: undefined, layoutTypeId: 1})
    } else if (layout.layoutTypeId) {
      setIsDisabled(true)
      layout.layoutTypeId === 1 ? setTypeSelected('Padrão') : setTypeSelected('LPR')
    }
    if (layout.clientId) getClient(layout.clientId)
    if (!isCreate && companies.length) {
      setCompanyNames([companies.find((it) => it.id === layout.companyId)!.name])
      setSelectedCompanyId([layout.companyId!])
    }
  }, [])

  async function loadCameras() {
    try {
      const response = await api.camera.getMany({
        filter: {
          clientIds: layout.clientId ? [layout.clientId] : undefined,
          hasLpr: layout.layoutTypeId === 2 ? true : undefined,
          origin: 'RTSP',
          companyIds: selectedCompanies,
        },
        includes: ['layouts', 'server'],
        paginate: false,
      })

      const cameras = response.data.data.entities

      cameras.forEach((it) => {
        if (it.layouts.length === 0) {
          camerasLeft.push(it)
        } else if (it.layouts.some((otherLayout) => otherLayout.layoutId === layout.id)) {
          camerasRight.push(it)
        } else {
          camerasLeft.push(it)
        }
      })
      setLeftItems(camerasLeft.map(mapCamaraToSelectItem))
      setRightItems(camerasRight.map(mapCamaraToSelectItem))
      setChecked(rightItems.map((it) => it.data.cameraId))
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao listar câmeras')
    }
  }

  async function getClient(id: number) {
    const response = await api.client.getById(id)
    const isClientDefaultLayout = layout.id === response.data.data.defaultLayoutId
    setIsChecked(isClientDefaultLayout)
    if (isClientDefaultLayout) setClientLayout(response.data.data.defaultLayoutId)
    isLoadingClients.current = false
  }

  async function createLayout() {
    setIsLoading(true)
    try {
      await api.layout.create({
        name: layout.name,
        layoutTypeId: layout.layoutTypeId,
        maxCameras: layout.maxCameras,
        cameraIds: checked,
        clientId: layout.clientId,
        isClientDefault: isChecked,
        companyId: selectedCompanyId[0],
      })
      enqueueSnackbar('Layout criado com sucesso', {
        variant: 'success',
      })
      loadLayouts()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar layout')
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  async function updateLayout() {
    setIsLoading(true)
    try {
      await api.layout.update(layout.id, {
        name: layout.name,
        maxCameras: layout.maxCameras,
        clientId: layout.clientId,
        isClientDefault: isChecked,
      })
      await api.layout.updateCameras(layout.id, {layoutCameras: {adding: listHasChanged.current ? checked : rightItems.map((it) => it.data.cameraId)}})
      enqueueSnackbar('Layout atualizado com sucesso', {
        variant: 'success',
      })

      loadLayouts()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar layout')
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  return (
    <Dialog open={openModal} onClose={closeModal} scroll='paper' maxWidth='lg' fullWidth>
      <DialogTitle sx={styles.title}>
        <BtnClose onClose={closeModal} />
        <Stepper nonLinear activeStep={activeStep} sx={styles.stepper}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      {activeStep === 0 ? (
        <DialogContent sx={{height: '320px'}}>
          <Box sx={styles.nameContainer}>
            <FormControl sx={{width: '50%', paddingRight: '20px'}}>
              <TextField
                label='Nome'
                variant='outlined'
                type={'text'}
                value={layout.name}
                autoFocus={true}
                onChange={(e) => setLayout({...layout, name: e.target.value})}
                sx={styles.input}
              />
            </FormControl>
            <FormControl sx={{width: '50%'}}>
              <InputLabel id='demo-simple-select-label'>Selecione o tipo de layout</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                label='Selecione o tipo de layout'
                sx={{width: '100%', height: '56px'}}
                value={typeSelected}
                disabled={isDisabled}
                onChange={(e) => {
                  setTypeSelected(e.target.value)
                  setLayout({...layout, layoutTypeId: e.target.value === 'Padrão' ? 1 : 2})
                }}>
                {layoutType.map((layoutType, index) => (
                  <MenuItem key={`role-${layoutType}-${index}`} value={layoutType}>
                    {layoutType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={styles.dataLayout}>
            <Box sx={{width: '98%', paddingRight: '20px'}}>
              <CompaniesSelect
                selectLabel='Empresa'
                selectSize='medium'
                setSelectedCompaniesIds={setSelectedCompanyId}
                companiesScroll={companiesScroll}
                setCompaniesScroll={setCompaniesScroll}
                pageCount={pageCount}
                setPageCount={setPageCount}
                companyNames={companyNames}
                setCompanyNames={setCompanyNames}
                multiple={false}
                disabled={!isCreate}
              />
            </Box>
            {hasReadClientsPermission && (
              <FormControl sx={styles.form} fullWidth>
                <InputLabel id='demo-simple-select-label' sx={{width: '100%'}}>
                  {clientIdMessage}
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  disabled={companyNames.length === 0}
                  label={clientIdMessage}
                  sx={{
                    width: '100%',
                    height: '56px',
                    '&.Mui-focused .MuiIconButton-root': {color: 'primary.main'},
                    '&:hover .Mui-disabled': {
                      cursor: 'not-allowed',
                    },
                  }}
                  endAdornment={
                    !isCreate && (
                      <Tooltip title='Limpar seleção' placement='bottom'>
                        <IconButton sx={{visibility: layout.clientId ? 'visible' : 'hidden', marginRight: '4px'}} onClick={handleClearClick}>
                          <ClearIcon fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  value={layout.clientId}
                  onChange={(e) => {
                    setLayout({...layout, clientId: Number(e.target.value)})
                    getClient(Number(e.target.value))
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 48 * 4.5 + 8,
                        width: '300px',
                      },
                    },
                  }}>
                  {clients
                    .filter((it) => it.companyId === selectedCompanyId[0])
                    .map((client, index) => (
                      <MenuItem key={`role-${client.id}-${index}`} value={client.id}>
                        {client.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          </Box>
          <Box sx={styles.clientinfo}>
            <FormControl sx={styles.form} fullWidth>
              <InputLabel id='demo-simple-select-label' sx={{width: '320px'}}>
                Selecione a quantidade de câmeras
              </InputLabel>
              <Box sx={{width: '96.5%'}}>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Selecione a quantidade de câmeras'
                  sx={{width: '100%', height: '56px'}}
                  value={layout.maxCameras ? layout.maxCameras : mosaicoSelected}
                  onChange={(e) => {
                    setMosaicoSelected(Number(e.target.value))
                    setLayout({...layout, maxCameras: Number(e.target.value)})
                  }}>
                  {mosaico.map((mosaico, index) => (
                    <MenuItem key={`role-${mosaico}-${index}`} value={mosaico}>
                      Mosaico com {mosaico} câmera{mosaico === 1 ? '' : 's'}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </FormControl>

            <FormControl sx={{width: '100%', paddingTop: '5px'}}>
              <FormControlLabel
                control={<Checkbox />}
                label='Definir este layout como padrão para o cliente selecionado'
                checked={isChecked}
                onClick={(e) => {
                  setIsChecked(!isChecked)
                }}
              />
            </FormControl>
          </Box>
        </DialogContent>
      ) : (
        <DialogContent sx={{height: '600px', overflow: 'hidden'}}>
          <LayoutCameraList disabled={false} leftItems={leftItems} rightItems={rightItems} maxCameras={layout.maxCameras} onChange={onChangeTransferList} />
        </DialogContent>
      )}
      <DialogActions>
        {activeStep === 0 ? (
          <Box sx={styles.stepContainer}>
            <Button onClick={closeModal} sx={styles.cancelButton}>
              Cancelar
            </Button>
            <LoadingButton
              variant='contained'
              sx={styles.confirmButton}
              onClick={() => {
                setActiveStep(1)
                loadCameras()
              }}
              disabled={isCreate && (layout.name === '' || !mosaicoSelected || !typeSelected || !layout.clientId)}>
              Avançar
            </LoadingButton>
          </Box>
        ) : (
          <Box sx={styles.stepContainer}>
            <Box sx={styles.checkedInfo}>{`Câmeras selecionadas: ${rightItems.length}/${layout.maxCameras ? layout.maxCameras : mosaicoSelected}`}</Box>
            <Button
              onClick={() => {
                setActiveStep(0)
              }}
              sx={styles.cancelButton}>
              Voltar
            </Button>
            <LoadingButton
              variant='contained'
              sx={styles.confirmButton}
              onClick={() => {
                if (isCreate) createLayout()
                else updateLayout()
              }}
              loading={isLoading}
              disabled={layout.name === ''}>
              Salvar
            </LoadingButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  stepper: {
    paddingTop: 5,
    paddingBottom: 2,
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '1px',
    marginTop: '15px',
  },
  dataLayout: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '1px',
    marginBottom: '20px',
  },
  clientinfo: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '0.4rem',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  form: {
    paddingTop: 0,
  },
  input: {
    marginBottom: '20px',
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: 2.5,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  checkedInfo: {
    width: '230px',
    height: '20px',
    fontSize: '13px',
    marginBottom: 0,
    marginTop: 2,
  },
  stepContainer: {
    display: 'flex',
    paddingBottom: 2,
    paddingTop: 1,
  },
})

export default LayoutRegisterModal
