import {enqueueSnackbar} from 'notistack'
import {createContext, useContext, useRef, useState} from 'react'
import api, {Camera} from 'src/services/api'
import {Plan} from 'src/services/api/endpoints/PlansEndpoint'

type B2CContextData = {
  registerData: RegisterData
  setRegisterData: (data: Partial<RegisterData>) => void
  selectedRecurrence: EnumRecurrence.MONTHLY | EnumRecurrence.ANNUALY
  setSelectedRecurrence: (selected: EnumRecurrence.MONTHLY | EnumRecurrence.ANNUALY) => void
  paymentMethod: PaymentMethod
  setPaymentMethod: (payment: Partial<PaymentMethod>) => void
  plans: Plan[]
  getPlans: () => void
  selectedPlan: Plan
  setSelectedPlan: (plan: Plan) => void
  cameraCreatedPrevious: Camera | undefined
  setCameraCreatedPrevious: (camera: Camera) => void
  isLoading: boolean
}

export type PersonType = 'person' | 'company'

type RegisterData = {
  email: string
  password: string
  name: string
  companyName: string
  document: string
  phone: string
  type: PersonType
}
export enum EnumRecurrence {
  MONTHLY = 'monthly',
  ANNUALY = 'yearly',
}

export type CardFlagsEnum = 'amex' | 'diners' | 'elo' | 'hipercard' | 'jcb' | 'mastercard' | 'visa'

export type PaymentMethod = {
  cardNumber: string
  cardName: string
  cardExpiration: string
  cardCVV: string
  flag: CardFlagsEnum
}

export const B2CContext = createContext({} as B2CContextData)

function B2CProvider({children}: React.PropsWithChildren<any>) {
  const [registerData, _setRegisterData] = useState({type: 'person'} as RegisterData)
  const [selectedRecurrence, setSelectedRecurrence] = useState(EnumRecurrence.MONTHLY)
  const [paymentMethod, _setPaymentMethod] = useState({} as PaymentMethod)
  const [plans, setPlans] = useState<Array<Plan>>([])
  const [selectedPlan, setSelectedPlan] = useState<Plan>({} as Plan)
  const [cameraCreatedPrevious, setCameraCreatedPrevious] = useState<Camera>()
  const [isLoading, setIsLoading] = useState(true)
  const blockPlansRequests = useRef(false)

  function setRegisterData(data: Partial<RegisterData>) {
    _setRegisterData((prev) => ({...prev, ...data}))
  }

  function setPaymentMethod(payment: Partial<PaymentMethod>) {
    _setPaymentMethod((prev) => ({...prev, ...payment}))
  }

  async function getPlans() {
    if (blockPlansRequests.current) return
    blockPlansRequests.current = true
    setIsLoading(true)
    try {
      const plans = await api.plans.getPlans()
      setPlans(plans.data.data)
    } catch (error) {
      enqueueSnackbar('Não conseguimos buscar os planos disponíveis, tente novamente', {variant: 'error'})
    } finally {
      setIsLoading(false)
      blockPlansRequests.current = false
    }
  }

  return (
    <B2CContext.Provider
      value={{
        registerData,
        setRegisterData,
        selectedRecurrence,
        setSelectedRecurrence,
        paymentMethod,
        setPaymentMethod,
        plans,
        getPlans,
        selectedPlan,
        setSelectedPlan,
        cameraCreatedPrevious,
        setCameraCreatedPrevious,
        isLoading,
      }}>
      {children}
    </B2CContext.Provider>
  )
}

export function useB2CContext() {
  return useContext(B2CContext)
}

export default B2CProvider
