import styled from 'styled-components'

export const TitleOnButton = styled.span`
  color: white;
  font-weight: 600;

  @media (max-width: 1200px) {
    font-size: 10px;
    max-width: 120px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`
