import sub from 'date-fns/sub'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {CompaniesMemo, useCompany} from 'src/contexts/CompanyContext'
import {useQueryParams} from 'src/hooks/useQuery'
import {ListObjectIdEnum, listObject} from 'src/pages/alarms/AlarmsFunctions'
import api, {Alarm} from 'src/services/api'
import {Page} from 'src/types'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isNumber} from 'src/utilities/regexValidation'
import {useDebounce} from 'usehooks-ts'
import {AlarmsContextData, FilterDate} from './AlarmsContextData'
import {objectTypeList} from './ObjectTypesEnum'
import config from 'src/services/config'
import {capitalize} from 'lodash'
import {RowsAlarm} from 'src/common/types/alarms/TableAlarm'

const eventStatus = ['Iniciado', 'Processado', 'Ignorado', 'Expirado', 'Erro', 'Despachado']
const cameraFilterStatus = ['Armado', 'Desarmado']
const cameraFilterSituation = ['Habilitado', 'Desabilitado']

export type CameraImage = {
  cameraName: string
  mediaType: string
  base64String: string
  state: 'LOADING' | 'ERROR' | 'COMPLETED'
}

export type SnapshotEvent = {
  id: number
  additionDate: string
  awsKey: string
  eventId: number
  demarcatedAreas: any[]
}

const AlarmsContext = createContext({} as AlarmsContextData)

function AlarmsContextProvider({children}: any) {
  const {enqueueSnackbar} = useSnackbar()
  const query = useQueryParams()
  const [loadingAlarms, setLoadingAlarms] = useState(true)
  const [reloadingAlarms, setReloadingAlarms] = useState(false)
  const [reloadAlarmCount, setReloadAlarmCount] = useState(0)
  const [alarms, setAlarms] = useState<Alarm[]>([])
  const [self, setSelf] = useState<Alarm>()
  const [alarmsPage, setAlarmsPage] = useState<Page>({page: 1, pageSize: 20})
  const [alarmsTotalCount] = useState(0)
  const [filterSelected, setFilterSelected] = useState<string>('Câmera')
  const [listSelected, setListSelected] = useState<Array<string>>([])
  const [listResult, setListResult] = useState<Array<string>>([])
  const [filterSit, setFilterSit] = useState<string[]>([])
  const [canLoadMore, setCanLoadMore] = useState(true)
  const [situationFilter, setSituationFilter] = useState<string>()
  const filterDebounced = useDebounce(filterSelected)
  const {selectedCompanies} = useCompany()
  const [rows, setRows] = useState<RowsAlarm[]>([])

  const hasPermissionReadCamera = ValidatePermissions('cameras:read')
  const alarmFilterDebounced = useDebounce(situationFilter, 500)

  const [nextId, setNextId] = useState<number | null>(null)
  const [isAlarmModalOpen, setIsAlarmModalOpen] = useState(false)

  const [loadingAlarm, setLoadingAlarm] = useState(false)
  const [alarm, setAlarm] = useState<Alarm | null>(null)

  const [alarmSearch, setAlarmSearch] = useState('')
  const alarmSearchDebounced = useDebounce(alarmSearch, 500)

  const [openFilter, setOpenFilter] = useState<boolean>(true)

  const [snapshotsEvents, setSnapshotsEvents] = useState<SnapshotEvent[]>([])
  const [filterDate, setFilterDate] = useState<FilterDate>({
    from: moment(sub(new Date(), {minutes: 30})).format('YYYY-MM-DDTHH:mm'),
    to: moment(new Date()).format('YYYY-MM-DDTHH:mm'),
  })
  const [isFilterDateAlarm, setIsFilterDateAlarm] = useState<boolean>(false)

  const [maxTimeCreatedAt, setMaxTimeCreatedAt] = useState<string>('')
  const [alarmStatusFilter, _setAlarmStatusFilter] = useState<string[]>(['Despachado'])
  const alarmStatusFilterDebounced = useDebounce(alarmStatusFilter, 1000)

  const loadMore = useRef(false)
  const blockAlertModalRequests = useRef(false)
  const blockEventsRequests = useRef(false)

  const hasReadEventsPermissions = ValidatePermissions('events:read')
  const navigate = useNavigate()

  const [preventGetEvents, setPreventGetEvents] = useState<boolean>(false)

  const handleFilterClick = () => {
    setOpenFilter(!openFilter)
  }
  const handleFilterClose = () => {
    setOpenFilter(false)
  }

  function max(res: Alarm[]) {
    res.forEach((it) => {
      if (moment(maxTimeCreatedAt).format() < moment(it.createdAt).format()) {
        setMaxTimeCreatedAt(it.createdAt)
      }
    })
  }

  const toggleSituationFilter = (status: string) => {
    setFilterSit(cameraFilterSituation)
    if (status === 'Habilitado' || status === 'Desabilitado') {
      const isCheckedSituation = filterSit.length > 0 ? filterSit.includes(status) : cameraFilterSituation?.includes(status)
      if (isCheckedSituation) {
        setSituationFilter(status)
        setFilterSit((prev) =>
          prev?.map((value) => {
            if (value === status) value = ''
            return value
          }),
        )
      } else {
        setFilterSit(cameraFilterSituation)
        setSituationFilter(undefined)
        reloadAlarms()
      }
    }
  }

  async function getSnapshotsFromEvent(alarmId: number) {
    setIsAlarmModalOpen(true)
    if (blockAlertModalRequests.current) return
    blockAlertModalRequests.current = true
    setLoadingAlarm(true)

    try {
      const snapshotsResponses = await api.events.getSnapshots({
        filter: {
          eventIds: [alarmId.toString()],
        },
      })

      const snapshotEventDTO = snapshotsResponses.data.data.entities.map((it) => {
        return {
          id: it.id,
          eventId: it.eventId,
          additionDate: it.additionDate,
          awsKey: it.awsKey,
          demarcatedAreas: it.demarcatedAreas,
        }
      })

      setSnapshotsEvents(snapshotEventDTO)
      navigate(`/events?id=${alarmId}`)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar os dados do evento')
    } finally {
      setLoadingAlarm(false)
      blockAlertModalRequests.current = false
    }
  }

  function toggleStatusFilter(status: string) {
    const isChecked = alarmStatusFilter.includes(status)

    if (isChecked) {
      setAlarmStatusFilter(alarmStatusFilter.filter((x) => x !== status))
    } else {
      setAlarmStatusFilter([...alarmStatusFilter, status])
    }
  }

  async function setAlarmStatusFilter(newAlarmStatusFilter: string[]) {
    _setAlarmStatusFilter(newAlarmStatusFilter)
  }

  useEffect(() => {
    setNextId(null)
    setPreventGetEvents(false)
    setAlarms([])
  }, [alarmStatusFilterDebounced, alarmFilterDebounced, alarmSearchDebounced, filterDebounced, listSelected, listObject, filterDate, selectedCompanies])

  const loadAlarms = useCallback(async () => {
    try {
      if (preventGetEvents) {
        setPreventGetEvents(false)
        setLoadingAlarm(false)
        setLoadingAlarms(false)
      }
      if (blockEventsRequests.current) return
      blockEventsRequests.current = true
      setLoadingAlarm(true)
      setLoadingAlarms(true)
      if (filterDate.from !== '' && filterDate.to !== '') setIsFilterDateAlarm(true)
      if (!hasReadEventsPermissions) return

      const eventTypeIds = listSelected.flatMap((objectName) => listObject.find((value) => value.name === objectName)!.id)
      let includes: Array<string> = ['camera', 'status']
      eventTypeIds?.forEach((value) => {
        if (ListObjectIdEnum.FACIAL_RECOGNITION === value) includes.push('faceRecognitions.face')
        else if (ListObjectIdEnum.LINE_CROSSING === value) includes.push('lineCrossingLogs')
        else includes.push('eventType')
      })
      const queryCamera = query.get('camera')
      if (queryCamera) {
        setAlarmSearch(queryCamera)
      }
      const cameraName = filterDebounced.includes('Câmera') ? queryCamera || alarmSearchDebounced : undefined

      const response = await api.events.getMany({
        batchSize: 50,
        startId: loadMore.current ? nextId : null,
        search: {
          cameraName,
          id: filterDebounced.includes('ID') && isNumber(alarmSearchDebounced) ? alarmSearchDebounced : undefined,
          clientName: filterDebounced.includes('Cliente') ? alarmSearchDebounced : undefined,
        },
        filter: {
          companyIds: selectedCompanies,
          eventTypeIds,
          statuses: alarmStatusFilterDebounced.map((s) => s.toUpperCase()),
          from: filterDate.from !== '' ? moment(filterDate.from).format() : moment().hours(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm'),
          to: filterDate.to !== '' ? moment(filterDate.to).format() : moment().hours(23).minutes(59).seconds(0).format('YYYY-MM-DDTHH:mm'),
        },
        orderBy: {ids: 'DESC'},
        includes: includes.length > 0 ? includes : undefined,
      })

      let eventsResponse = []
      if (loadMore.current) eventsResponse = [...alarms, ...response.data.data.items]
      else eventsResponse = [...response.data.data.items]
      setAlarms(eventsResponse)
      const companiesMemo = localStorage.getItem('companiesMemo')
      const companiesMemoParsed = companiesMemo ? JSON.parse(companiesMemo) : [{id: 0, name: ''}]

      setRows(
        eventsResponse.map((alarm) => {
          const lastUpdate = new Date(alarm.lastUpdate)
          const createdAt = new Date(alarm.createdAt)
          const timeoutDate = new Date(Date.now() - config.eventInitTimeout)
          const isTimeout = moment(createdAt).isBefore(timeoutDate)
          const status = alarm.status?.description ? capitalize(alarm.status.description?.toLowerCase()) : ''
          const objectName = alarm.objects !== null ? objectTypeList.find((object) => object.name === alarm.objects)?.label : '-'
          const companyId = alarm.camera?.companyId
          const companyName =
            companiesMemoParsed && companiesMemoParsed.length ? companiesMemoParsed?.find((it: CompaniesMemo) => it?.id === companyId).name : '-'
          return {
            id: alarm.id,
            trigger: alarm.hasTrigger,
            name: alarm.camera?.name ?? '-',
            company: companyName,
            objectTypeLabel: objectName,
            status: status === 'Iniciado' && isTimeout ? 'Timeout' : status,
            lastUpdate: lastUpdate.toLocaleString(),
            createdAt: createdAt.toLocaleString(),
            timeUpdate: moment(lastUpdate).fromNow().split(' ').slice(1).join(' '),
            self: alarm,
          }
        }),
      )

      const nextIdFromRes = response.data.data.nextId
      if (nextIdFromRes === null || nextIdFromRes === nextId) {
        setPreventGetEvents(true)
      }
      setCanLoadMore(response.data.data.items.length === 50)
      setNextId(nextIdFromRes)
      if (filterDate.to === '' && response.data.data.items.length > 0) {
        setMaxTimeCreatedAt(response.data.data.items[0].createdAt)
        max(response.data.data.items)
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar eventos')
    } finally {
      setLoadingAlarm(false)
      setLoadingAlarms(false)
      setReloadingAlarms(false)
      loadMore.current = false

      setTimeout(() => {
        blockEventsRequests.current = false
      }, 2000)
    }
  }, [reloadAlarmCount, alarmStatusFilterDebounced, alarmFilterDebounced, alarmSearchDebounced, listSelected, listObject, filterDate, selectedCompanies])

  useEffect(() => {
    const controller = new AbortController()

    loadAlarms()
    return () => controller.abort()
  }, [reloadAlarmCount, alarmStatusFilterDebounced, alarmFilterDebounced, alarmSearchDebounced, listSelected, listObject, filterDate, selectedCompanies])

  useEffect(() => {
    const filterListEvents = sessionStorage.getItem('filter-list-events')
    if (filterListEvents === null) return
    const list = JSON.parse(filterListEvents)
    setListSelected(list)
  }, [])

  async function openAlarmModal(alarm: Alarm) {
    if (loadingAlarm) return
    setSelf(alarm)
    setAlarm(alarm)
    getSnapshotsFromEvent(alarm.id)
  }

  function closeAlarmModal() {
    setIsAlarmModalOpen(false)
    setAlarm(null)
    navigate('/events')
  }

  const reloadAlarms = (shouldLoadMore?: boolean) => {
    if (shouldLoadMore) loadMore.current = true
    setReloadingAlarms(true)
    setReloadAlarmCount((x) => x + 1)
    closeAlarmModal()
  }

  const alarmsPageChange = (page: Partial<Page>) => setAlarmsPage((lastPage) => ({...lastPage, ...page}))

  return (
    <AlarmsContext.Provider
      value={{
        hasPermissionReadCamera,
        alarms,
        alarmsTotalCount,
        alarmsPage,
        alarmsPageChange,
        alarmSearch,
        setAlarmSearch,
        reloadAlarms,
        reloadingAlarms,
        loadingAlarms: loadingAlarms && !reloadingAlarms,
        alarm,
        loadingAlarm,
        isAlarmModalOpen,
        openAlarmModal,
        closeAlarmModal,
        setOpenFilter,
        openFilter,
        handleFilterClick,
        handleFilterClose,
        setLoadingAlarms,
        setAlarms,
        snapshotsEvents,
        eventStatus,
        filterDate,
        setFilterDate,
        isFilterDateAlarm,
        setIsFilterDateAlarm,
        maxTimeCreatedAt,
        self,
        objectTypeList,
        filterSelected,
        setFilterSelected,
        listSelected,
        setListSelected,
        listResult,
        setListResult,
        cameraFilterStatus,
        cameraFilterSituation,
        toggleStatusFilter,
        toggleSituationFilter,
        alarmStatusFilter,
        cameraSituationFilter: situationFilter,
        nextId,
        setNextId,
        preventGetEvents,
        canLoadMore,
        getSnapshotsFromEvent,
        rows,
      }}>
      {children}
    </AlarmsContext.Provider>
  )
}

function useAlarmsContext() {
  return useContext(AlarmsContext)
}

export {useAlarmsContext}
export default AlarmsContextProvider
