import {useSnackbar} from 'notistack'
import {createContext, useCallback, useContext, useEffect, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {Client, Server} from 'src/services/api'
import {UpdateAnalyticCameraBodyDTO} from 'src/services/api/endpoints/AnalyticCameraEndpoint'
import {Page} from 'src/types'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {AnalyticCameraContextData} from './AnalyticCamerasContextData'

export class AnalyticCamera {
  name = ''
  macAddress = ''
  code?: string = undefined
  id?: string = undefined
  host?: string = undefined
  port?: number = undefined
  analytics?: string[] = undefined
}

export type Analytics = {
  analytic: string
  label: string
  selected: boolean
}

type CameraAnalyticDTO = {
  client: {id: number; name: string; companyId: number}
  clientId: number
  id: number
  macAddress: string
  name: string
}

export type FormValidationType = {[x in keyof AnalyticCamera]: boolean}

const AnalyticCamerasContext = createContext({} as AnalyticCameraContextData)

export type AnalyticCamerasProviderProps = {
  children: JSX.Element | JSX.Element[]
}

const AnalyticCamerasProvider = ({children}: AnalyticCamerasProviderProps) => {
  const {selectedCompanies} = useCompany()
  const [reloadClientCount, setReloadClientCount] = useState(0)
  const [loadingClients, setLoadingClients] = useState(true)
  const [clients, setClients] = useState<Client[]>([])
  const [isLoadingClients, setIsLoadingClients] = useState(false)
  const [editModalIsOpen, setEditModalIsOpen] = useState<boolean>(false)
  const [selectedCamera, setSelectedCamera] = useState<AnalyticCamera>(new AnalyticCamera())
  const [selectedCameraId, setSelectedCameraId] = useState<number | null>(null)
  const [analytics, setAnalytics] = useState<Analytics[]>([] as Analytics[])
  const [selectedAnalytics, setSelectedAnalytics] = useState<string[]>([])
  const [analyticCamera, setAnalyticCamera] = useState<AnalyticCamera>(new AnalyticCamera())
  const {enqueueSnackbar} = useSnackbar()
  const [analyticCameras, setAnalyticCameras] = useState<CameraAnalyticDTO[]>([])
  const [totalCameras, setTotalCameras] = useState(0)
  const [page, setPage] = useState<Page>({
    page: 1,
    pageSize: 20,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [camera, setCamera] = useState<AnalyticCamera>(analyticCamera)

  const [isStepsOpen, setIsStepsOpen] = useState(false)
  const [step, setStep] = useState<number>(0)
  const [selectedTypeIntegration, setSelectedTypeIntegration] = useState<string>('')
  const [selectedModelDevice] = useState<number | null>(null)
  const [selectedClient, setSelectedClient] = useState<Client>()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [, setServers] = useState<Server[]>([] as Server[])
  const [serversSearch, setServersSearch] = useState('')
  const [filteredClients, setfilteredClients] = useState<Array<string> | string>([])
  const [filteredAnalyticCameras, setFilteredAnalyticCameras] = useState<Array<string> | string>([])
  const hasCreateCameraPermission = ValidatePermissions('analytic-cameras:create')
  const [searchValue, setSearchValue] = useState('')
  const [selectedSearchFilter, setselectedSearchFilter] = useState<string>('Nome do Dispositivo')
  const [selectedCompanyId, setSelectedCompanyId] = useState<number[] | null>([selectedCompanies[0]] || null)
  const debouncedServerSearch = useDebounce(serversSearch, 200)
  const debouncedSearchValue = useDebounce(searchValue, 200)
  const [totalPages, setTotalPages] = useState(0)

  const hasPermissionReadClients = ValidatePermissions('clients:read')
  const hasPermissionReadServers = ValidatePermissions('servers:read')

  const pageChange = (updatePage: Partial<Page>) => setPage((prev) => ({...prev, ...updatePage}))

  const setCurrentPage = (page: number): void => {
    setPage((prev: Page) => ({...prev, page}))
  }

  const setPageSize = (pageSize: number): void => {
    setPage((prev: Page) => ({...prev, pageSize}))
  }

  function stepsOpen() {
    setIsStepsOpen(true)
    setSelectedTypeIntegration('')
    reloadClients()
  }

  function step1(client: Client) {
    setSelectedClient(client)
    setStep(1)
  }

  function step2() {
    if (step !== 3) {
      setAnalyticCamera(new AnalyticCamera())
    }
    setStep(2)
  }

  function step3() {
    setStep(3)
  }

  async function stepsCompleted() {
    if (isSaving) return

    if (selectedTypeIntegration === 'Hikvision') {
      stepsClose()
    }
  }

  function stepsPrevious() {
    setStep((x) => Math.max(x - 1, 0))
    setSelectedTypeIntegration('')
  }

  function stepsClose() {
    setIsStepsOpen(false)
    setStep(0)
    setSelectedTypeIntegration('')
    setAnalyticCamera(new AnalyticCamera())
    setSelectedAnalytics([])
    setAnalytics(
      analytics.map((analytic) => {
        analytic.selected = false
        return analytic
      }),
    )
  }

  const hasUpdateCameraPermission = ValidatePermissions('analytic-cameras:update')

  const getAnalytics = async () => {
    try {
      const response = await api.analyticCamera.getAllAnalytics()
      const rawAnalytics = response.data.data
      const mappedAnalytics = rawAnalytics.map((analytic) => {
        const newAnalytic = {...analytic} as Analytics
        newAnalytic.selected = false
        if (selectedAnalytics.includes(analytic.analytic)) newAnalytic.selected = true
        return newAnalytic
      })
      setAnalytics(mappedAnalytics)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar os analíticos')
    }
  }

  const openModalEdit = () => {
    setEditModalIsOpen(true)
  }

  const closeModalEdit = () => {
    setEditModalIsOpen(false)
    const resettedAnalytics = analytics.map((analytic) => {
      analytic.selected = false
      return analytic
    })
    setAnalytics(resettedAnalytics)
    setSelectedCameraId(null)
    setSelectedAnalytics([] as string[])
  }
  async function loadAnalytics() {
    try {
      if (!selectedCameraId) return
      const response = await api.analyticCamera.getAnalytics(selectedCameraId)
      const rawAnalytics = response.data.data
      const mappedAnalytics = analytics.map((analytic) => {
        if (rawAnalytics.includes(analytic.analytic)) return {...analytic, selected: !analytic.selected}
        return analytic
      })
      setAnalytics(mappedAnalytics)
      const selected = mappedAnalytics.filter((analytic) => analytic.selected).map((analytic) => ' ' + analytic.label)
      setSelectedAnalytics(selected)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar os analíticos da câmera')
    }
  }

  useEffect(() => {
    loadAnalytics()
  }, [selectedCameraId, setAnalytics, enqueueSnackbar])

  const saveEdit = async () => {
    try {
      const selectedAnalytics = analytics.filter((analytic) => analytic.selected).map((analytic) => analytic.analytic)
      const body: UpdateAnalyticCameraBodyDTO = {name: selectedCamera.name, macAddress: selectedCamera.macAddress, analytics: selectedAnalytics}
      const response = await api.analyticCamera.updateCameraAnalytics(selectedCameraId!, body)
      if (response.data.data) {
        enqueueSnackbar('Analíticos atualizados com sucesso', {
          variant: 'success',
        })
        closeModalEdit()
        loadNavigationState()
        setSelectedCameraId(null)
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar os analíticos da câmera')
    }
  }

  useEffect(() => {
    loadNavigationState(debouncedSearchValue)
  }, [debouncedSearchValue])

  const loadNavigationState = async (debouncedSearchValue?: string) => {
    try {
      setIsLoading(true)
      const search: any = {}
      if (selectedSearchFilter === 'Nome do Dispositivo') search['cameraName'] = debouncedSearchValue
      else if (selectedSearchFilter === 'Cliente') search['clientName'] = debouncedSearchValue
      else search['macAddress'] = debouncedSearchValue
      const response = await api.analyticCamera.getAnalyticCameras({page: page.page, pageSize: page.pageSize, search, filter: {companyIds: selectedCompanies}})
      setAnalyticCameras(response.data.data.entities)
      setTotalCameras(response.data.data.totalElements)
      const rawTotalPages = response.data.data.totalPages
      setTotalPages(rawTotalPages || 0)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao buscar câmeras analíticas')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!hasPermissionReadClients) return

    async function loadClients() {
      try {
        if (!selectedCompanyId) return
        const response = await api.client.getMany({
          page: 1,
          paginate: false,
          filter: {companyIds: selectedCompanyId},
          search: {
            name: debouncedServerSearch,
          },
        })
        setClients(response.data.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar os Clientes')
      } finally {
        setLoadingClients(false)
        setIsLoadingClients(false)
      }
    }
    loadClients()
  }, [enqueueSnackbar, debouncedServerSearch, reloadClientCount, selectedCompanyId])

  const reloadClients = () => {
    if (loadingClients) return
    setIsLoadingClients(true)
    setReloadClientCount((x) => x + 1)
  }

  const loadAllServers = useCallback(async () => {
    if (!isStepsOpen) return
    if (!selectedCompanyId) return
    if (hasPermissionReadServers) {
      try {
        const response = await api.server.findManyPaged({
          page: 1,
          pageSize: 100,
          filter: {
            integrationType: 'ENTRADA',
            companyIds: selectedCompanyId,
          },
        })
        setServers(response.data.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar servidores de integração')
      }
    }
  }, [enqueueSnackbar, hasPermissionReadServers, isStepsOpen, selectedCompanyId])

  useEffect(() => {
    loadAllServers()
  }, [selectedClient, loadAllServers])

  const deleteAnalyticCameras = async (selectedCamerasIds: number[]) => {
    try {
      await api.analyticCamera.deleteAnalyticCameras(selectedCamerasIds)
      enqueueSnackbar('Câmeras deletadas com sucesso', {variant: 'success'})
      return true
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao deletar câmeras')
      return false
    }
  }
  return (
    <AnalyticCamerasContext.Provider
      value={{
        analyticCamera,
        setAnalyticCamera,
        editModalIsOpen,
        openModalEdit,
        closeModalEdit,
        selectedCamera,
        setSelectedCamera,
        analytics,
        setAnalytics,
        selectedAnalytics,
        setSelectedAnalytics,
        selectedCameraId,
        setSelectedCameraId,
        getAnalytics,
        saveEdit,
        analyticCameras,
        totalCameras,
        page,
        setPage,
        isLoading,
        hasUpdateCameraPermission,
        camera,
        setCamera,
        isStepsOpen,
        step,
        step1,
        step2,
        step3,
        stepsPrevious,
        stepsClose,
        stepsCompleted,
        selectedTypeIntegration,
        setSelectedTypeIntegration,
        selectedModelDevice,
        selectedClient,
        isSaving,
        setIsSaving,
        stepsOpen,
        hasPermissionReadClients,
        loadAllServers,
        serversSearch,
        setServersSearch,
        isLoadingClients,
        reloadClients,
        clients,
        loadNavigationState,
        filteredAnalyticCameras,
        setFilteredAnalyticCameras,
        filteredClients,
        setfilteredClients,
        hasCreateCameraPermission,
        searchValue,
        setSearchValue,
        selectedSearchFilter,
        setselectedSearchFilter,
        debouncedServerSearch,
        debouncedSearchValue,
        deleteAnalyticCameras,
        selectedCompanyId,
        setSelectedCompanyId,
        pageChange,
        totalPages,
        setCurrentPage,
        setPageSize,
      }}>
      {children}
    </AnalyticCamerasContext.Provider>
  )
}

function useAnalyticCamerasContext() {
  return useContext(AnalyticCamerasContext)
}

export {useAnalyticCamerasContext}
export default AnalyticCamerasProvider
