import {PageRangeBase} from './PageRangeBase'
import {PageRangeProps} from './PageRangeProps'

function CurrentItemsRange(props: PageRangeProps) {
  const {totalItems, currentPage, itemsPerPage} = props
  return (
    <PageRangeBase>
      {(itemsPerPage * currentPage - (itemsPerPage - 1)).toString()}-
      {itemsPerPage * currentPage >= totalItems ? totalItems.toString() : (itemsPerPage * currentPage).toString()} de {totalItems.toString()}
    </PageRangeBase>
  )
}

export default CurrentItemsRange
