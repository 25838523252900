import styled from 'styled-components'

export const StepperModalContainer = styled.div`
  padding: 20px 0 10px 0;
  @media (max-width: 768px) {
    overflow-y: scroll;
  }
`

export const StepperModalActionsButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const StepperModalBarrierContainer = styled.div`
  display: flex;
  gap: 16px;
  min-height: 300px;
  .separation {
    display: block;
  }
  @media (max-width: 1024px) {
    .separation {
      display: none;
      max-height: 290px;
    }
  }
`
