import styled, {keyframes} from 'styled-components'
import {DropdownContainerProps, DropdownIconColorProps, DropdownListProps, DropdownVerifyProps} from './DropdownProps'

export const DropdownContainer = styled.div<DropdownContainerProps>`
  position: relative;
  min-width: 80px;
  width: ${({width}) => (!!width ? width : '100%')};
  height: ${({height}) => (!!height ? height : '100%')};
  font-family: ${({theme}) => theme.fonts.primary};

  ${(props) => props.margin && `margin: ${props.margin};`};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`};
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft};`};
  ${(props) => props.marginRight && `margin-right: ${props.marginRight};`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`};

  ${(props) => props.padding && `padding: ${props.padding};`};
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft};`};
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight};`};
  ${(props) => props.paddingBottom && `padding-bottom: ${props.paddingBottom};`};
  @media (max-width: 1400px) {
    font-size: 12px;
  }
`

export const DropdownHeaderContainer = styled.div`
  margin-bottom: 4px;
`

export const DropdownLabel = styled.label<DropdownVerifyProps>`
  font-family: ${({theme}) => theme.fonts.primary};
  color: ${({disabled, theme}) => (disabled ? `${theme.colors.disabled}` : `${theme.colors.text}`)};
  font-size: ${({labelFontSize}) => labelFontSize ?? '14px'};
  @media (max-width: 1400px) {
    font-size: 10px;
  }
`

export const DropdownRequired = styled.span<DropdownVerifyProps>`
  margin-left: 4px;
  color: ${({disabled, theme}) => (disabled ? `${theme.colors.disabled}` : `${theme.colors.error}`)};
`

export const DropdownInputContainer = styled.div<DropdownVerifyProps>`
  display: inline-flex;
  align-items: center;

  border: 1px solid ${({theme, borderColor}) => borderColor ?? theme.input.borderColor};
  border-radius: ${({theme}) => theme.input.borderRadius};
  box-sizing: border-box;
  background-color: ${({bgColor, theme}) => bgColor ?? theme.dropdown.backgroundColor};
  padding-left: ${({theme, paddingLeftInsideInput}) => paddingLeftInsideInput ?? theme.dropdown.paddingHorizontal};
  padding-right: ${({theme}) => theme.dropdown.paddingHorizontal};
  width: 100%;
  height: 100%;

  border-color: ${({error, open, theme, disabledBorderColor}) => (error ? `${theme.colors.error};` : open && !disabledBorderColor && `${theme.colors.focus};`)};

  ${({disabled, theme}) =>
    disabled &&
    `border-color: ${theme.colors.disabled};
    background-color: ${theme.colors.disabledBackground};`}
`

export const DropdownIcon = styled.div<DropdownIconColorProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  color: ${({color, disabled, theme}) => (disabled ? `${theme.colors.disabled}` : color ?? `${theme.input.borderColor}`)};

  width: 16px;
  height: 16px;
`

export const DropdownHiddenInput = styled.input`
  width: 0;
  height: 0;
  display: none;
`

export const DropdownInput = styled.input<DropdownContainerProps>`
  border: 0;
  outline: 0;

  width: 100%;
  height: ${({height, theme}) => height ?? theme.input.height};
  padding-top: 0px;
  padding-bottom: 0px;

  font-size: ${({fontSize, theme}) => fontSize ?? `${theme.input.fontSize}`};
  font-family: ${({fontFamily, theme}) => fontFamily ?? `${theme.input.fontFamily}`};
  font-weight: ${({fontWeight, theme}) => fontWeight ?? `${theme.input.fontWeight}`};

  background-color: transparent;

  color: ${({disabled, theme}) => (disabled ? `${theme.colors.disabled}` : `${theme.colors.text}`)};

  ::placeholder {
    font-weight: ${({theme}) => theme.dropdown.fontWeight};
    color: ${({disabled, placeholderColor, theme}) => (disabled ? `${theme.colors.disabled}` : placeholderColor)};
  }

  @media (max-width: 1400px) {
    font-size: 10px;
  }
`

export const DropdownButton = styled.span<DropdownVerifyProps>`
  width: 20px;
  text-align: center;
  border-radius: 50%;
  cursor: ${({disabled}) => (disabled ? `auto` : `pointer`)};
`

export const DropdownList = styled.ul<DropdownListProps>`
  z-index: 9999;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  list-style: none;
  max-height: 290px;
  padding: 6px 0;
  margin: 8px 0 0 0;
  border-radius: ${({theme}) => theme.input.borderRadius};
  border: 1px solid ${({theme, borderColor}) => borderColor ?? theme.input.borderColor};
  background-color: ${({bgColor, theme}) => bgColor ?? theme.dropdown.backgroundColor};

  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  font-family: ${({theme}) => theme.fonts.primary};
  font-size: ${({theme}) => theme.dropdown.fontSize};

  ::before {
    content: '';
  }

  ::-webkit-scrollbar {
    width: ${({theme}) => theme.dropdown.scrollbarWidth};
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${({theme}) => theme.dropdown.scrollbarBackground};
    border-radius: 100vw;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`

export const DropdownListItem = styled.li`
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: ${({theme}) => theme.colors.text};
  padding: 14px 16px;

  :hover {
    background-color: ${({theme}) => theme.dropdown.itemHoverColor};
    color: black;
  }

  :focus {
    outline: none;
    background-color: ${({theme}) => theme.dropdown.itemHoverColor};
    color: #fff;
  }
`

const appearsFromBottom = keyframes`
  from {
    bottom: -32px;
  }
  to {
    bottom: 12px;
  }
`

export const DropdownInfiniteScrollLoading = styled.div`
  padding: 8px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  background-color: ${({theme}) => theme.dropdown.backgroundColor};
  box-shadow:
    0px -1px 5px rgba(0, 0, 0, 0.15),
    0px 4px 5px rgba(0, 0, 0, 0.25);
  position: sticky;
  bottom: -32px;
  margin-top: -52px;

  animation: ${appearsFromBottom} 500ms forwards;
`

export const DropdownErrorText = styled.span<DropdownVerifyProps>`
  position: absolute;
  bottom: -1rem;
  font-size: 12px;
  white-space: nowrap;
  left: 0;

  color: ${({disabled, theme, error}) => (disabled ? `${theme.colors.disabled}` : error ? `${theme.colors.error}` : `${theme.input.borderColor}`)};
`
