import {Box, Button, Checkbox, Input, Typography} from '@viptech/react-components'
import _ from 'lodash'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {ListFacialRecognitionDTO} from 'src/common/models/facial-recognition/ListFacialRecognitionDTO'
import {CreateTriggerDTO, Trigger} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import Dropdown from '../../../../../../../../components/dropdown/Dropdown'
import {DropdownItem} from '../../../../../../../../components/dropdown/DropdownProps'
import api, {PutActions} from '../../../../../../../../services/api'
import handleErrorWithSnackbar from '../../../../../../../../utilities/handleErrorWithSnackbar'
import {useCameraConfigContext} from '../../../../../../context/CameraConfigContext'
import {StepperModalActionsButtonContainer} from '../../../styles'
import Card from '../../common/components/Card'
import {skipSecondStep, skipThirdStep} from '../Constants'
import {CreateMultipleActionsProps, MultipleActionsType} from './types/CreateMultipleActionsTypes'

const dropdownItemNoneSelected: DropdownItem = {
  id: '',
  label: '',
}

const enumServerLabel = {
  'One Portaria': 'Setor',
  Moni: 'Partição',
  'Brain Box': 'Canal',
  Telegram: 'Id do chat',
  Spia: '',
  Harpia: '',
}

const enumIdSpiaHarpia = {
  '-3': 4,
  '-4': 5,
}

const enumLists = ['Reconhecimento Facial', 'Facial', 'Leitura de Placas', 'Placa']

const CreateMultipleActions = (props: CreateMultipleActionsProps) => {
  const {finishFunction, returnFunction, chosen, brainBox, brainBoxItems, outputServersItems, actions, listSelectedId, openWarningModal, updateTable, isEdit} =
    props
  const {camera, setDiffConfig, clientIntegration} = useCameraConfigContext()
  const {enqueueSnackbar} = useSnackbar()

  const [selectedServer, setSelectedServer] = useState(dropdownItemNoneSelected)
  const [selectedList, setSelectedList] = useState<DropdownItem>(dropdownItemNoneSelected)

  const [multipleActionsList, setMultipleActionsList] = useState<MultipleActionsType[]>(actions)
  const [partition, setPartition] = useState(camera?.configuration.partition || '')
  const [showActions, setShowActions] = useState(false)

  const [brainBoxSelected, setBrainBoxSelected] = useState(dropdownItemNoneSelected)
  const [outputBrainBoxItems, setOutputBrainBoxItems] = useState<DropdownItem[]>([])
  const [outputSelectBox, setOutputSelectBox] = useState(dropdownItemNoneSelected)

  const [configTriggerTime, setConfigTriggerTime] = useState(false)
  const [triggerTime, setTriggerTime] = useState('')

  const [telegramChatId, setTelegramChatId] = useState('')

  const [faceLists, setFaceLists] = useState<Array<ListFacialRecognitionDTO>>([])
  const [faceListsItems, setFaceListsItems] = useState<DropdownItem[]>([])
  const showFaceOrPlatesLists = useRef(false)
  const [platesListsItems, setPlatesListsItems] = useState<DropdownItem[]>([])

  const removedLinksMatrix = useRef(new Map<string, boolean>())

  const [hasLoaded, setHasLoaded] = useState(false)

  const [outputServers, setOutputServers] = useState(outputServersItems)

  useEffect(() => {
    actions.forEach((it) => {
      if (it.outputId !== -2) removedLinksMatrix.current.set(`${it.listId}-${it.outputId}`, true)
    })
  }, [actions])

  useEffect(() => {
    if (brainBoxSelected.id === '') return
    const getOutput = brainBox.filter((it) => it.id === Number(brainBoxSelected.id))
    if (getOutput.length) {
      const filteredOutput: BrainBoxOutput[] = []
      getOutput[0].outputDevices.forEach((it) => {
        if (removedLinksMatrix.current.has(`${selectedList.id}-${it.id}`)) {
          if (removedLinksMatrix.current.get(`${selectedList.id}-${it.id}`) === false) {
            filteredOutput.push(it)
          }
        } else {
          filteredOutput.push(it)
        }
      })

      setOutputBrainBoxItems(filteredOutput.map((it) => ({id: String(it.id), label: it.description!})))
    }
  }, [brainBoxSelected, selectedList, brainBox])

  useEffect(() => {
    if (chosen?.detectionTypeChosen === 'Reconhecimento Facial' || chosen?.detectionTypeChosen === 'Facial') {
      getFaceLists()
      showFaceOrPlatesLists.current = true
    }
    if (chosen?.detectionTypeChosen === 'Leitura de Placas' || chosen?.detectionTypeChosen === 'Placa') {
      getPlateLists()
      showFaceOrPlatesLists.current = true
    }
    setHasLoaded(true)
  }, [])

  const handleNextInputComponent = () => {
    if (selectedServer.label === 'One Portaria' || selectedServer.label === 'Moni') {
      return (
        <Input.Root
          labelFontWeight='400'
          placeholder={enumServerLabel[selectedServer.label as keyof typeof enumServerLabel]}
          label={enumServerLabel[selectedServer.label as keyof typeof enumServerLabel]}
          value={partition}
          defaultValue={camera?.configuration.partition}
          onChange={(e) => {
            setPartition(e.target.value)
            if (e.target.value !== camera?.configuration.partition) setDiffConfig({partition: e.target.value})
          }}
        />
      )
    }
    if (selectedServer.label === 'Brain Box') {
      return (
        <Box display='flex' columnGap='10px' direction='column'>
          <Dropdown
            height='40px'
            fontSize='12px'
            selected={brainBoxSelected}
            placeholder='Brain Box cadastradas'
            items={brainBoxItems}
            onChangeSelected={(e) => {
              setBrainBoxSelected(e)
            }}></Dropdown>

          <Dropdown
            marginTop='10px'
            height='40px'
            fontSize='12px'
            selected={outputSelectBox}
            placeholder='Canais'
            items={outputBrainBoxItems}
            onChangeSelected={(e) => {
              setOutputSelectBox(e)
            }}></Dropdown>

          <Box display='flex' direction='column' padding='10px 0px'>
            <Checkbox
              label='Configurar tempo de disparo'
              labelSize='14px'
              checked={configTriggerTime}
              onChange={() => setConfigTriggerTime(!configTriggerTime)}
            />
            <Typography variant='span' size='12px' color='#8E8E8E'>
              Essa ação permite você personalizar por quantos segundos o alerta irá soar
            </Typography>

            {configTriggerTime && (
              <Box marginTop='10px'>
                <Input.Root
                  placeholder='Em segundos'
                  label='Tempo de disparo'
                  value={triggerTime}
                  onChange={(e) => setTriggerTime(e.target.value)}
                  mask={(input) => input.replace(/\[a-z/g, '')}
                />
              </Box>
            )}
          </Box>
        </Box>
      )
    }
    if (selectedServer.label === 'Telegram') {
      return (
        <Box>
          <Input.Root
            labelFontWeight='400'
            placeholder={''}
            label={'Id do chat'}
            onChange={(e) => {
              setTelegramChatId(e.target.value)
            }}
            mask={(value) => {
              if (value.length <= 19 && value.match(/^-?([0-9]+)?$/)) return value
              return telegramChatId
            }}
          />
        </Box>
      )
    }
  }

  function clearFields() {
    setSelectedServer(dropdownItemNoneSelected)
    setSelectedList(dropdownItemNoneSelected)
    setBrainBoxSelected(dropdownItemNoneSelected)
    setOutputSelectBox(dropdownItemNoneSelected)
    setConfigTriggerTime(false)
    setTriggerTime('')
    setPartition(camera?.configuration.partition || '')
    setTelegramChatId('')
  }

  const handleAddMultipleActions = () => {
    const inputValue = () => {
      if (selectedServer.label === 'Sigma Cloud') return ''
      if (selectedServer.label === 'Brain Box') return outputSelectBox.label
      if (selectedServer.label === 'Telegram') return telegramChatId
      if (selectedServer.label === 'Spia' || selectedServer.label === 'Harpia' || !partition) return ''
      return partition
    }

    const action = {
      outputId: +selectedServer.id,
      label: selectedServer.label,
      inputLabel: enumServerLabel[selectedServer.label as keyof typeof enumServerLabel],
      valueInput: inputValue(),
      brainBox: selectedServer.label === 'Brain Box' ? brainBoxSelected.label : undefined,
      brainboxTriggerTime: +triggerTime || undefined,
      listId: +selectedList.id || undefined,
      listName: selectedList.label || undefined,
      telegramChatId: +telegramChatId,
    }

    const brainBoxIsSelected = +outputSelectBox.id !== -1
    const selectedActionIsBrainbox = action.outputId === -1
    if (brainBoxIsSelected && selectedActionIsBrainbox) action.outputId = +outputSelectBox.id
    const newMultipleActionsList: MultipleActionsType[] = [...multipleActionsList]
    newMultipleActionsList.push(action)
    if (selectedServer.label === 'Brain Box') {
      const newItems = outputBrainBoxItems.filter((it) => it.id !== outputSelectBox.id)
      setOutputBrainBoxItems(newItems)
      setBrainBoxSelected(dropdownItemNoneSelected)
      setOutputSelectBox(dropdownItemNoneSelected)
    }
    const actionListId = action.listId
    if (action.outputId !== -2) removedLinksMatrix.current.set(`${actionListId}-${action.outputId}`, true)
    setMultipleActionsList(newMultipleActionsList.filter((value, index, array) => array.indexOf(value) === index))
    clearFields()
  }

  const removeActionFromList = async (action: MultipleActionsType) => {
    if (action.idToRemove) {
      try {
        await api.object.deleteAction(action.idToRemove)
        updateTable()
        enqueueSnackbar('Ação deletada com sucesso', {
          variant: 'success',
        })
        if (action.triggerId) {
          await api.brainBox.deleteTrigger(action.triggerId)
        }
        updateTable()
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao deletar ação')
      }
    }
    const newMultipleActionsList = multipleActionsList.filter((it) => !(it.outputId === action.outputId && it.listId === action.listId))
    const actionListId = action.listId
    removedLinksMatrix.current.set(`${actionListId}-${action.outputId}`, false)
    setMultipleActionsList(newMultipleActionsList.filter((value, index, array) => array.indexOf(value) === index))
  }

  function handleAddObjectWithList(toAdd: MultipleActionsType): PutActions {
    const isBrainBox = toAdd.label === 'Brain Box'
    const isTelegram = toAdd.label === 'Telegram'
    const isSpiaOrHarpia = toAdd.outputId <= -2
    let action = {}
    let toAssign = []
    if (isBrainBox) {
      toAssign.push({
        type: 'trigger',
        outputActionId: toAdd.outputId,
      })
    } else if (isTelegram) {
      toAssign.push({
        type: 'telegram',
        outputActionId: toAdd.telegramChatId,
      })
    } else if (isSpiaOrHarpia) {
      toAssign.push({
        type: 'lpr-integration',
        outputActionId: enumIdSpiaHarpia[String(toAdd.outputId) as keyof typeof enumIdSpiaHarpia],
      })
    } else {
      toAssign.push({
        type: 'output-integration',
        outputActionId: toAdd.outputId,
      })
    }
    if (String(toAdd.listId) === '-1') {
      toAssign.push({
        blockUnknownByClient: true,
      })
    } else if (String(toAdd.listId) === '-2') {
      toAssign.push({
        blockUnknownByClient: false,
      })
    } else {
      toAssign.push({
        listIds: [toAdd.listId!],
        blockUnknownByClient: false,
      })
    }
    return Object.assign(action, ...toAssign) as PutActions
  }

  function handleAddDefaultObject(toAdd: MultipleActionsType): PutActions {
    const isBrainBox = toAdd.label === 'Brain Box'
    return {
      type: isBrainBox ? 'trigger' : 'output-integration',
      outputActionId: toAdd.outputId,
    } as PutActions
  }

  function handleAddTelegramObject(toAdd: MultipleActionsType): PutActions {
    return {
      type: 'telegram',
      outputActionId: toAdd.telegramChatId,
    } as PutActions
  }

  function handleAddTriggerAndOutput(toAdd: MultipleActionsType, needListId: boolean): PutActions {
    if (needListId) return handleAddObjectWithList(toAdd)
    return handleAddDefaultObject(toAdd)
  }

  function handleAddTelegram(toAdd: MultipleActionsType, needListid: boolean) {
    if (needListid) return handleAddObjectWithList(toAdd)
    return handleAddTelegramObject(toAdd)
  }

  const handleSaveObjectAndActions = async () => {
    let triggers: CreateTriggerDTO[] | null = []
    const actions: PutActions[] = []
    const brainBoxWithListActions: PutActions[] = []
    const needListId = enumLists.includes(chosen?.detectionTypeChosen!)
    for (const toAdd of multipleActionsList) {
      if (toAdd.idToRemove) continue
      const isBrainbox = toAdd.label === 'Brain Box'
      const isTelegram = toAdd.label === 'Telegram'
      if (isBrainbox) {
        triggers.push({
          objectId: 1,
          outputDeviceId: toAdd.idToRemove || toAdd.outputId,
          triggerTime: toAdd.brainboxTriggerTime || undefined,
        })
        brainBoxWithListActions.push(handleAddTriggerAndOutput(toAdd, needListId))
      } else if (isTelegram) {
        actions.push(handleAddTelegram(toAdd, needListId))
      } else {
        actions.push(handleAddTriggerAndOutput(toAdd, needListId))
      }
    }
    if (partition !== camera?.configuration.partition) {
      const configuration = {..._.omit(camera?.configuration, ['id', 'interestAreas', 'isOnline']), partition: partition}
      await api.camera.updateCamera(camera!.id, {configuration})
    }
    const responseObject = await finishFunction()
    if (!responseObject) return
    let triggerFromResponse: Trigger[] = []
    if (triggers.length !== 0) {
      try {
        const rawData = await Promise.all(
          triggers.map(async (trigger) => {
            return api.brainBox.createTrigger({...trigger, objectId: responseObject.id})
          }),
        )
        triggerFromResponse.push(...rawData.map((it) => it.data.data))
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao salvar ação com Brain Box')
      }
    }
    if (triggerFromResponse && triggerFromResponse.length !== 0) {
      brainBoxWithListActions.forEach((action) => {
        action.outputActionId = triggerFromResponse.find((trigger) => trigger.outputDeviceId === action.outputActionId)?.id!
      })
    }
    let actionsToSave = actions.filter((it) => Object.keys(it).length !== 0)
    actionsToSave = [...actionsToSave, ...brainBoxWithListActions]
    if (actionsToSave.length === 0) return
    try {
      await api.object.putActions(responseObject.id, actionsToSave)
      enqueueSnackbar('Ações salvas com sucesso', {
        variant: 'success',
      })
      if (clientIntegration === 'noIntegration') openWarningModal()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao salvar ações')
    }
  }

  const disabledButtonAddActions = () => {
    if (selectedServer.label === '') return true
    if (selectedServer.label !== 'Brain Box' && partition) return false
    else if ((selectedServer.label === 'Brain Box' && outputSelectBox.label === '') || brainBoxSelected.label === '') return true
    else if (configTriggerTime && !triggerTime) return true
    return false
  }

  const getFaceLists = async () => {
    try {
      const response = (
        await api.facialRecognition.getList({
          filter: {
            companyIds: [camera?.companyId!],
          },
        })
      ).data
      const filteredLists = response.data.entities.filter((list) => list.clientId === camera?.clientId! || list.clientId === null)
      const list = filteredLists.map((it) => {
        return {
          ...it,
          id: String(it.id),
          label: it.name,
        }
      })

      const faceList = [...list.map((it) => ({id: it.id, label: it.name})), {id: '-1', label: 'Faces desconhecidas'}, {id: '-2', label: 'Todas as faces'}]

      const listSelected = list.filter((it) => +it.id === listSelectedId)[0]
      if (listSelected) {
        setSelectedList(listSelected)
      }

      const newActions = actions.map((it) => {
        const listId = it.listId
        if (!listId) {
          if (enumLists.includes(chosen?.detectionTypeChosen!)) return {...it, listName: 'Todas as faces'}
          return it
        }
        if (listId === -1) return {...it, listName: 'Faces desconhecidas'}
        const label = faceList.find((list) => list.id === String(listId))?.label
        return {...it, listName: label}
      })
      setMultipleActionsList(newActions)
      setFaceLists(response.data.entities)
      setFaceListsItems(faceList)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao carregar listas cadastradas')
      return []
    }
  }

  const getPlateLists = async () => {
    try {
      const response = (
        await api.lpr.getManyLists({
          filter: {
            companyIds: [camera?.companyId!],
          },
        })
      ).data
      const filteredLists = response.data.entities.filter((list) => list.clientId === camera?.clientId! || list.clientId === null)
      const list = filteredLists.map((it) => {
        return {
          ...it,
          id: String(it.id),
          label: it.name,
        }
      })

      const plateList = [...list.map((it) => ({id: it.id, label: it.name})), {id: '-1', label: 'Placas desconhecidas'}, {id: '-2', label: 'Todas as placas'}]

      const listSelected = list.filter((it) => +it.id === listSelectedId)[0]
      if (listSelected) {
        setSelectedList(listSelected)
      }
      const newActions = actions.map((it) => {
        const listId = it.listId
        if (!listId) {
          if (enumLists.includes(chosen?.detectionTypeChosen!)) return {...it, listName: 'Todas as placas'}
          return it
        }
        if (listId === -1) return {...it, listName: 'Placas desconhecidas'}
        const label = plateList.find((list) => list.id === String(listId))?.label
        return {...it, listName: label}
      })
      setMultipleActionsList(newActions)
      setPlatesListsItems(plateList)
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao carregar listas cadastradas')
      return []
    }
  }

  const handleGoBackFunction = () => {
    const detectionChosen = chosen?.detectionTypeChosen!
    const skipSecond = skipSecondStep.includes(detectionChosen)
    const skipThird = skipThirdStep.includes(detectionChosen)
    if (skipSecond && skipThird) return returnFunction(2, 0)
    if (skipThird) return returnFunction(2, 1)
    returnFunction(2)
  }

  useEffect(() => {
    if (multipleActionsList.length >= 1) setShowActions(true)
    else setShowActions(false)
  }, [multipleActionsList])

  useEffect(() => {
    const newOutputServers: DropdownItem[] = []
    outputServersItems.forEach((it) => {
      if (removedLinksMatrix.current.has(`${undefined}-${it.id}`)) {
        if (removedLinksMatrix.current.get(`${undefined}-${it.id}`) === false) {
          newOutputServers.push(it)
        }
      } else {
        newOutputServers.push(it)
      }
    })
    setOutputServers(newOutputServers)
  }, [selectedList, removedLinksMatrix.current, multipleActionsList])

  function getButtonLabel() {
    if (!skipThirdStep.includes(chosen?.detectionTypeChosen!)) return 'Finalizar'
    if (multipleActionsList.length) return 'Finalizar'
    return 'Finalizar sem vincular ação'
  }

  return (
    <Box padding='20px'>
      {hasLoaded && (
        <Box display='flex' direction='row' width='100%' columnGap='20px' height='360px' overflow='auto'>
          <Box width='50%' display='flex' direction='column' rowGap='20px'>
            {showFaceOrPlatesLists.current && (
              <Box width='90%' display='flex' direction='column' rowGap='20px'>
                <Box>
                  <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                    Listas cadastradas
                  </Typography>
                  <Typography variant='p' size='12px' color='#8E8E8E'>
                    Escolha a ação que cada lista deverá executar
                  </Typography>
                </Box>

                <Dropdown
                  height='40px'
                  fontSize='12px'
                  selected={selectedList}
                  placeholder='Listas'
                  items={faceLists.length ? faceListsItems : platesListsItems}
                  onChangeSelected={(e) => {
                    setSelectedList(e)
                  }}></Dropdown>
              </Box>
            )}
            {(!showFaceOrPlatesLists.current || selectedList.id !== '') && (
              <Box width='90%' display='flex' direction='column' rowGap='10px'>
                <Box>
                  <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                    Despachar evento
                  </Typography>
                  <Typography variant='p' size='12px' color='#8E8E8E'>
                    Selecione a forma que o evento será despachado
                  </Typography>
                </Box>

                <Dropdown
                  marginTop='10px'
                  height='40px'
                  fontSize='12px'
                  selected={selectedServer}
                  placeholder='Selecione'
                  items={outputServers}
                  onChangeSelected={(e) => {
                    setSelectedServer(e)
                  }}></Dropdown>

                {selectedServer && handleNextInputComponent()}

                <Button
                  fontSize='12px'
                  height='40px'
                  width='200px'
                  variant='outlined'
                  onClick={() => handleAddMultipleActions()}
                  disabled={disabledButtonAddActions()}>
                  {showActions ? 'Adicionar nova ação' : 'Adicionar'}
                </Button>
              </Box>
            )}
          </Box>
          <Box width='50%' display='flex' direction='column' marginRight='5px'>
            <Box display='flex' direction='column' rowGap='20px' align='flex-end' justify='flex-start' marginTop='50px' marginBottom='15px' overflow='auto'>
              {showActions && (
                <>
                  {multipleActionsList?.map((it, index) => {
                    return (
                      <Card
                        initialState={true}
                        label={it.label}
                        onClick={() => removeActionFromList(it)}
                        multipleActions={true}
                        inputLabel={it.inputLabel}
                        valueInput={it.valueInput}
                        key={index}
                        brainBox={it.brainBox}
                        listName={it.listName}
                      />
                    )
                  })}
                </>
              )}
            </Box>
          </Box>
        </Box>
      )}

      <StepperModalActionsButtonContainer>
        <Button
          fontSize='12px'
          height='40px'
          width='150px'
          color='#8E8E8E'
          variant='outlined'
          disabled={skipThirdStep.includes(chosen?.detectionTypeChosen!) && isEdit}
          onClick={() => handleGoBackFunction()}>
          Voltar
        </Button>
        <Button
          fontSize='12px'
          height='40px'
          disabledTextColor='#8E8E8E'
          width='200px'
          onClick={() => handleSaveObjectAndActions()}
          disabled={!(skipThirdStep.includes(chosen?.detectionTypeChosen!) || !!multipleActionsList.length)}>
          {getButtonLabel()}
        </Button>
      </StepperModalActionsButtonContainer>
    </Box>
  )
}

export default CreateMultipleActions
