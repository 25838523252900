import {SxMap} from 'src/utilities/createStyleSheet'

const white = '#f4f4f4'
const grey = '#d1d1d1'
const blue1 = '#009EFF'

export const interestAreaSelectStyles: SxMap = {
  listItem: {
    paddingBottom: 1,
  },
  listItemButton: {
    minHeight: 43,
    borderRadius: 2,
    borderColor: grey,
    borderWidth: 1,
    height: '43px',
    borderStyle: 'solid',
    '&.Mui-selected': {
      backgroundColor: blue1,
      height: '43px',
      color: white,
      transition: 'opacity 0.15s ease-in-out',
    },
    '&.Mui-selected:hover': {
      backgroundColor: blue1,
      opacity: 0.8,
    },
  },
  listItemIcon: {
    minWidth: 'inherit',
  },
  editIcon: {
    color: white,
  },
}
