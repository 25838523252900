import {Box} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {ReactElement, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import {useAuth} from 'src/contexts/AuthContext'
import api, {Company, CompanyCredentials} from 'src/services/api'
import {Page} from 'src/types'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useDebounce} from 'usehooks-ts'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import {ButtonsContentType, ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import ConfirmCredentialsDialog from '../settings/pages/settings-credentials/components/ConfirmCredentialsDialog'
import CredentialsDialog from '../settings/pages/settings-credentials/components/CredentialsDialog'
import CompanyGridTable from './components/CompanyGridTable'
import CompanyOptionsMenu from './components/CompanyOptionsMenu'
import CompanyRegisterModal from './components/CompanyRegisterModal'

const filtersTypes = [{id: '1', label: 'Nome da Empresa'}]

function Companies() {
  const hasCreateCompanyPermission = ValidatePermissions('companies:create')
  const initialCompany = {id: 0, name: '', document: ''}
  const [companySearch, setCompanySearch] = useState<string>('')
  const companySearchDebounced = useDebounce(companySearch, 500)
  const [companies, setCompanies] = useState<Company[]>([])
  const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false)
  const [totalElements, setTotalElements] = useState<number>(0)
  const [companiesPage, setCompaniesPage] = useState<Page>({page: 1, pageSize: 20})
  const [open, setOpen] = useState<boolean>(false)
  const [company, setCompany] = useState<Company>(initialCompany)
  const [isCreate, setIsCreate] = useState<boolean>(true)
  const {enqueueSnackbar} = useSnackbar()
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<string | ReactElement>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [optButton, setOptButton] = useState<boolean>(false)
  const [companyIds, setCompanyIds] = useState<number[]>([])
  const [openCredentialsDialog, setOpenCredentialsDialog] = useState<boolean>(false)
  const [openConfirmCredentialsDialog, setOpenConfirmCredentialsDialog] = useState<boolean>(false)
  const [credentials, setCredentials] = useState<CompanyCredentials>({apiUser: '', apiPassword: ''})
  const menuOpen = Boolean(menuEl)
  const quantity = companyIds.length
  const isPlural: string = quantity <= 1 ? '' : 's'
  const navigate = useNavigate()
  const [, setIdCompany] = useState<number>()
  const {refreshToken} = useAuth()

  const [arrayOfButtons, setArrayOfButtons] = useState<ButtonsContentType[]>([])

  function withConfirmationDelete(companyId?: number) {
    if (companyId) {
      setCompanyIds((prev) => [...prev, companyId])
    }
    setMenuEl(null)
    setOpenModal(true)
    setModalContent(`Esta ação resultará na exclusão desta${isPlural} empresa${isPlural} e não poderá ser desfeita.`)
    setModalTitle(`Deletar empresa${isPlural}`)
    setArrayOfButtons([
      {
        label: 'Cancelar',
        onClick: () => setOpenModal(false),
        variant: 'outlined',
        color: '#8E8E8E',
      },
      {
        label: 'Confirmar',
        onClick: () => {
          setOpenModal(false)
          setMenuEl(null)
          deleteCompany(companyId)
        },
        variant: 'contained',
        dangerAction: true,
      },
    ])
    setOpenModal(true)
  }

  function withConfirmationDefinePermissions(idCompany: number) {
    setMenuEl(null)
    setOpenModal(true)
    setModalContent('Recomendamos definir permissões antes de prosseguir.')
    setModalTitle('Definir permissões da empresa')
    setArrayOfButtons([
      {
        label: 'Definir depois',
        onClick: async () => {
          await refreshToken()
          localStorage.removeItem('needsRefresh')
          loadCompanies()
          if (!isCreate) setCompany(initialCompany)
          else setOpen(false)
          setOpenModal(false)
        },
        variant: 'outlined',
        color: '#8E8E8E',
        hoverColor: 'red',
      },
      {
        label: 'Definir permissões',
        onClick: () => {
          if (!isCreate) return setCompany(initialCompany)

          refreshToken().then(() => navigate(`${idCompany}`))
        },
        variant: 'contained',
        color: '#009EFF',
      },
    ])
  }

  async function loadCompanies() {
    setLoadingCompanies(true)
    try {
      const response = await api.company.getMany({
        page: companiesPage.page === 0 ? 1 : companiesPage.page,
        pageSize: companiesPage.pageSize,
        search: {
          name: companySearchDebounced,
        },
        orderBy: {names: 'ASC'},
        includes: ['permissions'],
      })
      setCompanies(response.data.data.entities)
      setTotalElements(response.data.data.totalElements)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar empresas')
    } finally {
      setLoadingCompanies(false)
    }
  }

  async function deleteCompany(companyId?: number) {
    const companies = companyId ? [...companyIds, companyId] : companyIds
    try {
      await api.company.delete(companies)
      enqueueSnackbar('Empresa(s) removida(s) com sucesso', {
        variant: 'success',
      })
      setCompanyIds([])
      loadCompanies()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover a(s) empresa(s)')
    }
  }

  useEffect(() => {
    loadCompanies()
  }, [companySearchDebounced, companiesPage])

  function openEditModal(company: Company) {
    setIsCreate(false)
    setOpen(true)
    setCompany(company)
  }

  function handleOpenConfirmCredentialsDialog(company: Company) {
    setCompany(company)
    setOpenConfirmCredentialsDialog(true)
  }

  useEffect(() => {
    if (companyIds.length > 1) {
      setOptButton(true)
    } else setOptButton(false)
  }, [companyIds])

  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton onClick={() => loadCompanies()} loading={loadingCompanies} />
            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={filtersTypes}
              setSearchSelected={setShowSearchSelected}
              searchSelected={showSearchSelected}
              inputValue={companySearch}
              inputSearch={setCompanySearch}
              widthOnSelect='290px'
            />
          </Box>

          <RenderIfConditionIsMet condition={hasCreateCompanyPermission}>
            <AppAddOrOptionsButton
              text='Adicionar uma empresa'
              showOptions={optButton}
              onClickAdd={() => {
                setIsCreate(true)
                setOpen(true)
              }}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
            />
          </RenderIfConditionIsMet>
          <CompanyOptionsMenu open={menuOpen} anchorEl={menuEl} onClose={() => setMenuEl(null)} onDelete={() => withConfirmationDelete()} />
        </Box>
      </TopBar>
      <CompanyGridTable
        companies={companies}
        loadingCompanies={loadingCompanies}
        totalElements={totalElements}
        setCompaniesPage={setCompaniesPage}
        companiesPage={companiesPage}
        openEditModal={openEditModal}
        selectedCompaniesIds={companyIds}
        setSelectedCompaniesIds={setCompanyIds}
        openConfirmCredentialsDialog={handleOpenConfirmCredentialsDialog}
        deleteCompany={(id) => withConfirmationDelete(id)}
      />
      <ConfirmationModal
        title={modalTitle}
        content={modalContent}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        buttonsContent={arrayOfButtons}
      />
      {open && (
        <CompanyRegisterModal
          isCreate={isCreate}
          openModal={open}
          closeModal={() => {
            if (!isCreate) setCompany(initialCompany)
            setOpen(false)
          }}
          company={company}
          setCompany={setCompany}
          loadCompanies={loadCompanies}
          setIdCompany={(id) => {
            setIdCompany(id)
          }}
          setSaveModalCompany={(id) => withConfirmationDefinePermissions(id)}
        />
      )}

      {openConfirmCredentialsDialog && (
        <ConfirmCredentialsDialog
          open={openConfirmCredentialsDialog}
          setOpen={setOpenConfirmCredentialsDialog}
          setOpenCredentialsDialog={setOpenCredentialsDialog}
          setCredentials={setCredentials}
          company={company}
        />
      )}
      {openCredentialsDialog && (
        <CredentialsDialog
          open={openCredentialsDialog}
          setOpen={setOpenCredentialsDialog}
          credentials={credentials}
          onClickConfirm={() => {
            setOpenCredentialsDialog(false)
            loadCompanies()
          }}
        />
      )}
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default Companies
