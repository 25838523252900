import {Badge, BadgeProps, Divider, styled, Tooltip} from '@mui/material'
import moment from 'moment'
import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useCompany} from 'src/contexts/CompanyContext'
import api, {ReleasesNotes, UserViews} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import badgeReleases from 'src/images/badgeReleases.svg'
import iconNotifications from 'src/images/iconNotifications.svg'
import {Box, Button, Menu, Typography} from '@viptech/react-components'
import {useApp} from '../../contexts/AppContext'
import {BoxHeaderWrapper} from '../HeaderBase'

const StyledBadgeNotes = styled(Badge)<BadgeProps>(({theme}) => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'red',
    fontWeight: 'bold',
    fontSize: '9px',
    color: '#ffffff',
    left: 0,
    minWeight: 0,
    minHeight: 0,
    border: '2px solid white',
    position: 'absolute',
    borderRadius: '50%',
  },
}))

export function NotificationsComponent() {
  const {selectedCompanies} = useCompany()
  const hasReadNotificationsPermission = ValidatePermissions('notifications:read')
  const [anchorElNotifications, setAnchorElNotifications] = useState<null | HTMLElement>(null)
  const [releasesNotes, setReleasesNotes] = useState<ReleasesNotes[]>([])
  const [wasViewed, setWasViewed] = useState<UserViews | null>(null)

  const location = useLocation()
  const pathname = location.pathname.replace('/', '')
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const {updateNotifications} = useApp()
  const blockNotificationsRequests = useRef(false)

  useEffect(() => {
    const getNewReleases = async () => {
      try {
        if (blockNotificationsRequests.current) return
        blockNotificationsRequests.current = true
        const response = await api.transaction.getAll({
          page: 1,
          pageSize: 100,
          orderBy: {createdAt: 'DESC'},
          filter: {companyIds: selectedCompanies},
        })
        setReleasesNotes(response.data.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar novas atualizações')
      } finally {
        blockNotificationsRequests.current = false
      }
    }
    if (hasReadNotificationsPermission) {
      getNewReleases()
    }
  }, [wasViewed, anchorElNotifications, updateNotifications])

  const createReleasesViews = async (notification: ReleasesNotes) => {
    try {
      const response = await api.transaction.create(notification.id)
      setWasViewed(response.data.data)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar novas visualizações')
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNotifications(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorElNotifications(null)
  }

  const showIcon = () => {
    const list = releasesNotes.map((it) => it.userViews)
    let isEmpty = false
    const newList = releasesNotes.filter((it) => it.userViews?.length === 0).length

    for (let i = 0; i < list.length; i++) {
      if (list[i]?.length === 0) {
        isEmpty = true
        break
      }
    }
    if (isEmpty)
      return (
        <StyledBadgeNotes badgeContent={newList} color='secondary'>
          <img src={iconNotifications} alt='Notificações' />
        </StyledBadgeNotes>
      )
    else return <img src={iconNotifications} alt='Notificações' />
  }

  return (
    <BoxHeaderWrapper>
      {hasReadNotificationsPermission && (
        <>
          <Tooltip title='Notificações'>
            <Button variant='outlined' color='transparent' hoverColor='transparent' padding='0px' onClick={handleClick}>
              {showIcon()}
            </Button>
          </Tooltip>
          <Menu anchor={anchorElNotifications} onClose={handleClose} borderRadius='8px'>
            <Box width='300px' padding='10px 0'>
              <Box display='flex' justifyContent='space-between'>
                <Box style={styles.boxUpdates}>Notificações</Box>
                {pathname !== 'notifications' && (
                  <Box style={styles.viewAll} onClick={() => navigate('/notifications')}>
                    Ver todas
                  </Box>
                )}
              </Box>
              <Divider />
              {releasesNotes.length !== 0 ? (
                <Box style={styles.boxList}>
                  {releasesNotes.map((note) => (
                    <Box>
                      <Tooltip title='Mais informações' placement='bottom'>
                        <Box
                          style={styles.menuItem}
                          onClick={() => {
                            if (note.userViews?.length === 0) createReleasesViews(note)
                            navigate(`/notifications?id=${note.id}`)
                            handleClose()
                          }}>
                          <Box display='flex'>
                            <Typography variant='p' style={styles.nameTitle}>
                              {note.title}
                            </Typography>
                            {note.userViews?.length === 0 && <img src={badgeReleases} alt='Badge' style={styles.badge} />}
                          </Box>
                          <Box padding='3px 0 0 15px'>{note.subtitle}</Box>
                          <Box style={styles.boxDate}>
                            <Box style={styles.createdAt}>{`Criado ${moment(note.createdAt).startOf('hour').fromNow()}`}</Box>
                            {note.userViews?.length !== 0 && (
                              <Box style={styles.viewAt}>{`Visto ${note.userViews?.flatMap((it) => moment(it.createdAt).fromNow())}`}</Box>
                            )}
                          </Box>
                        </Box>
                      </Tooltip>
                      <Divider variant='middle' />
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box style={styles.notReleases}>Nenhuma atualização</Box>
              )}
            </Box>
          </Menu>
        </>
      )}
    </BoxHeaderWrapper>
  )
}

const styles = createStyleSheet({
  title: {
    fontStyle: 'normal',
    fontSize: '1rem',
    lineHeight: '2rem',
    fontWeight: '500',
    fontFamily: 'Inter',
  },
  boxUpdates: {
    padding: '5px 0 10px 15px',
    color: '#009eff',
    fontSize: '0.8125rem',
    fontWeight: 'bold',
  },
  viewAll: {
    color: '#009eff',
    fontSize: '0.8125rem',
    fontWeight: 500,
    padding: '5px 15px 10px 0',
    cursor: 'pointer',
    textDecoration: 'underline',
    ':hover': {
      color: '#1565c0',
    },
  },
  boxList: {
    overflowY: 'scroll',
    height: 330,
  },
  menuItem: {
    fontSize: '0.75rem',
    cursor: 'pointer',
    flexDirection: 'column',
    margin: '5px 0 5px 0',
    display: 'flex',
    '&:hover': {backgroundColor: '#f5f5f5'},
  },
  nameTitle: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: '#004677',
    padding: '5px 0 0 15px',
  },
  createdAt: {
    fontSize: '0.625rem',
    color: '#999999',
    padding: '5px 0 0 15px',
  },
  viewAt: {
    fontSize: '0.625rem',
    padding: '5px 15px 0 0',
    color: '#999999',
  },
  notReleases: {
    fontSize: '0.75rem',
    justifyContent: 'center',
    height: '50px',
    alignItems: 'center',
    display: 'flex',
  },
  badge: {
    width: '8px',
    height: '8px',
    margin: '8.5px 0px 0px 10px',
  },
  boxDate: {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
  },
})
