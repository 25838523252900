import './services/debug'

import {SnackbarProvider} from 'notistack'
import {AuthProvider} from 'src/contexts/AuthContext'

import {ThemeProvider as MuiThemeProvider} from '@mui/material'
import moment from 'moment'
import 'moment/locale/pt-br'
import {ThemeProvider as StyledThemeProvider} from 'styled-components'

import {StyledTheme, muiTheme} from './StyledTheme'
import SnackbarCloseButton from './components/SnackbarCloseButton'
import RoutesPage from './routes/RoutesPage'
import validateEnv from './utilities/validateEnv'
import {useState} from 'react'

moment.locale('pt-br')
validateEnv()

export default function App() {
  const [isValidEnv, setIsValidEnv] = useState(false)

  if (!isValidEnv) setIsValidEnv(validateEnv())

  return (
    <StyledThemeProvider theme={StyledTheme}>
      <MuiThemeProvider theme={muiTheme}>
        <AuthProvider>
          <SnackbarProvider
            action={(key) => <SnackbarCloseButton key={key} />}
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            preventDuplicate={true}>
            <RoutesPage />
          </SnackbarProvider>
        </AuthProvider>
      </MuiThemeProvider>
    </StyledThemeProvider>
  )
}
