import {Box} from '@viptech/react-components'
import {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'src/contexts/AuthContext'
import ConfirmationImage from 'src/images/confirmationImage.svg'
import {useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {Image, Subtitle, Title} from '../components'
import {ButtonLink, ConfirmationContainer, FormCodeContainer, InputCode} from './ConfirmationPageBase'
import api from 'src/services/api/api'
import {useSnackbar} from 'notistack'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {CreateDealDTO} from 'src/services/api/endpoints/CrmIntegrationEndpoint'

function ConfirmationPage() {
  const {registerData} = useB2CContext()
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const {login} = useAuth()

  const [otpCodeFields, setOtpCodeFields] = useState<string[]>(['', '', '', '', '', ''])
  const codeRefs = useRef<HTMLInputElement[]>([])

  const resendEmail = async () => {
    try {
      await api.auth.sendConfirmationEmail({email: registerData.email, username: registerData.name})
      enqueueSnackbar('E-mail reenviado', {variant: 'success'})
    } catch {
      enqueueSnackbar('Não conseguimos reenviar o e-mail, tente novamente', {variant: 'error'})
    }
  }

  const registerUser = async (code: string) => {
    try {
      const selfRegisterBody = {
        cpf: registerData.document,
        email: registerData.email,
        name: registerData.name,
        password: registerData.password,
        phone: registerData.phone.replace(/\D/g, ''),
        companyName: registerData.companyName || undefined,
        code: code,
      }
      let contactToSendRdStation: CreateDealDTO

      if (registerData.companyName === undefined) {
        await api.company.selfRegister(selfRegisterBody)
        contactToSendRdStation = {
          name: 'Pessoa Física realizou cadastro no Brain - B2C',
          contact: {
            email: registerData.email,
            name: registerData.name,
            phone: registerData.phone,
            title: `CPF: ${registerData.document}`,
          },
        }
        enqueueSnackbar('Cadastro realizado com sucesso', {variant: 'success'})
      } else
        contactToSendRdStation = {
          name: 'Pessoa Jurídica realizou cadastro no Brain - B2C',
          contact: {
            email: registerData.email,
            name: registerData.name,
            phone: registerData.phone,
            title: `CNPJ: ${registerData.document}, Razão Social: ${registerData.companyName}`,
          },
        }

      api.crmIntegration.createCrmDealWithContact(contactToSendRdStation)
      if (registerData.companyName) return navigate('/status?type=company')

      await login(registerData.email, registerData.password, false)
      navigate('/select-plan')
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao realizar cadastro')
    }
  }

  useEffect(() => {
    if (codeRefs.current[0]) codeRefs.current[0].focus()
  }, [])

  const onInput = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {value} = event.target
    if (!value) return

    const updatedFields = [...otpCodeFields]
    updatedFields[index] = value.toUpperCase()
    setOtpCodeFields(updatedFields)

    if (index < 5 && codeRefs.current[index + 1]) codeRefs.current[index + 1].focus()
  }

  const onBackspaceKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault()
    const updatedFields = [...otpCodeFields]
    updatedFields[index] = ''

    if (index > 0 && codeRefs.current[index - 1]) codeRefs.current[index - 1].focus()

    setOtpCodeFields(updatedFields)
  }

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>, index: number) => {
    event.preventDefault()
    const text = event.clipboardData?.getData('text') || ''
    const trimmedText = text.replace(' ', '').trim().toUpperCase()

    if (trimmedText.length > 0) {
      const updatedFields = [...otpCodeFields]
      for (let i = index; i < otpCodeFields.length && i - index < trimmedText.length; i++) {
        updatedFields[i] = trimmedText[i - index]
        if (codeRefs.current[i]) codeRefs.current[i].value = trimmedText[i - index]
      }
      setOtpCodeFields(updatedFields)
      if (codeRefs.current[index + trimmedText.length - 1]) codeRefs.current[index + trimmedText.length - 1].focus()
    }
  }

  const moveLeft = (index: number) => {
    if (index > 0 && codeRefs.current[index - 1]) codeRefs.current[index - 1].focus()
  }

  const moveRight = (index: number) => {
    if (index < 5 && codeRefs.current[index + 1]) codeRefs.current[index + 1].focus()
  }

  const onLeftKeyUp = (index: number) => {
    if (codeRefs.current[index].value) {
      if (index > 0) moveLeft(index)
      else codeRefs.current[index].focus()
    }
  }

  const onRightKeyUp = (index: number) => {
    if (codeRefs.current[index].value) {
      if (index < 5) moveRight(index)
      else codeRefs.current[index].focus()
    }
  }

  useEffect(() => {
    const code = otpCodeFields.join('').trim().replace(' ', '').toUpperCase()
    if (code.length === otpCodeFields.length) registerUser(code)
  }, [otpCodeFields])

  return (
    <ConfirmationContainer>
      <Image src={ConfirmationImage} alt='Illustration' />

      <FormCodeContainer>
        <Box display='flex' direction='column' rowGap='16px'>
          <Title>
            Obrigado por se cadastrar! <br /> Confirme seu e-mail para continuar
          </Title>
          <Subtitle>Enviamos um e-mail para você com um código de verificação.</Subtitle>
        </Box>

        <Subtitle bold>Insira o código abaixo para confirmar seu endereço de email e concluir o seu processo de cadastro.</Subtitle>

        <Box display='flex' direction='column' rowGap='20px' align='center' width='100%'>
          <Box display='flex' align='center' justifyContent='center' columnGap='15px' width='100%'>
            {otpCodeFields.map((codeField, index) => (
              <Box key={`code-container-${index}`} display='flex' align='center' justify='center' justifyContent='center' height='70px'>
                <InputCode
                  id={`code-${index}`}
                  ref={(el) => (codeRefs.current[index] = el as HTMLInputElement)}
                  value={codeField.toUpperCase()}
                  onKeyUp={(e) => {
                    if (e.key === 'Backspace') onBackspaceKeyDown(e, index)
                    else if (e.key === 'ArrowLeft') onLeftKeyUp(index)
                    else if (e.key === 'ArrowRight') onRightKeyUp(index)
                  }}
                  onPaste={(e) => onPaste(e, index)}
                  onChange={(e) => onInput(e, index)}
                  type='text'
                  maxLength={1}
                />
              </Box>
            ))}
          </Box>

          <Box display='flex' direction='column' width='100%' rowGap='5px' marginTop='20px'>
            <p>Não conseguiu encontrar o código? Verifique sua pasta de spam.</p>
            <ButtonLink onClick={() => resendEmail()} style={{cursor: 'pointer'}}>
              <Box display='flex' alignSelf='flex-start'>
                Gerar um novo código
              </Box>
            </ButtonLink>

            <ButtonLink onClick={() => navigate(-1)} style={{cursor: 'pointer'}}>
              Informar um novo e-mail para envio
            </ButtonLink>
          </Box>
        </Box>
      </FormCodeContainer>
    </ConfirmationContainer>
  )
}

export default ConfirmationPage
