import {Button} from '@mui/material'
import {useEffect, useState} from 'react'

type CountdownButtonProps = {
  onClick: () => void
  seconds: number
  disabled?: boolean
  children?: React.ReactNode
  sx?: any
  variant?: 'text' | 'outlined' | 'contained'
}

function CountdownButton(props: CountdownButtonProps) {
  const {onClick, disabled = false, seconds, children, sx, variant} = props
  const [countdown, setCountdown] = useState(seconds)
  const [isCounting, setIsCounting] = useState(true)

  useEffect(() => {
    if (isCounting) {
      const interval = setInterval(() => {
        setCountdown((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [isCounting])

  useEffect(() => {
    if (countdown === 0) {
      setIsCounting(false)
    }
  }, [countdown])

  useEffect(() => {
    if (disabled) {
      setCountdown(seconds)
      setIsCounting(false)
    }
  }, [disabled])

  return (
    <Button
      variant={variant}
      sx={sx}
      onClick={() => {
        onClick()
        setCountdown(seconds)
        setIsCounting(true)
      }}
      disabled={disabled || isCounting}>
      {isCounting ? countdown : children}
    </Button>
  )
}

export default CountdownButton
