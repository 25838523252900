import styled from 'styled-components'
import {BubbleProps} from './SliderProps'

export const SliderContainer = styled.div<{width?: string}>`
  width: ${({width}) => width ?? '100%'};
  height: 20px;
  position: relative;
  padding: 15px 0;
`

export const SliderInput = styled.input<{background?: string; thumbColor?: string}>`
  width: 100%;
  height: 10px;
  min-width: 168px;
  border-radius: 10px;
  cursor: pointer;
  padding: '10px 0';
  -webkit-appearance: none;
  &::-webkit-slider-runnable-track {
    border-radius: 10px;
    height: 100%;
    width: 98%;
    border: 0px transparent;
    background: ${(props) => props.background ?? '#258BE3'};
  }
  &::-webkit-slider-thumb {
    height: 27px;
    width: 27px;
    appearance: none;
    background: ${(props) => props.thumbColor ?? '#258BE3'};
    margin-top: -9px;
    border-radius: 100%;
    border: 3px solid white;
  }
  &::-moz-range-track {
    border-radius: 10px;
    height: 100%;
    width: 98%;
    border: 0px transparent;
    background: ${(props) => props.background ?? '#258BE3'};
  }
  &::-moz-range-thumb {
    height: 25px;
    width: 25px;
    appearance: none;
    background: #258be3;
    margin-top: -12px;
    border-radius: 100%;
    border: 3px solid #fff;
  }
`

export const SliderBubble = styled.div<BubbleProps>`
  ${({showBubble}) => (showBubble ? '' : 'display: none;')}
  position: absolute;
  top: -65%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  background-color: #fff;
  border: 2px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  transition: all 0.03s;
`

export const SliderBubbleText = styled.span`
  transform: rotate(45deg);
`
