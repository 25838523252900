import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {Buffer} from 'buffer'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {BtnClose} from 'src/components/BtnClose'
import {useCompany} from 'src/contexts/CompanyContext'
import CompaniesSelect from 'src/layouts/main-layout/header/components/CompaniesSelect'
import api, {Company, DispatchTestEventResponse, EnumServer, Server} from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isValidHostAddress} from 'src/utilities/regexValidation'

export type ServerRegisterModalProps = {
  isCreate: boolean
  openModal: boolean
  closeModal: () => void
  server: Server
  setServer: (server: Server) => void
  loadServers: Function
}

export type AuthServer = {
  user: string | undefined
  password: string | undefined
}

type ServerT = {
  id: number
  name: string
}

const ServersTypeInput: Record<string, ServerT> = {
  Digifort: {id: 2, name: 'Digifort'},
  'D-Guard': {id: 4, name: 'D-Guard'},
}
const ServersTypeOutput: Record<string, ServerT> = {
  'Sigma-Cloud': {id: 1, name: 'Sigma-Cloud'},
  Moni: {id: 3, name: 'Moni'},
  'One Portaria': {id: 6, name: 'One Portaria'},
}

function ServerRegisterModal(props: ServerRegisterModalProps) {
  const {isCreate, openModal, closeModal, server, setServer, loadServers} = props
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [basicAuth, setBasicAuth] = useState<AuthServer>({user: undefined, password: undefined})
  const [sigmaToken, setSigmaToken] = useState<string>('')
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [protocol, setProtocol] = useState<string>(isCreate ? 'http' : '')
  const [host, setHost] = useState<string>('')
  const [port, setPort] = useState<string>('')
  const [currentServerType, setCurrentServerType] = useState<number | undefined>(0)
  const [testLoading, setTestLoading] = useState<boolean>(false)
  const {selectedCompanies} = useCompany()
  const [selectedCompanyId, setSelectedCompanyId] = useState<number[]>([selectedCompanies[0]])
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])

  const handleClickShowPassword = () => {
    setShowPassword((previous) => !previous)
  }
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  function handleTestEventResult({message, status}: DispatchTestEventResponse) {
    enqueueSnackbar(message, {variant: status === 200 ? 'success' : 'warning'})
  }

  async function testConnection() {
    setTestLoading(true)
    try {
      const response = await api.server.testIntegration({
        ip: server.ip ? server.ip : 'api.segware.com.br',
        auth: handleAuth(),
        serverType: server.serverType?.id!,
        id: server.id === 0 ? undefined : server.id,
      })
      handleTestEventResult(response.data.data)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Não foi possível realizar a tentativa de conexão', 'error')
    } finally {
      setTestLoading(false)
    }
  }

  useEffect(() => {
    const inputPort = port ? `:${port}` : ''
    setServer({...server, ip: `${protocol}://${host}${inputPort}`})
  }, [protocol, host, port])

  useEffect(() => {
    const handleEdit = () => {
      setCurrentServerType(server.serverType?.id)
      const linkSeparated = server.ip.split(':')

      if (linkSeparated.length >= 2) {
        linkSeparated.shift()
        const linkIp = linkSeparated.slice(0, 1).join(':')
        const linkProtocol = server.ip.split('://')[0]
        const newLinkIp = linkIp.split('//')[1]
        const linkPort = linkSeparated.slice(1).join(':')
        setProtocol(linkProtocol)
        setHost(newLinkIp)
        setPort(linkPort)
      }
    }
    handleEdit()
  }, [isCreate])

  const {enqueueSnackbar} = useSnackbar()

  const handleAuth = () => {
    const {user, password} = basicAuth
    if (user && password && server.serverType?.id !== EnumServer.SIGMA_CLOUD) {
      if (server.serverType?.id === EnumServer.D_GUARD) return `${user}:${password}`
      else return base64converter(`${user}:${password}`)
    } else if (sigmaToken && server.serverType?.id === EnumServer.SIGMA_CLOUD) return sigmaToken
    else return undefined
  }

  async function createServer() {
    setIsLoading(true)
    try {
      await api.server.create({name: server.name, ip: server.ip, serverTypeId: server.serverType?.id!, auth: handleAuth()!, companyId: selectedCompanyId[0]})
      enqueueSnackbar('Servidor de integração criado com sucesso', {variant: 'success'})
      loadServers()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao criar servidor de integração')
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  async function updateServer() {
    setIsLoading(true)
    try {
      await api.server.update({id: server.id, name: server.name, ip: server.ip, serverTypeId: server.serverType?.id, auth: handleAuth()})
      enqueueSnackbar('Servidor de integração atualizado com sucesso', {variant: 'success'})
      loadServers()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao atualizar servidor de integração')
    } finally {
      setIsLoading(false)
      closeModal()
    }
  }

  const handleDisableConfirmButton = () => {
    if (server.name === '' || !isValidHostAddress(host) || !server.serverType?.id) return true
    const hasAuth = server.serverType.id !== EnumServer.SIGMA_CLOUD ? basicAuth.password && basicAuth.user : sigmaToken.trim()
    if (isCreate && !hasAuth) return true
    else if (!isCreate && ((!basicAuth.password && basicAuth.user) || (basicAuth.password && !basicAuth.user))) return true
    else if (currentServerType !== server.serverType.id && !hasAuth) return true
    return false
  }

  function base64converter(string: string) {
    const encodedString = Buffer.from(string).toString('base64')
    return encodedString
  }
  const urlFull = window.location.href
  const typeServerList = urlFull.includes('inputs') ? ServersTypeInput : ServersTypeOutput

  useEffect(() => {
    if (isCreate) setServer({...server, name: '', ip: '', serverType: {id: 0}})
  }, [])

  return (
    <Dialog open={openModal} onClose={closeModal} scroll='paper' maxWidth='sm' fullWidth>
      <DialogTitle sx={styles.dialogTitle}>{isCreate ? 'Adicionar' : 'Atualizar'} Servidor</DialogTitle>
      <BtnClose onClose={closeModal} />
      <DialogContent>
        <FormControl sx={styles.form} fullWidth>
          <TextField
            label='Nome'
            variant='outlined'
            type={'text'}
            value={server.name}
            autoFocus={true}
            onChange={(e) => setServer({...server, name: e.target.value.substring(0, 99)})}
            sx={styles.input}
          />
          <Box sx={{width: '100%', paddingBottom: '10px'}}>
            <CompaniesSelect
              selectLabel='Empresa'
              selectSize='medium'
              setSelectedCompaniesIds={setSelectedCompanyId}
              companiesScroll={companiesScroll}
              setCompaniesScroll={setCompaniesScroll}
              pageCount={pageCount}
              setPageCount={setPageCount}
              companyNames={companyNames}
              setCompanyNames={setCompanyNames}
              multiple={false}
            />
          </Box>
          <FormControl sx={{marginTop: 1.3}}>
            <InputLabel id='demo-simple-select-label'>Selecione o tipo de Integração</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={!server.serverType?.id ? '' : server.serverType?.id}
              label='Selecione o tipo de Integração'
              onChange={(e) => {
                if (Number(e.target.value) === EnumServer.SIGMA_CLOUD) {
                  setProtocol('https')
                  setPort('')
                  setHost('api.segware.com.br')
                } else if (server.serverType?.id === EnumServer.SIGMA_CLOUD) setHost('')
                setServer({...server, serverType: {id: Number(e.target.value)}})
              }}
              sx={styles.input}
              disabled={!isCreate}>
              {Object.keys(typeServerList).map((key) => {
                const typeServer = typeServerList[key]
                return (
                  <MenuItem key={typeServer.id} value={typeServer.id}>
                    {key}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>

          <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
            <FormControl fullWidth sx={styles.inputProtocol}>
              <InputLabel id='demo-simple-select-label'>Protocolo</InputLabel>
              <Select
                label='Protocolo'
                value={protocol}
                disabled={server.serverType?.id === EnumServer.SIGMA_CLOUD}
                onChange={(e) => setProtocol(e.target.value)}>
                <MenuItem key={'http'} value={'http'}>
                  HTTP
                </MenuItem>
                <MenuItem key={'https'} value={'https'}>
                  HTTPS
                </MenuItem>
              </Select>
            </FormControl>
            <TextField
              label='Host'
              variant='outlined'
              placeholder='000.000.000.000'
              type={'text'}
              value={host}
              disabled={server.serverType?.id === EnumServer.SIGMA_CLOUD}
              sx={styles.inputIp}
              onChange={(e) => setHost(e.target.value)}
            />
            <TextField
              label='Porta'
              variant='outlined'
              placeholder='0000'
              type={'text'}
              value={port}
              disabled={server.serverType?.id === EnumServer.SIGMA_CLOUD}
              sx={styles.inputPort}
              onChange={(e) => setPort(e.target.value.replace(/[^0-9]+/, '').substring(0, 5))}
            />
          </Box>
        </FormControl>

        <Box>
          {[EnumServer.DIGIFORT, EnumServer.MONI, EnumServer.D_GUARD, EnumServer.ONE_PORTARIA].find((it) => it === server.serverType?.id) ? (
            <Box>
              <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                <Typography sx={{marginTop: 0.1, marginBottom: 0.5, fontSize: '1.2rem'}}>Autenticação</Typography>
                {server.serverType?.id !== EnumServer.ONE_PORTARIA && (
                  <LoadingButton
                    variant='contained'
                    sx={styles.informationButton}
                    loading={testLoading}
                    onClick={() => {
                      if (server.serverType?.id === EnumServer.MONI) window.open(`/tutorials/moni`)
                      else if (server.serverType?.id === EnumServer.D_GUARD) window.open(`/tutorials/dguard`)
                      else if (server.serverType?.id === EnumServer.DIGIFORT) window.open(`/tutorials/digifort`)
                    }}>
                    Como integrar
                  </LoadingButton>
                )}
              </Box>
              <FormControl sx={styles.form} fullWidth>
                <TextField
                  label='Usuário'
                  variant='outlined'
                  type={'text'}
                  value={basicAuth.user}
                  autoFocus={true}
                  autoComplete='new-password'
                  onChange={(e) => setBasicAuth({...basicAuth, user: e.target.value.substring(0, 99)})}
                  sx={styles.input}
                />
                <FormControl>
                  <InputLabel htmlFor='outlined-adorment-password'>Senha</InputLabel>
                  <OutlinedInput
                    sx={styles.input}
                    type={showPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    value={basicAuth.password}
                    autoFocus={true}
                    label='Senha'
                    onChange={(e) => setBasicAuth({...basicAuth, password: e.target.value.substring(0, 99)})}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </FormControl>
            </Box>
          ) : server.serverType?.id ? (
            <Box>
              <FormControl sx={{paddingTop: 0.3}} fullWidth>
                <TextField
                  label='Token'
                  variant='outlined'
                  type={'text'}
                  value={sigmaToken}
                  sx={styles.input}
                  onChange={(e) => setSigmaToken(e.target.value)}
                />
              </FormControl>
            </Box>
          ) : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{marginBottom: 2, display: 'flex', justifyContent: 'space-between', width: '100%'}}>
          <LoadingButton variant='contained' loading={testLoading} onClick={() => testConnection()} sx={styles.testButton}>
            Testar Integração
          </LoadingButton>
          <Box>
            <Button
              onClick={() => {
                closeModal()
              }}
              sx={styles.cancelButton}>
              Cancelar
            </Button>
            <LoadingButton
              variant='contained'
              sx={styles.confirmButton}
              onClick={() => {
                if (isCreate) createServer()
                else updateServer()
                closeModal()
              }}
              loading={isLoading}
              disabled={handleDisableConfirmButton()}>
              Salvar
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const styles = createStyleSheet({
  container: {
    paddingTop: 2,
    paddingBottom: 2,
  },
  title: {
    fontSize: '1.25rem',
    lineHeight: '0.4rem',
  },
  form: {
    paddingTop: 1,
  },
  input: {
    marginBottom: '20px',
  },
  inputIp: {
    marginBottom: '20px',
    width: '59%',
  },
  inputPort: {
    marginBottom: '20px',
    width: '16%',
  },
  inputProtocol: {
    marginBottom: '20px',
    width: '18%',
  },
  confirmButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    margin: '0 17px 0 10px',
  },
  dialogTitle: {paddingTop: 3, color: '#353535'},
  informationButton: {
    width: '150px',
    height: '35px',
    marginRight: 0,
    marginBottom: 0,
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  cancelButton: {
    width: '150px',
    height: '40px',
    marginRight: 1.3,
    marginBottom: 0,
    border: '1px solid',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
  },
  testButton: {
    width: '150px',
    height: '40px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginLeft: 1.9,
    marginBottom: 0,
    fontSize: '12.5px',
    border: '1px solid',
    '&:hover': {
      boxShadow: '#00000033 0px 2px 4px -1px',
    },
  },
  contentHeader: {
    display: 'flex',
    flexDirection: 'row',
    height: 1,
    justifyContent: 'space-between',
  },
  closeButton: {
    p: 1,
    minWidth: 0,
    height: 1,
    fontWeight: 'bold',
  },
})

export default ServerRegisterModal
