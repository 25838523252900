import {Badge, styled, Tooltip, Typography} from '@mui/material'
import {Box} from '@viptech/react-components'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {useAlarmsContext} from '../contexts/AlarmsContext'

import {useSnackbar} from 'notistack'
import Button from 'src/components/Button/Button'
import RowHover from 'src/components/RowHover/RowHover'
import AlarmsModal from './alarms-modal/AlarmsModal'
import {useAuth} from 'src/contexts/AuthContext'
import {useQueryParams} from 'src/hooks/useQuery'
import {useEffect, useState} from 'react'
import Table from 'src/components/table/Table'
import TableData from 'src/components/table/components/tableData/TableData'
import {useWindowSize} from 'usehooks-ts'
import {ContainerTable} from './alarms-modal/AlarmsModalBase'

const StyledBadge = styled(Badge)(({theme}) => ({
  '& .MuiBadge-badge': {
    color: 'red',
    '&::after': {
      position: 'absolute',
      top: 2,
      left: 2,
      width: '5px',
      height: '5px',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '6px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

function AlarmsGridTable() {
  const {
    reloadingAlarms,
    loadingAlarms,
    alarmsPage,
    openAlarmModal,
    reloadAlarms,
    preventGetEvents,
    canLoadMore,
    getSnapshotsFromEvent,
    isAlarmModalOpen,
    rows,
  } = useAlarmsContext()
  const {userIsB2c} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const query = useQueryParams()
  const queryId = query.get('id')

  const {width} = useWindowSize()
  const [hideTableIfModalIsOpen, setHideTableIfModalIsOpen] = useState(false)

  useEffect(() => {
    if (width >= 767) setHideTableIfModalIsOpen(false)

    if (width <= 767 && isAlarmModalOpen) setHideTableIfModalIsOpen(true)
    else setHideTableIfModalIsOpen(false)
  }, [width, isAlarmModalOpen])

  useEffect(() => {
    if (queryId) getSnapshotsFromEvent(+queryId)
  }, [queryId])

  function handleLoadMore() {
    if (preventGetEvents) return enqueueSnackbar('Todos os eventos já foram carregados', {variant: 'info'})
    reloadAlarms(true)
  }

  const columns = userIsB2c
    ? [
        {
          id: 'id',
          name: 'ID',
        },
        {
          id: 'name',
          name: 'Nome da Câmera',
        },

        {
          id: 'objectTypeLabel',
          name: 'Tipo de Detecção',
        },
        {
          id: 'status',
          name: 'Resultado',
        },
        {
          id: 'createdAt',
          name: 'Data e hora',
        },
        {
          id: 'timeUpdate',
          name: 'Atualizado há',
        },
        {
          id: 'trigger',
          name: '',
        },
      ]
    : [
        {
          id: 'id',
          name: 'ID',
        },
        {
          id: 'name',
          name: 'Nome da Câmera',
        },
        {
          id: 'company',
          name: 'Empresa',
        },
        {
          id: 'objectTypeLabel',
          name: 'Tipo de Detecção',
        },
        {
          id: 'status',
          name: 'Resultado',
        },
        {
          id: 'createdAt',
          name: 'Data e hora',
        },
        {
          id: 'timeUpdate',
          name: 'Atualizado há',
        },
        {
          id: 'trigger',
          name: '',
        },
      ]

  return (
    <Box height='100%' padding='0 20px'>
      <ContainerTable isOpen={isAlarmModalOpen}>
        {!hideTableIfModalIsOpen && (
          <Table isLoading={loadingAlarms} skeletonRowsAmount={alarmsPage.pageSize} isOpenAdvancedSearch={false} columns={columns}>
            {rows.map((row) => {
              return (
                <RowHover id={`row-${row.id}`} key={`row-id-${row.id}`} onClick={() => openAlarmModal(row.self)}>
                  <TableData width='100px'>{row.id}</TableData>
                  <TableData width='500px'>
                    <Typography sx={styles.links}>{row.name}</Typography>
                  </TableData>
                  {!userIsB2c && <TableData width='400px'>{row.company}</TableData>}
                  <TableData width='100px'>{row.objectTypeLabel || '-'}</TableData>
                  <TableData>{row.status}</TableData>
                  <TableData width='200px'>{row.createdAt}</TableData>
                  <TableData width='80x'>{row.timeUpdate}</TableData>
                  <TableData width='30x'>
                    {row.trigger && (
                      <Tooltip title='Evento com Disparo' placement='bottom'>
                        <StyledBadge overlap='circular' anchorOrigin={{horizontal: 'left', vertical: 'top'}} variant='dot'>
                          <Box style={{width: '10px', height: '10px', background: 'red', borderRadius: 5}}></Box>
                        </StyledBadge>
                      </Tooltip>
                    )}
                  </TableData>
                </RowHover>
              )
            })}
          </Table>
        )}
        {!loadingAlarms && rows.length === 0 && <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>}

        {isAlarmModalOpen && <AlarmsModal />}
      </ContainerTable>

      {!loadingAlarms && rows.length > 0 && (
        <Box style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '6px'}}>
          <Button onClick={() => handleLoadMore()} isLoading={reloadingAlarms} disabled={!canLoadMore} />
        </Box>
      )}
    </Box>
  )
}
const styles = createStyleSheet({
  links: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    marginRight: 1,
    cursor: 'pointer',
    fontWeight: '0',
    '&:hover': {
      textDecoration: 'underline',
      color: '#00639f',
    },
  },
  noRegisters: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    padding: '50px 0px',

    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
})

export default AlarmsGridTable
