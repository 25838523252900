import {Box, Button, Checkbox, Input, Modal, Typography} from '@viptech/react-components'
import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {BtnClose} from 'src/components/BtnClose'
import api from 'src/services/api'
import {BrainBoxInput, BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

export type TriggerOutputDevicesProps = {
  input: BrainBoxInput
  outputs: BrainBoxOutput[]
  onClose: () => void
}

type BrainBoxOutputConfig = {
  channel: number
  isActive: boolean
  triggerTime: number
  dontStop: boolean
}

const initialOutputConfig = () => {
  const output = []
  for (let i = 1; i <= 4; i++) {
    output.push({
      channel: i,
      isActive: false,
      triggerTime: 1,
      dontStop: true,
    })
  }
  return output
}

function BrainBoxTriggerOutputDevices({input, onClose, outputs}: TriggerOutputDevicesProps) {
  const {enqueueSnackbar} = useSnackbar()
  const [outputConfigs, setOutputConfigs] = useState<BrainBoxOutputConfig[]>(initialOutputConfig())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const sortedConfigs = outputConfigs.sort((a, b) => a.channel - b.channel)
  const sortedOutputs = outputs.sort((a, b) => a.channel - b.channel)
  const setThisOutputConfig = (outputConfigs: BrainBoxOutputConfig[], index: number, key: string, value: any) => {
    const newOutputConfigs = [...outputConfigs]
    newOutputConfigs[index][key as keyof BrainBoxOutputConfig] = value as never
    setOutputConfigs(newOutputConfigs)
  }

  function handleSave() {
    async function saveActions() {
      try {
        setIsLoading(true)
        const filteredActions = outputConfigs.filter((it) => it.isActive)
        const actions = filteredActions.map((diff) => {
          const triggerTime = diff.dontStop ? null : diff.triggerTime
          return {
            triggerTime: triggerTime || undefined,
            outputDeviceChannel: diff.channel,
          }
        })
        const req = {
          inputDeviceId: input.id,
          inputDeviceActions: actions,
        }
        await api.brainBoxOutput.upsertActions(req)
        onClose()
      } catch (err) {
        const error = err as AxiosError
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao salvar ações')
      } finally {
        setIsLoading(false)
      }
    }
    saveActions()
  }

  function displayMessage() {
    const actives = outputConfigs.filter((it) => it.isActive)
    const disabledOutputs = outputs.filter((it) => !it.isActive)
    if (actives.length === 0 || disabledOutputs.length === 0) return ''
    const activeDisabledChannels: number[] = []
    actives.forEach((active) => {
      disabledOutputs.forEach((disabled) => {
        if (active.channel === Number(disabled.channel)) activeDisabledChannels.push(active.channel)
      })
    })
    if (activeDisabledChannels.length === 0) return ''
    if (activeDisabledChannels.length === 1)
      return `O dispositivo de saída com canal ${activeDisabledChannels[0]} está desabilitado. Ative-o para que ocorra o correto acionamento do dispositivo`
    return `Os dispositivos de saída com canais ${activeDisabledChannels.join(
      ', ',
    )} estão desabilitados. Ative-os para que ocorra o correto acionamento do dispositivo`
  }

  useEffect(() => {
    async function getActions() {
      try {
        setIsLoading(true)
        const res = await api.brainBoxOutput.getBrainBoxInputDeviceActions(input.id)
        const actions = res.data.data.entities
        actions.forEach((action) => {
          const filteredAction = outputConfigs.filter((it) => it.channel === action.outputDeviceChannel)[0]
          setOutputConfigs((prev) => {
            const newOutputConfigs = [...prev]
            newOutputConfigs[filteredAction.channel - 1] = {
              ...filteredAction,
              isActive: true,
              triggerTime: action.triggerTime,
            }
            return newOutputConfigs
          })
        })
      } catch (err) {
        const error = err as AxiosError
        if (error.response?.status !== 404) {
          handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar ações')
        }
      } finally {
        setIsLoading(false)
      }
    }

    getActions()
  }, [])

  return (
    <Modal isOpen={true} closeModal={() => onClose()} backgroundColor='white' width='760px' height='710px'>
      <Box width='100%' display='flex' justifyContent='space-between' fontSize='24px' fontWeight='500' padding='16px 12px 0px 5px'>
        <Box height={'30px'}>
          <Box>Configurações de acionamento - {input.description}</Box>
          <BtnClose onClose={() => onClose()} />
        </Box>
      </Box>
      <Box display='flex' direction='column' justifyContent='space-between' align='flex-start' height='80%' padding='16px' overflow='hidden' rowGap='16px'>
        <Box color='#8E8E8E' fontSize='14px'>
          Configure por quanto tempo e quais dispositivos de saída serão acionados
        </Box>
        <Box fontWeight='500'>Dispositivos de saída</Box>
        <Box border='1px solid #000' borderRadius='8px' padding='16px'>
          {!isLoading &&
            sortedConfigs.map((output, index) => {
              const triggerTime = String(outputConfigs[index].triggerTime)
              const isChecked = outputConfigs[index].isActive || false
              const description = sortedOutputs[index].description
              return (
                <>
                  <Box display='flex' align='center' justify='space-between' direction='row' rowGap='16px' width='666px'>
                    <Checkbox checked={isChecked} onChange={() => setThisOutputConfig(outputConfigs, index, 'isActive', !isChecked)} />
                    <Box>
                      <Typography variant='span' weight={500} color='#364152' family='Inter' size='14px'>
                        {description}
                      </Typography>
                    </Box>
                    <Box>
                      <Checkbox
                        checked={outputConfigs[index].dontStop}
                        disabled={!isChecked}
                        onChange={() => {
                          setThisOutputConfig(outputConfigs, index, 'dontStop', !outputConfigs[index].dontStop)
                        }}
                      />
                      <Typography variant='span' weight={500} color='#364152' family='Inter' size='14px'>
                        Sem parar
                      </Typography>
                    </Box>
                    <Input.Root
                      label='Tempo de acionamento'
                      width='200px'
                      disabled={!isChecked || outputConfigs[index].dontStop}
                      defaultValue={triggerTime}
                      value={triggerTime}
                      mask={(value) => {
                        const newValue = value.replace(/\D/g, '')
                        if (Number(newValue) > 0) return newValue
                        return ''
                      }}
                      paddingBottom='20px'
                      onChange={(e) => setThisOutputConfig(outputConfigs, index, 'triggerTime', Number(e.target.value))}></Input.Root>
                  </Box>
                </>
              )
            })}
        </Box>
        <Box height='50px'>
          <Typography variant='span'>{displayMessage()}</Typography>
        </Box>
      </Box>
      <Box display='flex' align='flex-end' justifyContent='flex-end' marginRight='10px'>
        <Button variant='outlined' padding='10px 30px' marginTop='15px' marginRight='20px' width='120px' disabledBgColor='#9E9E9E' onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button variant='contained' padding='10px 30px' marginTop='15px' width='120px' disabledBgColor='#9E9E9E' onClick={() => handleSave()}>
          Salvar
        </Button>
      </Box>
    </Modal>
  )
}

export default BrainBoxTriggerOutputDevices
