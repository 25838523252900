import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import LoadingButton from '@mui/lab/LoadingButton'
import {Button, FormControl, IconButton, InputAdornment, InputBase, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'

import {AxiosError} from 'axios'
import {useSnackbar} from 'notistack'
import AppVersion from 'src/components/AppVersion'
import {useAuth} from 'src/contexts/AuthContext'
import useLoading from 'src/hooks/useLoading'
import {InitialRoutesWithPermissions} from 'src/routes/RoutesPageFunctions'
import api from 'src/services/api'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isPasswordLoginValid} from 'src/utilities/regexValidation'
import {useToggle} from 'usehooks-ts'
import ViptechLogo from '../../images/viptech-logo.svg'
import {ButtonDiv, Container, Form, InputDiv, LoginBox, MessageSpan, TopDiv, ViptechLogoDiv} from './Styles'

function DefinePassword() {
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmed, setPasswordConfirmed] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const [isConfirmed, setIsConfirmed] = useState<boolean>(true)
  const [showPassword, setShowPassword] = useToggle(false)
  const [confirmShowPassword, setConfirmShowPassword] = useToggle(false)

  const {user, login, logout} = useAuth()
  const {loading, applyLoading} = useLoading(false)
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()

  const handleChange = (e: any) => {
    setPasswordConfirmed(e.target.value)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  async function UpdatePassword() {
    if (!user?.id) throw new Error('Missing id when editing user')
    try {
      await api.auth.updatePassword({
        newPassword: passwordConfirmed,
      })
      await applyLoading(() => login(user.email, passwordConfirmed, false))
      navigate(`/${InitialRoutesWithPermissions(user).path}`)
    } catch (err) {
      const error = err as AxiosError
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao alterar a senha')
    }
  }

  function stringToColor(string: string) {
    if (!string) return string
    let hash = 0

    for (let i = 0; i < string.length; i++) hash = string.charCodeAt(i) + ((hash << 5) - hash)

    let color = '#'

    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }

    return color
  }

  useEffect(() => {
    if (!password) {
      setIsConfirmed(true)
      setPasswordConfirmed('')
      return
    }

    if (!isPasswordLoginValid(password)) {
      setIsConfirmed(false)
      setMessage('A senha deve conter no mínimo 8 e no máximo 40 caracteres, e deve conter um número e uma letra')
    } else {
      setIsConfirmed(true)
      if (passwordConfirmed !== password) {
        setIsConfirmed(false)
        setMessage('A senha digitada está diferente')
      } else {
        setIsConfirmed(true)
      }
    }
  }, [password, passwordConfirmed])

  return (
    <Container>
      <LoginBox>
        <TopDiv>
          <MessageSpan color={'#ffffff'}>
            Olá <span style={{color: stringToColor(user!.name)}}> {user?.name}</span>,
            <br />
            Digite sua nova Senha<MessageSpan color={'#009EFF'}>.</MessageSpan>
          </MessageSpan>
        </TopDiv>
        <Form>
          <InputDiv>
            <FormControl>
              <InputBase
                type={showPassword ? 'text' : 'password'}
                value={password}
                sx={styles.inputPassword}
                autoFocus={true}
                onKeyPress={(event) => false}
                placeholder={'Nova senha'}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{color: '#FFFFFF'}}
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword()}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </InputDiv>
          <InputDiv>
            <FormControl>
              <InputBase
                type={confirmShowPassword ? 'text' : 'password'}
                value={passwordConfirmed}
                sx={styles.inputPassword}
                autoFocus={true}
                onChange={handleChange}
                placeholder={'Confirme a nova senha'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{color: '#FFFFFF'}}
                      aria-label='toggle password visibility'
                      onClick={() => setConfirmShowPassword()}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'>
                      {confirmShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </InputDiv>
          <InputDiv>
            <Typography variant='h6' hidden={isConfirmed} sx={{color: 'tomato', fontSize: '1em'}}>
              {message}
            </Typography>
          </InputDiv>
          <ButtonDiv>
            <Button onClick={() => logout()} variant='contained' sx={styles.cancelButton}>
              Sair
            </Button>
            <LoadingButton
              onClick={() => UpdatePassword()}
              variant='contained'
              sx={styles.confirmButton}
              disabled={!isConfirmed || !password}
              loading={loading}>
              Salvar
            </LoadingButton>
          </ButtonDiv>
        </Form>
      </LoginBox>
      <ViptechLogoDiv>
        <img src={ViptechLogo} alt={'Logo Viptech'} />
      </ViptechLogoDiv>
      <AppVersion color='white' />
    </Container>
  )
}

const styles = createStyleSheet({
  confirmButton: {
    width: '100%',
    height: '50px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    '&:disabled': {
      background: '#808080',
    },
  },
  inputPassword: {
    fontSize: '16px',
    color: '#ffffff',
    backgroundColor: '#46464f',
    height: '3.5em',
    borderRadius: '10px',
    padding: '0px 15px 0px 15px',
    border: 'none',
    textIndent: '4%',
    outline: '0',
    '&:focus': {
      outline: 'none',
    },
  },
  cancelButton: {
    width: '100%',
    height: '50px',
    fontFamily: 'Inter',
    boxShadow: 'none',
    textTransform: 'none',
    marginRight: '15px',
    marginBottom: '15px',
    background: '#808080',
  },
})

export default DefinePassword
