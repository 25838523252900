import {useSnackbar} from 'notistack'
import {useEffect, useRef, useState} from 'react'
import api from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {BrainBoxInput, BrainBoxOutput} from 'src/services/api/endpoints/BrainBoxOutputEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import BrainBoxConfigGridDeviceOut from './components/BrainBoxConfigDeviceOutGrid'
import BrainBoxRegisterDeviceOut from './components/BrainBoxRegisterDeviceOut'
import BrainBoxTriggerOutputDevices from './components/BrainBoxTriggerOutputDevices'

type BrainBoxOutputDevicesProps = {
  brainBoxOne: BrainBox
}

const BrainBoxOutputDevices = ({brainBoxOne}: BrainBoxOutputDevicesProps) => {
  const initialBrainBoxOut = {
    id: 0,
    brainBoxId: 0,
    description: '',
    channel: 0,
    isActive: false,
    lastTrigger: '',
    lastTriggerEject: '',
    triggerTime: 0,
    actionId: 0,
  }
  const [isRegisterDeviceOutOpen, setIsRegisterDeviceOutOpen] = useState<boolean>(false)
  const [isTriggerOutputDeviceOpen, setIsTriggerOutputDeviceOpen] = useState<boolean>(false)
  const [brainBoxDevicesOutputs, setBrainBoxDevicesOutputs] = useState<BrainBoxOutput[]>([])
  const [brainBoxDevicesInputs, setBrainBoxDevicesInputs] = useState<BrainBoxInput[]>([])
  const [brainBoxOut, setBrainBoxOut] = useState<BrainBoxOutput>(initialBrainBoxOut)
  const [brainboxIn, setBrainBoxIn] = useState<BrainBoxInput>({} as BrainBoxInput)
  const {enqueueSnackbar} = useSnackbar()
  const [loadingList, setLoadingList] = useState<boolean>(false)
  const [isCreate] = useState<boolean>(false)
  const [deviceType, setDeviceType] = useState<'input' | 'output'>('output')
  const [channels, setChannels] = useState<(number | null | undefined)[]>([])
  const blockOutputDevicesRequests = useRef(false)
  const blockInputDevicesRequests = useRef(false)

  useEffect(() => {
    if (brainBoxOne.id === -1) return
    getBrainBoxOutputDevices()
    getBrainBoxInputDevices()
  }, [])

  const getBrainBoxInputDevices = async () => {
    if (blockInputDevicesRequests.current) return
    blockInputDevicesRequests.current = true
    setLoadingList(true)
    try {
      const response = await api.brainBoxOutput.getBrainBoxInputDevices({
        brainBoxId: Number(brainBoxOne.id),
      })
      setBrainBoxDevicesInputs(response.data.data.entities.sort((a, b) => a.channel - b.channel))
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar dispositivos de entrada')
    } finally {
      setLoadingList(false)
      blockInputDevicesRequests.current = false
    }
  }

  const getBrainBoxOutputDevices = async () => {
    if (blockOutputDevicesRequests.current) return
    blockOutputDevicesRequests.current = true
    setLoadingList(true)
    try {
      const response = await api.brainBoxOutput.getAll({
        brainBoxId: Number(brainBoxOne.id),
      })
      setBrainBoxDevicesOutputs(response.data.data.entities.sort((a, b) => a.channel - b.channel))
      setChannels(response.data.data.entities.map((it) => it.channel))
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar dispositivos de saída')
    } finally {
      setLoadingList(false)
      blockOutputDevicesRequests.current = false
    }
  }

  const deleteBrainBoxOutput = async (id: number) => {
    try {
      await api.brainBoxOutput.delete(id)
      enqueueSnackbar('Dispositivo de saída removido com sucesso', {
        variant: 'success',
      })
      getBrainBoxOutputDevices()
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao remover dispositivo de saída')
    }
  }

  const triggerShootOutputDevices = async (id: number) => {
    try {
      await api.brainBoxOutput.forceShootOutputDevices(id)
      enqueueSnackbar('Disparo enviado', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao forçar disparo')
    }
  }

  const ejectShootOutputDevices = async (id: number) => {
    try {
      await api.brainBoxOutput.stopShootOutputDevices(id)
      enqueueSnackbar('Disparo cancelado', {
        variant: 'success',
      })
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao cancelar disparo')
    }
  }

  function openEditModal(brainBox: BrainBoxOutput, deviceType: 'input' | 'output') {
    setIsRegisterDeviceOutOpen(true)
    setDeviceType(deviceType)
    setBrainBoxOut(brainBox)
  }

  function openTriggerOutputDeviceModal(brainBox: BrainBoxInput) {
    setIsTriggerOutputDeviceOpen(true)
    setBrainBoxIn(brainBox)
  }

  return (
    <>
      <BrainBoxConfigGridDeviceOut
        getOutputDevices={getBrainBoxOutputDevices}
        getInputDevices={getBrainBoxInputDevices}
        triggerShoot={triggerShootOutputDevices}
        stopShoot={ejectShootOutputDevices}
        onEdit={openEditModal}
        loadingList={loadingList}
        brainBoxDevicesInputs={brainBoxDevicesInputs}
        brainBoxDevicesOutputs={brainBoxDevicesOutputs}
        onTriggerOutputDevice={openTriggerOutputDeviceModal}
        onDelete={deleteBrainBoxOutput}
      />
      {isRegisterDeviceOutOpen && (
        <BrainBoxRegisterDeviceOut
          channels={channels}
          getOutputDevices={getBrainBoxOutputDevices}
          getInputDevices={getBrainBoxInputDevices}
          isRegisterDeviceOutOpen={isRegisterDeviceOutOpen}
          setIsRegisterDeviceOutOpen={setIsRegisterDeviceOutOpen}
          brainBoxOut={brainBoxOut}
          brainBoxOne={brainBoxOne}
          deviceType={deviceType}
          setBrainBoxOut={setBrainBoxOut}
          handleClose={() => {
            if (isCreate) setBrainBoxOut(initialBrainBoxOut)
            setIsRegisterDeviceOutOpen(false)
          }}
        />
      )}
      {isTriggerOutputDeviceOpen && (
        <BrainBoxTriggerOutputDevices input={brainboxIn} outputs={brainBoxDevicesOutputs} onClose={() => setIsTriggerOutputDeviceOpen(false)} />
      )}
    </>
  )
}

export default BrainBoxOutputDevices
