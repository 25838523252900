import styled from 'styled-components'

export const RulerButton = styled.button<{selected: boolean; hover?: boolean}>`
  background: none;
  white-space: normal;
  box-sizing: border-box;
  color: ${({selected}) => (selected ? '#009EFF' : '#364152')};
  font-size: 14px;
  font-weight: ${({selected}) => selected && 'bold'};

  :hover {
    color: ${({hover}) => hover && '#009eff'};

    :not(:disabled) {
      text-shadow: 0px 0px 1px black;
    }
  }
  @media (max-width: 1500px) {
    font-size: 12px;
  }
`

export const CalendarButton = styled.button`
  background: none;
  font-weight: bold;
  border: 1px solid #9aa4b2;
  border-radius: 8px;
  padding: 5px 20px;
  line-height: 14px;
  height: 40px;
  color: #009eff;
  @media (max-width: 1400px) {
    padding: 0px 10px;
  }
`

export const ContainerInsideButton = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  @media (max-width: 1400px) {
    font-size: 12px;
  }
`
