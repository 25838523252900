import styled from 'styled-components'

export const ContainerBaseInput = styled.div<{width?: string}>`
  border-radius: 8px;
  border: 1px solid #9aa4b2;
  background: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: ${({width}) => width};

  @media (max-width: 767px) {
    display: none;
  }
`

export const ContainerBaseInputResponsive = styled.div`
  border-radius: 8px;
  border: 1px solid #9aa4b2;
  background: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  display: none;

  @media (max-width: 767px) {
    display: block;
    width: 100%;
    height: 45px;
  }
`

export const SelectWithSearchWrapper = styled.div`
  display: flex;
  gap: 30px;

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    flex-flow: column;
    gap: 10px;
  }
`
export const AdvancedFiltersWrapper = styled.div`
  @media (max-width: 767px) {
    display: none;
  }
`
