import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {useMemo, useState} from 'react'
import TransferOneList from './TransferOneList'
import {Item} from './types'

function partition<T>(xs: T[], selector: (x: T) => boolean): [T[], T[]] {
  const parts: [T[], T[]] = [[], []]
  xs.forEach((x) => parts[+selector(x)].push(x))
  return parts
}

function markUnchecked(items: Item[]): Item[] {
  return items.map((it) => ({...it, checked: false}))
}

function moveChecked(lhs: Item[], rhs: Item[]) {
  const [unchecked, checked] = partition(lhs, (it) => it.checked)
  return [unchecked, rhs.concat(markUnchecked(checked))]
}

function toggleCheck(items: Item[], key: string) {
  return items.map((it) => (it.key === key ? {...it, checked: !it.checked} : it))
}

function moveRightFiltered(lfItems: Item[], rfItems: Item[], key: string, id: number) {
  if (id === 2) {
    key = ''
  }

  const leftItems = lfItems.filter((it) => !it.key.match(key.toUpperCase()))
  const rightItems = rfItems.concat(lfItems.filter((it) => it.key.match(key.toUpperCase())))
  return [leftItems, rightItems]
}

function moveLeftFiltered(lfItems: Item[], rfItems: Item[], key: string, id: number) {
  if (id === 1) {
    key = ''
  }

  const leftItems = lfItems.concat(rfItems.filter((it) => it.key.match(key.toUpperCase())))
  const rightItems = rfItems.filter((it) => !it.key.match(key.toUpperCase()))
  return [leftItems, rightItems]
}

export type TransferListData = {
  leftItems: Item[]
  rightItems: Item[]
}

type TransferListProps = {
  leftItems: Item[]
  rightItems: Item[]
  onChange: (data: TransferListData) => void
  disabled?: boolean
  leftTitle?: string
  rightTitle?: string
  objGender?: 'o' | 'a'
}

function TransferList({leftItems, rightItems, onChange, disabled = false, leftTitle, rightTitle, objGender}: TransferListProps) {
  const leftItemsChecked = useMemo(() => leftItems.filter((it) => it.checked), [leftItems])
  const rightItemsChecked = useMemo(() => rightItems.filter((it) => it.checked), [rightItems])
  const [search, setSearch] = useState<string>('')
  const [id, setId] = useState(Number)

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' sx={[styles.container]}>
      <Grid item style={styles.list}>
        <TransferOneList
          id={1}
          valueKey={(search, identity) => {
            setId(identity)
            setSearch(search)
          }}
          disabled={disabled}
          title={leftTitle || 'Câmeras disponíveis'}
          items={leftItems}
          toggleChecked={(item) => {
            onChange({
              leftItems: toggleCheck(leftItems, item.key),
              rightItems,
            })
          }}
          objGender={objGender}
        />
      </Grid>
      <Grid item sx={styles.buttonsContainer}>
        <Grid container sx={styles.buttons}>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [lNewItems, rNewItems] = moveRightFiltered(leftItems, rightItems, search, id)
              onChange({
                leftItems: lNewItems,
                rightItems: rNewItems,
              })
            }}
            disabled={disabled || leftItems.length === 0}
            aria-label='move all to right'>
            <DoubleArrowRoundedIcon />
          </Button>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [newLeftItems, newRightItems] = moveChecked(leftItems, rightItems)
              onChange({leftItems: newLeftItems, rightItems: newRightItems})
            }}
            disabled={disabled || leftItemsChecked.length === 0}
            aria-label='move selected to right'>
            <ArrowForwardIosIcon />
          </Button>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [newRightItems, newLeftItems] = moveChecked(rightItems, leftItems)
              onChange({leftItems: newLeftItems, rightItems: newRightItems})
            }}
            disabled={disabled || rightItemsChecked.length === 0}
            aria-label='move selected to left'>
            <ArrowBackIosRoundedIcon />
          </Button>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [lNewItems, rNewItems] = moveLeftFiltered(leftItems, rightItems, search, id)
              onChange({
                leftItems: lNewItems,
                rightItems: rNewItems,
              })
            }}
            disabled={disabled || rightItems.length === 0}
            aria-label='move all to left'>
            <DoubleArrowRoundedIcon sx={styles.reverseButton} />
          </Button>
        </Grid>
      </Grid>
      <Grid item style={styles.list}>
        <TransferOneList
          valueKey={(search, identity) => {
            setId(identity)
            setSearch(search)
          }}
          disabled={disabled}
          title={rightTitle || 'Câmeras selecionadas'}
          items={rightItems}
          toggleChecked={(item) => {
            onChange({
              leftItems,
              rightItems: toggleCheck(rightItems, item.key),
            })
          }}
          id={2}
          objGender={objGender}
        />
      </Grid>
    </Grid>
  )
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderRadius: 2,
    margin: '8px 0 8px 0 ',
    width: '100%',
    borderColor: '#9a9a9b',
    overflowY: 'auto',
  },
  list: {
    padding: 0,
    minWidth: '200px',
    width: '42%',
    paddingBottom: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    width: '10%',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    my: 0.5,
    minWidth: '38px',
    minHeight: '38px',
  },
  reverseButton: {
    transform: 'scaleX(-1)',
  },
}

export default TransferList
