import {Box, Checkbox, Typography} from '@viptech/react-components'
import {useEffect, useState} from 'react'
import {useCameraConfigContext} from '../../context/CameraConfigContext'
import {useDebounce} from 'usehooks-ts'
import Slider from 'src/components/slider'

type CameraAdvancedConfigProps = {
  isEditing: boolean
  isCancel: boolean
  setConfigs: (detectionSensitivity: number, largeQueue: boolean) => void
}

function CameraAdvancedConfig(props: CameraAdvancedConfigProps) {
  const {camera} = useCameraConfigContext()
  const [detectionSensitivity, setDetectionSensitivity] = useState(camera?.configuration.detectionSensitivity! * 100 || 0.85)
  const [largeQueue, setLargeQueue] = useState<boolean>(camera?.configuration.largeQueue || false)
  const detectionSensitivityDebounced = useDebounce(detectionSensitivity, 500)

  function toggleButton() {
    setLargeQueue((prev) => !prev)
  }

  useEffect(() => {
    props.setConfigs(detectionSensitivityDebounced / 100, largeQueue)
  }, [detectionSensitivityDebounced, largeQueue])

  useEffect(() => {
    setDetectionSensitivity(camera?.configuration.detectionSensitivity! * 100)
    setLargeQueue(camera?.configuration.largeQueue || false)
  }, [props.isCancel])

  return (
    <Box display='flex' direction='column' height='100%' minHeight='50vh'>
      <Box paddingTop='24px'>
        <Typography variant='h5' size='16px'>
          Sensibilidade da detecção
        </Typography>
        <Typography variant='span' size='14px' color='#8E8E8E'>
          A sensibilidade da detecção define o quão sensível a câmera se comportará. Quanto mais sensível mais chance de enviar eventos.
        </Typography>
        <Box paddingTop='30px'>
          <Slider
            min={1}
            max={100}
            value={detectionSensitivity}
            setValue={setDetectionSensitivity}
            bubble
            thumbColor={props.isEditing ? '#258BE3' : '#79b3e6'}
            background={props.isEditing ? '#b9b9b9' : '#e0e0e0'}
            width='500px'
            disabled={!props.isEditing}
          />
          <Box display='flex' direction='row' align='center' justify='space-between' width='500px' paddingTop='8px'>
            <Typography variant='span' size='12px'>
              Menos sensível
            </Typography>
            <Typography variant='span' size='12px'>
              Mais sensível
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box paddingTop='64px'>
        <Box columnGap='4px' display='flex' align='center' justify='flex-start' direction='row'>
          <Checkbox checked={largeQueue} onClick={() => toggleButton()} disabled={!props.isEditing} />{' '}
          <Typography variant='h5' size='16px'>
            Desejo utilizar fila de detecção de maior precisão
          </Typography>
        </Box>
        <Typography variant='span' size='14px' color='#8E8E8E'>
          Ao utilizar essa fila as detecções serão mais lentas, porém mais acuradas. Utilizar somente em câmeras com comportamento crítico.
        </Typography>
      </Box>
    </Box>
  )
}

export default CameraAdvancedConfig
