import {AxiosError} from 'axios'
import {useEffect, useState} from 'react'
import {ListFacialRecognitionDTO} from 'src/common/models/facial-recognition/ListFacialRecognitionDTO'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {useCompany} from 'src/contexts/CompanyContext'
import api from 'src/services/api'

type FaceListProps = {
  companyId?: number
}

function useFaceList(props: FaceListProps) {
  const [lists, setLists] = useState<ListFacialRecognitionDTO[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<AxiosError | null>(null)
  const hasFacialPermission = ValidatePermissions('face:read')
  const {selectedCompanies} = useCompany()

  async function getLists(replace: boolean = true) {
    setIsLoading(true)
    try {
      let facial: ListFacialRecognitionDTO[] = []
      const companyIdFilter = props.companyId ? [props.companyId] : selectedCompanies
      if (hasFacialPermission) {
        facial = (await api.facialRecognition.getList({filter: {companyIds: companyIdFilter}})).data.data.entities
      }
      setLists(facial)
      setError(null)
    } catch (err) {
      const error = err as AxiosError
      setError(error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getLists()
  }, [])

  return {lists, isLoading, error, refresh: getLists}
}

export default useFaceList
