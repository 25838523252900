import {Box} from '@viptech/react-components'
import {useState} from 'react'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ValidatePermissions} from '../../common/utils/ValidatePermissions'
import {ConfirmationModal} from '../../components/ConfirmationModal/ConfirmationModal'
import TopBar from '../../components/top-bar/TopBar'
import AppReloadButton from '../../components/appReloadButton/AppReloadButton'
import {DropdownItem} from '../../components/dropdown/DropdownProps'
import {SelectWithSearchComponent} from '../../components/selectWithSearchComponent/SelectWithSearchComponent'
import {RenderIfConditionIsMet} from '../../utilities/RenderIfConditionIsMet'
import UserOptionsMenu from '../users/components/UserOptionsMenu'
import ClientsModal from './components/ClientsModal'
import ClientsGridTable from './components/clients-grid-table/ClientsGridTable'
import ClientsContextProvider, {useClientsContext} from './contexts/ClientsContext'

function ClientsInner() {
  const {
    openClientAddModal,
    clientSearch,
    showOptionsButton,
    setClientSearch,
    reloadingClients,
    reloadClients,
    selectedClientsIds,
    deleteClient,
    setSearchFilterSelected,
    searchFilterData,
  } = useClientsContext()

  const hasCreateClientPermission = ValidatePermissions('clients:create')
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const menuOpen = Boolean(menuEl)
  const quantity = selectedClientsIds.length
  const isPlural: string = quantity === 1 ? '' : 's'

  function withConfirmation(message: string, title: string) {
    return () => {
      setMenuEl(null)
      setModalContent(title)
      setOpenModal(true)
      setModalTitle(message)
    }
  }
  const [showSearchSelected, setShowSearchSelected] = useState<DropdownItem>({
    id: '',
    label: '',
  })

  const onSelectChange = (e: DropdownItem) => {
    setShowSearchSelected(e)
    setSearchFilterSelected(e.label)
  }

  return (
    <Box style={styles.paper}>
      <TopBar>
        <Box display='flex' direction='row' justifyContent='space-between'>
          <Box display='flex' columnGap='16px'>
            <AppReloadButton loading={reloadingClients} onClick={() => reloadClients()} />

            <SelectWithSearchComponent
              hideAdvancedFilters
              searchBy={searchFilterData}
              setSearchSelected={onSelectChange}
              searchSelected={showSearchSelected}
              inputValue={clientSearch}
              inputSearch={setClientSearch}
              widthOnSelect={'270px'}
            />
          </Box>

          <RenderIfConditionIsMet condition={hasCreateClientPermission}>
            <AppAddOrOptionsButton
              showOptions={showOptionsButton}
              onClickAdd={() => openClientAddModal()}
              onClickOptions={(e) => setMenuEl(e.currentTarget)}
              text='Adicionar um cliente'
            />
          </RenderIfConditionIsMet>
        </Box>
      </TopBar>
      <ClientsGridTable />
      <ConfirmationModal
        title={modalTitle}
        content={modalContent}
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        buttonsContent={[
          {
            label: 'Cancelar',
            onClick: () => setOpenModal(false),
            variant: 'outlined',
            color: '#8E8E8E',
          },
          {
            label: 'Confirmar',
            onClick: () => {
              setOpenModal(false)
              setMenuEl(null)
              deleteClient()
            },
            variant: 'contained',
            color: '#009EFF',
          },
        ]}
      />
      <ClientsModal />
      <UserOptionsMenu
        open={menuOpen}
        anchorEl={menuEl}
        onClose={() => setMenuEl(null)}
        onDelete={withConfirmation(
          `Deletar cliente${isPlural}`,
          `Os registros deste${isPlural} cliente${isPlural} e todas as câmeras associadas serão removidas. Esta ação pode ser revertida.`,
        )}
      />
    </Box>
  )
}

function Clients() {
  return (
    <ClientsContextProvider>
      <ClientsInner />
    </ClientsContextProvider>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
  },
})

export default Clients
