import {useSnackbar} from 'notistack'
import {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react'
import {AreaData} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import {Dim} from 'src/common/types/generics/ImageDetections'
import {KonvaImageRef} from 'src/common/types/generics/KonvaImageRef'
import api, {Camera, CameraConfiguration} from 'src/services/api'
import {LineCrossingDTO} from 'src/services/api/endpoints/LineCrossingEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {CameraConfigContextData} from './CameraConfigContextData'

export const CameraConfigContext = createContext<CameraConfigContextData>({} as CameraConfigContextData)

type ClientIntegrationType = 'sigma' | 'moni' | 'onePortaria' | 'noIntegration'

export const CameraConfigProvider = ({children}: any) => {
  const [loadingSnapshot, setLoadingSnapshot] = useState(false)
  const [isOpenReceiveModal, setIsOpenReceiveModal] = useState(false)
  const [loadInterestAreas, setLoadInterestAreas] = useState(false)
  const [snapshot, setSnapshot] = useState<null | string>(null)
  const [reloadSnapshotCount, setReloadSnapshotCount] = useState(0)
  const [camera, setCamera] = useState<Camera | null>(null)
  const [_dimImage, setDimImage] = useState<Dim>({width: 612, height: 459})
  const [areaItems, setAreaItems] = useState<AreaData[]>([])
  const [diffConfig, _setDiffConfig] = useState<CameraConfiguration | null>(null)
  const [listLineCrossing, setListLineCrossing] = useState<LineCrossingDTO[]>([])
  const [clientIntegration, setClientIntegration] = useState<ClientIntegrationType>('noIntegration')
  const {enqueueSnackbar} = useSnackbar()
  const konvaImageRef = useRef<KonvaImageRef>({scale: 0, dim: {height: 0, width: 390}})
  const reloadSnapshot = () => setReloadSnapshotCount((x) => x + 1)
  const blockCameraRequests = useRef(false)
  const blockSnapshotRequests = useRef(false)

  const setDiffConfig = useCallback((newConfig: Partial<CameraConfiguration>) => {
    _setDiffConfig((diffConfig) => {
      if (diffConfig !== null) {
        return {...diffConfig, ...newConfig}
      } else {
        return null
      }
    })
  }, [])

  const getCamera = useCallback(
    async (cameraId: number) => {
      try {
        if (blockCameraRequests.current) return
        blockCameraRequests.current = true
        const response = await api.camera.getById(cameraId, {
          includes: ['configuration', 'configuration', 'status', 'server', 'client', 'addressInfo'],
        })
        if (response.data.data.addressInfo === null && response.data.data.origin === 'RTSP') {
          enqueueSnackbar('Falha ao tentar obter informações da câmera', {variant: 'error'})
        }
        setCamera(response.data.data)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Falha ao tentar obter câmera')
      } finally {
        blockCameraRequests.current = false
      }
    },
    [enqueueSnackbar],
  )

  useEffect(() => {
    async function getClientIntegration() {
      if (!camera || !camera.client) return
      const res = await api.client.getById(camera?.client.id!)
      const client = res.data.data
      if (client.sigmaAccount) return setClientIntegration('sigma')
      if (client.moniClientInfo) return setClientIntegration('moni')
      if (client.onePortariaClientInfo) return setClientIntegration('onePortaria')
      return setClientIntegration('noIntegration')
    }

    getClientIntegration()
  }, [camera])

  const loadLineCrossing = useCallback(
    async (id: number) => {
      try {
        const response = (await api.lineCrossing.getLineCrossing(id)).data
        setListLineCrossing(response.data)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Falha ao buscar os cruzamentos de linhas cadastrados')
      }
    },
    [enqueueSnackbar],
  )

  const loadSnapshot = useCallback(
    async (camera: Camera) => {
      try {
        if (blockSnapshotRequests.current) return
        blockSnapshotRequests.current = true
        setLoadingSnapshot(true)
        const response = await api.camera.getSnapshot(camera.id)
        setSnapshot(response?.data?.data?.time.base64)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar snapshot do dispositivo')
      } finally {
        setLoadingSnapshot(false)
        blockSnapshotRequests.current = false
      }
    },
    [enqueueSnackbar],
  )

  const getSnapshotRtsp = async (cameraId: number) => {
    if (blockSnapshotRequests.current) return
    blockSnapshotRequests.current = true
    const response = await api.camera.getSnapshot(cameraId)
    setSnapshot(response?.data?.data?.time.base64)
    blockSnapshotRequests.current = false
  }

  useEffect(() => {
    if (camera === null || camera.origin === 'RTSP') return
    loadSnapshot(camera)
  }, [camera, reloadSnapshotCount, loadSnapshot])

  useEffect(() => {
    if (camera === null) return
    _setDiffConfig(camera.configuration)
  }, [camera])

  const blockInterestAreasRequests = useRef(false)
  const getInterestAreas = async (id: number, _dimImage: Dim) => {
    try {
      if (blockInterestAreasRequests.current) return
      blockInterestAreasRequests.current = true
      const response = await api.interestArea.getInterestAreas({cameraId: id})
      setAreaItems(
        response.data.data.map((it) => {
          const points = it.coordinates.map((it2) => {
            return {
              id: it2.id,
              x: it2.x * _dimImage.width,
              y: it2.y * _dimImage.height,
              interestAreaId: it2.interestAreaId,
            }
          })

          return {
            key: `${it.name} ${it.id}`,
            label: it.name,
            points,
            data: {area: it, updated: false},
          }
        }),
      )
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar áreas')
    } finally {
      setLoadInterestAreas(false)
      blockInterestAreasRequests.current = false
    }
  }

  return (
    <CameraConfigContext.Provider
      value={{
        camera,
        getCamera,
        diffConfig,
        setDiffConfig,
        snapshot,
        loadingSnapshot,
        reloadSnapshot,
        konvaImageRef,
        _dimImage,
        setDimImage,
        areaItems,
        setAreaItems,
        loadInterestAreas,
        setLoadInterestAreas,
        isOpenReceiveModal,
        setIsOpenReceiveModal,
        getSnapshotRtsp,
        setLoadingSnapshot,
        listLineCrossing,
        setListLineCrossing,
        loadLineCrossing,
        clientIntegration,
        getInterestAreas,
      }}>
      {children}
    </CameraConfigContext.Provider>
  )
}

export function useCameraConfigContext() {
  return useContext(CameraConfigContext)
}
