import {Box, Button, Input, Loading, Typography} from '@viptech/react-components'
import {enqueueSnackbar} from 'notistack'
import {useCallback, useEffect, useState} from 'react'
import {useDebounce} from 'usehooks-ts'
import {ValidatePermissions} from '../../../common/utils/ValidatePermissions'
import RealodIconSmallChangeColor from '../../../images/ReloadIconGreenSmall'
import SearchIconInputSmallChangeColor from '../../../images/SearchIconInputSmall'
import addIcon from '../../../images/addPlusIconBlueSmall.svg'
import CompaniesSelect from '../../../layouts/main-layout/header/components/CompaniesSelect'
import api, {Client, Server} from '../../../services/api'
import {Company} from '../../../services/api/endpoints/CompanyEndpoint'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'
import Card from '../../camera-config/components/object-detection/newComponents/stepperModal/common/components/Card'
import {StepperModalActionsButtonContainer} from '../../camera-config/components/object-detection/newComponents/styles'
import {useCamRegisterModalContext2} from '../context/CameraRegisterContext'
import {ChosenObject} from './CreateCameraStepper'
import {ContainerClientsCards, ContainerServers, ContainerServersCards, ContainerSteps, ContainerStepsRow, TransparentButton} from './styles'

type ServerList = {
  id: number
  name: string
}

type GeneralInformationStepProps = {
  nextFunction: (index: number, resume: string) => void
  returnFunction: (open: boolean) => void
  setChosen: (choices: Partial<ChosenObject>) => void
  chosen: ChosenObject | null
}

const GeneralInformationStep = (props: GeneralInformationStepProps) => {
  const {nextFunction, returnFunction, setChosen} = props

  const {setSelectedCompanyId, selectedCompanyId, setSelectedServer, setStep, setSelectedClient} = useCamRegisterModalContext2()

  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])

  const [clients, setClients] = useState<Client[]>([])
  const [isLoadingClients, setIsLoadingClients] = useState(false)
  const [clientSelected, setClientSelected] = useState({id: '', label: ''})
  const [disableButton, setDisableButton] = useState(false)

  const hasPermissionReadClients = ValidatePermissions('clients:read')
  const [serversSearch, setServersSearch] = useState('')
  const debouncedServerSearch = useDebounce(serversSearch, 400)

  const loadClients = async () => {
    try {
      setIsLoadingClients(true)
      setServerSelected({id: '', label: ''})
      if (!selectedCompanyId) return setClients([])
      if (!hasPermissionReadClients) {
        enqueueSnackbar('Você não possui permissão de visualizar clientes. Entre em contato com o administrador', {variant: 'warning'})
        return
      }

      setClientSelected({id: '', label: ''})
      const response = await api.client.getMany({
        page: 1,
        paginate: true,
        filter: {companyIds: selectedCompanyId},
        search: {
          name: debouncedServerSearch,
        },
      })
      setClients(response.data.data.entities)
      const clients = response.data.data.entities

      if (!clients.length) {
        setDisableButton(true)
        enqueueSnackbar('A empresa selecionada não possui clientes cadastrados', {variant: 'warning'})
      }

      if (clients.length === 1) {
        setClientSelected({id: String(clients[0].id), label: clients[0].name})
        setSelectedClient(clients[0])
        setChosen({
          clientId: clients[0].id,
        })
      }
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar os clientes')
    } finally {
      setIsLoadingClients(false)
    }
  }

  useEffect(() => {
    loadClients()
    loadAllServers()
  }, [selectedCompanyId, debouncedServerSearch])

  const [servers, setServers] = useState<Server[]>([])
  const [serverList, setServerList] = useState<ServerList[]>([])

  const hasPermissionReadServers = ValidatePermissions('servers:read')
  const hasRtspPermissionCreate = ValidatePermissions('rtsp-camera:create')

  const [serverSelected, setServerSelected] = useState({id: '', label: ''})

  const loadAllServers = useCallback(async () => {
    if (hasPermissionReadServers) {
      if (!selectedCompanyId) return
      try {
        setServerSelected({id: '', label: ''})
        const response = await api.server.findManyPaged({
          page: 1,
          pageSize: 100,
          filter: {
            integrationType: 'ENTRADA',
            companyIds: selectedCompanyId,
          },
          includes: ['serverType'],
        })
        const servers = response.data.data.entities.map((it) => it.serverType)
        setServers(response.data.data.entities)
        if (servers.length === 1 && !hasRtspPermissionCreate) {
          checkServerList()
          setServerSelected({id: String(serverList[0].id), label: serverList[0].name})
          setChosen({
            serverName: serverList[0].name,
          })
        }

        if (!servers.length && !hasRtspPermissionCreate) {
          setDisableButton(true)
          enqueueSnackbar('Não encontramos servidores de entrada cadastrados para o cliente', {variant: 'warning'})
        }
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao buscar servidores de integração')
      } finally {
      }
    } else enqueueSnackbar('Você não possui permissão de visualizar servidores. Entre em contato com o administrador', {variant: 'warning'})
  }, [selectedCompanyId])

  const checkServerList = () => {
    const list: ServerList[] = []
    if (servers.some((it) => it.serverTypeId === 2)) list.push({...list, id: 2, name: 'Digifort'})
    if (servers.some((it) => it.serverTypeId === 4)) list.push({...list, id: 4, name: 'D-Guard'})
    setServerList(list)
  }

  useEffect(() => {
    checkServerList()
  }, [servers])

  useEffect(() => {
    setChosen({
      companyId: selectedCompanyId[0],
    })
  }, selectedCompanyId)

  return (
    <ContainerSteps>
      <ContainerStepsRow>
        <Box display='flex' direction='column' width='50%'>
          <Box flex={1} align='center' justifyContent='center'>
            <Typography variant='span' size='16px' style={{fontWeight: 500}}>
              Empresa
            </Typography>
            <Typography variant='p' size='12px' color='#8E8E8E'>
              Selecione abaixo qual empresa irá receber a(s) câmera(s)
            </Typography>

            <Box paddingTop='20px'>
              <CompaniesSelect
                selectSize='small'
                defaultValueIds={selectedCompanyId}
                setSelectedCompaniesIds={setSelectedCompanyId}
                companiesScroll={companiesScroll}
                setCompaniesScroll={setCompaniesScroll}
                pageCount={pageCount}
                setPageCount={setPageCount}
                companyNames={companyNames}
                setCompanyNames={setCompanyNames}
                multiple={false}
              />
            </Box>
          </Box>

          <Box align='center' justifyContent='center'>
            <Typography variant='span' size='16px' style={{fontWeight: 500}}>
              Clientes
            </Typography>
            <Typography variant='p' size='12px' color='#8E8E8E'>
              Selecione abaixo qual cliente irá receber a(s) câmera(s)
            </Typography>

            <Box justifyContent='space-around' display='flex' align='center' margin='10px 0'>
              <TransparentButton color='#039855' onClick={loadClients}>
                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                  <RealodIconSmallChangeColor />
                  <span>Atualizar</span>
                </div>
              </TransparentButton>

              <TransparentButton color='#009EFF' onClick={() => window.open('/registers/clients?new')}>
                <div style={{display: 'flex', gap: '5px', justifyContent: 'flex-end'}}>
                  <img src={addIcon} alt='Adicionar cliente' />
                  <span>Adicionar cliente</span>
                </div>
              </TransparentButton>
            </Box>

            <Input.Root
              hasIcon
              maxLength={50}
              leftIcon={<SearchIconInputSmallChangeColor />}
              labelFontWeight='400'
              placeholder='Pesquisar'
              value={debouncedServerSearch}
              onChange={(e) => setServersSearch(e.target.value)}
            />

            <ContainerClientsCards>
              {isLoadingClients ? (
                <Loading color='#009EFF' size='40px' align='center' />
              ) : (
                clients.map((client) => {
                  return (
                    <Card
                      label={client.name}
                      selected={clientSelected.label === client.name}
                      onClick={() => {
                        setClientSelected({id: String(client.id), label: client.name})
                        setSelectedClient(client)
                        setChosen({
                          companyId: selectedCompanyId[0],
                          clientId: client.id,
                        })
                      }}
                      initialState={!clientSelected.label}
                    />
                  )
                })
              )}
            </ContainerClientsCards>
          </Box>
        </Box>

        <Box display='flex' direction='column' rowGap='25px' width='50%'>
          {clientSelected.label && (
            <Box align='center' justifyContent='center'>
              <Typography variant='span' size='16px' style={{fontWeight: 500}}>
                Integração de entrada
              </Typography>
              <Typography variant='p' size='12px' color='#8E8E8E'>
                Selecione o tipo de integração para se conectar à câmera
              </Typography>

              <Box justifyContent='space-around' width='100%' display='flex' align='center' margin='10px 0'>
                <TransparentButton color='#039855' onClick={loadAllServers}>
                  <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                    <RealodIconSmallChangeColor />
                    <span>Atualizar</span>
                  </div>
                </TransparentButton>

                <TransparentButton color='#009EFF' onClick={() => window.open('/settings/inputs?new')}>
                  <div style={{display: 'flex', gap: '5px', justifyContent: 'flex-end'}}>
                    <img src={addIcon} alt='Adicionar integração' />
                    <span>Adicionar integração</span>
                  </div>
                </TransparentButton>
              </Box>

              <ContainerServers>
                <div>
                  {serverList.length !== 0 && (
                    <>
                      <Typography variant='span' size='14px' color='#5F5B5B' style={{fontWeight: 500}}>
                        VMS:
                      </Typography>
                      <ContainerServersCards>
                        {serverList.map((server) => {
                          return (
                            <Card
                              label={server.name}
                              selected={serverSelected.label === server.name}
                              onClick={() => {
                                setServerSelected({id: String(server.id), label: server.name})
                                setChosen({
                                  serverName: server.name,
                                })
                              }}
                              initialState={!serverSelected.label}
                            />
                          )
                        })}
                      </ContainerServersCards>
                    </>
                  )}
                </div>

                <div>
                  {hasRtspPermissionCreate && (
                    <>
                      <Typography variant='span' size='14px' color='#5F5B5B' style={{fontWeight: 500}}>
                        Conexão direta:
                      </Typography>
                      <ContainerServersCards>
                        <Card
                          label={'DVR / NVR'}
                          selected={serverSelected.label === 'DVR / NVR'}
                          onClick={() => {
                            setServerSelected({id: '11', label: 'DVR / NVR'})
                            setChosen({
                              serverName: 'DVR / NVR',
                            })
                          }}
                          initialState={!serverSelected.label}
                        />
                        <Card
                          label={'Câmera RTSP'}
                          selected={serverSelected.label === 'Câmera RTSP'}
                          onClick={() => {
                            setChosen({
                              serverName: 'Câmera RTSP',
                            })
                            setServerSelected({id: '12', label: 'Câmera RTSP'})
                          }}
                          initialState={!serverSelected.label}
                        />
                      </ContainerServersCards>
                    </>
                  )}
                </div>
              </ContainerServers>
            </Box>
          )}
        </Box>
      </ContainerStepsRow>

      <StepperModalActionsButtonContainer>
        <Button
          fontSize='12px'
          height='40px'
          width='150px'
          variant='outlined'
          color='#8E8E8E'
          onClick={() => {
            setClientSelected({id: '', label: ''})
            returnFunction(false)
          }}>
          Cancelar
        </Button>
        <Button
          fontSize='12px'
          disabledTextColor='#8E8E8E'
          height='40px'
          width='150px'
          onClick={() => {
            setStep(2)
            if (serverSelected.label === 'Digifort' || serverSelected.label === 'D-guard')
              setSelectedServer(servers.find((it) => it.serverTypeId === +serverSelected.id)!)
            else setSelectedServer(null)
            nextFunction(1, 'Concluído')
          }}
          disabled={disableButton || !serverSelected.label}>
          Próximo
        </Button>
      </StepperModalActionsButtonContainer>
    </ContainerSteps>
  )
}

export default GeneralInformationStep
