import {Box, Button} from '@mui/material'
import {Link} from 'react-router-dom'
import {BtnClose} from 'src/components/BtnClose'
import {useAuth} from 'src/contexts/AuthContext'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {useAlarmsContext} from '../../contexts/AlarmsContext'
import AlarmsImagesModal from '../AlarmsImagesModal'
import {FacialModal} from './components/facial-modal/FacialModal'
import {ModalContainer} from './AlarmsModalBase'

function AlarmsModal() {
  const {user} = useAuth()
  const {self, alarm, closeAlarmModal, hasPermissionReadCamera} = useAlarmsContext()

  return self && self.faceRecognitions && self.faceRecognitions.length > 0 ? (
    <FacialModal alarm={self} faceRecognition={self.faceRecognitions[0]} isDisabled={user?.role === 'operator'} />
  ) : (
    <ModalContainer>
      <Box sx={styles.buttonsContainer}>
        {hasPermissionReadCamera && (
          <Button
            variant='contained'
            sx={styles.configButton}
            component={Link}
            to={`/registers/devices/${alarm?.cameraId}`}
            disabled={user?.role === 'operator' || !alarm}>
            Configuração do Dispositivo
          </Button>
        )}
        <BtnClose onClose={closeAlarmModal} />
      </Box>
      <AlarmsImagesModal />
    </ModalContainer>
  )
}

export default AlarmsModal

const styles = createStyleSheet({
  buttonsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 1.5,
  },
  configButton: {
    width: '43%',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'white',
    marginBottom: 1,
    marginLeft: 1,

    fontFamily: 'Inter, sans-serif',
    fontSize: '0.665em',
    fontWeight: 'bold',
    textTransform: 'initial',
    textAlign: 'center',

    backgroundColor: '#009eff',
    transition: '0.1s',
    '&:hover': {
      backgroundColor: '#00639f',
      color: 'white',
    },
  },
})
