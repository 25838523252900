import styled from 'styled-components'

export const InputCode = styled.input`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid #344054;
  border-radius: 8px;
  width: 61.579px;
  height: 76.632px;

  @media (max-width: 1200px) {
    width: 50px;
    height: 66px;
  }

  @media (max-width: 948px) {
    width: 40px;
    height: 56px;
  }
`

export const ConfirmationContainer = styled.div`
  display: flex;
  gap: 250px;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: 1200px) {
    gap: 150px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

export const FormCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 24px;
  max-width: 540px;

  @media (max-width: 1024px) {
    max-width: 300px;
  }

  @media (max-width: 767px) {
    max-width: 540px;
  }
`

export const ButtonLink = styled.button`
  background: transparent;
  color: #009eff;
  align-self: flex-start;
  padding: 0;

  :hover {
    text-decoration: underline;
  }
`
