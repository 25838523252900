import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {useNavigate} from 'react-router-dom'
import {Box} from '@viptech/react-components'
import {useApp} from '../../contexts/AppContext'
import {PageTitle, PageTitleBreadcrumb} from '../HeaderBase'

export function HeaderPageTitle() {
  const navigate = useNavigate()
  const {sidebarTitle, setSidebarTitle} = useApp()

  const renderTitleOnPage = (): string[] => {
    if (sidebarTitle.includes('>')) {
      const newTitle = sidebarTitle.split('>')
      return newTitle
    } else {
      const oneTitle = [sidebarTitle]
      return oneTitle
    }
  }

  const breadcrumbNavigationFunction = () => {
    if (renderTitleOnPage()[0].includes('Cadastros - Brain Box')) navigate('/registers/brainbox')
    else if (renderTitleOnPage()[0].includes('Administrativo - Empresas')) navigate('/administrative/companies')
    else if (renderTitleOnPage()[0].includes('Cadastros - Usuários')) navigate('/registers/users')
    else if (renderTitleOnPage()[0].includes('Cadastros - Dispositivos')) navigate('/registers/devices')
    else navigate(-1)

    setSidebarTitle(renderTitleOnPage()[0])
  }

  return (
    <Box>
      {renderTitleOnPage().length === 1 ? (
        <PageTitle>{renderTitleOnPage()[0]}</PageTitle>
      ) : (
        <Box width='100%' display='flex' align='center'>
          <PageTitleBreadcrumb onClick={() => breadcrumbNavigationFunction()}>{renderTitleOnPage()[0]}</PageTitleBreadcrumb>
          <ArrowForwardIosIcon sx={{fontSize: '0.75rem', marginRight: '7px'}} />
          <PageTitle>{renderTitleOnPage()[1]}</PageTitle>
        </Box>
      )}
    </Box>
  )
}
