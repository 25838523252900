import {Box, Chip} from '@mui/material'
import {useTransactionsContext} from '../contexts/TransactionsContext'

function TransactionsFilter() {
  const {openFilter, toggleStatusFilter, transactionStatusFilter, transactionStatus} = useTransactionsContext()

  if (!openFilter) return null

  return (
    <Box sx={{paddingBottom: 1.5, paddingTop: 0.5}}>
      {transactionStatus.map((status, index) => {
        return (
          <Chip
            key={`day-checkbox-${index}`}
            sx={{marginLeft: index === 0 ? 0 : 1, border: '1px solid #1976d2'}}
            label={status}
            color='primary'
            variant={transactionStatusFilter ? 'filled' : 'outlined'}
            onClick={() => toggleStatusFilter()}
          />
        )
      })}
    </Box>
  )
}

export default TransactionsFilter
