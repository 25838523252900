import {Outlet} from 'react-router-dom'
import {Container} from '../pages/settings/Styles'

function SubMenus() {
  return (
    <Container>
      <Outlet />
    </Container>
  )
}

export default SubMenus
