import {useState} from 'react'
import React from 'react'
import {createPortal} from 'react-dom'
import {TooltipChildrenContainer, TooltipContainer} from './TooltipBase'
import {MouseCoords, TooltipProps} from './TooltipProps'

function Tooltip({children, content, containerProps: box, ...styleProps}: TooltipProps) {
  const [coords, setCoords] = useState<{left: number; top: number} | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)

  const onMouseEnter = () => setShowTooltip(true)
  const onMouseLeave = () => setShowTooltip(false)

  const handleMouseCoords = (e: MouseCoords) => {
    setCoords({
      left: e.clientX,
      top: e.clientY,
    })
  }

  return (
    <>
      <TooltipChildrenContainer onMouseEnter={onMouseEnter} onMouseMove={handleMouseCoords} onMouseLeave={onMouseLeave} {...box}>
        {children}
      </TooltipChildrenContainer>

      {showTooltip &&
        coords &&
        content &&
        createPortal(
          <TooltipContainer {...styleProps} top={coords.top} left={coords.left}>
            {content}
          </TooltipContainer>,
          document.body,
        )}
    </>
  )
}

export default Tooltip
