import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import {ValidatePermissions} from 'src/common/utils/ValidatePermissions'
import {
  ButtonModalConfigEveryone,
  StyleBoxItemCamEveryone,
  StyleBoxItemCamSchedule,
  StyleTabsModal,
  SxBoxListSchedule,
  TitleModalConfig,
} from 'src/pages/camera-config/components/camera-general-data/styles'
import {useCameraConfigContext} from 'src/pages/camera-config/context/CameraConfigContext'
import api, {Schedule, Server} from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isNumber} from 'src/utilities/regexValidation'
import {cameraConfigReceiveEventStyles} from './CameraConfigReceiveEventBase'
import {ReceiveModeOptionsEnum, daysWeek, formatScheduleTime, isHidden, receiveMode, removeScheduleTime} from './CameraConfigReceiveEventFunctions'
import {CameraConfigReceiveEventProps} from './CameraConfigReceiveEventProps'
import {Link} from 'react-router-dom'

function CameraConfigReceiveEvent(cameraConfigReceiveEventProps: CameraConfigReceiveEventProps) {
  const [outputServers, setOutputServers] = useState<Server[]>([])
  const [schedulers, setSchedulers] = useState<Schedule[]>([])
  const [brainBoxes, setBrainBoxes] = useState<BrainBox[]>([])
  const {enqueueSnackbar} = useSnackbar()
  const {camera, diffConfig, setDiffConfig, setIsOpenReceiveModal, isOpenReceiveModal} = useCameraConfigContext()
  const {isEditing, outputIntegration} = cameraConfigReceiveEventProps
  const hasReadBrainBoxPermission = ValidatePermissions('brainbox:read')
  const blockBrainBoxRequests = useRef(false)
  const blockScheduleRequests = useRef(false)
  const blockServersRequests = useRef(false)

  const handleChangePartition = (e: {target: {value: any}}) => {
    if (isNumber(e.target.value) || e.target.value === '') setDiffConfig({...diffConfig, partition: e.target.value})
  }

  const getAllBrainBoxes = useCallback(async () => {
    try {
      if (blockBrainBoxRequests.current) return
      blockBrainBoxRequests.current = true
      const response = await api.brainBox.getAll({
        includes: ['client'],
        filter: {clientIds: [camera?.clientId!]},
      })
      setBrainBoxes(response.data.data.entities)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar tabela de Brain Boxes')
    } finally {
      blockBrainBoxRequests.current = false
    }
  }, [enqueueSnackbar, camera])

  const loadConfigSchedulers = useCallback(
    async (id: number) => {
      try {
        if (blockScheduleRequests.current) return
        blockScheduleRequests.current = true
        const response = await api.schedule.getByCamera({
          cameraId: id,
        })
        setSchedulers(response.data.data)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar agendamentos')
      } finally {
        blockScheduleRequests.current = false
      }
    },
    [enqueueSnackbar],
  )

  useEffect(() => {
    if (!hasReadBrainBoxPermission) return
    getAllBrainBoxes()
  }, [getAllBrainBoxes, camera, hasReadBrainBoxPermission])

  useEffect(() => {
    if (!camera) throw new Error('unreachable')
    if (isOpenReceiveModal) return
    loadConfigSchedulers(camera.id)
  }, [camera, loadConfigSchedulers, isOpenReceiveModal])

  useEffect(() => {
    async function getOutputServers() {
      if (!camera) return
      if (blockServersRequests.current) return
      blockServersRequests.current = true
      const response = await api.server.findManyPaged({
        page: 1,
        pageSize: 100,
        filter: {integrationType: 'SAÍDA', companyIds: [camera.companyId]},
        includes: ['serverType'],
      })
      const servers = response.data.data.entities
      servers.unshift({id: -1, name: 'Sem Integração', ip: '', serverType: {}})
      setOutputServers(servers)
      blockServersRequests.current = false
    }
    getOutputServers()
  }, [camera])

  return (
    <Box overflow='auto' height='55vh' paddingTop='30px'>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <Grid container direction='column' justifyContent='flex-start' spacing={1.5}>
          <Grid item xs={12}>
            <Box sx={[cameraConfigReceiveEventStyles.gridPaper, {display: 'flex', flexDirection: 'row'}]}>
              <Box>
                <TitleModalConfig>Modo de Controle Arme/Desarme</TitleModalConfig>
                <Box sx={StyleBoxItemCamEveryone}>
                  <FormControl disabled={!isEditing}>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      value={diffConfig && diffConfig.receiveModeId}
                      onChange={(_, value) => setDiffConfig({receiveModeId: receiveMode(+value)?.receiveMode.id!})}>
                      {outputServers.filter((server) => server.id !== -1).length > 0 && (
                        <Box sx={{display: 'flex', flexDirection: 'row', gap: '8px'}}>
                          <FormControlLabel
                            value={ReceiveModeOptionsEnum.CLIENT}
                            control={<Radio />}
                            label={<Typography sx={StyleTabsModal}>Controle pelo Cliente</Typography>}
                          />
                          {(outputIntegration === 'sigma' ||
                            outputIntegration === 'moni' ||
                            (diffConfig?.receiveModeId === ReceiveModeOptionsEnum.CLIENT && outputIntegration !== 'noIntegration')) && (
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                              <Typography sx={{fontSize: '12px'}}>Partição</Typography>
                              <TextField
                                sx={{width: '2.75em'}}
                                inputProps={{maxLength: 4}}
                                disabled={!isEditing}
                                variant={'standard'}
                                value={diffConfig && diffConfig.partition}
                                onChange={handleChangePartition}
                              />
                            </Box>
                          )}
                        </Box>
                      )}
                      <FormControlLabel
                        value={ReceiveModeOptionsEnum.HOUR}
                        control={<Radio />}
                        label={<Typography sx={StyleTabsModal}>Controle pelo Horário</Typography>}
                      />
                      {brainBoxes.length > 0 && (
                        <FormControlLabel
                          value={ReceiveModeOptionsEnum.BRAIN_BOX}
                          control={<Radio />}
                          label={<Typography sx={StyleTabsModal}>Controle pela Brain Box</Typography>}
                        />
                      )}
                      <FormControlLabel
                        value={ReceiveModeOptionsEnum.ARMED}
                        control={<Radio />}
                        label={<Typography sx={StyleTabsModal}>Armado 24 Horas</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                  {outputIntegration === 'noIntegration' && diffConfig?.receiveModeId === ReceiveModeOptionsEnum.CLIENT && (
                    <Typography sx={{fontSize: '15px', paddingTop: '5px', paddingLeft: '3px'}}>
                      Este cliente não possui integração de saída configurada para ele, por favor ajuste o cadastro clicando{' '}
                      <Link to={`/registers/clients/clients?id=${camera?.clientId!}`} target='_blank' rel='noopener noreferrer'>
                        aqui
                      </Link>
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid>
        <Grid item xs={12} hidden={isHidden(diffConfig)}>
          <Box sx={[cameraConfigReceiveEventStyles.gridPaper, {display: 'flex', flexDirection: 'row'}]}>
            {diffConfig !== null && diffConfig.receiveModeId === ReceiveModeOptionsEnum.BRAIN_BOX && (
              <Box sx={{display: 'flex', flexDirection: 'column', width: '20%'}}>
                <TitleModalConfig>Brain Box</TitleModalConfig>
                <Box>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>Selecione uma Brain Box</InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      disabled={!isEditing}
                      value={diffConfig !== null && diffConfig.brainBoxId}
                      label='Selecione uma Brain Box'
                      onChange={(e) => setDiffConfig({brainBoxId: Number(e.target.value)})}>
                      {brainBoxes
                        .filter((it) => it.client?.id === camera!.client.id)
                        .map((it2) => (
                          <MenuItem key={'server-' + Number(it2.id)} value={Number(it2.id)}>
                            {it2.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            <Box sx={{width: '100%'}} hidden={diffConfig !== null && diffConfig.receiveModeId !== ReceiveModeOptionsEnum.HOUR}>
              <TitleModalConfig>Períodos de Recebimento</TitleModalConfig>
              <Box sx={StyleBoxItemCamSchedule}>
                <Box
                  sx={{
                    border: '1px solid black',
                    overflowY: 'auto',
                    height: '200px',
                  }}>
                  {schedulers.map((schedule, index) => (
                    <Box sx={SxBoxListSchedule} key={`schedule-${index}-${schedule.id}`}>
                      <List>
                        <ListItem
                          sx={{p: 0, paddingLeft: 1}}
                          secondaryAction={
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={async () => {
                                if (await removeScheduleTime(schedule.id, enqueueSnackbar)) loadConfigSchedulers(camera!.id)
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          }>
                          <ListItemText
                            primary={<Typography sx={StyleTabsModal}>{formatScheduleTime(schedule)}</Typography>}
                            secondary={
                              <Typography sx={StyleTabsModal}>
                                {daysWeek.map((_, index) => {
                                  if (schedule.scheduleHasDays.find((it) => it.dayOfWeekId === index + 1)) {
                                    return daysWeek[index]
                                  } else {
                                    return '-'
                                  }
                                })}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <ButtonModalConfigEveryone disabled={!isEditing} onClick={() => setIsOpenReceiveModal(true)}>
                    <AddCircleOutlineIcon sx={{width: 24, height: 24}} />
                    Adicionar
                  </ButtonModalConfigEveryone>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default CameraConfigReceiveEvent
