import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {BtnClose} from 'src/components/BtnClose'
import {useAuth} from 'src/contexts/AuthContext'
import {useCompany} from 'src/contexts/CompanyContext'
import CompaniesSelect from 'src/layouts/main-layout/header/components/CompaniesSelect'
import TransferList from 'src/pages/cameras/components/CameraAddModal/CameraRegisterModal/TransferList'
import {Item} from 'src/pages/cameras/components/CameraAddModal/CameraRegisterModal/TransferList/types'
import {TransferListData} from 'src/pages/layouts/components/LayoutCameraList'
import api, {Client, Company} from 'src/services/api'
import {AddUser, AuthorizedClientsBody, AuthorizedCompaniesBody, RoleType} from 'src/services/api/endpoints/UserEndpoint'
import createStyleSheet from 'src/utilities/createStyleSheet'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {isEmailLoginValid} from 'src/utilities/regexValidation'
import {RawUser, UpdateUser} from '..'
import {ConfirmationModal} from '../../../components/ConfirmationModal/ConfirmationModal'
import {AddButtonModal, CancelButtonModal} from '../styles'

type Authorizations = {
  companyIds: number[]
  clientIds: number[]
}

type UserRegisterModalProps = {
  open: boolean
  handleClose: () => void
  user: RawUser
  roles: Array<RoleType>
  addUser: (user: AddUser) => void
  isSaving: boolean

  editUser: (
    user: UpdateUser,
    companiesToAdd: AuthorizedCompaniesBody,
    companiesToRemove: AuthorizedCompaniesBody,
    clientsToAdd: AuthorizedClientsBody,
    clientsToRemove: AuthorizedClientsBody,
  ) => void
  isEdit: boolean
  companiesScroll: Company[]
  setCompaniesScroll: (companies: Company[]) => void
  pageCount: number
  setPageCount: (page: number) => void
  totalPage: number
  hasReadCompanyPermission?: boolean
  saveModalUsers: boolean
  setSaveModalUsers: (saveModalUsers: boolean) => void
  idUser?: number
}

function UserRegisterModal(props: UserRegisterModalProps) {
  const {open, handleClose, user, addUser, isSaving, editUser, isEdit, saveModalUsers, idUser, setSaveModalUsers, roles} = props

  const {user: userAuth} = useAuth()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()

  const [isConfirmed, setIsConfirmed] = useState<boolean>(true)

  const [userName, setUserName] = useState<string>('')
  const [userRoleType, setUserRoleType] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string>('')

  const [leftItems, setLeftItems] = useState<Item<Client>[]>([])
  const [rightItems, setRightItems] = useState<Item<Client>[]>([])
  const [authorizations, setAuthorizations] = useState<Authorizations>({companyIds: [], clientIds: []})
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<number[]>([])
  const [companiesScroll, setCompaniesScroll] = useState<Company[]>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const [companyNames, setCompanyNames] = useState<string[]>([])
  const [alreadyInitialized, setAlreadyInitialized] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const {companies} = useCompany()
  const currentUserRole = findRoleByName(userAuth?.role)
  const [labelTitle, setLabelTitle] = useState<string>('')
  const [selectedIdsZeroClients, setSelectedIdsZeroClients] = useState<number[]>([])
  const selectedIsMe = userAuth?.id === user.id
  const [blockClients, setBlockClients] = useState<boolean>(false)
  const [canSave, setCanSave] = useState<boolean>(false)

  const blockedClientsRoles = ['manager', 'admin']

  const getRolesAvailable = () => {
    const sortRoles = (roles: RoleType[]) => {
      return [...roles].sort((roleA, roleB) => {
        if (roleA.priority < roleB.priority) return 1

        return -1
      })
    }
    if (!currentUserRole) return []
    const rolesFilteredByMe = roles.filter((role) => role.priority <= currentUserRole.priority)
    if (selectedIsMe) return sortRoles(rolesFilteredByMe)
    const sortedRoles = sortRoles(roles)
    sortedRoles.shift()
    return sortedRoles
  }

  const defaultEmailValidationStyle: SxProps<Theme> = {
    width: '100%',
    '& label.Mui-focused': {
      color: '#1976d2',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#1976d2',
      },
    },
  }
  const [emailValidationStyle, setEmailValidationStyle] = useState<SxProps<Theme>>(defaultEmailValidationStyle)

  const onChangeTransferList = useCallback(
    (data: TransferListData) => {
      setLeftItems(data.leftItems)
      setRightItems(data.rightItems)
      const companyIds = selectedCompaniesIds
      const clientIds = Array.from(new Set<number>(data.rightItems.map((it) => it.data.id)))
      setAuthorizations({companyIds, clientIds})
    },
    [selectedCompaniesIds],
  )

  const handleUserEmailChange = (value: string) => {
    setUserEmail(value.toLowerCase())
    if (value.trim().length > 0)
      if (!isEmailLoginValid(value)) {
        setIsConfirmed(false)
        setEmailValidationStyle({
          width: '100%',
          '& label.Mui-focused': {
            color: '#f44336',
          },
          '& label': {
            color: '#f44336',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#f44336',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#f44336',
            },
          },
        })
      } else {
        setIsConfirmed(true)
        setEmailValidationStyle(defaultEmailValidationStyle)
      }
    else {
      setIsConfirmed(true)
      setEmailValidationStyle(defaultEmailValidationStyle)
    }
  }

  function clearFields() {
    setUserName('')
    setUserEmail('')
    setUserRoleType('')
    setCompanyNames([])
    setSelectedCompaniesIds([])
    setSelectedIdsZeroClients([])
    setLeftItems([])
    setRightItems([])
    setAuthorizations({companyIds: [], clientIds: []})
    setAlreadyInitialized(false)
    setIsLoading(true)
  }

  useEffect(() => {
    if (!isEdit) {
      setLabelTitle('Adicionar')
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    setUserName(user.name)
    setUserEmail(user.email)
    setUserRoleType(user.role)
    setSelectedCompaniesIds(user.authorizedCompanies)
    loadClients(user.authorizedCompanies)
    const userAuthorizedCompanyNames = companies.map((company) => {
      if (user.authorizedCompanies.includes(company.id)) {
        return company.name
      }
      return null
    })
    setCompanyNames(userAuthorizedCompanyNames.filter((it) => it !== null) as string[])
    setLabelTitle('Editar')
    return () => {
      clearFields()
    }
  }, [open])

  const populateSelector = useCallback(
    (clients: Client[]) => {
      const rightArray = []
      const leftArray = []
      for (const client of clients) {
        const {company} = client
        if (!company) continue
        const label = `${client.name} - ${company.name}`
        const alreadyInRight = rightItems.filter((it) => it.key === client.id.toString()).length !== 0
        const alreadyInLeft = leftItems.filter((it) => it.key === client.id.toString()).length !== 0
        if (!alreadyInLeft && !alreadyInRight) {
          leftArray.push({key: client.id.toString(), label: label, checked: false, data: client})
        } else if (alreadyInLeft && !alreadyInRight) {
          leftArray.push({key: client.id.toString(), label: label, checked: false, data: client})
        } else {
          rightArray.push({key: client.id.toString(), label: label, checked: false, data: client})
        }
      }
      setRightItems(rightArray)
      setLeftItems(leftArray)
      return leftArray
    },
    [leftItems, rightItems],
  )

  const loadClients = useCallback(
    async (userAuthorizedCompanies?: Array<number>) => {
      const controller = new AbortController()
      try {
        if (!open) return
        if (!selectedCompaniesIds.length && !userAuthorizedCompanies) {
          setLeftItems([])
          setRightItems([])
          setIsLoading(false)
          return
        }
        const companyIds = userAuthorizedCompanies || selectedCompaniesIds
        const response = await api.client.getMany({
          paginate: false,
          filter: {companyIds: companyIds},
          includes: ['company'],
        })
        const clientsFromResponse = response.data.data.entities

        setSelectedIdsZeroClients([])
        companyIds.forEach((id) => {
          const clientsFromCompany = clientsFromResponse.filter((client) => client.companyId === id)
          if (clientsFromCompany.length === 0) {
            setSelectedIdsZeroClients((prev) => Array.from(new Set([...prev, id])))
          }
        })
        const leftArray = populateSelector(clientsFromResponse)
        if (alreadyInitialized && user.role === 'manager') {
          setLeftItems([])
          setRightItems((prev) => [...prev, ...leftArray])
        }
        if (!isEdit || alreadyInitialized) return
        const selectedCompanyNames = Array<string>()
        const selectedCompanyIds = Array<number>()
        companies.forEach((it) => {
          if (user.authorizedCompanies.includes(it.id)) {
            selectedCompanyNames.push(it.name)
            selectedCompanyIds.push(it.id)
          }
        })
        setCompanyNames(selectedCompanyNames)
        const indexToMaintain = leftArray.map((it, index) => {
          if (user.authorizedClients.includes(Number(it.key))) {
            setRightItems((prev) => [...prev, it])
            return null
          }
          return index
        })
        const newLeftItems = leftArray.filter((it, index) => indexToMaintain.includes(index))
        if (user.role === 'manager') {
          setLeftItems([])
          setRightItems((prev) => [...prev, ...leftArray])
        } else {
          setLeftItems(newLeftItems)
        }
        setAuthorizations({companyIds: selectedCompanyIds, clientIds: user.authorizedClients})
        setAlreadyInitialized(true)
        setIsLoading(false)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar clientes')
      }
      return () => controller.abort()
    },
    [alreadyInitialized, companies, enqueueSnackbar, isEdit, open, populateSelector, selectedCompaniesIds],
  )

  useEffect(() => {
    if (!alreadyInitialized && isEdit) return
    loadClients()
  }, [selectedCompaniesIds])

  function mapRoles(role: RoleType, index: number) {
    return (
      <MenuItem key={`role-${role.name}-${index}`} value={role.name}>
        {role.label}
      </MenuItem>
    )
  }

  function userCanBeEdited(targetUser: UpdateUser) {
    if (!isEdit) return true
    if (userAuth?.role === 'admin') return true
    if (!currentUserRole) return false
    if (!targetUser.id) return true

    const targetRole = findRoleByName(targetUser.role)
    if (!targetRole) return true
    if (targetRole.label === 'manager') return false
    if (targetRole?.priority > currentUserRole?.priority) return false
    return true
  }

  function findRoleByName(roleName?: string): RoleType | undefined {
    return roles.find((role) => role.name === roleName)
  }

  useEffect(() => {
    if (blockedClientsRoles.includes(userRoleType)) {
      setRightItems((prev) => [...prev, ...leftItems])
      setLeftItems([])
      setBlockClients(true)
    } else {
      setBlockClients(false)
    }
  }, [userRoleType])

  async function handleEdit() {
    const companyIds: number[] = []
    rightItems.forEach((it) => {
      const companyId = it.data?.companyId
      if (companyId && !companyIds.includes(companyId)) companyIds.push(companyId)
    })
    companyIds.push(...selectedIdsZeroClients)
    const userData: Partial<AddUser> = {
      name: userName,
      email: userEmail,
      role: userRoleType,
    }
    Object.entries(userData).forEach(([key, value]) => {
      if (user[key as keyof AddUser] === value) userData[key as keyof Partial<AddUser>] = undefined
      if ((key === 'authorizedCompanies' || key === 'authorizedClients') && selectedIsMe) userData[key as keyof Partial<AddUser>] = undefined
    })

    try {
      const companiesToAdd: AuthorizedCompaniesBody = {companyIds: companyIds.filter((it) => !user.authorizedCompanies.includes(it))}
      const companiesToRemove: AuthorizedCompaniesBody = {companyIds: user.authorizedCompanies.filter((it) => !companyIds.includes(it))}
      const clientsToAdd: AuthorizedClientsBody = {clientIds: authorizations.clientIds.filter((it) => !user.authorizedClients.includes(it))}
      const clientsToRemove: AuthorizedClientsBody = {clientIds: user.authorizedClients.filter((it) => !authorizations.clientIds.includes(it))}
      if (companiesToRemove.companyIds.length === user.authorizedCompanies.length && companiesToAdd.companyIds.length === 0)
        return enqueueSnackbar('Usuário não pode ficar sem empresas', {variant: 'error'})
      editUser(userData, companiesToAdd, companiesToRemove, clientsToAdd, clientsToRemove)
      setTimeout(() => {
        clearFields()
      }, 800)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao editar usuário')
    }
  }

  async function handleCreate() {
    const authorizedClients = blockedClientsRoles.includes(userRoleType) ? undefined : authorizations.clientIds.length ? authorizations.clientIds : undefined
    const userData = {
      name: userName,
      email: userEmail,
      role: userRoleType,
      authorizedCompanies: selectedCompaniesIds,
      authorizedClients,
    }
    addUser(userData)
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!isConfirmed || userRoleType === '') return
    if (isEdit) {
      return await handleEdit()
    }
    await handleCreate()
  }

  useEffect(() => {
    if (!isEdit) {
      setCanSave(
        userName.trim() === '' ||
          !isEmailLoginValid(userEmail) ||
          (selectedIdsZeroClients.length === 0 && rightItems.length === 0 && !blockClients) ||
          !userCanBeEdited(user) ||
          userRoleType === '',
      )
    }
    const hasDifference =
      userEmail !== user.email ||
      userName !== user.name ||
      userRoleType !== user.role ||
      user.authorizedClients !== authorizations.clientIds ||
      (user.role === 'manager' && user.authorizedCompanies !== selectedCompaniesIds)
    setCanSave(!hasDifference || !isEmailLoginValid(userEmail) || !userCanBeEdited(user) || userName.trim() === '' || userRoleType === '')
  }, [userName, userEmail, userRoleType, rightItems, blockClients])

  return (
    <>
      {!saveModalUsers ? (
        <Dialog
          open={open}
          onClose={() => {
            clearFields()
            handleClose()
          }}
          scroll='paper'
          maxWidth='lg'
          fullWidth>
          <DialogTitle sx={styles.dialogTitle}>{labelTitle} Usuário</DialogTitle>
          <BtnClose
            onClose={() => {
              handleClose()
              setAlreadyInitialized(false)
              setRightItems([])
              setLeftItems([])
            }}
          />

          <form onSubmit={(event) => handleSubmit(event)}>
            <DialogContent>
              <Grid
                container
                spacing={2}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  paddingLeft: '15px',
                }}>
                <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                  <Grid item sx={{width: '100%', paddingBottom: '6px'}}>
                    <Box sx={styles.gridItem}>
                      <Box>
                        <TextField
                          label='Nome'
                          variant='outlined'
                          type={'text'}
                          value={userName}
                          autoFocus={true}
                          disabled={!userCanBeEdited(user)}
                          sx={{width: '565px'}}
                          onChange={(e) => {
                            const value = e.target.value
                            if (value.length < 100) setUserName(value)
                          }}
                        />
                      </Box>
                      <Box>
                        <FormControl fullWidth>
                          <InputLabel>Tipo</InputLabel>
                          <Select
                            label='Tipo'
                            sx={{width: '565px'}}
                            disabled={!userCanBeEdited(user) || selectedIsMe}
                            value={userRoleType}
                            onChange={(e) => {
                              setUserRoleType(e.target.value as any)
                            }}>
                            {getRolesAvailable().map(mapRoles)}
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Grid>
                  <Box sx={styles.gridItem}>
                    <Box
                      sx={{
                        flexDirection: 'column',
                        width: '565px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}>
                      <TextField
                        label='Login'
                        variant='outlined'
                        type={'text'}
                        value={userEmail}
                        sx={emailValidationStyle}
                        disabled={!userCanBeEdited(user)}
                        onChange={(e) => handleUserEmailChange(e.target.value)}
                      />
                    </Box>
                    <Box sx={{width: '565px'}}>
                      <CompaniesSelect
                        selectLabel='Empresa(s)'
                        selectSize='medium'
                        setSelectedCompaniesIds={setSelectedCompaniesIds}
                        companiesScroll={companiesScroll}
                        setCompaniesScroll={setCompaniesScroll}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        companyNames={companyNames}
                        setCompanyNames={setCompanyNames}
                        multiple={true}
                        blockUnselect={true}
                        defaultValueIds={[]}
                        disabled={!userCanBeEdited(user) || selectedIsMe}
                      />
                    </Box>
                  </Box>
                </Box>
                {isLoading ? (
                  <Box sx={{width: '100%', height: '52vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress disableShrink />
                  </Box>
                ) : (
                  <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px'}}>
                    <TransferList
                      leftItems={leftItems}
                      rightItems={rightItems}
                      onChange={onChangeTransferList}
                      leftTitle='Clientes disponíveis'
                      rightTitle='Clientes selecionados'
                      disabled={!userCanBeEdited(user) || selectedIsMe || blockClients}
                      objGender='o'
                    />
                  </Box>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Box>
                <Button
                  onClick={() => {
                    clearFields()
                    handleClose()
                  }}
                  disabled={!userCanBeEdited(user)}
                  sx={CancelButtonModal}>
                  Cancelar
                </Button>
                <LoadingButton type={'submit'} loading={isSaving} variant='contained' sx={AddButtonModal} disabled={canSave}>
                  Salvar
                </LoadingButton>
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      ) : (
        userRoleType !== '' &&
        userRoleType !== 'admin' && (
          <>
            <ConfirmationModal
              isOpen={saveModalUsers}
              title={'Configurar permissões do usuário'}
              content={'Recomendamos definir as permissões do usuário antes de prosseguir.'}
              onClose={() => {
                setSaveModalUsers(false)
                handleClose()
                clearFields()
              }}
              buttonsContent={[
                {
                  label: 'Definir depois',
                  onClick: () => {
                    setSaveModalUsers(false)
                    handleClose()
                    setCompanyNames([])
                    setAlreadyInitialized(false)
                    setRightItems([])
                  },
                  variant: 'outlined',
                  color: '#8E8E8E',
                  hoverColor: 'red',
                },
                {
                  label: 'Definir permissões',
                  onClick: () => {
                    navigate(`/registers/users/${idUser}`)
                  },
                  variant: 'contained',
                  color: '#009EFF',
                },
              ]}
            />
          </>
        )
      )}
    </>
  )
}

const styles = createStyleSheet({
  gridItem: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
    padding: '0px',
    marginTop: '10px',
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dialogTitle: {paddingTop: 3, color: '#353535'},
})

export default UserRegisterModal
