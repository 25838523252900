import styled from 'styled-components'
import {CalendarStyleProps} from '../../CalendarProps'

export const MonthPickerContainer = styled.div`
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 0;

  overflow-y: auto;
  height: 11.5rem;
  justify-content: center;
  align-items: center;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0rem 0.5rem;

  ::-webkit-scrollbar {
    width: ${({theme}) => theme.dropdown.scrollbarWidth};
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${({theme}) => theme.dropdown.scrollbarBackground};
    border-radius: 5px;
    border: 4px solid rgba(0, 0, 0, 0);
  }
`

export const MonthPickerContent = styled.div<CalendarStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
  height: 2rem;
  width: 5.33rem;
  border-radius: 8px;
  background-color: ${({isSelected, theme}) => (isSelected ? theme.calendar.selectedDayBackgroundColor : theme.calendar.backgroundColor)};
  color: ${({isSelected, theme}) => (isSelected ? theme.calendar.selectedDayColor : theme.calendar.color)};

  ${({isSelected, theme, isCurrentMonth}) => isCurrentMonth && !isSelected && `border: 1px solid ${theme.calendar.actualDateBorder}`};

  font-family: ${({theme}) => theme.calendar.fontFamily};
  font-size: ${({theme}) => theme.calendar.fontSize};
  font-weight: ${({theme}) => theme.calendar.fontWeight};

  ${({isCurrentMonth, isSelected, theme}) => isCurrentMonth && !isSelected && `border: 1px solid ${theme.calendar.actualDateBorder};`};

  ${({isCurrentMonth, isSelected, theme}) => isCurrentMonth && !isSelected && `color: ${theme.calendar.actualDateColor};`};

  ${({isCurrentMonth, isSelected, theme}) => isCurrentMonth && !isSelected && `background-color: ${theme.calendar.actualDateBackgroundColor};`};

  :hover {
    background-color: ${({theme, isSelected}) => !isSelected && theme.calendar.hoverBackgroundColor};
    color: ${({theme, isSelected}) => !isSelected && theme.calendar.hoverColor};
  }
`
