import {SxMap} from 'src/utilities/createStyleSheet'

const black = '#1D1E28'
const blue1 = '#009EFF'

export const interestAreaBottomStyles: SxMap = {
  background: {
    padding: 0,
    margin: 0,
    backgroundColor: black,
    width: '100%',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
  },
  iconButton: {
    fontSize: '15px',
    textTransform: 'initial',
    color: blue1,
    fontFamily: 'Inter',
  },
  addButton: {
    fontSize: '15px',
  },
  icon: {
    width: '20px',
    height: '20px',
    color: blue1,
  },
}
