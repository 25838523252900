import {Box, Card, CardContent, CircularProgress, Typography} from '@mui/material'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {BorderLinearProgress} from './BorderLinearProgress'

type LinearProgressCardProps = {
  title: string
  loading: boolean
  data: Array<{label: string; value: number}> | undefined
  height?: string
}

function LinearProgressCard({title, loading, data, height}: LinearProgressCardProps) {
  return (
    <Card sx={[styles.card, {height: height}]} variant='outlined'>
      <CardContent>
        <Typography style={styles.title}>{title}</Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box sx={{paddingTop: '10px'}}>
            {data?.map((it) => {
              return (
                <Box sx={{paddingTop: '20px'}}>
                  <Box style={styles.labels}>
                    <Typography style={styles.typographyLabels} sx={{color: '#364152'}}>
                      {it.label}
                    </Typography>
                    <Typography style={styles.typographyLabels} sx={{color: '#009EFF'}}>
                      {it.value ? it.value.toFixed(0) : 0}%
                    </Typography>
                  </Box>

                  <BorderLinearProgress variant='determinate' value={it.value} />
                </Box>
              )
            })}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  card: {
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    padding: '2px 10px',
  },
  title: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 700,
    color: '#364152',
    whiteSpace: 'nowrap',
    maxWidth: '100%',
    fontSize: '16px',
    textAlign: 'center',
  },
  value: {
    margin: '0px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '2.5rem',
    color: '#697586',
    fontWeight: 700,
    textAlign: 'center',
    padding: '10px',
  },
  labels: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  typographyLabels: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
})

export default LinearProgressCard
