import {Button} from '@viptech/react-components'
import ArrowLeftIcon from '../../images/selectables/ArrowLeftIcon'
import ArrowRightIcon from '../../images/selectables/ArrowRightIcon'
import Dropdown from '../dropdown/Dropdown'
import {DropdownItem} from '../dropdown/DropdownProps'
import {PaginationContainer, PaginationInnerContainer, PaginationNumberButton} from './PaginationBase'
import {PaginationProps} from './PaginationProps'
import CurrentItemsRange from './components/PageRange/PageRange'

function PaginationRoot(props: PaginationProps) {
  const {
    totalItems,
    itemsPerPage,
    control,
    possibleItemsPerPage,
    pageRange = true,
    width = '100%',
    fontSize = '14px',
    dropdownWidth = '80px',
    backgroundColor = '#fff',
    color = '#000',
    hoverColor = '#e0e0e0',
  } = props
  const totalPages = Math.ceil(totalItems / Number(itemsPerPage))
  const selectPossibleItems: DropdownItem[] = []
  if (possibleItemsPerPage) {
    possibleItemsPerPage.forEach((item) => selectPossibleItems.push({id: item.toString(), label: item.toString()}))
  }

  return (
    <PaginationContainer width={width} color={color}>
      {pageRange && (
        <div style={{display: 'inline-block'}}>
          <CurrentItemsRange totalItems={totalItems} currentPage={control.currentPage} itemsPerPage={Number(itemsPerPage)} />
        </div>
      )}
      {possibleItemsPerPage && possibleItemsPerPage.length > 0 && (
        <Dropdown
          width={dropdownWidth}
          height='38px'
          selected={{id: String(itemsPerPage), label: String(itemsPerPage)}}
          onChangeSelected={(e) => control.changePageSize(Number(e.id))}
          items={selectPossibleItems}
        />
      )}
      <PaginationInnerContainer justifyContent='space-evenly'>
        <Button
          width='5%'
          onClick={control.prevPage}
          variant='outlined'
          color='gray'
          disabled={control.currentPage === 1}
          leftIcon={<ArrowLeftIcon color={control.currentPage === 1 ? '#c6c6c6' : 'black'} />}
        />

        {[...Array(totalPages)].map((_, index) => (
          <PaginationNumberButton
            key={index}
            onClick={() => control.setCurrentPage(index + 1)}
            backgroundColor={control.currentPage === index + 1 ? backgroundColor : 'transparent'}
            color={control.currentPage === index + 1 ? color : 'darkgray'}
            hoverColor={hoverColor}
            fontSize={fontSize}>
            {index + 1}
          </PaginationNumberButton>
        ))}

        <Button
          width='5%'
          onClick={control.nextPage}
          variant='outlined'
          color='gray'
          leftIcon={<ArrowRightIcon color={control.currentPage * Number(itemsPerPage) >= totalItems ? '#c6c6c6' : 'black'} />}
          disabled={control.currentPage * Number(itemsPerPage) >= totalItems}
        />
      </PaginationInnerContainer>
    </PaginationContainer>
  )
}

export default PaginationRoot
