import {LoadingButtonProps} from '@mui/lab/LoadingButton'
import RealodIconSmallChangeColor from '../../images/ReloadIconGreenSmall'
import {ReloadButtonCustom} from './AppReloadButtonBase'

type AppReloadButtonProps = {
  loading: boolean
  onClick: () => void
  size?: number
  sx?: LoadingButtonProps['sx']
  LoadingButtonProps?: LoadingButtonProps
}

function AppReloadButton({onClick}: AppReloadButtonProps) {
  return (
    <ReloadButtonCustom onClick={onClick}>
      <RealodIconSmallChangeColor color='#009EFF' />
    </ReloadButtonCustom>
  )
}

export default AppReloadButton
