import ms from "ms";
import pkg from "../../../package.json";
const nodeEnv = process.env.NODE_ENV
const isDevelopment = nodeEnv === 'development';

const config = {
  version: pkg.version,
  eventInitTimeout: ms("30 minutes"),
  debugApi: isDevelopment,
  debug: false,
  debugKeys: 'the-power'
}

export default config
