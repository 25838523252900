import {Box, Checkbox, Table, TableData, TableHeaderColumn, TableHeaderRow, Typography} from '@viptech/react-components'
import RowHover from 'src/components/RowHover/RowHover'
import {useCompany} from 'src/contexts/CompanyContext'
import {RenderIfConditionIsMet} from 'src/utilities/RenderIfConditionIsMet'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ValidatePermissions} from '../../../common/utils/ValidatePermissions'
import CheckboxTable from '../../../components/CheckboxTable'
import Pagination from '../../../components/pagination/Pagination'
import {AnalyticCamera, useAnalyticCamerasContext} from './../context/AnalyticCamerasContext'
import {CamerasAnalyticTableOption} from './CamerasAnalyticTableOption'

type CamerasAnalyticGridTableProps = {
  openCameraModal: (camera: AnalyticCamera) => Promise<void>
  selectedCamerasIds: number[]
  setSelectedCamerasIds: (selectedCamerasIds: number[]) => void
  amountSelectedCameras: number
}

function CamerasAnalyticGridTable({openCameraModal, setSelectedCamerasIds, amountSelectedCameras, selectedCamerasIds}: CamerasAnalyticGridTableProps) {
  const {
    analyticCameras,
    page,
    isLoading,
    hasCreateCameraPermission,
    hasUpdateCameraPermission,
    deleteAnalyticCameras,
    totalCameras,
    setCurrentPage,
    setPageSize,
  } = useAnalyticCamerasContext()
  const hasDeleteAnalyticCameraPermission = ValidatePermissions('analytic-cameras:delete')
  const {companies} = useCompany()

  const control = {
    currentPage: page.page,
    setCurrentPage: setCurrentPage,
    changePageSize: setPageSize,
    nextPage: () => {
      setCurrentPage(page.page + 1)
    },
    prevPage: () => {
      setCurrentPage(page.page - 1)
    },
  }

  const cameraRows = analyticCameras.map((camera) => {
    return {
      id: camera.id,
      name: camera.name,
      client: camera.client ? camera.client.name : '-',
      address: camera.macAddress.toUpperCase(),
      company: companies.find((company) => company.id === camera.client?.companyId)?.name ?? '',
      self: camera,
    }
  })

  const columns = [
    {
      id: 'checkbox',
      name: '',
    },
    {
      id: 'name',
      name: 'Nome do Dispositivo',
    },
    {
      id: 'client',
      name: 'Cliente',
    },
    {
      id: 'company',
      name: 'Empresa',
    },
    {
      id: 'address',
      name: 'Endereço Físico',
    },
    {
      id: 'contextMenu',
      name: '',
    },
  ]
  const onSelectAll = () => {
    if (selectedCamerasIds.length) return setSelectedCamerasIds([])

    const allIds = cameraRows.map((row) => row.id)
    setSelectedCamerasIds(allIds)
  }

  const customHeader = (
    <thead>
      <TableHeaderRow>
        {columns.map((col) =>
          col.id === 'checkbox' ? (
            <TableHeaderColumn key={col.name}>
              <Checkbox id='check' checked={cameraRows.length ? selectedCamerasIds.length === cameraRows.length : false} onChange={onSelectAll} />
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn key={col.id}>{col.name}</TableHeaderColumn>
          ),
        )}
      </TableHeaderRow>
    </thead>
  )
  if (!(hasCreateCameraPermission && hasUpdateCameraPermission)) {
    columns.shift()
  }

  return (
    <Box style={styles.gridItem}>
      <Box flex={1} display={'flex'}>
        <Table columns={columns} isLoading={isLoading} skeletonRowsAmount={page.pageSize} tableHeaderChildren={customHeader}>
          {cameraRows.map((camera) => (
            <RowHover id={`row-${camera.id}`} key={`row-${camera.id}`}>
              <RenderIfConditionIsMet condition={hasCreateCameraPermission && hasUpdateCameraPermission}>
                <TableData>
                  <CheckboxTable id={camera.id} selectedIds={selectedCamerasIds} setSelectedIds={setSelectedCamerasIds} />
                </TableData>
              </RenderIfConditionIsMet>
              <TableData
                onClick={() => {
                  if (hasUpdateCameraPermission) {
                    openCameraModal(camera.self as unknown as AnalyticCamera)
                  }
                }}>
                <Typography variant='p' style={styles.links}>
                  {camera.name}
                </Typography>
              </TableData>
              <TableData>{camera.client}</TableData>
              <TableData>{camera.company}</TableData>
              <TableData>{camera.address}</TableData>
              <TableData>
                <CamerasAnalyticTableOption
                  oneObject={camera.id.toString()}
                  onClick={(event, oneObject) => {
                    if (event === 'delete') deleteAnalyticCameras([+oneObject])
                    else if (event === 'edit-camera') openCameraModal(camera.self as unknown as AnalyticCamera)
                  }}
                  hasDeleteCameraPermission={hasDeleteAnalyticCameraPermission}
                  hasUpdateCameraPermission={hasUpdateCameraPermission}
                />
              </TableData>
            </RowHover>
          ))}
        </Table>
      </Box>

      {!isLoading && cameraRows.length === 0 ? (
        <Box style={styles.noRegisters}>Nenhum registro encontrado</Box>
      ) : (
        <Box style={styles.pagination}>
          <Pagination.Root
            backgroundColor='#009EFF'
            color='white'
            totalItems={totalCameras!}
            itemsPerPage={page.pageSize}
            possibleItemsPerPage={[20, 50, 100]}
            control={control}
          />
        </Box>
      )}
    </Box>
  )
}
const styles = createStyleSheet({
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: '0px 20px',
    height: '100%',
    minHeight: '79vh',
  },
  links: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    marginRight: 1,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: '#00639f',
    },
  },
  noRegisters: {
    fontFamily: "'Inter', 'sans-serif'",
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 450,
    minHeight: '65vh',
    '@media(max-width:1540px)': {
      fontSize: '0.95em',
    },
  },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    alignSelf: 'flex-end',
    margin: '15px 0',
  },
})

export default CamerasAnalyticGridTable
