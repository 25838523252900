import {useState} from 'react'
import {CameraConfigProvider} from '../../camera-config/context/CameraConfigContext'
import CameraRtmpActions from '../camera-rtmp-actions/CameraRtmpActions'
import CameraRtmpArea from '../camera-rtmp-area/CameraRtmpArea'
import CameraRTMPConfiguration from '../camera-rtmp-configuration/CameraRTMPConfiguration'

function RtmpCamera() {
  const [cameraConfigId, setCameraConfigId] = useState<number>(0)
  const [cameraId, setCameraId] = useState<number>(0)
  const [step, setStep] = useState(0)
  const [areaIdCreated, setAreaIdCreated] = useState<Array<number>>([])

  return (
    <CameraConfigProvider>
      {step === 0 && <CameraRTMPConfiguration setCameraConfigId={setCameraConfigId} setCameraId={setCameraId} cameraConfigId={cameraConfigId} setPage={setStep} />}
      {step === 1 && <CameraRtmpArea cameraId={cameraId} setPage={setStep} setAreaIdCreated={setAreaIdCreated} />}
      {step === 2 && <CameraRtmpActions areaIdCreated={areaIdCreated} cameraId={cameraId} cameraConfigId={cameraConfigId} />}
    </CameraConfigProvider>
  )
}

export default RtmpCamera
