import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_EVENTS_BASE_URL

export interface AlarmStatus {
  id: number
  description: string
}

export class EventStatusEndpoint {
  public getAll(): Promise<AxiosResult<AlarmStatus[]>> {
    return axios.get(`${prefix}/event-statuses`)
  }
}
