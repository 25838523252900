import {BrainBox} from './BrainBoxEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Paginate} from './_types'

const prefix = process.env.REACT_APP_BRAINBOX_BASE_URL

type GetBrainBoxButtonControlParams = {
  includes?: string[]
  filter?: {
    status: string
  }
  brainBoxId: number
}

type StatusButtonControl = {
  id: number
  description: string
}

export type BrainBoxButtonControl = {
  id: number
  brainBox?: BrainBox
  code: string
  actionId: number
  statusId: number
  description?: string
  brainBoxId: number
  status: StatusButtonControl | undefined
}

export type BrainBoxUpdate = {
  actionId?: number
  description: string
}

export class BrainBoxButtonControlEndpoint {
  async getAll(brainBoxParams: GetBrainBoxButtonControlParams): Promise<AxiosResult<Paginate<BrainBoxButtonControl>>> {
    return await axios.get(`${prefix}/brain-box/buttons/all`, {
      params: prepareParams(brainBoxParams),
    })
  }

  async updateBrainBoxStatus(id: number, body: BrainBoxUpdate) {
    return await axios.patch(`${prefix}/brain-box/buttons/${id}`, body)
  }

  async delete(id: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/brain-box/buttons/${id}`)
  }

  async reboot(id: number) {
    return await axios.post(`${prefix}/brain-box/${id}/reboot`)
  }
}
