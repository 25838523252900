import {FormControlLabel, Switch} from '@mui/material'
import {Box, Column, Modal, Table, TableData} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ObjectData} from 'src/common/models/detection-objects/DetectionObjectsDTO'
import AppAddOrOptionsButton from 'src/components/AppAddOrOptionsButton'
import LinkWithAction from 'src/components/LinkWithAction'
import RowHover from 'src/components/RowHover/RowHover'
import api, {CameraHasObject, CameraObject, InterestAreaCam, ObjectType, Server} from 'src/services/api'
import {BrainBox} from 'src/services/api/endpoints/BrainBoxEndpoint'
import {LineCrossingObject} from 'src/services/api/endpoints/ObjectEndpoint'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import {useSessionStorage} from 'usehooks-ts'
import {ValidatePermissions} from '../../../../common/utils/ValidatePermissions'
import {ConfirmationModal} from '../../../../components/ConfirmationModal/ConfirmationModal'
import {useCameraConfigContext} from '../../context/CameraConfigContext'
import {RowType, deleteObject, objectDetectionRow, updateIsActive} from './ObjectDetectionFunctions'
import MenuMoreInformationsObject from './components/camera-modal-object-register/components/MoreInformationsObject'
import StepperModal from './newComponents/stepperModal/StepperModal'

export type ObjectDetectionProps = {
  objectType: ObjectType
  interestArea: InterestAreaCam
}

const columns: Column[] = [
  {
    id: 'typeObject',
    name: 'Tipo do Objeto',
  },
  {
    id: 'barrierType',
    name: 'Tipo da Barreira',
  },
  {
    id: 'barrier',
    name: 'Barreira',
  },
  {
    id: 'description',
    name: 'Descrição',
  },
  {
    id: 'confidence',
    name: 'Nível de Confiança',
  },
  {
    id: 'drive',
    name: 'Acionamento',
  },
  {
    id: 'active',
    name: 'Ativo',
  },
  {
    id: 'actions',
    name: 'Ações',
  },
]

export type ObjectActionType = {
  outputIntegrationId?: number
  triggerId?: number
  boxOutputDeviceId?: number
  telegramChatId?: number
  lprIntegrationId?: number
  listId?: number
  idToDelete: number
}

export type MappedActions = {
  id: number
  objectAction: ObjectActionType[]
}

export function ObjectDetection() {
  const [isActive, setIsActive] = useState(false)
  const [loadingObject, setLoadingObject] = useState(false)
  const [isCreate, setIsCreate] = useState(false)
  const [openObject, setOpenObject] = useState(false)
  const [getLoadObject, setGetLoadObject] = useState(false)
  const [selfObject, setSelfObject] = useState<CameraHasObject | LineCrossingObject>()
  const [, setLineCrossingObject] = useState<LineCrossingObject>()
  const [, setObjectItems] = useState<ObjectData[]>([])
  const [objectItemsInterest, setObjectItemsInterest] = useState<CameraHasObject[]>([])
  const [listLineCrossing, setListLineCrossing] = useState<Array<LineCrossingObject>>([])
  const {camera} = useCameraConfigContext()
  const {enqueueSnackbar} = useSnackbar()
  const [registeredObjects, setRegisteredObjects] = useState<ObjectDetectionProps[]>([])
  const [mappedActions, setMappedActions] = useState<MappedActions[]>([])
  const [allObjects, setAllObjects] = useState<ObjectType[]>([])
  const hasReadBrainBoxPermission = ValidatePermissions('brainbox:read')
  const clientId = camera?.clientId
  const [brainBox, setBrainBox] = useSessionStorage<BrainBox[]>(`BrainBox-client-${clientId}`, [])
  const [servers, setServers] = useSessionStorage<Server[]>(`Server-client-${clientId}`, [])
  const blockServersRequests = useRef(false)
  const [warningModalIsOpen, setWarningModalIsOpen] = useState(false)
  const [shouldUpdateTable, setShouldUpdateTable] = useState(0)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  function updateTable() {
    setShouldUpdateTable((prev) => prev + 1)
  }

  const getLoadObjects = () => setLoadingObject((prev) => !prev)

  const loadObjects = useCallback(async (configId: number, allObjects: CameraObject[]) => {
    try {
      setIsLoading(true)
      const cameraHasObjects = (
        await api.object.getInterestAreaObjects({
          configurationId: configId,
          includes: ['interestArea', 'objectType', 'trigger', 'faceLists', 'actions'],
        })
      ).data.data
      const selectedObjectItems = allObjects.map((it) => {
        const cameraHasObject = cameraHasObjects.find((itt) => it.id === itt.objectType?.id)
        const selected = cameraHasObject ? true : false
        return {
          key: it.name,
          label: it.label,
          data: {object: it, selected},
          selected,
          qtde: cameraHasObject ? cameraHasObject.threshold : 1,
        }
      })
      const response = (await api.object.getLineCrossingsObjects({configurationId: configId, includes: ['lineCrossing', 'objectType', 'actions']})).data
      setListLineCrossing(response.data)
      const filteredRegisteredObjects = cameraHasObjects.map((it) => {
        const objects = it.objectType
        const interestArea = it.interestArea
        return {
          objectType: objects,
          interestArea: interestArea!,
        }
      })
      const actions: MappedActions[] = [...cameraHasObjects, ...response.data].map((it) => {
        const objectAction: ObjectActionType[] = []
        if (it.actions) {
          it.actions.forEach((action) => {
            let boxOutputDeviceId = undefined
            if (it.trigger) {
              boxOutputDeviceId = it.trigger.find((trigger) => trigger.id === action.triggerId)?.boxOutputDeviceId
            }
            objectAction.push({
              outputIntegrationId: action.outputIntegrationId || undefined,
              triggerId: action.triggerId || undefined,
              listId: action.faceListId || action.licensePlateListId || (action.blockUnknown ? -1 : undefined),
              telegramChatId: action.telegramChatId || undefined,
              lprIntegrationId: action.lprIntegrationId || undefined,
              boxOutputDeviceId: boxOutputDeviceId || undefined,
              idToDelete: action.id,
            })
          })
        }

        const action = {
          id: it.id,
          objectAction,
        }
        return action
      })
      setMappedActions(actions)
      setObjectItems(selectedObjectItems)
      setObjectItemsInterest(cameraHasObjects)
      setRegisteredObjects(filteredRegisteredObjects)
    } catch {
    } finally {
      setIsLoading(false)

      setLoadingObject(false)
      setGetLoadObject(false)
    }
  }, [])

  useEffect(() => {
    if (!camera) return
    const controller = new AbortController()

    setListLineCrossing([])
    ;(async () => {
      try {
        const response = await api.object.getAllObjectTypes()
        setAllObjects(response.data.data)
        loadObjects(camera.configurationId, response.data.data)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Falha ao carregar objetos')
      }
    })()

    return () => controller.abort()
  }, [enqueueSnackbar, camera, loadObjects, loadingObject, getLoadObject, shouldUpdateTable])

  function handleRowClick(row: RowType) {
    setIsCreate(false)
    setLineCrossingObject(undefined)
    setSelfObject(row.self)
    setOpenObject(true)
  }

  const getAllBrainBoxes = useCallback(async () => {
    const clientId = camera?.clientId
    if (!hasReadBrainBoxPermission) return
    if (!clientId) return
    try {
      const response = await api.brainBox.getAll({
        includes: ['client', 'outputDevices'],
        filter: {clientIds: [clientId]},
      })
      setBrainBox(response.data.data.entities)
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar listagem de Brain Boxes')
    }
  }, [enqueueSnackbar, hasReadBrainBoxPermission])

  useEffect(() => {
    async function getOutputServers() {
      if (!camera) return
      if (blockServersRequests.current) return
      blockServersRequests.current = true
      const response = await api.server.findManyPaged({
        page: 1,
        pageSize: 20,
        filter: {integrationType: 'SAÍDA', companyIds: [camera.companyId]},
        includes: ['serverType'],
      })
      setServers(response.data.data.entities)
      blockServersRequests.current = false
    }
    getOutputServers()
    getAllBrainBoxes()
  }, [])

  return (
    <Box>
      <Box>
        <Box display='flex' justify='flex-end'>
          <ConfirmationModal
            isOpen={warningModalIsOpen}
            onClose={() => setWarningModalIsOpen(false)}
            title='Integração de saída não configurada'
            content={'O cliente desta câmera ainda não possui uma integração de saída. Deseja configurar uma agora?'}
            buttonsContent={[
              {
                label: 'Cancelar',
                onClick: () => setWarningModalIsOpen(false),
                variant: 'outlined',
                color: '#8E8E8E',
              },
              {
                label: 'Configurar',
                onClick: () => {
                  navigate(`/registers/clients?id=${clientId}`)
                },
                variant: 'contained',
                color: '#009EFF',
              },
            ]}
          />

          <Modal
            isOpen={openObject}
            padding='16px'
            paddingBottom='16px'
            paddingLeft='16px'
            paddingRight='16px'
            paddingTop='16px'
            children={
              <StepperModal
                closeModal={setOpenObject}
                registeredObjects={registeredObjects}
                allObjects={allObjects}
                isEdit={!isCreate}
                editingObject={selfObject}
                mappedActions={mappedActions}
                brainBox={brainBox}
                servers={servers}
                setWarningModalIsOpen={setWarningModalIsOpen}
                updateTable={updateTable}
              />
            }
            closeModal={() => setOpenObject(false)}
            backgroundColor='white'
            width='60%'
          />
          <AppAddOrOptionsButton
            text='Adicionar'
            onClickAdd={() => {
              setOpenObject(true)
              setIsCreate(true)
              setObjectItems((prev) =>
                prev.map((value) => {
                  value.selected = false
                  return value
                }),
              )
            }}
            showOptions={false}
          />
        </Box>
      </Box>
      <Box overflow='auto' height='500px' marginTop='20px' paddingBottom='60px'>
        <Table isLoading={isLoading} columns={columns} headerFontSize='14px' skeletonRowsAmount={3}>
          {objectDetectionRow(objectItemsInterest, listLineCrossing).map((row) => (
            <RowHover id={`${row.id}-${row.barrierType}`}>
              <TableData>
                <LinkWithAction onClick={() => handleRowClick(row)}>{row.typeObject}</LinkWithAction>
              </TableData>
              <TableData>{row.barrierType}</TableData>
              <TableData>{row.barrier}</TableData>
              <TableData>{row.description}</TableData>
              <TableData>{row.confidence}</TableData>
              <TableData>{row.drive}</TableData>
              <TableData>
                <Box width='100%' display='flex' justify='flex-start' align='center'>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={row.active}
                        onChange={async (e) => {
                          const checked = e.target.checked
                          await updateIsActive(checked, row, enqueueSnackbar, getLoadObjects)
                          setIsActive(!isActive)
                        }}
                        inputProps={{'aria-label': 'controlled'}}
                      />
                    }
                    label={undefined}
                  />
                </Box>
              </TableData>
              <TableData>
                <MenuMoreInformationsObject
                  oneObject={row.id}
                  onClick={(event) => {
                    if (event === 'edit') {
                      setIsCreate(false)
                      setLineCrossingObject(undefined)
                      setSelfObject({...row.self})
                      setOpenObject(true)
                    }
                    if (event === 'delete') {
                      deleteObject(row.self, getLoadObjects, enqueueSnackbar)
                    }
                  }}
                />
              </TableData>
            </RowHover>
          ))}
        </Table>
      </Box>
    </Box>
  )
}
