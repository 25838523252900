import {Box, Typography} from '@mui/material'
import {ImageAlarm, PercentageFacial} from 'src/pages/alarms/components/alarms-modal/AlarmsModalBase'
import {FacialDTO} from 'src/common/models/facial-recognition/FacialDTO'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import useImage from 'use-image'
import {useAlarmsContext} from 'src/pages/alarms/contexts/AlarmsContext'

type RegisteredFaceProps = {
  id: number
  accuracy: number
  imageKey: string | null
  cropImageKey: string | null
  face: FacialDTO
  getSelectedImage: (selectedImage: string, detections?: Array<any>) => void
  getIsDetectionAccurate?: (isDetectionAccurate?: boolean) => void
}

export const RegisteredFace = (registeredFaceProps: RegisteredFaceProps) => {
  const percentage = (registeredFaceProps.accuracy * 100).toFixed().toString().concat('%')
  const {'1': imageKey} = useImage(`${process.env.REACT_APP_FACE_IMAGES_BASE_URL}/${registeredFaceProps.cropImageKey}`)
  const {'1': facialImageKey} = useImage(`${process.env.REACT_APP_FACE_IMAGES_BASE_URL}/${registeredFaceProps.face.cropImageKey}`)
  const {snapshotsEvents} = useAlarmsContext()

  const handleSetImageDemarcated = async (imageKey: string | null) => {
    const snapshotEvent = snapshotsEvents.find((value) => value.eventId === registeredFaceProps.id)
    const src = await `${process.env.REACT_APP_EVENT_IMAGES_BASE_URL}/${imageKey}`
    registeredFaceProps.getSelectedImage(
      src,
      snapshotEvent?.demarcatedAreas.map((it) => ({
        x: it.startX,
        y: it.startY,
        width: it.endX,
        height: it.endY,
        data: it,
      })),
    )
  }

  const NotImage = () => {
    return (
      <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'165px'} height={'218px'}>
        <ImageNotSupportedIcon fontSize='large' />
      </Box>
    )
  }

  return (
    <Box rowGap={'32px'} display={'flex'} flexDirection={'column'}>
      <Box flexDirection={'row'} display={'flex'} marginTop={'40px'} columnGap={'32px'}>
        {imageKey === 'loaded' ? (
          <Box sx={{cursor: 'pointer'}}>
            <ImageAlarm
              src={`${process.env.REACT_APP_FACE_IMAGES_BASE_URL}/${registeredFaceProps.cropImageKey}`}
              onClick={async () => await handleSetImageDemarcated(registeredFaceProps.imageKey)}
            />
          </Box>
        ) : (
          <NotImage />
        )}
        {facialImageKey === 'loaded' ? (
          <ImageAlarm src={`${process.env.REACT_APP_FACE_IMAGES_BASE_URL}/${registeredFaceProps.face.cropImageKey}`} />
        ) : (
          <NotImage />
        )}
      </Box>
      <Box flexDirection={'row'} display={'flex'} alignItems={'center'} columnGap={'12px'}>
        <PercentageFacial>
          <Box width={percentage} height={'10px'} sx={{backgroundColor: '#009EFF'}} borderRadius={'10px'} />
        </PercentageFacial>
        <Typography>{percentage}</Typography>
      </Box>
      <Box>
        <Typography>{`Nome: ${registeredFaceProps.face.name}`}</Typography>
      </Box>
    </Box>
  )
}
