import styled from 'styled-components'
import {ButtonProps, IconContainerProps} from './ButtonProps'
import {isColorValid} from 'src/common/utils/validateColor'

const standardGapRatio = 2
const standardWidthRatio = 8.75
const standardHeightRatio = 2.5

export const ButtonIconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({size}) => (size ? (size > 16 ? `${size}px` : '1em') : '1em')};
  height: ${({size}) => (size ? (size > 16 ? `${size}px` : '1em') : '1em')};
`

export const ButtonBase = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${({shape}) => (shape === 'rounded' ? '5em' : shape === 'squared' ? `none` : shape)};

  font-size: ${({fontSize, size}) => (size ? (size > 16 ? `${size}px` : fontSize ?? `16px`) : fontSize ?? `16px`)};
  font-weight: 500;

  width: ${({width, size}) => (size ? (size > 16 ? `${standardWidthRatio * size!}px` : width ?? 'auto') : width ?? 'auto')};
  height: ${({height, size}) => (size ? (size > 16 ? `${standardHeightRatio * size!}px` : height ?? 'auto') : height ?? 'auto')};

  ${(props) => props.margin && `margin: ${props.margin};`};
  ${(props) => props.marginTop && `margin-top: ${props.marginTop};`};
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft};`};
  ${(props) => props.marginRight && `margin-right: ${props.marginRight};`};
  ${(props) => props.marginBottom && `margin-bottom: ${props.marginBottom};`};

  ${(props) => props.padding && `padding: ${props.padding};`};
  ${(props) => props.padding && `padding: ${props.padding};`};
  ${(props) => props.paddingTop && `padding-top: ${props.paddingTop};`};
  ${(props) => props.paddingLeft && `padding-left: ${props.paddingLeft};`};
  ${(props) => props.paddingRight && `padding-right: ${props.paddingRight};`};
  ${(props) => props.paddingBottom && `padding-bottom: ${props.paddingBottom};`};

  cursor: pointer;
  column-gap: ${({size}) => (size && size > 16 ? size / standardGapRatio : 8)}px;
  transition: all 0.3s ease-in-out;

  ${({color, variant, disabled, isLoading}) =>
    disabled && !isLoading
      ? variant && variant === 'outlined'
        ? `background-color: #fff; 
           border: 1px solid black;
           color: black;
           cursor: auto;`
        : `background-color: black; 
           border: 1px solid black;
           color: #fff; 
           cursor: auto;`
      : !isLoading && variant && variant === 'outlined'
        ? `background-color: #fff; 
         border: 1px solid ${color && isColorValid(color) ? color : ''}; 
         color: ${color && isColorValid(color) ? color : ''}; `
        : `background-color: ${color && isColorValid(color) ? color : ''};
         border: 1px solid ${color && isColorValid(color) ? color : ''};
         color: #fff;`};

  ${({hoverColor, variant, color, isLoading}) =>
    isLoading && variant && variant === 'outlined'
      ? `background-color: ${color && isColorValid(color) ? color : ''};
           border: 1px solid ${color && isColorValid(color) ? color : ''}; 
           color: #fff;
           cursor: auto;`
      : isLoading &&
        `background-color: ${hoverColor && isColorValid(hoverColor) ? hoverColor : ''};
           border: 1px solid ${hoverColor && isColorValid(hoverColor) ? hoverColor : ''};
           color: #fff;
           cursor: auto;`};

  &:hover:not([disabled]) {
    ${({hoverColor, variant, color}) =>
      variant && variant === 'outlined'
        ? `background-color: ${color && isColorValid(color) ? color : ''};
           border: 1px solid ${color && isColorValid(color) ? color : ''}; 
           color: #fff;`
        : `background-color: ${hoverColor && isColorValid(hoverColor) ? hoverColor : ''};
           border: 1px solid ${hoverColor && isColorValid(hoverColor) ? hoverColor : ''};
           color: #fff;`};
  }
`
