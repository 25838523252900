import {useSnackbar} from 'notistack'
import {createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useCompany} from 'src/contexts/CompanyContext'
import {Page} from 'src/types'
import {isValidUuid} from 'src/utilities/regexValidation'
import {useDebounce} from 'usehooks-ts'

import api, {Transaction} from '../../../services/api'
import handleErrorWithSnackbar from '../../../utilities/handleErrorWithSnackbar'

const transactionStatus = ['Detecção de Pessoas']

export interface CameraImage {
  cameraName: string
  mediaType: string
  base64String: string
  state: 'LOADING' | 'ERROR' | 'COMPLETED'
}

type RowsTransaction = {
  id: string
  status: string
  createdAt: string
}

type ColumnsTransaction = {
  field: string
  headerName: string
  minWidth: number
  maxWidth?: number
  flex?: number
}

type ITransactionsContext = {
  // transactions
  loadingTransactions: boolean
  reloadingTransactions: boolean
  transactions: Transaction[]
  transactionsPage: Page
  transactionsTotalCount: number
  reloadTransactions: () => void
  transactionsPageChange: (page: number) => void
  // search
  transactionSearch: string
  setTransactionSearch: (text: string) => void
  // transaction
  isTransactionModalOpen: boolean
  loadingTransaction: boolean
  transaction: Transaction | null
  openTransactionModal: (transactionId: string) => Promise<void>
  closeTransactionModal: () => void
  setTransactions: (transaction: Transaction[]) => void

  setLoadingTransactions: Dispatch<SetStateAction<boolean>>

  rows: RowsTransaction[]
  columns: ColumnsTransaction[]

  imageSelected: string
  setImageSelected: (image: string) => void

  openFilter: boolean
  setOpenFilter: (x: boolean) => void
  handleFilterClick: () => void

  transactionStatusFilter: boolean
  toggleStatusFilter: () => void
  transactionStatus: string[]
}

const TransactionsContext = createContext({} as ITransactionsContext)

function TransactionsContextProvider({children}: any) {
  const {enqueueSnackbar} = useSnackbar()

  const [loadingTransactions, setLoadingTransactions] = useState(true)
  const [reloadingTransactions, setReloadingTransactions] = useState(false)
  const [reloadTransactionCount, setReloadTransactionCount] = useState(0)
  const [rows, setRows] = useState<RowsTransaction[]>([])
  const [_transactions, setTransactions] = useState<Transaction[]>([])
  const [transactionsPage, setTransactionsPage] = useState<Page>({page: 1, pageSize: 20})
  const [transactionsTotalCount, setTransactionsTotalCount] = useState<number>(0)
  const [columns, setColumns] = useState<ColumnsTransaction[]>([])
  const [openFilter, setOpenFilter] = useState<boolean>(false)
  const [transactionStatusFilter, _setTransactionStatusFilter] = useState<boolean>(false)

  function getTransactionStatus(status: string): string {
    switch (status) {
      case 'PROCESSING':
        return 'Processando'
      case 'MISSED':
        return 'Expirado'
      default:
        return 'Erro'
    }
  }

  const transactions = useMemo(() => {
    setRows(
      _transactions.map((transaction) => {
        const createdAt = new Date(transaction.createdAt)
        return {
          createdAt: createdAt.toLocaleString(),
          id: transaction.transactionId,
          status: getTransactionStatus(transaction.status),
        }
      }),
    )
    return _transactions
  }, [_transactions])

  const [isTransactionModalOpen, setIsTransactionModalOpen] = useState(false)

  const [loadingTransaction, setLoadingTransaction] = useState(false)
  const [transaction, setTransaction] = useState<Transaction | null>(null)

  const [transactionSearch, setTransactionSearch] = useState('')
  const transactionSearchDebounced = useDebounce(isValidUuid(transactionSearch) ? transactionSearch : '', 500)
  const [imageSelected, setImageSelected] = useState('')

  const {selectedCompanies} = useCompany()
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    async function openTransactionModal(transactionId: string) {
      setIsTransactionModalOpen(true)
      setLoadingTransaction(true)
      try {
        const response = await api.transaction.getById(transactionId)
        setTransaction(response.data)
        const _response = await api.snapshotIngester.snapshot(response.data.transactionId)
        setImageSelected(`data:image/jpeg;base64,${_response.data.data.base64}`)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar os dados do imagem')
      } finally {
        setLoadingTransaction(false)
      }
    }

    const params = new URLSearchParams(location.search)
    if (params.has('id')) {
      const id = params.get('id')!
      openTransactionModal(id)
    } else {
      setLoadingTransaction(false)
    }
  }, [location, enqueueSnackbar])

  useEffect(() => {
    if (transaction !== null && isTransactionModalOpen) {
      if (location.search.length === 0) {
        navigate(`/transactions?id=${transaction.transactionId}`)
      }
    }
  }, [transaction, location, isTransactionModalOpen, navigate])

  useEffect(() => {
    setColumns([
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 350,
        flex: 1,
      },
      {
        field: 'status',
        headerName: 'Status',
        minWidth: 150,
        flex: 1,
      },
      {
        field: 'createdAt',
        headerName: 'Criação',
        minWidth: 150,
        flex: 1,
      },
    ])
  }, [isTransactionModalOpen])

  useEffect(() => {
    async function loadTransactions() {
      closeTransactionModal()
      try {
        const response = await api.transaction.getMany({
          page: transactionsPage.page === 0 ? 1 : transactionsPage.page,
          pageSize: 100,
          filter: {
            ids: [transactionSearchDebounced],
            onlyDetection: transactionStatusFilter,
            companyIds: selectedCompanies,
          },
          orderBy: {
            createdAt: 'DESC',
          },
        })
        setTransactionsTotalCount(response.data.totalElements)
        setTransactions(response.data.entities)
      } catch (error) {
        handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar imagens')
      } finally {
        setLoadingTransactions(false)
        setReloadingTransactions(false)
      }
    }

    loadTransactions()
  }, [reloadTransactionCount, transactionSearchDebounced, transactionsPage, enqueueSnackbar, transactionStatusFilter, selectedCompanies])

  async function openTransactionModal(transactionId: string) {
    if (loadingTransaction) return
    navigate(`/transactions?id=${transactionId}`)
  }

  function closeTransactionModal() {
    setIsTransactionModalOpen(false)
    setTransaction(null)
    navigate('/transactions')
  }

  const reloadTransactions = () => {
    if (loadingTransaction) return
    setReloadingTransactions(true)
    setReloadTransactionCount((x) => x + 1)
  }

  const handleFilterClick = () => {
    setOpenFilter(!openFilter)
  }

  function toggleStatusFilter() {
    _setTransactionStatusFilter(!transactionStatusFilter)
  }

  const transactionsPageChange = (page: number) => setTransactionsPage((lastPage) => ({...lastPage, page}))

  return (
    <TransactionsContext.Provider
      value={{
        transactionStatus,
        transactionStatusFilter,
        toggleStatusFilter,
        handleFilterClick,
        setOpenFilter,
        openFilter,
        transactions,
        transactionsTotalCount,
        transactionsPage,
        transactionsPageChange,
        transactionSearch,
        setTransactionSearch,
        reloadTransactions,
        reloadingTransactions,
        loadingTransactions: loadingTransactions && !reloadingTransactions,
        transaction,
        loadingTransaction,
        isTransactionModalOpen,
        openTransactionModal,
        closeTransactionModal,
        setLoadingTransactions,
        setTransactions,
        rows,
        columns,
        imageSelected,
        setImageSelected,
      }}>
      {children}
    </TransactionsContext.Provider>
  )
}

function useTransactionsContext() {
  return useContext(TransactionsContext)
}

export {useTransactionsContext}
export default TransactionsContextProvider
