import styled from 'styled-components'

export const Paragraph = styled.div`
  display: inline-block;
  width: 300px;
  height: 2em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  float: left;
`
