import {Box, Detection} from 'src/common/types/generics/ImageDetections'
import {boxToPoints} from 'src/common/utils/Internal'

export function handlePointsDemarcatedArea(internalScale: number, detection: Detection, box: Box, typeDemarcatedArea?: string) {
  if (typeDemarcatedArea === 'face') {
    return [
      detection.y * internalScale,
      detection.x * internalScale,
      detection.height * internalScale,
      detection.x * internalScale,
      detection.height * internalScale,
      detection.width * internalScale,
      detection.y * internalScale,
      detection.width * internalScale,
      detection.y * internalScale,
      detection.width * internalScale,
    ]
  } else return boxToPoints(box)
}
