import {Permission} from 'src/common/utils/PermissionsList'
import {useAuth} from 'src/contexts/AuthContext'
import RedirectTo from '../pages/redirect/RedirectTo'

interface PrivateRouteProps {
  permissions: Permission[]
  component: any
  disabled?: boolean
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({permissions, component: Component, disabled}) => {
  const {user} = useAuth()
  if (disabled) return <RedirectTo user={user} />
  const userPermissions = user?.permissions
  const hasPermissions = permissions.every((permission) => userPermissions?.includes(permission.name)) ? <Component /> : <RedirectTo user={user} />

  return hasPermissions
}
