import {AxiosErrorGeneric, RequestError} from './RequestError'

export type VariantErrorType = 'error' | 'warning'

function handleErrorWithSnackbar(snackbar: Function, error: unknown, message: string, variant?: VariantErrorType) {
  const err = error as AxiosErrorGeneric

  if (err.response) {
    snackbar(`${message}: ${RequestError(err)}`, {
      variant: variant ?? 'error',
    })
  } else {
    snackbar(message, {variant: 'error'})
    console.error(error)
  }
}

export default handleErrorWithSnackbar
