import styled from 'styled-components'

export const ContainerBaseInput = styled.div`
  border-radius: 6px;
  border: 1px solid #9aa4b2;
  background: #fff;
  height: 40px;
  width: 440px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`
export const ButtonSelected = styled.div`
  border-radius: 8px;
  padding: 6px 40px 6px 20px;
  border: 1px solid #009eff;
  background: rgba(0, 158, 255, 0.05);
  box-shadow: 0px 1px 2px 0px rgba(18, 25, 38, 0.05);
  color: #1976d2;
  min-width: 150px;
  min-height: 30px;

  position: relative;
  display: flex;
`

export const TransparentButton = styled.button`
  background: transparent;
  color: #433942;
  height: 30px;
  font-size: 12px;
  min-width: 120px;
  align-self: center;
  align-items: center;
  margin-bottom: 15px;
`
export const MenuContainer = styled.div`
  padding: 15px 0px;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  gap: 10px;
  border: 1px solid #9aa4b2;
  border-radius: 8px;
`

export const ItemOnMenu = styled.div`
  color: '#4B5565';
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 4px;
  :hover {
    background-color: #f5f5f5;
  }
`

export const CloseButtonFilter = styled.button`
  background: none;
  position: absolute;
  right: 9px;
  top: 1px;
`

export const LabelOnButton = styled.p`
  font-size: 12px;
  color: #1976d2;
`
