import {Box, Button, DropdownItem, Input, Modal, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {BtnClose} from 'src/components/BtnClose'
import api, {Camera} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'
import Dropdown from '../../../../../../components/dropdown/Dropdown'
import useFaceList from './hooks/useFaceList'

type AddFaceModalEventProps = {
  isOpenModal: boolean
  closeModal: () => void
  nameFromEvent: string
  image: string
  camera: Camera
  imageId: number
}

export const AddFaceModalEvent = ({isOpenModal, closeModal, nameFromEvent, image, camera, imageId}: AddFaceModalEventProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const [selectedList, setSelectedList] = useState<DropdownItem>({label: '', id: ''})
  const companyIdFromCamera = camera.companyId
  const {lists, isLoading} = useFaceList({companyId: companyIdFromCamera})
  const [listItems, setListItems] = useState<DropdownItem[]>([])
  const [name, setName] = useState('')

  useEffect(() => {
    setListItems(lists.map((list) => ({label: list.name, id: `${list.id}`})))
  }, [lists])

  async function saveFace() {
    try {
      const faceDTO = {
        id: imageId,
        name,
        listId: +selectedList.id,
      }
      await api.facialRecognition.updateFacial(faceDTO)
      enqueueSnackbar('Face criada com sucesso', {variant: 'success'})
      closeModal()
    } catch (err) {
      handleErrorWithSnackbar(enqueueSnackbar, err, 'Erro ao cadastrar face')
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        closeModal={closeModal}
        width='500px'
        height='305px'
        backgroundColor='white'
        paddingBottom='24px'
        paddingLeft='24px'
        paddingRight='24px'
        paddingTop='24px'>
        <Typography variant='h4'>Adicionar face à lista</Typography>
        <BtnClose onClose={closeModal} />
        <Box width='100%' display='flex' direction='column' rowGap='12px' paddingTop='12px'>
          <Dropdown label='Lista' items={listItems} onChangeSelected={(item) => setSelectedList(item)} isLoading={isLoading} />
          <Box display='flex' direction='column' rowGap='6px'>
            <Typography variant='span' size='0.875rem'>
              Nome completo
            </Typography>
            <Input.Root defaultValue={nameFromEvent} onChange={(e) => setName(e.target.value)} />
          </Box>
        </Box>
        <Box display='flex' justifyContent='flex-end' columnGap='15px' marginTop='20px' marginRight='0px'>
          <Button onClick={closeModal} variant='outlined' width='150px' height='40px' marginBottom='0' fontFamily='Inter' boxShadow='none'>
            Cancelar
          </Button>
          <Button
            variant='contained'
            disabled={!name || !selectedList.id}
            disabledBgColor='#9E9E9E'
            onClick={async () => {
              await saveFace()
              closeModal()
            }}
            width='150px'
            height='40px'
            fontFamily='Inter'
            boxShadow='none'>
            Salvar
          </Button>
        </Box>
      </Modal>
    </>
  )
}
