import {Box, Button, Input, Modal, Typography} from '@viptech/react-components'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {Icon} from 'src/common/generics/components/icon/IconBase'
import CloseIcon from 'src/images/closeBlueIcon.svg'
import {isValidLicensePlate} from 'src/utilities/regexValidation'
import Dropdown from '../../../../components/dropdown/Dropdown'
import {DropdownItem} from '../../../../components/dropdown/DropdownProps'
import useListLPR from '../../hooks/useListLPR'
import {patchLicensePlate} from './AddPlateToListFunctions'

type AddPlateToListModalProps = {
  isOpenModal: boolean
  closeModal: () => void
  plateLpr: string
  companyId: number
}

export const AddPlateToListModal = ({isOpenModal, closeModal, plateLpr, companyId}: AddPlateToListModalProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const [selectedList, setSelectedList] = useState<DropdownItem>({label: '', id: ''})
  const {lists, isLoading, refresh} = useListLPR({companyId})
  const [listItems, setListItems] = useState<DropdownItem[]>([])
  const [description, setDescription] = useState('')

  useEffect(() => {
    refresh()
  }, [companyId])

  useEffect(() => {
    setListItems(lists.map((list) => ({label: list.name, id: `${list.id}`})))
  }, [lists])

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        closeModal={closeModal}
        backgroundColor='white'
        width='510px'
        height='415px'
        padding='32px'
        paddingBottom='32px'
        paddingLeft='32px'
        paddingRight='32px'
        paddingTop='32px'>
        <Box fontWeight='600' fontSize='22px' color='black'>
          Adicionar placa à uma lista
        </Box>
        <Box position='absolute' right='14px' top='14px'>
          <Icon src={CloseIcon} cursor='pointer' onClick={() => closeModal()} />
        </Box>
        <Box paddingTop='16px' width='100%' display='flex' direction='column' justify='center' align='center' rowGap='16px'>
          <Dropdown label='Lista' items={listItems} onChangeSelected={(item) => setSelectedList(item)} isLoading={isLoading} />
          <Box display='flex' align='flex-start' direction='column' width='100%' rowGap='4px'>
            <Typography variant='span' size='0.875rem'>
              Placa
            </Typography>
            <Input.Root defaultValue={plateLpr} disabled />
          </Box>
          <Input.Root label='Descrição' value={description} onChange={(e) => setDescription(e.target.value)} placeholder='Descrição' />
        </Box>
        <Box display='flex' justifyContent='flex-end' columnGap='15px' marginTop='24px' marginRight='0px'>
          <Button onClick={closeModal} fontSize='12px' height='40px' width='150px' variant='outlined' color='#8E8E8E'>
            Cancelar
          </Button>
          <Button
            onClick={async () => {
              await patchLicensePlate(selectedList.id, plateLpr, description, enqueueSnackbar)
              closeModal()
            }}
            fontSize='12px'
            height='40px'
            width='150px'
            disabledTextColor='#8E8E8E'
            disabled={!selectedList || !isValidLicensePlate(plateLpr) || !description}>
            Salvar
          </Button>
        </Box>
      </Modal>
    </>
  )
}
