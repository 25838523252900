import styled from 'styled-components'

export const ContainerFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`

export const FiltersSelectedContainer = styled.div`
  display: flex;
  flex-flow: row-reverse wrap;
  align-items: flex-end;
  gap: 10px;
  padding-bottom: 15px;
  justify-content: flex-end;
`
