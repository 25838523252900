import {useNavigate} from 'react-router-dom'
import Forbidden from 'src/images/forbiddenBlack.svg'
import LogoIcon from 'src/images/fullViptechLogo.svg'
import IconLogin from 'src/images/iconLogin.svg'
import {
  BlueButton,
  Button,
  ContainerButtons,
  ContainerErrorSSO,
  ContainerImageErrorSSO,
  ContainerInfosErrorSSO,
  ContainerRedirect,
  ErrorContent,
  Logo,
  TitleError,
} from './LogoutSSOBase'

type LogoutSSOProps = {
  errorOnSSOLogin: string
}

const LogoutSSO = (redirectToProps: LogoutSSOProps) => {
  const navigate = useNavigate()

  const logoutSSO = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/brain-authentication/sso/msal/logout`
  }

  return (
    <ContainerRedirect>
      {redirectToProps.errorOnSSOLogin && (
        <div>
          <Logo src={LogoIcon} />

          <ContainerErrorSSO>
            <ContainerImageErrorSSO>
              <img src={Forbidden} alt='foto' />
            </ContainerImageErrorSSO>

            <ContainerInfosErrorSSO>
              <TitleError>Oops, {redirectToProps.errorOnSSOLogin}!</TitleError>
              <ErrorContent>
                <b>Desculpe, você não tem permissão para acessar.</b> Faça logout da sua conta Microsoft e tente fazer login novamente com outra conta.
              </ErrorContent>

              <ContainerButtons>
                <BlueButton onClick={() => navigate('/login')}>
                  <span>Retornar para página de login</span>
                </BlueButton>

                <Button onClick={logoutSSO}>
                  <img src={IconLogin} alt='microsoft icon' style={{paddingRight: '8px'}}></img>
                  <span>Deslogar da Microsoft</span>
                </Button>
              </ContainerButtons>
            </ContainerInfosErrorSSO>
          </ContainerErrorSSO>
        </div>
      )}
    </ContainerRedirect>
  )
}
export default LogoutSSO
