import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_DIGIFORT_BASE_URL

export class SnapshotParams {
  width = 1280
  height = 720
  quality = 100
  customProfile = 'Brain'
  profile = 'Custom'
}

export type Snapshot = {
  base64: string
  type: string
}

export type DigifortGetCamerasParams = {
  serverId: number
  signal?: AbortSignal
}

export type DigifortCamera = {
  name: string
  description: string
  active: string
  model: string
  deviceType: string
  connectionAddress: string
  connectionPort: number
  latitude: number
  longitude: number
  memo: string
  mediaProfiles: string[]
  group: string
}

export class DigifortEndpoint {
  async getCameras(params: DigifortGetCamerasParams): Promise<AxiosResult<DigifortCamera[]>> {
    return axios.get(`${prefix}/camera`, {
      params: {serverId: params.serverId, select: 'name'},
      timeout: 30000,
      signal: params.signal,
    })
  }
}
