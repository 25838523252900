import {useSnackbar} from 'notistack'
import {createContext, useContext, useEffect, useMemo, useRef, useState} from 'react'
import api, {Company} from 'src/services/api'
import handleErrorWithSnackbar from 'src/utilities/handleErrorWithSnackbar'

type CompanyData = {
  companiesMemoized: Company[]
  selectedCompanies: number[]
  setSelectedCompanies: (companyIds: number[]) => void
  selectedCompanyId: number
  setSelectedCompanyId: (id: number) => void
  companies: Company[]
  isLoading: boolean
  _pageCount: number
  totalPage: number
  checkRetailDashboardAccess: () => boolean
  checkCountObjectPermissionOnCompany: () => boolean
  getCompanyName: (companyId?: number) => string
  loadCompanyHeader: () => void
}

function loadSelectedCompaniesState(): number[] {
  const savedState = localStorage.getItem('selectedCompaniesState')
  return savedState ? JSON.parse('[' + savedState + ']') : []
}

function loadSelectedCompanyState(): number {
  const savedState = localStorage.getItem('selectedCompanyState')
  return savedState ? Number(savedState) : 0
}

export type CompaniesMemo = {
  id: number
  name: string
}

export const CompanyContext = createContext({} as CompanyData)

export function CompanyProvider({children}: React.PropsWithChildren<any>) {
  const [companies, setCompanies] = useState<Company[]>([])
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>(loadSelectedCompaniesState())
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(loadSelectedCompanyState())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [_pageCount, setPageCount] = useState<number>(0)
  const [totalPage, setTotalPage] = useState<number>(0)
  const {enqueueSnackbar} = useSnackbar()
  const blockCompanyRequests = useRef(false)
  const companiesMemoized = useMemo(() => companies, [companies])

  async function loadCompanyHeader() {
    if (blockCompanyRequests.current) return
    blockCompanyRequests.current = true
    setIsLoading(true)
    try {
      const response = await api.company.getMany({
        orderBy: {names: 'ASC'},
        pageSize: 100,
      })
      setPageCount(_pageCount + 1)
      setTotalPage(response.data.data.totalPages!)
      setCompanies(response.data.data.entities)
      const companiesMemo = response.data.data.entities.map((it) => {
        return {
          id: it.id,
          name: it.name,
        }
      })
      localStorage.setItem('companiesMemo', JSON.stringify(companiesMemo))

      if (!selectedCompanyId) setSelectedCompanyId(response.data.data.entities[0].id)
      if (!selectedCompanies || !selectedCompanies.length) setSelectedCompanies([response.data.data.entities[0].id])
    } catch (error) {
      handleErrorWithSnackbar(enqueueSnackbar, error, 'Erro ao carregar empresas')
    } finally {
      setIsLoading(false)
      blockCompanyRequests.current = false
    }
  }
  useEffect(() => {
    loadCompanyHeader()
  }, [])

  const checkRetailDashboardAccess = () => {
    const permissionsOnCompany = companies.find((it) => it.id === selectedCompanyId)?.permissions
    const isRetail = permissionsOnCompany ? permissionsOnCompany.map((it) => it.codename).includes('analytic-cameras:read') : false
    return isRetail
  }

  const checkCountObjectPermissionOnCompany = () => {
    const permissionsOnCompany = companies.find((it) => it.id === selectedCompanyId)?.permissions
    const hasDetectorCounterRead = permissionsOnCompany ? permissionsOnCompany.map((it) => it.codename).includes('detector-counter:read') : false
    return hasDetectorCounterRead
  }

  const getCompanyName = (companyId?: number) => {
    if (!companyId) return ''
    const company = companies.find((company) => company.id === companyId)
    if (company) return company.name

    return 'Empresa não encontrada'
  }

  return (
    <CompanyContext.Provider
      value={{
        companiesMemoized,
        selectedCompanies,
        setSelectedCompanies,
        selectedCompanyId,
        setSelectedCompanyId,
        companies,
        isLoading,
        _pageCount,
        totalPage,
        checkRetailDashboardAccess,
        checkCountObjectPermissionOnCompany,
        getCompanyName,
        loadCompanyHeader,
      }}>
      {children}
    </CompanyContext.Provider>
  )
}

export function useCompany() {
  return useContext(CompanyContext)
}
