import {Box, Button, Menu, MenuItem, Tooltip} from '@mui/material'
import {useState} from 'react'
import actionsObject from 'src/images/actionsObject.svg'
import Camera from 'src/images/camerasBlack.svg'
import Delete from 'src/images/deleteIconBlack.svg'
import IconEditUser from 'src/images/iconEditUser.svg'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {ValidatePermissions} from '../../../../../../common/utils/ValidatePermissions'

type ClientsTableOptionProps = {
  oneObject: string
  onClick: (event: string, oneObject: string) => void
  totalOfCameras: number
}

export const ClientsTableOption = (clientsTableOptionProps: ClientsTableOptionProps) => {
  const {oneObject, onClick, totalOfCameras} = clientsTableOptionProps
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const hasReadCamerasPermission = ValidatePermissions('cameras:read')
  const hasDeleteClientPermission = ValidatePermissions('clients:delete')
  const hasUpdateClientPermission = ValidatePermissions('clients:update')

  return (
    <>
      <Tooltip title='Opções' placement='top'>
        <Button
          sx={{height: '26px', minWidth: '5px', width: '1px'}}
          onClick={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
          }}>
          <img src={actionsObject} alt='Menu logo' style={{width: 20, height: 20}} />
        </Button>
      </Tooltip>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null)
        }}>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick('edit-user', oneObject)
          }}
          disabled={!hasUpdateClientPermission}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center'}}>
            <img src={IconEditUser} alt='Menu logo' style={{width: 16, height: 16}} />
            <Box sx={{fontFamily: 'Inter'}}>Editar Cliente</Box>
          </Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick('list-devices', oneObject)
          }}
          disabled={!hasReadCamerasPermission || totalOfCameras === 0}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center'}}>
            <img src={Camera} alt='Menu logo' style={{width: 16, height: 16}} />
            <Box sx={{fontFamily: 'Inter'}}>Listar Dispositivos</Box>
          </Box>
        </MenuItem>
        <MenuItem
          sx={styles.menuInfos}
          onClick={() => {
            setAnchorEl(null)
            onClick('delete', oneObject)
          }}
          disabled={!hasDeleteClientPermission}>
          <Box sx={{display: 'flex', justifyContent: 'center', gap: '10px', alignItems: 'center'}}>
            <img src={Delete} alt='Menu logo' style={{width: 16, height: 16, paddingRight: '3px'}} />
            <Box sx={{fontFamily: 'Inter'}}>Deletar</Box>
          </Box>
        </MenuItem>
      </Menu>
    </>
  )
}

const styles = createStyleSheet({
  menuInfos: {
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
  },
})
