import styled from 'styled-components'

export const TitleModal = styled.h2`
  font-size: 30px;
`

export const BoxTitle = styled.div`
  margin-bottom: 4px;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const FormControlListTransfer = {
  m: 1,
  marginLeft: '0px',
  width: '100%',
}

export const OutlinedInputListTransfer = {
  height: '35px',
}

export const ListTransfer = {
  width: '100%',
  height: 300,
  bgcolor: 'background.paper',

  border: '1px solid hsl(214, 32%, 91%)',
  borderRadius: '5px',
}

export const StyleBoxModal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'max-content',
  height: 'auto',
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  m: 2,
  marginBottom: 0,
  paddingBottom: 0,
  marginTop: '0px',
  padding: '0px 50px',
}

export const BoxCameraModal = {
  display: 'flex',
  justifyContent: 'right',
  m: 4,
  marginRight: 0,
  fontFamily: 'Inter',
}

export const CancelButtonModal = {
  width: '200px',
  height: '40px',
  marginRight: 2,
  marginBottom: 0,
  border: '1px solid',
  fontFamily: 'Inter',
  textTransform: 'none',
  boxShadow: 'none',
}

export const AddButtonModal = {
  width: '200px',
  height: '40px',
  fontFamily: 'Inter',
  textTransform: 'none',
  boxShadow: 'none',
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const GridBoxItemStyle = {
  width: '93%',
  padding: '10px',
  borderColor: '#9a9a9b',
}

export const CardSubtitle = styled.span`
  font-size: 10px;
`

export const TitleGridItems = styled.div`
  margin-bottom: 0px;
  font-size: 14px;
`
