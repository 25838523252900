import {RadioButton, Typography} from '@viptech/react-components'
import {useState} from 'react'
import {AccordionButtonContainer, AccordionContainer, AccordionIconContainer, ChildrenContainer} from './AccordionBase'
import {AccordionProps} from './AccordionProps'

export default function Accordion({isOpen, children, isLoading, disabled, onClick, title, titleComponent, ...props}: AccordionProps) {
  const [isOpenAccordion, setIsOpenAccordion] = useState(props.controlled ? isOpen : props.defaultIsOpen ?? false)
  const handleAccordion = () => {
    if (!props.controlled) setIsOpenAccordion(!isOpenAccordion)
    else if (onClick) onClick(!isOpen)
  }

  return (
    <AccordionContainer {...props}>
      <AccordionButtonContainer
        {...props}
        isOpen={props.controlled ? isOpen : isOpenAccordion}
        onClick={() => !isLoading && !disabled && handleAccordion()}
        disabled={isLoading ?? disabled}>
        <AccordionIconContainer {...props} isOpen={isOpen ?? isOpenAccordion}>
          <RadioButton id={'1'} name='radio' value={'radio 1'} checked={isOpen ?? isOpenAccordion} />
        </AccordionIconContainer>
        {titleComponent ?? <Typography variant={'label'}>{title}</Typography>}
      </AccordionButtonContainer>
      {!disabled && (
        <ChildrenContainer {...props} isOpen={props.controlled ? isOpen : isOpenAccordion}>
          {children}
        </ChildrenContainer>
      )}
    </AccordionContainer>
  )
}
