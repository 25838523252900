import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import {IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListProps} from '@mui/material'
import {LineCrossingDTO} from 'src/services/api/endpoints/LineCrossingEndpoint'
import {interestAreaSelectStyles} from './LineCrossingSelectBase'

type LineCrossingSelectProps = {
  lineCrossings: LineCrossingDTO[]
  selected: LineCrossingDTO | null
  onSelectChange: (area: LineCrossingDTO) => void
  onClickDelete: (area: LineCrossingDTO) => void
  onClickEdit: (area: LineCrossingDTO) => void
  hasEditPermission: boolean
  hasDeletePermission: boolean
  ListProps?: ListProps
  disabled: boolean
}

export function LineCrossingSelect(lineCrossingSelectProps: LineCrossingSelectProps) {
  const {lineCrossings, selected, onSelectChange, onClickDelete, onClickEdit, ListProps, disabled, hasEditPermission, hasDeletePermission} =
    lineCrossingSelectProps
  return (
    <List sx={[interestAreaSelectStyles.list, ListProps || {}]}>
      {lineCrossings.map((it, index) => {
        const isSelected = it.id === selected?.id
        return (
          <ListItem sx={interestAreaSelectStyles.listItem} key={`area-${it}-${index}`} disablePadding onClick={() => onSelectChange(it)}>
            <ListItemButton sx={interestAreaSelectStyles.listItemButton} selected={isSelected} disabled={disabled}>
              <ListItemText primary={it.name} />
              {isSelected && (
                <>
                  {hasEditPermission && (
                    <ListItemIcon sx={interestAreaSelectStyles.listItemIcon}>
                      <IconButton
                        sx={interestAreaSelectStyles.editIcon}
                        onClick={(event) => {
                          event.stopPropagation()
                          onClickEdit(it)
                        }}>
                        <ModeEditIcon style={{width: '18px'}} />
                      </IconButton>
                    </ListItemIcon>
                  )}
                  {hasDeletePermission && (
                    <ListItemIcon sx={interestAreaSelectStyles.listItemIcon}>
                      <IconButton
                        color='error'
                        onClick={(event) => {
                          event.stopPropagation()
                          onClickDelete(it)
                        }}>
                        <DeleteOutlineIcon style={{width: '18px'}} />
                      </IconButton>
                    </ListItemIcon>
                  )}
                </>
              )}
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}
