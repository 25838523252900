import {Box, Button, Card, CardContent, Checkbox, FormControl, InputLabel, MenuItem, Select, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import 'rsuite/dist/rsuite.min.css'
import RefreshIcon from 'src/images/refreshIcon.svg'
import {useAnalyticCamerasContext} from 'src/pages/analytic-cameras/context/AnalyticCamerasContext'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {CameraAnalyticDTO} from 'src/services/api/endpoints/AnalyticCameraEndpoint'
import CustomCalendar from 'src/components/customCalendar/CustomCalendar'

type HeaderDashRetailProps = {
  refreshAt: string
  cameraOptions: CameraAnalyticDTO[]
  onClick: () => void
  handleChangeTimeStart: (dates: [Date, Date]) => void
}

function HeaderDashRetail({refreshAt, cameraOptions, onClick, handleChangeTimeStart}: HeaderDashRetailProps) {
  const [cameraSelected, setCameraSelected] = useState<string[]>([])
  const [clientSelected, setClientSelected] = useState<string[]>([])
  const {clients, setfilteredClients, setFilteredAnalyticCameras} = useAnalyticCamerasContext()

  useEffect(() => {
    setCameraSelected(cameraOptions.map((it) => it.name))
    setClientSelected(clients?.map((it) => it.name))
  }, [])

  return (
    <Card sx={[styles.card]} variant='outlined'>
      <CardContent>
        <Box sx={styles.container}>
          <Box display='flex' flexDirection='row' sx={{alignItems: 'center'}}>
            <Typography style={styles.title}>
              <b>Última atualização:</b> {refreshAt}
            </Typography>
            <Button onClick={() => onClick()}>
              <img src={RefreshIcon} alt='atualizar imagem' />
            </Button>
          </Box>

          <Box display='flex' flexDirection='row' sx={{alignItems: 'center', gap: '20px'}}>
            <FormControl>
              <InputLabel id='demo-simple-select-helper-label' style={{top: '-6px', fontSize: '14px'}}>
                Selecione um cliente
              </InputLabel>
              <Select
                labelId='demo-simple-select-helper-label'
                id='demo-simple-select-helper'
                label='Selecione um cliente'
                sx={{width: '15em', borderRadius: '8px', height: '42px'}}
                multiple
                required
                size='small'
                value={clientSelected}
                renderValue={(selected) => selected.join(', ')}
                onChange={(e) => {
                  setfilteredClients(e.target.value)
                  let setClient = e.target.value as string[]
                  setClientSelected(setClient)
                }}
                disabled={!clients.length}>
                {clients?.map((client, index) => (
                  <MenuItem key={'role-' + index} value={client.name}>
                    <Checkbox checked={clientSelected.indexOf(client.name) > -1} />
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <InputLabel id='demo-simple-select-helper-label' style={{top: '-6px', fontSize: '14px'}}>
                Selecione uma câmera
              </InputLabel>
              <Select
                labelId='demo-simple-select-helper-label'
                id='demo-simple-select-helper'
                label='Selecione uma câmera'
                sx={{width: '15em', borderRadius: '8px', height: '42px'}}
                multiple
                required
                size='small'
                value={cameraSelected}
                renderValue={(selected) => selected.join(', ')}
                onChange={(e) => {
                  setFilteredAnalyticCameras(e.target.value)
                  let setCamera = e.target.value as string[]
                  setCameraSelected(setCamera)
                }}
                disabled={!cameraOptions.length}>
                {cameraOptions.map((cameras, index) => (
                  <MenuItem key={'role-' + index} value={cameras.name}>
                    <Checkbox checked={cameraSelected.indexOf(cameras.name) > -1} />
                    {cameras.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <CustomCalendar setDate={handleChangeTimeStart} sizeToHideRuler={1590} hideRuler hideTime />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  card: {
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    margin: '0px',
    padding: '10px',
  },
  title: {
    fontFamily: '"Inter","Helvetica","Inter"',
    maxWidth: '100%',
    fontSize: '0.9em',
    textAlign: 'center',
    overflow: 'clip',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '5px',
  },
})

export default HeaderDashRetail
