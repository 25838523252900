import {Icon} from 'src/common/generics/components/icon/IconBase'
import {ButtonText, ButtonContainer, ButtonContainerInner} from './ButtonBase'
import LoadMoreIcon from 'src/images/load-more-icon.svg'
import LoadMoreDisabledIcon from 'src/images/load-more-icon-disabled.svg'
import {CircularProgress} from '@mui/material'

type ButtonProps = {
  children?: React.ReactNode
  isLoading: boolean
  minWidth?: string
  minHeight?: string
  disabled?: boolean
  onClick: () => void
}

function Button(props: ButtonProps) {
  const {onClick, isLoading, minHeight, minWidth, disabled = false} = props

  return (
    <ButtonContainer minWidth={minWidth || '175px'} minHeight={minHeight || '48px'}>
      {isLoading ? (
        <CircularProgress size='25px' />
      ) : (
        <ButtonContainerInner
          onClick={() => {
            if (!disabled) onClick()
          }}>
          <ButtonText disabled={disabled}>Carregar mais</ButtonText>
          <Icon src={disabled ? LoadMoreDisabledIcon : LoadMoreIcon} alt='Icone' />
        </ButtonContainerInner>
      )}
    </ButtonContainer>
  )
}

export default Button
