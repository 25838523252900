import {DispatchTestEventResponse} from './ClientEndpoint'
import axios from './_axios'
import {prepareParams} from './_internal'
import {AxiosResult, Ord, Paginate} from './_types'

const prefix = process.env.REACT_APP_COMPANIES_BASE_URL

export type ServerInclude = 'serverType'
export type IntegrationType = 'ENTRADA' | 'SAÍDA'

export enum EnumServer {
  SIGMA_CLOUD = 1,
  DIGIFORT = 2,
  MONI = 3,
  D_GUARD = 4,
  ONE_PORTARIA = 6,
}

export interface Server {
  id: number
  ip: string
  name: string
  serverType?: ServerType
  serverTypeId?: number
  dguardServerGuid?: string
  dguardServerName?: string
  companyId?: number
}

export type ServerType = {
  id?: number
  description?: string
  integrationType?: IntegrationType
}

export type GetServerParams = {
  filter?: {
    ids?: number[]
    serverTypeIds?: number[]
    integrationType?: IntegrationType
  }
  includes?: ServerInclude[]
  serverTypeIds?: number[]
  integrationType?: IntegrationType[]
}

export type GetPagedServerParams = {
  page: number
  pageSize: number
  filter?: {
    ids?: number[]
    companyIds?: number[]
    serverTypeIds?: number[]
    integrationType?: IntegrationType
  }
  includes?: ServerInclude[]
  search?: {
    name?: string
    document?: string
  }
  orderBy?: OrderByType
}

export type CreateServer = {
  id?: number
  name: string
  ip: string
  serverTypeId: number
  auth: string
  companyId?: number
}

export type UpdateServer = {
  id: number
  name?: string
  ip?: string
  serverTypeId?: number
  auth?: string
}

export type TestServer = {
  id?: number
  ip?: string
  auth?: string
  serverType?: number
}

export type OrderByType = {
  ids?: Ord
  names?: Ord
  ips?: Ord
}

export type ServerStatus = {
  id: number
  status: 'OK' | 'ERROR'
}

export class ServerEndpoint {
  async getAll(params: GetServerParams): Promise<AxiosResult<Server[]>> {
    return await axios.get(`${prefix}/servers`, {params})
  }
  async getById(id: number): Promise<AxiosResult<Server>> {
    return await axios.get(`${prefix}/servers/${id}`)
  }

  async findManyPaged(params: GetPagedServerParams): Promise<AxiosResult<Paginate<Server>>> {
    return await axios.get(`${prefix}/servers/paged`, {params: prepareParams(params)})
  }

  async create(body: CreateServer): Promise<AxiosResult<CreateServer>> {
    return await axios.post(`${prefix}/servers`, body)
  }

  async update(body: UpdateServer): Promise<AxiosResult<UpdateServer>> {
    return await axios.patch(`${prefix}/servers`, body)
  }

  async deleteMany(ids: number[], companyId: number): Promise<AxiosResult<any>> {
    return await axios.delete(`${prefix}/servers`, {
      data: {ids, companyId},
    })
  }

  async testIntegration(body: TestServer): Promise<AxiosResult<DispatchTestEventResponse>> {
    return await axios.post(`${prefix}/servers/test`, body)
  }

  getServerStatus(ids: number[]) {
    const qs = ids?.map((id) => `ids[]=${id}`).join('&')
    const customHeaders: Record<string, string> = {
      'Transfer-Encoding': 'chunked',
      'Content-Type': 'text/plain',
      Accept: 'text/plain',
      Authorization: 'Bearer ' + localStorage.getItem('@Brain-token'),
    }
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), 7000)
    const res = fetch(`${prefix}/servers/status?` + qs, {
      method: 'GET',
      headers: customHeaders,
      signal: controller.signal,
    })
    clearTimeout(id)
    return res
  }
}
