import {Card, CardContent, CircularProgress, Typography} from '@mui/material'
import createStyleSheet from 'src/utilities/createStyleSheet'

type CountCardProps = {
  title?: string
  value?: number | undefined | string
  loading: boolean
  height?: string
  notFound?: boolean
}

function CountCard({title, value, loading, height, notFound}: CountCardProps) {
  return (
    <Card sx={[styles.card, {height}]} variant='outlined'>
      <CardContent sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography style={styles.value}>{loading ? <CircularProgress /> : value && value}</Typography>
        <Typography style={styles.title}>{title && title}</Typography>
        {notFound && (
          <>
            <Typography style={styles.headerTitle}>Contagem</Typography>
            <Typography style={styles.textInfo}>Nenhuma contagem encontrada</Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}

const styles = createStyleSheet({
  headerTitle: {
    position: 'absolute',
    left: 20,
    top: 40,
    fontWeight: 550,
    fontSize: '1.125em',
    lineHeight: '1',
    fontFamily: 'Inter',
  },
  textInfo: {
    fontWeight: 500,
    fontSize: '0.875em',
    p: 1,
  },
  card: {
    position: 'relative',
    fontFamily: 'Inter',
    backgroundColor: '#ffff',
    borderRadius: '16px',
    overflow: 'hidden',
    textAlign: 'center',
    margin: '0px',
    minHeight: '100%',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontWeight: 700,
    color: '#009EFF',
    maxWidth: '100%',
    fontSize: '1em',
    textAlign: 'center',
  },
  value: {
    fontSize: '2.55rem',
    color: '#697586',
    fontWeight: 700,
    textAlign: 'center',
  },
})

export default CountCard
