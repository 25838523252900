import {CommonProps} from '@mui/material/OverridableComponent'
import styled from 'styled-components'

interface IConnectedCam {
  isOnline: boolean
}

interface InfoConfigCameraInterface {
  cursor?: string
  textDecoration?: string
}

interface DivInfoInterface {
  alingContent?: string
  width?: string
  display?: string
  alignItems?: string
  flexFlow?: string
}

export const NameModal = styled.h2`
  font-size: 17px;
  color: #000000;
`

export const TitleModalConfig = styled.h2`
  font-size: 15px;
  text-align: left;
  color: #000000;
  padding: 4px 0 10px 0;
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 0;
  }
`

export const PartionBoxTitle = styled.h2`
  font-size: 15px;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
`

export const TitleConfigCamera = styled.label`
  font-size: 13px;
  font-weight: bold;
  padding-bottom: 10px;
`
export const MotionDetectionTitle = styled.label`
  font-size: 13px;
  font-weight: bold;
  margin-top: 20px;
`

export const ContainerGeneralData = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 869px) {
    flex-direction: column;
  }
`

export const ReactPlayerContainer = styled.div`
  display: flex;
  min-width: 800px;
  min-height: 420px;
  @media (max-width: 869px) {
    min-width: 625px;
  }

  @media (max-width: 511px) {
    min-width: 435px;
  }

  @media (max-width: 400px) {
    min-width: 35px;
  }
`

export const InfoConfigCamera = styled.p<InfoConfigCameraInterface>`
  font-size: 13px;
  ${(props) => !!props.cursor && `cursor: ${props.cursor};`}
  color: #0000cd;
  white-space: nowrap;
  ${(props) => !!props.textDecoration && `text-decoration: ${props.textDecoration};`};
`

export const SxBoxModalTabs = {
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
}

export const StyleTabsModal = {
  fontFamily: 'Inter',
  fontSize: '14px',
}

export const SxBoxCamViewConfig = {
  width: '1000px',
}

export const StyleGridViewConfig = {
  justifyContent: 'center',
  alignContent: 'center',
}

export const BoxButtonsTabConfig = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  marginTop: '20px',
  marginBottom: '20px',
}

export const SxGridItemStatus = {
  textAlign: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}

export const LabelTextStatus = styled.label`
  margin-top: 10px;
  font-weight: bold;
`

export const SxGridLineStatus = {
  display: 'flex',
  alignItems: 'center',
  maxWidth: ' 5.5%',
}

export const SxLineStatusSmall = {
  color: 'black',
  width: '120px',
  border: '5px solid ',
  borderRadius: '5px',
}

export const LogoIconStatus = styled.img`
  height: 3.75em;
  left: 2.5em;
  top: 2.5em;
`

export const ButtonDesarmedStatus = {
  borderRadius: '1.25em',
  marginTop: '0.625em',
  color: 'white',
  transition: '0.3s',
  background: 'red',
  cursor: 'default',
  '&:disabled': {
    opacity: '0.4px',
  },
  '&:hover': {
    backgroundColor: 'red',
  },
}

export const ButtonArmedStatus = {
  borderRadius: '1.25em',
  marginTop: '0.625em',
  color: 'white',
  transition: '0.3s',
  background: 'green',
  cursor: 'default',
  '&:disabled': {
    opacity: '0.4px',
  },
  '&:hover': {
    backgroundColor: 'green',
  },
}

export const ConectedCamStatus = styled.div<IConnectedCam>`
  border-radius: 1.25em;
  padding: 5px 4px;
  color: white;
  background-color: ${(props) => (props.isOnline ? '#20bc30' : '#f44336')};
  transition: 0.3s;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 147px;
  @media (max-width: 1154px) {
    width: 110px;
  }
`
export const CameraStatusLabel = styled.div<{status: string}>`
  border-radius: 1.25em;
  color: white;
  transition: 0.3s;
  cursor: default;
  font-size: 12px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  ${({status}) => status === 'ARMADO' && `background: green;`}
  ${({status}) => status === 'DESARMADO' && `background: red;`}
  ${({status}) => status === 'FORA DO HORÁRIO' && `background: orange;`}
`

export const ImageToSnapshot = styled.img`
  max-height: 345px;
  max-width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ContainerStatusCamera = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1356px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`

export const ButtonIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 3px;
  margin-left: 3px;
`

export const SxLineStatusMedium = {
  color: 'black',
  width: '250px',
  border: '5px solid ',
  borderRadius: '5px',
}

export const StyleBoxViewConfigStatus: CommonProps['style'] = {
  alignContent: 'center',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'flex-end',
  flexDirection: 'row',
  padding: 8,
}

export const SxGridItemEveryone = {
  marginTop: '50px',
}

export const TitleSectorArea = {
  fontSize: '25px',
}

export const ImgCamSnapShoot = styled.img`
  height: 371.5px;
  width: 460px;
`

export const DivInfo = styled.div<DivInfoInterface>`
  text-align: left;
  ${(props) => props.alingContent && `align-content: ${props.alingContent};`}
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.display && `display: ${props.display};`}
  ${(props) => props.alignItems && `align-items: ${props.alignItems};`}
  ${(props) => props.flexFlow && `flex-flow: ${props.flexFlow};`}

  @media (max-width: 869px) {
    max-width: 423px;
    display: flex;
    flex-direction: column;
  }
`

export const StyleBoxOriginData: CommonProps['style'] = {
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left',
}

export const StyleBoxNameCamera: CommonProps['style'] = {
  width: 'fit-content',
}

export const StyleBoxConfigEveryone: CommonProps['style'] = {
  alignContent: 'center',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: '1px',
}

export const StyleItemCamEveryone = {
  width: '100%',
  padding: '12px',
}

export const StyleBoxItemCamEveryone: CommonProps['style'] = {
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
}

export const StyleBoxItemCamSchedule: CommonProps['style'] = {
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'left',
}

export const SxBoxListSchedule = {
  borderRadius: 2,
  m: 2,
  height: '3em',
}
export const DivInfoAlert = styled.div`
  text-align: left;
`

export const ItemComponent = styled.div`
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow:
    0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  padding: 5px;
  text-align: center;
  width: 40%;
  margin-bottom: 6px;
  color: rgba(0, 0, 0, 0.6);
`

export const SxBoxRadioButton = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'start',
}

export const SxRadioButton = {
  marginBottom: '5px',
}

export const ButtonModalConfigEveryone = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 2.7em;
  width: 9em;
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  background-color: #009eff;
  transition: 0.3s;
  margin-top: 16px;

  :disabled {
    opacity: 0.4;
    cursor: default;
    :hover {
      background-color: #009eff;
    }
  }
  :hover {
    background-color: #00639f;
  }
`

export const ButtonModalConfigEveryoneEdit = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 2.7em;
  width: 6.5em;
  border-radius: 5px;
  border: none;
  background: none;
  cursor: pointer;
  color: white;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  background-color: #20bc30;
  transition: 0.3s;

  :hover {
    background-color: #00850e;
  }
`

export const LoadingInfinite = styled.div`
  span {
    animation: blink 0.9s ease-in-out infinite;
    -webkit-animation: blink 0.9s ease-in-out infinite alternate;
  }
  span:nth-child(1) {
    animation-delay: 0.3s;
  }
  span:nth-child(2) {
    animation-delay: 0.6s;
  }
  span:nth-child(3) {
    animation-delay: 0.9s;
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

export const StyleBoxItemCamQuantity: CommonProps['style'] = {
  flexDirection: 'row',
  display: 'flex',
  justifyContent: 'space-evenly',
}
