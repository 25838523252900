import {VideoContainer, VideoFrame} from './YoutubeEmbedBase'

export type YoutubeProps = {
  embedId: string
}

function YoutubeEmbed({embedId}: YoutubeProps) {
  return (
    <VideoContainer>
      <VideoFrame
        src={`https://www.youtube.com/embed/${embedId}`}
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
        title='Embedded youtube'
      />
    </VideoContainer>
  )
}
export default YoutubeEmbed
