import React from 'react'
import {MonthPickerProps} from './MonthPickerProps'
import {MonthPickerContainer, MonthPickerContent} from './MonthPickerBase'

const MONTHS = [
  {id: 0, name: 'Janeiro', enName: 'January'},
  {id: 1, name: 'Fevereiro', enName: 'February'},
  {id: 2, name: 'Março', enName: 'March'},
  {id: 3, name: 'Abril', enName: 'April'},
  {id: 4, name: 'Maio', enName: 'May'},
  {id: 5, name: 'Junho', enName: 'June'},
  {id: 6, name: 'Julho', enName: 'July'},
  {id: 7, name: 'Agosto', enName: 'August'},
  {id: 8, name: 'Setembro', enName: 'September'},
  {id: 9, name: 'Outubro', enName: 'October'},
  {id: 10, name: 'Novembro', enName: 'November'},
  {id: 11, name: 'Dezembro', enName: 'December'},
]

const MonthPicker = ({currentMonth, startYear, language = 'pt-br', onChangeMonth}: MonthPickerProps) => {
  return (
    <MonthPickerContainer>
      {MONTHS.map((month) => (
        <MonthPickerContent
          key={`month_${month.id}`}
          isCurrentMonth={new Date().getMonth() === month.id && new Date().getFullYear() === startYear}
          isSelected={currentMonth === month.id}
          onClick={() => onChangeMonth(month.id)}
          id={`month_${month}`}>
          {language === 'pt-br' ? month.name : month.enName}
        </MonthPickerContent>
      ))}
    </MonthPickerContainer>
  )
}

export default MonthPicker
