import {useState} from 'react'
import RealodIconSmallChangeColor from '../../images/ReloadIconGreenSmall'
import Dropdown from '../dropdown/Dropdown'
import Separation from '../separation/Separation'
import {ReloadButtonCotainer, ReloadButtonStyled} from './ReloadButtonBase'

type ReloadButtonProps = {
  clickButton: () => void
  setReload: (reload: boolean) => void
  setTimeToReload: (time: number) => void
}

const NewReloadButton = (props: ReloadButtonProps) => {
  const {clickButton, setReload, setTimeToReload} = props
  const [reloadTimeChosed, setReloadTime] = useState<string>('')
  const optionsReloadTimes = [
    {id: '1', label: '10 segundos', time: '10s'},
    {id: '2', label: '30 segundos', time: '30s'},
    {id: '3', label: '1 minuto', time: '1m'},
    {id: '4', label: '5 minutos', time: '5m'},
    {id: '5', label: '15 minutos', time: '15m'},
  ]

  const getMilliseconds = (reloadTime: string) => {
    const label = optionsReloadTimes.find((it) => it.label === reloadTime)?.time ?? ''
    setReloadTime(label)
    let times: {[x: string]: number} = {
      Off: 0,
      '10 segundos': 10000,
      '30 segundos': 30000,
      '1 minuto': 60000,
      '5 minutos': 300000,
      '15 minutos': 900000,
    }
    const reload = reloadTime !== 'Off'
    setReload(reload)
    setTimeToReload(times[reloadTime])
  }

  const handleLabelReloadAuto = () => {
    if (reloadTimeChosed) return `Atualizar automático: ${reloadTimeChosed}`
    return 'Atualizar automático'
  }

  return (
    <ReloadButtonCotainer>
      <ReloadButtonStyled onClick={() => clickButton()}>
        <RealodIconSmallChangeColor color='#009EFF' />
      </ReloadButtonStyled>

      <Separation height='80%' width='1px' color='#9aa4b2' />

      <Dropdown
        fontSize='14px'
        labelFontSize='14px'
        borderColor='transparent'
        bgColor='white'
        disabledBorderColor
        placeholderColor='#344054'
        placeholder={handleLabelReloadAuto()}
        items={optionsReloadTimes}
        onChangeSelected={(e) => getMilliseconds(e.label)}
        dontReflectValueSelectedOnLabel
      />
    </ReloadButtonCotainer>
  )
}

export default NewReloadButton
