import axios from './_axios'
import {AxiosResult} from './_types'

const prefix = process.env.REACT_APP_OBJECTS_BASE_URL

type Coordinate = {
  id: number
  lineCrossingId?: number
  interestAreaId?: number
  x: number
  y: number
}

export type LineCrossingDTO = {
  id: number
  cameraConfigurationId: number
  name: string
  coordinates?: Array<Coordinate>
}

type Point = {
  x: number
  y: number
}

export type CreateLineCrossing = {
  cameraId: number
  name: string
  coordinates: Array<Point>
}

export type UpdateLineCrossing = {
  id: number
  name?: string
  coordinates?: Array<Point>
}

export class LineCrossingEndpoint {
  async createLineCrossing(body: CreateLineCrossing): Promise<AxiosResult<LineCrossingDTO>> {
    return await axios.post(`${prefix}/line-crossing`, body)
  }

  async getLineCrossing(cameraId: number): Promise<AxiosResult<Array<LineCrossingDTO>>> {
    return await axios.get(`${prefix}/line-crossing?cameraId=${cameraId}`)
  }

  async updateLineCrossing(body: UpdateLineCrossing): Promise<AxiosResult<LineCrossingDTO>> {
    return await axios.patch(`${prefix}/line-crossing`, body)
  }

  async deleteLineCrossing(lineCrossingId: number): Promise<AxiosResult<void>> {
    return await axios.delete(`${prefix}/line-crossing/${lineCrossingId}`)
  }
}
