
interface ErrorIconProps {
  color?: string
}

export default function ErrorIcon(errorIconProps: ErrorIconProps) {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="3d85a16bd8036ee77fbb6b1ad59f34c0"
        d="M7.33334 5.33334V8.00001M7.33334 10.6667H7.34001M14 8.00001C14 11.6819 11.0152 14.6667 7.33334 14.6667C3.65144 14.6667 0.666672 11.6819 0.666672 8.00001C0.666672 4.31811 3.65144 1.33334 7.33334 1.33334C11.0152 1.33334 14 4.31811 14 8.00001Z"
        stroke={errorIconProps.color ?? '#F04438'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  )
}
