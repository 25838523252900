import {Box, RadioButton} from '@viptech/react-components'
import {useState} from 'react'
import {Input} from 'src/components/input/Input'
import {PersonType, useB2CContext} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {validateCNPJ, validateCPF} from '../../../../utilities/validateDocuments'

type FirstFormPageProps = {
  personType: PersonType
  setPersonType: (personType: PersonType) => void
  setDisabledButton: (disabled: boolean) => void
  handleKeyPress: (event: KeyboardEvent) => void
}

function FirstFormPage(props: FirstFormPageProps) {
  const handleKeyPress = props.handleKeyPress
  const {personType, setPersonType, setDisabledButton} = props
  const {registerData, setRegisterData} = useB2CContext()
  const isPerson = personType === 'person'
  const [errorOnInput, setErrorOnInput] = useState(false)

  function maskDocument(value: string) {
    if (isPerson) {
      return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    } else {
      return value.replace(/\D/g, '').replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
    }
  }

  function maskPhone(value: string) {
    return value.replace(/\D/g, '').replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3')
  }

  function handleToggleRadio(id: PersonType) {
    setPersonType(id)
    setRegisterData({document: '', companyName: '', name: ''})
    setErrorOnInput(false)
  }

  return (
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', flexDirection: 'column', width: '100%'}}>
      <Box style={{display: 'flex', flexDirection: 'row', gap: '32px'}}>
        <RadioButton defaultChecked id='person' name='personType' label='Sou Pessoa Física' onChange={(e) => handleToggleRadio(e.target.id as PersonType)} />
        <RadioButton id='company' name='personType' label='Sou Pessoa Jurídica' onChange={(e) => handleToggleRadio(e.target.id as PersonType)} />
      </Box>

      <Box style={{display: 'flex', flexDirection: 'column', gap: '22px', width: '100%', alignItems: 'center'}}>
        {isPerson ? (
          <Box width='100%' display='flex' direction='column' rowGap='10px'>
            <Input.Root
              controlled
              value={registerData.name}
              id='name'
              label={'Nome Completo'}
              labelFontSize='14px'
              onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
              onChange={(e) => setRegisterData({name: e.target.value})}
            />
            <Input.Root
              controlled
              value={registerData.document}
              label='CPF'
              labelFontSize='14px'
              maxLength={14}
              mask={(value) => maskDocument(value)}
              onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
              error={errorOnInput}
              onChange={(e) => {
                const validated = validateCPF(e.target.value)
                setErrorOnInput(!validated)
                setDisabledButton(!validated)

                setRegisterData({document: e.target.value})
              }}>
              {errorOnInput && <Input.SingleError message={'CPF inválido'} />}
            </Input.Root>
          </Box>
        ) : (
          <Box width='100%' display='flex' direction='column' rowGap='18px'>
            <Input.Root
              controlled
              id='companyname'
              label='Razão Social'
              labelFontSize='14px'
              value={registerData.companyName}
              onChange={(e) => setRegisterData({companyName: e.target.value})}
            />
            <Input.Root
              controlled
              value={registerData.document}
              label='CNPJ'
              labelFontSize='14px'
              maxLength={18}
              onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
              mask={(value) => maskDocument(value)}
              onChange={(e) => {
                const validated = validateCNPJ(e.target.value)
                setErrorOnInput(!validated)
                setDisabledButton(!validated)

                setRegisterData({document: e.target.value})
              }}>
              {errorOnInput && <Input.SingleError message={'CNPJ inválido'} />}
            </Input.Root>
            <Input.Root
              id='name'
              onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
              label={'Nome do responsável'}
              labelFontSize='14px'
              labelColor='#344054'
              controlled
              value={registerData.name}
              onChange={(e) => setRegisterData({name: e.target.value})}
            />
          </Box>
        )}
        <Input.Root
          label='Telefone'
          labelFontSize='14px'
          onKeyDown={(e: KeyboardEvent) => handleKeyPress(e)}
          maxLength={15}
          mask={(value) => maskPhone(value)}
          value={registerData.phone}
          controlled
          onChange={(e) => setRegisterData({phone: e.target.value})}
        />
      </Box>
    </Box>
  )
}

export default FirstFormPage
