import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded'
import {Button, Grid} from '@mui/material'
import {useMemo, useState} from 'react'
import TransferOneList from 'src/pages/cameras/components/CameraAddModal/CameraRegisterModal/TransferList/TransferOneList'
import createStyleSheet from 'src/utilities/createStyleSheet'
import {Item} from './../../cameras/components/CameraAddModal/CameraRegisterModal/TransferList/types'

function partition<T>(xs: T[], selector: (x: T) => boolean): [T[], T[]] {
  const parts: [T[], T[]] = [[], []]
  xs.forEach((x) => parts[+selector(x)].push(x))
  return parts
}

function markUnchecked(items: Item[]): Item[] {
  return items.map((it) => ({...it, checked: false}))
}

function moveChecked(lhs: Item[], rhs: Item[]) {
  const [unchecked, checked] = partition(lhs, (it) => it.checked)
  return [unchecked, rhs.concat(markUnchecked(checked))]
}

function toggleCheck(items: Item[], key: string) {
  return items.map((it) => (it.key === key ? {...it, checked: !it.checked} : it))
}

function moveRightFiltered(lfItems: Item[], rfItems: Item[], key: string, id: number, maxCameras: number) {
  if (id === 2) key = ''

  const start = maxCameras - rfItems.length
  const leftItems = lfItems.concat(rfItems.filter((it) => !it.key.match(key.toUpperCase()))).slice(start)
  const rightItems = rfItems.concat(lfItems.filter((it) => it.key.match(key.toUpperCase()))).slice(0, maxCameras)
  return [leftItems, rightItems]
}

function moveLeftFiltered(lfItems: Item[], rfItems: Item[], key: string, id: number) {
  if (id === 1) key = ''

  const leftItems = lfItems.concat(rfItems.filter((it) => it.key.match(key.toUpperCase())))
  const rightItems = rfItems.filter((it) => !it.key.match(key.toUpperCase()))
  return [leftItems, rightItems]
}

export type TransferListData = {
  leftItems: Item[]
  rightItems: Item[]
}

type TransferListProps = {
  leftItems: Item[]
  rightItems: Item[]
  onChange: (data: TransferListData) => void
  maxCameras: number
  disabled?: boolean
}

function LayoutCameraList({leftItems, rightItems, onChange, maxCameras, disabled = false}: TransferListProps) {
  const [search, setSearch] = useState('')
  const leftItemsChecked = useMemo(() => leftItems.filter((it) => it.checked), [leftItems])
  const rightItemsChecked = useMemo(() => rightItems.filter((it) => it.checked), [rightItems])
  const [id, setId] = useState(Number)

  return (
    <Grid container spacing={2} justifyContent='center' alignItems='center' sx={{height: '100%'}}>
      <Grid sx={{width: '45%'}}>
        <TransferOneList
          id={1}
          valueKey={(search, identity) => {
            setId(identity)
            setSearch(search)
          }}
          disabled={disabled}
          title='Pesquisar câmeras disponíveis'
          items={leftItems}
          toggleChecked={(item) => {
            onChange({
              leftItems: toggleCheck(leftItems, item.key),
              rightItems,
            })
          }}
        />
      </Grid>
      <Grid item>
        <Grid container direction='column' alignItems='center' justifyContent='center' sx={styles.buttons}>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [lNewItems, rNewItems] = moveRightFiltered(leftItems, rightItems, search, id, maxCameras)
              onChange({
                leftItems: lNewItems,
                rightItems: rNewItems,
              })
            }}
            disabled={disabled || leftItems.length === 0 || rightItems.length === maxCameras}
            aria-label='move all to right'>
            <DoubleArrowRoundedIcon />
          </Button>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [newLeftItems, newRightItems] = moveChecked(leftItems, rightItems)
              onChange({leftItems: newLeftItems, rightItems: newRightItems})
            }}
            disabled={disabled || leftItemsChecked.length === 0 || rightItems.length === maxCameras || leftItemsChecked.length + rightItems.length > maxCameras}
            aria-label='move selected to right'>
            <ArrowForwardIosIcon />
          </Button>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [newRightItems, newLeftItems] = moveChecked(rightItems, leftItems)
              onChange({leftItems: newLeftItems, rightItems: newRightItems})
            }}
            disabled={disabled || rightItemsChecked.length === 0}
            aria-label='move selected to left'>
            <ArrowBackIosRoundedIcon />
          </Button>
          <Button
            sx={styles.button}
            variant='outlined'
            size='small'
            onClick={() => {
              const [lNewItems, rNewItems] = moveLeftFiltered(leftItems, rightItems, search, id)
              onChange({
                leftItems: lNewItems,
                rightItems: rNewItems,
              })
            }}
            disabled={disabled || rightItems.length === 0}
            aria-label='move all to left'>
            <DoubleArrowRoundedIcon sx={styles.reverseButton} />
          </Button>
        </Grid>
      </Grid>
      <Grid sx={{width: '45%'}}>
        <TransferOneList
          id={2}
          valueKey={(search, identity) => {
            setId(identity)
            setSearch(search)
          }}
          disabled={disabled}
          title='Pesquisar câmeras disponíveis'
          items={rightItems}
          toggleChecked={(item) => {
            onChange({
              leftItems,
              rightItems: toggleCheck(rightItems, item.key),
            })
          }}
        />
      </Grid>
    </Grid>
  )
}
const styles = createStyleSheet({
  notFoundText: {
    textAlign: 'center',
    marginTop: '5vh',
  },
  itemButtom: {
    padding: 0,
    margin: 0,
  },
  listIcon: {
    padding: 0.3,
    width: '2.1em',
    minWidth: 0,
  },
  inputSearch: {
    height: '2.825em',
    fontSize: '0.775em',
    fontStyle: 'normal',
    paddingTop: '1px',
    width: '100%',
  },
  formInput: {
    margin: '16px 16px 0px 16px',
  },
  buttons: {
    marginTop: '100px',
  },
  button: {
    marginRight: 2,
    my: 0.5,
    minWidth: '58px',
    minHeight: '38px',
    background: '#e2e8f0',
  },
  reverseButton: {
    transform: 'scaleX(-1)',
  },
})

export default LayoutCameraList
