import {Box, Typography} from '@mui/material'
import ReactPlayer from 'react-player'
import createStyleSheet from 'src/utilities/createStyleSheet'

const DguardGuide = () => {
  const service = '{"device_name":"$camera.name$"}'

  return (
    <Box sx={styles.paper}>
      <Typography sx={{marginBottom: 5, fontSize: '1.6rem'}}>Informações adicionais para credenciais D-Guard</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>Habilite o envio de requisição HTTP</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>No campo Método, mantenha "POST"</Typography>

      <Typography sx={{marginBottom: 1, fontSize: '1.3rem'}}>
        No campo URL, insira o seguinte link:
        <Typography sx={{marginBottom: 4, fontSize: '1.2rem'}}>https://brain-gateway-prd.viptech.com.br/dguard-integration/trigger</Typography>
      </Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>No campo Autenticação, mantenha "Basic"</Typography>

      <Typography sx={{marginBottom: 4, fontSize: '1.3rem'}}>
        Nos campos "Usuário" e "Senha" devem ser inseridas as credenciais de acesso pragmático geradas na plataforma do Brain
      </Typography>

      <Typography sx={{marginBottom: 2, fontSize: '1.3rem'}}>
        No campo cabeçalho, insira o seguinte conteúdo:
        <Typography sx={{marginBottom: 4, fontSize: '1.2rem'}}>Content-type: application/json</Typography>
      </Typography>

      <Typography sx={{marginBottom: 2, fontSize: '1.3rem'}}>
        Por fim, no campo "Corpo", deve ser inserido o seguinte valor:
        <Typography sx={{marginBottom: 4, fontSize: '1.2rem'}}>{service}</Typography>
      </Typography>

      <Typography sx={{marginBottom: 3, fontSize: '1.3rem'}}>O vídeo a seguir explica de forma detalhada os passos para completar a integração:</Typography>

      <Box>
        <ReactPlayer width={'1000px'} height={'450px'} url='https://www.youtube.com/watch?v=uaMskbxaAP0' controls={true} />
      </Box>
    </Box>
  )
}

const styles = createStyleSheet({
  paper: {
    borderRadius: '8px',
    alignItems: 'center',
    flexDirection: 'column',
    background: 'white',
    padding: 1.5,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: 0.5,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '77vh',
    overflow: 'scroll',
  },
  gridItem2: {
    display: 'flex',
    flexDirection: 'column',
    height: '82vh',
    overflow: 'scroll',
  },
  notReleases: {
    fontSize: '1rem',
    justifyContent: 'center',
    height: '77vh',
    alignItems: 'center',
    display: 'flex',
  },
})

export default DguardGuide
