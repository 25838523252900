import styled from 'styled-components'

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 56px;
  gap: 32px;
  width: 100%;

  @media (max-width: 1400px) {
    padding-top: 30px;
  }

  @media (max-width: 1200px) {
    padding-top: 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const InnerCardContainer = styled.div<{isDefault?: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 250px;
  border-radius: 8px;
  border: 1px solid #98a2b3;
  padding: 30px;
  position: relative;
  ${({isDefault}) => {
    if (isDefault) {
      return `
      border-radius: 8.4px;
      border: 1.05px solid #62B6FF;
      background: #FFF;
      box-shadow: 0px 2px 3px 0px rgba(142, 150, 185, 0.25);
      min-height: 350px;
      `
    }
  }}
`
export const Pin = styled.div`
  border-radius: 100px;
  border: 1px solid #08f;
  background: #dbeeff;
  font-size: 10px;
  color: #08f;
  display: flex;
  width: 70px;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  align-self: flex-start;
  position: absolute;
  right: 20px;
`
export const CardTitle = styled.span`
  font-size: 18px;
  color: #344054;
  font-weight: 500;
`

export const CardSubtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #667085;
  font-weight: 400;
  text-align: left;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #98a2b3;
  margin: 20px 0;
`
