import {useLayoutEffect, useRef} from 'react'
import {SingleErrorText} from './SingleErrorBase'
import {SingleErrorProps} from './SingleErrorProps'

export default function SingleError({disabled, message}: SingleErrorProps) {
  const ref = useRef<HTMLSpanElement>(null)

  useLayoutEffect(() => {
    const element = ref.current
    if (!element) return

    const elementHeight = element.offsetHeight
    element.style.bottom = `-${elementHeight + 6}px`
  }, [message])

  return (
    <SingleErrorText ref={ref} $disabled={disabled}>
      {message}
    </SingleErrorText>
  )
}
