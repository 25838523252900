import styled from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background-color: #344054;
  position: relative;
`

export const ImageContainer = styled.img`
  width: 177px;
  height: 39px;
  cursor: pointer;
`

export const HeaderExitButtonContainer = styled.div`
  position: absolute;
  right: 15px;

  @media (max-width: 767px) {
    right: 5px;
  }
`

export const ImageLogoutContainer = styled.img`
  @media (max-width: 767px) {
    display: none;
  }
`
