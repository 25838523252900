import {Box, Button, Typography} from '@viptech/react-components'
import CheckIcon from 'src/images/checkSmallBlue.svg'
import {EnumRecurrence} from 'src/layouts/b2c-layout/contexts/B2CContext'
import {Plan} from 'src/services/api/endpoints/PlansEndpoint'
import {CardSubtitle, CardTitle, Divider, InnerCardContainer, Pin} from './PlanCardBase'

type PlanCardProps = {
  plan: Plan
  recurrency: EnumRecurrence
  onClick: () => void
  isDefaultPlan?: boolean
}

function PlanCard(props: PlanCardProps) {
  const {plan, onClick, isDefaultPlan} = props
  let price = String(plan.price)
  if (!price.includes('.')) {
    price += '.00'
  }
  const splittedPrice = price.split('.')

  return (
    <InnerCardContainer isDefault={isDefaultPlan}>
      {isDefaultPlan && <Pin>Padrão</Pin>}
      <Box display='flex' direction='column' justifyContent='flex-start' marginTop='10px' width='100%' flex={1}>
        <CardTitle>{plan.name}</CardTitle>
        <Box style={{display: 'flex', flexDirection: 'column', paddingTop: '15px'}}>
          <Box>
            <Typography variant='span' size='38px' color='#344054' weight={700}>
              R${splittedPrice[0]}
            </Typography>
            <Typography variant='span' size='20px' color='#344054' weight={700}>
              ,{splittedPrice[1]}
            </Typography>
            <Typography variant='span' size='18px' color='#667085' weight={400}>
              / câmera
            </Typography>
          </Box>

          <CardSubtitle>{plan.description}</CardSubtitle>

          <Divider />

          <Box>
            <Box display='flex' columnGap='5px' margin='5px 0'>
              <img src={CheckIcon} alt='icone' />
              <CardSubtitle>Detecção de pessoas, carro e moto</CardSubtitle>
            </Box>

            <Box display='flex' columnGap='5px' margin='5px 0'>
              <img src={CheckIcon} alt='icone' />
              <CardSubtitle>Alertas em tempo real</CardSubtitle>
            </Box>

            <Box display='flex' columnGap='5px' margin='5px 0'>
              <img src={CheckIcon} alt='icone' />
              <CardSubtitle>Plataforma com eventos </CardSubtitle>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box marginTop='25px' width='100%' display='flex' alignSelf='flex-end'>
        <Button width='100%' fontSize='14px' variant={isDefaultPlan ? 'contained' : 'outlined'} onClick={() => onClick()}>
          Assinar
        </Button>
      </Box>
    </InnerCardContainer>
  )
}

export default PlanCard
